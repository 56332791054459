/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import StatusField from '@atlassian/jira-issue-view-foundation/src/status/view.tsx';

const FoundationContext = ({
	issueViewRelayFragment,
	isStatusOnLHS = false,
	isStatusPositionExperimentEnabled = false,
}: {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	isStatusOnLHS?: boolean;
	isStatusPositionExperimentEnabled?: boolean;
}) => (
	<>
		<div
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
			css={[foundationContextWrapperStyle, isStatusOnLHS && foundationContextWrapperStyleOverflow]}
		>
			<StatusField
				key="STATUS"
				isStatusPositionExperimentEnabled={isStatusPositionExperimentEnabled}
				fragmentKey={issueViewRelayFragment}
				isStatusOnLHS={isStatusOnLHS}
			/>
		</div>
	</>
);

export default FoundationContext;

const foundationContextWrapperStyle = css({
	marginBottom: 0,
});

const foundationContextWrapperStyleOverflow = css({
	overflow: 'visible',
});
