import React, { useMemo, type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { DataRedactionHistoryItem, RedactionGroupHistoryItem } from '../../../../types.tsx';
import messages from './messages.tsx';

type Props = {
	historyItem: DataRedactionHistoryItem | RedactionGroupHistoryItem;
};

const RedactedHistoryItem = ({ historyItem }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp } = historyItem;

	const fields = useMemo(() => {
		if (historyItem.type === 'REDACTION_GROUPED_HISTORY_ITEM') {
			return historyItem.items.map((item) => item.field).join(', ');
		}
		return historyItem.field;
	}, [historyItem]);

	const fieldsCount = useMemo(() => {
		if (historyItem.type === 'REDACTION_GROUPED_HISTORY_ITEM') {
			return historyItem.items.length;
		}
		return 1;
	}, [historyItem]);

	return (
		<HistoryItem
			testId="issue-history.ui.history-items.history-item.redaction-history-item.history-item"
			actor={actor}
			action={formatMessage(messages.removed)}
			timestamp={timestamp}
			change={
				<Box xcss={changeStyles}>
					{formatMessage(messages.description, {
						b: (content: ReactNode) => <b>{content}</b>,
						fields,
						fieldsCount,
					})}
				</Box>
			}
		/>
	);
};

const changeStyles = xcss({
	marginBlockStart: 'space.100',
});

export default RedactedHistoryItem;
