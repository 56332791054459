import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';

import InfoIcon from '@atlassian/jira-icons/src/ui/info-icon/index.tsx';

const FieldDescInfoIcon = () => (
	<Box xcss={infoIconStyles}>
		<InfoIcon testId="issue-field-description.ui.assets.icon" label="" />
	</Box>
);

const infoIconStyles = xcss({
	marginBottom: 'space.025',
});

export default FieldDescInfoIcon;
