import React, { memo } from 'react';
import { useFragment, graphql } from 'react-relay';
import Heading from '@atlaskit/heading';
import { Box, Inline, Stack, Text } from '@atlaskit/primitives';
import { SectionMessageAction } from '@atlaskit/section-message';
import InformationIcon from '@atlaskit/icon/core/information';
import WarningIcon from '@atlaskit/icon/core/warning';
import { token } from '@atlaskit/tokens';
import { B500, Y500 } from '@atlaskit/theme/colors';
import { useIntl } from '@atlassian/jira-intl';
import { COMPANY_MANAGED_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { ui_issueViewLayoutTemplates_DateFieldsMessage$key as DateFieldsMessageFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTemplates_DateFieldsMessage.graphql';
import getConfigurationHref from '@atlassian/jira-issue-view-foundation/src/utils/get-configuration-href.tsx';
import type { MessageAttributesType, Props } from './types.tsx';
import messages from './messages.tsx';

export const DateFieldsMessage = memo(({ rootRelayFragment }: Props) => {
	const { formatMessage } = useIntl();

	const data = useFragment<DateFieldsMessageFragment>(
		graphql`
			fragment ui_issueViewLayoutTemplates_DateFieldsMessage on JiraQuery {
				issueByKey(cloudId: $cloudId, key: $issueKey) {
					projectField {
						project {
							projectStyle
							canAdministerProject: action(type: EDIT_PROJECT_CONFIG) {
								canPerform
							}
						}
					}
				}
			}
		`,
		rootRelayFragment?.jira || null,
	);

	const project = data?.issueByKey?.projectField?.project;
	const projectStyle = project?.projectStyle ?? COMPANY_MANAGED_PROJECT;
	const canEditProjectConfig = !!project?.canAdministerProject?.canPerform;

	const configureFieldsPageHref = getConfigurationHref(formatMessage);

	const enableOrConfigureFieldsMessage =
		projectStyle === COMPANY_MANAGED_PROJECT ? (
			<SectionMessageAction key={1} href={configureFieldsPageHref}>
				{formatMessage(messages.configureFields)}
			</SectionMessageAction>
		) : (
			<SectionMessageAction key={1} href="#">
				{formatMessage(messages.enable)}
			</SectionMessageAction>
		);

	const dismissMessage = (
		<SectionMessageAction key={2} href="#">
			{formatMessage(messages.dismiss)}
		</SectionMessageAction>
	);

	const messageAttributes: MessageAttributesType = canEditProjectConfig
		? {
				color: token('color.icon.warning', Y500),
				icon: WarningIcon,
				iconLabel: 'warning',
				actions: [enableOrConfigureFieldsMessage, dismissMessage],
				messageDescription: formatMessage(messages.enableDateFieldsDescriptionAdmin),
			}
		: {
				color: token('color.icon.information', B500),
				icon: InformationIcon,
				iconLabel: 'information',
				actions: [dismissMessage],
				messageDescription: formatMessage(messages.enableDateFieldsDescriptionNonAdmin),
			};

	const Icon = messageAttributes.icon;

	return (
		<Box
			as="section"
			paddingInline="space.100"
			paddingBlock="space.200"
			testId="issue-date-fields-message.ui.date-fields-message"
		>
			<Inline space="space.100" alignBlock="stretch">
				<Box>
					<Icon
						color={messageAttributes.color}
						spacing="spacious"
						label={messageAttributes.iconLabel}
						LEGACY_size="small"
					/>
				</Box>
				<Stack space="space.100">
					<Heading as="h3" size="xsmall">
						{formatMessage(messages.enableDateFieldsTitle)}
					</Heading>

					<Box>
						<Text>{messageAttributes.messageDescription}</Text>
					</Box>

					<Inline shouldWrap separator="·" space="space.100" rowSpace="space.0" role="list">
						{messageAttributes.actions.map((action, id) => (
							<Inline role="listitem" key={id}>
								{action}
							</Inline>
						))}
					</Inline>
				</Stack>
			</Inline>
		</Box>
	);
});
