import React, { useCallback, useMemo, useState } from 'react';
import { css, styled } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { OriginalEstimateEditViewProps } from '@atlassian/jira-issue-field-original-estimate-editview-full/src/ui/original-estimate/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { OriginalEstimateEditView } from '@atlassian/jira-issue-field-original-estimate-editview-full/src/ui/original-estimate/index.tsx';
import { OriginalEstimateReadView } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/index.tsx';
import { TIME_ESTIMATE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { type AnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import OriginalEstimateEditViewEntryPoint from '@atlassian/jira-issue-field-original-estimate-editview-full/src/entrypoint.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type {
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation as OriginalEstimateMutationOld,
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$data as OriginalEstimateMutationDataOld,
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$rawResponse as OriginalEstimateMutationResponseOld,
} from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation.graphql';
import type {
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation as OriginalEstimateMutation,
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation$data as OriginalEstimateMutationData,
} from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation.graphql';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef$key as OriginalEstimateFragmentOld } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef.graphql';
import type { originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewNew_fragmentRef$key as OriginalEstimateFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewNew_fragmentRef.graphql';
import type { originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef$key as OriginalEstimateInlineEditViewIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef.graphql';
import type {
	ErrorsType,
	OriginalEstimateInlineEditViewProps,
	OriginalEstimateInlineEditViewOldProps,
	OriginalEstimateInlineEditViewIsEditableProps,
} from './types.tsx';

const nonEditableStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',

	lineHeight: '20px',

	paddingLeft: token('space.075', '6px'),
});

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [OriginalEstimateInlineEditViewProps](./types.tsx)
 */

export const OriginalEstimateInlineEditViewOld = ({
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	readViewFitContainerWidth = true,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	onFocus,
	onEdit,
	onCancel,
	onEnter,
}: OriginalEstimateInlineEditViewOldProps) => {
	// #region Relay
	const data = useFragment<OriginalEstimateFragmentOld>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef on JiraTimeTrackingField {
				...originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView
				id
				name
				originalEstimate {
					timeInSeconds
				}
				fieldConfig {
					isEditable
				}
				timeTrackingSettings {
					...originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView
				}
			}
		`,
		fragmentRef,
	);

	const uniqueFieldId = data?.id ?? '';
	const name = data?.name ?? '';
	const fieldConfig = data?.fieldConfig;
	const originalEstimate = data?.originalEstimate;

	const [commit] = useMutation<OriginalEstimateMutationOld>(graphql`
		mutation originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation(
			$input: JiraUpdateTimeTrackingFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateTimeTrackingField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						originalEstimate {
							timeInSeconds
						}
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const [isEditing, setIsEditing] = useState<boolean>(startWithEditViewOpen);
	const [newValue, setNewValue] = useState<number | null | undefined>(
		originalEstimate?.timeInSeconds,
	);
	const [invalidMessage, setInvalidMessage] = useState<string | null>(null);

	const isFieldEditable = fg('relay-migration-issue-fields-original-estimate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() => overriding.overrideIsEditable(fieldConfig?.isEditable || false),
				[fieldConfig?.isEditable, overriding],
			)
		: fieldConfig?.isEditable || false;

	const fieldName = fg('relay-migration-issue-fields-original-estimate')
		? overriding.overrideLabel(name)
		: name;
	// #endregion

	// #region Handle new value
	const onCancelRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			setInvalidMessage(null);
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel],
	);

	const onEditRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			setInvalidMessage(null);
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter],
	);

	const onCommitError = useCallback(
		(responseErrors: ErrorsType) => {
			setInvalidMessage(responseErrors?.[0]?.message ?? 'Commit error');
			setIsEditing(true);
			onSubmitFailed?.();
		},
		[onSubmitFailed],
	);

	const onCommitCompleted = useCallback(
		(response: OriginalEstimateMutationDataOld, updatedValue?: number | null) => {
			if (!response.jira?.updateTimeTrackingField) {
				onCommitError([]);
				return;
			}

			const { success, errors: responseErrors, field } = response.jira.updateTimeTrackingField;
			if (!success) {
				onCommitError(responseErrors);
				return;
			}
			setNewValue(field?.originalEstimate?.timeInSeconds);
			onSubmitSucceeded?.(updatedValue);
		},
		[onSubmitSucceeded, onCommitError],
	);

	const handleNewValue = useCallback(
		(updatedValue: number | null, analyticsEvent: AnalyticsEvent) => {
			// Clear errors and exit editing mode
			setInvalidMessage(null);
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
			onSubmit?.(updatedValue);
			/**
			 * This will execute the GraphQl mutation.
			 */
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						originalEstimate: {
							timeInSeconds: updatedValue || 0,
						},
					},
				},
				onCompleted(res) {
					onCommitCompleted(res, updatedValue);
				},
				onError(error) {
					onCommitError([error]);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateTimeTrackingField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								originalEstimate: {
									timeInSeconds: updatedValue,
								},
							},
						},
					},
				} as OriginalEstimateMutationResponseOld,
			});
		},
		[commit, onCommitCompleted, onCommitError, onSubmit, uniqueFieldId],
	);
	// #endregion

	// #region Common callbacks
	const onConfirmRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			if (!invalidMessage) {
				handleNewValue(newValue || null, analyticsEvent);
			}
		},
		[invalidMessage, handleNewValue, newValue],
	);

	// #endregion

	// #region Read view
	const renderReadView = () => (
		<ReadViewContainer data-testid="issue-field-original-estimate-inline-edit-full.ui.original-estimate.read-view">
			<OriginalEstimateReadView fragmentRef={data} enableHover={isFieldEditable} />
		</ReadViewContainer>
	);
	// #endregion

	// #region Edit view
	const renderEditView = () => (
		<>
			<EditViewContainer data-testid="issue-field-original-estimate-inline-edit-full.ui.original-estimate.edit-view">
				<OriginalEstimateEditView
					initialTimeInSeconds={originalEstimate?.timeInSeconds}
					onChange={setNewValue}
					onFocus={onFocus}
					onChangeInvalidMessage={setInvalidMessage}
					invalidMessage={invalidMessage}
					timeTrackingSettingsfragmentRef={data?.timeTrackingSettings || null}
				/>
			</EditViewContainer>
		</>
	);
	// #endregion

	return (
		<InlineEditContainer isEditable={isFieldEditable}>
			<FieldInlineEditStateLess
				defaultValue="0"
				editView={renderEditView}
				isEditable={isFieldEditable}
				isEditing={isEditing}
				isLabelHidden
				label={fieldName}
				readView={renderReadView}
				readViewFitContainerWidth={readViewFitContainerWidth}
				testId="issue-field-original-estimate-inline-edit-full.ui.original-estimate.field-inline-edit-state-less"
				fieldId={fg('one_event_rules_them_all_fg') ? TIME_ESTIMATE_TYPE : undefined}
				onCancel={onCancelRequest}
				onConfirm={onConfirmRequest}
				onEdit={onEditRequest}
				onEnter={onEnterRequest}
				componentAnalyticsData={
					fg('one_event_rules_them_all_fg')
						? {
								fieldType: FIELD_TYPE_MAP[TIME_ESTIMATE_TYPE],
							}
						: undefined
				}
			/>
		</InlineEditContainer>
	);
};

export const OriginalEstimateInlineEditViewIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	spacing = 'compact',
	menuPosition,
	menuPortalTarget,
	readViewFitContainerHeight,
	isEditable,
	onSubmit,
	onSubmitSucceeded,
	onSubmitFailed,
}: OriginalEstimateInlineEditViewIsEditableProps) => {
	// #region Relay
	const data = useFragment<OriginalEstimateInlineEditViewIsEditableFragment>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef on JiraTimeTrackingField {
				...originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView
				id
				fieldId
				name
				type
				originalEstimate {
					timeInSeconds
				}
				timeTrackingSettings {
					...originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView
				}
			}
		`,
		fragmentRef,
	);
	const {
		id: uniqueFieldId = '',
		name = '',
		fieldId = '',
		type: fieldType = '',
		originalEstimate,
	} = data || {};

	const [commit] = useMutation<OriginalEstimateMutation>(graphql`
		mutation originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation(
			$input: JiraUpdateTimeTrackingFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateTimeTrackingField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						originalEstimate {
							timeInSeconds
						}
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const [invalidMessage, setInvalidMessage] = useState<string | null>();
	const initialValue = originalEstimate?.timeInSeconds || null;
	const [updatedValue, setUpdatedValue] = useState<number | null>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(
		() => (fg('relay-migration-issue-fields-original-estimate') ? overrideLabel(name) : name),
		[name, overrideLabel],
	);

	const isFieldEditable = fg('relay-migration-issue-fields-original-estimate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => overrideIsEditable(isEditable), [isEditable, overrideIsEditable])
		: isEditable;

	// #region Action
	const handleSubmit = useCallback(
		(value: number | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						originalEstimate: {
							timeInSeconds: value || 0,
						},
					},
				},
				onCompleted: (response: OriginalEstimateMutationData) => {
					if (response.jira?.updateTimeTrackingField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateTimeTrackingField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								originalEstimate: {
									timeInSeconds: value,
								},
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, uniqueFieldId],
	);

	const actions = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		updatedValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
	});

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		invalidMessage: invalidMessageFromActions,
		isEditing,
	} = actions;

	// #region View
	const renderReadView = useCallback(
		() => <OriginalEstimateReadView fragmentRef={data} enableHover={isFieldEditable} />,
		[data, isFieldEditable],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): OriginalEstimateEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		initialTimeInSeconds: updatedValue,
		menuPosition,
		menuPortalTarget,
		spacing,
		onChange: handleChange,
		invalidMessage,
		isInvalid: Boolean(invalidMessageFromActions),
		timeTrackingSettingsfragmentRef: null,
		onChangeInvalidMessage: setInvalidMessage,
	});

	const onConfirm = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			if (!invalidMessage) {
				handleConfirm(analyticsEvent);
			}
		},
		[invalidMessage, handleConfirm],
	);

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={OriginalEstimateEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessageFromActions}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={onConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
		/>
	);
};

export const OriginalEstimateInlineEditViewNew = ({
	fragmentRef,
	...props
}: OriginalEstimateInlineEditViewProps) => {
	const data = useFragment<OriginalEstimateFragment>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewNew_fragmentRef on JiraTimeTrackingField {
				...originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	return (
		<OriginalEstimateInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data?.fieldConfig?.isEditable ?? false}
		/>
	);
};

export const OriginalEstimateInlineEditView = componentWithFG(
	'jsc_inline_editing_field_refactor',
	OriginalEstimateInlineEditViewNew,
	OriginalEstimateInlineEditViewOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStyles : undefined),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',

	lineHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const EditViewContainer = styled.div({
	zIndex: 300,
	position: 'relative',
});
