import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import memoizeOne from 'memoize-one';
import { isFedRamp } from '@atlassian/atl-context';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	FieldConfiguration,
	IssueFieldConfigurationType,
} from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import {
	FORGE_CONTEXT_TYPE,
	layoutContainerItemTypes,
	type LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import { ISSUE_FIELD_SPOTLIGHT_PREFIX } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import { getForgeFieldType } from '@atlassian/jira-issue-view-common-utils/src/layout/index.tsx';
import {
	customFieldsToDisplay,
	ecosystemCustomFields,
	specialFields,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import type {
	CheckedLegacyFieldTypeDefinitions,
	CheckFieldTypeDefinitions,
	IgnoredFieldCheck,
} from '@atlassian/jira-platform-field-config/src/common/types/checked-fields.tsx';
import {
	AFFECTS_VERSIONS_TYPE,
	APPROVALS_CF_TYPE,
	APPROVERS_LIST_CF_TYPE,
	ASSET_ONBOARDING_TYPE,
	ASSETS_CF_TYPE,
	ASSIGNEE_TYPE,
	BASELINE_END_CF_TYPE,
	BASELINE_START_CF_TYPE,
	CASCADING_SELECT_CF_TYPE,
	CATEGORY_TYPE,
	CHAT_CHANNEL_TYPE,
	CMDB_OBJECT_CF_TYPE,
	COLOR_CF_TYPE,
	COMPONENTS_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DESCRIPTION_TYPE,
	DEV_SUMMARY_TYPE,
	DUE_DATE_TYPE,
	ECOSYSTEM_CONTEXT_TYPE,
	ECOSYSTEM_GLANCE_TYPE,
	ENVIRONMENT_TYPE,
	EPIC_NAME_TYPE,
	EPIC_STATUS_TYPE,
	EPIC_TYPE,
	FIX_VERSIONS_TYPE,
	FLAGGED_CF_TYPE,
	FORGE_CUSTOM_FIELD_TYPE,
	FORGE_GLANCE_TYPE,
	GROUP_CF_TYPE,
	ISSUE_FIELD_DATETIME_CF_TYPE,
	ISSUE_FIELD_GROUPS_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	ISSUE_FIELD_NUMBER_CF_TYPE,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
	ISSUE_FIELD_STRINGS_CF_TYPE,
	ISSUE_FIELD_USER_CF_TYPE,
	KNOWLEDGE_BASE_TYPE,
	JOURNEY_PANEL_TYPE,
	SENTIMENT_TYPE,
	LABELS_CF_TYPE,
	LABELS_TYPE,
	LINKED_ALERTS_TYPE,
	MAJOR_INCIDENT_CF_TYPE,
	MANAGE_STAKEHOLDERS_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MSTEAMS_CHANNEL_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	NUMBER_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	PARENT_CF_TYPE,
	PARENT_FIELD_TYPE,
	PARTICIPANTS_CF_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	PROJECT_PICKER_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	READ_ONLY_CF_TYPE,
	RELEASES_TYPE,
	REPORTER_TYPE,
	REQUEST_FEEDBACK_CF_TYPE,
	REQUEST_LANGUAGE_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	REQUEST_TYPE_CF_TYPE,
	RESPONDERS_CF_TYPE,
	SELECT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	SLA_PANEL_TYPE,
	SPRINT_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	STORY_POINTS_TYPE,
	TEAM_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	TIME_TRACKING_TYPE,
	URL_CF_TYPE,
	USER_CF_TYPE,
	VERSION_CF_TYPE,
	ZOOM_MEETING_TYPE,
	ENTITLEMENT_CF_TYPE,
	SENTIMENT_CF_TYPE,
	GOALS_CF_TYPE,
	ORGANIZATION_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export type ContextItem = {
	type: string;
	id: string;
};

// Convenient feature flagging point for adding or filtering
export const getPanels = (): string[] => [
	ASSET_ONBOARDING_TYPE,
	DEV_SUMMARY_TYPE,
	KNOWLEDGE_BASE_TYPE,
	...(fg('jsm-journey-builder-m1') ? [JOURNEY_PANEL_TYPE] : []),
	SENTIMENT_TYPE,
	SLA_PANEL_TYPE,
	LINKED_ALERTS_TYPE,
	...(isFedRamp() ? [] : [CHAT_CHANNEL_TYPE]),
	...(isFedRamp() ? [] : [MSTEAMS_CHANNEL_TYPE]),
	...(isFedRamp() ? [] : [ZOOM_MEETING_TYPE]),
	MANAGE_STAKEHOLDERS_TYPE,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) =>
	isNil(value) ||
	(isArray(value) && value.length === 0) ||
	(isPlainObject(value) && Object.keys(value).length === 0) ||
	(isPlainObject(value) &&
		value.type === 'doc' &&
		value.content &&
		Object.keys(value.content).length === 0);

const atlassianSystemCFTypes = () => {
	const customSystemFields = specialFields();
	return [
		...customFieldsToDisplay().filter((cf) => !ecosystemCustomFields.includes(cf)),
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'SpecialFieldTypes'.
		...Object.keys(customSystemFields).map((key) => customSystemFields[key]),
		FLAGGED_CF_TYPE,
	] as const;
};

export const getAtlassianCustomFieldType = (custom: string | null): string | undefined =>
	atlassianSystemCFTypes().find((type) => type === custom);

export const fieldTypeHandler = (
	fieldConfig: FieldConfiguration<unknown>,
	layoutItem: LayoutContainerTemplateItem,
): ContextItem => {
	const id = getLayoutItemId(layoutItem);
	const { type } = layoutItem;

	if (
		type === layoutContainerItemTypes.tab ||
		type === ECOSYSTEM_GLANCE_TYPE ||
		type === FORGE_GLANCE_TYPE ||
		type === ECOSYSTEM_CONTEXT_TYPE ||
		type === FORGE_CONTEXT_TYPE
	) {
		return {
			type,
			id,
		};
	}

	if (getPanels().includes(id)) {
		return { id, type: id };
	}

	const forgeCustomField = getForgeFieldType(fieldConfig);

	if (forgeCustomField !== null) {
		return {
			type: forgeCustomField,
			id,
		};
	}

	const fieldSchema = fieldConfig?.schema || {};
	const systemType = fieldSchema.system !== null ? fieldSchema.system : '';

	return {
		type: getAtlassianCustomFieldType(fieldSchema.custom) || systemType || fieldSchema.type,
		id,
	};
};

export const getFieldType = (
	field?: Field | FieldConfiguration<unknown> | null,
): string | undefined => {
	const fieldSchema = field ? field.schema : undefined;
	return fieldSchema
		? getAtlassianCustomFieldType(fieldSchema.custom) || fieldSchema.system || fieldSchema.type
		: undefined;
};

/**
 * We can't directly identify `Affected services`, `Change risk` or `Impact`, but we can
 * minimise the number of extra targets by wrapping only 'select' type fields since the above
 * all fit that criteria. `Description` fortunately has a custom type to identify it
 */
const fieldsWithGlobalTargets: string[] = [
	DESCRIPTION_TYPE,
	SELECT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
];

/**
 * @deprecated Adds references to layout items for fields that allow global spotlight targeting.
 * @param items An array of layout items to check to see if it should add a 'globalRef'
 * @param issueFieldsConfig The field configuration of the issue
 * @returns An optionally decorated array of the passed items with `globalRef`
 * @see <GlobalSpotlightTargetDeprecated /> from `@atlassian/jira-servicedesk-common`
 */
export const addGlobalRefToLayoutContainerTemplateItems = <T extends LayoutContainerTemplateItem>(
	// Use generic to constrain return type, e.g., passing ContextPanelItem should not return LayoutContainerTemplateItem
	items?: T[],
	issueFieldsConfig?: IssueFieldConfigurationType['value'],
) => {
	if (!items || !issueFieldsConfig) {
		return [];
	}

	return items.map((item) => {
		const { type, id } = fieldTypeHandler(issueFieldsConfig[getLayoutItemId(item)], item);
		if (fieldsWithGlobalTargets.includes(type)) {
			return {
				...item,
				globalRef: `${ISSUE_FIELD_SPOTLIGHT_PREFIX}${id}`,
			};
		}
		return item;
	});
};

// Assert that every 'checked' issue field has either been explicitly flagged
// ignored or otherwise has been implemented. In this way authors of new
// fields are made aware of every field context which must be supported.
//
// See the doc/playbooks/issue-fields.md for more information.
//
// Fields declared ignored suggest that it does not have a JSON definition and
// will instead be rendered using the backend-generated field HTML. Any new
// fields added here should be done so temporarily and an issue created to
// promote them to BUILTIN_COLUMN_CONFIGURATIONS.
type IgnoredFieldChecks<T> = {
	['com.atlassian.jconnect.jconnect-plugin:location']: IgnoredFieldCheck<T>;
	['com.atlassian.jconnect.jconnect-plugin:uuid']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:firstresponsedate']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:timeinstatus']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:flagged']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:LastCommentDate']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:assigneedomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:attachments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:comments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:dayslastcommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastupdateorcommenter']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastusercommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:reporterdomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:userproperty']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-request-feedback-date']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-sla-field']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-color']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-lexo-rank']: IgnoredFieldCheck<T>;
	aggregateprogress: IgnoredFieldCheck<T>;
	aggregatetimeestimate: IgnoredFieldCheck<T>;
	aggregatetimeoriginalestimate: IgnoredFieldCheck<T>;
	attachment: IgnoredFieldCheck<T>;
	comment: IgnoredFieldCheck<T>;
	created: IgnoredFieldCheck<T>;
	creator: IgnoredFieldCheck<T>;
	issuekey: IgnoredFieldCheck<T>;
	issuelinks: IgnoredFieldCheck<T>;
	issuerestriction: IgnoredFieldCheck<T>;
	issuetype: IgnoredFieldCheck<T>;
	lastViewed: IgnoredFieldCheck<T>;
	progress: IgnoredFieldCheck<T>;
	project: IgnoredFieldCheck<T>;
	resolution: IgnoredFieldCheck<T>;
	resolutiondate: IgnoredFieldCheck<T>;
	security: IgnoredFieldCheck<T>;
	status: IgnoredFieldCheck<T>;
	statuscategorychangedate: IgnoredFieldCheck<T>;
	subtasks: IgnoredFieldCheck<T>;
	summary: IgnoredFieldCheck<T>;
	timeestimate: IgnoredFieldCheck<T>;
	timespent: IgnoredFieldCheck<T>;
	updated: IgnoredFieldCheck<T>;
	votes: IgnoredFieldCheck<T>;
	watches: IgnoredFieldCheck<T>;
	worklog: IgnoredFieldCheck<T>;
	workratio: IgnoredFieldCheck<T>;
};

// This typing exists to ensure that we include fields here when adding new ones.
const getBaseSupportedItems = memoizeOne(
	(): CheckFieldTypeDefinitions<
		CheckedLegacyFieldTypeDefinitions<boolean>,
		IgnoredFieldChecks<boolean>
	> => {
		return {
			// @ts-expect-error - TS2322 - Type '{ ecosystemGlance: boolean; forgeGlance: boolean; "com.pyxis.greenhopper.jira:gh-epic-status": true; "com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect": true; assignee: true; ... 75 more ...; "com.pyxis.greenhopper.jira:gh-epic-label": true; }' is not assignable to type 'CheckFieldTypeDefinitions<CheckedLegacyFieldTypeDefinitions<boolean>, IgnoredFieldChecks<boolean>>'.
			[ECOSYSTEM_GLANCE_TYPE]: true,
			[FORGE_GLANCE_TYPE]: true,
			[FORGE_CONTEXT_TYPE]: true,
			[ECOSYSTEM_CONTEXT_TYPE]: true,
			[EPIC_STATUS_TYPE]: true,
			[CASCADING_SELECT_CF_TYPE]: true,
			[ASSIGNEE_TYPE]: true,
			[REPORTER_TYPE]: true,
			[DESCRIPTION_TYPE]: true,
			[ENVIRONMENT_TYPE]: true,
			[DUE_DATE_TYPE]: true,
			[PRIORITY_TYPE]: true,
			[LABELS_TYPE]: true,
			[COMPONENTS_TYPE]: true,
			[SPRINT_TYPE]: true,
			[STORY_POINTS_TYPE]: true,
			[EPIC_TYPE]: true,
			[FIX_VERSIONS_TYPE]: true,
			[AFFECTS_VERSIONS_TYPE]: true,
			[TIME_TRACKING_TYPE]: true,
			[TIME_ESTIMATE_TYPE]: true,
			[LABELS_CF_TYPE]: true,
			[TEXT_CF_TYPE]: true,
			[MULTI_USER_CF_TYPE]: true,
			[PARTICIPANTS_CF_TYPE]: true,
			[URL_CF_TYPE]: true,
			[NUMBER_CF_TYPE]: true,
			[STORY_POINT_ESTIMATE_CF_TYPE]: true,
			[SELECT_CF_TYPE]: true,
			[MULTI_SELECT_CF_TYPE]: true,
			[VERSION_CF_TYPE]: true,
			[TEXT_AREA_CF_TYPE]: true,
			[MULTI_VERSION_CF_TYPE]: true,
			[DATE_CF_TYPE]: true,
			[GROUP_CF_TYPE]: true,
			[MULTI_GROUP_CF_TYPE]: true,
			[USER_CF_TYPE]: true,
			[PEOPLE_CF_TYPE]: true,
			[MULTI_CHECKBOXES_CF_TYPE]: true,
			[RADIO_BUTTONS_CF_TYPE]: true,
			[DATETIME_CF_TYPE]: true,
			[TEAMS_PLATFORM_CF_TYPE]: true,
			[TEAM_CF_TYPE]: true,
			[PARENT_CF_TYPE]: true,
			[PARENT_FIELD_TYPE]: true,
			[BASELINE_START_CF_TYPE]: true,
			[BASELINE_END_CF_TYPE]: true,
			[COLOR_CF_TYPE]: true,
			[REQUEST_TYPE_CF_TYPE]: true,
			[APPROVALS_CF_TYPE]: true,
			[REQUEST_LANGUAGE_CF_TYPE]: true,
			[REQUEST_PARTICIPANTS_CF_TYPE]: true,
			[ORGANIZATIONS_CF_TYPE]: true,
			[ISSUE_FIELD_NUMBER_CF_TYPE]: true,
			[ISSUE_FIELD_STRING_CF_TYPE]: true,
			[ISSUE_FIELD_SINGLE_SELECT_CF_TYPE]: true,
			[ISSUE_FIELD_MULTI_SELECT_CF_TYPE]: true,
			[ISSUE_FIELD_DATETIME_CF_TYPE]: true,
			[ASSETS_CF_TYPE]: true,
			[SERVICE_ENTITY_CF_TYPE]: true,
			[APPROVERS_LIST_CF_TYPE]: true,
			[CMDB_OBJECT_CF_TYPE]: true,
			[REQUEST_FEEDBACK_CF_TYPE]: true,
			[ISSUE_FIELD_USER_CF_TYPE]: true,
			[ISSUE_FIELD_GROUPS_CF_TYPE]: true,
			[ISSUE_FIELD_STRINGS_CF_TYPE]: true,
			[READ_ONLY_CF_TYPE]: true,
			[PROJECT_PICKER_CF_TYPE]: true,
			[FORGE_CUSTOM_FIELD_TYPE]: true,
			[KNOWLEDGE_BASE_TYPE]: true,
			[JOURNEY_PANEL_TYPE]: !__SERVER__ && fg('jsm-journey-builder-m1'),
			[SLA_PANEL_TYPE]: true,
			[SENTIMENT_TYPE]: true,
			[DEV_SUMMARY_TYPE]: true,
			[RELEASES_TYPE]: true,
			[ASSET_ONBOARDING_TYPE]: true,
			[LINKED_ALERTS_TYPE]: true,
			[CHAT_CHANNEL_TYPE]: !__SERVER__ && !isFedRamp(),
			[MSTEAMS_CHANNEL_TYPE]: !__SERVER__ && !isFedRamp(),
			[ZOOM_MEETING_TYPE]: !__SERVER__ && !isFedRamp(),
			[MANAGE_STAKEHOLDERS_TYPE]: !__SERVER__,
			[RESPONDERS_CF_TYPE]: true,
			[MAJOR_INCIDENT_CF_TYPE]: true,
			[MESSAGE_EDIT_CF_TYPE]: true,
			[MESSAGE_VIEW_CF_TYPE]: true,
			[EPIC_NAME_TYPE]: true,
			[CATEGORY_TYPE]: true,
			[ENTITLEMENT_CF_TYPE]: true,
			[SENTIMENT_CF_TYPE]: true,
			[GOALS_CF_TYPE]: true,
			[ORGANIZATION_CF_TYPE]: true,
		};
	},
);

/**
 * Certain fields are returned from the backend response for field layout despite not being supported in the frontend
 * For example the old dev status field (typically customfield_10000) was previously filtered out by the 'legacy' DDL transformer that
 * was used prior to issue-view-layout-templates existing.
 *
 * Without verifying field support, we'll get unexpected errors as the components don't exist in getLayoutItemMap()
 * Due to this we do some explicit checks on the field type to determine whether it is able to be rendered (supported)
 * inside issue-view-layout templates.
 *
 * @param type The field type, eg 'com.pyxis.greenhopper.jira:gh-epic-label'
 * @returns true if the field is not supported to be displayed in issue-view-layout templates
 */
// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CheckFieldTypeDefinitions<CheckedLegacyFieldTypeDefinitions<boolean>, IgnoredFieldChecks<boolean>>'.
export const isUnsupportedItem = (type: string) => !getBaseSupportedItems()[type];
