/* eslint-disable @atlassian/relay/query-restriction */
import React, { useMemo, useState, type MouseEvent } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import AkTooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { TEAM_MANAGED_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import IssueTypeChanger from '@atlassian/jira-issue-type-changer/src/ui/index.tsx';
import { PermalinkButton } from '@atlassian/jira-issue-view-common/src/component/permalink-button/button/async.tsx';
import { View as NoneditableIssueType } from '@atlassian/jira-issue-view-foundation/src/noneditable-issue-type/view.tsx';
import { IssueTypeSwitcher } from '@atlassian/jira-issue-view-layout-issue-type-switcher/src/ui/index.tsx';
import type { currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue$key } from '@atlassian/jira-relay/src/__generated__/currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue.graphql';
import type { currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue$key } from '@atlassian/jira-relay/src/__generated__/currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue.graphql';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../messages.tsx';
import { IssueContainer, SmallBreadcrumbsItem } from '../styled.tsx';
import { createRelativeIssueUrl } from '../utils.tsx';

export type Props = {
	// Remove these props on ff relay-migration-issue-header-and-parent cleanup
	isSimplifiedProject?: boolean;
	isIssueTypeFieldEditable?: boolean;
	issueKey?: IssueKey;
	issueTypeName?: string;
	issueTypeIconUrl?: string;
	relativeIssueUrl?: string;
	fullIssueUrl?: string;

	onClick: (event: MouseEvent) => void;
	onCopyClick: (event: UIAnalyticsEvent) => void;
	issue?: currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue$key; // remove optional on ff relay-migration-issue-header-and-parent cleanup
};

export const CopyLink = ({
	isVisible,
	fullIssueUrl,
	onCopyClick,
}: {
	isVisible: boolean;
	fullIssueUrl: string;
	onCopyClick: (event: UIAnalyticsEvent) => void;
}) => {
	const { formatMessage } = useIntl();

	const label = formatMessage(
		expVal('issue-terminology-refresh-m1-fade', 'isIssueTermFaded', false)
			? messages.copyLink
			: messages.copy,
	);

	return (
		<PermalinkButton
			id="BreadcrumbCurrentIssue"
			label={label}
			fullIssueUrl={fullIssueUrl}
			// @ts-expect-error the type definition expects (AnalyticsEvent) but instead receives (SyntheticEvent, AnalyticsEvent)
			onCopyClick={onCopyClick}
			isVisible={isVisible}
		/>
	);
};

export const BreadcrumbsWithTooltip = ({
	isSimplifiedProject: isSimplifiedProjectOld,
	issueKey: issueKeyOld,
	relativeIssueUrl: relativeIssueUrlOld,
	issue,
	onClick,
}: {
	// Remove these props on relay-migration-issue-header-and-parent cleanup
	isSimplifiedProject?: boolean;
	issueKey?: IssueKey;
	relativeIssueUrl?: string;

	issue?: currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue$key; // remove optional on ff relay-migration-issue-header-and-parent cleanup
	onClick: (event: MouseEvent) => void;
}) => {
	// I needed to add 'parent' to fieldsById because we expect all fields on the same parent with the name fieldsById to have the same argument values (issue-parent-switcher)
	const data = fg('relay-migration-issue-header-and-parent')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFragment<currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue$key>(
				graphql`
					fragment currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue on JiraIssue {
						key
						webUrl
						summaryField {
							text
						}
						fieldsById(ids: ["parent", "project"]) {
							edges {
								node {
									__typename
									... on JiraProjectField {
										project {
											projectStyle
										}
									}
								}
							}
						}
					}
				`,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				issue!,
			)
		: undefined;

	// TODO replace this with relay
	// eslint-disable-next-line no-nested-ternary
	const issueIdOld = fg('relay-migration-issue-header-and-parent')
		? undefined
		: fg('relay-migration-issue-fields-summary')
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useIssueId()
			: undefined;
	const [{ value: summaryValue }] = fg('relay-migration-issue-header-and-parent')
		? [{ value: '' }]
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useSummaryField({ issueKey: issueKeyOld || '', issueId: issueIdOld || '' });
	const breadCrumbsTooltip = useMemo(
		() =>
			fg('relay-migration-issue-header-and-parent')
				? data?.summaryField?.text || ''
				: summaryValue || '',
		[data?.summaryField?.text, summaryValue],
	);

	const isSimplifiedProject = useMemo(() => {
		if (fg('relay-migration-issue-header-and-parent')) {
			const project = data?.fieldsById?.edges
				?.map((edge) =>
					edge?.node?.__typename === 'JiraProjectField' ? edge?.node?.project : null,
				)
				.filter(Boolean)[0];
			return project?.projectStyle === TEAM_MANAGED_PROJECT;
		}
		return isSimplifiedProjectOld;
	}, [data?.fieldsById?.edges, isSimplifiedProjectOld]);
	const issueKey = fg('relay-migration-issue-header-and-parent') ? data?.key : issueKeyOld;
	const relativeIssueUrl = fg('relay-migration-issue-header-and-parent')
		? createRelativeIssueUrl(data?.webUrl)
		: relativeIssueUrlOld;
	return isSimplifiedProject ? (
		<AkTooltip
			content={`${issueKey}: ${breadCrumbsTooltip}`}
			position="top"
			delay={0}
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.issue-base.foundation.breadcrumbs.current-issue.tooltip"
		>
			<BreadcrumbsItem
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.foundation.breadcrumbs.current-issue.item"
				key="issueKeyBreadcrumbItem"
				href={relativeIssueUrl}
				onClick={onClick}
				text={issueKey || ''}
				target="_blank"
				component={SmallBreadcrumbsItem}
				aria-current="page"
			/>
		</AkTooltip>
	) : (
		<div role="presentation">
			<BreadcrumbsItem
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.foundation.breadcrumbs.current-issue.item"
				key="issueKeyBreadcrumbItem"
				href={relativeIssueUrl}
				onClick={onClick}
				text={issueKey || ''}
				target="_blank"
				component={SmallBreadcrumbsItem}
				aria-current="page"
			/>
		</div>
	);
};

export const BreadcrumbCurrentIssue = ({
	isSimplifiedProject: isSimplifiedProjectOld = false,
	isIssueTypeFieldEditable = false,
	issueKey = toIssueKey(''),
	issueTypeName = '',
	issueTypeIconUrl = '',
	relativeIssueUrl = '',
	fullIssueUrl = '',
	onClick = noop,
	onCopyClick = noop,
	issue,
}: Props) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const show = () => setIsVisible(true);
	const hide = () => setIsVisible(false);
	const data = fg('relay-migration-issue-header-and-parent')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFragment<currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue$key>(
				graphql`
					fragment currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue on JiraIssue
					@argumentDefinitions(
						issueViewRelayIssueTypeSwitcherFlag: {
							type: "Boolean!"
							provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-type-switcher.relayprovider"
						}
					) {
						key
						webUrl
						issueTypeField {
							isEditableInIssueView
							issueType {
								name
								avatar {
									xsmall
								}
							}
							...ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher
								@include(if: $issueViewRelayIssueTypeSwitcherFlag)
						}
						fieldsById(ids: ["parent", "project"]) {
							edges {
								node {
									__typename
									... on JiraProjectField {
										project {
											projectStyle
										}
									}
								}
							}
						}
						...currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue
					}
				`,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				issue!,
			)
		: undefined;

	const isSimplifiedProject = useMemo(() => {
		if (fg('relay-migration-issue-header-and-parent')) {
			const project = data?.fieldsById?.edges
				?.map((edge) =>
					edge?.node?.__typename === 'JiraProjectField' ? edge?.node?.project : null,
				)
				.filter(Boolean)[0];
			return project?.projectStyle === TEAM_MANAGED_PROJECT;
		}
		return isSimplifiedProjectOld;
	}, [data?.fieldsById?.edges, isSimplifiedProjectOld]);

	const issueTypeSwitcherComponent = useMemo(
		() =>
			data?.issueTypeField && fg('relay-migration-issue-header-and-parent') ? (
				<IssueTypeSwitcher key="issueTypeSwitcher" fragmentKey={data.issueTypeField} />
			) : (
				<IssueTypeChanger
					key="issueTypeChanger"
					icon={
						<AsyncIcon
							url={
								fg('relay-migration-issue-header-and-parent')
									? data?.issueTypeField?.issueType?.avatar?.xsmall
									: issueTypeIconUrl
							}
							alt={
								fg('relay-migration-issue-header-and-parent')
									? data?.issueTypeField?.issueType?.name
									: issueTypeName
							}
						/>
					}
				/>
			),
		[data?.issueTypeField, issueTypeIconUrl, issueTypeName],
	);
	return (
		<IssueContainer
			onMouseLeave={hide}
			onMouseEnter={show}
			onFocus={show}
			onBlur={hide}
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.foundation.breadcrumbs.breadcrumb-current-issue-container"
			fixExtraDivider
			role="listitem"
		>
			{(
				fg('relay-migration-issue-header-and-parent')
					? data?.issueTypeField?.isEditableInIssueView
					: isIssueTypeFieldEditable
			) ? (
				issueTypeSwitcherComponent
			) : (
				<NoneditableIssueType
					issueTypeName={
						fg('relay-migration-issue-header-and-parent')
							? data?.issueTypeField?.issueType?.name || ''
							: issueTypeName
					}
					issueTypeIconUrl={
						fg('relay-migration-issue-header-and-parent')
							? data?.issueTypeField?.issueType?.avatar?.xsmall || ''
							: issueTypeIconUrl
					}
				/>
			)}
			<BreadcrumbsWithTooltip
				isSimplifiedProject={isSimplifiedProject}
				issueKey={fg('relay-migration-issue-header-and-parent') ? data?.key : issueKey}
				relativeIssueUrl={
					fg('relay-migration-issue-header-and-parent')
						? createRelativeIssueUrl(data?.webUrl)
						: relativeIssueUrl
				}
				onClick={onClick}
				issue={data}
			/>
			<CopyLink
				isVisible={isVisible}
				fullIssueUrl={
					fg('relay-migration-issue-header-and-parent') ? data?.webUrl || '' : fullIssueUrl
				}
				onCopyClick={onCopyClick}
			/>
		</IssueContainer>
	);
};

export default BreadcrumbCurrentIssue;
