import React, { useMemo } from 'react';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	ISSUE_PANEL_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import type { IssuePanel as IssuePanelType } from '@atlassian/jira-forge-issue-panel/src/ui/IssuePanel.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { LazyIssueViewForgeExtension } from '@atlassian/jira-forge-issue-view-base/src/ui/async/index.tsx';
import { Skeleton } from '@atlassian/jira-forge-ui-async/src/common/ui/skeleton/main.tsx';
import type {
	LazyComponentProps,
	LazyRenderer,
} from '@atlassian/jira-forge-ui-async/src/types.tsx';

const skeletonConfig = [{ width: 100 }, { width: 75 }];

export const LazyIssuePanelRenderer: LazyRenderer<typeof IssuePanelType> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint<LazyRenderer<typeof IssuePanelType>>(
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-issue-panel" */ '@atlassian/jira-forge-issue-panel/src/ui/IssuePanel.tsx'
			),
		{ ssr: false },
	),
);

export const LazyIssuePanel = (props: LazyComponentProps<typeof IssuePanelType>) => {
	const { extension, extensionData, setCustomActions, ...restProps } = props;

	const loadingComponent = useMemo(() => <Skeleton skeletonConfig={skeletonConfig} />, []);
	const extensionDataValue = useMemo(
		() => ({
			...extensionData,
			type: ISSUE_PANEL_MODULE,
		}),
		[extensionData],
	);
	const extraProps = useMemo(
		() => ({
			loadingComponent,
			module: ISSUE_PANEL_MODULE,
			extensionData: extensionDataValue,
			extension,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extension, extensionDataValue, loadingComponent],
	);

	if (isNativeUiExtension(extension) || isCustomUIExtension(extension)) {
		return (
			<Placeholder name="issue-panel" fallback={loadingComponent}>
				<LazyIssueViewForgeExtension {...restProps} {...extraProps} />
			</Placeholder>
		);
	}

	return (
		<Placeholder name="issue-panel" fallback={loadingComponent}>
			<LazyIssuePanelRenderer {...props} {...extraProps} />
		</Placeholder>
	);
};

export const AsyncIssuePanel = LazyIssuePanel;
