import { defineMessages } from '@atlassian/jira-intl';

export const PlaybookPanelMessages = defineMessages({
	playbooks: {
		id: 'jira.playbooks.agent-view.playbooks-panel.playbooks.non-final',
		defaultMessage: 'Playbooks',
		description: 'Header for the playbooks section in issue view',
	},
	description: {
		id: 'jira.playbooks.agent-view.playbooks-panel.description.non-final',
		defaultMessage:
			'Guides that help resolve incidents by consolidating relevant data, actions, and documents.',
		description: 'Description for the playbooks section in issue view',
	},
	new: {
		id: 'jira.playbooks.agent-view.playbooks-panel.new.non-final',
		defaultMessage: 'New',
		description: 'Lozenge to indicate the new feature',
	},
	errorViewDescription: {
		id: 'jira.playbooks.agent-view.playbooks-panel.error-view-description.non-final',
		defaultMessage:
			'There was an error in fetching the playbooks for this issue. Please refresh and try again',
		description: 'Description for error view',
	},
});
