import React from 'react';
import { styled } from '@compiled/react';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import AKTooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueTypeField } from '@atlassian/jira-issue-field-issue-type/src/services/index.tsx';

const Header = () => {
	const issueKey = useIssueKey();
	const [{ value: fieldType }] = useIssueTypeField({ issueKey });

	return (
		<Wrapper>
			<AKTooltip content={fieldType?.name || ''} position="left">
				<AsyncIcon alt={fieldType?.name || ''} url={fieldType?.iconUrl} />
			</AKTooltip>
			<IssueKey>{issueKey}</IssueKey>
		</Wrapper>
	);
};

export default Header;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexShrink: 0,
	paddingTop: token('space.050', '4px'),
	paddingRight: 0,
	paddingBottom: token('space.100', '8px'),
	paddingLeft: 0,
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKey = styled.span({
	marginTop: 0,
	marginRight: 0,
	marginBottom: token('space.075', '6px'),
	marginLeft: token('space.075', '6px'),
	color: token('color.text.subtlest', N200),
});
