import React, { useEffect, useState } from 'react';
import ClockIcon from '@atlaskit/icon/core/clock';
import WarningIcon from '@atlaskit/icon/core/warning';
import { Flex, xcss, Text, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import { getNoOfDaysSinceUTCDateString } from '@atlassian/jira-issue-view-common/src/utils/date-utils/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type DateWithUrgencyIndicatorProps = {
	value: string;
	formattedDateString?: string | null;
};

export const DateWithUrgencyIndicator = ({
	value,
	formattedDateString,
}: DateWithUrgencyIndicatorProps) => {
	const { formatMessage } = useIntl();
	const { formatDateOnly } = useDateTimeFormatter();
	const [daysBetweenTodayAndValue, setDaysBetweenTodayAndValue] = useState<number | null>(null);

	useEffect(() => {
		setDaysBetweenTodayAndValue(getNoOfDaysSinceUTCDateString(value));
	}, [value]);

	const formattedDate = formattedDateString || formatDateOnly(value);

	const dueMessage =
		daysBetweenTodayAndValue === 0 ? (
			formatMessage(messages.dueToday)
		) : (
			<FormattedMessage {...messages.dueIn} values={{ daysBetween: daysBetweenTodayAndValue }} />
		);

	const isDatePast = daysBetweenTodayAndValue != null && daysBetweenTodayAndValue >= 0;
	const Icon = daysBetweenTodayAndValue === 0 ? ClockIcon : WarningIcon;

	return (
		<Flex alignItems="center" testId="date-with-urgency-indicator.container">
			{isVisualRefreshEnabled() ? (
				<Box xcss={[baseStyles, isDatePast ? redBackgroundStyle : false]}>
					<Text color={isDatePast ? 'color.text.accent.red' : 'color.text.accent.gray.bolder'}>
						{formattedDate}
					</Text>
				</Box>
			) : (
				<Box xcss={[baseStyles, isDatePast ? redBackgroundStyle : greyBackgroundStyle]}>
					<Text color={isDatePast ? 'color.text.accent.red' : 'color.text.accent.gray.bolder'}>
						{formattedDate}
					</Text>
				</Box>
			)}
			{isDatePast && (
				<Tooltip content={dueMessage} position="top">
					<Icon
						LEGACY_size="medium"
						label=""
						color={token('color.icon.danger')}
						testId="date-with-urgency-indicator.warning-icon"
					/>
				</Tooltip>
			)}
		</Flex>
	);
};

const baseStyles = xcss({
	padding: 'space.025',
	borderRadius: '3px',
	marginRight: 'space.075',
	minWidth: '92px',
});

const redBackgroundStyle = xcss({
	backgroundColor: 'color.background.accent.red.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.red.subtlest.hovered',
	},
});

const greyBackgroundStyle = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	},
});
