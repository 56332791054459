import React, { Component, type FocusEvent, type RefObject } from 'react';
import noop from 'lodash/noop';
import { v4 as uuid } from 'uuid';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { CreatableSelect } from '@atlaskit/select';
import type { AtlaskitSelectRefType } from '@atlaskit/select/types';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import type {
	ActionMeta,
	Props as AkSelectProps,
} from '@atlassian/jira-common-components-picker/src/model.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import { memoizedHashString } from '@atlassian/jira-issue-analytics/src/utils/hash-attribute/index.tsx';
import { fireUIAnalytics, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import SelectWithFooter from '../select-with-footer/index.tsx';
import type { Options, Option, Group } from '../types.tsx';
import {
	filterOptions,
	flattenOptions,
	flattenSelectedOptions,
	getOptionValuesLengths,
	getSafeOptionsForAnalytics,
	getOptionIdsForAnalytics,
	getSafeValue,
} from './utils.tsx';

export type Props = {
	canCreateNewItem: boolean;
} & AkSelectProps<Option | Group> & {
		fieldId: string;
		options: Options;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		createAnalyticsEvent: (payload?: any) => UIAnalyticsEvent;
		additionalAnalyticsProps?: {
			isEpicLink?: boolean;
			source?: string;
			isRemote?: boolean;
		};
		optionValuesSafeForAnalytics?: boolean; // if values are safe to be added to analytics attributes (like ids),
		onChange: (
			selectedOption: Option | Option[] | null,
			actionMeta: ActionMeta<Option>,
			sessionId?: string,
		) => void;
		onInputChange: (value: string, actionMeta: ActionMeta<Option>, sessionId?: string) => void;
		onFocus: (event: FocusEvent<HTMLElement>, sessionId?: string) => void;
		selectRef?: RefObject<AtlaskitSelectRefType>;
	};

const analyticsProps = {
	onChange: 'valueChanged',
	onInputChange: 'inputChanged',
	onMenuOpen: 'menuOpened',
	onMenuClose: 'menuClosed',
} as const;

const SelectWithFooterAnalytics = ComponentWithAnalytics(
	'select',
	analyticsProps,
)(SelectWithFooter);

const CreatableSelectWithAnalytics = ComponentWithAnalytics(
	'select',
	analyticsProps,
)(CreatableSelect);

const fakeAction = {
	action: 'fake-action',
} as const;

export const ANALYTICS_PAYLOAD_TRUNCATION_SIZE = 20;

// eslint-disable-next-line jira/react/no-class-components
export class SelectWithAnalyticsView extends Component<Props> {
	static defaultProps = {
		isLoading: false,
		createAnalyticsEvent: noop,
		optionValuesSafeForAnalytics: false,
		onChange: noop,
		onInputChange: noop,
		onMenuOpen: noop,
		onMenuClose: noop,
	};

	constructor(props: Props) {
		super(props);

		this.flatOptions = flattenOptions(this.props.options);
		// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
		this.isOpened = props.menuIsOpen;
		if (this.isOpened) {
			this.startSession();
			this.fireInputChangedEvent('', fakeAction, this.props.createAnalyticsEvent({}));
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.options !== prevProps.options) {
			this.flatOptions = flattenOptions(this.props.options);
		}
	}

	componentWillUnmount() {
		this.endSession();
	}

	getDefaultAnalyticsAttributes = (query: string) => {
		const { fieldId, value, isLoading, optionValuesSafeForAnalytics } = this.props;

		const queryAttributes = {
			queryLength: query.length,
			queryHash: query === '' ? query : memoizedHashString(query),
		};
		const selectedOptions = flattenSelectedOptions(value);

		let filteredOptions = filterOptions(this.flatOptions, query);
		const optionsLength = filteredOptions.length;

		// truncate to reduce payload size
		filteredOptions = filteredOptions.slice(0, ANALYTICS_PAYLOAD_TRUNCATION_SIZE);
		const safeFilteredOptions = getSafeOptionsForAnalytics(
			filteredOptions,
			optionValuesSafeForAnalytics,
		);

		return {
			fieldId,
			componentName: 'select',
			packageName: '@atlaskit/select',
			options: safeFilteredOptions,
			optionsLength,
			optionsLengths: getOptionValuesLengths(filteredOptions),
			optionIds: getOptionIdsForAnalytics(filteredOptions),
			selectedOptions: getSafeOptionsForAnalytics(selectedOptions, optionValuesSafeForAnalytics),
			selectedOptionsLengths: getOptionValuesLengths(selectedOptions),
			selectedOptionIds: getOptionIdsForAnalytics(selectedOptions),
			sessionId: this.sessionId,
			truncationSize: ANALYTICS_PAYLOAD_TRUNCATION_SIZE,
			isLoading,
			...queryAttributes,
			optionValuesSafeForAnalytics,
			...this.props.additionalAnalyticsProps,
		};
	};

	getItemOrder = (selectedItem: Option) => {
		const matchedIndexes: Array<number> = [];
		this.flatOptions.forEach((option, index) => {
			const filterValues = option.filterValues || [];
			// @ts-expect-error - TS2345 - Argument of type 'Value' is not assignable to parameter of type 'string'.
			if (option.value === selectedItem.value || filterValues.includes(selectedItem.value)) {
				matchedIndexes.push(index);
			}
		});

		return matchedIndexes.length > 0 ? matchedIndexes[0] : -1;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fireAnalyticsEvent(event: UIAnalyticsEvent, query: string, extraAttributes: any = {}) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const attributes: any = {
			...this.getDefaultAnalyticsAttributes(query),
			...extraAttributes,
		};
		fireUIAnalytics(event, attributes);
	}

	fireTrackAnalyticsEvent(
		analyticsEvent: UIAnalyticsEvent,
		action: string,
		actionSubject: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		extraAttributes?: any,
	) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const attributes: any = {
			...this.getDefaultAnalyticsAttributes(this.query),
			...extraAttributes,
		};
		fireTrackAnalytics(analyticsEvent, `${actionSubject} ${action}`, attributes);
	}

	startSession = () => {
		this.sessionId = uuid();
		this.sessionStartedAt = performance.now();
		this.fireTrackAnalyticsEvent(
			this.props.createAnalyticsEvent({ action: 'started' }),
			'started',
			'optionSelectionSession',
		);
	};

	endSession = () => {
		if (this.sessionId) {
			const elapsedTime = this.sessionStartedAt ? performance.now() - this.sessionStartedAt : 0;
			this.fireTrackAnalyticsEvent(
				this.props.createAnalyticsEvent({ action: 'ended' }),
				'ended',
				'optionSelectionSession',
				{ elapsedTime, ...this.props.additionalAnalyticsProps },
			);
		}
		this.sessionStartedAt = 0;
		this.sessionId = undefined;
	};

	flatOptions: Option[] = [];

	sessionId: string | undefined = undefined;

	queryAtSetValue = '';

	query = '';

	sessionStartedAt = 0;

	isOpened = false;

	fireInputChangedEvent = (
		query: string,
		actionMeta: ActionMeta<Option>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		if (actionMeta.action === 'set-value') {
			// save query for option changed analytic
			this.queryAtSetValue = this.query;
		}
		this.query = query;

		analyticsEvent.update({
			action: 'changed',
			actionSubject: 'filter',
		});
		this.fireAnalyticsEvent(analyticsEvent, this.query);
		return this.sessionId;
	};

	restartSession = () => {
		this.endSession();
		if (this.props.menuIsOpen) {
			// keep opened
			this.isOpened = true;
			this.startSession();
		}
	};

	fireOptionCreatedEvent = (option: Option, analyticsEvent: UIAnalyticsEvent) => {
		analyticsEvent.update({
			action: 'created',
			actionSubject: 'option',
		});
		const oldSessionId = this.sessionId;

		this.restartSession();

		this.fireAnalyticsEvent(analyticsEvent, this.queryAtSetValue, {
			selectedOption: getSafeValue(option.value, this.props.optionValuesSafeForAnalytics),
			sessionId: oldSessionId,
		});
	};

	fireOptionRemovedEvent = (
		removedOption: Option | null | undefined,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		analyticsEvent.update({
			action: 'removed',
			actionSubject: 'option',
		});
		this.fireAnalyticsEvent(analyticsEvent, this.query, {
			removedItem: removedOption
				? getSafeValue(removedOption.value, this.props.optionValuesSafeForAnalytics)
				: undefined,
		});
	};

	fireOptionSelectedEvent = (option: Option, analyticsEvent: UIAnalyticsEvent) => {
		analyticsEvent.update({
			action: 'changed',
			actionSubject: 'option',
		});
		const oldSessionId = this.sessionId;

		this.restartSession();

		this.fireAnalyticsEvent(analyticsEvent, this.queryAtSetValue, {
			selectedOrder: this.getItemOrder(option),
			selectedOption: getSafeValue(option.value, this.props.optionValuesSafeForAnalytics),
			selectedOptionLength: option.value !== null ? `${option.value}`.length : 0,
			sessionId: oldSessionId,
		});
	};

	fireOpenChangedEvent = (
		event: {
			isOpen: boolean;
		},
		analyticsEvent: UIAnalyticsEvent,
	) => {
		if (event.isOpen && !this.isOpened) {
			this.startSession();
		}

		this.isOpened = event.isOpen;
		analyticsEvent.update({
			action: event.isOpen ? 'opened' : 'closed',
			actionSubject: 'optionSelect',
			attributes: this.getDefaultAnalyticsAttributes(this.query),
		});
		this.fireAnalyticsEvent(analyticsEvent, this.query);
	};

	onChange = (
		selectedOptionOrOptions: Option | Option[] | null,
		actionMeta: ActionMeta<Option>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const selectedOption = Array.isArray(selectedOptionOrOptions)
			? selectedOptionOrOptions[selectedOptionOrOptions.length - 1]
			: selectedOptionOrOptions;
		const oldSessionid = this.sessionId;

		switch (actionMeta.action) {
			case 'create-option':
				if (selectedOption !== null) {
					this.fireOptionCreatedEvent(selectedOption, analyticsEvent);
				}
				break;
			case 'remove-value':
			case 'pop-value':
			case 'deselect-option':
				this.fireOptionRemovedEvent(actionMeta.removedValue || actionMeta.option, analyticsEvent);
				break;
			case 'select-option':
				if (selectedOption !== null) {
					this.fireOptionSelectedEvent(selectedOption, analyticsEvent);
				}
				break;
			default:
				break;
		}

		if (this.props.onChange) {
			this.props.onChange(selectedOptionOrOptions, actionMeta, oldSessionid);
		}
	};

	onInputChange = (
		value: string,
		actionMeta: ActionMeta<Option>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { onInputChange } = this.props;

		if (actionMeta.action === 'input-change' || actionMeta.action === 'set-value') {
			this.fireInputChangedEvent(value, actionMeta, analyticsEvent);
		}

		onInputChange(value, actionMeta, this.sessionId);
	};

	onMenuOpen = (analyticsEvent: UIAnalyticsEvent) => {
		this.fireOpenChangedEvent({ isOpen: true }, analyticsEvent);

		const { onMenuOpen } = this.props;

		if (onMenuOpen) {
			onMenuOpen(analyticsEvent);
		}
	};

	onMenuClose = (analyticsEvent: UIAnalyticsEvent) => {
		this.fireOpenChangedEvent({ isOpen: false }, analyticsEvent);

		const { onMenuClose } = this.props;

		if (onMenuClose) {
			onMenuClose(analyticsEvent);
		}
	};

	onFocus = (focusEvent: FocusEvent<HTMLElement>) => {
		this.props.onFocus(focusEvent, this.sessionId);
	};

	render() {
		const { canCreateNewItem, createAnalyticsEvent, selectRef, ...restProps } = this.props;

		const SelectComponentWithAnalytics = canCreateNewItem
			? CreatableSelectWithAnalytics
			: SelectWithFooterAnalytics;

		return (
			<SelectComponentWithAnalytics
				{...restProps}
				onFocus={this.onFocus}
				// @ts-expect-error - Type '(selectedOptionOrOptions: Option | Option[] | null, actionMeta: ActionMeta<Option>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type '(selectedOption: Option | Group | readonly (Option | Group)[] | null, arg2: ActionMeta<Option | Group>, args_2: UIAnalyticsEvent) => any'.
				onChange={this.onChange}
				// @ts-expect-error - Type '(value: string, actionMeta: ActionMeta<Option>, analyticsEvent: UIAnalyticsEvent) => void' is not assignable to type '(value: string, arg2: ActionMeta<Option | Group>, args_2: UIAnalyticsEvent) => any'.
				onInputChange={this.onInputChange}
				onMenuOpen={this.onMenuOpen}
				onMenuClose={this.onMenuClose}
				{...(fg('jfp_a11y_team_link_issues_focus') && {
					...(canCreateNewItem ? { ref: selectRef } : { selectRef }),
				})}
			/>
		);
	}
}

// @ts-expect-error - Argument of type 'typeof SelectWithAnalyticsView' is not assignable to parameter of type 'ComponentType<Props> & typeof SelectWithAnalyticsView'.
export default withAnalyticsEvents()(SelectWithAnalyticsView);
