import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { createIssueAri } from '../../../../common/utils/ari/index.tsx';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import { createGetPlatformGoalsValueSelector } from '../../selectors/properties/index.tsx';
import type { Props, State } from '../../types.tsx';
import type { GoalsFieldValue } from '../../utils/field-mapping/goals/index.tsx';

export const updateIssueGoals =
	({
		fieldKey,
		localIssueId,
		goalsToLink = [],
		goalsToUnlink = [],
	}: {
		fieldKey: FieldKey;
		localIssueId: LocalIssueId;
		goalsToLink?: string[];
		goalsToUnlink?: string[];
	}): Action<State, Props> =>
	async ({ getState, setState }: StoreActionApi<State>, props: Props) => {
		if (!goalsToLink.length && !goalsToUnlink.length) {
			return;
		}

		const state = getState();
		const { issuesRemote, onIssueUpdateFailed, cloudId } = props;

		const jiraIdMap = getLocalIssueIdToJiraId(getState(), props);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const issueId = jiraIdMap[localIssueId] as IssueId;
		const goals = createGetPlatformGoalsValueSelector(fieldKey, localIssueId)(state, props);

		const currentGoals = goals || [];

		// Add new goals (merge unique values)
		const newGoals = Array.from(new Set([...currentGoals, ...goalsToLink]));
		// Remove goals to unlink
		const updatedGoals = newGoals.filter((goal) => !goalsToUnlink.includes(goal));

		const issueAri = createIssueAri(cloudId, issueId);

		// Optimistic update only for goals property
		const prevGoals = state.properties.goals?.[fieldKey]?.[localIssueId];
		setState({
			...state,
			properties: {
				...state.properties,
				goals: {
					...state.properties.goals,
					[fieldKey]: {
						...state.properties.goals?.[fieldKey],
						[localIssueId]: updatedGoals,
					},
				},
			},
			lastUpdatedIssueIds: [localIssueId],
		});

		const promises = [];
		if (goalsToLink.length) {
			const linkRelationships = goalsToLink.map((goal) => ({ from: issueAri, to: goal }));
			promises.push(issuesRemote.linkGoals({ relationships: linkRelationships }));
		}
		if (goalsToUnlink.length) {
			const unlinkRelationships = goalsToUnlink.map((goal) => ({ from: issueAri, to: goal }));
			promises.push(issuesRemote.unlinkGoals({ relationships: unlinkRelationships }));
		}

		try {
			await Promise.all(promises);
		} catch (error) {
			// Revert optimistic update only for goals property
			setState({
				...state,
				properties: {
					...state.properties,
					goals: {
						...state.properties.goals,
						[fieldKey]: {
							...state.properties.goals?.[fieldKey],
							[localIssueId]: prevGoals,
						},
					},
				},
			});

			onIssueUpdateFailed(new Error());
		}
	};

export const updateGoalsFieldValue =
	({
		fieldKey,
		localIssueId,
		newValue,
		removeValue,
	}: {
		localIssueId: LocalIssueId;
		newValue: unknown;
		removeValue: unknown;
		fieldKey: FieldKey;
	}): Action<State, Props> =>
	({ dispatch }) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const goalsToLink = newValue as GoalsFieldValue;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const goalsToUnlink = removeValue as GoalsFieldValue;

		if (newValue === undefined) {
			dispatch(
				updateIssueGoals({
					fieldKey,
					localIssueId,
					goalsToUnlink,
				}),
			);
			return;
		}

		dispatch(
			updateIssueGoals({
				fieldKey,
				localIssueId,
				goalsToLink,
				goalsToUnlink,
			}),
		);
	};
