import { useCallback, useEffect, useState } from 'react';
import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { getHasDismissedIdeasCrossflowCard } from '../utils.tsx';

type Props = {
	userPropertyCrossflowIdeasCard: string;
};

type AdControlsFunctionality = {
	isDismissed: boolean;
	dismiss: () => Promise<void>;
};
const AD_CONTROLS_FETCH_ERROR = 'adControlsFetchError';
export const useAdControlsFunctionality = ({
	userPropertyCrossflowIdeasCard: crossFlowIdeasCardProperty,
}: Props): AdControlsFunctionality => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const accountId = useAccountId() || '';
	const [isDismissed, setIsDismissed] = useState(true);

	const fetchUserProperties = useCallback(async () => {
		try {
			const res = await getHasDismissedIdeasCrossflowCard(accountId, crossFlowIdeasCardProperty);
			setIsDismissed(res);
		} catch (error) {
			setIsDismissed(true);
			let adControlsError: Error;

			if (error instanceof FetchError) {
				adControlsError = new Error(
					`Fetch Error, failed to get user property: ${crossFlowIdeasCardProperty}, status code was: ${error.statusCode}`,
				);
			} else {
				adControlsError = new Error(`Failed to get user property: ${crossFlowIdeasCardProperty}`, {
					cause: error,
				});
			}

			fireErrorAnalytics({
				meta: {
					id: AD_CONTROLS_FETCH_ERROR,
					packageName: 'issueViewJpdIdeas',
					teamName: 'galileo',
				},
				error: adControlsError,
			});
		}
	}, [accountId, crossFlowIdeasCardProperty]);

	useEffect(() => {
		if (accountId !== '') {
			fetchUserProperties();
		}
	}, [accountId, fetchUserProperties]);

	const clickedButtonBaseEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'button',
	});

	const dismiss = useCallback(async () => {
		setIsDismissed(true);
		fireUIAnalytics(clickedButtonBaseEvent, 'notInterested', { panelName: 'crossflow-idea-card' });

		try {
			setUserProperties(accountId, crossFlowIdeasCardProperty, 'true');
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: AD_CONTROLS_FETCH_ERROR,
					packageName: 'issueViewJpdIdeas',
					teamName: 'galileo',
				},
				error: new Error(`Failed to set user property: ${crossFlowIdeasCardProperty}`, {
					cause: error,
				}),
			});
		}
	}, [accountId, crossFlowIdeasCardProperty, clickedButtonBaseEvent]);

	return { isDismissed, dismiss };
};
