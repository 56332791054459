import React from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { MenuGroup, Section } from '@atlaskit/menu';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import UserItem from './user-item/index.tsx';

type Props = {
	canManageWatchers: boolean;
	users: User[];
	headerText: string;
	onDeleteUser: (
		arg1: User,
		arg2: (accountId: AccountId | null) => void,
		arg3: () => void,
		arg4: UIAnalyticsEvent,
	) => void;
	onDeleteSuccess: (accountId: AccountId | null) => void;
	onDeleteError: (error: Error) => void;
};

const UsersList = (props: Props) => {
	const { users, headerText, onDeleteUser, canManageWatchers } = props;
	const sectionContent = (
		<>
			{isVisualRefreshEnabled() && (
				<Box paddingInline="space.150" paddingBlock="space.100">
					<Text color="color.text.subtle" weight="semibold" size="UNSAFE_small">
						{headerText}
					</Text>
				</Box>
			)}
			<Container>
				{users.map((user) => (
					<UserItem
						key={user.id}
						user={user}
						onDeleteUser={onDeleteUser}
						canDelete={canManageWatchers}
						onDeleteSuccess={props.onDeleteSuccess}
						// @ts-expect-error - Type '(error: Error) => void' is not assignable to type '() => void'.
						onDeleteError={props.onDeleteError}
					/>
				))}
			</Container>
		</>
	);
	return (
		<Box xcss={watchersListContainerStyles}>
			<MenuGroup>
				{isVisualRefreshEnabled() ? (
					sectionContent
				) : (
					<Section title={headerText}>{sectionContent}</Section>
				)}
			</MenuGroup>
		</Box>
	);
};

export default UsersList;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"span[role='button']": {
		cursor: 'initial',
		paddingTop: token('space.050', '4px'),
		paddingRight: token('space.150', '12px'),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		paddingBottom: '5px',
		paddingLeft: token('space.150', '12px'),
	},
});

const watchersListContainerStyles = xcss({
	paddingBottom: 'space.100',
});
