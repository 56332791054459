/**
 * @generated SignedSource<<077f1d7d1c1e7d447bcf4b2b2437303a>>
 * @relayHash d03750769f84d002e438e5b82dc00cac
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3af7a0ddcee718c2aacf3c6c103a19e6cbdd6879023109ae4ea5f308de914ac5

import type { ConcreteRequest, Query } from 'relay-runtime';
export type detailsWorkSuggestionsDataQuery$variables = {
  cloudId: string;
  isPrMergeable: boolean;
  isPrReviewable: boolean;
  issueId: string;
};
export type detailsWorkSuggestionsDataQuery$data = {
  readonly workSuggestions: {
    readonly suggestionsByIssues: {
      readonly recentPullRequests: {
        readonly mergeableSuggestions?: ReadonlyArray<{
          readonly id: string;
          readonly isMergeActionEnabled: boolean | null | undefined;
          readonly url: string;
        }> | null | undefined;
        readonly pullRequestReviewSuggestions?: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type detailsWorkSuggestionsDataQuery = {
  response: detailsWorkSuggestionsDataQuery$data;
  variables: detailsWorkSuggestionsDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPrMergeable"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPrReviewable"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueId"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "url"
},
v6 = [
  {
    "concreteType": "WorkSuggestions",
    "kind": "LinkedField",
    "name": "workSuggestions",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "items": [
              {
                "kind": "Variable",
                "name": "issueIds.0",
                "variableName": "issueId"
              }
            ],
            "kind": "ListValue",
            "name": "issueIds"
          }
        ],
        "concreteType": "WorkSuggestionsByIssuesResponse",
        "kind": "LinkedField",
        "name": "suggestionsByIssues",
        "plural": false,
        "selections": [
          {
            "concreteType": "WorkSuggestionsPullRequestSuggestionsResponse",
            "kind": "LinkedField",
            "name": "recentPullRequests",
            "plural": false,
            "selections": [
              {
                "condition": "isPrReviewable",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "WorkSuggestionsPullRequestReviewTask",
                    "kind": "LinkedField",
                    "name": "pullRequestReviewSuggestions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "isPrMergeable",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "WorkSuggestionsPRMergeableTask",
                    "kind": "LinkedField",
                    "name": "mergeableSuggestions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "isMergeActionEnabled"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "detailsWorkSuggestionsDataQuery",
    "selections": (v6/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "detailsWorkSuggestionsDataQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": "3af7a0ddcee718c2aacf3c6c103a19e6cbdd6879023109ae4ea5f308de914ac5",
    "metadata": {},
    "name": "detailsWorkSuggestionsDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d682c9cbb2a414725e452baecae3a839";

export default node;
