import React, { useCallback, useState, useMemo } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import PriorityEditViewEntryPoint from '@atlassian/jira-issue-field-priority-editview-full/src/entrypoint.tsx';
import type {
	NullableOption,
	PriorityEditViewProps,
} from '@atlassian/jira-issue-field-priority-editview-full/src/ui/priority/types.tsx';
import { PriorityReadView } from '@atlassian/jira-issue-field-priority-readview-full/src/ui/priority/index.tsx';
import { getPriorityIdFromAri } from '@atlassian/jira-issue-view-common-utils/src/id-from-ari/index.tsx';
import type { priority_issueFieldPriority_PriorityField_Mutation as PriorityMutation } from '@atlassian/jira-relay/src/__generated__/priority_issueFieldPriority_PriorityField_Mutation.graphql';
import type { priority_issueFieldPriorityInlineEditFull_PriorityInlineEditView_fragmentRef$key as PriorityFragment } from '@atlassian/jira-relay/src/__generated__/priority_issueFieldPriorityInlineEditFull_PriorityInlineEditView_fragmentRef.graphql';
import type { priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef$key as PriorityWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef.graphql';
import type {
	PriorityInlineEditViewProps,
	PriorityInlineEditViewWithIsEditableProps,
} from './types.tsx';
import { transformOptionToAgg } from './utils.tsx';

const isEqualPriority = (a: NullableOption, b: NullableOption) => a?.value === b?.value;

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `PriorityInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [PriorityInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const PriorityInlineEditViewIsEditable = ({
	attributes,
	spacing = 'compact',
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isCompact,
	menuPosition,
	onSubmit,
	onSubmitNew,
	onSubmitFailed,
	onSubmitSucceeded,
	onSubmitSucceededNew,
	isEditable,
	readViewFitContainerHeight,
}: PriorityInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<PriorityWithIsEditableFragment>(
		graphql`
			fragment priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef on JiraPriorityField {
				...priority_issueFieldPriorityReadviewFull_PriorityReadView
				id
				fieldId
				type
				name
				priority {
					id
					name
					iconUrl
				}
			}
		`,
		fragmentRef,
	);
	const { overriding } = fg('relay-migration-issue-fields-priority')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useInlineEditFieldInjections()
		: { overriding: undefined };
	const { id: uniqueFieldId, fieldId, type, name, priority } = data;

	const fieldName = useMemo(
		() =>
			overriding && fg('relay-migration-issue-fields-priority')
				? overriding?.overrideLabel(name)
				: name,
		[name, overriding],
	);

	const isFieldEditable = useMemo(
		() =>
			overriding && fg('relay-migration-issue-fields-priority')
				? overriding?.overrideIsEditable(isEditable)
				: isEditable,
		[isEditable, overriding],
	);

	const filterOptionsById = fg('relay-migration-issue-fields-priority')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => overriding?.overrideFieldOptionsFilter(null), [overriding])
		: undefined;

	const [commit] = useMutation<PriorityMutation>(graphql`
		mutation priority_issueFieldPriority_PriorityField_Mutation(
			$input: JiraUpdatePriorityFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updatePriorityField(input: $input) {
					success
					errors {
						message
					}
					field {
						priority {
							id
							name
							iconUrl
						}
					}
				}
			}
		}
	`);
	// #endregion

	const initialValue = useMemo(
		() =>
			priority
				? {
						label: priority.name ?? '',
						value: priority.id,
						iconUrl: priority.iconUrl ?? '',
					}
				: null,
		[priority],
	);

	// #region Common state
	const [updatedValue, setUpdatedValue] = useState<NullableOption>(initialValue);

	const handleSubmit = useCallback(
		(option: NullableOption, { onSuccess, onFail }: OnSubmitCallbacks) => {
			const aggValue = fg('relay-migration-issue-fields-priority')
				? transformOptionToAgg(option)
				: undefined;
			if (fg('relay-migration-issue-fields-priority')) {
				// TODO remove eslint on relay-migration-issue-fields-priority' cleanup
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				onSubmitNew?.(aggValue!);
			} else {
				onSubmit?.(option);
			}
			if (option?.value != null) {
				commit({
					variables: {
						input: {
							id: uniqueFieldId,
							operation: {
								operation: 'SET',
								id: option?.value,
							},
						},
					},
					onCompleted: (mutationData) => {
						if (mutationData.jira?.updatePriorityField?.success) {
							onSuccess();
						} else {
							onFail();
						}
					},
					onError(error) {
						onFail(error);
					},
					optimisticResponse: {
						jira: {
							updatePriorityField: {
								success: true,
								errors: null,
								field: {
									id: uniqueFieldId,
									priority: fg('relay-migration-issue-fields-priority')
										? {
												// TODO remove below eslints on relay-migration-issue-fields-priority' cleanup
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												id: aggValue!.id,
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												iconUrl: aggValue!.iconUrl,
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												name: aggValue!.name,
											}
										: {
												id: option.value,
												iconUrl: option.iconUrl,
												name: option.label,
											},
								},
							},
						},
					},
				});
			}
		},
		[onSubmit, onSubmitNew, commit, uniqueFieldId],
	);

	const handleSubmitSucceeded = fg('relay-migration-issue-fields-priority')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(value: NullableOption) => {
					onSubmitSucceededNew?.(transformOptionToAgg(value));
				},
				[onSubmitSucceededNew],
			)
		: undefined;

	const setAnalyticsAttributes = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(value: NullableOption) => {
					/* TODO https://hello.jira.atlassian.cloud/browse/DEE-6534 - extract priorityId from fragment to use for attributes */
					getUpdateAnalyticsFlowHelper().setActionTakenAttributes(
						fieldId,
						getPriorityIdFromAri(initialValue?.value),
						getPriorityIdFromAri(value?.value),
					);
				},
				[fieldId, initialValue],
			)
		: undefined;

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		isValueEqual: isEqualPriority,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded: fg('relay-migration-issue-fields-priority')
			? handleSubmitSucceeded
			: onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		updatedValue,
		...(fg('one_event_rules_them_all_fg') && { setAnalyticsAttributes }),
	});

	const renderReadView = useCallback(
		() => <PriorityReadView fragmentRef={data} isCompact={isCompact} />,
		[data, isCompact],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): PriorityEditViewProps => ({
		...fieldProps,
		spacing,
		fieldId: uniqueFieldId,
		fieldName,
		autoFocus: true,
		value: updatedValue,
		onChange: handleChangeAndConfirm,
		openMenuOnFocus: true,
		menuPosition,
		filterOptionsById,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={PriorityEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [PriorityInlineEditViewProps](./types.tsx)
 */

export const PriorityInlineEditView = ({ fragmentRef, ...props }: PriorityInlineEditViewProps) => {
	const data = useFragment<PriorityFragment>(
		graphql`
			fragment priority_issueFieldPriorityInlineEditFull_PriorityInlineEditView_fragmentRef on JiraPriorityField {
				...priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<PriorityInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
