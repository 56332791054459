export const CREATED_BY_ME = 'CreatedByMe' as const;
export const SHARED_WITH_ME = 'SharedWithMe' as const;
export const PREDEFINED = 'PreDefined' as const;

// ResponderType
export const USER = 'User' as const;
export const TEAM = 'Team' as const;
export const SCHEDULE = 'Schedule' as const;
const ESCALATION = 'Escalation' as const;

export const SEARCH_ALERTS_PAGE_SIZE = 30;

export const RESPONDER = {
	USER,
	TEAM,
	SCHEDULE,
	ESCALATION,
} as const;

const OPEN = 'Open' as const;
const CLOSED = 'Closed' as const;
const ACKED = 'Acked' as const;
const SNOOZED = 'Snoozed' as const;

export const ALERT_STATUS = {
	OPEN,
	CLOSED,
	ACKED,
	SNOOZED,
} as const;

const DATA = 'DATA' as const;
const ERROR = 'ERROR' as const;
const EMPTY = 'EMPTY' as const;
const NO_INCIDENT = 'NO_INCIDENT' as const;
const NO_OPSGENIE_ACCOUNT = 'NO_OPSGENIE_ACCOUNT' as const;
export const MODAL_DISPLAY_STATE = {
	DATA,
	ERROR,
	EMPTY,
	NO_INCIDENT,
	NO_OPSGENIE_ACCOUNT,
} as const;
