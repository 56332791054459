import React, { useCallback, useMemo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { UiModificationsExtension } from '@atlassian/ui-modifications-core/types';
import { IssueAdjustmentsErrorHandler } from '@atlassian/jira-issue-adjustments/src/ui.tsx';
import {
	useAnalyticsSource,
	useIssueId,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfigWithoutUimOverrides } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	useProjectContext,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { UiModificationsJiraCore } from '@atlassian/jira-ui-modifications-common/src/common/ui/ui-modifications-jira-core/index.tsx';
import { AnalyticsListener, getErrorType } from '@atlassian/ui-modifications-analytics';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { ISSUE_VIEW_VIEW_TYPE } from '../../common/constants.tsx';
import { ErrorFlags } from './error-flags/index.tsx';
import { FieldsProcessor } from './fields-processor/index.tsx';
import { NotificationFlags } from './notification-flags/index.tsx';
import { useOverridesStoreCleanup } from './overrides-store-cleanup/index.tsx';
import { useBackgroundRefresh } from './use-background-refresh/index.tsx';
import { getSupportedFieldsCount } from './utils/index.tsx';

export type Props = {
	modules: UiModificationsExtension[];
};

const UiModificationsEntryPointLoaded = ({ modules }: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const project = useProjectContext(projectKey);
	const analyticsSource = useAnalyticsSource();
	const [{ value: fieldsConfig }] = useIssueFieldConfigWithoutUimOverrides({ issueKey });
	const [issueType] = useFieldValue({ issueKey, fieldKey: ISSUE_TYPE });
	const issueId = useIssueId();

	useOverridesStoreCleanup({ issueType, issueKey });
	useBackgroundRefresh();

	const executionContext = useMemo(() => {
		if (issueType && project && issueId) {
			return {
				project: {
					id: project.projectId,
					key: project.projectKey,
					type: project.projectType,
				},
				issueType: {
					id: issueType.id,
					name: issueType.name,
				},
				issue: {
					id: issueId,
					key: issueKey,
				},
				viewType: ISSUE_VIEW_VIEW_TYPE,
			};
		}

		return null;
	}, [issueId, issueType, project, issueKey]);

	const errorFallback = useCallback(
		() => (
			<>
				<IssueAdjustmentsErrorHandler />
				<ErrorFlags />
			</>
		),
		[],
	);

	const runtimeProps = useMemo(
		() => ({
			// null check will be performed later in renderIssueAdjustments
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			issueAdjustmentsContext: executionContext!,
			fieldsCount: getSupportedFieldsCount(fieldsConfig),
			viewType: ISSUE_VIEW_VIEW_TYPE,
			resetFormFieldsCounter: 0,
			modules,
		}),
		[executionContext, fieldsConfig, modules],
	);

	const renderIssueAdjustments = () => {
		if (!executionContext) {
			return null;
		}

		const executionContextKey = fg('uim_execution_context_data')
			? `${executionContext.project.id}-${executionContext.issueType.id}`
			: undefined;

		return (
			<UiModificationsJiraCore
				// We need to make sure that the context reset along with the issue type change
				// so the fields could re-initialize correctly.
				// Current issue is batched updates on sweet state hooks prevent unmounting FieldsProcessor on context change
				key={`uim-context-${issueId}-${issueType.id}`}
				containerId="uiModifications"
				containerPackageName="jiraUiModificationsViewIssueView"
				runtimeProps={runtimeProps}
				executionContext={executionContext}
				triggerPointKey={analyticsSource}
				errorFallback={errorFallback}
				executionContextKey={executionContextKey}
			/>
		);
	};

	return (
		<>
			{renderIssueAdjustments()}
			{issueId && (
				<FieldsProcessor issueKey={issueKey} fieldsConfig={fieldsConfig} issueId={issueId} />
			)}
			<ErrorFlags />
			<NotificationFlags />
		</>
	);
};

export const UiModificationsEntryPoint = (props: Props) => (
	<JSErrorBoundary
		id="uiModifications"
		packageName="jiraUiModificationsViewIssueView"
		fallback={() => (
			<>
				<IssueAdjustmentsErrorHandler />
				<ErrorFlags />
			</>
		)}
		attributes={(error: Error) => ({
			errorType: getErrorType(error),
			viewType: ISSUE_VIEW_VIEW_TYPE,
		})}
		sendToPrivacyUnsafeSplunk
	>
		<AnalyticsListener
			analyticsWebClient={getAnalyticsWebClientPromise().then((client) => client.getInstance())}
		>
			<UiModificationsEntryPointLoaded {...props} />
		</AnalyticsListener>
	</JSErrorBoundary>
);
