import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { expVal } from '@atlassian/jira-feature-experiments';
import { type Action, INITIALIZE_APP_PROPS } from '../../state/actions/app/index.tsx';
import { fetchDetail } from '../../state/actions/details/index.tsx';
import { changeTab } from '../../state/actions/ui/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import { getSelectedTab } from '../../state/selectors/app/index.tsx';
import { fetchWorkSuggestions } from '../../state/actions/work-suggestions/index.tsx';

export const epic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(INITIALIZE_APP_PROPS).mergeMap(() => {
		const state = store.getState();
		const selectedTab = getSelectedTab(state);
		if (expVal('jira-work-suggestions-dev-detail-dialog-experiment', 'isEnabled', false)) {
			// @ts-expect-error - TS2769 - No overload matches this call.
			return Observable.of(fetchDetail(), changeTab(selectedTab), fetchWorkSuggestions());
		}
		// @ts-expect-error - TS2769 - No overload matches this call.
		return Observable.of(fetchDetail(), changeTab(selectedTab));
	});
