/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode } from 'react';

import { css, jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled

import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const border = css({
	backgroundColor: token('color.background.input'),
	borderColor: token('color.border.input'),
	cursor: 'text',
	'&:hover': {
		backgroundColor: token('color.background.input.hovered'),
		borderColor: token('color.border.input'),
	},
	'&:focus-within': {
		backgroundColor: token('color.background.input.pressed'),
		borderColor: token('color.border.focused'),
		boxShadow: `inset 0 0 0 ${token('border.width')} ${token('color.border.focused')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&[data-invalid], &[data-invalid]:hover': {
		borderColor: token('color.border.danger'),
		boxShadow: `inset 0 0 0 ${token('border.width')} ${token('color.border.danger')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&[data-invalid]:focus-within': {
		backgroundColor: token('color.background.input.pressed'),
		borderColor: token('color.border.focused'),
		boxShadow: `inset 0 0 0 ${token('border.width')} ${token('color.border.focused')}`,
	},
	'@media screen and (-ms-high-contrast: active)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&[data-invalid]:focus-within': {
			borderColor: 'Highlight',
		},
		'&:focus-within': {
			borderColor: 'Highlight',
		},
	},
	borderRadius: 3,
	borderWidth: token('border.width'),
	borderStyle: 'solid',
	boxSizing: 'border-box',
	transition: `background-color 0.2s ease-in-out, border-color 0.2s ease-in-out`,
});

const input = css({
	boxSizing: 'border-box',
	padding: `${token('space.075')} ${token('space.075')}`,
	[media.above.xs]: {
		padding: `${token('space.100')} ${token('space.075')}`,
	},
	// Fix chromeless editor style
	// platform/packages/editor/editor-core/src/ui/Appearance/Chromeless.tsx:46
	// Without it the last `p` will have the bottom padding.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'div div > .ProseMirror > :last-child': {
		paddingBottom: '0',
	},
});

interface Props {
	isInvalid?: boolean;
	children: ReactNode;
}

export function TextareaDecoration({ children, isInvalid }: Props) {
	return (
		<div css={border} data-invalid={isInvalid || undefined}>
			<div css={input}>{children}</div>
		</div>
	);
}
