/**
 * @generated SignedSource<<3eef04ca570dd288e09ac752f2709585>>
 * @relayHash 7a81d3f9d5d09f98003f576cd018dd19
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6b6c99573cd5a8f61a41431d0a0e3825b2dceb4c73058a257a32b8a18ea36bb3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlaybooksPanelBodyQuery } from './PlaybooksPanelBodyQuery.graphql';

const node: PreloadableConcreteRequest<PlaybooksPanelBodyQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6b6c99573cd5a8f61a41431d0a0e3825b2dceb4c73058a257a32b8a18ea36bb3",
    "metadata": {},
    "name": "PlaybooksPanelBodyQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
