import React, { useState, useEffect, useCallback } from 'react';
import type { ProductId as SubProductId, Integration, AdditionalTab } from '@atlaskit/share';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { LegacyShareClientConfig, RenderCustomTriggerButton } from '../common/types.tsx';
import { useIsHipaaComplianceService } from '../services/compliance-service-hipaa/index.tsx';
import useEmailSettingsService from '../services/email-settings-service/index.tsx';
import useUserPickerPermission from '../services/user-picker-permission/index.tsx';
import OutgoingMailWarningMessage from './pre-share-view/warning-content/outgoing-mail/index.tsx';
import messages from './messages.tsx';
import PreShareView from './pre-share-view/index.tsx';
import type { ButtonStyle } from './pre-share-view/types.tsx';
import ShareButtonView from './share-view/index.tsx';
import type { ButtonAppearance, ProductId } from './types.tsx';

/** some props are passed to the underlying @atlaskit/share
 * @see ../../../../../../../platform/packages/elements/share/src/types/ShareDialogContainer.ts
 */
export type Props = {
	isAutoOpenDialog?: boolean;
	shouldAutoDismissFlag?: boolean;
	integrationType: string; // @see ../constants.tsx
	triggerButtonAppearance?: ButtonAppearance;
	triggerButtonStyle?: ButtonStyle;
	renderCustomTriggerButton?: RenderCustomTriggerButton;
	testId?: string; // Test of of the button from custom trigger button renderer to manage focus
	productId: ProductId;
	subProductId: SubProductId;
	shareLink?: string; // Link of the resource to be shared (should NOT include origin tracing). Optional, the current page URL is used by default.
	shareTitle: string;
	shareContentType: string; // some values defaults the shareFormTitle prop below if not present
	shareFormTitle?: string;
	objectAri: string;
	shareIntegrations?: Integration[];
	additionalTabs?: AdditionalTab[];
	builtInTabContentWidth?: number;
	integrationMode?: 'tabs' | 'off' | 'menu';
	legacyShareClientConfig?: LegacyShareClientConfig;
	copyTooltipText?: string;
	shareShortcutTriggerCount?: number;
	prevShareShortcutTriggerCount?: number;
	onDialogClose?: () => void;
	onTriggerButtonClick?: () => void;
	shareFormHelperMessage?: string;
	shareFieldsFooter?: React.ReactNode;
	poSpotlightShareButtonId?: string;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		integrationType,
		triggerButtonStyle,
		isAutoOpenDialog = false,
		onDialogClose,
		renderCustomTriggerButton,
		shareShortcutTriggerCount = 0,
		prevShareShortcutTriggerCount = 0,
		shareFormTitle,
		shareContentType,
		poSpotlightShareButtonId,
		additionalTabs,
		shareIntegrations,
		shareFieldsFooter,
		...otherProps
	} = props;

	const { formatMessage } = useIntl();
	const { data: orgId } = useOrgId();
	const { cloudId, activationId } = useTenantContext();

	const [
		{
			loading: loadingOutgoingMailStatus,
			data: outgoingMailStatus,
			error: outgoingMailStatusError,
		},
		fetchEmailSettings,
	] = useEmailSettingsService();

	const [{ data: hasUserPermission, error: userPermissionError }, fetchUserPermission] =
		useUserPickerPermission();

	const [
		{
			loading: loadingComplainceStatusHipaa,
			data: complainceStatusHIPAA,
			error: complainceStatusHipaaError,
		},
		fetchHipaaComplianceStatus,
	] = useIsHipaaComplianceService();

	const [isAutoOpen, setIsAutoOpen] = useState<boolean>(true);

	const shortcutPressed = !!(
		shareShortcutTriggerCount &&
		shareShortcutTriggerCount > 0 &&
		shareShortcutTriggerCount > prevShareShortcutTriggerCount
	);

	useEffect(() => {
		if (outgoingMailStatus === undefined && isAutoOpenDialog === true) {
			fetchEmailSettings();
		}
		// fetchEmailSettings is unstable and casues a setState loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAutoOpenDialog, outgoingMailStatus]);

	useEffect(() => {
		if (complainceStatusHIPAA === undefined && isAutoOpenDialog === true) {
			fetchHipaaComplianceStatus();
		}
		// complianceStatusHIPAA is unstable and casues a setState loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAutoOpenDialog, complainceStatusHIPAA]);

	useEffect(() => {
		if (hasUserPermission === undefined && isAutoOpenDialog === true) {
			fetchUserPermission();
		}
		// hasUserPermission is unstable and casues a setState loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAutoOpenDialog, hasUserPermission]);

	// This is used purely to trigger a re-render.
	// If `isAutoOpenDialog` prop is already true, when the prop is recalculated
	// in the parent if it's `true` again there will be no re-render.
	useEffect(() => {
		if (outgoingMailStatus === undefined && shortcutPressed) {
			fetchEmailSettings();
		}
		if (shortcutPressed) {
			setIsAutoOpen(shortcutPressed);
		}
		// fetchEmailSettings is unstable and casues a setState loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outgoingMailStatus, shortcutPressed, shareShortcutTriggerCount]);

	useEffect(() => {
		if (complainceStatusHIPAA === undefined && shortcutPressed) {
			fetchHipaaComplianceStatus();
		}
		if (shortcutPressed) {
			setIsAutoOpen(shortcutPressed);
		}
		// fetchEmailSettings is unstable and casues a setState loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complainceStatusHIPAA, shortcutPressed, shareShortcutTriggerCount]);

	useEffect(() => {
		if (hasUserPermission === undefined && shortcutPressed) {
			fetchUserPermission();
		}
		if (shortcutPressed) {
			setIsAutoOpen(shortcutPressed);
		}
		// fetchUserPermission is unstable and casues a setState loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasUserPermission, shortcutPressed, shareShortcutTriggerCount]);

	const getCustomFormTitle = useCallback(() => {
		switch (shareContentType) {
			case 'summary':
				return formatMessage(messages.shareMainTabTextJwmSummary);
			case 'board':
				return formatMessage(messages.shareMainTabTextJwmBoard);
			case 'list':
				return formatMessage(messages.shareMainTabTextJwmList);
			case 'calendar':
				return formatMessage(messages.shareMainTabTextJwmCalendar);
			case 'timeline':
				return formatMessage(messages.shareMainTabTextJwmTimeline);
			case 'form':
				return formatMessage(messages.shareMainTabTextJwmForm);
			case 'attachments':
				return formatMessage(messages.shareMainTabTextJwmAttachments);
			default:
				return shareFormTitle;
		}
	}, [formatMessage, shareContentType, shareFormTitle]);

	// Error handling fires analytics.
	useEffect(() => {
		if (outgoingMailStatusError) {
			fireErrorAnalytics({
				meta: {
					id: 'getEmailSettings',
					packageName: 'jiraShareButton',
					teamName: 'people-and-teams-collective',
				},
			});
		}
	}, [outgoingMailStatusError]);

	useEffect(() => {
		if (complainceStatusHipaaError) {
			fireErrorAnalytics({
				meta: {
					id: 'getComplainceStatusHIPAA',
					packageName: 'jiraShareButton',
					teamName: 'people-and-teams-collective',
				},
			});
		}
	}, [complainceStatusHipaaError]);

	useEffect(() => {
		if (userPermissionError) {
			fireErrorAnalytics({
				meta: {
					id: 'userPermissionError',
					packageName: 'jiraShareButton',
					teamName: 'people-and-teams-collective',
				},
			});
		}
	}, [userPermissionError]);

	// Loading but no errors or data available.
	const shouldAutoOpenPreShareDialog =
		isAutoOpenDialog === true &&
		(loadingOutgoingMailStatus ||
			loadingComplainceStatusHipaa ||
			complainceStatusHIPAA !== undefined ||
			!!outgoingMailStatusError ||
			!!complainceStatusHipaaError ||
			outgoingMailStatus !== undefined);
	if (fg('plans_outgoing_mail_fix')) {
		const isOutgoingMailDisabled = outgoingMailStatus?.isEmailEnabled === false;
		const isDisabledDueToEmail = isOutgoingMailDisabled && (additionalTabs?.length ?? 0) === 0;
		return (
			<>
				{(outgoingMailStatus === undefined ||
					isDisabledDueToEmail ||
					complainceStatusHIPAA?.isCompliant === true) && (
					<PreShareView
						triggerButtonStyle={triggerButtonStyle}
						loading={loadingOutgoingMailStatus || loadingComplainceStatusHipaa}
						error={outgoingMailStatusError || complainceStatusHipaaError}
						emailSettings={outgoingMailStatus}
						hipaaStatus={complainceStatusHIPAA}
						renderCustomTriggerButton={renderCustomTriggerButton}
						getEmailSettings={fetchEmailSettings}
						getHipaaStatus={fetchHipaaComplianceStatus}
						getUserPermission={fetchUserPermission}
						isAutoOpenDialog={shortcutPressed || shouldAutoOpenPreShareDialog}
						onTriggerButtonClick={props.onTriggerButtonClick}
						shareShortcutTriggerCount={shareShortcutTriggerCount}
						poSpotlightShareButtonId={poSpotlightShareButtonId}
					/>
				)}
				{outgoingMailStatus !== undefined &&
					!isDisabledDueToEmail &&
					complainceStatusHIPAA?.isCompliant === false && (
						<ShareButtonView
							triggerButtonAppearance="default"
							isAutoOpenDialog={isAutoOpen}
							renderCustomTriggerButton={renderCustomTriggerButton}
							shareContentType={shareContentType}
							shareFormTitle={shareFormTitle || getCustomFormTitle()}
							triggerButtonStyle={triggerButtonStyle}
							isBrowseUsersEnabled={hasUserPermission}
							onDialogClose={() => {
								setIsAutoOpen(false);
								if (onDialogClose) onDialogClose();
							}}
							orgId={orgId}
							workspaceAri={createAri({
								resourceId: activationId,
								cloudId,
								resourceType: 'workspace',
								resourceOwner: 'jira',
							})}
							additionalTabs={additionalTabs}
							shareIntegrations={isOutgoingMailDisabled ? [] : shareIntegrations}
							isOutgoingMailDisabled={isOutgoingMailDisabled}
							shareFieldsFooter={
								isOutgoingMailDisabled
									? OutgoingMailWarningMessage({
											canUserEnableEmail: outgoingMailStatus?.canUserEnableEmail,
										})
									: shareFieldsFooter
							}
							{...otherProps}
						/>
					)}
			</>
		);
	}
	return (
		<>
			{(outgoingMailStatus === undefined ||
				outgoingMailStatus?.isEmailEnabled === false ||
				complainceStatusHIPAA?.isCompliant === true) && (
				<PreShareView
					triggerButtonStyle={triggerButtonStyle}
					loading={loadingOutgoingMailStatus || loadingComplainceStatusHipaa}
					error={outgoingMailStatusError || complainceStatusHipaaError}
					emailSettings={outgoingMailStatus}
					hipaaStatus={complainceStatusHIPAA}
					renderCustomTriggerButton={renderCustomTriggerButton}
					getEmailSettings={fetchEmailSettings}
					getHipaaStatus={fetchHipaaComplianceStatus}
					getUserPermission={fetchUserPermission}
					isAutoOpenDialog={shortcutPressed || shouldAutoOpenPreShareDialog}
					onTriggerButtonClick={props.onTriggerButtonClick}
					shareShortcutTriggerCount={shareShortcutTriggerCount}
					poSpotlightShareButtonId={poSpotlightShareButtonId}
				/>
			)}
			{outgoingMailStatus?.isEmailEnabled === true &&
				complainceStatusHIPAA?.isCompliant === false && (
					<ShareButtonView
						triggerButtonAppearance="default"
						isAutoOpenDialog={isAutoOpen}
						renderCustomTriggerButton={renderCustomTriggerButton}
						shareContentType={shareContentType}
						shareFormTitle={shareFormTitle || getCustomFormTitle()}
						triggerButtonStyle={triggerButtonStyle}
						isBrowseUsersEnabled={hasUserPermission}
						onDialogClose={() => {
							setIsAutoOpen(false);
							if (onDialogClose) onDialogClose();
						}}
						orgId={orgId}
						workspaceAri={createAri({
							resourceId: activationId,
							cloudId,
							resourceType: 'workspace',
							resourceOwner: 'jira',
						})}
						additionalTabs={additionalTabs}
						shareIntegrations={shareIntegrations}
						shareFieldsFooter={shareFieldsFooter}
						{...otherProps}
					/>
				)}
		</>
	);
};
