import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import type { CommandPaletteIssueActionsProps } from '@atlassian/jira-command-palette-issue-actions-common/src/common/types/index.tsx';
import { asyncIssueActionsEntryPoint } from '@atlassian/jira-command-palette-issue-actions-core/entrypoint.tsx';
import { IssueActionsEntryPointContext } from '@atlassian/jira-command-palette-issue-actions-context/src/context.tsx';

import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';

type CommandPaletteIssueActions = {
	issueKey: string;
};

const STABLE_EMPTY_OBJECT = {};
export const CommandPaletteIssueActions = ({ issueKey }: CommandPaletteIssueActions) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncIssueActionsEntryPoint,
		STABLE_EMPTY_OBJECT,
	);
	const issueId = useIssueId();

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (!fg('llc-command-palette-issue-actions-core')) {
			entryPointActions.load();
		}
	}, [entryPointActions]);

	const {
		entryPointActions: epActionsWithFG,
		entryPointReferenceSubject: epReferenceSubjectWithFG,
	} = useContext(IssueActionsEntryPointContext);

	const entryPointsLoaded = useRef(false);
	const keydownEventHandler = useCallback(
		(event: KeyboardEvent) => {
			// cmd or ctrl + k will trigger command palette. '.' will trigger shortcut menu
			if ((event.ctrlKey || event.metaKey || event.key === '.') && !entryPointsLoaded.current) {
				epActionsWithFG.load();
				entryPointsLoaded.current = true;
			}
		},
		[epActionsWithFG],
	);

	useEffect(() => {
		if (fg('llc-command-palette-issue-actions-core')) {
			if (typeof document !== 'undefined' && !entryPointsLoaded.current) {
				document.addEventListener('keydown', keydownEventHandler);
			}
			return () => {
				typeof document !== 'undefined' &&
					document?.removeEventListener('keydown', keydownEventHandler);
			};
		}
	}, [entryPointsLoaded, keydownEventHandler]);

	const runtimeProps: CommandPaletteIssueActionsProps = useMemo(
		() => ({ issueKey, issueId, trigger: 'issue-view' }),
		[issueKey, issueId],
	);
	return (
		<JiraEntryPointContainer
			id="jira.issue-view.command-palette.issue-actions-core"
			packageName="issue-view"
			entryPointReferenceSubject={
				fg('llc-command-palette-issue-actions-core')
					? epReferenceSubjectWithFG
					: entryPointReferenceSubject
			}
			fallback={<></>}
			runtimeProps={runtimeProps}
		/>
	);
};
