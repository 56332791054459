import React, { useCallback, useEffect } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useIsIssueOfPostIncidentReviewPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useConfluencePermission } from '@atlassian/jira-servicedesk-post-incident-review-confluence-permission/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useExportConfluenceDialog } from '../../export-confluence-modal/controller/index.tsx';
import messages from './messages.tsx';

export const ExportIssueConfluenceOption = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { openExportConfluenceDialog }] = useExportConfluenceDialog();
	const onClick = useCallback(() => {
		openExportConfluenceDialog();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'exportConfluence');
	}, [createAnalyticsEvent, openExportConfluenceDialog]);

	const { cloudId } = useTenantContext();
	const { showFlag } = useFlagsService();
	const { result: confluencePermission, error } = useConfluencePermission(cloudId);
	const displayConfluenceConfiguration = confluencePermission.userHasConfluenceLicense;

	useEffect(() => {
		if (error) {
			showFlag({
				type: 'error',
				title: messages.errorTitle,
				description: messages.errorDescription,
			});
		}
	}, [error, showFlag]);

	return (
		<DropdownItem
			isDisabled={!displayConfluenceConfiguration}
			key="ExportConfluenceIssueActionView"
			onClick={onClick}
		>
			{formatMessage(messages.exportConfluence)}
		</DropdownItem>
	);
};

const ExportIssueConfluence = () => {
	const isIssueOfPIRPractice = useIsIssueOfPostIncidentReviewPractice();
	if (!isIssueOfPIRPractice) {
		return null;
	}
	return <ExportIssueConfluenceOption />;
};

export default ExportIssueConfluence;
