import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	loomInsertExistingVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-insert-link-item-button.loom-insert-existing-video-button-label',
		defaultMessage: 'Insert existing Loom',
		description: 'The label to be used on the button for inserting an existing Loom video',
	},
	loomExistingVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-insert-link-item-button.loom-existing-video-button-label',
		defaultMessage: 'Existing Loom video',
		description:
			'The label to be used on the button for inserting an existing Loom video, for quick actions menu GA experiment only',
	},
	linkVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-insert-link-item-button.link-video-button-label',
		defaultMessage: 'Link video',
		description:
			'The label to be used on the button for inserting an existing Loom video, for quick actions menu GA experiment only',
	},
	videoParentNestedMenuLabel: {
		id: 'issue-view-foundation.quick-add.loom-insert-link-item-button.video-parent-nested-menu-label',
		defaultMessage: 'Video',
		description:
			'The label to be used on the button for nested menu parent video, for quick actions menu GA experiment only',
	},
	videoParentNestedMenuLabelSearchText: {
		id: 'issue-view-foundation.quick-add.loom-insert-link-item-button.video-parent-nested-menu-label-search-text',
		defaultMessage: 'Create or link loom video',
		description:
			'The label to be used on the button for nested menu parent video, for quick actions menu GA experiment only',
	},
});

export default messages;
