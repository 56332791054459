import {
	SET_VALUE_ARRAY_SIZE_LIMIT,
	baseScopesRequiredPerField,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isAriResourceIdValid } from '@atlassian/ui-modifications-public-api-utils/is-ari-resource-id-valid';
import { isNotEmptyString } from '@atlassian/ui-modifications-public-api-utils/is-not-empty-string';

export type PublicGetValue = {
	id: string;
	value: string;
};

export type MultiSelectPublicSetValue = string[];

export const validateMultiSelectSetValue = (value: unknown): value is MultiSelectPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const validateMultiSelectWithAriSetValue = (
	value: unknown,
): value is MultiSelectPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every((val) => isNotEmptyString(val) && isAriResourceIdValid(val));

export const multiSelectScopes = baseScopesRequiredPerField;
