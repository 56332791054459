import React, { useMemo } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { asyncIssueActionsEntryPoint } from '@atlassian/jira-command-palette-issue-actions-core/entrypoint.tsx';

export const IssueActionsEntryPointContext =
	createUseEntryPointContext<typeof asyncIssueActionsEntryPoint>();

export const IssueActionsEntryPointContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const entryPointParams = useMemo(() => ({}), []);
	const entryPointResult = useEntryPoint(asyncIssueActionsEntryPoint, entryPointParams);
	return (
		<IssueActionsEntryPointContext.Provider value={entryPointResult}>
			{children}
		</IssueActionsEntryPointContext.Provider>
	);
};
