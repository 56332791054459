import React, { useMemo } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import useConnectOperations from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-connect-operations/index.tsx';
import type { Action as ForgeAction } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import type { EcosystemOperation } from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { ShortcutOption } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { getEcosystemItems } from '../add-to-issue-dropdown-group/ecosystem-dropdown-items/index.tsx';

export const appsDropdownGroup = (
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
) => ({
	name: '',
	key: 'AppsDropdownGroup',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: actions.reduce<Array<any>>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []),
});

export const useAppsActions = (
	forgeIssueActions: (EcosystemOperation | ForgeAction)[],
): ShortcutOption[] => {
	const issueKey = useIssueKey();

	const [{ value: connectOperations }] = useConnectOperations(issueKey);

	const items = useMemo(
		() =>
			getEcosystemItems({
				ecosystemActions: [...forgeIssueActions, ...connectOperations],
			}),

		[forgeIssueActions, connectOperations],
	);

	return items;
};
