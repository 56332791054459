import type { WorkSuggestions } from '../../../model/index.tsx';

// FETCH WORK SUGGESTIONS
export const FETCH_WORK_SUGGESTIONS =
	'state.actions.work-suggestions.FETCH_WORK_SUGGESTIONS' as const;

export type FetchWorkSuggestionsAction = {
	type: typeof FETCH_WORK_SUGGESTIONS;
};

export const fetchWorkSuggestions = (): FetchWorkSuggestionsAction => ({
	type: FETCH_WORK_SUGGESTIONS,
});

// SET WORK SUGGESTIONS DATA
export const SET_WORK_SUGGESTIONS_DATA =
	'state.actions.work-suggestions.SET_WORK_SUGGESTIONS_DATA' as const;

export type SetWorkSuggestionsDataAction = {
	type: typeof SET_WORK_SUGGESTIONS_DATA;
	payload: WorkSuggestions;
};

export const setWorkSuggestionsData = (payload: WorkSuggestions): SetWorkSuggestionsDataAction => ({
	type: SET_WORK_SUGGESTIONS_DATA,
	payload,
});

export type Action = FetchWorkSuggestionsAction | SetWorkSuggestionsDataAction;
