// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ReactNode, forwardRef, useMemo } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import Tooltip, { type PositionType } from '@atlaskit/tooltip';

export type ProgressElementProps = {
	// value - amount of items in single element
	value: number;
	// tooltipContent - message to display on hovering element (not required, default none)
	tooltipContent: string | ReactNode;
	// color - color of single element
	color: string;
	// position - position of tooltip
	position?: PositionType;
	// tooltipHoverPadding - padding above & below element to trigger tooltip
	tooltipHoverPadding?: number;
};

const SingleElementWithTooltip = (props: ProgressElementProps) => {
	const { value, tooltipContent, color, position, tooltipHoverPadding } = props;

	const TooltipTag = useMemo(() => {
		const singleElementProps = {
			alt: tooltipContent,
		};
		// FIXME: Fix that by extracting the forwarded component

		return forwardRef((tagProps, ref) => (
			<SingleElement
				elementGrow={value}
				color={color}
				// @ts-expect-error - TS2322 - Type '((instance: unknown) => void) | MutableRefObject<unknown> | null' is not assignable to type '((instance: any) => void) | RefObject<HTMLElement | SVGElement | Component<{}, {}, any>> | undefined'.
				innerRef={ref}
				tooltipHoverPadding={tooltipHoverPadding}
				{...tagProps}
				{...(typeof tooltipContent === 'string' ? singleElementProps : {})}
			/>
		));
	}, [value, color, tooltipHoverPadding, tooltipContent]);

	return (
		// @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<RefAttributes<unknown>>' is not assignable to type 'keyof IntrinsicElements | ComponentType<AllHTMLAttributes<HTMLElement> & { ref: Ref<HTMLElement>; }> | undefined'.
		<Tooltip content={tooltipContent} position={position} tag={TooltipTag}>
			<div />
		</Tooltip>
	);
};

// eslint-disable-next-line jira/react/no-class-components
export default class ProgressElement extends PureComponent<ProgressElementProps> {
	static defaultProps: Partial<ProgressElementProps> = {
		value: 0,
		tooltipContent: '',
	};

	render() {
		const { value, tooltipContent, color } = this.props;
		const hasTooltipContent = Boolean(tooltipContent);

		if (hasTooltipContent) {
			return <SingleElementWithTooltip {...this.props} tooltipContent={tooltipContent} />;
		}
		return <SingleElement elementGrow={value} color={color} alt={tooltipContent} />;
	}
}

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SingleElement = styled.div<{
	elementGrow: number;
	color: string;
	alt: string | ReactNode;
	tooltipHoverPadding?: number;
}>`
	flex-grow: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => props.elementGrow
	};
	height: 100%;
	min-width: 5px;
	background-color: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => props.color
	};
	div {
		height: 100%;
		width: 100%;
		${({ tooltipHoverPadding }) =>
			tooltipHoverPadding &&
			`padding-block: ${tooltipHoverPadding}px;
            margin-top: -${tooltipHoverPadding}px;`}
	}
`;
SingleElement.displayName = 'SingleElement';
