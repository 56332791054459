import React, { forwardRef, type Ref } from 'react';
import { graphql, useLazyLoadQuery, type EntryPointProps } from 'react-relay';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SelectableFieldEditViewWithFieldOptionsFragment } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/index.tsx';
import client from '@atlassian/jira-apollo-gira/src/index.tsx';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import type {
	SelectableValueOption,
	NullableOptions,
} from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import type { singleSelect_issueSelectableFieldQuery } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueSelectableFieldQuery.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useUpdateField } from '@atlassian/jira-business-inline-field-config-sidebar/src/services/update-field/index.tsx';
import type { OptionsInput } from '@atlassian/jira-option-color-picker/src/types.tsx';

import messages from './messages.tsx';
import type { SingleSelectEditViewProps } from './types.tsx';
import { transformToSelectableValueOption, transformToOnChangeShape } from './utils.tsx';

const SingleSelectEditViewBase = forwardRef(
	(props: SingleSelectEditViewProps, ref: Ref<HTMLDivElement>) => {
		const { formatMessage } = useIntl();
		const { update } = useUpdateField();
		const { value, fieldId, fieldName, onChange, menuPosition } = props;
		const { data } = useProjectContext();
		const projectId = data?.projectId;
		const fieldIdWithoutARI = fieldId.split(':').pop()?.split('/').pop() || '';

		const suggestionsData = useLazyLoadQuery<singleSelect_issueSelectableFieldQuery>(
			graphql`
				query singleSelect_issueSelectableFieldQuery(
					$id: ID!
					$filterById: JiraFieldOptionIdsFilterInput
				) {
					...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
						@arguments(id: $id, filterById: $filterById)
				}
			`,
			{ id: fieldId, filterById: props.filterOptionsById },
			{ fetchPolicy: 'store-only' },
		);

		const handleOnChange = (
			newValue: NullableOptions,
			_actionMeta: ActionMeta<SelectableValueOption>,
		): void => {
			onChange?.(transformToOnChangeShape(newValue));
		};

		const handleOnOptionChange = (updatedOptionsInput: OptionsInput[]) => {
			const input = {
				projectId,
				fieldId: fieldIdWithoutARI,
				name: fieldName,
				description: null,
				options: updatedOptionsInput,
				configuration: null,
			};

			return update({
				input,
			});
		};

		const content = (
			<SelectableFieldEditViewWithFieldOptionsFragment
				{...props}
				isClearable
				placeholder={
					fieldName
						? formatMessage(messages.placeholder, {
								fieldName,
							})
						: undefined
				}
				value={value ? [transformToSelectableValueOption(value)] : undefined}
				onChange={handleOnChange}
				fieldOptionsFragmentRef={suggestionsData}
				menuPosition={menuPosition}
				onOptionChange={handleOnOptionChange}
			/>
		);

		return fg('jsc_inline_editing_field_refactor') ? <div ref={ref}>{content}</div> : content;
	},
);

export const SingleSelectEditView = forwardRef(
	(props: SingleSelectEditViewProps, ref: Ref<HTMLDivElement>) => (
		<ApolloClientProvider client={client}>
			<SingleSelectEditViewBase {...props} ref={ref} />
		</ApolloClientProvider>
	),
);

const SingleSelectViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, SingleSelectEditViewProps, {}>) => <SingleSelectEditView {...props} />;

export default SingleSelectViewEntryPoint;
