import { fg } from '@atlassian/jira-feature-gating';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetTransitionForStatus } from '../../../workflow/selectors/transitions.tsx';
import { createGetField } from '../../selectors/fields.tsx';
import { createGetIssueType, createGetStatus } from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { updateConnectionFieldValue } from '../connection/index.tsx';
import { updateFieldValue } from '../update-field-value/index.tsx';
import { updateStatus } from '../update-status/index.tsx';
import { updateGoalsFieldValue } from '../goals/index.tsx';

export const updateField =
	<TFieldValueType,>(
		fieldKey: FieldKey,
		appendMultiValues: boolean,
		issueId: LocalIssueId,
		value?: TFieldValueType,
		removeValue?: TFieldValueType,
		// Note: the purpose of this parameter is to allow this function to be called in callbacks with the actual state.
		// If not provided
		overrideState?: State,
		onSuccess?: () => void,
		onError?: (arg1: Error) => void,
	) =>
	(
		{ getState, dispatch }: StoreActionApi<State>,
		props: Props,
	): {
		/**
		 * whether or not the updated issue will be filtered out after the update,
		 * or undefined if unknown
		 */
		filtered: boolean | undefined;
	} => {
		const field = createGetField(fieldKey)(getState(), props);

		if (field === undefined) {
			return { filtered: undefined };
		}

		if (field.type === FIELD_TYPES.STATUS) {
			if (value === undefined) {
				return { filtered: undefined };
			}

			const issueTypeSelector = createGetIssueType(issueId);
			const issueType = issueTypeSelector(overrideState || getState(), props);

			const getStatus = createGetStatus(issueId);
			const status = getStatus(overrideState || getState(), props);

			if (issueType === undefined || status === undefined) {
				return { filtered: undefined };
			}

			const getTransitionForStatus = createGetTransitionForStatus(
				issueType.id,
				status,
				// @ts-expect-error - TS2345 - Argument of type 'TFieldValueType' is not assignable to parameter of type 'Status'.
				value,
			);

			const transition = getTransitionForStatus({ transitions: props.workflowTransitions });

			if (transition !== undefined) {
				return dispatch(updateStatus(fieldKey, issueId, transition, onSuccess, onError));
			}
			return { filtered: undefined };
		}

		if (field.type === FIELD_TYPES.REPORTER) {
			if (value === undefined) {
				return { filtered: undefined };
			}
		}

		if (fg('jpd_issues_relationships')) {
			if (field.type === FIELD_TYPES.CONNECTION) {
				dispatch(
					updateConnectionFieldValue({
						localIssueId: issueId,
						fieldKey,
						newValue: value,
						removeValue,
					}),
				);
				return { filtered: undefined };
			}
		}

		if (fg('jpd_platform_goals_field_support')) {
			if (field.type === FIELD_TYPES.PLATFORM_GOALS) {
				dispatch(
					updateGoalsFieldValue({
						localIssueId: issueId,
						fieldKey,
						newValue: value,
						removeValue,
					}),
				);
				return { filtered: undefined };
			}
		}

		return dispatch(
			updateFieldValue({
				fieldKey,
				localIssueIds: [issueId],
				newValue: value,
				removeValue,
				appendMultiValues,
			}),
		);
	};
