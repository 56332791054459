export const issueTypeAvatarSizesMap = {
	xsmall: 16,
	small: 24,
	medium: 32,
	large: 40,
};

export type IssueTypeAvatarSizes = keyof typeof issueTypeAvatarSizesMap;

export const makeAvatarUrlFromId = (id: string, size?: IssueTypeAvatarSizes) => {
	return `/rest/api/2/universal_avatar/view/type/issuetype/avatar/${id}${size ? `?size=${size}` : ''}`;
};
