import React, { useCallback, type KeyboardEvent, type MouseEvent } from 'react';
import { ColorPaletteMenu, Compact } from '@atlaskit/color-picker';
import { useIntl } from '@atlassian/jira-intl';
import type { ColorCardVariant, ColorPaletteVariant } from '../types.tsx';
import {
	CHECK_MARK_COLOR_DARK,
	CHECK_MARK_COLOR_LIGHT,
	OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT,
	OPTION_COLOR_THEMES,
} from './constants.tsx';
import { getColorNameFromValue, getValueFromColorName } from './utils/index.tsx';

type Props = {
	color: string;
	onChangeColor: (color: string) => void;
	colorPaletteVariant?: ColorPaletteVariant;
	colorCardVariant?: ColorCardVariant;
};

export const ColorPickerMenu = ({
	color,
	onChangeColor,
	colorPaletteVariant = 'old',
	colorCardVariant = 'fill',
}: Props) => {
	const { formatMessage } = useIntl();

	const colorPalette = OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT.map(
		({ backgroundColor, messageLabel }) => ({
			label: formatMessage(messageLabel),
			value: backgroundColor,
		}),
	);

	const colorPaletteOld = OPTION_COLOR_THEMES.map(({ backgroundColor, messageLabel }) => ({
		label: formatMessage(messageLabel),
		value: backgroundColor,
	}));

	const handleChangeColor = useCallback(
		(_: MouseEvent | KeyboardEvent, newColor: string) => {
			onChangeColor(getColorNameFromValue(newColor));
		},
		[onChangeColor],
	);

	return (
		<ColorPaletteMenu
			cols={colorPaletteVariant === 'old' ? colorPaletteOld.length / 5 : colorPalette.length}
			palette={colorPaletteVariant === 'old' ? colorPaletteOld : colorPalette}
			selectedColor={getValueFromColorName(color)}
			onChange={handleChangeColor}
			mode={Compact}
			checkMarkColor={color.includes('LIGHT') ? CHECK_MARK_COLOR_LIGHT : CHECK_MARK_COLOR_DARK}
			autoFocus={false}
			isFocusLockEnabled={false}
			isInsideMenu={false}
			variant={colorCardVariant}
		/>
	);
};
