import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { addDesignFormEntryPoint } from '@atlassian/jira-issue-add-design-form/entrypoint.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { DEFAULT_WORKSPACE } from '@atlassian/jira-issue-design-common/src/constants.tsx';
import { useFigmaProviderId } from '@atlassian/jira-issue-design-common/src/utils.tsx';
import { useDesignPanelStore } from '@atlassian/jira-issue-design-section-store/src/index.tsx';
import { VIEW_DESIGN_SECTION_EXPERIENCE } from '@atlassian/jira-issue-designs-observability/src/services/constants.tsx';
import { IssueDesignsExperienceSuccess } from '@atlassian/jira-issue-designs-observability/src/services/index.tsx';
import {
	ContextualAnalyticsData,
	fireTrackAnalytics,
	fireUIAnalytics,
	SCREEN,
	type UIAnalyticsEvent,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { panel_issueDesignSection_DesignPanel$key } from '@atlassian/jira-relay/src/__generated__/panel_issueDesignSection_DesignPanel.graphql.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ITEMS_PER_PAGE, ITEMS_PER_PAGE_XS } from '../../common/constants.tsx';
import { useIsDesignPanelCompactView } from '../../common/utils.tsx';
import { designPanelContentEntryPoint } from './content/entrypoint.tsx';
import DesignsHeading from './heading/index.tsx';

export type DesignPanelDetailsProps = {
	issueAri: string;
	isExpanded: boolean;
	rootRelayFragment: panel_issueDesignSection_DesignPanel$key;
};

const DesignPanel = ({ isExpanded, issueAri, rootRelayFragment }: DesignPanelDetailsProps) => {
	const devOpsData = useFragment<panel_issueDesignSection_DesignPanel$key>(
		graphql`
			fragment panel_issueDesignSection_DesignPanel on Query {
				devOps @optIn(to: "DevOpsSecurityInJira") {
					providers(id: $siteAri, providerTypes: [DESIGN]) {
						...heading_issueDesignSection_DesignsHeading
						designProviders {
							id
							configState(cloudId: $cloudId) @optIn(to: "Jira-config-state") {
								config {
									nodes {
										status
										workspaceId
									}
								}
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	const cloudId = useCloudId();
	const issueId = Number(useIssueId());

	const entryPointParams = useMemo(() => {
		if (!issueId) {
			return { siteAri: '', issueAri };
		}
		const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();
		return { siteAri, issueAri };
	}, [cloudId, issueAri, issueId]);

	const {
		entryPointActions: designPanelContentEntryPointActions,
		entryPointReferenceSubject: designPanelContentEntryPointReferenceSubject,
	} = useEntryPoint(designPanelContentEntryPoint, entryPointParams);

	const {
		entryPointActions: addDesignFormEntryPointActions,
		entryPointReferenceSubject: addDesignFormEntryPointReferenceSubject,
	} = useEntryPoint(addDesignFormEntryPoint, entryPointParams);

	const figmaProviderId = useFigmaProviderId();

	const [
		{ shouldShowAddDesignForm },
		{ resetShouldShowAddDesignForm, setShouldShowAddDesignForm },
	] = useDesignPanelStore();

	const [currentIssueKey, setCurrentIssueKey] = useState<string | null>(null);
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);
	const [{ canEditIssues }] = useProjectPermissions(projectKey);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Effect to reset the design panel state when the issue key changes during SPA transitions
	useEffect(() => {
		setCurrentIssueKey(issueKey);
		if (currentIssueKey && currentIssueKey !== issueKey) {
			resetShouldShowAddDesignForm();
		}
	}, [currentIssueKey, issueKey, resetShouldShowAddDesignForm]);

	useEffect(
		() => () => {
			resetShouldShowAddDesignForm?.();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(isExpanded);
	const [designIdToUnlink, setDesignIdToUnlink] = useState<string | undefined>(undefined);
	const [totalCount, setTotalCount] = useState<number | undefined>(undefined);

	const figmaProvider = useMemo(
		() =>
			devOpsData.devOps?.providers?.designProviders?.find(
				(provider) => provider?.id === figmaProviderId,
			),
		[devOpsData.devOps?.providers?.designProviders, figmaProviderId],
	);

	const handleDesignsPanelExpansion = useCallback(
		(_: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'toggleDesignSection', {
				designSectionExpanded: !isPanelExpanded,
			});

			setIsPanelExpanded(!isPanelExpanded);
		},
		[isPanelExpanded],
	);

	const isConfigured = useMemo(
		() =>
			figmaProvider?.configState?.config?.nodes?.some(
				(node) => node.workspaceId === DEFAULT_WORKSPACE && node.status === 'CONFIGURED',
				// We assume the ideal state in the presence of nulls, i.e that the app is configured
			) ?? true,
		[figmaProvider],
	);

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'designSection rendered', {
			issueId,
			projectId,
			isConfigured,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [issueId, projectId, createAnalyticsEvent]);

	const designPanelContainerRef = useRef<HTMLDivElement>(null);

	const isCompactView = useIsDesignPanelCompactView(designPanelContainerRef);

	useEffect(() => {
		setItemsPerPage(isCompactView ? ITEMS_PER_PAGE_XS : ITEMS_PER_PAGE);
	}, [isCompactView]);

	useEffect(() => {
		if (isPanelExpanded) {
			designPanelContentEntryPointActions.load();
		} else {
			resetShouldShowAddDesignForm();
		}
	}, [designPanelContentEntryPointActions, isPanelExpanded, resetShouldShowAddDesignForm]);

	useEffect(() => {
		if (canEditIssues && shouldShowAddDesignForm) {
			addDesignFormEntryPointActions.load();
			setIsPanelExpanded(true);
		}
	}, [addDesignFormEntryPointActions, canEditIssues, shouldShowAddDesignForm]);

	useEffect(() => {
		if (isPanelExpanded && totalCount === 0) {
			if (canEditIssues) {
				setShouldShowAddDesignForm(true);
			} else {
				setIsPanelExpanded(false);
			}
		}
	}, [canEditIssues, isPanelExpanded, setShouldShowAddDesignForm, totalCount]);

	const onAddDesignFormCancelHandler = useCallback(() => {
		if (totalCount === 0) {
			setIsPanelExpanded(false);
		}
	}, [totalCount]);

	const runtimeProps = useMemo(
		() => ({
			designPanelContainerRef,
			setDesignIdToUnlink,
			itemsPerPage,
			designIdToUnlink,
			setTotalCount,
		}),
		[designIdToUnlink, itemsPerPage],
	);

	return (
		<ContextualAnalyticsData
			sourceName="designSection"
			sourceType={SCREEN}
			attributes={{ issueId, projectId }}
		>
			<DesignsHeading
				providerId={figmaProviderId}
				totalCount={totalCount}
				isCompactView={isCompactView}
				isPanelExpanded={isPanelExpanded}
				onDesignsPanelExpansion={handleDesignsPanelExpansion}
				providers={devOpsData.devOps?.providers ?? null}
			/>
			<DesignsPanelContainer shouldDisplay={isPanelExpanded} ref={designPanelContainerRef}>
				{canEditIssues && shouldShowAddDesignForm && (
					<JiraEntryPointContainer
						entryPointReferenceSubject={addDesignFormEntryPointReferenceSubject}
						id="jiraIssueAddDesignForm"
						packageName="jiraIssueViewBase"
						errorFallback="unmount"
						teamName="helios-headcoach"
						runtimeProps={{ onCancel: onAddDesignFormCancelHandler }}
					/>
				)}
				<JiraEntryPointContainer
					entryPointReferenceSubject={designPanelContentEntryPointReferenceSubject}
					id="jiraIssueDesignPanelContent"
					packageName="jiraIssueViewBase"
					errorFallback="unmount"
					teamName="helios-headcoach"
					runtimeProps={runtimeProps}
				/>
			</DesignsPanelContainer>
			<IssueDesignsExperienceSuccess experience={VIEW_DESIGN_SECTION_EXPERIENCE} />
		</ContextualAnalyticsData>
	);
};

export default DesignPanel;

interface DesignsPanelContainerProps {
	shouldDisplay: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DesignsPanelContainer = styled.div<DesignsPanelContainerProps>({
	justifyContent: 'flex-start',
	flexDirection: 'column',
	alignItems: 'stretch',
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ shouldDisplay }: DesignsPanelContainerProps) => (shouldDisplay ? 'flex' : 'none'),
});
