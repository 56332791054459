/**
 * @generated SignedSource<<a3b3f9df6318f0b0ce8bd8fcaf79f8ee>>
 * @relayHash 6aa078e8a98168144a82d6fda2728df8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 41c911e2fd7f858be29fe5593f3b731507192d2ad6fbe97e554ec50025ab88a6

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TownsquareUnshardedAccessControlCapability = "ACCESS" | "ADMINISTER" | "CREATE" | "%future added value";
export type TownsquareUnshardedCapabilityContainer = "GOALS_APP" | "PROJECTS_APP" | "%future added value";
export type mainGoalsPopupContentQuery$variables = {
  cloudId: string;
  issueAri: string;
};
export type mainGoalsPopupContentQuery$data = {
  readonly townsquareUnsharded_allWorkspaceSummariesForOrg: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cloudId: string;
        readonly name: string;
        readonly userCapabilities: {
          readonly capabilities: ReadonlyArray<{
            readonly capability: TownsquareUnshardedAccessControlCapability | null | undefined;
            readonly capabilityContainer: TownsquareUnshardedCapabilityContainer | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueGoals_AtlassianGoalPicker">;
};
export type mainGoalsPopupContentQuery = {
  response: mainGoalsPopupContentQuery$data;
  variables: mainGoalsPopupContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "cloudId"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "concreteType": "TownsquareUnshardedUserCapabilities",
  "kind": "LinkedField",
  "name": "userCapabilities",
  "plural": false,
  "selections": [
    {
      "concreteType": "TownsquareUnshardedCapability",
      "kind": "LinkedField",
      "name": "capabilities",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "capability"
        },
        {
          "kind": "ScalarField",
          "name": "capabilityContainer"
        }
      ]
    }
  ]
},
v5 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "issueAri"
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v11 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v12 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v13 = [
  "id"
],
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "mainGoalsPopupContentQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "issueGoals_AtlassianGoalPicker"
      },
      {
        "args": (v1/*: any*/),
        "concreteType": "TownsquareUnshardedWorkspaceSummaryConnection",
        "kind": "LinkedField",
        "name": "townsquareUnsharded_allWorkspaceSummariesForOrg",
        "plural": false,
        "selections": [
          {
            "concreteType": "TownsquareUnshardedWorkspaceSummaryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "TownsquareUnshardedWorkspaceSummary",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mainGoalsPopupContentQuery",
    "selections": [
      {
        "concreteType": "GraphStore",
        "kind": "LinkedField",
        "name": "graphStore",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalConnection",
            "kind": "LinkedField",
            "name": "jiraEpicContributesToAtlasGoal",
            "plural": false,
            "selections": [
              {
                "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "url"
                          },
                          {
                            "concreteType": "TownsquareStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              },
                              {
                                "concreteType": "TownsquareLocalizationField",
                                "kind": "LinkedField",
                                "name": "localizedLabel",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "messageId"
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "TownsquareGoal"
                      },
                      (v10/*: any*/)
                    ]
                  },
                  (v11/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v12/*: any*/)
            ]
          },
          {
            "args": (v6/*: any*/),
            "filters": (v13/*: any*/),
            "handle": "connection",
            "key": "LinkedGoals_jiraEpicContributesToAtlasGoal",
            "kind": "LinkedHandle",
            "name": "jiraEpicContributesToAtlasGoal"
          },
          {
            "alias": "issueGoalPicker",
            "args": (v14/*: any*/),
            "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalConnection",
            "kind": "LinkedField",
            "name": "jiraEpicContributesToAtlasGoal",
            "plural": false,
            "selections": [
              {
                "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "TownsquareGoal"
                      },
                      (v10/*: any*/)
                    ]
                  },
                  (v11/*: any*/),
                  (v8/*: any*/)
                ]
              },
              (v12/*: any*/)
            ]
          },
          {
            "alias": "issueGoalPicker",
            "args": (v14/*: any*/),
            "filters": (v13/*: any*/),
            "handle": "connection",
            "key": "Goals_issueGoalPicker",
            "kind": "LinkedHandle",
            "name": "jiraEpicContributesToAtlasGoal"
          }
        ]
      },
      {
        "args": (v1/*: any*/),
        "concreteType": "TownsquareUnshardedWorkspaceSummaryConnection",
        "kind": "LinkedField",
        "name": "townsquareUnsharded_allWorkspaceSummariesForOrg",
        "plural": false,
        "selections": [
          {
            "concreteType": "TownsquareUnshardedWorkspaceSummaryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "TownsquareUnshardedWorkspaceSummary",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "41c911e2fd7f858be29fe5593f3b731507192d2ad6fbe97e554ec50025ab88a6",
    "metadata": {},
    "name": "mainGoalsPopupContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "46961c295be31891ee43ef13cc35d017";

export default node;
