import React, { useMemo } from 'react';
import {
	IssueAdjustmentsAsync,
	type IssueAdjustmentsAsyncProps,
} from '@atlassian/jira-issue-adjustments/src/ui.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ForgeAppRenderer } from './forge-app-renderer/index.tsx';

export type UiModificationsJiraCoreProps = Omit<
	IssueAdjustmentsAsyncProps,
	'cloudId' | 'activationId' | 'runtimeProps'
> & { runtimeProps: Omit<IssueAdjustmentsAsyncProps['runtimeProps'], 'ForgeAppRenderer'> };

export const UiModificationsJiraCore = (props: UiModificationsJiraCoreProps) => {
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const { executionContextKey } = props;
	const { issueAdjustmentsContext, fieldsCount, viewType, resetFormFieldsCounter, modules } =
		props.runtimeProps;

	const runtimeProps = useMemo(() => {
		return {
			issueAdjustmentsContext,
			fieldsCount,
			viewType,
			resetFormFieldsCounter,
			modules,
			executionContextKey,
			ForgeAppRenderer,
		};
	}, [
		issueAdjustmentsContext,
		fieldsCount,
		viewType,
		resetFormFieldsCounter,
		modules,
		executionContextKey,
	]);

	return (
		<IssueAdjustmentsAsync
			{...props}
			runtimeProps={runtimeProps}
			cloudId={cloudId}
			activationId={activationId}
		/>
	);
};
