import React, { useCallback, useMemo, type ReactNode } from 'react';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { isFedRamp } from '@atlaskit/atlassian-context';
import { token } from '@atlaskit/tokens';
import VideoIcon from '@atlaskit/icon/core/video';
import { AsyncSuggestResourcesQuickAction } from '@atlassian/jira-aiops-suggested-resources/src/ui/quick-action/async.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	usePracticesFieldValue,
	useIsIssueOfIncidentsPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { SCHEDULE_CHANGE_ID } from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY } from '@atlassian/jira-issue-view-common/src/component/loom-videos/constants.tsx';
import { useLoomTouchpointVariantWithAdControls } from '@atlassian/jira-issue-view-common/src/component/loom-videos/use-loom-touchpoint-variant-with-ad-controls/index.tsx';
import {
	LoomTouchpointVariant,
	type LoomTouchpointVariantType,
} from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/types.tsx';
import { useForms } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-service/index.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { AsyncDeveloperEscalationsButton } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/async.tsx';
import { useIsDeveloperEscalationsEnabled } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/controllers/use-is-developer-escalations-enabled/index.tsx';
import { AsyncReviewButton } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/review-button/async.tsx';
import { CHANGES } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { JIRA_ISSUE_QUICK_ACTIONS } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { AddDesignItem } from '../../add-design-item/index.tsx';
import { AddFormButton } from '../../add-form-button/add-form-button-view/index.tsx';
import addFormButtonMessages from '../../add-form-button/add-form-button-view/messages.tsx';
import { CreateConfluenceContentMenu } from '../../create-confluence-content-menu/index.tsx';
import { LoomInsertLinkButton } from '../../loom-insert-link-item-button/view.tsx';
import loomInsertLinkButtonMessages from '../../loom-insert-link-item-button/messages.tsx';
import { LoomQuickAddItemView } from '../../loom-record-item-button/index.tsx';
import { getLabel } from '../../loom-record-item-button/utils.tsx';
import { ScheduleChangeButton } from '../../schedule-change-button/async.tsx';
import type { QuickActionComponent, QuickAddItemToRender } from '../../types.tsx';
import {
	useDynamicQuickActionListItems,
	type QuickActionDropdownItemProps,
} from '../use-dynamic-quick-action-list-items/index.tsx';
import quickAddItemsMessages from '../messages.tsx';
import { isMatch } from '../item-list/utils.tsx';

type ExternalQuickActionsPropTypes = {
	isAnonymousUser: boolean;
	onInsertLoomLinkClick: () => void;
	shouldBeCompact: boolean;
	forceUpdate: () => void;
	projectKey: string;
	issueKey: string;
	isQuickActionsListView: boolean;
	canCreateIssueLink: boolean;
	onAddIssueLinkClick: () => void;
	createItemView: (itemToRender: QuickAddItemToRender) => React.JSX.Element;
	staticComponents?: { [key: string]: ReactNode };
	onLinkPage: ((link: string, analyticsEvent: UIAnalyticsEvent) => void) | undefined;
	searchQuery: string;
};

type ExternalQuickActionsReturnTypes = {
	components: QuickActionComponent[][];
	actions: {
		setQuickActionListItem: (
			category: string,
			name: string,
			newValues: QuickActionDropdownItemProps | null,
		) => void;
	};
	crossflow: {
		actions: QuickActionComponent[];
	};
};

const useExternalQuickActionComponents = ({
	isAnonymousUser,
	onInsertLoomLinkClick,
	shouldBeCompact,
	forceUpdate,
	projectKey,
	issueKey,
	isQuickActionsListView,
	canCreateIssueLink,
	onAddIssueLinkClick,
	createItemView,
	onLinkPage,
	searchQuery,
	staticComponents,
}: ExternalQuickActionsPropTypes): ExternalQuickActionsReturnTypes => {
	const { formatMessage } = useIntl();
	const hasConfluenceAccess = useUserHasConfluenceAccess(JIRA_ISSUE_QUICK_ACTIONS).hasAccess;
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const [practices] = usePracticesFieldValue({ issueKey });
	const shouldShowScheduleChangeButton: boolean =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		canEditIssues && (practices?.some((practice: any) => practice === CHANGES) ?? false);
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const isDeveloperEscalationsEnabled = useIsDeveloperEscalationsEnabled();

	const { dynamicQuickActionListItems, setQuickActionListItem } = useDynamicQuickActionListItems(
		fg('quick_actions_menu_ga')
			? [
					{
						category: 'confluence',
						name: 'new-page',
						linkComponent: 'confluencePage',
						parentLabelMessage: formatMessage(
							quickAddItemsMessages.confluencePageNestedParentLabel,
						),
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-page',
					},
					{
						category: 'confluence',
						name: 'new-whiteboard',
						linkComponent: 'confluenceWhiteboard',
						parentLabelMessage: formatMessage(
							quickAddItemsMessages.confluenceWhiteboardNestedParentLabel,
						),
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-whiteboard',
					},
				]
			: [],
	);

	const getQuickActionListComponentsByCategory = useCallback(
		(category: string) =>
			dynamicQuickActionListItems
				.filter((quickActionListItem) => quickActionListItem.category === category)
				.map((quickActionListItem) => {
					const dropdownItem = fg('quick_actions_m2_experiment_gate') ? (
						<DropdownMenu
							key={`nested-dropdown-${category}`}
							placement="right-start"
							shouldRenderToParent
							trigger={({ triggerRef, ...triggerProps }) => (
								<DropdownItem
									{...triggerProps}
									ref={triggerRef}
									elemBefore={quickActionListItem.iconComponent}
									elemAfter={
										<ChevronRightIcon primaryColor={token('color.icon.subtle', '')} label="" />
									}
								>
									{quickActionListItem.parentLabel}
								</DropdownItem>
							)}
						>
							{quickActionListItem.linkComponent
								? staticComponents?.[quickActionListItem.linkComponent]
								: null}
							{isMatch(searchQuery, quickActionListItem.label) ? (
								<DropdownItem
									key={quickActionListItem.itemKey}
									onClick={quickActionListItem.onClick}
									elemAfter={quickActionListItem.itemBadgeComponent}
									{...quickActionListItem.additionalDropdownProps}
								>
									{quickActionListItem.labelComponent}
								</DropdownItem>
							) : null}
						</DropdownMenu>
					) : (
						<DropdownItem
							key={quickActionListItem.itemKey}
							onClick={quickActionListItem.onClick}
							elemBefore={quickActionListItem.iconComponent}
							elemAfter={quickActionListItem.itemBadgeComponent}
							{...quickActionListItem.additionalDropdownProps}
						>
							{quickActionListItem.labelComponent}
						</DropdownItem>
					);

					const component = fg('add_error_boundary_for_quick_actions_add_button') ? (
						<UFOSegment key={quickActionListItem.itemKey} name="quick-actions-confluence-buttons">
							<JSErrorBoundary
								id={quickActionListItem.name || 'quick-actions-confluence-buttons'}
								packageName="jiraQuickActions"
								teamName="bento"
								fallback="unmount"
							>
								{dropdownItem}
							</JSErrorBoundary>
						</UFOSegment>
					) : (
						dropdownItem
					);

					return {
						component,
						label: fg('quick_actions_m2_experiment_gate')
							? quickActionListItem.parentLabelMessage
							: quickActionListItem.label,
					};
				}),
		[dynamicQuickActionListItems, searchQuery, staticComponents],
	);

	const [
		{
			value: { hasIssueForms, hasProjectForms },
		},
		{ onClickAddFormButton },
	] = useForms({
		issueKey: toIssueKey(issueKey),
	});

	const suggestResourcesAction = useMemo(
		() =>
			isIssueOfIncidentsPractice && fg('incident_suggested_resources')
				? [
						{
							component: (
								<AsyncSuggestResourcesQuickAction
									isCompactMode={shouldBeCompact}
									isQuickActionsListView={isQuickActionsListView}
									key="suggest-resources-action"
								/>
							),
						},
					]
				: [],
		[isIssueOfIncidentsPractice, isQuickActionsListView, shouldBeCompact],
	);

	const reviewButton = useMemo(
		() =>
			isIssueOfIncidentsPractice && canCreateIssueLink
				? [
						{
							component: (
								<AsyncReviewButton
									onAddIssueLinkClick={onAddIssueLinkClick}
									compactMode={shouldBeCompact}
									isQuickActionsListView={isQuickActionsListView}
									key="async-review-button"
								/>
							),
						},
					]
				: [],
		[
			canCreateIssueLink,
			isIssueOfIncidentsPractice,
			isQuickActionsListView,
			onAddIssueLinkClick,
			shouldBeCompact,
		],
	);

	const changeManagementActions = useMemo(
		() =>
			[
				shouldShowScheduleChangeButton && {
					component: (
						<ScheduleChangeButton key={SCHEDULE_CHANGE_ID} createItemView={createItemView} />
					),
				},
			].filter(Boolean),
		[createItemView, shouldShowScheduleChangeButton],
	);

	const developerEscalationsAction = useMemo(
		() =>
			isDeveloperEscalationsEnabled
				? [
						{
							component: (
								<AsyncDeveloperEscalationsButton
									key="async-developer-escalations-button"
									createItemView={createItemView}
								/>
							),
						},
					]
				: [],
		[createItemView, isDeveloperEscalationsEnabled],
	);

	const formsActions = useMemo(
		() =>
			hasProjectForms
				? [
						{
							component: (
								<AddFormButton
									key="add-form-button"
									createItemView={createItemView}
									onClick={onClickAddFormButton}
									hasIssueForms={hasIssueForms}
									isQuickActionsListView={isQuickActionsListView}
								/>
							),
							label: formatMessage(addFormButtonMessages.label),
						},
					]
				: [],
		[
			createItemView,
			hasIssueForms,
			hasProjectForms,
			isQuickActionsListView,
			onClickAddFormButton,
			formatMessage,
		],
	);

	const designActions = useMemo(
		() =>
			!isAnonymousUser && canEditIssues
				? [
						{
							component: (
								<AddDesignItem
									key="add-design-button"
									createItemView={createItemView}
									onMount={forceUpdate}
									isQuickActionsListView={isQuickActionsListView}
								/>
							),
						},
					]
				: [],
		[canEditIssues, createItemView, forceUpdate, isAnonymousUser, isQuickActionsListView],
	);

	const asyncConfluenceContentMenu = useMemo(
		() => [
			{
				component: (
					<CreateConfluenceContentMenu
						key="create-confluence-content-menu"
						appearance={shouldBeCompact ? 'compact' : ''}
						isQuickActionsListView={isQuickActionsListView}
						onLinkPage={onLinkPage}
					/>
				),
			},
		],
		[isQuickActionsListView, shouldBeCompact, onLinkPage],
	);

	let loomTouchpointVariant: LoomTouchpointVariantType = LoomTouchpointVariant.NONE;
	let dismissAd: () => Promise<void> = useCallback(() => Promise.resolve(), []);

	if (fg('loom_crossflow_enablement_in_jira')) {
		/**
		 * Adding this because of removal of file level eslint disable for
		 * rules of hooks (PIR-21595). To be cleaned up when above FG is removed.
		 */
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const touchPointVariants = useLoomTouchpointVariantWithAdControls(
			LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY,
		);
		loomTouchpointVariant = touchPointVariants.loomTouchpointVariant;
		dismissAd = touchPointVariants.dismissAd;
	}

	let loomVideoButtons: QuickActionComponent[] = useMemo(() => {
		let loomButtons: QuickActionComponent[] = [];
		if (fg('loom_crossflow_enablement_in_jira')) {
			loomButtons = [
				// record Loom button could have crossflow, crossjoin or co-use touchpoints, so the logic is handled in the LoomQuickAddItemView
				{
					component: (
						<LoomQuickAddItemView
							key="loom-record-video"
							loomTouchpointVariant={loomTouchpointVariant}
							onDismissAd={dismissAd}
						/>
					),
					label: formatMessage(getLabel(loomTouchpointVariant)),
				},
			];

			if (loomTouchpointVariant === LoomTouchpointVariant.COUSE) {
				// insert existing Loom video button should only render when we are in the co-use scenario
				loomButtons = [
					...loomButtons,
					{
						component: (
							<LoomInsertLinkButton
								key="loom-insert-existing-video"
								onInsertLoomLinkClick={onInsertLoomLinkClick}
							/>
						),
						label: fg('quick_actions_m2_experiment_gate')
							? formatMessage(loomInsertLinkButtonMessages.linkVideoButtonLabel)
							: formatMessage(loomInsertLinkButtonMessages.loomExistingVideoButtonLabel),
					},
				];
			}
		}

		return loomButtons;
	}, [dismissAd, loomTouchpointVariant, onInsertLoomLinkClick, formatMessage]);

	let confluenceButtons: QuickActionComponent[] = useMemo(() => {
		return fg('quick_actions_menu_ga') ? getQuickActionListComponentsByCategory('confluence') : [];
	}, [getQuickActionListComponentsByCategory]);

	const isFedRampInstance = isFedRamp();
	const crossflowButtons = [];

	if (fg('quick_actions_m2_experiment_gate')) {
		/**
		 * Logic: If the user doesn't have access to loom or confluence, we want to show these buttons in the crossflow section
		 * instead of the regular dropdown.
		 */
		if (loomTouchpointVariant === LoomTouchpointVariant.CROSSFLOW && !isFedRampInstance) {
			crossflowButtons.push(...loomVideoButtons);
			loomVideoButtons = [];
		} else {
			const revLoomVideoButtons = [...loomVideoButtons].reverse();

			loomVideoButtons = [
				{
					component: (
						<DropdownMenu
							key="nested-dropdown-loom"
							placement="right-start"
							shouldRenderToParent
							trigger={({ triggerRef, ...triggerProps }) => (
								<DropdownItem
									{...triggerProps}
									ref={triggerRef}
									elemBefore={
										<VideoIcon
											label={formatMessage(loomInsertLinkButtonMessages.videoParentNestedMenuLabel)}
											color="currentColor"
											spacing="spacious"
										/>
									}
									elemAfter={
										<ChevronRightIcon primaryColor={token('color.icon.subtle', '')} label="" />
									}
								>
									{formatMessage(loomInsertLinkButtonMessages.videoParentNestedMenuLabel)}
								</DropdownItem>
							)}
						>
							{revLoomVideoButtons
								.filter((item) => isMatch(searchQuery, item.label))
								.map((item) => item.component)}
						</DropdownMenu>
					),
					label: formatMessage(loomInsertLinkButtonMessages.videoParentNestedMenuLabelSearchText),
				},
			];
		}

		if (!hasConfluenceAccess) {
			crossflowButtons.push(...confluenceButtons);
			confluenceButtons = [];
		}
	}

	return {
		components: [
			designActions,
			formsActions,
			developerEscalationsAction,
			changeManagementActions,
			reviewButton,
			suggestResourcesAction,
			asyncConfluenceContentMenu,
			loomVideoButtons,
			confluenceButtons,
		],
		actions: {
			setQuickActionListItem,
		},
		crossflow: {
			actions: [...crossflowButtons],
		},
	};
};

export default useExternalQuickActionComponents;
