import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { Option as EditViewOption } from '@atlassian/jira-issue-field-single-select-editview-full/src/ui/single-select/types.tsx';
import { SingleSelectInlineEditView } from '@atlassian/jira-issue-field-single-select-inline-edit-full/src/ui/single-select/index.tsx';
import type { SelectFieldOption } from '@atlassian/jira-issue-shared-types/src/common/types/select-field-option-type.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField.graphql';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * Props for `<\{@link IssueViewSingleSelectField}/>`
 */
interface IssueViewSingleSelectFieldPropsWithFragment {
	/** Defines which area of the Issue View the field will be displayed */
	area?: Area;
	/** This is a reference to the relay fragment of GraphQL type JiraSingleSelectField */
	fragmentKey: ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$key;
}

/**
 * Props for `<\{@link IssueViewSingleSelectField}/>` only used by tests
 */
export type IssueViewSingleSelectFieldProps = Omit<
	IssueViewSingleSelectFieldPropsWithFragment,
	'fragmentKey'
>;

export const transformRelayDataToLegacyShape = (
	value: EditViewOption | null,
): SelectFieldOption | null => {
	if (value === null) return null;

	return {
		ari: value.value,
		id: value.optionId,
		value: value.label,
	};
};

/**
 * Issue View wrapper of the single select field's inline-edit component,
 * with relevant information displayed by the Issue View (field name, description).
 */
export const IssueViewSingleSelectField = ({
	area,
	fragmentKey,
}: IssueViewSingleSelectFieldPropsWithFragment) => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const [, { setFieldValue }] = useFieldsValuesActions();

	const data = useFragment<ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$key>(
		graphql`
			fragment ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField on JiraSingleSelectField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef
				...singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewNew_fragmentRef
				fieldId
			}
		`,
		fragmentKey,
	);

	const content = (
		<SingleSelectInlineEditView
			fragmentRef={data}
			onSubmit={(value) => {
				issueId &&
					fieldChangeRequested(issueId, data.fieldId, transformRelayDataToLegacyShape(value));
			}}
			onSubmitSucceeded={(value) => {
				const option = transformRelayDataToLegacyShape(value);

				issueId && fieldChanged(issueId, data.fieldId, option);
				setFieldValue(issueKey, data.fieldId, option);
			}}
			onSubmitFailed={() => issueId && fieldChangeFailed(issueId, data.fieldId)}
			spacing="compact"
		/>
	);

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-single-select-field.${data.fieldId}`}
		>
			{fg('jsc_inline_editing_field_refactor') ? (
				<Box xcss={fg('issue_view_field_config_edit') ? fieldWrapperStyles : fieldWrapperStylesOld}>
					{content}
				</Box>
			) : (
				content
			)}
		</IssueViewFieldHeading>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.050',
	marginRight: 'space.100',
});

const fieldWrapperStylesOld = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
