import React, { useState, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import InlineEdit from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldWrapper,
	FieldHeadingTitle,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type { organizationField_servicedeskCustomerServiceCustomFields$key } from '@atlassian/jira-relay/src/__generated__/organizationField_servicedeskCustomerServiceCustomFields.graphql';
import { ReadView } from '../../../common/ui/organization-read-view/index.tsx';
import { EditView } from '../../../common/ui/organization-edit-view/index.tsx';
import { useUpdateIssueViewOrganizationField } from '../../../controllers/use-update-issue-view-organization-field/index.tsx';

export type Props = {
	fragmentKey: organizationField_servicedeskCustomerServiceCustomFields$key;
	expandButton?: ReactNode;
};

export const OrganizationField = ({ fragmentKey, expandButton }: Props) => {
	const [isEditing, setEditing] = useState<boolean>(false);

	const data = useFragment<organizationField_servicedeskCustomerServiceCustomFields$key>(
		graphql`
			fragment organizationField_servicedeskCustomerServiceCustomFields on JiraCustomerServiceOrganizationField {
				id
				name
				# eslint-disable-next-line @atlassian/relay/unused-fields # used by agg transformer
				type
				fieldId
				fieldConfig {
					isEditable
				}
				selectedOrganization {
					organizationName
					organizationId
				}
				searchUrl
			}
		`,
		fragmentKey,
	);

	const selectedOrganizationOption =
		data.selectedOrganization &&
		data.selectedOrganization.organizationId &&
		data.selectedOrganization.organizationName
			? {
					label: data.selectedOrganization.organizationName,
					value: data.selectedOrganization.organizationId,
				}
			: undefined;

	const { onChange, isInvalid } = useUpdateIssueViewOrganizationField({
		setEditing,
		id: data.id,
		fieldId: data.fieldId,
		fieldName: data.name,
	});

	const inlineEdit = data?.fieldConfig?.isEditable ? (
		<Box xcss={editPaddingStyles}>
			<InlineEdit
				isEditing={isEditing}
				defaultValue={selectedOrganizationOption}
				editView={() => (
					<EditView
						aria-label={data.name}
						value={selectedOrganizationOption}
						onChange={onChange}
						autoFocus
						openMenuOnFocus
						isInvalid={isInvalid}
						searchUrl={data.searchUrl || undefined}
					/>
				)}
				readView={() => <ReadView name={selectedOrganizationOption?.label || undefined} />}
				onCancel={() => setEditing(false)}
				onConfirm={() => setEditing(false)}
				onEdit={() => setEditing(true)}
				readViewFitContainerWidth
				hideActionButtons
			/>
		</Box>
	) : (
		<ReadView name={selectedOrganizationOption?.label || undefined} />
	);

	return (
		<ContextualAnalyticsData sourceName="organizationFieldIssueView" sourceType={SCREEN}>
			<FieldWrapper>
				<FieldHeading>
					<Box xcss={titleStyles}>
						<FieldHeadingTitle>{data.name}</FieldHeadingTitle>
					</Box>
				</FieldHeading>
				<SideBySideField>
					<Box xcss={[fieldWrapperStyles]}>
						<FireScreenAnalytics
							attributes={{
								organizationFieldViewed: 'IssueView',
							}}
						/>
						<Box xcss={inlineEditStyles}>{inlineEdit}</Box>
						{expandButton}
					</Box>
				</SideBySideField>
			</FieldWrapper>
		</ContextualAnalyticsData>
	);
};

const fieldWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	paddingRight: 'space.100',
	marginLeft: 'space.negative.100',
});

const editPaddingStyles = xcss({
	marginTop: 'space.negative.100',
});

const inlineEditStyles = xcss({
	flexGrow: 1,
});

const titleStyles = xcss({
	display: 'flex',
});
