/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type SortableItemDragPreviewProps = {
	fieldName: string;
};

const containerStyles = css({
	boxSizing: 'border-box',
	/**
	 * Using a max width of `280px` because wider previews have significantly
	 * lowered opacity on Windows.
	 *
	 * See: https://staging.atlassian.design/components/pragmatic-drag-and-drop/how-it-works/design-constraints
	 */
	maxWidth: '280px',
	backgroundColor: token('elevation.surface.overlay', '#FFF'),
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	borderRadius: token('border.radius.100', '3px'),
});

/**
 * Inlining the text styles instead of using `FieldHeadingTitle`
 * so we can add the truncation styles.
 */
const textStyles = css({
	font: token('font.body'),
	fontWeight: token('font.weight.semibold'),
	color: token('color.text', N800),
	/**
	 * Truncation styles
	 */
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export const SortableItemDragPreview = ({ fieldName }: SortableItemDragPreviewProps) => (
	<div css={[containerStyles, textStyles]}>{fieldName}</div>
);
