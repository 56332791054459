import React, { type FC } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useRelatedResourcesActions,
	useRelatedResourcesState,
} from '../../controllers/related-resources-context.tsx';
import messages from '../messages.tsx';

type Props = {
	onShowAISuggestionsClicked: () => void;
};

export const SuggestRelatedResourcesButton: FC<Props> = ({ onShowAISuggestionsClicked }) => {
	const { formatMessage } = useIntl();
	const { fetchSuggestions, isRelatedResourcesOpen } = useRelatedResourcesActions();
	const { isLoadingSuggestions } = useRelatedResourcesState();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Box xcss={buttonStyles}>
			<Button
				spacing="compact"
				isDisabled={isLoadingSuggestions}
				onClick={() => {
					onShowAISuggestionsClicked();
					fetchSuggestions('suggestConfluencePagesButton', createAnalyticsEvent({}));
				}}
				iconBefore={(iconProps) => (
					<AiIcon
						{...iconProps}
						label={formatMessage(messages.suggestConfluencePages)}
						size="small"
					/>
				)}
				{...(fg('aligning-jira-ai-features-ui-improvements')
					? { isSelected: !!isRelatedResourcesOpen() }
					: {})}
			>
				{formatMessage(messages.suggestConfluencePages)}
			</Button>
		</Box>
	);
};

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
