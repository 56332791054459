import React from 'react';
import { Skeleton } from '@atlaskit/avatar';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useIssueTypeAvatarId,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeAvatarSizes } from '../../common/utils/make-avatar-url-from-id.tsx';
import { IssueTypeAvatar } from './issue-type-avatar.tsx';

type Props = {
	issueTypeId: IssueTypeId;
	size?: IssueTypeAvatarSizes;
};

export const IssueTypeIcon = ({ issueTypeId, size = 'xsmall' }: Props) => {
	const avatarId = useIssueTypeAvatarId({ issueTypeId });
	const issueTypeName = useIssueTypeName({ issueTypeId });

	if (avatarId === undefined) {
		return <Skeleton appearance="square" size={size} />;
	}

	return <IssueTypeAvatar avatarId={avatarId} size={size} name={issueTypeName} />;
};
