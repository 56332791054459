import { useState, useMemo } from 'react';
import { type OptionData, TeamType } from '@atlaskit/user-picker';
import { useIntl } from '@atlassian/jira-intl';
import type { Team } from '@atlassian/jira-issue-shared-types/src/common/types/team-type.tsx';
import { NO_TEAM_ID } from '../../ui/team/constants.tsx';
import messages from '../../ui/team/messages.tsx';
import type {
	AggJiraTeamConnection,
	AggJiraTeamConnectionNew,
	UseTeamOptions,
	UseTeamOptionsNew,
} from '../../ui/team/types.tsx';

export const getSuggestionsList = (teams: AggJiraTeamConnection): Team[] =>
	teams?.edges
		?.map((edge) => edge?.node?.fullTeam ?? null)
		.filter(Boolean)
		.map((team) => ({
			name: team?.name || '',
			id: team?.id || '',
			avatarUrl: team?.avatarUrl || undefined,
			verified: team.isVerified || false,
		})) || [];

export const getSuggestionsListNew = (teams: AggJiraTeamConnectionNew): Team[] =>
	teams?.edges
		?.map((edge) => edge?.node ?? null)
		.filter((team) => Boolean(team?.fullTeam))
		.map((team) => {
			const fullTeam = team?.fullTeam;
			return {
				id: fullTeam?.id || '',
				type: TeamType,
				description: fullTeam?.description || '',
				name: fullTeam?.name || '',
				memberCount: team?.jiraMemberCount ?? 0,
				includesYou: !!team?.jiraIncludesYou,
				avatarUrl: fullTeam?.avatarUrl || undefined,
				tooltip: fullTeam?.name,
				teamId: team?.jiraSuppliedId || '',
				verified: fullTeam?.isVerified || false,
			};
		}) || [];

export const useTeamOptions = ({
	fieldOptionsData,
	searchByString,
	value,
	formattedNoValueText,
	lastFetchError,
}: UseTeamOptions): [Team[], boolean, boolean] => {
	const [hasTeams, setHasTeams] = useState(true);
	const [isCreateButtonFocused, setIsCreateButtonFocused] = useState(false);
	const { formatMessage } = useIntl();

	const defaultFailedOption = useMemo(
		() => ({
			name: formatMessage(messages.failedFetch),
			id: '',
			isDisabled: true,
		}),
		[formatMessage],
	);

	const options = useMemo(() => {
		if (lastFetchError != null) {
			return [defaultFailedOption];
		}

		const suggestions = getSuggestionsList(fieldOptionsData);

		setHasTeams(!(suggestions.length === 0 && searchByString?.length === 0));
		setIsCreateButtonFocused(searchByString?.length > 0 && suggestions.length === 0);

		if (searchByString === '' && !!value) {
			return [
				{
					id: NO_TEAM_ID,
					name: formattedNoValueText,
				},
				...suggestions,
			];
		}
		return suggestions;
	}, [
		fieldOptionsData,
		searchByString,
		value,
		formattedNoValueText,
		lastFetchError,
		defaultFailedOption,
	]);

	return [options, hasTeams, isCreateButtonFocused];
};

export const useTeamOptionsNew = ({
	fieldOptionsData,
	searchByString,
	value,
	formattedNoValueText,
	lastFetchError,
}: UseTeamOptionsNew): [OptionData[], boolean, boolean] => {
	const [hasTeams, setHasTeams] = useState(true);
	const [isCreateButtonFocused, setIsCreateButtonFocused] = useState(false);
	const { formatMessage } = useIntl();

	const defaultFailedOption = useMemo(
		() => ({
			name: formatMessage(messages.failedFetch),
			id: '',
			isDisabled: true,
			isVerified: false,
		}),
		[formatMessage],
	);

	const options = useMemo(() => {
		if (lastFetchError != null) {
			return [defaultFailedOption];
		}

		const suggestions = getSuggestionsListNew(fieldOptionsData);
		setHasTeams(!(suggestions.length === 0 && searchByString?.length === 0));
		setIsCreateButtonFocused(searchByString?.length > 0 && suggestions.length === 0);

		if (searchByString === '' && !!value) {
			return [
				{
					id: NO_TEAM_ID,
					name: formattedNoValueText,
				},
				...suggestions,
			];
		}
		return suggestions;
	}, [
		fieldOptionsData,
		searchByString,
		value,
		formattedNoValueText,
		lastFetchError,
		defaultFailedOption,
	]);

	return [options, hasTeams, isCreateButtonFocused];
};
