import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { Bleed } from '@atlaskit/primitives';
import { CustomOption } from '@atlaskit/smart-user-picker/option';
import { B400, N20, N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { CREATE_TEAM_ID } from '../../ui/team/constants.tsx';
import messages from '../../ui/team/messages.tsx';
import { useTeamPermissions } from '../use-team-permissions/index.tsx';
import CreateTeamAvatar from './assets/create-team-avatar.svg';

type CreateTeamOptionProps = {
	onClick: () => void;
	isFocused: boolean;
};

export function CreateTeamOption({ onClick, isFocused }: CreateTeamOptionProps) {
	const { formatMessage } = useIntl();
	const formattedCreateTeamText = formatMessage(messages.createTeamButtonText);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleCreateTeamOptionClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createTeamInline', {
			teamsFieldEmptyState: false,
		});
		onClick();
	}, [onClick, createAnalyticsEvent]);

	const { canCreateTeams } = useTeamPermissions();

	return canCreateTeams ? (
		// eslint-disable-next-line jira/restricted/react-suspense
		<React.Suspense fallback={null}>
			<Bleed inline="space.200" block="space.100">
				<CreateButtonBorder />
				<Container
					onClick={handleCreateTeamOptionClick}
					isFocused={isFocused}
					data-testid="issue-field-team-editview-full.utils.create-team-option.create-team-inline-option"
				>
					<OptionDataWrapper>
						<CustomOption
							isSelected={false}
							data={{
								id: CREATE_TEAM_ID,
								name: formattedCreateTeamText,
								type: 'custom',
								avatarUrl: CreateTeamAvatar,
							}}
						/>
					</OptionDataWrapper>
				</Container>
			</Bleed>
		</React.Suspense>
	) : null;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateButtonBorder = styled.div({
	borderTop: `1px solid ${token('color.border', N30A)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isFocused: boolean }>({
	marginTop: token('space.025', '2px'),
	width: '100%',
	height: '100%',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered', N20),
		boxShadow: `inset 2px 0px 0px ${token('color.border.selected', B400)}`,
	},
	// isFocused
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isFocused }) =>
		isFocused ? token('color.background.neutral.subtle.hovered', N20) : 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: ({ isFocused }) =>
		isFocused ? `inset 2px 0px 0px ${token('color.border.selected', B400)}` : 'none',
});

// Matching spacing of other options
// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionDataWrapper = styled.div({
	paddingTop: token('space.075', '6px'),
	paddingRight: token('space.150', '12px'),
	paddingBottom: token('space.075', '6px'),
	paddingLeft: token('space.150', '12px'),
});
