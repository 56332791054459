// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	switchToSidebar: {
		id: 'issue.switch-to-sidebar',
		defaultMessage: 'Open issues in sidebar',
		description: 'Label for the issue actions item the open issues in the sidebar',
	},
	switchToSidebarIssueTermRefresh: {
		id: 'issue.switch-to-sidebar-issue-term-refresh',
		defaultMessage: 'Open work items in sidebar',
		description: 'Label for the issue actions item the open issues in the sidebar',
	},
});
