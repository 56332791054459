import React, { useCallback } from 'react';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Pressable, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';

import { DropdownSectionHeader } from '../dropdown-section-header/view.tsx';
import type { QuickActionComponent } from '../types.tsx';
import { AdControls } from './ad-control-dropdown/view.tsx';
import messages from './messages.tsx';

type Props = {
	testId?: string;
	onDismiss: () => void;
	label?: string;
	actions?: QuickActionComponent[];
};

export const AdControlSection = (props: Props) => {
	const { formatMessage } = useIntl();

	const {
		onDismiss: onDismissCallback,
		label = formatMessage(messages.adSectionTitle),
		testId = 'issue-view-foundation.quick-add.ad-control-section.dropdown-item',
		actions = null,
	} = props;

	const onDismiss = useCallback(() => {
		onDismissCallback();
		return Promise.resolve();
	}, [onDismissCallback]);

	return (
		<AdControlProvider>
			<UFOSegment name="quick-actions-ad-control-section">
				<JSErrorBoundary
					id="quick-actions-ad-control-section"
					packageName="jiraQuickActions"
					teamName="bento"
					fallback="unmount"
				>
					<DropdownItemGroup>
						<DropdownItem
							testId={testId}
							elemAfter={
								<Pressable
									onClick={(e) => {
										e.stopPropagation();
									}}
									xcss={pressableStyles}
								>
									<AdControls onDismiss={onDismiss} />
								</Pressable>
							}
						>
							<DropdownSectionHeader label={label} />
						</DropdownItem>
						{actions?.map(({ component }) => component)}
					</DropdownItemGroup>
				</JSErrorBoundary>
			</UFOSegment>
		</AdControlProvider>
	);
};

const pressableStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	padding: 'space.0',
});
