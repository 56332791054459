import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import OfficeBuildingFilledIcon from '@atlaskit/icon/glyph/office-building-filled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { OrganizationOption } from '../../../types.tsx';
import { useIsAgentOrProjectAdmin } from '../../../utils.tsx';
import { getProfileUrl } from '../../details/utils.tsx';

export const OrganizationField = ({ value }: { value: OrganizationOption }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const profileURL = getProfileUrl(projectKey, 'ORGANIZATION', value.id);
	const isAgentOrProjectAdmin = useIsAgentOrProjectAdmin();

	const onClick = (e: SyntheticEvent) => {
		e.preventDefault();
		push(profileURL);
		fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'viewCustomerContextProfile', {
			context: 'ORGANIZATION',
		});
	};

	return (
		<FieldContainer data-testid="servicedesk-customer-service-issue-view-panel.common.ui.organization.organization-field.field-container">
			<IconSquare>
				<OfficeBuildingFilledIcon
					primaryColor={token('color.icon', '#44546F')}
					size="small"
					label=""
				/>
			</IconSquare>
			<NameWrapper>
				{!fg('jcs_detail_permissions') || isAgentOrProjectAdmin ? (
					<a onClick={onClick} href={profileURL}>
						{value.name}
					</a>
				) : (
					value.name
				)}
			</NameWrapper>
		</FieldContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	height: `${token('space.400', '32px')}`,
	alignItems: 'center',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameWrapper = styled.span({
	display: 'flex',
	flexWrap: 'wrap',
	lineHeight: 1,
	wordBreak: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconSquare = styled.div({
	height: `${token('space.200', '16px')}`,
	width: `${token('space.200', '16px')}`,
	display: 'flex',
	borderRadius: '4px',
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	paddingLeft: token('space.050', '4px'),
	marginRight: `${token('space.100', '8px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: `${token('color.background.neutral', colors.N30)}`,
});
