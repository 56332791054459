/**
 * @generated SignedSource<<bf046c3fc1d1cb9d3c8a8b0339c689f5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly dateTime: AGG$DateTime | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView">;
  readonly " $fragmentType": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef";
};
export type dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "e7911508d34079b211453d8cdb4bbc3d";

export default node;
