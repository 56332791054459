import React from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	canAdministerProjectPermissionsSelector,
	canUseServiceDeskAgentFeaturesSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

// RLL does not support `ssr: false` and delaying to start fetching afterPaint
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const JbPanelAsync = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "issue-view-jb-panel" */ '@atlassian/jira-servicedesk-journey-builder-issue-view-panel/src/ui/jb-panel'
		),
	{ ssr: false },
);

type Props = {
	hasAccess: boolean;
};

const JbPanel = ({ hasAccess }: Props) => {
	if (!hasAccess) {
		return null;
	}

	return (
		<JSErrorBoundary
			id="journeyBuilderViewPanel"
			packageName="@atlassian/jira-issue-view-base"
			teamName="jsm-getting-started"
		>
			<Placeholder name="journeyBuilderViewPanelAsync" fallback={null}>
				<JbPanelAsync />
			</Placeholder>
		</JSErrorBoundary>
	);
};

export default flowWithSafeComponent(
	connect((state: State) => ({
		// TODO START-1903 or only canAdministerProjectPermissionsSelector ?
		hasAccess:
			canAdministerProjectPermissionsSelector(state) ||
			canUseServiceDeskAgentFeaturesSelector(state),
	})),
)(JbPanel);
