import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { view_issueViewFoundation_ConfigureIssueFF$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewFoundation_ConfigureIssueFF.graphql';
import { getHrefWithBackLink } from '@atlassian/jira-project-settings-apps-common/src/backlink.tsx';
import messages from './messages.tsx';

type Props = {
	issueViewRelayFragment: view_issueViewFoundation_ConfigureIssueFF$key | null;
};

const ConfigureIssueFF = ({ issueViewRelayFragment }: Props) => {
	// We're in a non-tangerine folder structure, I can confirm this is a UI component and it isn't sharing state outside itself
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<view_issueViewFoundation_ConfigureIssueFF$key>(
		graphql`
			fragment view_issueViewFoundation_ConfigureIssueFF on JiraIssue {
				key
				configurationUrl
			}
		`,
		issueViewRelayFragment,
	);

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const href = data?.configurationUrl;

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'configureIssue');
		href && push(href);
	}, [createAnalyticsEvent, push, href]);

	if (!href) {
		return null;
	}

	const issueKey = data?.key;
	const hrefWithBacklink = getHrefWithBackLink(
		href,
		formatMessage(messages.swiftBacklink, { issueKey }),
	);

	return (
		<DropdownItem
			href={hrefWithBacklink}
			key="configure"
			onClick={onClick}
			UNSAFE_shouldDisableRouterLink
		>
			{formatMessage(messages.configure)}
		</DropdownItem>
	);
};

export default ConfigureIssueFF;
