import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { fetchForgeSuccess } from '@atlassian/jira-issue-view-store/src/actions/forge-actions.tsx';
import { ForgeDataStoreInit as ForgeDataStoreInitView } from './view.tsx';

const mapStateToProps = () => ({});

const ForgeDataStoreInit = connect(mapStateToProps, {
	onForgeSuccess: fetchForgeSuccess,
})(ForgeDataStoreInitView);

export default ForgeDataStoreInit;
