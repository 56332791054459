import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ConfirmIcon from '@atlaskit/icon/glyph/check';
import CancelIcon from '@atlaskit/icon/glyph/cross';
import { B400, N0, N20A, N30A, N50A, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

interface ButtonsProp {
	confirmButtonLabel: string;
	cancelButtonLabel: string;
	onMouseDown?: () => void;
	onCancelClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Buttons = ({
	confirmButtonLabel,
	cancelButtonLabel,
	onMouseDown,
	onCancelClick,
}: ButtonsProp) => (
	<ButtonsContainer>
		<ButtonWrapper>
			<Button
				aria-label={confirmButtonLabel}
				type="submit"
				iconBefore={<ConfirmIcon label={confirmButtonLabel} size="small" />}
				shouldFitContainer
				onMouseDown={onMouseDown}
			/>
		</ButtonWrapper>
		<ButtonWrapper>
			<Button
				aria-label={cancelButtonLabel}
				iconBefore={<CancelIcon label={cancelButtonLabel} size="small" />}
				onClick={onCancelClick}
				shouldFitContainer
				onMouseDown={onMouseDown}
			/>
		</ButtonWrapper>
	</ButtonsContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsContainer = styled.div({
	display: 'flex',
	marginBlockStart: token('space.075', '6px'),
	position: 'absolute',
	insetBlockStart: '100%',
	insetInlineEnd: 0,
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	boxSizing: 'border-box',
	width: token('space.400', '32px'),
	zIndex: 200,
	backgroundColor: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginInlineStart: token('space.050', '4px'),
	},
	boxShadow: token('elevation.shadow.overlay', `0 4px 8px -2px ${N50A}, 0 0 1px ${N60A}`),
	// These styles are copied from @atlaskit/inline-edit hence the unsafe nested selectors. Once AK adopts a platform
	// friendly solution, e.g. xcss, that should be adopted here as well.

	// These buttons are floating, so they need an override to overlay interaction states
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button': {
		backgroundColor: token('elevation.surface.overlay', N20A),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button:hover': {
		backgroundColor: token('elevation.surface.overlay.hovered', N30A),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button:active': {
		backgroundColor: token('elevation.surface.overlay.pressed', 'rgba(179, 212, 255, 0.6)'),
		color: token('color.text', B400),
	},
});

export default Buttons;
