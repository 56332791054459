import React from 'react';
import { SOFTWARE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import { isInSidebar } from '@atlassian/jira-issue-view-services/src/issue/issue-view-mode.tsx';
import type { view_issueViewFoundation_ConfigureIssueFF$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewFoundation_ConfigureIssueFF.graphql';
import ConfigureClassic from './configure-issue/main.tsx';
import SwitchToModal from './switch-to-modal/index.tsx';
import SwitchToSidebar from './switch-to-sidebar/index.tsx';

// Handling for view mode switching resides in the Legacy JS code
// We are currently supporting view mode switching from Bento
// See https://extranet.atlassian.com/display/JPLAT/Bento+Sidebar+on+Boards
const emitEventToLegacyApp = (event: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById('ghx-issue-fragment');

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.AJS) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.AJS.$(container).trigger(event);
	}
};

const issueConfigurationDropdownGroup = (
	viewModeOptions: ViewModeOptions,
	shouldShowConfiguration: boolean,
	isSoftwareProjectType: boolean,
	analyticsSource?: string,
	issueViewRelayFragment?: view_issueViewFoundation_ConfigureIssueFF$key | null,
) => {
	const viewModeToggleAvailable =
		(isSoftwareProjectType && analyticsSource === SOFTWARE_ISSUE) ||
		viewModeOptions.viewModeSwitchEnabled;

	const shouldShowSwitchToModal =
		viewModeToggleAvailable === true && isInSidebar(viewModeOptions.viewMode);
	const shouldShowSwitchToSidebar =
		viewModeToggleAvailable === true && !isInSidebar(viewModeOptions.viewMode);

	return {
		name: '',
		key: 'issueConfigurationDropdownGroup',
		items: [
			...(shouldShowSwitchToModal
				? [
						<SwitchToModal
							key="switchToModal"
							emitEventToLegacyApp={emitEventToLegacyApp}
							viewModeOptions={viewModeOptions}
						/>,
					]
				: []),
			...(shouldShowSwitchToSidebar
				? [
						<SwitchToSidebar
							key="switchToSidebar"
							emitEventToLegacyApp={emitEventToLegacyApp}
							viewModeOptions={viewModeOptions}
						/>,
					]
				: []),
			...(shouldShowConfiguration
				? [
						<ConfigureClassic
							key="configureClassic"
							issueViewRelayFragment={issueViewRelayFragment ?? null}
						/>,
					]
				: []),
		],
	};
};

export default issueConfigurationDropdownGroup;
