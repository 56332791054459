import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import type { Goal } from '../../common/types.tsx';
import messages from './messages.tsx';

export const useGoalsCellAriaLabel = ({
	goalCount,
	showPlaceholder,
	goals,
}: {
	goalCount: number;
	showPlaceholder?: boolean;
	goals: Goal[];
}) => {
	const { formatMessage } = useIntl();

	if (showPlaceholder) {
		return undefined;
	}

	if (goalCount === 0) {
		return formatMessage(messages.addGoal);
	}

	if (fg('list-view-goals-aria-label')) {
		if (goalCount === 1) {
			return goals[0].name;
		}

		return formatMessage(messages.goalsLinked, { goalCount });
	}

	return formatMessage(messages.linkGoals);
};
