import React, { type ChangeEvent, useCallback, useState, useRef, useMemo } from 'react';
import { css, styled } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { SingleLineTextEditView as UrlEditView } from '@atlassian/jira-issue-field-single-line-text-editview-full/src/ui/single-line-text/index.tsx';
import UrlEditViewEntryPoint from '@atlassian/jira-issue-field-single-line-text-editview-full/src/entrypoint.tsx';
import { UrlReadView } from '@atlassian/jira-issue-field-url-readview-full/src/ui/url/index.tsx';
import { fireTrackAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	url_issueFieldUrl_UrlField_OldMutation as UrlMutationOld,
	url_issueFieldUrl_UrlField_OldMutation$data as UrlMutationDataOld,
	url_issueFieldUrl_UrlField_OldMutation$rawResponse as UrlMutationResponseOld,
} from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrl_UrlField_OldMutation.graphql';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type {
	url_issueFieldUrl_UrlField_Mutation as UrlMutation,
	url_issueFieldUrl_UrlField_Mutation$data as UrlMutationData,
} from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrl_UrlField_Mutation.graphql';
import type { url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef$key as UrlFragmentOld } from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef.graphql';
import type { url_issueFieldUrlInlineEditFull_UrlInlineEditViewNew_fragmentRef$key as UrlFragment } from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrlInlineEditFull_UrlInlineEditViewNew_fragmentRef.graphql';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef$key as UrlInlineEditViewIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef.graphql';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { SingleLineTextEditViewProps } from '@atlassian/jira-issue-field-single-line-text-editview-full/src/ui/single-line-text/types.tsx';
import type {
	ClientEventValidator,
	ClientValidator,
} from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/types.tsx';
import messages from './messages.tsx';
import type {
	ErrorsType,
	UrlInlineEditViewOldProps,
	UrlInlineEditViewProps,
	UrlInlineEditViewIsEditableProps,
} from './types.tsx';
import { validationForErrors, isAllowed } from './utils.tsx';

export const UrlInlineEditViewOld = ({
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	readViewFitContainerWidth = true,
	onCancel,
	onEdit,
	onEscape,
	onConfirm,
	onEnter,
	onUpdate,
	onUpdateFailed,
}: UrlInlineEditViewOldProps) => {
	const { formatMessage } = useIntl();

	// #region Relay
	const data = useFragment<UrlFragmentOld>(
		graphql`
			fragment url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef on JiraUrlField {
				id
				name
				fieldId
				type
				uri
				...url_issueFieldUrlReadviewFull_UrlReadView
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, uri, fieldConfig, name, fieldId, type } = data;

	const [commit] = useMutation<UrlMutationOld>(graphql`
		mutation url_issueFieldUrl_UrlField_OldMutation($input: JiraUpdateUrlFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateUrlField(input: $input) {
					success
					errors {
						message
					}
					field {
						...url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const editFieldRef = useRef<HTMLInputElement>(null);
	const [isEditing, setIsEditing] = useState<boolean>(startWithEditViewOpen);
	const [updatedValue, setUpdatedValue] = useState<string | null>(uri || '');
	const [errors, setErrors] = useState<ErrorsType>(null);
	const [invalidMessage, setInvalidMessage] = useState<string | null>(null);
	const [showValidationMessage, setShowValidationMessage] = useState(false);

	const isFieldEditable = useMemo(
		() => overriding.overrideIsEditable(fieldConfig?.isEditable || false),
		[fieldConfig, overriding],
	);

	const analyticsData = useMemo(() => ({ fieldKey: fieldId, fieldType: type }), [fieldId, type]);
	// #endregion

	// #region Handle new value
	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			/**
			 * This will execute the GraphQl mutation.
			 */
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							uri: updatedValue || '',
							operation: 'SET',
						},
					},
				},
				onCompleted(mutationData: UrlMutationDataOld) {
					if (mutationData.jira?.updateUrlField) {
						const { success, errors: responseErrors } = mutationData.jira?.updateUrlField;
						if (success) {
							onUpdate?.(updatedValue || '');
							// Field updated event is a core action used to track MCU as defined in https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition
							fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', analyticsData);
							return;
						}
						setErrors(responseErrors);
					} else {
						setErrors([]);
					}
					setIsEditing(true);
				},
				onError(error) {
					setErrors([{ message: error.message }]);
					onUpdateFailed?.(updatedValue || '');
					setIsEditing(true);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateUrlField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								uri: updatedValue,
								fieldConfig: {
									isEditable: true,
								},
							},
						},
					},
				} as UrlMutationResponseOld,
			});
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[
			commit,
			uniqueFieldId,
			updatedValue,
			onUpdate,
			onUpdateFailed,
			analyticsData,
			createAnalyticsEvent,
		],
	);
	// #endregion

	// #region Common callbacks
	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setErrors(null);
			setIsEditing(false);
			// @ts-expect-error - Argument of type 'string | null | undefined' is not assignable to parameter of type 'SetStateAction<string | null>'.
			setUpdatedValue(uri);
			fireUIAnalytics(analyticsEvent);
		},
		[setErrors, uri],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setShowValidationMessage(false);
			setInvalidMessage(null);
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (invalidMessage) {
				setShowValidationMessage(true);
				editFieldRef.current?.focus();
			} else {
				save(analyticsEvent);
				onConfirm && onConfirm(analyticsEvent, updatedValue);
			}
		},
		[onConfirm, save, invalidMessage, editFieldRef, updatedValue],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setShowValidationMessage(false);
			setInvalidMessage(null);
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setShowValidationMessage(false);
			let { validationMessage } = event.target;
			const typedValue = event.target.value === '' ? null : event.target.value;
			if (!isAllowed(typedValue)) {
				validationMessage = formatMessage(messages.validUrl);
			}
			setInvalidMessage(validationMessage);
			setErrors(null);
			setUpdatedValue(typedValue);
		},
		[setErrors, setUpdatedValue, formatMessage],
	);
	// #endregion

	// #region Read view
	const renderReadView = () => (
		<ReadViewContainer data-testid="issue-field-url-inline-edit-full.ui.url.read-view">
			<UrlReadView fragmentRef={data} />
		</ReadViewContainer>
	);
	// #endregion

	// #region Edit view
	const renderEditView = () => (
		<>
			<EditViewContainer data-testid="issue-field-url-inline-edit-full.ui.url.edit-view">
				<UrlEditView
					ariaLabel={name}
					autoFocus
					type="url"
					ref={editFieldRef}
					value={updatedValue}
					onChange={onChange}
					invalidMessage={validationForErrors(
						showValidationMessage,
						invalidMessage,
						// @ts-expect-error - Argument of type 'readonly { readonly message: string | null | undefined; }[] | null | undefined' is not assignable to parameter of type 'typeOfErrors | undefined'.
						errors,
					)}
				/>
			</EditViewContainer>
		</>
	);
	// #endregion

	return (
		<InlineEditContainer isEditable={isFieldEditable}>
			<FieldInlineEditStateLess
				editView={renderEditView}
				isEditable={isFieldEditable}
				isEditing={isEditing}
				isLabelHidden
				readView={renderReadView}
				readViewFitContainerWidth={readViewFitContainerWidth}
				testId="issue-field-url-inline-edit-full.ui.url.field-inline-edit-state-less"
				onCancel={onCancelRequest}
				onConfirm={onConfirmRequest}
				onEdit={onEditRequested}
				onEnter={onEnterRequest}
				onEscape={onEscapeRequest}
				defaultValue={updatedValue}
				fieldId={fg('one_event_rules_them_all_fg') ? fieldId : undefined}
			/>
		</InlineEditContainer>
	);
};

export const UrlInlineEditViewIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	spacing = 'compact',
	menuPosition,
	menuPortalTarget,
	readViewFitContainerHeight,
	isEditable,
	onSubmit,
	onSubmitSucceeded,
	onSubmitFailed,
}: UrlInlineEditViewIsEditableProps) => {
	// #region Relay
	const data = useFragment<UrlInlineEditViewIsEditableFragment>(
		graphql`
			fragment url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef on JiraUrlField {
				id
				name
				fieldId
				type
				uri
				...url_issueFieldUrlReadviewFull_UrlReadView
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<UrlMutation>(graphql`
		mutation url_issueFieldUrl_UrlField_Mutation($input: JiraUpdateUrlFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateUrlField(input: $input) {
					success
					errors {
						message
					}
					field {
						...url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const { id: uniqueFieldId, name, fieldId, type: fieldType } = data;
	// #endregion Relay

	// #region State
	const { formatMessage } = useIntl();

	const uri = data.uri ?? '';
	const [updatedValue, setUpdatedValue] = useState<string>(uri);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);
	// #endregion State

	// #region Action
	const handleSubmit = useCallback(
		(value: string, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							uri: value,
						},
					},
				},
				onCompleted: (response: UrlMutationData) => {
					if (response.jira?.updateUrlField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError: (error) => {
					onFail(error, error.message);
				},
				optimisticResponse: {
					jira: {
						updateUrlField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								fieldId,
								type: fieldType,
								name,
								uri: value,
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldType, name, onSubmit, uniqueFieldId],
	);

	const validator: ClientEventValidator<string> = useMemo(() => {
		const change: ClientValidator<string> = (value) => {
			if (!isAllowed(value)) {
				return {
					type: 'error',
					message: formatMessage(messages.validUrl),
				};
			}

			return null;
		};

		return { change };
	}, [formatMessage]);

	const actions = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue: uri,
		updatedValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		validator,
	});

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		invalidMessage,
		isEditing,
	} = actions;

	const onChange = useCallback(
		({ target }: ChangeEvent<HTMLInputElement>) => {
			handleChange(target.value);
		},
		[handleChange],
	);
	// #endregion Action

	// #region View
	const renderReadView = useCallback(() => <UrlReadView fragmentRef={data} />, [data]);

	const getEditViewProps = (fieldProps: ValidationFieldProps): SingleLineTextEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		spacing,
		onChange,
		type: 'url',
		isInvalid: Boolean(invalidMessage),
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={UrlEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
		/>
	);
};

export const UrlInlineEditViewNew = ({ fragmentRef, ...props }: UrlInlineEditViewProps) => {
	const data = useFragment<UrlFragment>(
		graphql`
			fragment url_issueFieldUrlInlineEditFull_UrlInlineEditViewNew_fragmentRef on JiraUrlField {
				...url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<UrlInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};

export const UrlInlineEditView = componentWithFG(
	'jsc_inline_editing_field_refactor',
	UrlInlineEditViewNew,
	UrlInlineEditViewOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	zIndex: 300,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: '20px',
	paddingLeft: token('space.075', '6px'),
	width: '100%',
});

const nonEditableStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: '20px',
	paddingLeft: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStyles : undefined),
);
