import React, { useEffect, useRef, useState } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';
import Placeholder from '@atlassian/react-ufo/placeholder';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import PanelViewOrganizationQuery, {
	type panelViewOrganizationQuery,
} from '@atlassian/jira-relay/src/__generated__/panelViewOrganizationQuery.graphql.ts';
import { PanelView } from '@atlassian/jira-servicedesk-customer-service-issue-view-panel/src/common/ui/organization/panel-view/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner$key } from '@atlassian/jira-relay/src/__generated__/issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner.graphql';
import { ExpandButton } from '../../common/ui/expand-button/index.tsx';
import messages from './messages.tsx';
import { OrganizationField } from './organization-field/index.tsx';

export type JCSOrganizationFieldProps = {
	fragmentKey: issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner$key;
};

export const JCSOrganizationFieldInner = ({ fragmentKey }: JCSOrganizationFieldProps) => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const issueId = useIssueId() || '';
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [queryReference, loadQuery, disposeQuery] = useQueryLoader<panelViewOrganizationQuery>(
		PanelViewOrganizationQuery,
	);
	const idRef = useRef<string | null | undefined>(undefined);
	const hasQueryReference = !!queryReference;

	const data =
		useFragment<issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner$key>(
			graphql`
				fragment issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner on JiraCustomerServiceOrganizationField {
					# eslint-disable-next-line @atlassian/relay/unused-fields # used by agg transformer
					type
					selectedOrganization {
						organizationId
						organizationName
					}
					...organizationField_servicedeskCustomerServiceCustomFields
				}
			`,
			fragmentKey,
		);

	const { selectedOrganization } = data;

	useEffect(() => {
		if (isOpen && idRef.current !== selectedOrganization?.organizationId) {
			setIsOpen(false);
		}

		if (!isOpen && hasQueryReference) {
			disposeQuery();
		}

		idRef.current = selectedOrganization?.organizationId;
	}, [disposeQuery, hasQueryReference, isOpen, selectedOrganization?.organizationId]);

	const onToggle = () => {
		setIsOpen((prev) => {
			if (!prev && selectedOrganization?.organizationId) {
				loadQuery({
					cloudId,
					organizationId: selectedOrganization.organizationId,
					filter: fg('jcs_detail_permissions')
						? { context: { type: 'ISSUE', issueId } }
						: { context: { type: 'ISSUE' } },
				});
			}

			return !prev;
		});
	};

	return (
		<>
			<OrganizationField
				fragmentKey={data}
				expandButton={
					selectedOrganization && (
						<ExpandButton
							fieldName="organization"
							isOpen={isOpen}
							onToggle={onToggle}
							expandAriaLabel={formatMessage(messages.expandAriaLabel, {
								organizationName: selectedOrganization.organizationName,
							})}
							collapseAriaLabel={formatMessage(messages.collapseAriaLabel, {
								organizationName: selectedOrganization.organizationName,
							})}
						/>
					)
				}
			/>
			<div hidden={!isOpen} id="organization-custom-field-expand-panel">
				{isOpen && queryReference && <PanelView queryReference={queryReference} showProfileLink />}
			</div>
		</>
	);
};

export const JCSOrganizationField = (props: JCSOrganizationFieldProps) => (
	<ErrorBoundary
		id="IssueViewOrganizationField"
		packageName="servicedeskCustomerServiceCustomFields"
	>
		<Placeholder name="customer-service-custom-fields-organization-field" fallback={null}>
			<JCSOrganizationFieldInner {...props} />
		</Placeholder>
	</ErrorBoundary>
);
