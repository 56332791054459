import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/timer';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { mergeMap } from 'rxjs/operators';
import { fg } from '@atlassian/jira-feature-gating';
import { TOO_MANY_REQUESTS } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { getErrorType } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type/index.tsx';
import { FORGE_SAMPLING_RATE } from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import {
	fireInitializationIssueViewFailedEvent,
	fireInitializationIssueViewFinishedEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/fetch-modules/index.tsx';
import { canFetchForgeModules } from '@atlassian/jira-forge-ui-utils/src/utils/can-fetch-forge/index.tsx';
import { ForgeFetchError } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules/errors/index.tsx';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import type { ForgeServiceActions } from '@atlassian/jira-issue-view-forge-service/src/services/types.tsx';
import { fetchAllForgeDataPromise } from '@atlassian/jira-issue-view-services/src/issue/forge-fetch-server.tsx';
import { fetchForgeSuccess } from '@atlassian/jira-issue-view-store/src/actions/forge-actions.tsx';
import { LOAD_NEW_ISSUE } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import {
	FETCH_ISSUE_REQUEST,
	FETCH_FORGE_REQUEST,
	REFRESH_ISSUE_REQUEST,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { throttleIssueRefresh } from './constants.tsx';

const throttledActionsTypeMap: Record<string, string> = {
	[REFRESH_ISSUE_REQUEST]: 'refresh',
};

const unthrottledActionsTypeMap: Record<string, string> = {
	[FETCH_ISSUE_REQUEST]: 'fetch',
	[LOAD_NEW_ISSUE]: 'load-new',
	[FETCH_FORGE_REQUEST]: 'fetch-forge',
};

export const actionTypeMap: Record<string, string> = {
	...throttledActionsTypeMap,
	...unthrottledActionsTypeMap,
};

export const actions = Object.keys(actionTypeMap);
const unthrottledActions = Object.keys(unthrottledActionsTypeMap);
const throttledActions = Object.keys(throttledActionsTypeMap);

type Action = {
	type: string;
	payload?: ForgeResponse;
};

const actionCallback = (
	action: Action,
	forgeActions: ForgeServiceActions,
	store: MiddlewareAPI<State>,
	prefetchedResourceManager: ResourceManager | null | undefined,
) => {
	if (!fg('get_rid_of_429_retries_for_forge_modules_fetch')) {
		if (forgeActions.isForgeRateLimited()) {
			return Observable.empty<never>();
		}
	}

	const source = analyticsSourceSelector(store.getState());
	// Just in case something goes very much wrong we don't want to call the XIS
	if (!canFetchForgeModules()) {
		forgeActions.setForgeLoadingFailed();

		return Observable.empty<never>();
	}

	// These might be set any minute, so don't use them
	let isPrefetchResolved = false;
	let prefetchData: ForgeResponse;

	// These are set once and contain the correct data
	let isPrefetchedDataResolved = false;
	let isPrefetchedDataValid: boolean;

	const actionType = actionTypeMap[action.type];
	const issueForgeData = prefetchedResourceManager?.issueForgeData;
	const isPrefetched = !!issueForgeData;

	const getAttributes = (error?: Error) => {
		const attributes: Record<string, unknown> = { actionType, isPrefetched };
		if (isPrefetched) {
			attributes.isPrefetchedDataResolved = isPrefetchedDataResolved;
			attributes.isPrefetchedDataValid = isPrefetchedDataValid;
		}
		if (error) {
			attributes.errorType = getErrorType(error);
			attributes.error = error;
		}
		return attributes;
	};

	// If the promise is resolved on the next tick we will get the info about it
	issueForgeData?.then((data: ForgeResponse) => {
		isPrefetchResolved = true;
		prefetchData = data;
	});

	return Observable.fromPromise(
		// Simulate the next tick so we can depend on the data from the promise resolution above
		Promise.resolve().then(() => {
			// This data is set once (right now) and it gets send on success or error
			isPrefetchedDataResolved = isPrefetchResolved;
			isPrefetchResolved && (isPrefetchedDataValid = !!prefetchData);

			// Use prefetched data only when:
			// 1. The promise is still pending
			// 2. The promise is already resolved with a valid data
			if (isPrefetched && (isPrefetchedDataValid || !isPrefetchedDataResolved)) {
				return fetchAllForgeDataPromise(store.getState(), issueForgeData);
			}

			// Otherwise request a fresh data
			return fetchAllForgeDataPromise(store.getState());
		}),
	)
		.map((payload) => {
			if (!fg('get_rid_of_429_retries_for_forge_modules_fetch')) {
				forgeActions.setFailedEventsCount(0);
			}
			if (Math.random() * 100 < FORGE_SAMPLING_RATE) {
				fireInitializationIssueViewFinishedEvent(source, getAttributes());
			}

			forgeActions.setForge({
				...payload,
				isForgeDataComplete: true,
				isForgeLoadingFailed: false,
			});
			return fetchForgeSuccess(payload);
		})
		.catch((error) => {
			if (!fg('get_rid_of_429_retries_for_forge_modules_fetch')) {
				if (error instanceof ForgeFetchError && error.statusCodes?.includes(TOO_MANY_REQUESTS)) {
					forgeActions.setFailedEventsCount(
						(previousFailedEventsCount) => previousFailedEventsCount + 1,
					);
				}
			}
			forgeActions.setForgeLoadingFailed();
			fireInitializationIssueViewFailedEvent(source, getAttributes(error));
			return Observable.empty<never>();
		});
};
type ForgeFetchEpic = (
	prefetchedResourceManager: ResourceManager | null | undefined,
	forgeActions: ForgeServiceActions,
) => (
	action$: ActionsObservable<{ type: string }>,
	store: MiddlewareAPI<State>,
) => Observable<{
	type: 'FETCH_FORGE_SUCCESS';
	payload: ForgeResponse;
}>;

const throttledActionsEpic: ForgeFetchEpic =
	(prefetchedResourceManager, forgeActions) => (action$, store) =>
		action$.ofType(...throttledActions).pipe(
			throttleIssueRefresh(),
			mergeMap((action) => actionCallback(action, forgeActions, store, prefetchedResourceManager)),
		);

const unthrottledActionsEpic: ForgeFetchEpic =
	(prefetchedResourceManager, forgeActions) => (action$, store) =>
		action$
			.ofType(...unthrottledActions)
			.pipe(
				mergeMap((action) =>
					actionCallback(action, forgeActions, store, prefetchedResourceManager),
				),
			);

const forgeFetchEpic = (
	prefetchedResourceManager: ResourceManager | null | undefined,
	forgeActions: ForgeServiceActions,
) =>
	combineEpics(
		throttledActionsEpic(prefetchedResourceManager, forgeActions),
		unthrottledActionsEpic(prefetchedResourceManager, forgeActions),
	);

export default forgeFetchEpic;
