import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	beta: {
		id: 'ai-related-resources.beta',
		defaultMessage: 'BETA',
		description: 'Lozenge to indicate that feature is in beta phase',
	},
	suggestConfluencePages: {
		id: 'ai-related-resources.suggest-confluence-pages',
		defaultMessage: 'Suggest Confluence pages',
		description: 'Prompt indicating that the AI is suggesting confluence pages',
	},
	discardSuggestions: {
		id: 'ai-related-resources.discard-suggestions',
		defaultMessage: 'Discard and close',
		description: 'Message to indicate that the button can initiate an action to descard suggestion',
	},
	relatedResourcesHeading: {
		id: 'ai-related-resources.related-resources-heading',
		defaultMessage: 'Related Resources',
		description: 'Title for Related resources',
	},
	generatingPageSuggestions: {
		id: 'ai-related-resources.generating-page-suggestions',
		defaultMessage: 'Generating page suggestions',
		description:
			'Text shown when AI is generating suggestions for pages related to the jira work item',
	},
	pageSuggestionsEmptyHeader: {
		id: 'ai-related-resources.page-suggestions-empty-header-non-final',
		defaultMessage: 'We can’t make suggestions for this work item',
		description: 'Message header shown when AI cannot find pages relevant to the work item',
	},
	pageSuggestionsEmptyInfo: {
		id: 'ai-related-resources.page-suggestions-empty-non-final',
		defaultMessage: 'Try rewriting the work item description, then try again.',
		description: 'Message info shown when AI cannot find pages relevant to the work item',
	},
	pageSuggestionsCouldNotBeGeneratedNew: {
		id: 'ai-related-resources.page-suggestions-new-could-not-be-generated-non-final',
		defaultMessage:
			'Atlassian Intelligence isn’t responding. Try again later or <anchor>check the status of Atlassian Intelligence.</anchor>',
		description: 'Message shown when AI cannot suggest pages relevant to the work item',
	},
	// To be removed when clean up jira_ai_related_resources_new_error_state
	pageSuggestionsCouldNotBeGenerated: {
		id: 'ai-related-resources.page-suggestions-could-not-be-generated-non-final',
		defaultMessage:
			"Atlassian Intelligence couldn't suggest confluence pages relevant for this issue ",
		description: 'Message shown when AI cannot suggest pages relevant to the issue',
	},
	// To be removed when clean up jira_ai_related_resources_new_error_state
	pageSuggestionsCouldNotBeGeneratedIssueTermRefresh: {
		id: 'ai-related-resources.page-suggestions-could-not-be-generated-non-final-issue-term-refresh',
		defaultMessage:
			"Atlassian Intelligence couldn't suggest confluence pages relevant for this work item",
		description: 'Message shown when AI cannot suggest pages relevant to the issue',
	},
	siteSwitcherLabel: {
		id: 'ai-related-resources.site-switcher-label',
		defaultMessage: 'Choose site',
		description: 'Label for site switcher to let users know to choose a site',
	},
});
