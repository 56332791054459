import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs/observable/forkJoin';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { commentActivityItem } from '@atlassian/jira-issue-activity-feed/src/constants.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import {
	type IssueScrollActions,
	SCROLL_TARGET_TYPES,
} from '@atlassian/jira-issue-scroll/src/services/types.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import {
	NUM_PAGED_ITEMS_TO_LOAD,
	NUM_INITIAL_ITEMS_TO_LOAD,
} from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { commentExperienceDescription } from '@atlassian/jira-issue-view-common-utils/src/experience-tracking/comment-experience-tracking.tsx';
import flagMessages from '@atlassian/jira-issue-view-common/src/messages/flags.tsx';
import {
	fetchMoreCommentsFromGira,
	fetchSortedCommentsFromGira,
	fetchFocusedCommentFromGira,
} from '@atlassian/jira-issue-view-services/src/issue/comment-fetch-server.tsx';
import {
	fetchFocusedCommentFromAgg,
	fetchMoreCommentsFromAgg,
	fetchSortedCommentsFromAgg,
} from '@atlassian/jira-issue-view-services/src/issue/comment-fetch-agg-server.tsx';
import { setInitialSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions.tsx';
import {
	fetchMoreCommentsSuccess,
	fetchMoreCommentsFailure,
	FETCH_OLDER_COMMENTS_REQUEST,
	FETCH_NEWER_COMMENTS_REQUEST,
	setCommentScrollStatus,
	FETCH_SORTED_COMMENTS_REQUEST,
	type FetchSortedCommentsRequestAction,
	fetchSortedCommentsSuccess,
	fetchSortedCommentsFailure,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { SCROLL_TO_ATTACHMENT_COMMENT } from '@atlassian/jira-issue-view-store/src/actions/issue-scroll-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
	issueAriSelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	totalCommentsSelector,
	loadedCommentsSelector,
	startIndexCommentsSelector,
	commentsPageInfoSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { PermalinkType } from '@atlassian/jira-platform-issue-permalinks/src/constants.tsx';
import { getUrlPermalinkParameter } from '@atlassian/jira-platform-issue-permalinks/src/index.tsx';
import { extractTraceId } from '@atlassian/jira-software-sla-tracker/src/services/extract-trace-id/index.tsx';
import type {
	NormalizedLoadedComments,
	NormalizedLoadedCommentsV2,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import { comparePaginationComment } from '@atlassian/jira-issue-view-services/src/issue/gira/comments/index.tsx';

const LOG_LOCATION = 'issue.comment.fetch.epic';
const LOG_MESSAGE = 'Failed to fetch comments';
const filter4xxError = (error: Error | FetchError) =>
	!(error instanceof FetchError && isHttpClientErrorResponse(error));
export const getSortedCommentMaxResultsAndStartAt = (
	type: string,
	commentsStartIndex = 0,
	totalComments: number,
	loadedComments = 0,
): {
	maxResults: number;
	startAt: number;
} => {
	let loadedCommentsInner = loadedComments;
	let commentsStartIndexInner = commentsStartIndex;

	if (!loadedCommentsInner) loadedCommentsInner = 0;
	if (!commentsStartIndexInner) commentsStartIndexInner = 0;

	let maxResults = 0;
	let startFetchingFrom = 0;

	if (type === FETCH_OLDER_COMMENTS_REQUEST) {
		startFetchingFrom = commentsStartIndexInner + loadedCommentsInner;
		return {
			maxResults: Math.min(totalComments - startFetchingFrom, NUM_PAGED_ITEMS_TO_LOAD),
			startAt: startFetchingFrom,
		};
	}

	startFetchingFrom = Math.max(0, commentsStartIndexInner - NUM_PAGED_ITEMS_TO_LOAD);
	maxResults = commentsStartIndexInner - startFetchingFrom;

	return { maxResults, startAt: startFetchingFrom };
};

export const fetchCommentsRequest = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(FETCH_OLDER_COMMENTS_REQUEST, FETCH_NEWER_COMMENTS_REQUEST)
		.switchMap(({ type }) => {
			const state = store.getState();

			let aggCommentsData;
			let giraCommentsData;
			if (
				fg('jira_comments_agg_pagination') ||
				fg('jira_comments_agg_pagination_consistency_check')
			) {
				const pageInfo = commentsPageInfoSelector(state);
				const issueAri = issueAriSelector(state);
				const loadOlderComments = type === FETCH_OLDER_COMMENTS_REQUEST;

				aggCommentsData = fetchMoreCommentsFromAgg(issueAri, loadOlderComments, pageInfo);
			}

			if (!fg('jira_comments_agg_pagination')) {
				const baseUrl = baseUrlSelector(state);
				const issueKey = issueKeySelector(state);
				const totalComments = totalCommentsSelector(state);
				const loadedComments = loadedCommentsSelector(state);
				const commentsStartIndex = startIndexCommentsSelector(state);

				const { startAt, maxResults } = getSortedCommentMaxResultsAndStartAt(
					type,
					commentsStartIndex,
					totalComments,
					loadedComments,
				);

				giraCommentsData = fetchMoreCommentsFromGira({
					baseUrl,
					issueKey,
					maxResults,
					startAt,
					loadedComments,
					commentsStartIndex,
					type,
				});
			}

			let commentsData:
				| Observable<NormalizedLoadedCommentsV2>
				| Observable<NormalizedLoadedComments>
				| Observable<[NormalizedLoadedComments, NormalizedLoadedCommentsV2]>
				| undefined = fg('jira_comments_agg_pagination') ? aggCommentsData : giraCommentsData;

			if (
				giraCommentsData &&
				aggCommentsData &&
				fg('jira_comments_agg_pagination_consistency_check')
			) {
				commentsData = forkJoin(giraCommentsData, aggCommentsData);
			}

			// commentsData will never be undefined but have kept this as a fail safe.
			// remove this once jira_comments_agg_pagination is cleaned up
			if (!commentsData) return Observable.of(fetchMoreCommentsFailure());

			return commentsData
				.map(
					(
						comments:
							| NormalizedLoadedComments
							| NormalizedLoadedCommentsV2
							| [NormalizedLoadedComments, NormalizedLoadedCommentsV2],
					) => {
						if (Array.isArray(comments)) {
							const [giraComments, aggComments] = comments;

							if (fg('jira_comments_agg_pagination_consistency_check')) {
								const issueId = issueIdSelector(state);
								comparePaginationComment(
									giraComments,
									aggComments,
									'fetchCommentsRequest',
									issueId,
								);
								return fetchMoreCommentsSuccess({ ...giraComments, ...aggComments });
							}

							return fetchMoreCommentsSuccess(giraComments);
						}

						return fetchMoreCommentsSuccess(comments);
					},
				)
				.do(() => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							commentExperienceDescription({
								wasSuccessful: true,
								action: 'FETCH_MORE',
								analyticsSource: 'commentFetchEpic',
								projectType: state.entities.project?.projectType,
							}),
					});
				})
				.catch((error: Error | FetchError) => {
					trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
					log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
					if (filter4xxError(error)) {
						sendExperienceAnalytics({
							getExperienceDescription: ({ statusCode }) =>
								commentExperienceDescription({
									wasSuccessful: false,
									action: 'FETCH_MORE',
									analyticsSource: 'commentFetchEpic',
									projectType: state.entities.project?.projectType,
									statusCode,
									errorMessage: error?.message || 'UNKNOWN_ERROR',
									traceId: extractTraceId(error),
								}),
							error,
						});
					}
					return Observable.of(fetchMoreCommentsFailure());
				});
		});

export const fetchSortedCommentsRequest = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	(action$.ofType(FETCH_SORTED_COMMENTS_REQUEST) as Observable<FetchSortedCommentsRequestAction>)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
		.switchMap(({ payload: commentId }) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);

			if (commentId !== undefined) {
				let aggCommentsData;
				let giraCommentsData;
				if (
					fg('jira_comments_agg_pagination') ||
					fg('jira_comments_agg_pagination_consistency_check')
				) {
					const issueAri = issueAriSelector(state);
					aggCommentsData = fetchFocusedCommentFromAgg(issueAri, commentId);
				}

				if (!fg('jira_comments_agg_pagination')) {
					giraCommentsData = fetchFocusedCommentFromGira({
						baseUrl,
						issueKey,
						commentAmount: Math.floor(NUM_INITIAL_ITEMS_TO_LOAD),
						commentId,
					});
				}

				let commentsData:
					| Observable<NormalizedLoadedCommentsV2>
					| Observable<NormalizedLoadedComments>
					| Observable<[NormalizedLoadedComments, NormalizedLoadedCommentsV2]>
					| undefined = fg('jira_comments_agg_pagination') ? aggCommentsData : giraCommentsData;

				if (
					giraCommentsData &&
					aggCommentsData &&
					fg('jira_comments_agg_pagination_consistency_check')
				) {
					commentsData = forkJoin(giraCommentsData, aggCommentsData);
				}

				// commentsData will never be undefined but have kept this as a fail safe.
				// remove this once jira_comments_agg_pagination is cleaned up
				if (!commentsData) return Observable.of(fetchSortedCommentsFailure());

				return commentsData
					.map(
						(
							comments:
								| NormalizedLoadedComments
								| NormalizedLoadedCommentsV2
								| [NormalizedLoadedComments, NormalizedLoadedCommentsV2],
						) => {
							if (Array.isArray(comments)) {
								const [giraComments, aggComments] = comments;

								if (fg('jira_comments_agg_pagination_consistency_check')) {
									const issueId = issueIdSelector(state);
									comparePaginationComment(
										giraComments,
										aggComments,
										'fetchSortedCommentsRequest(focused)',
										issueId,
									);
									return fetchSortedCommentsSuccess({ ...giraComments, ...aggComments });
								}
								return fetchSortedCommentsSuccess(giraComments);
							}

							return fetchSortedCommentsSuccess(comments);
						},
					)
					.do(() => {
						sendExperienceAnalytics({
							getExperienceDescription: () =>
								commentExperienceDescription({
									wasSuccessful: true,
									action: 'FETCH_SORTED',
									analyticsSource: 'commentFetchEpic',
									projectType: state.entities.project?.projectType,
								}),
						});
					})
					.catch((error: Error | FetchError) => {
						trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
						log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
						if (filter4xxError(error)) {
							sendExperienceAnalytics({
								getExperienceDescription: ({ statusCode }) =>
									commentExperienceDescription({
										wasSuccessful: false,
										action: 'FETCH_SORTED',
										analyticsSource: 'commentFetchEpic',
										projectType: state.entities.project?.projectType,
										statusCode,
										errorMessage: error?.message || 'UNKNOWN_ERROR',
										traceId: extractTraceId(error),
									}),
								error,
							});
						}
						return Observable.of(fetchSortedCommentsFailure());
					});
			}

			let aggCommentsData;
			let giraCommentsData;
			if (
				fg('jira_comments_agg_pagination') ||
				fg('jira_comments_agg_pagination_consistency_check')
			) {
				const issueAri = issueAriSelector(state);
				aggCommentsData = fetchSortedCommentsFromAgg(issueAri);
			}

			if (!fg('jira_comments_agg_pagination')) {
				giraCommentsData = fetchSortedCommentsFromGira({
					baseUrl,
					issueKey,
					maxResults: NUM_INITIAL_ITEMS_TO_LOAD,
				});
			}

			let commentsData:
				| Observable<NormalizedLoadedCommentsV2>
				| Observable<NormalizedLoadedComments>
				| Observable<[NormalizedLoadedComments, NormalizedLoadedCommentsV2]>
				| undefined = fg('jira_comments_agg_pagination') ? aggCommentsData : giraCommentsData;

			if (
				giraCommentsData &&
				aggCommentsData &&
				fg('jira_comments_agg_pagination_consistency_check')
			) {
				commentsData = forkJoin(giraCommentsData, aggCommentsData);
			}

			// commentsData will never be undefined but have kept this as a fail safe.
			// remove this once jira_comments_agg_pagination is cleaned up
			if (!commentsData) return Observable.of(fetchSortedCommentsFailure());

			return commentsData
				.map(
					(
						comments:
							| NormalizedLoadedComments
							| NormalizedLoadedCommentsV2
							| [NormalizedLoadedComments, NormalizedLoadedCommentsV2],
					) => {
						if (Array.isArray(comments)) {
							const [giraComments, aggComments] = comments;

							if (fg('jira_comments_agg_pagination_consistency_check')) {
								const issueId = issueIdSelector(state);
								comparePaginationComment(
									giraComments,
									aggComments,
									'fetchSortedCommentsRequest',
									issueId,
								);
								return fetchSortedCommentsSuccess({ ...giraComments, ...aggComments });
							}
							return fetchSortedCommentsSuccess(giraComments);
						}

						return fetchSortedCommentsSuccess(comments);
					},
				)
				.do(() => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							commentExperienceDescription({
								wasSuccessful: true,
								action: 'FETCH_SORTED',
								analyticsSource: 'commentFetchEpic',
								projectType: state.entities.project?.projectType,
							}),
					});
				})
				.catch((error: Error | FetchError) => {
					trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
					log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
					if (filter4xxError(error)) {
						sendExperienceAnalytics({
							getExperienceDescription: ({ statusCode }) =>
								commentExperienceDescription({
									wasSuccessful: false,
									action: 'FETCH_SORTED',
									analyticsSource: 'commentFetchEpic',
									projectType: state.entities.project?.projectType,
									statusCode,
									errorMessage: error?.message || 'UNKNOWN_ERROR',
									traceId: extractTraceId(error),
								}),
							error,
						});
					}
					return Observable.of(fetchSortedCommentsFailure());
				});
		});

export const fetchAttachmentCommentRequest =
	(issueScrollAction: IssueScrollActions) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$.ofType(SCROLL_TO_ATTACHMENT_COMMENT).switchMap(() => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);
			const commentId = issueScrollAction.getScrollTargetId(SCROLL_TARGET_TYPES.COMMENT);

			// the store does not contain the right scroll target. Abort fetch call to backend
			if (commentId === null) {
				return Observable.of();
			}

			let aggCommentsData;
			let giraCommentsData;
			if (
				fg('jira_comments_agg_pagination') ||
				fg('jira_comments_agg_pagination_consistency_check')
			) {
				const issueAri = issueAriSelector(state);
				aggCommentsData = fetchFocusedCommentFromAgg(issueAri, commentId);
			}

			if (!fg('jira_comments_agg_pagination')) {
				giraCommentsData = fetchFocusedCommentFromGira({
					baseUrl,
					issueKey,
					commentAmount: Math.floor(NUM_INITIAL_ITEMS_TO_LOAD),
					commentId,
				});
			}

			let commentsData:
				| Observable<NormalizedLoadedCommentsV2>
				| Observable<NormalizedLoadedComments>
				| Observable<[NormalizedLoadedComments, NormalizedLoadedCommentsV2]>
				| undefined = fg('jira_comments_agg_pagination') ? aggCommentsData : giraCommentsData;

			if (
				giraCommentsData &&
				aggCommentsData &&
				fg('jira_comments_agg_pagination_consistency_check')
			) {
				commentsData = forkJoin(giraCommentsData, aggCommentsData);
			}

			// commentsData will never be undefined but have kept this as a fail safe.
			// remove this once jira_comments_agg_pagination is cleaned up
			if (!commentsData) return Observable.of();

			return commentsData
				.flatMap(
					(
						comments:
							| NormalizedLoadedComments
							| NormalizedLoadedCommentsV2
							| [NormalizedLoadedComments, NormalizedLoadedCommentsV2],
					) => {
						// modify the url, so that specific commentId can scroll within comment-view.js

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.history.replaceState(
							{},
							// @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string'.
							null,
							getUrlPermalinkParameter(commentId, PermalinkType.COMMENTS),
						);
						issueScrollAction.setScrollTarget(null);

						sendExperienceAnalytics({
							getExperienceDescription: () =>
								commentExperienceDescription({
									wasSuccessful: true,
									action: 'FETCH_SURROUNDING',
									analyticsSource: 'commentFetchEpic',
									projectType: state.entities.project?.projectType,
								}),
						});

						if (Array.isArray(comments)) {
							const [giraComments, aggComments] = comments;

							if (fg('jira_comments_agg_pagination_consistency_check')) {
								const issueId = issueIdSelector(state);
								comparePaginationComment(
									giraComments,
									aggComments,
									'fetchAttachmentCommentRequest',
									issueId,
								);
								return Observable.of(
									fetchSortedCommentsSuccess({ ...giraComments, ...aggComments }),
									// @ts-expect-error - TS2769 - No overload matches this call.
									setInitialSelectedActivityItem(commentActivityItem),
									setCommentScrollStatus(false),
								);
							}
							return Observable.of(
								fetchSortedCommentsSuccess(giraComments),
								// @ts-expect-error - TS2769 - No overload matches this call.
								setInitialSelectedActivityItem(commentActivityItem),
								setCommentScrollStatus(false),
							);
						}

						return Observable.of(
							fetchSortedCommentsSuccess(comments),
							// @ts-expect-error - TS2769 - No overload matches this call.
							setInitialSelectedActivityItem(commentActivityItem),
							setCommentScrollStatus(false),
						);
					},
				)
				.catch((error: Error | FetchError) => {
					trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
					log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
					const { showFlag } = useFlagsService();
					const field = 'comment';
					showFlag({
						type: 'error',
						title: [flagMessages.commentWorklogAttachmentFetchFailedTitle, { field }],
						description: flagMessages.commentWorklogAttachmentFetchFailedDescription,
						isAutoDismiss: true,
					});
					if (filter4xxError(error)) {
						sendExperienceAnalytics({
							getExperienceDescription: ({ statusCode }) =>
								commentExperienceDescription({
									wasSuccessful: false,
									action: 'FETCH_SURROUNDING',
									analyticsSource: 'commentFetchEpic',
									projectType: state.entities.project?.projectType,
									statusCode,
									errorMessage: error?.message || 'UNKNOWN_ERROR',
									traceId: extractTraceId(error),
								}),
							error,
						});
					}
					return Observable.of();
				});
		});
