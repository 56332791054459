import React from 'react';

import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

type OutgoingMailProps = {
	canUserEnableEmail: boolean;
};

const OutgoingMailWarningMessage = ({ canUserEnableEmail }: OutgoingMailProps) => {
	const { formatMessage } = useIntl();

	return canUserEnableEmail ? (
		<SectionMessage
			actions={
				<SectionMessageAction href="/secure/admin/OutgoingMailServers.jspa">
					{formatMessage(messages.outgoingMailSettingsLink)}
				</SectionMessageAction>
			}
			appearance="warning"
		>
			<Text>{formatMessage(messages.disabledWarningMessage)}</Text>
		</SectionMessage>
	) : (
		<SectionMessage appearance="warning">
			<Text>{formatMessage(messages.disabledWarningMessage)}</Text>
			<br />
			<Text>{formatMessage(messages.nonAdminWarningMessage)}</Text>
		</SectionMessage>
	);
};

export default OutgoingMailWarningMessage;
