import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	createStore,
	createHook,
	createActionsHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type IssueMountCountState = {
	issueKey: IssueKey | null;
	mountCount: number;
};

export type IncrementIssueMountCountType = (
	issueKey: IssueKey,
	event: UIAnalyticsEvent,
	options?: Object,
) => void;

type IssueMountCountActions = {
	incrementIssueMountCount: (
		issueKey: IssueKey,
		event: UIAnalyticsEvent,
		options?: Object,
	) => (_: StoreActionApi<IssueMountCountState>) => void;
	fireMountCountAnalyticEvent: (
		event: UIAnalyticsEvent,
		options?: Object,
	) => (_: StoreActionApi<IssueMountCountState>) => void;
	resetCount: () => (_: StoreActionApi<IssueMountCountState>) => void;
};

export type IssueMountCounterType = [IssueMountCountState, IssueMountCountActions];

const initialState: IssueMountCountState = {
	issueKey: null,
	mountCount: 1,
};

const actions: IssueMountCountActions = {
	incrementIssueMountCount:
		(issueKey, event, options) =>
		({ setState, getState }) => {
			if (issueKey === getState().issueKey) {
				setState({
					mountCount: getState().mountCount + 1,
				});
				// We decided to cap it at 3 mounts so as not to fire excessive events in a potential HOT scenario where there are many remounts
				if (getState().mountCount > 1 && getState().mountCount <= 3) {
					fireOperationalAnalytics(event, 'issueMountCount viewed', {
						...options,
						issueMountCount: getState().mountCount,
					});
				}
			} else if (getState().issueKey == null || issueKey !== getState().issueKey) {
				setState({
					issueKey,
					mountCount: 1,
				});
			}
		},
	fireMountCountAnalyticEvent:
		(event, options) =>
		({ getState }) => {
			fireOperationalAnalytics(event, 'issueMountCount viewed', {
				...options,
				issueMountCount: getState().mountCount,
			});
		},
	resetCount:
		() =>
		({ setState }) => {
			setState({ mountCount: 0 });
		},
};

const Store = createStore({
	initialState,
	actions,
});

export const useIssueMountCounter = createHook(Store);

export const useIssueMountCounterActions = createActionsHook(Store);
