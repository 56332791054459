import {
	type Action,
	FETCH_WORK_SUGGESTIONS,
	SET_WORK_SUGGESTIONS_DATA,
} from '../../actions/work-suggestions/index.tsx';
import type { WorkSuggestionsState } from './types.tsx';

export const defaultState: WorkSuggestionsState = {
	isLoading: false,
	isError: false,
	workSuggestions: undefined,
};

export const workSuggestionsReducer = (
	state: WorkSuggestionsState = defaultState,
	action: Action,
): WorkSuggestionsState => {
	switch (action.type) {
		case FETCH_WORK_SUGGESTIONS:
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case SET_WORK_SUGGESTIONS_DATA:
			return {
				workSuggestions: action.payload,
				isLoading: false,
				isError: false,
			};
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(action as never);
			return state;
	}
};

export default workSuggestionsReducer;
