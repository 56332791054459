/** Represents a validation error that occurs when parsing/serialising component data to the Clipboard API. */
export class ClipboardValidationError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'ClipboardValidationError';
	}

	static unexpectedComponentType(actual: string, expected: string): ClipboardValidationError {
		return new ClipboardValidationError(
			`Receive component type '${actual}' when '${expected}' was expected.`,
		);
	}

	static unsupportedFormat(format: string): ClipboardValidationError {
		return new ClipboardValidationError(`Unsupported data transfer format '${format}'.`);
	}
}
