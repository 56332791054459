import React, {
	useEffect,
	useCallback,
	useRef,
	type ReactNode,
	useMemo,
	useState,
	Fragment,
} from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import AKTooltip from '@atlaskit/tooltip';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useShouldBeCompact } from '@atlassian/jira-issue-hooks/src/services/use-should-be-compact/index.tsx';
import { QUICK_ADDS } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import {
	ADD_ISSUE_LINKS_ID,
	ADD_CONTENT_PANEL,
	SELECT_CONTENT_PANEL,
	CREATE_SUBTASK_ID,
	CREATE_ISSUE_IN_EPIC_ID,
	CREATE_CHILD_ID,
} from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { ISSUE_FIELD_SPOTLIGHT_PREFIX } from '@atlassian/jira-issue-view-common-constants/src/view-constants.tsx';
import ContentPanelQuickAdd from '@atlassian/jira-issue-view-ecosystem/src/ecosystem-content-panel-quickadd-view.tsx';
import { useLinkAlertsModalActions } from '@atlassian/jira-linked-alerts/src/services/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useRegisterQuickAddInCommandPalette } from '../command-palette/index.tsx';
import { CreateConfluenceContentMenu } from '../create-confluence-content-menu/index.tsx';
import DropdownListItem from '../link-button/ui/dropdown-list-item.tsx';
import LinkButton from '../link-button/view.tsx';
import QuickAddItemView from '../quick-add-item/view.tsx';
import QuickAddItemsCompact from '../quick-add-items-compact/index.tsx';
import type { QuickAddItemAllProps, QuickAddItemToRender } from '../types.tsx';
import getItemListCreator from './item-list/create-item-list.tsx';
import getItemList from './item-list/index.tsx';
import getQuickActionItems from './item-list/quick-action-items.tsx';
import messages from './messages.tsx';
import useExternalQuickActionComponents from './use-external-quick-action-components/index.tsx';
import { isMatch } from './item-list/utils.tsx';

interface QuickAddActionData {
	actions?: ReactNode[];
	apps?: ReactNode;
}

export const QuickAddItems = (props: QuickAddItemAllProps) => {
	const { formatMessage } = useIntl();
	const [, { register }] = useGlobalRefStoreActions();
	const {
		onAddAttachmentClick,
		onAddWebLinkClick,
		onLinkConfluencePageClick,
		fetchConfluenceAppLinks,
		triggerButtonClickAnalytics,
		onSelectContentPanel,
		isServiceDesk,
		isAnonymousUser,
		canCreateAttachment,
		canAddWebLinks,
		canLinkConfluencePage,
		onAddExtension,
		issueKey,
		containerWidth,
		onInsertLoomLinkClick,
		onLinkPage,
	} = props;

	const { shouldBeCompact, setMeasuredRef, forceUpdate } = useShouldBeCompact(containerWidth);
	const [, { openLinkAlertsModal: onLinkAlertsClick }] = useLinkAlertsModalActions();

	const createSubtaskRef = useRef<HTMLElement>(null);
	const linkIssueRef = useRef<HTMLButtonElement>(null);

	const projectKey = useProjectKey(issueKey);
	const tenantContext = useTenantContext();
	const hasPremiumServiceDeskAccess = tenantContext.appEditions.serviceDesk === PREMIUM_EDITION;
	const [searchQuery, setSearchQuery] = useState('');
	const ADD_BUTTON_MENU = 'addButton';

	let isQuickActionsListView = false;
	if (fg('quick_actions_menu_ga')) {
		/**
		 * Changing file level eslint disable to line level disables for rules of hooks
		 * as part of PIR-21595. To be cleaned up when above feature gates are removed.
		 */
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const projectType = useProjectType(projectKey);

		const isJSMProject = projectType === 'service_desk';

		isQuickActionsListView = !isJSMProject && fg('quick_actions_menu_ga');
	}

	const [openMenu, setOpenMenu] = useState<string | null>(null);

	useEffect(() => {
		fetchConfluenceAppLinks();
	}, [fetchConfluenceAppLinks, issueKey]);

	useEffect(() => {
		register('quick-add-items.create-subtask', createSubtaskRef.current);
		register('quick-add-items.link-issue', linkIssueRef.current);
	}, [register, createSubtaskRef, linkIssueRef]);

	const sendAnalyticsEvent = useCallback(
		(id: string, itemIndex: number, handleFunc: () => void) => () => {
			handleFunc();
			if (typeof triggerButtonClickAnalytics === 'function') {
				triggerButtonClickAnalytics(id, itemIndex, 4);
			}
		},
		[triggerButtonClickAnalytics],
	);

	const onAddChildCommand = useMemo(
		() =>
			getItemList({
				...props,
				formatMessage,
				onLinkAlertsClick,
				hasPremiumServiceDeskAccess,
			}).issueActions.find(({ id }) =>
				[CREATE_SUBTASK_ID, CREATE_ISSUE_IN_EPIC_ID, CREATE_CHILD_ID].includes(id),
			)?.onClick,
		[formatMessage, onLinkAlertsClick, props, hasPremiumServiceDeskAccess],
	);

	const shouldRenderAttachCommand = !isServiceDesk && canCreateAttachment;
	useRegisterQuickAddInCommandPalette({
		onAddAttachmentClick,
		onAddAUrlClick: onAddWebLinkClick,
		onLinkConfluencePageClick,
		onAddChildCommand: onAddChildCommand ?? noop,
		isAttachCommandEnabled: shouldRenderAttachCommand,
		isAddAUrlEnabled: canAddWebLinks,
		isLinkConfluencePageEnabled: canLinkConfluencePage,
		isAddChildEnabled: onAddChildCommand !== undefined,
	});

	const createItemViewInline = useCallback(
		(itemToRender: QuickAddItemToRender) => {
			const {
				id,
				tooltip,
				label,
				icon,
				onClick,
				itemIndex,
				testId,
				hasGlobalSpotlightTarget,
				hasSpotlightPulse,
				noWrapper,
			} = itemToRender;

			const quickAddItemViewComponent = (
				<QuickAddItemView
					key={id}
					id={id}
					icon={icon}
					onClick={sendAnalyticsEvent(id, itemIndex, onClick)}
					label={label}
					compactMode={shouldBeCompact}
					isQuickActionsListView={isQuickActionsListView}
					testId={testId}
					hasGlobalSpotlightTarget={hasGlobalSpotlightTarget}
					hasSpotlightPulse={hasSpotlightPulse}
					noWrapper={noWrapper}
					{...(id === CREATE_SUBTASK_ID && { ref: createSubtaskRef })}
				/>
			);
			if (isQuickActionsListView) {
				return quickAddItemViewComponent;
			}
			return (
				<AKTooltip content={tooltip} position="bottom" key={id}>
					{quickAddItemViewComponent}
				</AKTooltip>
			);
		},
		[sendAnalyticsEvent, shouldBeCompact, createSubtaskRef, isQuickActionsListView],
	);

	const createItemViewSegregated = useCallback(
		(itemsToRender: QuickAddItemToRender) => {
			const generatedCallback = getItemListCreator({
				isQuickActionsListView,
				sendAnalyticsEvent,
				shouldBeCompact,
				actionRefs: [{ actionId: CREATE_SUBTASK_ID, ref: createSubtaskRef }],
			});
			return generatedCallback(itemsToRender);
		},
		[isQuickActionsListView, sendAnalyticsEvent, shouldBeCompact, createSubtaskRef],
	);

	const createItemView = expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		? createItemViewSegregated
		: createItemViewInline;

	const { issueActions, linkingActions, ideaActions } = useMemo(
		() =>
			getItemList({
				...props,
				formatMessage,
				onLinkAlertsClick,
				isQuickActionsListView,
				hasPremiumServiceDeskAccess,
			}),
		[formatMessage, hasPremiumServiceDeskAccess, isQuickActionsListView, onLinkAlertsClick, props],
	);

	const ideaButtons = useMemo(
		() => ideaActions.filter((item) => isMatch(searchQuery, item.label)).map(createItemView),
		[createItemView, ideaActions, searchQuery],
	);

	const mappedIssueActions = useMemo(
		() => issueActions.map(createItemView),
		[createItemView, issueActions],
	);

	const linkDropdownButtonCompactLabelMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.linkDropdownButtonCompactLabelIssueTermRefresh
		: messages.linkDropdownButtonCompactLabel;

	const dropdownMessage = shouldBeCompact
		? linkDropdownButtonCompactLabelMessage
		: messages.linkDropdownButtonLabel;
	const dropdownTooltipLabel = formatMessage(dropdownMessage);

	const linkButton = useMemo(
		() => (
			<UFOSegment name="quick-add-link-button">
				<JSErrorBoundary
					id="quick-add-link-button"
					packageName="jiraQuickActions"
					teamName="bento"
					fallback="unmount"
				>
					<LinkButton
						compactMode={shouldBeCompact}
						actions={linkingActions}
						tooltipLabel={dropdownTooltipLabel}
						ref={linkIssueRef}
						isQuickActionsListView={isQuickActionsListView}
					/>
				</JSErrorBoundary>
			</UFOSegment>
		),
		[dropdownTooltipLabel, isQuickActionsListView, linkingActions, shouldBeCompact],
	);

	const linkButtonActions = useMemo(
		() =>
			isQuickActionsListView
				? [
						fg('concurrent-rendering-fix-unique-list-key') ? (
							<Fragment key="quick-add-link-button">{linkButton}</Fragment>
						) : (
							linkButton
						),
					]
				: [
						<SpotlightTarget
							key="quick-add-web-link-button"
							name={`${ISSUE_FIELD_SPOTLIGHT_PREFIX}${ADD_ISSUE_LINKS_ID}`}
						>
							{linkButton}
						</SpotlightTarget>,
					],
		[isQuickActionsListView, linkButton],
	);

	const ecosystemActions = useMemo(
		() => (
			<ContentPanelQuickAdd
				key="quick-add-content-panel"
				externalId="issue.ecosystem.quick-add-content-panel"
				// @ts-expect-error - TS7006 - Parameter 'addonModuleKey' implicitly has an 'any' type.
				onSelectContentPanel={(addonModuleKey) => {
					onSelectContentPanel(addonModuleKey);
					triggerButtonClickAnalytics(SELECT_CONTENT_PANEL, 3, 4);
				}}
				// @ts-expect-error - TS7006 - Parameter 'extension' implicitly has an 'any' type.
				onAddExtension={(extension) => {
					onAddExtension(extension);
					triggerButtonClickAnalytics(ADD_CONTENT_PANEL, 3, 4);
				}}
				createItemView={createItemView}
				startIndex={mappedIssueActions.length}
				isQuickActionsListView={isQuickActionsListView}
			/>
		),
		[
			createItemView,
			isQuickActionsListView,
			mappedIssueActions.length,
			onAddExtension,
			onSelectContentPanel,
			triggerButtonClickAnalytics,
		],
	);

	const ACTIONS_NATIVE = useMemo(
		() =>
			isQuickActionsListView &&
			expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
				? getQuickActionItems({
						...props,
						formatMessage,
						onLinkAlertsClick,
						isQuickActionsListView,
						hasPremiumServiceDeskAccess,
					})
				: {},
		[formatMessage, hasPremiumServiceDeskAccess, isQuickActionsListView, onLinkAlertsClick, props],
	);

	const {
		components: [
			designActions,
			formsActions,
			developerEscalationsAction,
			changeManagementActions,
			reviewButton,
			suggestResourcesAction,
			asyncConfluenceContentMenu,
			loomVideoButtons,
			confluenceButtons,
		],
		actions: { setQuickActionListItem },
		crossflow,
	} = useExternalQuickActionComponents({
		isAnonymousUser,
		onInsertLoomLinkClick,
		shouldBeCompact,
		forceUpdate,
		projectKey,
		issueKey,
		isQuickActionsListView,
		canCreateIssueLink: props.canCreateIssueLink,
		onAddIssueLinkClick: props.onAddIssueLinkClick,
		createItemView,
		onLinkPage,
		searchQuery,
		staticComponents: {
			confluencePage:
				ACTIONS_NATIVE.linkConfluencePage?.shouldRender &&
				isMatch(searchQuery, ACTIONS_NATIVE.linkConfluencePage.label)
					? createItemView(ACTIONS_NATIVE.linkConfluencePage)
					: null,
			confluenceWhiteboard:
				ACTIONS_NATIVE.linkConfluenceWhiteboard?.shouldRender &&
				isMatch(searchQuery, ACTIONS_NATIVE.linkConfluenceWhiteboard.label)
					? createItemView(ACTIONS_NATIVE.linkConfluenceWhiteboard)
					: null,
		},
	});

	const coreIssueViewJTBD = useMemo(() => {
		if (
			!isQuickActionsListView ||
			!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			return [];
		}

		const actions = [
			ACTIONS_NATIVE.subtask,
			ACTIONS_NATIVE.issueInEpic,
			ACTIONS_NATIVE.createChild,
			ACTIONS_NATIVE.addIssueLinks,
		];
		return actions
			.filter((item) => item.shouldRender && isMatch(searchQuery, item.label))
			.map(createItemView);
	}, [ACTIONS_NATIVE, createItemView, isQuickActionsListView, searchQuery]);

	const secondaryIssueViewJTBD = useMemo(() => {
		if (
			!isQuickActionsListView ||
			!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			return [];
		}

		return [
			...[ACTIONS_NATIVE.attachment]
				.filter((item) => item.shouldRender && isMatch(searchQuery, item.label))
				.map(createItemView),
			...formsActions
				.filter(({ label }) => isMatch(searchQuery, label))
				.map(({ component }) => component),
			...[ACTIONS_NATIVE.addWebLink]
				.filter((item) => item.shouldRender && isMatch(searchQuery, item.label))
				.map((item) => <DropdownListItem key={item.id} item={item} />),
		];
	}, [ACTIONS_NATIVE, createItemView, formsActions, isQuickActionsListView, searchQuery]);

	const external2PQuickActions = useMemo(
		() =>
			isQuickActionsListView &&
			expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
				? [
						...[ACTIONS_NATIVE.linkConfluenceListView]
							.filter((item) => item.shouldRender && isMatch(searchQuery, item.label))
							.map((item) => <DropdownListItem key={item.id} item={item} />),
						// following buttons will fall into 2P hierarchical menu or
						// will be rendered in the cross-flow section - pending
						...confluenceButtons
							.filter(({ label }) => isMatch(searchQuery, label))
							.map(({ component }) => component),
						...loomVideoButtons
							.filter(({ label }) => isMatch(searchQuery, label))
							.map(({ component }) => component),
						...ideaButtons,
					]
				: [],

		[
			ACTIONS_NATIVE,
			confluenceButtons,
			ideaButtons,
			isQuickActionsListView,
			loomVideoButtons,
			searchQuery,
		],
	);

	const getQuickAddActionData = useCallback((): QuickAddActionData | ReactNode[] => {
		if (
			isQuickActionsListView &&
			expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			return {
				actions: [coreIssueViewJTBD, secondaryIssueViewJTBD, external2PQuickActions],
				apps: ecosystemActions,
			};
		}

		const formsActionsComponents = formsActions.map(({ component }) => component);
		const confluenceButtonComponents = confluenceButtons.map(({ component }) => component);
		const loomVideoButtonsComponents = loomVideoButtons.map(({ component }) => component);
		const designActionComponents = designActions.map(({ component }) => component);
		const suggestResourcesActionComponents = suggestResourcesAction.map(
			({ component }) => component,
		);
		const changeManagementActionComponents = changeManagementActions.map(
			({ component }) => component,
		);
		const reviewButtonComponents = reviewButton.map(({ component }) => component);
		const developerEscalationsActionComponents = developerEscalationsAction.map(
			({ component }) => component,
		);
		const asyncConfluenceContentMenuComponents = asyncConfluenceContentMenu.map(
			({ component }) => component,
		);

		if (isQuickActionsListView) {
			return {
				actions: [
					// no JSM related actions in this list as quick
					// actions dropdown will not be enabled for JSM
					...mappedIssueActions,
					...linkButtonActions,
					...confluenceButtonComponents,
					...ideaButtons,
					...loomVideoButtonsComponents,
					...formsActionsComponents,
					...designActionComponents,
				],
				apps: ecosystemActions,
			};
		}

		return [
			...mappedIssueActions,
			...suggestResourcesActionComponents,
			linkButtonActions,
			...ideaButtons,
			...changeManagementActionComponents,
			...formsActionsComponents,
			...reviewButtonComponents,
			...developerEscalationsActionComponents,
			...designActionComponents,
			...asyncConfluenceContentMenuComponents,
			ecosystemActions,
		];
	}, [
		isQuickActionsListView,
		mappedIssueActions,
		suggestResourcesAction,
		linkButtonActions,
		ideaButtons,
		changeManagementActions,
		formsActions,
		reviewButton,
		developerEscalationsAction,
		designActions,
		asyncConfluenceContentMenu,
		ecosystemActions,
		coreIssueViewJTBD,
		secondaryIssueViewJTBD,
		external2PQuickActions,
		confluenceButtons,
		loomVideoButtons,
	]);

	const quickAddActionDataResult = fg('react-concurrent-quick-actions-fix')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => getQuickAddActionData(), [getQuickAddActionData])
		: null;

	if (isQuickActionsListView) {
		const quickAddActionData =
			quickAddActionDataResult !== null && fg('react-concurrent-quick-actions-fix')
				? quickAddActionDataResult
				: getQuickAddActionData();

		if ('actions' in quickAddActionData && 'apps' in quickAddActionData) {
			const { actions, apps } = quickAddActionData;
			const setMenuOpen = (menuName: string, isOpen: boolean) => {
				setOpenMenu(isOpen ? menuName : null);
				if (menuName === ADD_BUTTON_MENU) {
					setSearchQuery('');
				}
			};

			const { availableAddons, canInstallAddons } = props;

			const renderActions = () => {
				return (
					<>
						{actions && actions.length ? (
							<QuickAddWrapper ref={setMeasuredRef('quick-add-items')}>
								<QuickAddItemsCompact
									actions={actions}
									apps={apps}
									crossflow={crossflow}
									availableAddons={availableAddons}
									canInstallAddons={canInstallAddons}
									setMenuOpen={setMenuOpen}
									openMenu={openMenu}
									onSearch={setSearchQuery}
									searchQuery={searchQuery}
								/>
								<CreateConfluenceContentMenu
									key="create-confluence-content-menu"
									appearance={shouldBeCompact ? 'compact' : ''}
									isQuickActionsListView={isQuickActionsListView}
									setQuickActionListItem={setQuickActionListItem}
									onLinkPage={onLinkPage}
									openMenu={openMenu}
								/>
							</QuickAddWrapper>
						) : (
							<></>
						)}
					</>
				);
			};

			return (
				<UFOSegment name="issue-quick-add-items">
					<ErrorBoundary id="jira.issuebase.quickadditems">
						{fg('quick_actions_menu_ga') ? (
							<>{renderActions()}</>
						) : (
							<SpotlightTarget name={QUICK_ADDS}>{renderActions()}</SpotlightTarget>
						)}
					</ErrorBoundary>
				</UFOSegment>
			);
		}
	}

	const actions =
		quickAddActionDataResult !== null && fg('react-concurrent-quick-actions-fix')
			? quickAddActionDataResult
			: getQuickAddActionData();

	return (
		<UFOSegment name="issue-quick-add-items">
			<ErrorBoundary id="jira.issuebase.quickadditems">
				<SpotlightTarget name={QUICK_ADDS}>
					{Array.isArray(actions) && actions.length ? (
						<QuickAddWrapper ref={setMeasuredRef('quick-add-items')}>{actions}</QuickAddWrapper>
					) : (
						<div />
					)}
				</SpotlightTarget>
			</ErrorBoundary>
		</UFOSegment>
	);
};

QuickAddItems.defaultProps = {
	canCreateIssueLink: false,
	canCreateAttachment: false,
	canLinkConfluencePage: false,
	canAddWebLinks: false,
	triggerButtonClickAnalytics: noop,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const QuickAddWrapper = styled.div({
	marginBottom: token('space.050', '4px'),
	marginTop: token('space.050', '4px'),
	paddingTop: token('space.050', '4px'),
	paddingRight: 0,
	paddingBottom: token('space.050', '4px'),
	paddingLeft: 0,
	display: 'inline-flex',
});
