import { useCallback } from 'react';
import type { DocNode } from '@atlaskit/adf-schema';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { PROBLEM_MANAGEMENT } from '@atlassian/jira-issue-create-common-types/src/common/constants/graphql-field-types.tsx';
import type { CreatedIssueWithIssueData } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import type { Payload, Field } from '@atlassian/jira-issue-create/src/common/types/index.tsx';
import { ISSUE_VIEW_CREATE_LINKED_ISSUE } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { TriggerPointKeyType } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { convertTextToAdf } from '@atlassian/jira-rich-content/src/common/convert-text-to-adf/index.tsx';
import type { ProblemTicketSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-ops-panel-resources/src/common/types.tsx';
import { useGetRequestTypesForIssueCreate } from '@atlassian/jira-servicedesk-get-request-types-for-issue-create/src/index.tsx';
import type { RequestType } from '@atlassian/jira-servicedesk-get-request-types-for-issue-create/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { importForInteraction } from '@atlassian/react-async';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useGetCustomFields } from '../../../services/gic-custom-fields/index.tsx';

export const AFFECTED_SERVICES = 'Affected services';

export const ROOT_CAUSE = 'Root cause';

type CustomField = {
	[AFFECTED_SERVICES]: string | null;
	[ROOT_CAUSE]: string | null;
};

const getCreateSuggestionsAsync = () =>
	importForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-jira-servicedesk-ai-context-ops-panel-create-suggestions" */ './create-suggestions'
			),
	);

export type OnIssueCreate = (data: CreatedIssueWithIssueData) => void;

export type UseCreateProblemFromIncidentProps = {
	onIssueCreate: OnIssueCreate;
};

export const parseToADF = (adfString: string): DocNode | null => {
	try {
		return JSON.parse(adfString);
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'ProblemTicket.parseToADF',
				packageName: 'jiraServicedeskAIContextOpsPanel',
				teamName: 'itsm-aiops-grogu',
			},
			error:
				error instanceof Error
					? error
					: new Error('Something went wrong while parsing ADF for problem ticket description'),
		});

		return null;
	}
};

export const getIssueAndRequestType = (requestTypes: RequestType[]) => {
	const searchByIssueTypes = requestTypes.map((requestType) => requestType.issueTypeId).join(', ');
	const searchByRequestTypes = requestTypes.map((requestType) => requestType.id).join(', ');
	const issueTypeId = requestTypes.length ? requestTypes[0].issueTypeId : undefined;
	if (issueTypeId != null) {
		return {
			issueTypeId,
			issueTypes: searchByIssueTypes,
			requestTypes: searchByRequestTypes,
		};
	}
	return null;
};

export const getGICPayloadDefaultValues = (
	issueKey: string,
	defaultData: ProblemTicketSuggestedAction['context'] | null,
	customField: CustomField,
) => {
	let defaultValues: Field = {
		issueLinks: {
			linkTypeName: 'causes', // If changes GIC fallback to most recently used
			linkedIssueKeys: [issueKey],
		},
	};
	const { suggestion } = defaultData || {};
	if (defaultData && suggestion) {
		defaultValues = {
			...defaultValues,
			singleLineTextFields: [{ fieldId: 'summary', text: suggestion.summary }],
			richTextFields: [
				...(suggestion.description
					? [
							{
								fieldId: 'description',
								richText: { adfValue: parseToADF(suggestion.description) || getEmptyADF() },
							},
						]
					: []),

				...(customField[ROOT_CAUSE]
					? [
							{
								fieldId: customField[ROOT_CAUSE],
								richText: { adfValue: convertTextToAdf(suggestion.rootCause) },
							},
						]
					: []),
			],
			...(customField[AFFECTED_SERVICES]
				? {
						affectedServicesField: {
							fieldId: customField[AFFECTED_SERVICES],
							affectedServices: suggestion.affectedServices.map((service) => ({
								serviceId: service.id,
							})),
						},
					}
				: {}),
		};
	}
	return defaultValues;
};
export function useCreateProblemFromIncident({ onIssueCreate }: UseCreateProblemFromIncidentProps) {
	const [createState, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { data: project, loading: projectLoading } = useProjectContext();
	const projectId = project?.projectId;
	const [{ data: requestTypes, loading: requestTypesLoading }] = useGetRequestTypesForIssueCreate({
		projectId,
		filter: { practices: [PROBLEM_MANAGEMENT] },
	});
	let issueAndRequestType = null;
	const loading = projectLoading || requestTypesLoading;
	if (!loading && requestTypes) {
		issueAndRequestType = getIssueAndRequestType(requestTypes);
	}
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const [{ data: customFields, loading: customFieldLoading }] = useGetCustomFields(
		{
			projectId,
			issueTypeId: issueAndRequestType ? issueAndRequestType.issueTypeId : undefined,
		},
		[AFFECTED_SERVICES, ROOT_CAUSE],
	);

	const openProblemCreateModal = useCallback(
		(
			triggerPointKey: TriggerPointKeyType,
			title: MessageDescriptor,
			defaultSuggestion: ProblemTicketSuggestedAction['context'],
		) => {
			let payload: Payload = {};
			if (!loading && projectId && issueId) {
				payload = {
					...(issueAndRequestType?.issueTypeId
						? {
								issueType: { issueTypeId: issueAndRequestType.issueTypeId },
								issueTypes: issueAndRequestType.issueTypes,
								requestTypes: issueAndRequestType.requestTypes,
							}
						: {}),
					project: { projectId: projectId.toString() },
				};
			}
			payload.customFieldsToPreserveOnFormRecreation = [
				'issuelinks',
				'summary',
				...Object.keys(customFields || {}),
			];

			payload.defaultValues = getGICPayloadDefaultValues(issueKey, defaultSuggestion, {
				[AFFECTED_SERVICES]: customFields?.[AFFECTED_SERVICES] || null,
				[ROOT_CAUSE]: customFields?.[ROOT_CAUSE] || null,
			});

			if (fg('aiops_context_panel_problem_ticket_suggestion')) {
				payload.defaultValues = undefined;
				getCreateSuggestionsAsync().onComplete((createSuggestionsAync) => {
					openIssueCreateModal({
						title: formatMessage(title),
						payload,
						triggerPointKey,
						displayCreateAnotherIssueToggle: false,
						callbackPayload: {
							id: ISSUE_VIEW_CREATE_LINKED_ISSUE,
							data: {
								sourceIssueId: issueId,
							},
						},
						onIssueCreate,
						FieldWrapperComponent: createSuggestionsAync(defaultSuggestion.suggestion, {
							SUMMARY: 'summary',
							DESCRIPTION: 'description',
							ROOT_CAUSE: customFields?.[ROOT_CAUSE],
							AFFECTED_SERVICES: customFields?.[AFFECTED_SERVICES],
						}),
					});
				});
			} else {
				openIssueCreateModal({
					title: formatMessage(title),
					payload,
					triggerPointKey,
					displayCreateAnotherIssueToggle: false,
					callbackPayload: {
						id: ISSUE_VIEW_CREATE_LINKED_ISSUE,
						data: {
							sourceIssueId: issueId,
						},
					},
					onIssueCreate,
					FieldWrapperComponent: undefined,
				});
			}
		},
		[
			loading,
			projectId,
			issueId,
			customFields,
			issueKey,
			openIssueCreateModal,
			formatMessage,
			onIssueCreate,
			issueAndRequestType,
		],
	);
	return {
		createState,
		openProblemCreateModal,
		loading: loading || customFieldLoading,
		isDisabled: loading || customFieldLoading, // Disable until API are loaded
	};
}
