import React, {
	useCallback,
	useMemo,
	useRef,
	useEffect,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type MouseEvent,
	type KeyboardEvent,
} from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey, useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useCanConfigureStatus,
	shouldShowConfigureIssueTypeOrStatusOption,
	defaultModalProps,
} from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import { useProjectType } from '@atlassian/jira-project-context-service/src/main.tsx';
import { ISSUE_VIEW_SOURCE } from '@atlassian/jira-issue-status-and-types-common/src/types.tsx';
import { asyncCreateIssueTypeModalEntryPoint } from '@atlassian/jira-create-issue-type-modal/entrypoint.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import TriggerButton from './trigger-button.tsx';
import messages from './messages.tsx';

const ISSUE_TYPE_COUNT_LIMIT = 30;

export type OwnProps = {
	icon: ReactElement<ComponentProps<typeof AsyncIcon>>;
	value?: IssueType;
};

export type Props = {
	isOpen: boolean;
	issueId: IssueId | null;
	issueTypes?: IssueType[];
} & OwnProps & {
		showIssueTypeList: (arg1: boolean) => void;
		loadIssueTypes: (arg1: string) => void;
		changeIssueType: (
			arg1: IssueKey,
			arg2: IssueId,
			arg3: IssueType,
			arg4: IssueType,
			arg5: UIAnalyticsEvent,
		) => void;
	};

const filterSubTaskTypePredicate = (currentType: IssueType, targetType: IssueType): boolean => {
	if (currentType.subtask) {
		return targetType.subtask;
	}
	return true;
};

export const ChangeIssueType = ({
	isOpen,
	icon,
	issueTypes,
	issueId,
	showIssueTypeList,
	loadIssueTypes,
	changeIssueType,
	value,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const issueKey = useIssueKey();

	const currentType = fg('relay-migration-issue-header-and-parent')
		? value
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useFieldValue({
				issueKey,
				fieldKey: 'issuetype',
			})[0];

	const projectKey = useProjectKey();
	const cloudId = useCloudId();
	const projectType = useProjectType(projectKey);

	const canConfigureIssueType = useCanConfigureStatus(projectKey);
	const showConfigureIssueType = shouldShowConfigureIssueTypeOrStatusOption(projectType ?? null);
	const isIssueTypeConfigEnabled = canConfigureIssueType && showConfigureIssueType;

	const handleItemClick = useCallback(
		(nextType: IssueType) => {
			const analyticsEvent = createAnalyticsEvent({});
			if (issueId !== null) {
				changeIssueType(
					issueKey,
					issueId,
					currentType,
					nextType,
					// todo: check context componentName
					analyticsEvent,
				);
				if (analyticsEvent && fg('one_event_rules_them_all_fg')) {
					getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(ISSUE_TYPE, {
						analytics: analyticsEvent,
						attributes: {
							fieldType: ISSUE_TYPE,
							oldValId: currentType.id,
							newValId: nextType.id,
						},
					});
				}

				showIssueTypeList(false);
			}
		},
		[changeIssueType, createAnalyticsEvent, currentType, issueId, issueKey, showIssueTypeList],
	);

	const handleOpenChange = useCallback(
		({
			isOpen: eventPropIsOpen,
		}: {
			isOpen: boolean;
			event?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>;
		}) => {
			showIssueTypeList(eventPropIsOpen);
		},
		[showIssueTypeList],
	);

	const handleButtonClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
		(_, analyticsEvent) => {
			if (projectKey) {
				if (!isOpen) {
					if (fg('one_event_rules_them_all_fg')) {
						getUpdateAnalyticsFlowHelper().fireAnalyticsStart(ISSUE_TYPE, {
							analytics: createAnalyticsEvent({}),
							attributes: {
								fieldType: ISSUE_TYPE,
							},
						});
					}
					// todo: check this event, missing atlaskit button info
					fireUIAnalytics(
						analyticsEvent.update({
							name: 'changeIssueTypeDropdown',
						}),
						'button clicked',
						'changeIssueTypeDropdownButton',
					);
				}
				showIssueTypeList(!isOpen);
			}
		},
		[createAnalyticsEvent, isOpen, projectKey, showIssueTypeList],
	);

	const renderedIssueTypes = useMemo(():
		| ReactElement<ComponentProps<typeof DropdownItem>>[]
		| null => {
		if (currentType && issueTypes && issueTypes.length !== 0) {
			const transformedIssueTypes = issueTypes
				.filter((type: IssueType) => type.id !== currentType.id)
				.filter((type: IssueType) => filterSubTaskTypePredicate(currentType, type))
				.map((issueType: IssueType) => (
					<DropdownItem
						testId={`issue.views.issue-base.foundation.change-issue-type.item.${issueType.id}`}
						key={issueType.id}
						elemBefore={<AsyncIcon url={issueType.iconUrl} alt="" />}
						onClick={() => handleItemClick(issueType)}
					>
						{issueType.name}
					</DropdownItem>
				));

			return [
				<DropdownItem
					testId={`issue.views.issue-base.foundation.change-issue-type.item.${currentType.id}`}
					key={currentType.id}
					elemBefore={<AsyncIcon url={currentType.iconUrl} alt="" />}
					onClick={() => handleItemClick(currentType)}
				>
					{currentType.name}
				</DropdownItem>,
				...transformedIssueTypes,
			];
		}
		return null;
	}, [currentType, handleItemClick, issueTypes]);

	const firstRun = useRef(true);
	useEffect(() => {
		// only load issues on component updates
		if (!firstRun.current && projectKey) {
			loadIssueTypes(projectKey);
		} else {
			firstRun.current = false;
		}
	}, [projectKey, isOpen, loadIssueTypes]);

	const renderTriggerButton = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'triggerRef' implicitly has an 'any' type.
		({ triggerRef, ...props }) => (
			<TriggerButton
				{...props}
				icon={icon}
				issueType={currentType && currentType.name ? currentType.name : ''}
				onClick={handleButtonClick}
				ref={triggerRef}
			/>
		),
		[currentType, handleButtonClick, icon],
	);

	const handleCreateIssueTypeButtonClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'createIssueTypeButton',
		);
	};

	const handleCreateIssueTypeModalClose = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'inlineCreateIssueTypeModal',
				action: 'closed',
			}),
			{
				calledFrom: ISSUE_VIEW_SOURCE,
			},
		);
	};

	const entryPointProps = {
		onIssueTypeCreated: noop,
		onClose: handleCreateIssueTypeModalClose,
		source: ISSUE_VIEW_SOURCE,
		issueTypesCountLimit: ISSUE_TYPE_COUNT_LIMIT,
	};

	const entryPointParams = {
		projectKey,
		cloudId,
	};

	return (
		<DropdownMenu
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.issue-base.foundation.change-issue-type.dropdown"
			trigger={renderTriggerButton}
			placement="bottom-start"
			appearance="default"
			// @ts-expect-error - TS2769 - No overload matches this call.
			onOpenChange={handleOpenChange}
			isOpen={isOpen}
			isTriggerNotTabbable
		>
			<DropdownItemGroup
				title={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.changeTypeGroupNameIssueTermRefresh
						: messages.changeTypeGroupName,
				)}
			>
				{renderedIssueTypes}
			</DropdownItemGroup>
			{isIssueTypeConfigEnabled &&
				expVal('jira_issue_view_issue_type_configuration', 'isEnabled', false) && (
					<DropdownItemGroup hasSeparator>
						<ModalEntryPointPressableTrigger
							entryPoint={asyncCreateIssueTypeModalEntryPoint}
							entryPointProps={entryPointProps}
							entryPointParams={entryPointParams}
							interactionName="create-issue-type-modal"
							modalProps={defaultModalProps}
							useInternalModal={false}
						>
							{({ ref }) => (
								<DropdownItem onClick={handleCreateIssueTypeButtonClick} ref={ref}>
									{formatMessage(
										fg('jira-issue-terminology-refresh-m3')
											? messages.createWorkTypeButton
											: messages.createIssueTypeButton,
									)}
								</DropdownItem>
							)}
						</ModalEntryPointPressableTrigger>
					</DropdownItemGroup>
				)}
		</DropdownMenu>
	);
};

export default ChangeIssueType;
