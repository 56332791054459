/** @jsx jsx */
import type { PropsWithChildren } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const StyleWrapper = ({ children }: PropsWithChildren) => {
	return <div css={styles}>{children}</div>;
};

const styles = css({
	lineHeight: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	pre: {
		overflow: 'auto',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		color: token('color.link'),
	},
	marginTop: '0',
	marginBottom: '0',
	overflow: 'hidden',
});

export default StyleWrapper;
