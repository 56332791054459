import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { CommentId } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { commentExperienceDescription } from '@atlassian/jira-issue-view-common-utils/src/experience-tracking/comment-experience-tracking.tsx';
import {
	deleteCommentSuccess,
	deleteCommentFailure,
	DELETE_COMMENT_REQUEST,
	type DeleteCommentRequestAction,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { entitiesCommentsSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { hasMediaFileNodes } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { extractTraceId } from '@atlassian/jira-software-sla-tracker/src/services/extract-trace-id/index.tsx';

const deleteOptions = { method: 'DELETE' } as const;

const getDeletionUrl = (
	// @ts-expect-error - TS2304 - Cannot find name 'BaseUrl'.
	baseUrl: BaseUrl,
	// @ts-expect-error - TS2304 - Cannot find name 'IssueKey'.
	issueKey: IssueKey,
	commentId: CommentId,
	parentId?: CommentId,
) =>
	`${baseUrl}/rest/api/2/issue/${issueKey}/comment/${commentId}${parentId ? `?parentId=${parentId}` : ''}`;

// We interpret NOT_FOUND as a successful deletion. This allows for
// parallel deletion of a comment without either user receiving an error.
const handleDeleteCommentError = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any,
	id: CommentId,
	parentId?: CommentId,
	hasReplies?: boolean,
) => {
	trackOrLogClientError('issue.comment.delete', 'Could not delete a comment', error);
	return Observable.of(
		error.statusCode === NOT_FOUND
			? deleteCommentSuccess(id, parentId, hasReplies)
			: deleteCommentFailure(id),
	);
};

export const deleteComment = (
	state: State,
	id: CommentId,
	attachmentActions: AttachmentServiceActions,
	parentId?: CommentId,
	hasReplies?: boolean,
) => {
	const baseUrl = baseUrlSelector(state);
	const issueKey = toIssueKey(issueKeySelector(state));
	const deleteUrl = getDeletionUrl(baseUrl, issueKey, id, parentId);

	const { bodyAdf, eventOccurredAt, jsdIncidentActivityViewHidden } =
		entitiesCommentsSelector(state)[id];
	const shouldRefetchAttachment = bodyAdf ? hasMediaFileNodes(bodyAdf) : false;
	return fetchJson$(deleteUrl, deleteOptions)
		.map(() => {
			sendExperienceAnalytics({
				getExperienceDescription: () =>
					commentExperienceDescription({
						wasSuccessful: true,
						action: 'DELETE',
						analyticsSource: 'commentDeleteEpic',
						projectType: state.entities.project?.projectType,
						...(fg('jsm-timelines-phase-2')
							? {
									isEventOccurredAtSelected: Boolean(eventOccurredAt),
									isJsdIncidentActivityViewHidden: Boolean(jsdIncidentActivityViewHidden),
								}
							: {}),
					}),
			});
			shouldRefetchAttachment && attachmentActions.refreshAttachments(issueKey);
			return deleteCommentSuccess(id, parentId, hasReplies);
		})
		.catch((error) => {
			sendExperienceAnalytics({
				getExperienceDescription: ({ wasSuccessful, statusCode }) =>
					commentExperienceDescription({
						wasSuccessful,
						action: 'DELETE',
						analyticsSource: 'commentDeleteEpic',
						projectType: state.entities.project?.projectType,
						errorMessage: wasSuccessful ? undefined : error.message,
						statusCode,
						...(fg('jsm-timelines-phase-2')
							? {
									isEventOccurredAtSelected: Boolean(eventOccurredAt),
									isJsdIncidentActivityViewHidden: Boolean(jsdIncidentActivityViewHidden),
								}
							: {}),
						traceId: extractTraceId(error),
					}),
				error,
			});
			return handleDeleteCommentError(error, id, parentId, hasReplies);
		});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (attachmentActions: AttachmentServiceActions) =>
	(action$: ActionsObservable<DeleteCommentRequestAction>, store: MiddlewareAPI<State>) =>
		action$.ofType(DELETE_COMMENT_REQUEST).mergeMap((action) => {
			const state = store.getState();
			const { id: commentId, hasReplies, parentId } = action.payload;
			return deleteComment(state, commentId, attachmentActions, parentId, hasReplies);
		});
