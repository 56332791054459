import React, { useCallback, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { ProjectOption } from '../../common/types.tsx';

export type Props = {
	value: ProjectOption | null;
	noValueText?: string;
	onClick?: (e: MouseEvent | KeyboardEvent) => void;
	/**
	 * When `true` the project link text will cut off and show ellipsis if it exceeds the available space.
	 * When `false` the project link text will break onto the next line.
	 * Default value is `true`
	 */
	isTruncated?: boolean;
};

export const ProjectReadView = ({ value, noValueText, onClick, isTruncated = true }: Props) => {
	const onClickLink = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
		(e) => {
			e.stopPropagation();
			onClick && onClick(e);
		},
		[onClick],
	);

	if (value === undefined || value === null) {
		return (
			<Box xcss={emptyValueContainerStyles}>
				<EmptyValue data-testid="issue-field-project.ui.view-read-view-empty-value">
					{noValueText || ''}
				</EmptyValue>
			</Box>
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const stopPropagation = (e: any) => {
		e.stopPropagation();
	};

	return (
		<ReadViewContainer data-component-selector="project-read-view-container">
			{Boolean(value.avatarUrls['48x48']) && (
				<AvatarWrapper>
					<Avatar
						appearance="square"
						size="xsmall"
						src={value.avatarUrls['48x48']}
						borderColor="transparent"
						testId="issue-field-project.ui.project-avatar"
					/>
				</AvatarWrapper>
			)}
			<Box xcss={labelContainerStyles}>
				<Anchor
					testId="issue-field-project.ui.view-label-link"
					onClick={onClickLink}
					onKeyUp={stopPropagation}
					onKeyDown={stopPropagation}
					href={`/projects/${value.key}`}
					xcss={[
						labelLinkStylesBase,
						isTruncated ? labelLinkStylesTruncated : labelLinkStylesNonTruncated,
					]}
				>
					{value.name}
				</Anchor>
			</Box>
		</ReadViewContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyValue = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});

// Need below changes to deal with correct layout

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	// Max width follows tag layout https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/design-system/tag/src/constants.ts
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${25 * gridSize}px`,
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	paddingLeft: token('space.050', '4px'),
});

const emptyValueContainerStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div<{
	children?: ReactNode;
}>({
	marginRight: token('space.100', '8px'),
	display: 'flex',
});

const labelContainerStyles = xcss({
	display: 'inline-flex',
	minWidth: '0',
	marginRight: 'space.100',
});

const labelLinkStylesBase = xcss({
	display: 'inline-block',
	maxWidth: '100%',
	textDecoration: 'none',
	':hover': {
		textDecoration: 'underline',
	},
});

const labelLinkStylesTruncated = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const labelLinkStylesNonTruncated = xcss({ overflowWrap: 'anywhere' });
