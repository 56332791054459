import React from 'react';
import { Text } from '@atlaskit/primitives';

export const DropdownSectionHeader = ({ label }: { label: string }) => {
	return (
		<Text weight="bold" size="small" color="color.text.subtle">
			{label}
		</Text>
	);
};
