import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State, BaseProps } from '../model/types.tsx';
import type { ToggleWatchingSource } from './types.tsx';

// INITIALIZE APP PROPS
export const INITIALIZE_APP_PROPS = 'state.INITIALIZE_APP_PROPS' as const;

export type InitializeAppPropsAction = {
	type: typeof INITIALIZE_APP_PROPS;
	payload: State;
};

export const initializeAppProps = (payload: State): InitializeAppPropsAction => ({
	type: INITIALIZE_APP_PROPS,
	payload,
});

// REFRESH APP PROPS
export const REFRESH_APP_PROPS = 'state.REFRESH_APP_PROPS' as const;

export type RefreshAppPropsAction = {
	type: typeof REFRESH_APP_PROPS;
	payload: BaseProps;
};

export const refreshAppProps = (payload: BaseProps): RefreshAppPropsAction => ({
	type: REFRESH_APP_PROPS,
	payload,
});

export type ToggleWatchingPayload = {
	analyticsEvent?: UIAnalyticsEvent;
	source: ToggleWatchingSource;
	onSuccess?: (source: string, experience: string) => void;
	onError?: (source: string, experience: string, error?: Error | FetchError) => void;
};

// TOGGLE WATCHING REQUEST
export const TOGGLE_WATCHING_REQUEST = 'state.TOGGLE_WATCHING_REQUEST' as const;

export type ToggleWatchingRequestAction = {
	type: typeof TOGGLE_WATCHING_REQUEST;
	payload: ToggleWatchingPayload;
};

export const toggleWatchingRequest = (
	payload: ToggleWatchingPayload,
): ToggleWatchingRequestAction => ({
	type: TOGGLE_WATCHING_REQUEST,
	payload,
});

// START WATCHING SUCCESS
export const START_WATCHING_SUCCESS = 'state.START_WATCHING_SUCCESS' as const;

export type StartWatchingSuccessAction = {
	type: typeof START_WATCHING_SUCCESS;
};

export const startWatchingSuccess = (): StartWatchingSuccessAction => ({
	type: START_WATCHING_SUCCESS,
});

// START WATCHING FAILURE
export const START_WATCHING_FAILURE = 'state.START_WATCHING_FAILURE' as const;

export type StartWatchingFailureAction = {
	type: typeof START_WATCHING_FAILURE;
};

export const startWatchingFailure = (): StartWatchingFailureAction => ({
	type: START_WATCHING_FAILURE,
});

// STOP WATCHING SUCCESS
export const STOP_WATCHING_SUCCESS = 'state.STOP_WATCHING_SUCCESS' as const;

export type StopWatchingSuccessAction = {
	type: typeof STOP_WATCHING_SUCCESS;
};

export const stopWatchingSuccess = (): StopWatchingSuccessAction => ({
	type: STOP_WATCHING_SUCCESS,
});

// STOP WATCHING FAILURE
export const STOP_WATCHING_FAILURE = 'state.STOP_WATCHING_FAILURE' as const;

export type StopWatchingFailureAction = {
	type: typeof STOP_WATCHING_FAILURE;
};

export const stopWatchingFailure = (): StopWatchingFailureAction => ({
	type: STOP_WATCHING_FAILURE,
});

// ADD WATCHER REQUEST
export const ADD_WATCHER_REQUEST = 'state.ADD_WATCHER_REQUEST' as const;

export type WatcherPayload = {
	user: User;
	onSuccess?: (accountId: AccountId | null) => void;
	onError?: (error?: Error | FetchError) => void;
};

export type AddWatcherRequestAction = {
	type: typeof ADD_WATCHER_REQUEST;
	payload: WatcherPayload;
};

export const addWatcherRequest = (payload: WatcherPayload): AddWatcherRequestAction => ({
	type: ADD_WATCHER_REQUEST,
	payload,
});

// ADD WATCHER SUCCESS
export const ADD_WATCHER_SUCCESS = 'state.ADD_WATCHER_SUCCESS' as const;

export type AddWatcherSuccessAction = {
	type: typeof ADD_WATCHER_SUCCESS;
	payload: WatcherPayload;
};

export const addWatcherSuccess = (payload: WatcherPayload): AddWatcherSuccessAction => ({
	type: ADD_WATCHER_SUCCESS,
	payload,
});

// ADD WATCHER FAILURE
export const ADD_WATCHER_FAILURE = 'state.ADD_WATCHER_FAILURE' as const;

export type AddWatcherFailureAction = {
	type: typeof ADD_WATCHER_FAILURE;
	payload: WatcherPayload;
};

export const addWatcherFailure = (payload: WatcherPayload): AddWatcherFailureAction => ({
	type: ADD_WATCHER_FAILURE,
	payload,
});

// REMOVE WATCHER REQUEST
export const REMOVE_WATCHER_REQUEST = 'state.REMOVE_WATCHER_REQUEST' as const;

export type RemoveWatcherRequestAction = {
	type: typeof REMOVE_WATCHER_REQUEST;
	payload: WatcherPayload;
};

export const removeWatcherRequest = (payload: WatcherPayload): RemoveWatcherRequestAction => ({
	type: REMOVE_WATCHER_REQUEST,
	payload,
});

// REMOVE WATCHER SUCCESS
export const REMOVE_WATCHER_SUCCESS = 'state.REMOVE_WATCHER_SUCCESS' as const;

export type RemoveWatcherSuccessAction = {
	type: typeof REMOVE_WATCHER_SUCCESS;
	payload: WatcherPayload;
};

export const removeWatcherSuccess = (payload: WatcherPayload): RemoveWatcherSuccessAction => ({
	type: REMOVE_WATCHER_SUCCESS,
	payload,
});

// REMOVE WATCHER FAILURE
export const REMOVE_WATCHER_FAILURE = 'state.REMOVE_WATCHER_FAILURE' as const;

export type RemoveWatcherFailureAction = {
	type: typeof REMOVE_WATCHER_FAILURE;
	payload: WatcherPayload;
};

export const removeWatcherFailure = (payload: WatcherPayload): RemoveWatcherFailureAction => ({
	type: REMOVE_WATCHER_FAILURE,
	payload,
});

// FETCH WATCHERS REQUEST
export const FETCH_WATCHERS_REQUEST = 'state.FETCH_WATCHERS_REQUEST' as const;

export type FetchWatchersRequestAction = {
	type: typeof FETCH_WATCHERS_REQUEST;
};

export const fetchWatchersRequest = (): FetchWatchersRequestAction => ({
	type: FETCH_WATCHERS_REQUEST,
});

// FETCH WATCHERS SUCCESS
export const FETCH_WATCHERS_SUCCESS = 'state.FETCH_WATCHERS_SUCCESS' as const;

export type FetchWatchersSuccessPayload = {
	users: User[];
};

export type FetchWatchersSuccessAction = {
	type: typeof FETCH_WATCHERS_SUCCESS;
	payload: FetchWatchersSuccessPayload;
};

export const fetchWatchersSuccess = (
	payload: FetchWatchersSuccessPayload,
): FetchWatchersSuccessAction => ({
	type: FETCH_WATCHERS_SUCCESS,
	payload,
});

// FETCH WATCHERS FAILURE
export const FETCH_WATCHERS_FAILURE = 'state.FETCH_WATCHERS_FAILURE' as const;

export type FetchWatchersFailureAction = {
	type: typeof FETCH_WATCHERS_FAILURE;
};

export const fetchWatchersFailure = (): FetchWatchersFailureAction => ({
	type: FETCH_WATCHERS_FAILURE,
});

export type Action =
	| InitializeAppPropsAction
	| RefreshAppPropsAction
	| ToggleWatchingRequestAction
	| AddWatcherRequestAction
	| AddWatcherFailureAction
	| RemoveWatcherRequestAction
	| RemoveWatcherFailureAction
	| FetchWatchersRequestAction
	| FetchWatchersSuccessAction
	| FetchWatchersFailureAction
	| StartWatchingSuccessAction
	| StartWatchingFailureAction
	| StopWatchingSuccessAction
	| StopWatchingFailureAction;
