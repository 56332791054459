import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira/issue-field-option';
import type {
	AllowedValuesOverrides,
	FieldOptionsFilter,
	OptionsVisibility,
	PublicToInternalPropertyTransformerContext,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isAriResourceIdValid } from '@atlassian/ui-modifications-public-api-utils/is-ari-resource-id-valid';
import { isNotEmptyString } from '@atlassian/ui-modifications-public-api-utils/is-not-empty-string';
import { isObject } from '../is-object/index.tsx';

export const transformSelectSetOptionsVisibility = ({
	isVisible,
	options,
}: OptionsVisibility): AllowedValuesOverrides => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	ids: options,
});

export const transformSetOptionsVisibilityForFrontendFilteringWithARIs = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	context: PublicToInternalPropertyTransformerContext,
): AllowedValuesOverrides => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	ids: transformOptionIdsToARI(options, context),
});

export const transformOptionIdsToARI = (
	optionIds: string[],
	{ tenantContext, fieldContext }: PublicToInternalPropertyTransformerContext,
) =>
	optionIds.reduce<string[]>((acc, optionId) => {
		try {
			const optionAri = JiraIssueFieldOptionAri.create({
				siteId: tenantContext.cloudId,
				activationId: tenantContext.activationId,
				fieldId: fieldContext.fieldId,
				optionId,
			}).toString();

			acc.push(optionAri);
		} catch (e) {
			// do nothing
		}

		return acc;
	}, []);

export const transformSetOptionsVisibilityAri = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	context: PublicToInternalPropertyTransformerContext,
): FieldOptionsFilter => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	optionIds: transformOptionIdsToARI(options, context),
});

// transformer is called on FieldPublicShape type
export const transformSelectGetOptionsVisibility = ({
	isVisible,
	options,
}: OptionsVisibility): OptionsVisibility => ({
	isVisible,
	options,
});

export const validateSetOptionsVisibilityRule = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optionsVisibility: any,
): optionsVisibility is OptionsVisibility =>
	isObject(optionsVisibility) &&
	typeof optionsVisibility.isVisible === 'boolean' &&
	Array.isArray(optionsVisibility.options) &&
	optionsVisibility.options.every(isNotEmptyString);

export const optionsVisibilityContainNoMoreThan100Items = (optionsVisibility: OptionsVisibility) =>
	optionsVisibility.options.length <= 100;

export const validateSetOptionsVisibility = (optionsVisibility: OptionsVisibility): boolean =>
	validateSetOptionsVisibilityRule(optionsVisibility) &&
	optionsVisibilityContainNoMoreThan100Items(optionsVisibility);

export const validateSetOptionsVisibilityAri = (optionsVisibility: OptionsVisibility): boolean =>
	validateSetOptionsVisibility(optionsVisibility) &&
	optionsVisibility.options.every((optionId) => isAriResourceIdValid(optionId));
