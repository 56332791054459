import React, { useCallback } from 'react';
import { styled, css } from '@compiled/react';
import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import inlineMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	SideBySideField,
	FieldWrapper,
	FieldHeadingTitle,
	FieldHeadingIconsContainer,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import {
	InlineEditContainer,
	ReadViewContainer,
	READ_VIEW_CONTAINER_SELECTOR,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import TagView from '@atlassian/jira-issue-view-internal-tagview/src/tag-view.tsx';
import type { User } from '@atlassian/jira-project-settings-apps-people-field/src/index.tsx';
import PeopleField from '@atlassian/jira-project-settings-apps-people-field/src/view/view.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanAdministerJira,
	useCanAdministerProject,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon.tsx';
import { useEditFieldConfigContext } from '@atlassian/jira-issue-view-services/src/edit-field-config-context/context.tsx';
import messages from './messages.tsx';

type FieldConfiguration = {
	[key: string]: unknown;
};

export type Props = {
	isEditable: boolean;
	isEditing: boolean;
	isMobile: boolean;
	issueKey?: IssueKey; // TODO make mandatory when cleaning up isFieldDescriptionEnabled,
	label: string;
	configuration: FieldConfiguration | undefined;
	baseUrl: BaseUrl;
	value: ReadonlyArray<User>;
	fieldId: string;
	showPinButton?: boolean;
	onChange: (arg1: ReadonlyArray<User>) => void;
	onEditRequest: (arg1: UIAnalyticsEvent) => void;
	onConfirm: () => void;
	onCancel: () => void;
	showEditIcon?: boolean;
};

type IsValidProps = {
	configuration: FieldConfiguration | undefined;
	value: ReadonlyArray<User>;
};

type RenderReadViewProps = {
	isMobile: boolean;
	value: ReadonlyArray<User>;
};

type RenderEditViewProps = Pick<
	Props,
	'configuration' | 'value' | 'baseUrl' | 'onChange' | 'issueKey'
>;

const nonEditableStyles = css({
	marginLeft: 0,
	/* stop NonEditableMargin positioning */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		margin: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[READ_VIEW_CONTAINER_SELECTOR]: {
		left: 0,
		marginTop: token('space.150', '12px'),
		marginRight: 0,
		marginBottom: token('space.100', '8px'),
		marginLeft: 0,
		/**
		 * Side-by-side css hacks are leaking into non-editable tags adding
		 * unnecessary padding.
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div > div > div': {
			padding: 0,
		},
	},
});

const toTags = memoizeOne((users: ReadonlyArray<User>) =>
	users.map((user) => ({
		value: user.id,
		content: user.name,
		elemBefore: (
			<Avatar
				size="xsmall"
				{...(isVisualRefreshEnabled() ? { borderColor: 'transparent' } : {})}
				src={user.avatarUrl}
			/>
		),
	})),
);

const isMulti = (configuration?: FieldConfiguration) =>
	!configuration || configuration.isMulti !== false;

const isValid = ({ configuration, value }: IsValidProps) =>
	!isMulti(configuration) && value.length > 1;

const RenderReadView = ({ value, isMobile }: RenderReadViewProps) => {
	const { formatMessage } = useIntl();
	return (
		<TagView
			value={toTags(value)}
			noValueText={formatMessage(genericMessages.noValue)}
			tagAppearance="rounded"
			shouldHideLinks={isMobile}
			isUserTag
		/>
	);
};

const RenderEditView = ({
	configuration,
	value,
	baseUrl,
	onChange,
	issueKey,
}: RenderEditViewProps) => {
	const { formatMessage } = useIntl();
	const isMultiMode = isMulti(configuration) || value.length > 1;
	return (
		<PeopleField
			baseUrl={baseUrl}
			isMulti={isMultiMode}
			autoFocus
			isInvalid={isValid({ configuration, value })}
			isInputDisabled={isValid({ configuration, value })}
			invalidMessage={formatMessage(messages.singleValueValidationError)}
			placeholder={formatMessage(
				isMultiMode ? messages.placeholderMulti : messages.placeholderSingle,
			)}
			isDisabled={false}
			appearance="compact"
			onChange={onChange}
			// @ts-expect-error - TS2322 - Type 'readonly User[]' is not assignable to type 'undefined'.
			value={value}
			issueKey={issueKey}
		/>
	);
};

export const People = (props: Props) => {
	const {
		isEditable,
		isEditing,
		isMobile,
		issueKey,
		label,
		configuration,
		baseUrl,
		value,
		fieldId,
		showPinButton,
		onChange,
		onEditRequest,
		onConfirm,
		onCancel,
		showEditIcon,
	} = props;

	const testId = `issue.views.field.select.common.select-inline-edit.${fieldId}`;

	const { formatMessage } = useIntl();
	const isSingleMode = !isMulti(configuration) && value.length <= 1;
	const { onEditFieldConfigClick } = useEditFieldConfigContext();

	let isJiraAdmin = false;
	let isProjectAdmin = false;

	let handleClickFieldEdit = useCallback(noop, []);

	if (fg('issue_view_field_config_edit')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleClickFieldEdit = useCallback(
			() => onEditFieldConfigClick(fieldId),
			[fieldId, onEditFieldConfigClick],
		);
		if (issueKey) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const projectKey = useProjectKey(issueKey);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			isJiraAdmin = useCanAdministerJira(projectKey);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			isProjectAdmin = useCanAdministerProject(projectKey);
		}
	}

	return (
		<FieldWrapper data-testid={testId}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				<FieldHeadingIconsContainer
					isCustomFieldConfigurationEnabled={fg('issue_view_field_config_edit')}
				>
					{issueKey !== undefined && fieldId !== undefined && (
						<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
					)}
					{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
					{showEditIcon && fg('issue_view_field_config_edit') && (
						<FieldEditIcon
							onClick={handleClickFieldEdit}
							hasEditPermission={isJiraAdmin || isProjectAdmin}
						/>
					)}
				</FieldHeadingIconsContainer>
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<EnterEscapeHandler onEscape={onCancel}>
					<PeopleInlineEditContainer isEditable={isEditable}>
						<FieldInlineEditStateLess
							testId={`${testId}.field-inline-edit-state-less`}
							isLabelHidden
							label={label}
							fieldId={fg('one_event_rules_them_all_fg') ? fieldId : undefined}
							readView={
								<ReadViewContainer>
									<RenderReadView value={value} isMobile={isMobile} />
								</ReadViewContainer>
							}
							editView={
								isEditable ? (
									<RenderEditView
										configuration={configuration}
										value={value}
										baseUrl={baseUrl}
										onChange={onChange}
										issueKey={issueKey}
									/>
								) : null
							}
							// @ts-expect-error - TS2322 - Property 'disableEditViewFieldBase' does not exist on type 'IntrinsicAttributes & FieldInlineEditStateLessProps<unknown>'.
							disableEditViewFieldBase
							isEditing={isEditing}
							shouldConfirmOnEnter={isSingleMode}
							// isValid used to be declared in types for FieldInlineEditStateLess component but never used
							// we should remove it from here or implement the isValid functionality in FieldInlineEditStateLess
							isValid={isEditing ? isValid({ configuration, value }) : true}
							onConfirm={onConfirm}
							onCancel={onCancel}
							areActionButtonsHidden={isSingleMode}
							onEditRequested={onEditRequest}
							isFitContainerWidthReadView={!isMobile}
							editButtonLabel={formatMessage(inlineMessages.editButtonLabel, {
								fieldName: label,
							})}
							confirmButtonLabel={formatMessage(inlineMessages.confirmButtonLabel, {
								fieldName: label,
							})}
							cancelButtonLabel={formatMessage(inlineMessages.cancelButtonLabel, {
								fieldName: label,
							})}
							onEscape={onCancel}
							isEditable={isEditable}
						/>
					</PeopleInlineEditContainer>
				</EnterEscapeHandler>
			</SideBySideField>
		</FieldWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PeopleInlineEditContainer = styled(InlineEditContainer)<{ isEditable: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => !isEditable && nonEditableStyles,
);
