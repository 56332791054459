import React, { type FC, type ComponentType, useRef, useEffect, useCallback } from 'react';
import type { MediaClientConfig } from '@atlaskit/media-core';
import type {
	MediaError,
	UploadErrorEventPayload,
	UploadsStartEventPayload,
	UploadEndEventPayload,
	UploadPreviewUpdateEventPayload,
	DropzoneConfig,
} from '@atlaskit/media-picker';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueAttachmentsActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { UploadContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import type { Attachment } from '@atlassian/jira-issue-view-common-types/src/attachment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	attachmentUploadBegin,
	attachmentUploadEnd,
	attachmentUploadError,
	mediaPickerError,
	type AttachmentPickerAction,
} from '@atlassian/jira-issue-view-store/src/actions/attachment-picker-actions.tsx';
import {
	fetchUploadContextRequest,
	type FetchUploadContextRequestAction,
} from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';

export type EventHandlers = {
	['uploads-start']: (arg1: UploadsStartEventPayload) => void;
	['upload-preview-update']: (arg1: UploadPreviewUpdateEventPayload) => void;
	['upload-end']: (arg1: UploadEndEventPayload) => void;
	['upload-error']: (arg1: UploadErrorEventPayload) => void;
};

type OwnProps = {
	uploadContext?: UploadContext | null;
	eventHandlers?: EventHandlers;
	config: DropzoneConfig;
	// eslint-disable-next-line jira/react/handler-naming
	requestUploadToken: () => void;
	onDragEnter: () => void;
	onDragLeave: () => void;
	onDrop: () => void;
	// they are shadowed by DispatchProps with different shape
	// onUploadStart: (arg1: UploadsStartEventPayload) => void;
	// onUploadEnd: (arg1: UploadEndEventPayload) => void;
	onUploadError: (arg1: MediaError) => void;
	onGeneralError: (arg1: MediaError) => void;
};

type StateProps = {
	uploadContext: UploadContext | undefined;
};

type Action = FetchUploadContextRequestAction | AttachmentPickerAction;

type DispatchProps = {
	// eslint-disable-next-line jira/react/handler-naming
	requestUploadToken: () => Action;
	onUploadStart: (arg1: Attachment) => Action;
	onUploadEnd: (arg1: Attachment) => Action;
	onUploadError: (arg1: MediaError) => Action;
	onGeneralError: (arg1: MediaError) => Action;
};

type Props = OwnProps & StateProps & DispatchProps;

export const withMediaPickerPropsProvider =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(MediaPickerComponent: ComponentType<any>) => (props: Props) => {
		const {
			onUploadStart,
			onUploadEnd,
			onUploadError,
			onGeneralError,
			uploadContext,
			eventHandlers,
			config,
			onDragEnter,
			onDragLeave,
			onDrop,
			...otherProps
		} = props;

		const uploadTokensPromisesResolvers = useRef<unknown>([]);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const isTokenUpToDate = useCallback(() => {
			if (!uploadContext) {
				return false;
			}
			const { tokenIssueTimestamp, tokenLifespanInMs } = uploadContext;
			const expirationTimestamp = tokenIssueTimestamp + tokenLifespanInMs;
			return expirationTimestamp > Date.now();
		}, [uploadContext]);

		const resolveAuthPromise = useCallback(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			([resolve, reject]: [any, any]) => {
				if (!uploadContext || !isTokenUpToDate()) {
					reject();
					return;
				}
				const { serviceHost, clientId, token } = uploadContext;
				resolve({
					clientId,
					token,
					baseUrl: serviceHost,
				});
			},
			[isTokenUpToDate, uploadContext],
		);

		const resolveUploadPromises = useCallback(() => {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			uploadTokensPromisesResolvers.current.forEach(resolveAuthPromise);
			uploadTokensPromisesResolvers.current = [];
		}, [resolveAuthPromise]);

		const mediaClientConfig: MediaClientConfig = {
			authProvider: () =>
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				new Promise((resolve, reject: (error?: any) => void) => {
					if (isTokenUpToDate()) {
						resolveAuthPromise([resolve, reject]);
					} else {
						props.requestUploadToken();
						uploadTokensPromisesResolvers.current = [
							// @ts-expect-error - TS2488 - Type 'unknown' must have a '[Symbol.iterator]()' method that returns an iterator.
							...uploadTokensPromisesResolvers.current,
							[resolve, reject],
						];
					}
				}),
			useSha256ForUploads: true,
		};

		const mediaPickerConfig = {
			// @ts-expect-error - TS2783 - 'uploadParams' is specified more than once, so this usage will be overwritten.
			uploadParams: {
				collection: uploadContext?.collection,
			},
			...config,
			multiple: true,
		};
		const issueKey = useIssueKey();
		const [, { addNewAttachments }] = useIssueAttachmentsActions();

		const onUploadsStartFn = (payload: UploadsStartEventPayload) => {
			// Use the time of upload as the file's createdDate.
			const createdDate = Math.floor(Date.now() / 1000); // use seconds to align with api
			const { files } = payload;
			files.forEach((file) => {
				onUploadStart({
					id: `${file.id}`,
					createdDate,
				});
			});

			const newAttachments = files.map((file) => ({
				filename: file.name,
				filesize: file.size,
				mimetype: file.type,
				mediaApiFileId: file.id,
				createdDate,
			}));
			// @ts-expect-error - TS2345 - Argument of type '{ filename: string; filesize: number; mimetype: string; mediaApiFileId: string; createdDate: number; }[]' is not assignable to parameter of type 'Attachment[]'.
			addNewAttachments(issueKey, newAttachments);
		};

		const onUploadEndFn = (payload: UploadEndEventPayload) => {
			const { file } = payload;

			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd('attachment', {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: 'attachment',
					actionType: 'add',
				},
			});
			if (fg('issue_view_add_attachment_metrics')) {
				fireOperationalAnalytics(createAnalyticsEvent({}), 'issueViewAttach success');
			}

			onUploadEnd({ id: `${file.id}` });
		};

		const onErrorFn = (payload: UploadErrorEventPayload) => {
			const { error } = payload;

			fg('issue_view_add_attachment_metrics') &&
				fireOperationalAnalytics(createAnalyticsEvent({}), 'issueViewAttach failed');

			if (['upload_fail', 'object_create_fail'].includes(error.name)) {
				onUploadError(error);
			} else {
				onGeneralError(error);
			}
		};

		const onDragEnterFn = () => {
			onDragEnter();
		};

		const onDragLeaveFn = () => {
			onDragLeave();
		};

		const onDropFn = () => {
			onDrop();
		};

		// For next three events there is default implementation (ex. this.onError etc)
		// But it can be overridden. Rule is - if eventHandlers is present it value will be used (even if it's undefined)
		const onError = eventHandlers ? eventHandlers['upload-error'] : onErrorFn;
		const onUploadsStart = eventHandlers ? eventHandlers['uploads-start'] : onUploadsStartFn;
		const onEnd =
			eventHandlers && eventHandlers['upload-end'] ? eventHandlers['upload-end'] : onUploadEndFn;
		const onPreviewUpdate = eventHandlers && eventHandlers['upload-preview-update'];

		useEffect(() => {
			resolveUploadPromises();
		}, [resolveUploadPromises, uploadContext]);

		if (!uploadContext) {
			return null;
		}

		return (
			<MediaPickerComponent
				mediaClientConfig={mediaClientConfig}
				config={mediaPickerConfig}
				onUploadsStart={onUploadsStart}
				onError={onError}
				onEnd={onEnd}
				onPreviewUpdate={onPreviewUpdate}
				onDragEnter={onDragEnterFn}
				onDragLeave={onDragLeaveFn}
				onDrop={onDropFn}
				{...otherProps}
			/>
		);
	};

// QUALITY:OFF

// the following types are required to define the External Types for users of withMediaPickerProps
// by the cost of a little chaos inside this file
// the problem is one can pass many different component inside (Clipboard, Browser, Dropzone)
// and some flexibility is required to handle optional types (TS4 problem)

// We can use any as something will "extend" this initial shape
type ProvidedProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDragEnter?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDragLeave?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDrop?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onUploadsStart?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onUploadsEnd?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPreviewUpdate?: any;
};
type WithMediaAndOptionalConfigProps = ProvidedProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mediaClientConfig: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	config?: any;
};
type WithOptionalMediaConfigProps = ProvidedProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mediaClientConfig?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	config?: any;
};
export type WithMediaConfigProps = ProvidedProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mediaClientConfig: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	config: any;
};

type WithWiredProps =
	| WithMediaAndOptionalConfigProps
	| WithOptionalMediaConfigProps
	| WithMediaConfigProps;

export type WiredProps = {
	onDragEnter?: () => void;
	onDragLeave?: () => void;
	onDrop?: () => void;
	eventHandlers?: EventHandlers;
};

function withMediaPickerProps<K extends WithMediaAndOptionalConfigProps>(
	WrappedComponent: ComponentType<K>,
): FC<
	Omit<K, keyof WithMediaAndOptionalConfigProps> & WiredProps & { config?: Partial<K['config']> }
>;
function withMediaPickerProps<K extends WithOptionalMediaConfigProps>(
	WrappedComponent: ComponentType<K>,
): FC<Omit<K, keyof WithOptionalMediaConfigProps> & WiredProps & { config?: Partial<K['config']> }>;
function withMediaPickerProps<K extends WithMediaConfigProps>(
	WrappedComponent: ComponentType<K>,
): FC<Omit<K, keyof WithMediaConfigProps> & WiredProps & { config?: Partial<K['config']> }>;

// QUALITY:ON

function withMediaPickerProps<K extends WithWiredProps>(
	WrappedComponent: ComponentType<K>,
): FC<Omit<K, keyof WithWiredProps> & WiredProps & { config?: Partial<K['config']> }> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return connect(
		(state: State): StateProps => ({
			uploadContext: mediaContextSelector(state).uploadContext || undefined,
		}),
		{
			onUploadStart: attachmentUploadBegin,
			onUploadEnd: attachmentUploadEnd,
			onUploadError: attachmentUploadError,
			onGeneralError: mediaPickerError,
			requestUploadToken: fetchUploadContextRequest,
		},
		// FIXME: bypass typescript to enforce end component shape
	)(withMediaPickerPropsProvider(WrappedComponent)) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default withMediaPickerProps;
