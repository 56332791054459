import { useCallback, useMemo } from 'react';
import { graphql, fetchQuery } from 'react-relay';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldIsEditingActions } from '@atlassian/jira-issue-field-editing-store/src/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { assignToMeMutationDataQuery } from '@atlassian/jira-relay/src/__generated__/assignToMeMutationDataQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from './messages.tsx';
import type { AssignToMeMutationData } from './types.tsx';

export const useAssignToMeMutationData = () => {
	const cloudId = useCloudId();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const { overriding } = useInlineEditFieldInjections();
	const [{ canBeAssignedToIssues }] = useProjectPermissions(projectKey);
	const { formatMessage } = useIntl();
	const { setFieldEditingState } = useFieldIsEditingActions();

	const { showFlag, dismissFlag } = useFlagsService();

	const getData = useCallback(async (): Promise<AssignToMeMutationData> => {
		try {
			const data = await fetchQuery<assignToMeMutationDataQuery>(
				getRelayEnvironment(),
				graphql`
					query assignToMeMutationDataQuery(
						$cloudId: ID!
						$issueKey: String!
						$oneEventEnabled: Boolean!
					) {
						me {
							user {
								accountId
								name
								picture
								id
								accountStatus
							}
						}
						jira {
							issueByKey(cloudId: $cloudId, key: $issueKey) {
								assigneeField {
									id
									fieldId
									type
									name
									user {
										id
										accountId @include(if: $oneEventEnabled)
									}
									fieldConfig {
										isEditable
									}
								}
							}
						}
					}
				`,
				{
					cloudId,
					issueKey,
					oneEventEnabled: fg('one_event_rules_them_all_fg'),
				},
				{ fetchPolicy: 'store-or-network' },
			).toPromise();

			const isEditable = overriding.overrideIsEditable(
				(canBeAssignedToIssues && data?.jira?.issueByKey?.assigneeField?.fieldConfig?.isEditable) ||
					false,
			);
			const name = overriding.overrideLabel(data?.jira?.issueByKey?.assigneeField?.name || '');
			const isAssigneeSameAsCurrentUser =
				data?.me?.user?.id === data?.jira?.issueByKey?.assigneeField?.user?.id;

			const getNewUser = () => {
				if (isAssigneeSameAsCurrentUser) {
					return null;
				}
				const loggedInUser = data?.me?.user;
				if (!loggedInUser) {
					return null;
				}
				return {
					accountId: loggedInUser.accountId,
					name: loggedInUser.name,
					picture: loggedInUser.picture,
					id: loggedInUser.id,
					accountStatus: loggedInUser.accountStatus,
				};
			};

			return {
				isEditable,
				field: data?.jira?.issueByKey?.assigneeField
					? {
							id: data?.jira?.issueByKey?.assigneeField?.id,
							fieldId: data?.jira?.issueByKey?.assigneeField?.fieldId,
							type: data?.jira?.issueByKey?.assigneeField?.type,
							name,
							...(fg('one_event_rules_them_all_fg') && {
								accountId: data?.jira?.issueByKey?.assigneeField?.user?.accountId,
							}),
						}
					: undefined,
				newUser: getNewUser(),
			};
		} catch (e) {
			const flagId = showFlag({
				type: 'error',
				title: formatMessage(messages.submitFailedTitle, {
					fieldName: formatMessage(messages.assignee),
				}),
				description: formatMessage(messages.submitFailedDescription),
				actions: [
					{
						content: messages.submitFailedTryAgain,
						onClick: () => {
							setFieldEditingState(ASSIGNEE_TYPE, true);
							flagId && dismissFlag(flagId);
						},
					},
				],
				testId:
					'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.assign-to-me-mutation.error-flag',
			});
			throw e;
		}
	}, [
		canBeAssignedToIssues,
		cloudId,
		dismissFlag,
		formatMessage,
		issueKey,
		overriding,
		setFieldEditingState,
		showFlag,
	]);
	return useMemo(() => ({ getData }), [getData]);
};
