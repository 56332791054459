import React, { useCallback, useMemo, useState, type ComponentPropsWithoutRef } from 'react';
import { css, styled } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { format } from 'date-fns';
import { token } from '@atlaskit/tokens';
import {
	useAnalyticsEvents,
	type UIAnalyticsEvent,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { DateTimeEditView } from '@atlassian/jira-issue-field-date-time-editview-full/src/ui/date-time/index.tsx';
import DateTimeEditViewEntryPoint from '@atlassian/jira-issue-field-date-time-editview-full/src/entrypoint.tsx';
import type { DateTimeEditViewProps } from '@atlassian/jira-issue-field-date-time-editview-full/src/ui/date-time/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { DateTimeReadView } from '@atlassian/jira-issue-field-date-time-readview-full/src/ui/date-time/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { standardizeLocale } from '@atlassian/jira-issue-format-date/src/common/utils.tsx';
import {
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	ReadViewContainer as StandardReadViewContainer,
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	InlineEditContainer as StandardInlineEditContainer,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import type { dateTime_issueFieldDateTime_DateTimeField_Mutation as DateTimeMutation } from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTime_DateTimeField_Mutation.graphql';
import type {
	dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation as DateTimeOldMutation,
	dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation$rawResponse as DateTimeOldMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef$key as DateTimeOldFragment } from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef.graphql';
import type { dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef$key as DateTimeWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef.graphql';
import type { dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewNew_fragmentRef$key as DateTimeFragment } from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewNew_fragmentRef.graphql';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	constructDateTimeFromPartialData,
	getFormattedDate,
	getNext30MinuteInterval,
} from './utils.tsx';
import type {
	JiraDateTimePickerValue,
	DateTimeInlineEditViewOldProps,
	DateTimeInlineEditViewProps,
	DateTimeInlineEditViewWithIsEditableProps,
} from './types.tsx';
import messages from './messages.tsx';

export const ACTION_SUBJECT = 'dateTimeFieldInlineEdit';

/**
 * DateTimeInlineEditView Component will handle the switching behaviour between the 'readView' and 'editView' components
 * @param props {@link DateTimeInlineEditView}
 *
 * we also want to finalise if we need areActionButtonsHidden here or not
 * */

const DateTimeInlineEditViewOld = ({
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	timeZone,
}: DateTimeInlineEditViewOldProps) => {
	// #region Relay
	const data = useFragment<DateTimeOldFragment>(
		graphql`
			fragment dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef on JiraDateTimePickerField {
				...dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView
				id
				dateTime
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	const { id: uniqueFieldId, fieldConfig, dateTime } = data;
	const [commit] = useMutation<DateTimeOldMutation>(graphql`
		mutation dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation(
			$input: JiraUpdateDateTimeFieldInput!
		) @raw_response_type {
			jira {
				updateDateTimeField(input: $input) {
					success
					errors {
						message
					}
					field {
						dateTime
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isEditing, setIsEditing] = useOptionallyControlledEditingState(
		startWithEditViewOpen,
		uniqueFieldId,
	);
	const [newValue, setNewValue] = useState<JiraDateTimePickerValue>(dateTime ?? null);
	const [newTimeValue, setNewTimeValue] = useState<JiraDateTimePickerValue>(null);
	const [newDateValue, setNewDateValue] = useState<JiraDateTimePickerValue>(null);
	const [error, setError] = useState<Error | null>(null);
	const locale = useLocale();

	const isFieldEditable = fg('relay-migration-issue-fields-date-time-fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() => overriding.overrideIsEditable(fieldConfig?.isEditable || false),
				[fieldConfig?.isEditable, overriding],
			)
		: fieldConfig?.isEditable || false;
	// #endregion

	// #region Handle new value
	const onEditRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setError(null);
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
		},
		[setError, setIsEditing],
	);
	const handleErrors = useCallback(
		(incomingError: Error) => {
			setError(incomingError);
			setIsEditing(true);
			setNewValue(dateTime ?? null);
		},
		[setError, setIsEditing, setNewValue, dateTime],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (
				analyticsEvent &&
				analyticsEvent instanceof KeyboardEvent &&
				'stopPropagation' in analyticsEvent &&
				expValEquals('jira_issue_view_date_time_picker_experiment', 'isEnabled', true)
			) {
				analyticsEvent.stopPropagation();
			}

			setError(null);
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[setError, setIsEditing],
	);
	const handleNewValue = useCallback(
		(newDateTime: JiraDateTimePickerValue) => {
			if (fg('relay-migration-issue-fields-date-time-fg')) {
				onSubmit?.(newDateTime);
			}

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							datetime: newDateTime ?? null,
							operation: 'SET',
						},
					},
				},

				onCompleted(mutationData) {
					if (mutationData.jira?.updateDateTimeField) {
						const { success, errors: responseErrors } = mutationData.jira?.updateDateTimeField;
						if (success) {
							if (fg('relay-migration-issue-fields-date-time-fg')) {
								onSubmitSucceeded?.(newDateTime);
							}
							return;
						}

						if (fg('relay-migration-issue-fields-date-time-fg')) {
							onSubmitFailed?.();
						}

						if (responseErrors != null && responseErrors.length > 0) {
							handleErrors(
								new Error(
									responseErrors[0].message != null ? responseErrors[0].message : undefined,
								),
							);
						}
					}
				},
				onError(incomingError) {
					handleErrors(incomingError);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateDateTimeField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								dateTime: newDateTime,
							},
						},
					},
				} as DateTimeOldMutationResponse,
			});
			setIsEditing(false);
		},
		[
			commit,
			handleErrors,
			onSubmit,
			onSubmitFailed,
			onSubmitSucceeded,
			setIsEditing,
			uniqueFieldId,
		],
	);
	const onConfirm = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (expValEquals('jira_issue_view_date_time_picker_experiment', 'isEnabled', true)) {
				if (!newValue && (newDateValue || newTimeValue)) {
					const constructedValue = constructDateTimeFromPartialData(newDateValue, newTimeValue);

					setNewValue(constructedValue);

					const date = new Date(constructedValue);
					setNewDateValue(format(date, 'yyyy-MM-dd'));
					setNewTimeValue(format(date, 'HH:mm'));

					handleNewValue(constructedValue);
					fireUIAnalytics(analyticsEvent);
				} else {
					handleNewValue(newValue);
					fireUIAnalytics(analyticsEvent);
				}
			} else {
				handleNewValue(newValue);
				fireUIAnalytics(analyticsEvent);
			}
		},
		[newValue, newDateValue, newTimeValue, handleNewValue],
	);
	const onChange = useCallback(
		(value: JiraDateTimePickerValue) => {
			if (expValEquals('jira_issue_view_date_time_picker_experiment', 'isEnabled', true)) {
				if (!value) {
					handleNewValue(value);
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'confirmed',
							actionSubject: 'dateTimeFieldInlineEdit',
						}),
					);
				}
			}
			setError(null);
			setNewValue(value);
		},
		[setNewValue, handleNewValue, createAnalyticsEvent],
	);

	const onDateChange = (newDate: JiraDateTimePickerValue) => {
		setNewDateValue(newDate);
	};

	const onTimeChange = (newTime: JiraDateTimePickerValue) => {
		setNewTimeValue(newTime);
	};

	const timePlaceholder = useMemo(() => {
		return getNext30MinuteInterval();
	}, []);

	const datePlaceholder = useMemo(() => {
		const standardizedLocale = standardizeLocale(locale);

		let formattedDate = '';

		try {
			formattedDate = getFormattedDate(standardizedLocale);
		} catch (dateFormatError) {
			fireErrorAnalytics({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: dateFormatError as Error,
				meta: {
					packageName: 'dateTimeFieldInlineEdit',
					id: 'formatDateError',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		return formattedDate;
	}, [locale]);

	// #region Read view
	const ReadViewContainerToUse = fg('relay-migration-issue-fields-date-time-fg')
		? StandardReadViewContainer
		: ReadViewContainer;

	const renderReadView = () => (
		<ReadViewContainerToUse data-testid="issue-field-date-time-inline-edit-full.ui.date-time.read-view">
			<DateTimeReadView fragmentRef={data} timeZone={timeZone} />
		</ReadViewContainerToUse>
	);
	// #endregion
	// #region Edit view
	const renderEditView = () => {
		return (
			<>
				<EditViewContainer data-testid="issue-field-date-time-inline-edit-full.ui.date-time.edit-view">
					<DateTimeEditView
						{...(expValEquals('jira_issue_view_date_time_picker_experiment', 'isEnabled', true)
							? {
									autoFocus: true,
									defaultIsOpen: true,
									spacing: 'compact',
									datePlaceholder,
									timePlaceholder,
									onDateChange,
									onTimeChange,
								}
							: {})}
						value={newValue}
						onChange={onChange}
						timeZone={timeZone}
					/>
				</EditViewContainer>
			</>
		);
	};
	// #endregion

	const InlineEditContainerToUse = fg('relay-migration-issue-fields-date-time-fg')
		? StandardInlineEditContainer
		: InlineEditContainer;

	return (
		<ErrorBoundary id="Field(DateTime)" prefixOverride="issue">
			{/* @ts-expect-error Types of parameters 'analyticsEvent' and 'e' are incompatible */}
			<EnterEscapeHandler onEscape={onCancelRequest}>
				{error && (
					<ErrorFlag
						description={messages.errorMessage}
						error={error}
						title={messages.errorTitle}
					/>
				)}
				<InlineEditContainerToUse isEditable={isFieldEditable}>
					<FieldInlineEditStateLess
						testId="issue-field-date-time-inline-edit-full.ui.date-time.field-date-time"
						fieldId={fg('one_event_rules_them_all_fg') ? uniqueFieldId : undefined}
						isEditing={isEditing}
						areActionButtonsHidden
						actionSubject={ACTION_SUBJECT}
						isEditable={isFieldEditable}
						readView={renderReadView}
						editView={renderEditView}
						onCancel={onCancelRequest}
						onConfirm={onConfirm}
						onEdit={onEditRequest}
						defaultValue={null}
					/>
				</InlineEditContainerToUse>
			</EnterEscapeHandler>
		</ErrorBoundary>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `DateTimeInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [DateTimeInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const DateTimeInlineEditViewWithIsEditable = ({
	attributes,
	spacing = 'compact',
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	isEditable,
	readViewFitContainerHeight,
	timeZone,
	isAutoFocusFixEnabled,
	readViewDateOnlyFormat,
}: DateTimeInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<DateTimeWithIsEditableFragment>(
		graphql`
			fragment dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef on JiraDateTimePickerField {
				...dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView
				id
				fieldId
				type
				name
				dateTime
			}
		`,
		fragmentRef,
	);
	const { id: uniqueFieldId, fieldId, name: fieldName, type, dateTime } = data;
	const [commit] = useMutation<DateTimeMutation>(graphql`
		mutation dateTime_issueFieldDateTime_DateTimeField_Mutation(
			$input: JiraUpdateDateTimeFieldInput!
		) @raw_response_type {
			jira {
				updateDateTimeField(input: $input) {
					success
					errors {
						message
					}
					field {
						dateTime
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const locale = useLocale();
	const initialValue: JiraDateTimePickerValue = dateTime ?? null;
	const [updatedValue, setUpdatedValue] = useState<JiraDateTimePickerValue>(initialValue);
	const isFieldEditable = fg('relay-migration-issue-fields-date-time-fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => overriding.overrideIsEditable(isEditable), [isEditable, overriding])
		: isEditable || false;
	// #endregion

	// #region Handle new value
	const handleSubmit = useCallback(
		(newDateTime: JiraDateTimePickerValue, { onSuccess, onFail }: OnSubmitCallbacks) => {
			if (fg('relay-migration-issue-fields-date-time-fg')) {
				onSubmit?.(newDateTime);
			}

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							datetime: newDateTime ?? null,
							operation: 'SET',
						},
					},
				},

				onCompleted(mutationData) {
					if (mutationData.jira?.updateDateTimeField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateDateTimeField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								dateTime: newDateTime,
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, uniqueFieldId],
	);

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		updatedValue,
	});

	// #region Read view
	const renderReadView = () => (
		<DateTimeReadView
			fragmentRef={data}
			timeZone={timeZone}
			isDateOnlyFormat={readViewDateOnlyFormat}
		/>
	);
	// #endregion
	// #region Edit view
	const timePlaceholder = useMemo(() => {
		return getNext30MinuteInterval();
	}, []);

	const datePlaceholder = useMemo(() => {
		const standardizedLocale = standardizeLocale(locale);

		let formattedDate = '';

		try {
			formattedDate = getFormattedDate(standardizedLocale);
		} catch (dateFormatError) {
			fireErrorAnalytics({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: dateFormatError as Error,
				meta: {
					packageName: 'dateTimeFieldInlineEdit',
					id: 'formatDateError',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		return formattedDate;
	}, [locale]);
	const getEditViewProps = (fieldProps: ValidationFieldProps): DateTimeEditViewProps => ({
		...fieldProps,
		autoFocus: isAutoFocusFixEnabled,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChange,
		timeZone,
		...(expValEquals('jira_issue_view_date_time_picker_experiment', 'isEnabled', true)
			? {
					autoFocus: true,
					spacing,
					datePlaceholder,
					timePlaceholder,
				}
			: {}),
	});
	// #endregion

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={DateTimeEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [DateTimeInlineEditViewProps](./types.tsx)
 */

export const DateTimeInlineEditViewNew = ({
	fragmentRef,
	...props
}: DateTimeInlineEditViewProps) => {
	const data = useFragment<DateTimeFragment>(
		graphql`
			fragment dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewNew_fragmentRef on JiraDateTimePickerField {
				...dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<DateTimeInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};

export const DateTimeInlineEditView = componentWithFG(
	'jsc_inline_editing_field_refactor',
	DateTimeInlineEditViewNew,
	DateTimeInlineEditViewOld,
);

const readViewContainerSelectorName = 'jira-issue-field-date-time-read-view-container';
const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

const ReadViewContainer = (props: ComponentPropsWithoutRef<typeof ReadViewContainerComponent>) => (
	<ReadViewContainerComponent data-component-selector={readViewContainerSelectorName} {...props} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values
	zIndex: () => (isVisualRefreshEnabled() ? 200 : 300),
	position: 'relative',
});

const nonEditableStylesExperimentStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		paddingLeft: token('space.075', '6px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStylesExperimentStyles : undefined),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainerComponent = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	lineHeight: '20px',
});
