/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, { type ReactNode } from 'react';

import { css, jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled

import type { PromptEditorType } from '../../editors/types';

import { TextareaDecoration } from './TextareaDecoration';

interface Props {
	type?: PromptEditorType;
	isInvalid?: boolean;
	onFocus?: () => void;
	onBlur?: () => void;
	onClick?: () => void;
	children: ReactNode;
}

const decoration = css({
	width: '100%',
});

export function Decoration({ children, type, isInvalid, onFocus, onBlur, onClick }: Props) {
	return (
		// Editor doesn't support onFocus, that's why we will add handlers here.
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div css={decoration} onFocus={onFocus} onBlur={onBlur} onClick={onClick}>
			{type === 'advanced-prompt' ? (
				<TextareaDecoration isInvalid={isInvalid}>{children}</TextareaDecoration>
			) : (
				<>{children}</>
			)}
		</div>
	);
}
