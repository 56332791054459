import React from 'react';
import { usePreloadedQuery, type PreloadedQuery, graphql } from 'react-relay';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldsCompactModeProvider } from '@atlassian/jira-issue-view-layout-templates-compact-mode-provider/src/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { panelViewCustomerQuery } from '@atlassian/jira-relay/src/__generated__/panelViewCustomerQuery.graphql';
import { ExperienceSuccess } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/experience-success/index.tsx';
import { ThrowOnQueryError } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/throw-on-query-error/index.tsx';
import { isClientStatusCode } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/is-client-status-code/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { CustomerOrganizations } from '../../../common/ui/customer-organizations/index.tsx';
import { DetailsContainer } from '../../../common/ui/details-container/index.tsx';
import { DetailsView } from '../../../common/ui/details/details-view/index.tsx';
import { ErrorState } from '../../../common/ui/details/error-state/index.tsx';
import { Divider } from '../../../common/ui/details/index.tsx';
import { getEditDetailsUrlByEntityType, getProfileUrl } from '../../../common/ui/details/utils.tsx';
import { customerServiceIssueViewPanelCustomerExperience } from '../../../experiences.tsx';

export const DIVIDER_TESTID =
	'servicedesk-customer-service-issue-view-panel.ui.customer.customer-details.divider';

type Props = {
	queryReference: PreloadedQuery<panelViewCustomerQuery>;
	orgCount?: number;
	showProfileLink?: boolean;
};

const Inner = ({ queryReference, orgCount, showProfileLink }: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const {
		customerService: { individualByAccountId },
	} = usePreloadedQuery<panelViewCustomerQuery>(
		graphql`
			query panelViewCustomerQuery(
				$cloudId: ID!
				$accountId: ID!
				$useReporterOrganizations: Boolean!
				$filter: CustomerServiceFilterInput!
			) {
				customerService(cloudId: $cloudId) @required(action: THROW) {
					individualByAccountId(accountId: $accountId, filter: $filter) @required(action: THROW) {
						__typename
						... on CustomerServiceIndividual {
							id
							details {
								...detailsView_servicedeskCustomerServiceIssueViewPanel
							}
							organizations @include(if: $useReporterOrganizations) {
								...customerOrganizations_servicedeskCustomerServiceIssueViewPanel_CustomerOrganization
							}
						}
						... on QueryError {
							extensions {
								statusCode
							}
							...throwOnQueryError_servicedeskCustomerServiceCommon
						}
					}
				}
			}
		`,
		queryReference,
	);

	const queryError =
		individualByAccountId.__typename === 'QueryError' ? individualByAccountId : undefined;
	const is4XX = queryError?.extensions?.find((e) => isClientStatusCode(e.statusCode));

	if (is4XX) {
		return (
			<>
				<ErrorState context="CUSTOMER" />
				<ExperienceSuccess experience={customerServiceIssueViewPanelCustomerExperience} />
			</>
		);
	}

	if (individualByAccountId.__typename !== 'CustomerServiceIndividual') {
		return <ThrowOnQueryError operationName="panelViewCustomerQuery" errorRef={queryError} />;
	}

	const { details, organizations } = individualByAccountId;
	const profileUrl =
		showProfileLink && fg('jcs_issue_layout_eap')
			? getProfileUrl(projectKey, 'CUSTOMER', individualByAccountId.id)
			: undefined;

	return (
		<>
			<DetailsView
				entityType="CUSTOMER"
				editDetailsUrl={getEditDetailsUrlByEntityType(
					projectKey,
					'CUSTOMER',
					individualByAccountId.id,
				)}
				details={details}
				entityExperience={customerServiceIssueViewPanelCustomerExperience}
				profileUrl={profileUrl}
			/>
			{orgCount === 0 ? (
				<CustomerOrganizations organizations={organizations || []} />
			) : (
				<Divider data-testid={DIVIDER_TESTID} />
			)}
		</>
	);
};

export const PanelView = (props: Props) => (
	<DetailsContainer
		context="CUSTOMER"
		entityExperience={customerServiceIssueViewPanelCustomerExperience}
	>
		<FieldsCompactModeProvider>
			<Inner {...props} />
		</FieldsCompactModeProvider>
	</DetailsContainer>
);
