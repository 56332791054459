import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { ButtonItem } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { vulnerabilityDropdownItem_developmentSummaryVulnerability$key } from '@atlassian/jira-relay/src/__generated__/vulnerabilityDropdownItem_developmentSummaryVulnerability.graphql';
import { StatusLozenge } from '@atlassian/jira-software-security-common/src/ui/status-lozenge/index.tsx';
import messages from './messages.tsx';

const dropdownItemComponentSelectorName = 'development-summary-vulnerability-dropdown-item';
const DROPDOWN_ITEM_COMPONENT_SELECTOR = `[data-component-selector="${dropdownItemComponentSelectorName}"]`;

export type Props = {
	vulnerabilityDropdownItemData: vulnerabilityDropdownItem_developmentSummaryVulnerability$key;
	onActionClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

export const VulnerabilityDropdownItem = ({
	vulnerabilityDropdownItemData,
	onActionClick,
}: Props) => {
	const data = useFragment(
		graphql`
			fragment vulnerabilityDropdownItem_developmentSummaryVulnerability on DevOpsSecurityVulnerabilityDetails {
				title
				status
				url
			}
		`,
		vulnerabilityDropdownItemData,
	);

	const { title, status, url } = data;

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleActionClick = (event: React.MouseEvent | React.KeyboardEvent) => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'dropdownItem',
				action: 'clicked',
			}),
			'devPanelDropdownVulnerability',
		);
		onActionClick && onActionClick(event);
	};
	return (
		<Box xcss={styles} data-component-selector={dropdownItemComponentSelectorName}>
			<DropdownButtonItem>
				<ItemContainer>
					<LeftContent>{title}</LeftContent>
					<RightContent>
						<StatusContainer slideAway={!!url}>
							{/* @ts-expect-error - Type 'DevOpsSecurityVulnerabilityStatus | null | undefined' is not assignable to type 'DevOpsSecurityVulnerabilityStatus | null'. */}
							<StatusLozenge status={status} />
						</StatusContainer>
					</RightContent>
				</ItemContainer>
			</DropdownButtonItem>
			{url && (
				<ActionContainer>
					<Tooltip content={formatMessage(messages.dropdownItemActionTooltip)}>
						<Button
							onClick={handleActionClick}
							appearance="subtle"
							target="_blank"
							rel="noreferrer"
							href={url}
							iconBefore={
								<ShortcutIcon
									size="small"
									label={formatMessage(messages.dropdownItemActionTooltip)}
								/>
							}
						/>
					</Tooltip>
				</ActionContainer>
			)}
		</Box>
	);
};
const styles = xcss({
	position: 'relative',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ActionContainer = styled.div({
	position: 'absolute',
	right: token('space.200', '16px'),
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	opacity: 0,
	transition: 'opacity 0.3s ease-out',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`
    ${DROPDOWN_ITEM_COMPONENT_SELECTOR}:hover &,
    ${DROPDOWN_ITEM_COMPONENT_SELECTOR}:focus-within &
    `]: {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StatusContainer = styled.div({
	transition: 'margin 0.22s ease-in-out',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`
    ${DROPDOWN_ITEM_COMPONENT_SELECTOR}:hover &,
    ${DROPDOWN_ITEM_COMPONENT_SELECTOR}:focus-within &
    `]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginRight: (props: { slideAway: boolean }) =>
			props.slideAway ? `${token('space.400', '32px')}` : 'inherit',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const RightContent = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const DropdownButtonItem = styled(ButtonItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		// eslint-disable-next-line @atlaskit/platform/expand-spacing-shorthand, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		padding: `0 ${gridSize * 2}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		cursor: (props: { onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void }) =>
			props.onClick ? 'pointer' : 'default',
		boxShadow: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LeftContent = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ItemContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	paddingLeft: token('space.050', '4px'),
});
