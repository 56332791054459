import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import AttachmentsTable from '@atlassian/jira-issue-attachments-table/src/ui/main.tsx';
import withViewMediaClientConfig, {
	type InjectedProps as ViewMediaClientConfigInjectedProps,
} from '@atlassian/jira-issue-media-provider/src/controllers/view-media-client-config/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	scrollToAttachmentComment,
	scrollToAttachmentWorklog,
} from '@atlassian/jira-issue-view-store/src/actions/issue-scroll-actions.tsx';
import { fetchUploadContextRequest } from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions.tsx';
import { fetchViewContextRequest } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import type { Props, StateProps } from './types.tsx';

export const ListView = ({
	viewMediaClientConfig,
	onScrollToWorklogs,
	onScrollToComments,
}: Props & ViewMediaClientConfigInjectedProps) => (
	<ErrorBoundary id="issue.details.attachment.list-view">
		<Container
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.content.attachment.list-view.container"
		>
			<AttachmentsTable
				shouldHideErrorImage
				viewMediaClientConfig={viewMediaClientConfig}
				onScrollToWorklogs={onScrollToWorklogs}
				onScrollToComments={onScrollToComments}
			/>
		</Container>
	</ErrorBoundary>
);

const WithMediaConfig = withViewMediaClientConfig(ListView);

const Connected: ComponentType<{}> = connect(
	(state: State): StateProps => ({
		issueKey: issueKeySelector(state),
		mediaContext: mediaContextSelector(state),
	}),
	{
		onScrollToComments: scrollToAttachmentComment,
		onScrollToWorklogs: scrollToAttachmentWorklog,
		onViewRefresh: fetchViewContextRequest,
		onUploadRefresh: fetchUploadContextRequest,
	},
)(WithMediaConfig);

export default Connected;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	paddingLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 37}px`,
	position: 'relative',
	/*
        This is required to override the override for classic backlog here:
        src/packages/classic-software/src/ui/rapidboard-content/style-reset/styled.js
     */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	table: {
		width: '100%',
	},
});
