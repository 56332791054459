import {
	isIssueTypeIdFilter,
	isIssueTypeNameFilter,
	type ConnectionFieldIssueTypeFilter,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

type IsMatchingConnectionFieldFilter = {
	filters: ConnectionFieldIssueTypeFilter[];
	issueType: IssueTypeId | undefined;
	issueTypeNameFilter?: (names: string[]) => IssueTypeId[];
};

export const isMatchingConnectionFieldFilter = ({
	filters,
	issueType,
	issueTypeNameFilter,
}: IsMatchingConnectionFieldFilter) => {
	if (!issueType) {
		return false;
	}

	return filters.some((filter) => {
		if (isIssueTypeNameFilter(filter)) {
			const validIds = issueTypeNameFilter?.(filter.names) || [];
			return validIds.includes(issueType);
		}

		if (isIssueTypeIdFilter(filter)) {
			return filter.ids.includes(issueType);
		}

		// In case of unsupported filter type
		return false;
	});
};
