import type { CreatedIssues } from '../../types/index.tsx';

// this will be called to get url where user will be redirected post single or multiple issues are created
export const getIssueDiscoveryUrl = (createdIssues: CreatedIssues) => {
	if (createdIssues.length > 1) {
		const issueKeys = createdIssues.map((issue) => issue.issueKey).join(',');
		const jqlSearch = encodeURIComponent(`issueKey in (${issueKeys})`);
		return `/issues/?jql=${jqlSearch}`;
	}
	return `/browse/${createdIssues[0].issueKey}`;
};
