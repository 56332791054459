import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dismissFlagTitle: {
		id: 'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.dismiss-flag-title',
		defaultMessage: 'Got it, thanks for letting us know',
		description: 'Title of the flag that shows up when a user dismisses cross-flow touchpoints.',
	},
	dismissFlagDescription: {
		id: 'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.dismiss-flag-description',
		defaultMessage: "You won't see this here as often.",
		description:
			'Description of the flag that shows up when a user dismisses cross-flow touchpoints.',
	},
	reasonsModalTitle: {
		id: 'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description:
			'Title of the reasons modal explaining why the cross-flow touchpoint is being shown',
	},
	reasonsModalExplanation: {
		id: 'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.reasons-modal-explanation',
		defaultMessage:
			"We think that <strong>these products</strong> will improve your team's productivity. The recommendation is based on:",
		description: 'Explanation as to why the cross-flow touchpoint is being shown',
	},
	reasonsModalBulletOne: {
		id: 'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.reasons-modal-bullet-one',
		defaultMessage: 'the size of your team',
		description: 'First bullet point of the reasons modal for cross-flow touchpoint ad',
	},
	reasonsModalBulletTwo: {
		id: 'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.reasons-modal-bullet-two',
		defaultMessage: 'your existing Atlassian products',
		description: 'Second bullet point of the reasons modal for cross-flow touchpoint ad',
	},
	privacyPolicy: {
		id: 'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
});
