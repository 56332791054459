import React, { useEffect, type ComponentType } from 'react';

import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';

import {
	getPermalinkStatus,
	PermalinkType,
} from '@atlassian/jira-platform-issue-permalinks/src/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type Props = {
	commentIds: (
		| string
		| {
				id: string;
				size: number;
		  }
	)[];
};

type CommentsComponentProps<T> = T & Props;
export const withRemovePermalinkQuery =
	<T,>(
		CommentsComponent: ComponentType<CommentsComponentProps<T>>,
	): ComponentType<CommentsComponentProps<T>> =>
	(props: CommentsComponentProps<T>) => {
		const { replace } = useRouterActions();
		const { showFlag } = useFlagsService();

		useEffect(() => {
			if (!props.commentIds.length) {
				const { hasPermalink } = getPermalinkStatus(COMMENTS);
				if (hasPermalink) {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					const fullUrl = new URL(window.location.href);
					fullUrl.searchParams.delete(PermalinkType.COMMENTS);
					replace(fullUrl.toString());
					showFlag({
						type: 'error',
						title: messages.noFocusedCommentFoundTitle,
						description: messages.noFocusedCommentFoundDescription,
					});
				}
			}
		}, [replace, props.commentIds, showFlag]);

		return <CommentsComponent {...props} />;
	};
