import { NUM_PAGED_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';

export const calculateNumberOfCommentsToLoad = (
	totalComments: number,
	loadedComments = 0,
	startIndex = 0,
) => {
	let loadedCommentsInner = loadedComments;
	let startIndexInner = startIndex;

	if (!loadedCommentsInner) loadedCommentsInner = 0;
	if (!startIndexInner) startIndexInner = 0;

	let numPrevCommentsToLoad = 0;
	const numPagedItemsToLoad = NUM_PAGED_ITEMS_TO_LOAD;
	const endIndex = loadedCommentsInner + startIndexInner;
	const numNextCommentsToLoad = totalComments - endIndex;

	if (startIndexInner > 0) {
		numPrevCommentsToLoad =
			startIndexInner > numPagedItemsToLoad ? numPagedItemsToLoad : startIndexInner;
	}

	return {
		numNextCommentsToLoad:
			numNextCommentsToLoad > numPagedItemsToLoad ? numPagedItemsToLoad : numNextCommentsToLoad,
		numPrevCommentsToLoad,
	};
};
