import React from 'react';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { SCRUM_BOARD, KANBAN_BOARD } from '@atlassian/jira-common-constants/src/board-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fromFlagId, useFlagsService, AkFlag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-19531
import { useIntl } from '@atlassian/jira-intl';
import type { CreatedIssueWithIssueData, ActiveSprint } from '../../../common/types/index.tsx';
import { AddToSprintActionStatesContainer } from '../../../controllers/add-to-sprint-flag-action-context/index.tsx';
import {
	getDefaultFlagActions,
	getNewScrumIssuesWithNoSprintsActions,
	getHiddenIssuesActions,
} from './actions/index.tsx';
import {
	DEFAULT_SUCCESS_FLAG,
	CMP_SCRUM_FLAG,
	CMP_KANBAN_FLAG,
	SUCCESS_FLAG_TEST_ID,
} from './constants.tsx';
import messages from './messages.tsx';
import type { Props, DynamicSuccessFlagType } from './types.tsx';

export const SuccessFlag = (props: Props) => {
	const { flagKey, id, issueList, issueDiscoveryUrl, successFlagParams } = props;
	const { formatMessage } = useIntl();
	const { dismissFlag } = useFlagsService();

	const getDynamicFlagType = (): DynamicSuccessFlagType => {
		if (successFlagParams) {
			const { isRankable, isWorkMode, canManageSprints, boardType } = successFlagParams;
			if (isRankable && isWorkMode) {
				if (boardType === SCRUM_BOARD && canManageSprints) return CMP_SCRUM_FLAG;
				if (boardType === KANBAN_BOARD) return CMP_KANBAN_FLAG;
			}
		}
		return DEFAULT_SUCCESS_FLAG;
	};

	const getDefaultFlagTitle = () => {
		const areMultipleIssuesCreated = issueList.length > 1;
		return areMultipleIssuesCreated
			? formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.multiplesIssuesCreatedSuccessFlagTitleIssueTermRefresh
						: messages.multiplesIssuesCreatedSuccessFlagTitle,
					{
						issueCount: issueList.length,
					},
				)
			: formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.createSuccessFlagTitleIssueTermRefresh
						: messages.createSuccessFlagTitle,
					{ issueKey: issueList[0].issueKey },
				);
	};

	const FlagConfigs = {
		Default: () => ({
			flagTitle: getDefaultFlagTitle(),
			flagDescription: '',
			flagActions: getDefaultFlagActions({ issueList, issueDiscoveryUrl }),
		}),
		IssuesNotInSprint: (
			issuesWithNoSprint: CreatedIssueWithIssueData[],
			issuesExcludingEpics: CreatedIssueWithIssueData[],
			sprints: ActiveSprint[],
		) => ({
			flagTitle: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueCreatedNoSprintOrInBacklogSuccessFlagTitleIssueTermRefresh
					: messages.issueCreatedNoSprintOrInBacklogSuccessFlagTitle,
				{
					issueCount: issuesExcludingEpics.length,
				},
			),
			flagDescription: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueCreatedNoSprintSuccessFlagDescriptionIssueTermRefresh
					: messages.issueCreatedNoSprintSuccessFlagDescription,
				{
					issueKey: issuesWithNoSprint[0].issueKey,
					issueCount: issuesExcludingEpics.length,
				},
			),
			flagActions: getNewScrumIssuesWithNoSprintsActions({
				issueList,
				issueDiscoveryUrl,
				issuesWithNoSprint,
				sprints,
			}),
		}),
		IssuesNotInSprintMoreThanTwoActiveSprints: (
			issuesWithNoSprint: CreatedIssueWithIssueData[],
			issuesExcludingEpics: CreatedIssueWithIssueData[],
		) => ({
			flagTitle: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueCreatedNoSprintOrInBacklogSuccessFlagTitleIssueTermRefresh
					: messages.issueCreatedNoSprintOrInBacklogSuccessFlagTitle,
				{
					issueCount: issuesExcludingEpics.length,
				},
			),
			flagDescription: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueCreatedNoSprintSuccessFlagDescriptionIssueTermRefresh
					: messages.issueCreatedNoSprintSuccessFlagDescription,
				{
					issueKey: issuesWithNoSprint[0].issueKey,
					issueCount: issuesExcludingEpics.length,
				},
			),
			flagActions: getDefaultFlagActions({ issueList, issueDiscoveryUrl }),
		}),
		MultipleIssuesSomeNoSprint: () => ({
			flagTitle: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.multiplesIssuesCreatedSomeNoSprintSuccessFlagTitleIssueTermRefresh
					: messages.multiplesIssuesCreatedSomeNoSprintSuccessFlagTitle,
			),
			flagDescription: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.multiplesIssuesCreatedSomeNoSprintSuccessFlagDescriptionIssueTermRefresh
					: messages.multiplesIssuesCreatedSomeNoSprintSuccessFlagDescription,
			),
			flagActions: getDefaultFlagActions({ issueList, issueDiscoveryUrl }),
		}),
		HiddenScrumIssues: (issues: CreatedIssueWithIssueData[], isFiltered: boolean) => ({
			flagTitle: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.createdHiddenIssueSuccessFlagTitleIssueTermRefresh
					: messages.createdHiddenIssueSuccessFlagTitle,
				{
					issueCount: issues.length,
				},
			),
			flagDescription: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.scrumCreatedHiddenIssueSuccessFlagDescriptionIssueTermRefresh
					: messages.scrumCreatedHiddenIssueSuccessFlagDescription,
				{
					issueCount: issues.length,
				},
			),
			flagActions: getHiddenIssuesActions({
				issueList,
				issueDiscoveryUrl,
				isFiltered,
			}),
		}),
		IssuesInBacklog: (issues: CreatedIssueWithIssueData[]) => ({
			flagTitle: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueCreatedNoSprintOrInBacklogSuccessFlagTitleIssueTermRefresh
					: messages.issueCreatedNoSprintOrInBacklogSuccessFlagTitle,
				{
					issueCount: issues.length,
				},
			),
			flagDescription: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueCreatedInBacklogSuccessFlagDescriptionIssueTermRefresh
					: messages.issueCreatedInBacklogSuccessFlagDescription,
				{
					issueKey: issues[0].issueKey,
					issueCount: issues.length,
				},
			),
			flagActions: getDefaultFlagActions({ issueList, issueDiscoveryUrl }),
		}),
		MultipleIssuesSomeInBacklog: () => ({
			flagTitle: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.multiplesIssuesCreatedSomeInBacklogSuccessFlagTitleIssueTermRefresh
					: messages.multiplesIssuesCreatedSomeInBacklogSuccessFlagTitle,
			),
			flagDescription: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.multiplesIssuesCreatedSomeInBacklogSuccessFlagDescriptionIssueTermRefresh
					: messages.multiplesIssuesCreatedSomeInBacklogSuccessFlagDescription,
			),
			flagActions: getDefaultFlagActions({ issueList, issueDiscoveryUrl }),
		}),
		HiddenKanbanIssues: (issues: CreatedIssueWithIssueData[], isFiltered: boolean) => ({
			flagTitle: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.createdHiddenIssueSuccessFlagTitleIssueTermRefresh
					: messages.createdHiddenIssueSuccessFlagTitle,
				{
					issueCount: issues.length,
				},
			),
			flagDescription: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.kanbanCreatedHiddenIssueSuccessFlagDescriptionIssueTermRefresh
					: messages.kanbanCreatedHiddenIssueSuccessFlagDescription,
				{
					issueCount: issues.length,
				},
			),
			flagActions: getHiddenIssuesActions({
				issueList,
				issueDiscoveryUrl,
				isFiltered,
			}),
		}),
	};

	const getFlagContent = () => {
		if (successFlagParams) {
			if (getDynamicFlagType() === CMP_SCRUM_FLAG) {
				const {
					issues,
					isFiltered,
					invalidIssuesKeys,
					issuesWithNoSprint,
					issuesExcludingEpics,
					sprints,
				} = successFlagParams;

				if (issuesWithNoSprint.length > 0) {
					if (issuesWithNoSprint.length === issuesExcludingEpics.length) {
						if (sprints.length > 2) {
							return FlagConfigs.IssuesNotInSprintMoreThanTwoActiveSprints(
								issuesWithNoSprint,
								issuesExcludingEpics,
							);
						}
						return FlagConfigs.IssuesNotInSprint(issuesWithNoSprint, issuesExcludingEpics, sprints);
					}

					return FlagConfigs.MultipleIssuesSomeNoSprint();
				}

				if (invalidIssuesKeys.length > 0) {
					return FlagConfigs.HiddenScrumIssues(issues, isFiltered);
				}
			} else if (getDynamicFlagType() === CMP_KANBAN_FLAG) {
				const { kanbanIssuesInBacklog, invalidIssuesKeys, issues, isFiltered } = successFlagParams;

				if (kanbanIssuesInBacklog.length > 0) {
					if (kanbanIssuesInBacklog.length === issues.length) {
						return FlagConfigs.IssuesInBacklog(issues);
					}

					return FlagConfigs.MultipleIssuesSomeInBacklog();
				}

				if (invalidIssuesKeys.length > 0) {
					return FlagConfigs.HiddenKanbanIssues(issues, isFiltered);
				}
			}
		}

		return FlagConfigs.Default();
	};

	const { flagTitle, flagDescription, flagActions } = getFlagContent();

	return (
		<div
			data-issue-key={issueList.map((issue) => issue.issueKey).join(',')}
			data-testid={SUCCESS_FLAG_TEST_ID}
		>
			<AkFlag
				icon={<SuccessIcon primaryColor={token('color.icon.success', G300)} label="Success" />}
				id={fromFlagId(id)}
				key={flagKey}
				title={flagTitle}
				description={flagDescription}
				actions={flagActions}
				onDismissed={() => {
					dismissFlag(id);
				}}
			/>
		</div>
	);
};

const SuccessFlagWithContext = (props: Props) => {
	const { id } = props;
	return (
		<AddToSprintActionStatesContainer scope={id.valueOf()} key={id.valueOf()}>
			<SuccessFlag {...props} />
		</AddToSprintActionStatesContainer>
	);
};

export default SuccessFlagWithContext;
