// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeIssueLinkHeader: {
		id: 'issues.confirm-delete-issuelink-modal-header',
		defaultMessage: 'Remove the link to {issueKey}?',
		description: '',
	},
	removeIssueLinkContent: {
		id: 'issues.confirm-delete-issuelink-modal-text',
		defaultMessage: 'You can add it again later if you need to.',
		description: '',
	},
	removeIssueLinkButton: {
		id: 'issues.conirm-delete-issue-link-confirm-btn-text',
		defaultMessage: 'Remove',
		description: '',
	},
	issueLinkFailureHeader: {
		id: 'issue.issue-links-failure-header',
		defaultMessage: "We couldn't link the issue",
		description: '',
	},
	issueLinkFailureContent: {
		id: 'issue.issue-links-failure-content',
		defaultMessage: 'Wait a few moments, then give it another try.',
		description: '',
	},
	issueLinkNoPermissionFailureContent: {
		id: 'issue.issue-links-no-permission-failure-content',
		defaultMessage:
			"You don't have permission to link this issue. Please contact your Jira administrator for assistance.",
		description: '',
	},
	issueLinkFailureHeaderIssueTermRefresh: {
		id: 'issue.issue-links-failure-header-issue-term-refresh',
		defaultMessage: "We couldn't link the work item",
		description: '',
	},
	issueLinkNoPermissionFailureContentIssueTermRefresh: {
		id: 'issue.issue-links-no-permission-failure-content-issue-term-refresh',
		defaultMessage: "You don't have permission to link this work item. Please contact your admin.",
		description: '',
	},
});
