import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.ul({
	boxShadow: token(
		'elevation.shadow.raised',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 1px 1px ${colors.N50A}, 0 0 1px 1px ${colors.N40A}`,
	),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.raised', colors.N10),
	borderRadius: '3px',
	paddingTop: token('space.025', '2px'),
	paddingRight: 0,
	paddingBottom: token('space.025', '2px'),
	paddingLeft: 0,
	width: '100%',
});
