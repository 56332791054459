import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { AIEventBaseAttributes } from '@atlassian/ai-analytics';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { IssueLinkType } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useRelatedIssues, type RelatedIssue } from './controllers/context.tsx';
import { SuggestRelatedIssuesModal } from './ui/index.tsx';

type RelatedIssuesAttributes = AIEventBaseAttributes & { singleInstrumentationId: string };

const AI_EVENT_ATTRIBUTES: AIEventBaseAttributes = {
	aiFeatureName: 'jiraAIRelatedIssues',
	isAIFeature: 1,
	proactiveGeneratedAI: 0,
	userGeneratedAI: 1,
};

type Props = {
	issueKey: string;
	existingIssues: string[];
	panelDisplay?: boolean;
	onRelatedIssuesTriggered: (arg: boolean) => void;
	onIssueLinkSave: (
		optimisticId: string,
		linkSuggestionsRequest: RelatedIssue,
		issueLinkType: IssueLinkType,
		requestAnalyticsEvent: UIAnalyticsEvent | null,
		analyticsEvent?: UIAnalyticsEvent,
	) => void;
};

export const SuggestRelatedIssuesModalContainer = ({
	issueKey,
	existingIssues,
	panelDisplay,
	onRelatedIssuesTriggered,
	onIssueLinkSave,
}: Props) => {
	const [shouldDisplayModal, setShouldDisplayModal] = useState(!panelDisplay);
	const singleInstrumentationId = useRef(uuid());
	const [
		{ currentStep, isLinkedIssuePanelButtonTriggered },
		{
			setDisplayLinkedIssuesPanelCallback,
			discardAllSuggestions,
			setRelatedIssuesFetchStep,
			resetState,
		},
	] = useRelatedIssues();
	const attributes: RelatedIssuesAttributes = {
		...AI_EVENT_ATTRIBUTES,
		singleInstrumentationId: singleInstrumentationId.current,
	};

	useEffect(() => {
		// create an artificial blocker to prevent
		// panelDisplay effect on first render
		if (panelDisplay && shouldDisplayModal) {
			setRelatedIssuesFetchStep();
		}
		if (!shouldDisplayModal) {
			setShouldDisplayModal(true);
		}
		// not include shouldDisplayModal
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [panelDisplay, setRelatedIssuesFetchStep]);

	useEffect(() => {
		setDisplayLinkedIssuesPanelCallback(onRelatedIssuesTriggered);
	}, [onRelatedIssuesTriggered, setDisplayLinkedIssuesPanelCallback]);

	useEffect(() => {
		// reset on unmount
		return () => {
			resetState();
		};
	}, [issueKey, resetState]);

	return (
		<JSErrorBoundary
			id="jira-ai.gravity-ai.related-issues"
			packageName="jiraAIRelatedIssues"
			teamName="gravity-ai"
			fallback="unmount"
		>
			<ContextualAnalyticsData attributes={attributes}>
				<SuggestRelatedIssuesModal
					currentStep={currentStep}
					isLinkedIssuePanelButtonTriggered={isLinkedIssuePanelButtonTriggered}
					onDiscardAllSuggestions={discardAllSuggestions}
					existingIssues={existingIssues}
					onIssueLinkSave={onIssueLinkSave}
				/>
			</ContextualAnalyticsData>
		</JSErrorBoundary>
	);
};
