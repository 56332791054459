import React, {
	type ReactNode,
	type Ref,
	type ElementType,
	type SyntheticEvent,
	type ComponentPropsWithRef,
	type RefAttributes,
	useCallback,
} from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css, keyframes, type StyledComponentClass } from 'styled-components';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	UpdateAnimator,
	type AnimationProps,
} from '@atlassian/jira-packages-components-animations/src/update-animator.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import type { DropdownTriggerParams } from '../../common/types.tsx';
import messages from './messages.tsx';
import type { RouterLinkConfig } from './types.tsx';

type Props = {
	issueKey: string | null | undefined;
	routerLinkConfig?: RouterLinkConfig;
	title: ReactNode;
	secondaryData: ReactNode;
	href: string | null;
	itemType: string | null;
	itemCount: number | null;
	disabled: boolean;
	linkFormatting: boolean;
	target: string;
	secondaryHover: ReactNode;
	forceVisibleSecondary?: boolean;
} & Partial<
	Flow.Diff<
		DropdownTriggerParams,
		{
			ref: Ref<ElementType>;
			onClick: (event: SyntheticEvent | KeyboardEvent, analyticEvent: UIAnalyticsEvent) => void;
		}
	>
> & {
		reference?: Ref<ElementType>;
		icon?: ReactNode;
		['data-testid']: string;
		showIconInPlainLink: boolean;
		relativeDate?: ReactNode;
		onClick?: (event: SyntheticEvent | KeyboardEvent, analyticEvent: UIAnalyticsEvent) => void;
		oneClickUrl: string | null | undefined;
		oneClickToolTipContent: string | null;
		disableAnimationOnUpdates: boolean;
	};

export const SummaryItem = (props: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const getIconAfter = useCallback(
		() =>
			// temporary ternary for visual refresh update
			// eslint-disable-next-line no-nested-ternary
			props.href !== undefined && props.href != null ? (
				isVisualRefreshEnabled() ? (
					<LinkExternalIcon
						color={token('color.link')}
						label={formatMessage(messages.externalLink)}
					/>
				) : (
					<ShortcutIcon size="small" label={formatMessage(messages.externalLink)} />
				)
			) : (
				<Spinner size="small" />
			),
		[formatMessage, props.href],
	);
	const iconBeforeProps = { iconBefore: props.icon };

	const renderClickableSummaryItem = (
		title: ReactNode,
		secondaryData: ReactNode,
		isOneClickLinkEnabled: boolean,
		secondaryHover: ReactNode,
	) => {
		const plainLinkContainer = (
			<PlainLink
				href={props.href}
				onClick={props.onClick}
				data-testid="development-summary-common.ui.summary-item.plain-link"
			>
				<ContentContainer tabIndex={0}>
					{props.showIconInPlainLink && props.icon !== null && props.icon !== undefined && (
						<BeforeIconContainer>{props.icon}</BeforeIconContainer>
					)}
					<LeftContent>{title}</LeftContent>
					{props.relativeDate !== undefined && props.relativeDate !== null && (
						<RelativeDateContainer>{props.relativeDate}</RelativeDateContainer>
					)}
					<SecondaryDataContainer data-testid="development-summary-common.ui.summary-item.secondary-data-container">
						{secondaryData}
					</SecondaryDataContainer>
				</ContentContainer>
			</PlainLink>
		);

		const linkFormattedButtonContainer = props.routerLinkConfig ? (
			<LinkFormattedButton
				appearance="link"
				component={Link}
				// @ts-expect-error - TS2322 - Type '{ children: Element; iconBefore: ReactNode; appearance: "link"; component: ForwardRefExoticComponent<AnchorHTMLAttributes<HTMLAnchorElement> & { ...; } & RefAttributes<...>>; to: string | ... 2 more ... | Promise<...>; params: MatchParams | undefined; prefetch: false | ... 2 more ... | undefined; onClick: ((event: K...' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<WithOptionalTheme<ButtonProps & RefAttributes<HTMLElement>, any>, any>, any, any>> & Readonly<...> & Readonly<...>'.
				to={props.routerLinkConfig.route}
				params={props.routerLinkConfig.params}
				prefetch={props.routerLinkConfig.prefetch}
				onClick={props.onClick}
				{...iconBeforeProps}
			>
				<LeftAlignedContent>{title}</LeftAlignedContent>
			</LinkFormattedButton>
		) : (
			<LinkFormattedButton
				shouldFitContainer
				appearance="link"
				type="button"
				onClick={props.onClick}
				href={props.href ?? ''}
				{...iconBeforeProps}
				target={props.target}
				isSelected={props.isSelected}
				// @ts-expect-error - TS2322 - Type '{}' is not assignable to type 'ReactChild | undefined'.
				iconAfter={
					props.secondaryData !== undefined && props.secondaryData != null ? (
						props.secondaryData
					) : (
						<IconOnHoverContainer>{getIconAfter()}</IconOnHoverContainer>
					)
				}
				testId="development-summary-common.ui.summary-item.link-formatted-button"
				aria-controls={props['aria-controls']}
				aria-expanded={props['aria-expanded']}
				aria-haspopup={props['aria-haspopup']}
			>
				<LeftAlignedContent>{title}</LeftAlignedContent>
			</LinkFormattedButton>
		);

		const handlerContentWithTooltip = !props.linkFormatting ? (
			<Tooltip content={title}>{plainLinkContainer}</Tooltip>
		) : (
			<LinkFormattedContainer
				isSecondaryHoverExisting={secondaryHover !== undefined && secondaryHover != null}
				data-testid="development-summary-common.ui.summary-item.link-formatted-container"
			>
				<Tooltip content={title}>{linkFormattedButtonContainer}</Tooltip>
			</LinkFormattedContainer>
		);

		return (
			<RelativeContainer
				oneClickLinkEnabled={isOneClickLinkEnabled}
				// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
				forceVisibleSecondary={props.forceVisibleSecondary}
				isSecondaryHoverExisting={secondaryHover !== undefined && secondaryHover != null}
				data-testid="development-summary-common.ui.summary-item.relative-container"
			>
				<EnterEscapeHandler
					onEnter={(event) => {
						props?.onClick?.(
							event,
							createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
						);
					}}
				>
					{handlerContentWithTooltip}
				</EnterEscapeHandler>
				<ActionItemsContainer>
					{isOneClickLinkEnabled && (
						<ActionContainer>
							<Tooltip content={props.oneClickToolTipContent}>
								<Button
									appearance="subtle"
									target="_blank"
									rel="noreferrer noopener"
									href={props.oneClickUrl || ''}
									onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
										fireUIAnalytics(analyticsEvent, 'devPanelSummaryOneClickLink', {
											summaryItemType: props.itemType,
										});
									}}
									iconBefore={
										isVisualRefreshEnabled() ? (
											<LinkExternalIcon
												color={token('color.link')}
												label={props.oneClickToolTipContent || ''}
											/>
										) : (
											<ShortcutIcon size="small" label={props.oneClickToolTipContent || ''} />
										)
									}
									testId="development-summary-common.ui.summary-item.one-click-link-button"
								/>
							</Tooltip>
						</ActionContainer>
					)}
					{secondaryHover !== undefined && secondaryHover != null && (
						<ActionContainer>
							<ContextualAnalyticsData attributes={{ summaryItemType: props.itemType }}>
								{secondaryHover}
							</ContextualAnalyticsData>
						</ActionContainer>
					)}
				</ActionItemsContainer>
			</RelativeContainer>
		);
	};

	const renderDisabledSummaryItem = (title: ReactNode) => (
		// @ts-expect-error - TS2741 - Property 'tabIndex' is missing in type '{ children: (false | Element)[]; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { tabIndex: any; }, any>>'.
		<ContentContainer>
			{props.icon !== undefined && props.icon !== null && <>{props.icon}&nbsp;</>}
			<LeftContent>{title}</LeftContent>
		</ContentContainer>
	);

	const isOneClickLinkEnabled = props.itemCount === 1 && props.oneClickUrl !== null;

	return (
		<Row
			// @ts-expect-error - TS2322 - Type 'Ref<ElementType<any>> | undefined' is not assignable to type '((instance: any) => void) | RefObject<HTMLElement | SVGElement | Component<{}, {}, any>> | undefined'.
			innerRef={props.reference}
			disabled={props.disabled}
			data-testid={props['data-testid']}
			showIconInPlainLink={props.showIconInPlainLink}
		>
			{props.disableAnimationOnUpdates ? (
				<>
					{!props.disabled
						? renderClickableSummaryItem(
								props.title,
								props.secondaryData,
								isOneClickLinkEnabled,
								props.secondaryHover,
							)
						: renderDisabledSummaryItem(props.title)}
				</>
			) : (
				<UpdateAnimator
					animationOut={FadeOutAnimation}
					animationIn={FadeInAnimation}
					updateProps={{
						title: props.title,
						secondaryData: props.secondaryData,
					}}
				>
					{({ title, secondaryData }) =>
						!props.disabled
							? renderClickableSummaryItem(
									title,
									secondaryData,
									isOneClickLinkEnabled,
									props.secondaryHover,
								)
							: renderDisabledSummaryItem(title)
					}
				</UpdateAnimator>
			)}
		</Row>
	);
};

SummaryItem.defaultProps = {
	disabled: false,
	secondaryData: null,
	href: null,
	itemType: null,
	itemCount: null,
	issueKey: null,
	oneClickUrl: null,
	oneClickToolTipContent: null,
	linkFormatting: false,
	target: '_blank',
	secondaryHover: null, // secondary hover component next to the one click button
	'aria-controls': '',
	'aria-expanded': false,
	'aria-haspopup': false,
	isSelected: false,
	showIconInPlainLink: false,
	disableAnimationOnUpdates: false,
};

export const SummaryItemContainer = ({ children }: { children: ReactNode }) => (
	// @ts-expect-error - TS2741 - Property 'showIconInPlainLink' is missing in type '{ children: Element; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { showIconInPlainLink: boolean; disabled?: any; }, any>>'.
	<Row>
		{/* @ts-expect-error - TS2741 - Property 'tabIndex' is missing in type '{ children: ReactNode; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { tabIndex: any; }, any>>'. */}
		<ContentContainer>{children}</ContentContainer>
	</Row>
);

// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconOnHoverContainer = styled.div({});

type ButtonProps = ComponentPropsWithRef<typeof Button>;

const LinkFormattedButton: StyledComponentClass<
	ButtonProps & RefAttributes<HTMLElement>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
> =
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled(Button)({});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkFormattedContainer = styled.div<{ isSecondaryHoverExisting: boolean }>`
	flex: 1 0 100%;
	${({ isSecondaryHoverExisting }) =>
		isSecondaryHoverExisting &&
		css({
			marginRight: `${token('space.300', '24px')}`,
		})}
	${LinkFormattedButton} {
		padding: 0 ${token('space.025', '2px')};
		${({ isSecondaryHoverExisting }) =>
			isSecondaryHoverExisting &&
			isVisualRefreshEnabled() &&
			fg('visual-refresh_drop_4') &&
			css({
				marginRight: `padding: 0 ${token('space.050', '4px')} 0 ${token('space.025', '2px')};`,
			})}
	}
	${IconOnHoverContainer} {
		opacity: 0;
	}
	&:hover,
	&:focus-within {
		background-color: ${token('color.background.neutral.subtle.hovered', colors.N30)};
		border-radius: 3px;
		${IconOnHoverContainer} {
			opacity: 1;
		}
	}
`;

const fadeIn = keyframes({
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
});

const fadeOut = keyframes({
	'0%': {
		opacity: 1,
	},
	'100%': {
		opacity: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionItemsContainer = styled.div({
	position: 'absolute',
	right: 0,
	top: 0,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSecondaryDataMarginRight = (isSecondaryHoverExisting: any, oneClickLinkEnabled: any) => {
	if (oneClickLinkEnabled && isSecondaryHoverExisting) {
		return css({
			// With that spacing, we end up having 48px + 8px of paddingRight from the parent = 56px margin to the right
			// with the two icons on the right being 52px
			marginRight: `${token('space.600', '48px')}`,
		});
	}
	if (oneClickLinkEnabled || isSecondaryHoverExisting) {
		return css({
			marginRight: `${token('space.250', '20px')}`,
		});
	}

	return css({
		marginRight: 0,
	});
};

// FIXME: type error in object syntax
// WORKAROUND: return a plain object instead of `css()` call
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SecondaryDataContainer = styled.div({
	marginRight: 0,
	transition: 'margin 0.22s ease-in-out',
});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeContainer = styled.div<{
	isSecondaryHoverExisting: boolean;
	oneClickLinkEnabled: boolean;
	forceVisibleSecondary: boolean;
}>`
	position: relative;
	${ActionItemsContainer} {
		opacity: 0;
		transition: opacity 0.3s ease-out;
	}
	&:hover,
	&:focus-within {
		${ActionItemsContainer} {
			opacity: 1;
		}
		${SecondaryDataContainer} {
			${
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(props: any) =>
					getSecondaryDataMarginRight(props.isSecondaryHoverExisting, props.oneClickLinkEnabled)
			}
		}
	}
	${({ forceVisibleSecondary }) =>
		forceVisibleSecondary &&
		// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
		css`
			${ActionItemsContainer} {
				opacity: 1;
			}
			${SecondaryDataContainer} {
				${
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(props: any) =>
						getSecondaryDataMarginRight(props.isSecondaryHoverExisting, props.oneClickLinkEnabled)
				}
			}
		`}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FadeOutAnimation = styled.div<AnimationProps>({
	animation: `${fadeOut} 0.8s ease-out`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FadeInAnimation = styled.div<AnimationProps>({
	animation: `${fadeIn} 0.8s ease-in`,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div<{ tabIndex: any }>(
	{
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ tabIndex }) =>
		tabIndex !== undefined &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			borderRadius: '3px',
			'&:focus': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				boxShadow: `inset 2px 2px ${token('color.border.focused', colors.B200)}, inset -2px -2px ${token('color.border.focused', colors.B200)}`,
			},
			'&:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
			},
			'&:active': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('color.background.selected', colors.B50),
			},
		}),
);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Row = styled.div<{ showIconInPlainLink: boolean; disabled?: any }>`
	margin: 0 ${token('space.negative.100', '-8px')};
	${ContentContainer} {
		height: 32px;
		${({ showIconInPlainLink }) =>
			showIconInPlainLink
				? css({
						padding: `0 ${token('space.100', '8px')} 0 ${token('space.025', '2px')}`,
					})
				: css({
						padding: `0 ${token('space.100', '8px')}`,
					})}
		${({ disabled = false }) =>
			disabled
				? css({
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						color: token('color.text.subtlest', colors.N70),
					})
				: css({
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						color: token('color.text', colors.N800),
					})}
	}
	width: 100%;
`;

const ellipsisStyles = css({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BeforeIconContainer = styled2.div({
	display: 'flex',
	marginTop: 0,
	marginRight: token('space.025', '2px'),
	marginBottom: 0,
	marginLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftAlignedContent = styled2.div({
	textAlign: 'left',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const PlainLink = styled.a<any>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	cursor: props.onClick ? 'pointer' : 'default',
	'&:hover, &:focus, &:active': {
		textDecoration: 'none',
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionContainer = styled2.div({
	borderRadius: '3px',
	marginLeft: token('space.025', '2px'),
});

// FIXME: type error in object syntax from ellipsisStyles
// WORKAROUND: make ellipsisStyles a plain object
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeDateContainer = styled.div`
	${ellipsisStyles}
	flex: 0 5 auto;
	div[role='presentation'] {
		${ellipsisStyles}
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftContent = styled.div(
	{
		flexDirection: 'column',
		textAlign: 'left',
		margin: `0 ${token('space.025', '2px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	ellipsisStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			marginRight: token('space.050', '4px'),
			flex: '1 1 auto',
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	IconOnHoverContainer,
	PlainLink,
	ActionContainer,
	LeftContent,
	ContentContainer,
	LeftAlignedContent,
	LinkFormattedButton,
	BeforeIconContainer,
	RelativeDateContainer,
};
