import React, { type FocusEvent, useCallback } from 'react';
import { useInlineEditReadViewContainer } from '@atlassian/jira-inline-edit-lite/src/ui/use-inline-edit-read-view-container/index.tsx';
import InlineEditReadViewContainer from '@atlassian/jira-issue-field-inline-edit-read-view-container/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useCopyPasteTarget } from '@atlassian/jira-issue-field-copy-paste/src/ui/index.tsx';
import type { Props } from './types.tsx';

/**
 * Responsible for rendering the common read view UI elements for an issue field, including the container and associated
 * edit button.
 */
export const ReadView = ({
	children,
	context,
	editButtonLabel,
	editButtonRef,
	hideEditButton,
	isEditInteractionEnabled,
	isInvalid,
	isLoading,
	readViewContainerRef,
	readViewFitContainerHeight,
	onCopy,
	onPaste,
}: Props) => {
	const { isEditButtonFocused, onClick, onMouseDown, onEditButtonBlur, onEditButtonFocus } =
		useInlineEditReadViewContainer(context, isEditInteractionEnabled);

	let handleEditButtonBlur = onEditButtonBlur;
	let handleEditButtonFocus = onEditButtonFocus;
	if (expVal('jira_list_inline_editing', 'isInlineEditingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { onFocus, onBlur } = useCopyPasteTarget({
			onCopy,
			// Don't allow paste interaction for non-editable fields
			onPaste: isEditInteractionEnabled ? onPaste : undefined,
		});
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleEditButtonBlur = useCallback(
			(e: FocusEvent<HTMLButtonElement>) => {
				onEditButtonBlur(e);
				onBlur(e);
			},
			[onEditButtonBlur, onBlur],
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleEditButtonFocus = useCallback(
			(e: FocusEvent<HTMLButtonElement>) => {
				onEditButtonFocus(e);
				onFocus(e);
			},
			[onEditButtonFocus, onFocus],
		);
	}

	return (
		<InlineEditReadViewContainer
			context={context}
			editButtonLabel={editButtonLabel}
			editButtonRef={editButtonRef}
			hideEditButton={hideEditButton}
			isEditInteractionEnabled={isEditInteractionEnabled}
			isEditButtonFocused={isEditButtonFocused}
			// Highlight the field as invalid to notify the user if there was an error attempting to submit changes.
			isInvalid={isInvalid}
			isLoading={isLoading}
			onClick={onClick}
			onMouseDown={onMouseDown}
			onEditButtonBlur={handleEditButtonBlur}
			onEditButtonFocus={handleEditButtonFocus}
			readViewFitContainerHeight={readViewFitContainerHeight}
			ref={readViewContainerRef}
		>
			{children}
		</InlineEditReadViewContainer>
	);
};
