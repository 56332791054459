import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type HistoryItem,
	ASSIGNEE_CHANGED,
	STATUS_CHANGED,
	COMMENT_CHANGED,
	ATTACHMENT_CHANGED,
	PRIORITY_CHANGED,
	FLAG_CHANGED,
	FIELD_CHANGED,
	NUMERIC_FIELD_CHANGED,
	RICH_TEXT_FIELD_CHANGED,
	ISSUE_CREATED,
	WORK_LOGGED,
	TIME_CHANGED,
	PARENT_ISSUE_CHANGED,
	DATE_ISSUE_CHANGED,
	RESPONDERS_CHANGED,
	AFFECTED_SERVICES_CHANGED,
	ARCHIVED_CHANGED,
	DATA_CLASSIFICATION_CHANGED,
	DATA_REDACTED,
	REDACTION_GROUPED_HISTORY_ITEM,
} from '../../../types.tsx';
import { AffectedServicesHistoryItem } from './affected-services-history-item/index.tsx';
import ArchivedHistoryItem from './archived-history-item/index.tsx';
import AssigneeHistoryItem from './assignee-history-item/index.tsx';
import AttachmentHistoryItem from './attachment-history-item/index.tsx';
import CommentHistoryItem from './comment-history-item/index.tsx';
import { DataClassificationHistoryItem } from './data-classification-history-item/index.tsx';
import DateHistoryItem from './date-history-item/index.tsx';
import FlagHistoryItem from './flag-history-item/index.tsx';
import GenericHistoryItem from './generic-history-item/index.tsx';
import IssueCreatedHistoryItem from './issue-created-history-item/index.tsx';
import NumericValueHistoryItem from './numeric-value-history-item/index.tsx';
import ParentIssueHistoryItem from './parent-issue-history-item/index.tsx';
import PriorityHistoryItem from './priority-history-item/index.tsx';
import RespondersHistoryItem from './responders-history-item/index.tsx';
import RichTextHistoryItem from './rich-text-history-item/index.tsx';
import TimeChangedHistoryItem from './time-changed-history-item/index.tsx';
import TransitionHistoryItem from './transition-history-item/index.tsx';
import WorklogHistoryItem from './worklog-history-item/index.tsx';
import RedactedHistoryItem from './redaction-history-item/index.tsx';

interface Props {
	historyItem: HistoryItem;
	// it is used to distinguish between grouped and single items as we reuse HistoryItemComponent for both cases
	isGrouped?: boolean;
}

const HistoryItemComponent = ({ historyItem, isGrouped = false }: Props) => {
	const activityTimelineWorklogProps = expVal(
		'issue_view_activity_timeline',
		'isActivityTimelineEnabled',
		false,
	)
		? { showTimestamp: true, showPermalink: false }
		: {};

	switch (historyItem.type) {
		case ASSIGNEE_CHANGED:
			return <AssigneeHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case STATUS_CHANGED:
			return <TransitionHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case COMMENT_CHANGED:
			return <CommentHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case ARCHIVED_CHANGED:
			return <ArchivedHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case ATTACHMENT_CHANGED:
			return <AttachmentHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case PRIORITY_CHANGED:
			return <PriorityHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case FLAG_CHANGED:
			return <FlagHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case ISSUE_CREATED:
			return <IssueCreatedHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case FIELD_CHANGED:
			return <GenericHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case RICH_TEXT_FIELD_CHANGED:
			return <RichTextHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case NUMERIC_FIELD_CHANGED:
			return <NumericValueHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case TIME_CHANGED:
			return <TimeChangedHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case WORK_LOGGED:
			return (
				<WorklogHistoryItem
					historyItem={historyItem}
					isGrouped={isGrouped}
					{...activityTimelineWorklogProps}
				/>
			);
		case PARENT_ISSUE_CHANGED:
			return <ParentIssueHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case DATE_ISSUE_CHANGED:
			return <DateHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case RESPONDERS_CHANGED:
			return <RespondersHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case AFFECTED_SERVICES_CHANGED:
			return <AffectedServicesHistoryItem historyItem={historyItem} isGrouped={isGrouped} />;
		case DATA_CLASSIFICATION_CHANGED:
			return fg('jira-issue-history-data-classification_mhyjw') ? (
				<DataClassificationHistoryItem historyItem={historyItem} isGrouped={isGrouped} />
			) : null;
		case DATA_REDACTED:
		case REDACTION_GROUPED_HISTORY_ITEM:
			return <RedactedHistoryItem historyItem={historyItem} />;
		default:
			return null;
	}
};

export default HistoryItemComponent;
