import { useCallback, useState } from 'react';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { CROSSFLOW_AD_DISMISSED_USER_PREF_KEY } from '../constants.tsx';

/**
 * Hook to
 * - check if user has dismissed crossflow Ad in quick actions
 * - dismiss Ad by sending sending a user preference request to BE
 */
export const useCrossflowAdControls = () => {
	const [crossflowBannerDismissed, setCrossflowBannerDismissed] = useUserPreferencesValue(
		CROSSFLOW_AD_DISMISSED_USER_PREF_KEY,
	);
	const [userHasDismissedAd, setUserHasDismissedAd] = useState(!!crossflowBannerDismissed);

	const dismissAd = useCallback(() => {
		setUserHasDismissedAd(true);
		try {
			// API request to update user prefs
			setCrossflowBannerDismissed.setValue('true');
		} catch (error) {
			fireErrorAnalytics({
				error: error instanceof Error ? error : new Error('Failed to dismiss ad'),
				meta: {
					id: 'dismissAd',
					packageName: 'jiraQuickActions',
					teamName: 'bento',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [setCrossflowBannerDismissed]);

	return { userHasDismissedAd, dismissAd };
};
