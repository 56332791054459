import { css, styled } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css as cssSC } from 'styled-components';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { TimestampStyles } from '@atlassian/jira-issue-timestamp/src/index.tsx';

// TODO APP-442 migrate to Compiled
// see comment https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/69086/overview?commentId=4060638
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActivityTimestampStylesControl: TimestampStyles = {
	// @ts-expect-error - TS2322 - Type 'InterpolationValue[]' is not assignable to type 'string'.

	always: cssSC({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N500),
	}),
	// @ts-expect-error - TS2322 - Type 'InterpolationValue[]' is not assignable to type 'string'.

	whenDisplaySettingSwitchable: cssSC({
		'&:hover': {
			color: 'inherit',
		},
	}),
};

// eslint-disable-next-line @compiled/no-exported-css, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActivityTimestampStylesExperiment: TimestampStyles = {
	// @ts-expect-error - TS2322 - Type 'InterpolationValue[]' is not assignable to type 'string'.
	always: css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N500),
	}),
	// @ts-expect-error - TS2322 - Type 'InterpolationValue[]' is not assignable to type 'string'.
	whenDisplaySettingSwitchable: css({
		'&:hover': {
			color: 'inherit',
		},
	}),
};

// TODO ARMOR-114 get rid of this.
// This is the wrapper around @atlasKit/comment to show background color.
// We are adding padding and removing same values from margin to render same style as @atlasKit/comment have.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InternalCommentContainer = styled.div<{
	isEditing?: boolean;
	highlightColor: string;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize / 2}px`,
		paddingTop: token('space.100', '8px'),
		paddingRight: 0,
		paddingBottom: token('space.050', '4px'),
		paddingLeft: token('space.100', '8px'),
		marginTop: token('space.250', '20px'),
		marginRight: 0,
		marginBottom: token('space.negative.050', '-4px'),
		marginLeft: token('space.negative.100', '-8px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => (props.isEditing ? '' : `background-color: ${props.highlightColor};`),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentContainer = styled.span({
	display: 'flex',
	marginLeft: token('space.negative.050', '-4px'),
});
