import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import EditorErrorIcon from '@atlaskit/icon/glyph/editor/error';
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { FlagType } from '@atlassian/jira-flags';
import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { Store as LimitExceedStore } from '@atlassian/jira-issue-hard-limit-exceed-modal/src/controllers/index.tsx';
import {
	ADD_WATCHER_ERROR,
	ADD_WORKLOG_SUCCESS,
	ADD_WORKLOG_FAILURE,
	WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED,
	ASSIGN_TO_ME_SUCCESS,
	REMOVE_FROM_ME_SUCCESS,
	ATTACHMENT_ADD_ERROR,
	ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR,
	ATTACHMENT_ADD_SUCCESS,
	ATTACHMENT_DELETE_ERROR,
	CHANGE_ISSUE_TYPE_ERROR,
	CHANGE_ISSUE_TYPE_SUCCESS,
	COMMENT_ADD_ERROR_GENERIC,
	COMMENT_ADD_ERROR_INVALID,
	COMMENT_CONTENT_EXCEEDED_ERROR,
	COMMENT_DELETE_ERROR,
	CREATE_CHILD_ISSUE_ERROR,
	DELETE_CONFLUENCE_PAGE_LINK_FAILED,
	DELETE_ISSUE_ERROR,
	DELETE_LINKED_ISSUE_ERROR,
	DELETE_WORKLOG_FAILURE,
	DELETE_WORKLOG_SUCCESS,
	EDIT_EPIC_COLOUR_ERROR,
	EDIT_ISSUE_COLOUR_ERROR,
	EDIT_WORKLOG_FAILURE,
	EDIT_WORKLOG_SUCCESS,
	EDITOR_ERROR,
	FETCH_JIRA_APP_LINKS_ERROR,
	FETCH_WATCHERS_ERROR,
	type FlagConstant,
	LINK_CHILD_ISSUE_ERROR,
	LOG_WORK_CONFIRM_ERROR,
	REMOVE_WATCHER_ERROR,
	RICH_CONTENT_FIELD_CONFIRM_ERROR,
	RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR,
	RICH_CONTENT_FIELD_REQUIRED_ERROR,
	SAVE_APPROVAL_ANSWER_ERROR,
	SAVE_APPROVAL_ANSWER_SUCCESS,
	START_WATCHING_SUCCESS,
	STOP_WATCHING_SUCCESS,
	SUBTASKS_REORDER_ERROR_GENERIC,
	UPDATE_TIME_REMAINING_FAILURE,
	WATCH_TOGGLE_UPDATE_ERROR,
	REMOTE_LINK_VALIDATION_ERROR,
	COMMENT_FUTURE_TIME_ERROR,
	SAVE_COMMENT_SUCCESS_FLAG_MESSAGE,
	COMMENTS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED,
} from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { defaultRegistry } from '@atlassian/react-sweet-state';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColoredIcon = styled.span<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => props.color,
});

const genericErrorIcon = (
	// @ts-expect-error - TS2322 - Type '{ children: Element; size: string; color: "var(--ds-icon-danger)"; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
	<ColoredIcon size="medium" color={token('color.icon.danger', colors.R300)}>
		<EditorErrorIcon label="" />
	</ColoredIcon>
);

const genericSuccessIcon = (
	// @ts-expect-error - TS2322 - Type '{ children: Element; size: string; color: "var(--ds-icon-success)"; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
	<ColoredIcon size="medium" color={token('color.icon.success', colors.G300)}>
		<EditorSuccessIcon label="" />
	</ColoredIcon>
);

type Action = {
	messages: {
		content: MessageDescriptor;
	};
	onClick: (arg1: {
		closeFlag: () => void;
		retryIssueDelete?: (issueId: IssueId) => void;
		deleteFailureIssueKey?: IssueId;
		openComment?: () => void;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fireAnalyticsEvent?: (eventName: string, eventData?: Record<any, any>) => void;
	}) => void;
};

type FlagMessages = Record<
	FlagConstant,
	{
		messages: {
			title: MessageDescriptor;
			titleIssueTermRefresh?: MessageDescriptor;
			description?: MessageDescriptor;
			descriptionIssueTermRefresh?: MessageDescriptor;
		};
		icon?: ReactNode;
		actions?: Action[];
		onDismissed?: (arg1: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			fireAnalyticsEvent?: (eventName: string, eventData?: Record<any, any>) => void;
		}) => void;
		shouldAutoDismiss?: boolean;
		type: FlagType;
		testId?: string;
	}
>;

/**
 * NOTE: Prefer to use <FlagServiceSubscriber> for new code
 */
// @ts-expect-error(PARTIAL_RECORD) TS2739 | TS2739 - Type '{ COMMENT_ADD_ERROR_GENERIC: { messages: { title: { id: string; defaultMessage: string; description: string; }; description: { id: string; defaultMessage: string; description: string; }; }; icon: Element; type: "error"; }; ... 37 more ...; REMOTE_LINK_VALIDATION_ERROR: { ...; }; }' is missing the following properties from type 'FlagMessages': UPDATE_TIME_REMAINING_SUCCESS, DELETE_WEB_LINK_FAILED | Type '{ COMMENT_ADD_ERROR_GENERIC: { messages: { title: { id: string; defaultMessage: string; description: string; }; description: { id: string; defaultMessage: string; description: string; }; }; icon: Element; type: "error"; }; ... 37 more ...; REMOTE_LINK_VALIDATION_ERROR: { ...; }; }' is missing the following properties from type 'FlagMessages': UPDATE_TIME_REMAINING_SUCCESS, DELETE_WEB_LINK_FAILED
export const flagMessages: FlagMessages = {
	[COMMENT_ADD_ERROR_GENERIC]: {
		messages: defineMessages({
			title: {
				id: 'issue.generic-server-error-when-adding-comment-title',
				defaultMessage: 'Comment could not be updated',
				description: '',
			},
			description: {
				id: 'issue.generic-server-error-when-adding-comment-description',

				defaultMessage:
					'There was a connection problem and your comment was not successfully updated. Check your network before trying to update or add another comment.',

				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[COMMENTS_PER_ISSUE_LIMIT_EXCEEDED]: {
		messages: defineMessages({
			title: {
				id: 'issue.comments-per-issue-limit-exceeded-title',
				defaultMessage: 'We couldn’t add your comment',
				description: 'Title for warning flag when comments per issue limit is exceeded',
			},
			description: {
				id: 'issue.comments-per-issue-limit-exceeded-description',
				defaultMessage:
					'We can’t add any more comments to this issue as the limit has been reached.',
				description: 'Description for warning flag when comments per issue limit is exceeded',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.comments-per-issue-limit-exceeded-description-issue-term-refresh',
				defaultMessage:
					'We can’t add any more comments to this work item as the limit has been reached.',
				description: 'Description for warning flag when comments per work item limit is exceeded',
			},
		}),
		icon: <Warning label="warning" primaryColor={token('color.icon.warning', colors.Y300)} />,
		type: 'warning',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue.comments-per-issue-limit-exceeded.contact-administrator',
						defaultMessage: 'Notify your admin',
						description: 'A link to notify your admin which opens the comment in new tab',
					},
				}),
				onClick: () => {
					// This is necessary since we cannot use React hooks in this file, but still need to access the actions
					const StoreInstance = defaultRegistry.getStore(LimitExceedStore);
					StoreInstance?.actions.openModal();
					StoreInstance?.actions.setFieldType('comment');
				},
			},
		],
	},
	[COMMENT_ADD_ERROR_INVALID]: {
		messages: defineMessages({
			title: {
				id: 'issue.server-validation-error-when-adding-comment-title',
				defaultMessage: "We couldn't save your comment",
				description: '',
			},
			description: {
				id: 'issue.server-validation-error-when-adding-comment-description',

				defaultMessage:
					'It might be empty or have invalid formatting. If you copy-pasted, try removing the formatting and adding it directly in the editor.',

				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[COMMENT_FUTURE_TIME_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.comment-future-time-error-when-adding-comment-title',
				defaultMessage: "We couldn't save your comment",
				description:
					'Title of flag error when comment did not save successfully because of future date',
			},
			description: {
				id: 'issue.comment-future-time-error-when-adding-comment-description',

				defaultMessage:
					"It looks like you've selected a future time for this activity. Send this activity to the current time or a past time instead.",

				description:
					'Description of flag error when saving comment failed because comment can not be future dated',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[COMMENT_CONTENT_EXCEEDED_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.content-exceeded-error-when-adding-comment-title',
				defaultMessage: "We couldn't save your comment",
				description: 'Title of flag error when comment did not save successfully',
			},
			description: {
				id: 'issue.content-exceeded-error-when-adding-comment-description',

				defaultMessage:
					'The comment is too long. Try reducing its length, splitting it, or attaching a file to the issue.',

				description:
					'Description of flag error when saving comment failed because comment content was too long',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.content-exceeded-error-when-adding-comment-description-issue-term-refresh',

				defaultMessage:
					'The comment is too long. Try reducing its length, splitting it, or attaching a file to the work item.',

				description:
					'Description of flag error when saving comment failed because comment content was too long',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[SAVE_COMMENT_SUCCESS_FLAG_MESSAGE]: {
		messages: defineMessages({
			title: {
				id: 'issue.save-comment-success-flag-when-added-comment-title',
				defaultMessage: 'Note added to the past',
				description: 'heading showing that we successfully added the comment',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue.save-comment-success-flag-when-added-comment-description',
						defaultMessage: 'View internal note',
						description: 'text for the link which opens the comment in new tab',
					},
				}),
				onClick: (a) => {
					a.openComment?.();
					a.closeFlag();
				},
			},
		],
		shouldAutoDismiss: true,
	},
	[COMMENT_DELETE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.server-error-when-deleting-comment-title',
				defaultMessage: 'Comment could not be deleted',
				description: '',
			},
			description: {
				id: 'issue.server-error-when-deleting-comment-description',

				defaultMessage:
					'There was a connection problem and the comment was not successfully deleted. Try checking your network connection.',

				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[EDITOR_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-retrieving-editor-value-title',
				defaultMessage: 'The text editor failed',
				description: '',
			},
			description: {
				id: 'issue.error-when-retrieving-editor-value-description',
				defaultMessage: 'Retry your last action, or refresh the page.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[RICH_CONTENT_FIELD_CONFIRM_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-confirming-rich-text-field-value-title',
				defaultMessage: "We couldn't save your content",
				description:
					'The title for an error message displayed to the user when a rich content field for an issue cannot be saved.',
			},
			description: {
				id: 'issue.error-when-confirming-rich-text-field-value-description',
				defaultMessage:
					'It may contain formatting we don’t recognize. Try removing the formatting before saving.',
				description:
					'The contents for an error message displayed to the user when a rich content field for an issue cannot be saved.',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-confirming-rich-text-field-value-title',
				defaultMessage: "We couldn't save your content",
				description:
					'The title for an error message displayed to the user when a rich content field for an issue cannot be saved.',
			},
			description: {
				id: 'issue.error-when-confirming-rich-text-field-value-description-maximum-length-exceeded',
				defaultMessage:
					"It's too long for the field. Try attaching a file or page to the issue, or adding part of the content as comments.",
				description:
					'The contents for an error message displayed to the user when a rich content field for an issue cannot be saved due to it exceeding maximum length limits.',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[RICH_CONTENT_FIELD_REQUIRED_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-saving-empty-required-rich-text-field-title',
				defaultMessage: "We couldn't save your content",
				description:
					'The title for an error message displayed to the user when a rich content field for an issue cannot be saved.',
			},
			description: {
				id: 'issue.error-when-saving-empty-required-rich-text-field',
				defaultMessage: 'This field is required and cannot be empty.',
				description:
					'The contents for an error message displayed to the user when a rich content field for an issue cannot be saved as empty due to it being a required field.',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[ATTACHMENT_ADD_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-adding-attachment-title',
				defaultMessage: "We couldn't save your attachment",
				description: '',
			},
			description: {
				id: 'issue.error-when-adding-attachment-description',
				defaultMessage: 'Reload the issue then try adding it again.',
				description: '',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.error-when-adding-attachment-description-issue-term-refresh',
				defaultMessage: 'Reload the work item then try adding it again.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-adding-attachment-exceed-limit-title',
				defaultMessage:
					"We couldn't upload the attachment as you've exceeded the attachment size limit",
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED]: {
		messages: defineMessages({
			title: {
				id: 'issue.issue.issue-attachments-per-issue-limit-exceeded.title',
				defaultMessage: 'We couldn’t attach the file',
				description: 'Title for warning flag when issue exceeds number of attachments hard limit',
			},
			description: {
				id: 'issue.issue.issue-attachments-per-issue-limit-exceeded.description',
				defaultMessage:
					'We can’t add any more attachments to this issue as the limit has been reached.',
				description:
					'Description for warning flag when issue exceeds number of attachments hard limit',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.issue.issue-attachments-per-issue-limit-exceeded.description-issue-term-refresh',
				defaultMessage:
					'We can’t add any more attachments to this work item as the limit has been reached.',
				description:
					'Description for warning flag when work item exceeds number of attachments hard limit',
			},
		}),
		icon: <Warning label="warning" primaryColor={token('color.icon.warning', colors.Y300)} />,
		type: 'warning',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue.issue.issue-attachments-per-issue-limit-exceeded.notify-admin',
						defaultMessage: 'Notify your admin',
						description: 'A link to notify your admin which opens the comment in new tab',
					},
				}),
				onClick: () => {
					// This is necessary since we cannot use React hooks in this file, but still need to access the actions
					const StoreInstance = defaultRegistry.getStore(LimitExceedStore);
					StoreInstance?.actions.openModal();
					StoreInstance?.actions.setFieldType('attachment');
				},
			},
		],
		shouldAutoDismiss: true,
	},
	[ATTACHMENT_ADD_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'issue.attachment-jira-creation-success-title',
				defaultMessage: 'Successfully attached',
				description: 'The title of the success flag for when attachments are successfully attached',
			},
			description: {
				id: 'issue.attachment-jira-creation-success-description',
				defaultMessage:
					"You've added {numCreated, plural, one {an attachment} other {attachments}} to {issueKey}.",
				description:
					'The description of the success flag for when attachments are successfully attached',
			},
		}),
		type: 'success',
		icon: genericSuccessIcon,
	},
	[DELETE_LINKED_ISSUE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.server-error-when-deleting-issue-link-title',
				defaultMessage: "We couldn't remove the issue link",
				description: '',
			},
			titleIssueTermRefresh: {
				id: 'issue.server-error-when-deleting-issue-link-title-issue-term-refresh',
				defaultMessage: "We couldn't remove the work item link",
				description: '',
			},
			description: {
				id: 'issue.server-error-when-deleting-issue-link-description',
				defaultMessage: 'Try reloading the page and removing the issue link again.',
				description: '',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.server-error-when-deleting-issue-link-description-issue-term-refresh',
				defaultMessage: 'Try reloading the page and removing the work item link again.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[ATTACHMENT_DELETE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.server-error-when-deleting-attachment-title',
				defaultMessage: 'Attachment could not be deleted',
				description: '',
			},
			description: {
				id: 'issue.server-error-when-deleting-attachment-description',

				defaultMessage:
					'There was a connection problem and the attachment was not successfully deleted. Try checking your network connection.',

				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[WATCH_TOGGLE_UPDATE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.server-error-when-toggle-watch-title',
				defaultMessage: "We couldn't update your watch status",
				description: '',
			},
			description: {
				id: 'issue.server-error-when-toggle-watch-description',
				defaultMessage: 'Check your connection and try clicking watch again.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[FETCH_WATCHERS_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-fetching-watchers-title',
				defaultMessage: "We couldn't load the watchers list",
				description:
					'Displayed when there is an error while fetching the list of watchers for an issue.',
			},
			description: {
				id: 'issue.error-when-fetching-watchers-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The call to action that users see when there was error while trying to load the list of watchers.',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[START_WATCHING_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'issue.start-watching-success-title',
				defaultMessage: "You're watching this issue",
				description: 'Displayed when the user starts watching the issue using a hotkey',
			},
			titleIssueTermRefresh: {
				id: 'issue.start-watching-success-title-issue-term-refresh',
				defaultMessage: "You're watching this work item",
				description: 'Displayed when the user starts watching the work item using a hotkey',
			},
			description: {
				id: 'issue.start-watching-success-description',
				defaultMessage: "We'll notify you when there's activity on it.",
				description:
					'The message shown when a user starts watching an issue successfully with a hotkey ',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
	},
	[STOP_WATCHING_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'issue.stop-watching-success-title',
				defaultMessage: 'You stopped watching this issue',
				description: 'Displayed when the user stops watching the issue using a hotkey',
			},
			titleIssueTermRefresh: {
				id: 'issue.stop-watching-success-title-issue-term-refresh',
				defaultMessage: 'You stopped watching this work item',
				description: 'Displayed when the user stops watching the work item using a hotkey',
			},
			description: {
				id: 'issue.stop-watching-success-description',
				defaultMessage: "We won't notify you about activity on it.",
				description:
					'The message shown when a user stops watching an issue successfully with a hotkey ',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
	},
	[REMOVE_WATCHER_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-removing-watcher-title',
				defaultMessage: "We couldn't remove that watcher",
				description: 'Displayed when there is an error while removing the watcher for an issue.',
			},
			description: {
				id: 'issue.error-when-removing-watcher-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The call to action that users see when there was error while trying to remove watcher.',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[ADD_WATCHER_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-adding-watcher-title',
				defaultMessage: "We couldn't add that watcher",
				description: 'Displayed when there is an error while adding the watcher for an issue.',
			},
			description: {
				id: 'issue.error-when-adding-watcher-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The call to action that users see when there was error while trying to add watcher.',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[SUBTASKS_REORDER_ERROR_GENERIC]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-reordering-subtask',
				defaultMessage: "We couldn't reorder those subtasks",
				description: '',
			},
			description: {
				id: 'issue.error-when-reordering-subtask-description',
				defaultMessage: 'Wait a few moments, then give it another try.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[LOG_WORK_CONFIRM_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.server-error-when-logging-work-title',
				defaultMessage: "We couldn't add your time spent",
				description: '',
			},
			description: {
				id: 'issue.server-error-when-logging-work-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[ASSIGN_TO_ME_SUCCESS]: {
		// keep up to date with jira/src/packages/issue/keyboard-shortcuts/src/services/assign-to-me-or-unassign/messages.tsx
		messages: defineMessages({
			title: {
				id: 'issue.assign-to-me-flag-title',
				defaultMessage: 'Successfully assigned to you',
				description: '',
			},
			description: {
				id: 'issue.assign-to-me-flag-description',
				defaultMessage: "You're now the assignee for {issueKey}.",
				description: '',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[REMOVE_FROM_ME_SUCCESS]: {
		// keep up to date with jira/src/packages/issue/keyboard-shortcuts/src/services/assign-to-me-or-unassign/messages.tsx
		messages: defineMessages({
			title: {
				id: 'issue.remove-from-me-flag-title',
				defaultMessage: 'Successfully unassigned issue',
				description: 'Title of the flag displayed when users remove themselves from the assignees',
			},
			titleIssueTermRefresh: {
				id: 'issue.remove-from-me-flag-title-issue-term-refresh',
				defaultMessage: 'Successfully unassigned work item',
				description: 'Title of the flag displayed when users remove themselves from the assignees',
			},
			description: {
				id: 'issue.remove-from-me-flag-description',
				defaultMessage:
					"You've been removed as the assignee from {issueKey}. (To reassign yourself, select 'i' again.)",
				description:
					'Description of the flag displayed when users remove themselves from the assignees by pressing hotkey i',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[CREATE_CHILD_ISSUE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-creating-child-issue-title',
				defaultMessage: "We couldn't create the child issue",
				description: '',
			},
			titleIssueTermRefresh: {
				id: 'issue.error-when-creating-child-issue-title-issue-term-refresh',
				defaultMessage: "We couldn't create the child work item",
				description: '',
			},
			description: {
				id: 'issue.error-when-creating-child-issue-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[LINK_CHILD_ISSUE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-linking-child-issue-title',
				defaultMessage: "We couldn't link the child issue",
				description: '',
			},
			titleIssueTermRefresh: {
				id: 'issue.error-when-linking-child-issue-title-issue-term-refresh',
				defaultMessage: "We couldn't link the child work item",
				description: '',
			},
			description: {
				id: 'issue.error-when-linking-child-issue-description',
				defaultMessage:
					'The issue was created but the linking failed. Head over to your board and try again.',
				description: '',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.error-when-linking-child-issue-description-issue-term-refresh',
				defaultMessage:
					'The work item was created but the linking failed. Head over to your board and try again.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[CHANGE_ISSUE_TYPE_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'change.issue.type.success.title',
				defaultMessage: 'Issue type changed to {destType}',
				description: '',
			},
			titleIssueTermRefresh: {
				id: 'change.issue.type.success.title-issue-term-refresh',
				defaultMessage: 'Work item type changed to {destType}',
				description: '',
			},
			description: {
				id: 'change.issue.type.success.description',
				defaultMessage: 'Issue type changed',
				description: '',
			},
			descriptionIssueTermRefresh: {
				id: 'change.issue.type.success.description-issue-term-refresh',
				defaultMessage: 'Work Item type changed',
				description: '',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		shouldAutoDismiss: false,
		testId: 'issue.views.issue-base.foundation.change-issue-type.flag.success',
	},
	[CHANGE_ISSUE_TYPE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'change.issue.type.error.title',
				defaultMessage: 'Error changing issue type',
				description: '',
			},
			titleIssueTermRefresh: {
				id: 'change.issue.type.error.title-issue-term-refresh',
				defaultMessage: 'Error changing work item type',
				description: '',
			},
			description: {
				id: 'change.issue.type.error.description',
				defaultMessage: 'There was an error changing the issue type. Please try again.',
				description: '',
			},
			descriptionIssueTermRefresh: {
				id: 'change.issue.type.error.description-issue-term-refresh',
				defaultMessage: 'There was an error changing the work item type. Please try again.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
		shouldAutoDismiss: false,
		testId: 'issue.views.issue-base.foundation.change-issue-type.flag.error',
	},
	[DELETE_ISSUE_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.error-when-deleting-issue-title',
				defaultMessage: "We couldn't delete {issueKey}",
				description: '',
			},
			description: {
				id: 'issue.error-when-deleting-issue-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description: '',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue.error-when-deleting-issue-call-to-action',
						defaultMessage: 'Try again',
						description: '',
					},
				}),
				onClick: ({ closeFlag, retryIssueDelete, deleteFailureIssueKey }) => {
					if (
						retryIssueDelete &&
						deleteFailureIssueKey !== undefined &&
						deleteFailureIssueKey !== null &&
						deleteFailureIssueKey !== ''
					) {
						retryIssueDelete(deleteFailureIssueKey);
					}
					closeFlag();
				},
			},
		],
	},
	[EDIT_WORKLOG_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'issue.activity.worklog.flags.edit-success-title',
				defaultMessage: 'Work log entry updated',
				description:
					'The title text to show in the success flag when a worklog is successfully edited',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[DELETE_WORKLOG_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'issue.activity.worklog.flags.delete-success-title',
				defaultMessage: 'Work log entry deleted',
				description: 'The title text to show in the success flag when a worklog is deleted',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[ADD_WORKLOG_FAILURE]: {
		messages: defineMessages({
			title: {
				id: 'issue.activity.worklog.flags.add-failure-title',
				defaultMessage: "We couldn't save your changes",
				description: 'The title text to show in the error flag when adding the worklog fails',
			},
			description: {
				id: 'issue.activity.worklog.flags.add-failure-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The description text to show in the error flag when adding a new worklog fails',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED]: {
		messages: defineMessages({
			title: {
				id: 'issue.worklogs-per-issue-limit-exceeded-title',
				defaultMessage: 'We couldn’t log your time',
				description: 'Title for warning flag when worklogs per issue limit is exceeded',
			},
			description: {
				id: 'issue.worklogs-per-issue-limit-exceeded-description',
				defaultMessage: 'We can’t log any more time on this issue as the limit as been reached.',
				description: 'Description for warning flag when worklogs per issue limit is exceeded',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.worklogs-per-issue-limit-exceeded-description-issue-term-refresh',
				defaultMessage:
					'We can’t log any more time on this work item as the limit as been reached.',
				description: 'Description for warning flag when worklogs per work item limit is exceeded',
			},
		}),
		icon: <Warning label="warning" primaryColor={token('color.icon.warning', colors.Y300)} />,
		type: 'warning',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue.worklogs-per-issue-limit-exceeded.contact-administrator',
						defaultMessage: 'Notify your admin',
						description: 'A link to notify your admin which opens the comment in new tab',
					},
				}),
				onClick: () => {
					// This is necessary since we cannot use React hooks in this file, but still need to access the actions
					const StoreInstance = defaultRegistry.getStore(LimitExceedStore);
					StoreInstance?.actions.openModal();
					StoreInstance?.actions.setFieldType('worklog');
				},
			},
		],
	},
	[ADD_WORKLOG_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'issue.activity.worklog.flags.add-success-title',
				defaultMessage: 'Successfully logged work',
				description: 'The title text to show in the success flag when adding the worklog succeeds',
			},
			description: {
				id: 'issue.activity.worklog.flags.add-success-description',
				defaultMessage: "You've logged work to {issueKey}.",
				description:
					'The description text to show in the success flag when adding a new worklog succeeds',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[UPDATE_TIME_REMAINING_FAILURE]: {
		messages: defineMessages({
			title: {
				id: 'issue.activity.worklog.flags.update-time-remaining-failure-title',
				defaultMessage: "We couldn't save your edit",
				description:
					'The title text to show in the error flag when updating the remaining time fails',
			},
			description: {
				id: 'issue.activity.worklog.flags.update-time-remaining-failure-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The description text to show in the error flag when updating the remaining time fails',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[EDIT_WORKLOG_FAILURE]: {
		messages: defineMessages({
			title: {
				id: 'issue.activity.worklog.flags.edit-failure-title',
				defaultMessage: "We couldn't save your edit",
				description: 'The title text to show in the error flag when editing the worklog fails',
			},
			description: {
				id: 'issue.activity.worklog.flags.failure-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The description text to show in the error flag when editing or deleting the worklog fails',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[DELETE_WORKLOG_FAILURE]: {
		messages: defineMessages({
			title: {
				id: 'issue.activity.worklog.flags.delete-failure-title',
				defaultMessage: "We couldn't delete the work log entry",
				description: 'The title text to show in the error flag when deleting the worklog fails',
			},
			description: {
				id: 'issue.activity.worklog.flags.failure-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The description text to show in the error flag when editing or deleting the worklog fails',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[DELETE_CONFLUENCE_PAGE_LINK_FAILED]: {
		messages: defineMessages({
			title: {
				id: 'issue.confluence-pages.delete-failure-title',
				defaultMessage: 'We couldn’t remove the Confluence page link',
				description:
					'The title text to show in the error flag when deleting a confluence page link fails',
			},
			description: {
				id: 'issue.confluence-pages.delete-failure-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The description text to show in the error flag when deleting a confluence page link fails',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
	},
	[CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED]: {
		messages: defineMessages({
			title: {
				id: 'issue.links-per-issue-limit-exceeded-title',
				defaultMessage: 'We couldn’t add the link',
				description: 'Title for warning flag when remote links per issue limit is exceeded',
			},
			description: {
				id: 'issue.links-per-issue-limit-exceeded-description',
				defaultMessage:
					'We can’t link to any more Confluence pages in this issue as the limit of remote links has been reached.',
				description:
					'Description for warning flag when confluence links per issue limit is exceeded',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.links-per-issue-limit-exceeded-description-issue-term-refresh',
				defaultMessage:
					'We can’t link to any more Confluence pages in this work item as the limit of remote links has been reached.',
				description:
					'Description for warning flag when confluence links per work item limit is exceeded',
			},
		}),
		icon: <Warning label="warning" primaryColor={token('color.icon.warning', colors.Y300)} />,
		type: 'warning',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue.links-per-issue-limit-exceeded.contact-administrator',
						defaultMessage: 'Notify your admin',
						description: 'A link to notify your admin which opens the comment in new tab',
					},
				}),
				onClick: () => {
					// This is necessary since we cannot use React hooks in this file, but still need to access the actions
					const StoreInstance = defaultRegistry.getStore(LimitExceedStore);
					StoreInstance?.actions.openModal();
					StoreInstance?.actions.setFieldType('attachment');
				},
			},
		],
	},
	[SAVE_APPROVAL_ANSWER_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.approval.action.save-error-title',
				defaultMessage: 'We couldn’t save your approval response',
				description:
					'The title text to show in the error flag when saving the approval answer fails',
			},
			description: {
				id: 'issue.approval.action.save-error-description',
				defaultMessage: 'Give it a few seconds, then try again.',
				description:
					'The description text to show in the error flag when saving the approval answer fails',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
		shouldAutoDismiss: true,
	},
	[SAVE_APPROVAL_ANSWER_SUCCESS]: {
		messages: defineMessages({
			title: {
				id: 'issue.approval.action.save-success-title',
				defaultMessage: "We've saved your response.",
				description:
					'The title text to show in the success flag when saving the approval answer is successful',
			},
		}),
		icon: genericSuccessIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[EDIT_EPIC_COLOUR_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.color.action.change-color-title',
				defaultMessage: 'We couldn’t change the epic color',
				description: 'The title text to show when updating the epic color fails',
			},
			description: {
				id: 'issue.color.action.change-color-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description: 'The description text to show in the error flag when updating epic color',
			},
		}),
		icon: genericErrorIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[EDIT_ISSUE_COLOUR_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.color.action.change-issue-color-title',
				defaultMessage: 'We couldn’t change the issue color',
				description: 'The title text to show when updating the issue color fails',
			},
			titleIssueTermRefresh: {
				id: 'issue.color.action.change-issue-color-title-issue-term-refresh',
				defaultMessage: 'We couldn’t change the work item color',
				description: 'The title text to show when updating the work item color fails',
			},
			description: {
				id: 'issue.color.action.change-issue-color-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description: 'The description text to show in the error flag when updating issue color',
			},
		}),
		icon: genericErrorIcon,
		type: 'success',
		shouldAutoDismiss: true,
	},
	[FETCH_JIRA_APP_LINKS_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.link.jira-app-link-fetch-error-title',
				defaultMessage: 'We couldn’t fetch remote Jira application links',
				description: 'The title text to show when fetching Jira app-links fails',
			},
			description: {
				id: 'issue.link.jira-app-link-fetch-error-description',
				defaultMessage: 'Check your connection, then give it another try.',
				description:
					'The description text to show in the error flag when fetching Jira app-links fails',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
		shouldAutoDismiss: true,
	},
	[REMOTE_LINK_VALIDATION_ERROR]: {
		messages: defineMessages({
			title: {
				id: 'issue.link.jira-remote-link-server-error-title',
				defaultMessage: "We couldn't link the issue to {remoteIssueKey}.",
				description: 'The title text to show when add remote issue link failed',
			},
			titleIssueTermRefresh: {
				id: 'issue.link.jira-remote-link-server-error-title-issue-term-refresh',
				defaultMessage: "We couldn't link the work item to {remoteIssueKey}.",
				description: 'The title text to show when add remote work item link failed',
			},
			description: {
				id: 'issue.link.jira-remote-link-server-error-description',
				defaultMessage: 'Reason: {i18nServerErrorMessage}',
				description: 'The description text to show when add remote issue link failed',
			},
		}),
		icon: genericErrorIcon,
		type: 'error',
		shouldAutoDismiss: true,
	},
	[ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED]: {
		messages: defineMessages({
			title: {
				id: 'issue.issue-links-per-issue-limit-exceeded-title',
				defaultMessage: 'We couldn’t link the issue',
				description: 'Title for warning flag when issue links per issue limit is exceeded',
			},
			titleIssueTermRefresh: {
				id: 'issue.issue-links-per-issue-limit-exceeded-title-issue-term-refresh',
				defaultMessage: 'We couldn’t link the work item',
				description: 'Title for warning flag when work item links per issue limit is exceeded',
			},
			description: {
				id: 'issue.issue-links-per-issue-limit-exceeded-description',
				defaultMessage: 'We can’t link any more local issues as the limit has been reached.',
				description: 'Description for warning flag when issue links per issue limit is exceeded',
			},
			descriptionIssueTermRefresh: {
				id: 'issue.issue-links-per-issue-limit-exceeded-description-issue-term-refresh',
				defaultMessage: 'We can’t link any more local work item as the limit has been reached.',
				description:
					'Description for warning flag when work item links per issue limit is exceeded',
			},
		}),
		icon: <Warning label="warning" primaryColor={token('color.icon.warning', colors.Y300)} />,
		type: 'warning',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue.issue-links-per-issue-limit-exceeded.contact-administrator',
						defaultMessage: 'Notify your admin',
						description: 'A link to notify your admin which opens the comment in new tab',
					},
				}),
				onClick: () => {
					// This is necessary since we cannot use React hooks in this file, but still need to access the actions
					const StoreInstance = defaultRegistry.getStore(LimitExceedStore);
					StoreInstance?.actions.openModal();
					StoreInstance?.actions.setFieldType('issuelinks');
				},
			},
		],
	},
	[ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED]: {
		messages: defineMessages({
			title: {
				id: 'issue-view-base.content.issue-links-limit-exceeded.title',
				defaultMessage: 'We couldn’t link the issue',
				description: 'Title for warning flag when issue exceeds remote links hard limit',
			},
			titleIssueTermRefresh: {
				id: 'issue-view-base.content.issue-links-limit-exceeded.title-issue-term-refresh',
				defaultMessage: 'We couldn’t link the work item',
				description: 'Title for warning flag when work item exceeds remote links hard limit',
			},
			description: {
				id: 'issue-view-base.content.issue-links-limit-exceeded.description',
				defaultMessage: 'We can’t link any more remote issues as the limit has been reached.',
				description: 'Description for warning flag when issue exceeds remote links hard limit',
			},
			descriptionIssueTermRefresh: {
				id: 'issue-view-base.content.issue-links-limit-exceeded.description-issue-term-refresh',
				defaultMessage: 'We can’t link any more remote work items as the limit has been reached.',
				description: 'Description for warning flag when work item exceeds remote links hard limit',
			},
		}),
		icon: <Warning label="warning" primaryColor={token('color.icon.warning', colors.Y300)} />,
		type: 'warning',
		actions: [
			{
				messages: defineMessages({
					content: {
						id: 'issue-view-base.content.issue-links.content',
						defaultMessage: 'Notify your admin',
						description: 'A link to notify your admin which opens the comment in new tab',
					},
				}),
				onClick: () => {
					// This is necessary since we cannot use React hooks in this file, but still need to access the actions
					const StoreInstance = defaultRegistry.getStore(LimitExceedStore);
					StoreInstance?.actions.openModal();
					StoreInstance?.actions.setFieldType('remoteIssueLinks');
				},
			},
		],
	},
};
