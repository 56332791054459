import React, { useMemo } from 'react';
import { MOBILE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsSource,
	useProjectKey,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { TIME_TRACKING_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useTimeTrackingConfiguration } from '@atlassian/jira-settings-service/src/main.tsx';
import type { ShortcutOption } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import {
	LogWork,
	LogWorkButtonItem,
	label as logWorkLabel,
} from '../add-to-issue-dropdown-group/log-work/index.tsx';

export const logWorkDropdownGroup = (
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
) => ({
	name: '',
	key: 'LogWorkDropdownGroup',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: actions.reduce<Array<any>>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []),
});

export const useLogworkActions = (): ShortcutOption[] => {
	const { formatMessage } = useIntl();
	const source = useAnalyticsSource();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey();
	const [permissions] = useProjectPermissions(projectKey);
	const [timeTrackingFieldConfigData] = useFieldConfig(issueKey, TIME_TRACKING_TYPE);
	const timeTrackingFieldConfig = timeTrackingFieldConfigData.value;
	const [timeTrackingConfig] = useTimeTrackingConfiguration();

	const canLogTime = useMemo(
		() =>
			source !== MOBILE_ISSUE &&
			permissions.canLogWork &&
			!!timeTrackingFieldConfig &&
			timeTrackingConfig.isTimeTrackingEnabled,
		[source, permissions, timeTrackingFieldConfig, timeTrackingConfig],
	);

	const items = useMemo(
		() => [
			...(canLogTime
				? [
						{
							Component: LogWork,
							DialogComponent: LogWorkButtonItem,
							label: formatMessage(logWorkLabel),
							key: 'logWork',
						},
					]
				: []),
		],
		[canLogTime, formatMessage],
	);

	return items;
};
