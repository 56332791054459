/**
 * @generated SignedSource<<5eb94ae38c07e0bd1967239bfa6378c0>>
 * @relayHash f5da4fdf7670a03cb75d0ad537d6c34d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e0686a8dfd6bb41a72cfd5ab01c73272e165a08a09847c5d3474a3d723cd3575

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CustomerServiceContextType = "DEFAULT" | "ISSUE" | "%future added value";
export type CustomerServiceFilterInput = {
  context: CustomerServiceContext;
};
export type CustomerServiceContext = {
  issueId?: string | null | undefined;
  type: CustomerServiceContextType;
};
export type panelViewEntitlementQuery$variables = {
  cloudId: string;
  entitlementId: string;
  filter: CustomerServiceFilterInput;
  jcsIssueLayoutEapEnabled: boolean;
};
export type panelViewEntitlementQuery$data = {
  readonly customerService: {
    readonly entitlementById: {
      readonly __typename: "CustomerServiceEntitlement";
      readonly details: {
        readonly " $fragmentSpreads": FragmentRefs<"detailsView_servicedeskCustomerServiceIssueViewPanel">;
      };
      readonly entity?: {
        readonly __typename: "CustomerServiceIndividual";
        readonly id: string;
      } | {
        readonly __typename: "CustomerServiceOrganization";
        readonly id: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly id?: string;
    } | {
      readonly __typename: "QueryError";
      readonly extensions: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"throwOnQueryError_servicedeskCustomerServiceCommon">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type panelViewEntitlementQuery = {
  response: panelViewEntitlementQuery$data;
  variables: panelViewEntitlementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entitlementId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jcsIssueLayoutEapEnabled"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "entitlementId",
    "variableName": "entitlementId"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "CustomerServiceIndividual"
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "CustomerServiceOrganization"
},
v8 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v9 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v10 = {
  "kind": "ScalarField",
  "name": "name"
},
v11 = {
  "kind": "ScalarField",
  "name": "identifier"
},
v12 = {
  "kind": "ScalarField",
  "name": "message"
},
v13 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "panelViewEntitlementQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "CustomerServiceQueryApi",
          "kind": "LinkedField",
          "name": "customerService",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "entitlementById",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "condition": "jcsIssueLayoutEapEnabled",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "entity",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "detailsView_servicedeskCustomerServiceIssueViewPanel"
                          }
                        ]
                      }
                    ],
                    "type": "CustomerServiceEntitlement"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "throwOnQueryError_servicedeskCustomerServiceCommon"
                      }
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              "action": "THROW",
              "path": "customerService.entitlementById"
            }
          ]
        },
        "action": "THROW",
        "path": "customerService"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "panelViewEntitlementQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "CustomerServiceQueryApi",
        "kind": "LinkedField",
        "name": "customerService",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "condition": "jcsIssueLayoutEapEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "entity",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isCustomerServiceCustomDetailValuesQueryResult"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "CustomerServiceCustomDetailValue",
                            "kind": "LinkedField",
                            "name": "results",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v10/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "values"
                              },
                              {
                                "concreteType": "CustomerServiceCustomDetailType",
                                "kind": "LinkedField",
                                "name": "type",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "options"
                                  }
                                ]
                              },
                              {
                                "concreteType": "CustomerServiceCustomDetailConfigMetadata",
                                "kind": "LinkedField",
                                "name": "config",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "CustomerServiceCustomAttributeStyleConfiguration",
                                    "kind": "LinkedField",
                                    "name": "styleConfiguration",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "CustomerServiceCustomAttributeOptionsStyleConfiguration",
                                        "kind": "LinkedField",
                                        "name": "options",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "optionValue"
                                          },
                                          {
                                            "concreteType": "CustomerServiceCustomAttributeOptionStyle",
                                            "kind": "LinkedField",
                                            "name": "style",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "backgroundColour"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "CustomerServiceCustomDetailValues"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ],
                        "type": "QueryError"
                      }
                    ]
                  }
                ],
                "type": "CustomerServiceEntitlement"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v13/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "type": "QueryError"
              },
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e0686a8dfd6bb41a72cfd5ab01c73272e165a08a09847c5d3474a3d723cd3575",
    "metadata": {},
    "name": "panelViewEntitlementQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "13583339aaf25aafea36574694445b21";

export default node;
