import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	type MentionsResult,
	AbstractMentionResource,
	type MentionContextIdentifier,
	type UserRole,
	type MentionDescription,
} from '@atlaskit/mention';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { triggerOpenDrawer } from '@atlassian/jira-invite-people-drawer/src/controllers/index.tsx';
import { type ServerUser, subProductFromProjectType } from './utils.tsx';

const MENTION_DEBOUNCE_MS = 150;

export abstract class AbstractMentionProvider extends AbstractMentionResource {
	constructor(
		baseUrl: string,
		projectType?: ProjectType | null,
		createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	) {
		super();
		this.baseUrl = baseUrl;
		this.lastSearchTime = 0;
		this.activeSearches = new Set();

		this.productName = subProductFromProjectType(projectType);
		this.userRole = getTenantContext_DEPRECATED_DO_NOT_USE().isAdmin ? 'admin' : 'basic';
		this.onInviteItemClick = () => {
			triggerOpenDrawer(createAnalyticsEvent ?? null, { inviteFlow: 'mention' });
		};
		this.shouldEnableInvite = true;
		this.isEligibleXProductUserInvite = false;
		this.inviteXProductUser = () => Promise.resolve();
	}

	isFiltering(query: string) {
		return this.activeSearches.has(query);
	}

	filter(query?: string | null, contextIdentifier?: MentionContextIdentifier) {
		return this.debouncedFilter(query, contextIdentifier);
	}

	debouncedFilter = debounce(
		(query?: string | null, contextIdentifier?: MentionContextIdentifier) => {
			const currentSearchTime = Date.now();
			// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
			return this.search(query, contextIdentifier)
				.then((mentionResult) => {
					// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
					this.activeSearches.delete(query);
					// discard the response from previous requests
					if (currentSearchTime > this.lastSearchTime) {
						this.lastSearchTime = currentSearchTime;

						this._notifyListeners(mentionResult, {
							duration: Date.now() - currentSearchTime,
						});
					}

					this._notifyAllResultsListeners(mentionResult);
				})
				.catch((e) => {
					if (!isNil(query) && query.length > 0) {
						this.activeSearches.delete(query);
					}

					this._notifyErrorListeners(e);
				});
		},
		MENTION_DEBOUNCE_MS,
	);

	abstract getQueryString: (query?: string, contextIdentifier?: MentionContextIdentifier) => string;

	abstract getUrl: (queryString: string) => string;

	abstract shouldHighlightMention: (mention: MentionDescription) => boolean;

	lastSearchTime: number;

	activeSearches: Set<string>;

	baseUrl: string;

	search(query: string, context?: MentionContextIdentifier): Promise<MentionsResult> {
		const queryString = this.getQueryString(query, context);
		const url = this.getUrl(queryString);

		this.activeSearches.add(query);

		return this.fetchMentions(url)
			.then((users) =>
				users.map<MentionDescription>((user) => ({
					name: user.displayName,
					mentionName: user.displayName,
					nickname: user.displayName,
					id: user.accountId,
					avatarUrl: user.avatarUrls['48x48'],
				})),
			)
			.then((mentions) => ({ query, mentions }));
	}

	// Allow concrete subclasses to override this method so fetch calls can be instrumented
	fetchMentions(url: string): Promise<ServerUser[]> {
		return fetchJson(url);
	}

	shouldEnableInvite: boolean;

	userRole: UserRole;

	onInviteItemClick: () => void;

	productName: string;

	isEligibleXProductUserInvite: boolean;

	inviteXProductUser: (userId: string) => Promise<void>;
}
