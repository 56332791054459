import { browseIssueEmbedRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueEmbedRouteEntry.tsx';
import { browseIssueRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueRouteEntry.tsx';
import { legacyRedirectRouteEntry } from '@atlassian/jira-router-routes-legacy-redirect-entries/src/legacyRedirectRouteEntry.tsx';

import { bootstrapSingleSpaRoute } from './bootstrap/single-spa-route.tsx';

bootstrapSingleSpaRoute(() => [
	browseIssueEmbedRouteEntry,
	browseIssueRouteEntry,
	legacyRedirectRouteEntry,
]);
