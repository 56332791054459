/**
 * @generated SignedSource<<9442be53bcdd7af7728f5a6167fc0c3d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutUrlField_IssueViewUrlField$data = {
  readonly __typename: "JiraUrlField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutFieldHeading_IssueViewFieldHeading" | "url_issueFieldUrlInlineEditFull_UrlInlineEditViewNew_fragmentRef" | "url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef">;
  readonly " $fragmentType": "ui_issueViewLayoutUrlField_IssueViewUrlField";
};
export type ui_issueViewLayoutUrlField_IssueViewUrlField$key = {
  readonly " $data"?: ui_issueViewLayoutUrlField_IssueViewUrlField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutUrlField_IssueViewUrlField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutUrlField_IssueViewUrlField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "url_issueFieldUrlInlineEditFull_UrlInlineEditViewNew_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraUrlField"
};

(node as any).hash = "f0478c6839c358a1a8ce6f726c8a2427";

export default node;
