/**
 * @generated SignedSource<<edfb7e3bc52d29a5fac0a962b57c6df5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault$data = {
  readonly __typename: "JiraNumberField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberInlineEditFull_NumberInlineEditViewNew_fragmentRef" | "number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault";
};
export type ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault$key = {
  readonly " $data"?: ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "number_issueFieldNumberInlineEditFull_NumberInlineEditViewNew_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "a42dd36ad50cc77782f662aa95d9e775";

export default node;
