/**
 * @generated SignedSource<<c951008e392873dc1e4c8040c5239d71>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTemplates_DateFieldsMessage$data = {
  readonly issueByKey: {
    readonly projectField: {
      readonly project: {
        readonly canAdministerProject: {
          readonly canPerform: boolean;
        } | null | undefined;
        readonly projectStyle: JiraProjectStyle | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutTemplates_DateFieldsMessage";
};
export type ui_issueViewLayoutTemplates_DateFieldsMessage$key = {
  readonly " $data"?: ui_issueViewLayoutTemplates_DateFieldsMessage$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTemplates_DateFieldsMessage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "issueKey"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTemplates_DateFieldsMessage",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        {
          "kind": "Variable",
          "name": "key",
          "variableName": "issueKey"
        }
      ],
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issueByKey",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProjectField",
          "kind": "LinkedField",
          "name": "projectField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "projectStyle"
                },
                {
                  "alias": "canAdministerProject",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "type",
                      "value": "EDIT_PROJECT_CONFIG"
                    }
                  ],
                  "concreteType": "JiraProjectAction",
                  "kind": "LinkedField",
                  "name": "action",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "canPerform"
                    }
                  ],
                  "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "1a7aabe605a5cef01fc484757e2b0e2d";

export default node;
