import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useInlineEdit } from '@atlassian/jira-inline-edit-lite/src/ui/use-inline-edit/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import ValidationPopup from '@atlassian/jira-issue-field-validation-popup/src/ui/index.tsx';
import type { ValidationPopupProps } from '@atlassian/jira-issue-field-validation-popup/src/ui/types.tsx';
import { EditView } from './edit-view/index.tsx';
import messages from './messages.tsx';
import { ReadViewNonEditable } from './read-view-non-editable/index.tsx';
import { ReadView } from './read-view/index.tsx';
import type { FieldInlineEditLiteProps, ValidationFieldProps } from './types.tsx';

// Fallback placements to be used for the validation popover if it overflows its clipping parent
const fallbackPlacements: ValidationPopupProps['fallbackPlacements'] = [
	'left',
	'top-start',
	'auto',
];

/**
 * Component leveraging `@atlassian/jira-inline-edit-lite` to provide consistent spacing and validation UX for issue
 * fields.
 */
const FieldInlineEditLite = ({
	editView,
	editViewPopup,
	editViewPopupAlignBlock,
	editViewPopupMinWidth,
	fieldName,
	hasUnsubmittedChanges,
	hideActionButtons,
	hideEditButton,
	invalidMessage,
	isEditable,
	isEditing,
	keepEditViewOpenOnBlur,
	onCancel,
	onConfirm,
	onEdit,
	onCopy,
	onPaste,
	readViewFitContainerHeight,
	readViewContainerRef,
	readView,
	confirmButtonLabel: confirmButtonLabelProp,
	cancelButtonLabel: cancelButtonLabelProp,
	editButtonLabel: editButtonLabelProp,
}: FieldInlineEditLiteProps) => {
	const { formatMessage } = useIntl();

	const { context, refs } = useInlineEdit({
		isEditing,
		onCancel,
		onConfirm,
		onEdit,
	});

	const confirmButtonLabel =
		confirmButtonLabelProp ??
		formatMessage(messages.confirmButtonLabel, {
			fieldName,
		});
	const cancelButtonLabel =
		cancelButtonLabelProp ??
		formatMessage(messages.cancelButtonLabel, {
			fieldName,
		});
	const editButtonLabel =
		editButtonLabelProp ??
		formatMessage(messages.editButtonLabel, {
			fieldName,
		});

	if (!isEditable && !fg('empanada_inline_edit_ttvc_improvement')) {
		return (
			<ReadViewNonEditable
				hideEditButton={hideEditButton}
				readViewFitContainerHeight={readViewFitContainerHeight}
			>
				{readView()}
			</ReadViewNonEditable>
		);
	}

	if (isEditing && (isEditable || !fg('empanada_inline_edit_ttvc_improvement'))) {
		return (
			<EditView
				cancelButtonLabel={cancelButtonLabel}
				confirmButtonLabel={confirmButtonLabel}
				context={context}
				editViewPopup={editViewPopup}
				editViewPopupAlignBlock={editViewPopupAlignBlock}
				editViewPopupMinWidth={editViewPopupMinWidth}
				hideActionButtons={hideActionButtons}
				keepEditViewOpenOnBlur={keepEditViewOpenOnBlur}
			>
				<ValidationPopup
					message={invalidMessage}
					trigger={(triggerProps: ValidationFieldProps) => editView(triggerProps)}
					placement="right"
					fallbackPlacements={fallbackPlacements}
				/>
			</EditView>
		);
	}

	return (
		<ReadView
			context={context}
			editButtonLabel={editButtonLabel}
			editButtonRef={refs.editButton}
			hideEditButton={hideEditButton}
			isEditInteractionEnabled={isEditable}
			isInvalid={hasUnsubmittedChanges}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readViewContainerRef={readViewContainerRef}
			onCopy={onCopy}
			onPaste={onPaste}
		>
			{readView()}
		</ReadView>
	);
};

export default FieldInlineEditLite;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ReadViewNonEditable } from './read-view-non-editable';
