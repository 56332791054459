import { fg } from '@atlassian/jira-feature-gating';
import type { IncidentManagementField } from './types.tsx';

export const isMatch = (searchQuery: string, label?: string) => {
	const trimmedSearchQuery = searchQuery.trim();
	if (!trimmedSearchQuery || !fg('quick_actions_m2_experiment_gate')) {
		return true;
	}

	return label?.toLowerCase().includes(trimmedSearchQuery.toLowerCase());
};

export const shouldLinkAlertsItemRender = (
	incidentManagementField?: IncidentManagementField,
): boolean => {
	if (!incidentManagementField) {
		return false;
	}
	return incidentManagementField.alertLinking.enabled;
};
