import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

const InfoGlyph = (props: CustomGlyphProps) => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
	>
		<path
			d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM6 10.6667C8.57733 10.6667 10.6667 8.57733 10.6667 6C10.6667 3.42267 8.57733 1.33333 6 1.33333C3.42267 1.33333 1.33333 3.42267 1.33333 6C1.33333 8.57733 3.42267 10.6667 6 10.6667ZM6 9.33333C5.65811 9.33333 5.37633 9.07597 5.33782 8.74441L5.33333 8.66667V6C5.33333 5.63181 5.63181 5.33333 6 5.33333C6.34189 5.33333 6.62367 5.59069 6.66218 5.92225L6.66667 6V8.66667C6.66667 9.03486 6.36819 9.33333 6 9.33333Z"
			fill="currentColor"
		/>
		<path
			d="M6 2.66667C5.53976 2.66667 5.16667 3.03976 5.16667 3.5C5.16667 3.96024 5.53976 4.33333 6 4.33333C6.46024 4.33333 6.83333 3.96024 6.83333 3.5C6.83333 3.03976 6.46024 2.66667 6 2.66667Z"
			fill="currentColor"
		/>
	</svg>
);

const InfoIcon = (props: IconProps) => <Icon {...props} glyph={InfoGlyph} />;

export default InfoIcon;
