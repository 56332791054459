import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import { getUserPropertiesUrl } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getHasDismissedIdeasCrossflowCard = async (
	accountId: AccountId,
	propertyKey: string,
): Promise<boolean> => {
	const url = getUserPropertiesUrl(accountId, propertyKey);
	const response = await fetch(url, getDefaultOptions(url));

	if (response.status === 200) {
		const data = await response.json();
		return data.value;
	}

	// 401: If credential is incorrect, the user is not authorised and should not see the banner
	// 403: User doesn't have permission, so they should not see the banner
	if (response.status === 401 || response.status === 403) {
		return true;
	}

	// 404: API deliberately returns 404 if user property is not found, which implies the user has not dismissed the banner
	if (response.status === 404) {
		return false;
	}

	const errorText = await response.text();
	throw new FetchError(response.status, errorText);
};
