import findIndex from 'lodash/fp/findIndex';
import getOr from 'lodash/fp/getOr';
import merge from 'lodash/fp/merge';
import set from 'lodash/fp/set';
import has from 'lodash/has';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { getAriConfig, type Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { asyncSwitchAction } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/actions/index.tsx';
import type { TransformablePolarisPlayContribution } from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/fetch/types.tsx';
import { transformPlayContribution } from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/utils.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { PolarisPlayContributionsByIssueId } from '../../../project/types.tsx';
import { getLocalIssueIdsByJiraIssueId } from '../../selectors/issue-ids.tsx';
import type { State, Props } from '../../types.tsx';
import { refreshInsights } from '../insights/refresh/index.tsx';
import { enrichPlayContributionPayload } from '../utils/index.tsx';

type CreatePlayContributionProps = {
	amount: number;
	comment: ADF;
	play: Ari;
	subject: Ari;
};

type DeletePlayContributionProps = {
	play: Ari;
	subject: Ari;
};

type DeletePlayContributionFromRemoteProps = {
	playAri: Ari;
	subjectId: number;
	contribAri: Ari;
};

type UpdatePlayContributionProps = {
	amount?: number;
	comment?: string;
	content?: ADF;
	play: Ari;
	subject: Ari;
};

type CreatePlayContributionFromRemoteProps = {
	projectId: number;
	contribId: number;
	contribAri: Ari;
	playId: number;
	playAri: Ari;
	subjectId: number;
	updated: string;
	contribution: TransformablePolarisPlayContribution;
};

const getContributionPath = (playId: string, ideaLocalId: LocalIssueId) =>
	`properties.plays[${playId}][${ideaLocalId}]`;

export const createPlayContribution =
	(
		{ amount, comment, play, subject }: CreatePlayContributionProps,
		onSuccess?: () => void,
		onError?: (err?: Error) => void,
	) =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { onActionFailed, playContributionRemote } = props;
		try {
			const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
			const { resourceId: ideaId } = getAriConfig(subject);
			const { resourceId: playId } = getAriConfig(play);

			if (!has(localIdMap, ideaId)) {
				onSuccess?.();
				return;
			}

			const ideaLocalId = localIdMap[ideaId];

			const contribution = await playContributionRemote.createPlayContribution({
				amount,
				comment,
				play,
				subject,
			});

			const path = getContributionPath(playId, ideaLocalId);
			const remoteState = set(path, [...getOr([], path, state), contribution], state);
			setState({ ...remoteState, lastUpdatedIssueIds: [ideaLocalId] });
			dispatch(refreshInsights(ideaLocalId));
			onSuccess?.();

			return contribution;
		} catch (err) {
			const error = err instanceof Error ? err : new Error('Unknown error');

			fireErrorAnalytics({
				meta: {
					id: 'polaris.plays.action.create',
					teamName: 'JPD - Sirius',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			onActionFailed(error);
			onError?.(error);
		}
	};

export const updatePlayContribution =
	(
		contributionId: Ari,
		{ amount, comment, content, play, subject }: UpdatePlayContributionProps,
		onSuccess?: () => void,
		onError?: (err?: Error) => void,
	) =>
	async ({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { onActionFailed, playContributionRemote } = props;
		try {
			const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
			const { resourceId: ideaId } = getAriConfig(subject);
			const { resourceId: playId } = getAriConfig(play);

			if (!has(localIdMap, ideaId)) {
				onSuccess?.();
				return;
			}

			const ideaLocalId = localIdMap[ideaId];

			// Update state optimistically
			const path = getContributionPath(playId, ideaLocalId);
			const contributions: PolarisPlayContribution[] = getOr([], path, state);
			const contributionIdx = findIndex(['id', contributionId], contributions);
			const optimisticState = set(
				`${path}[${contributionIdx}]`,
				merge(getOr({}, `${path}[${contributionIdx}]`, state), {
					amount,
					comment: {
						updated: new Date().toISOString(),
						body: content,
					},
					play,
					subject,
				}),
				state,
			);
			setState(optimisticState);

			// Make a call to a service and update state with remote value
			const contribution = await playContributionRemote.updatePlayContribution(contributionId, {
				amount,
				comment,
				content,
			});
			const remoteState = set(
				`${path}[${contributionIdx}]`,
				merge(getOr({}, `${path}[${contributionIdx}]`, state), contribution),
				state,
			);
			setState(remoteState);
			onSuccess?.();

			return contribution;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof Error) {
				fireErrorAnalytics({
					meta: {
						id: 'polaris.plays.action.update',
						teamName: 'JPD - Sirius',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}

			onActionFailed(error);
			onError?.(error);
		}
	};

export const deletePlayContribution =
	(contributionId: Ari, { play, subject }: DeletePlayContributionProps) =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { onActionFailed, playContributionRemote } = props;
		try {
			const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
			const { resourceId: ideaId } = getAriConfig(subject);
			const { resourceId: playId } = getAriConfig(play);

			if (!has(localIdMap, ideaId)) {
				return;
			}

			const ideaLocalId = localIdMap[ideaId];

			// Update state optimistically
			const path = getContributionPath(playId, ideaLocalId);
			const contributions: PolarisPlayContribution[] = getOr([], path, state);
			const newContributions = contributions.filter(({ id }) => id !== contributionId);
			const optimisticState = set(path, newContributions, state);
			setState({ ...optimisticState, lastUpdatedIssueIds: [ideaLocalId] });

			// Delete contribution from polaris api
			await playContributionRemote.deletePlayContribution(contributionId);
			dispatch(refreshInsights(ideaLocalId));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof Error) {
				fireErrorAnalytics({
					meta: {
						id: 'polaris.plays.action.delete',
						teamName: 'JPD - Sirius',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}

			onActionFailed(error);
		}
	};

export const upsertPlayContributionFromRemote = asyncSwitchAction<
	State,
	Props,
	CreatePlayContributionFromRemoteProps,
	PolarisPlayContribution | undefined
>(
	async ({ playId, contribId, contribution }, _, props) => {
		const { playContributionRemote } = props;

		if (contribution) {
			return Promise.resolve(transformPlayContribution(contribution));
		}

		try {
			return await playContributionRemote.getPlayContribution(playId, contribId);
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics({
					meta: {
						id: 'polaris.plays.action.upsert-from-remote',
						teamName: 'JPD - Sirius',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
			return Promise.resolve(undefined);
		}
	},
	(contribution, { playAri, subjectId }, { getState, setState }, props) => {
		if (contribution === undefined) {
			return;
		}

		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		if (!has(localIdMap, subjectId)) {
			return;
		}

		const enrichedContribution = enrichPlayContributionPayload(
			contribution,
			state.properties.plays,
		);

		const ideaLocalId = localIdMap[subjectId];

		// Play id
		const { resourceId: playId } = getAriConfig(playAri);

		const path = getContributionPath(playId, ideaLocalId);
		const contributions = getOr([], path, state);
		const contributionIdx = findIndex(({ id }) => id === enrichedContribution.id, contributions);

		let remoteState;
		if (contributionIdx === -1) {
			remoteState = set(path, [...getOr([], path, state), enrichedContribution], state);
		} else {
			remoteState = set(
				`${path}[${contributionIdx}]`,
				merge(getOr({}, `${path}[${contributionIdx}]`, state), enrichedContribution),
				state,
			);
		}
		setState(remoteState);
	},
);

export const deletePlayContributionFromRemote =
	({ playAri, subjectId, contribAri }: DeletePlayContributionFromRemoteProps) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		if (!has(localIdMap, subjectId)) {
			return;
		}

		const ideaLocalId = localIdMap[subjectId];

		// Play id
		const { resourceId: playId } = getAriConfig(playAri);

		const path = getContributionPath(playId, ideaLocalId);
		const contributions: PolarisPlayContribution[] = getOr([], path, state);
		const newContributions = contributions.filter(({ id }) => id !== contribAri);
		const optimisticState = set(path, newContributions, state);
		setState(optimisticState);
	};

export const updatePlayContributionsFromProps =
	() =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
		const plays = props.plays ?? {};

		const localPlays: PolarisPlayContributionsByIssueId = Object.entries(plays).reduce(
			(acc, [key, value]) =>
				Object.assign(acc, {
					[key]: Object.keys(value).reduce((result, issueId) => {
						const localIssueId = localIdMap[issueId];
						if (localIssueId === undefined) {
							return result;
						}
						return Object.assign(result, {
							[localIssueId]: value[issueId],
						});
					}, {}),
				}),
			{},
		);

		setState({
			properties: {
				...getState().properties,
				plays: localPlays,
			},
		});
	};
