import { useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	type ApplicationKey,
	SOFTWARE,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PACKAGE_NAME, STORAGE_KEY, USAGE_LIMITS_EXPIRY_MS } from './common/constants.tsx';
import type { UsageLimit, UsageLimitsData } from './common/types.tsx';
import { getUsageLimitsByProductFromLocalStorage, usageLimitsStorage } from './common/utils.tsx';
import { useJswEditionAwarenessStorageLimitData } from './services/index.tsx';

export type UseDataUsageResponse = {
	data: UsageLimitsData | null;
	loading: boolean;
	error: Error | null;
};

export function getCachedDataUsage() {
	try {
		const cachedValue = usageLimitsStorage.get(STORAGE_KEY);
		if (cachedValue?.limits !== undefined) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return cachedValue as UsageLimitsData;
		}
		return undefined;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getCachedDataUsage',
				packageName: PACKAGE_NAME,
				teamName: 'growth-tako',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

export function setCachedDataUsage(response: UsageLimitsData) {
	try {
		usageLimitsStorage.set(STORAGE_KEY, response, Date.now() + USAGE_LIMITS_EXPIRY_MS);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'setCachedDataUsage',
				packageName: PACKAGE_NAME,
				teamName: 'growth-tako',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

type UseDataUsageParams = {
	skip?: boolean;
	caller: string; // The name of the component or package calling this hook
	shouldUseCache?: boolean;
	productType?: ApplicationKey;
	teamName?: string;
	maxAge?: number;
};

/**
 * Returns the storage capacity used and the max storage limit for the current site
 * @param skip - skips executing the hook returns null data
 * @param caller - the name of component or package calling the hook
 * @param productType - the product to return the storage usage for
 * @param shouldUseCache - whether to accept cached storage limits data, eliminates the need for unnecessary fetch calls
 * @param maxAge - the max age of the cache to accept, if the cache is expired it will make a fetch call and update the cache
 * @param teamName - the team that owns the component or package calling the hook, used for error analytics
 */
export const useDataUsage = ({
	skip = false,
	caller,
	productType = SOFTWARE,
	shouldUseCache = false,
	// default to one day in ms
	maxAge = 86400000,
	teamName = 'growth-tako',
}: UseDataUsageParams): UseDataUsageResponse => {
	const [data, setData] = useState<UsageLimitsData | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);

	const [hasTriggeredFetch, setHasTriggeredFetch] = useState<boolean>(false);

	const cloudId = useCloudId();
	const [storageLimitDataForProduct, { fetchStorageLimitDataForProductWithTimestamp }] =
		useJswEditionAwarenessStorageLimitData();

	// only run once initially, any updates to the memory from any global fetch calls will cause a re-run and update the data
	useEffect(() => {
		if (skip) {
			setData(null);
			setLoading(false);
			setError(null);
			return;
		}

		if (shouldUseCache) {
			// if data in memory is valid
			const memoryData = storageLimitDataForProduct?.[productType];
			if (
				memoryData?.data &&
				// timestamp hasn't expired
				(memoryData.data.fetchTimestamp ?? 0) + maxAge > Date.now()
			) {
				setData(memoryData.data);
				setLoading(memoryData.isFetching);
				setError(memoryData.error);
				return;
			}

			// else try to use local storage
			const localStorageData = getUsageLimitsByProductFromLocalStorage(teamName);
			const productLocalStorageData = localStorageData?.[productType];
			if (
				productLocalStorageData &&
				// timestamp hasn't expired
				(productLocalStorageData.fetchTimestamp ?? 0) + maxAge > Date.now()
			) {
				setData(productLocalStorageData);
				setLoading(false);
				setError(null);
				return;
			}

			// else fetch new data and update the data in memory and local storage, triggering a re-render and returning refreshed data
		}

		// only allow to fetch once
		if (!hasTriggeredFetch) {
			// refresh the memory and local storage with fresh data
			fetchStorageLimitDataForProductWithTimestamp({
				caller,
				analyticsMetaId: 'useDataUsage',
				analyticsTeamName: teamName,
				productType,
				cloudId,
			});

			setHasTriggeredFetch(true);
		}

		// return the data from memory which will be updated from the fetch call
		const memoryData = storageLimitDataForProduct[productType];

		// only update the state if it is not null to preserve initial data from cache or unititialized state
		if (memoryData?.data != null) {
			setData(memoryData.data);
		}
		if (memoryData?.isFetching != null) {
			setLoading(memoryData.isFetching);
		}
		if (memoryData?.error != null) {
			setError(memoryData.error);
		}
	}, [
		caller,
		cloudId,
		fetchStorageLimitDataForProductWithTimestamp,
		hasTriggeredFetch,
		maxAge,
		productType,
		shouldUseCache,
		skip,
		storageLimitDataForProduct,
		teamName,
	]);

	return {
		data,
		loading,
		error,
	};
};

export const getJswDataLimits = (
	data: UsageLimitsData | null,
	cloudId: string,
): UsageLimit | undefined =>
	data?.limits.find((limit) => limit.product === `ari:cloud:jira-software::site/${cloudId}`);
