/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const containerStyles = css({
	paddingTop: token('space.100', '0.5rem'),
	paddingRight: token('space.150', '0.75rem'),
	paddingBottom: token('space.100', '0.5rem'),
	paddingLeft: token('space.150', '0.75rem'),
	maxWidth: '12rem',
});

const ExportBlockedInfo = () => {
	const { formatMessage } = useIntl();
	return (
		<div css={containerStyles}>
			<Text
				color="color.text.subtle"
				size="small"
				testId="issue-view-foundation.issue-actions.issue-export-blocked-footer-group.export-blocked-info.text"
			>
				{formatMessage(messages.issueExportBlocked)}
			</Text>
		</div>
	);
};

export default ExportBlockedInfo;
