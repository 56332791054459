import { JiraIssueSearchViewAri } from '@atlassian/ari/jira/issue-search-view';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { FlagConfiguration } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import type {
	OptionResponse,
	SelectOption,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import {
	KNOWN_COLUMN_TYPES,
	MAX_COLUMNS as MAX_AMOUNT_OF_COLUMNS,
	MINIMUM_COLUMN_WIDTH_COMPACT as MINIMUM_SUMMARY_WIDTH,
} from '@atlassian/jira-native-issue-table/src/common/constants.tsx';
import type {
	ColumnConfigOverrideMap,
	SortOrder,
} from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import type { useColumnLoaderQuery$data as UseColumnLoaderQueryData } from '@atlassian/jira-relay/src/__generated__/useColumnLoaderQuery.graphql';
import type { ProjectId, IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../ui/messages.tsx';
import type { ColumnId } from '../ui/types.tsx';

export const JQLBuilder = ({
	issueKey,
	hideDone,
	sortColumn,
	sortDirection,
	filterSubtasks,
}: {
	issueKey: string;
	hideDone: string | null;
	sortColumn: string;
	sortDirection: SortOrder;
	filterSubtasks: boolean | null;
}) => {
	const hideDoneFlag = hideDone === 'true';
	const excludeDoneIssuesJQL = hideDoneFlag ? '& resolution = EMPTY' : '';
	const hierarchyLevelJQL =
		filterSubtasks === null ? '' : `AND hierarchyLevel ${filterSubtasks ? '=' : '!='} -1`;
	return `parent = ${issueKey} ${excludeDoneIssuesJQL} ${hierarchyLevelJQL} ORDER BY ${sortColumn} ${sortDirection}`;
};

export const onColumnsChangeErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.configureColumnsErrorTitle,
	description: messages.columnConfigMutationErrorDescription,
	isAutoDismiss: true,
};

export const onChildIssueLoadErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.getChildIssueErrorTitle,
	description: messages.getChildIssueErrorDescription,
	isAutoDismiss: true,
};

export const onChildIssueResizeErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.columnResizeMutationErrorTitle,
	description: messages.columnResizeMutationErrorDescription,
	isAutoDismiss: true,
};

export const createJiraIssueSearchViewAri = ({
	cloudId,
	activationId,
}: {
	cloudId: string;
	activationId: string;
}) =>
	JiraIssueSearchViewAri.create({
		siteId: cloudId,
		activationId,
		namespaceId: 'CHILD_ISSUE_PANEL',
		viewId: 'list_default',
	}).toString();

export const createConfigurableChildIssuesPanelQueryVariables = ({
	cloudId,
	jql,
	fieldSetIds,
	projectId,
	issueTypeId,
}: {
	cloudId: string;
	jql: string;
	fieldSetIds: ColumnId[];
	projectId: ProjectId | null;
	issueTypeId: IssueTypeId | null;
}) => ({
	cloudId,
	issueSearchInput: {
		jql,
	},
	amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
	namespace: 'CHILD_ISSUE_PANEL',
	options: {},
	fieldSetIds,
	shouldQueryFieldSetsById: false,
	fieldSetsInput: expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? {
				viewInput: {
					namespace: 'CHILD_ISSUE_PANEL',
					context: {
						projectContext: {
							project: projectId,
							issueType: issueTypeId,
						},
					},
				},
			}
		: null,
	filterId: undefined,
	projectId,
	issueTypeId,
	fieldSetsContext: { projectContext: { project: projectId, issueType: issueTypeId } },
});

// TODO: This will be altered based on the api connections

// export const createConfigurableChildIssuesPanelQueryVariablesNew = ({
// 	cloudId,
// 	issueKey,
// 	fieldSetIds,
// }: {
// 	cloudId: string;
// 	issueKey: string;
// 	jql: string;
// 	fieldSetIds: ColumnId[];
// 	projectId: ProjectId | null;
// 	issueTypeId: IssueTypeId | null;
// }) => ({
// 	cloudId,
// 	issueSearchInput: { childIssuesInput: { jql: 'order by rank', parentIssueKey: issueKey } },
// 	amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
// 	namespace: 'CHILD_ISSUE_PANEL',
// 	options: {},
// 	fieldSetIds,
// 	shouldQueryFieldSetsById: false,
// 	issueKey,
// 	fieldSetsInput: expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
// 		? {
// 				viewInput: {
// 					namespace: 'CHILD_ISSUE_PANEL',
// 					context: {
// 						issueContext: { issueKey },
// 					},
// 				},
// 			}
// 		: null,
// 	filterId: undefined,
// });

export const mapColumnPickerOptions = (
	data: UseColumnLoaderQueryData | undefined,
): OptionResponse => {
	const fieldSets = data?.jira?.fieldSetViewQueryByProject?.fieldSets;
	// Error
	if (!fieldSets) {
		return {
			options: [],
			totalCount: 0,
		};
	}
	const options: ReadonlyArray<SelectOption> =
		fieldSets?.edges?.map((edge) => ({
			optionType: 'option',
			cursor: edge?.cursor,
			value: edge?.node?.fieldSetId || '',
			label: edge?.node?.displayName || '',
		})) || [];
	return {
		options,
		totalCount: fieldSets?.totalCount || null,
		endCursor: fieldSets?.pageInfo.endCursor || undefined,
	};
};

export const getColumnConfigOverrides = (): ColumnConfigOverrideMap => ({
	[KNOWN_COLUMN_TYPES.ISSUE_TYPE]: () => ({ defaultWidth: 40 }),
	[KNOWN_COLUMN_TYPES.ISSUE_KEY]: () => ({ defaultWidth: 80 }),
	[KNOWN_COLUMN_TYPES.SUMMARY]: () => ({
		defaultWidth: 342,
		// TODO: Revise Minimum Custom Width in JIV-19730
		customWidth: MINIMUM_SUMMARY_WIDTH,
		hasDynamicWidth: true,
	}),
	[KNOWN_COLUMN_TYPES.STORY_POINT_ESTIMATE]: () => ({ defaultWidth: 45 }),
	[KNOWN_COLUMN_TYPES.NUMBER]: () => ({ defaultWidth: 45 }),
	[KNOWN_COLUMN_TYPES.PRIORITY]: () => ({ defaultWidth: 40 }),
	[KNOWN_COLUMN_TYPES.ASSIGNEE]: () => ({ defaultWidth: 40 }),
	[KNOWN_COLUMN_TYPES.STATUS]: () => ({ defaultWidth: 112 }),
	[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS]: () => ({ defaultWidth: 32 }),
});
