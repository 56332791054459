import React, { type FC, type PropsWithChildren, useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { HighlightActionsErrorBoundary } from '../common/ui/error-boundary/index.tsx';
import {
	HighlightActionsContainer,
	useIsHighlightEnabled,
	useTriggerPosition,
} from '../controllers/use-highlight-actions/index.tsx';
import { TargetContainer } from './popup-container/index.tsx';
import { asyncTargetEntryPoint } from './popup-target/entrypoint.tsx';

type Props = {
	scope: string;
};

const EMPTY_OBJECT = {};
export const PopupTarget: FC = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncTargetEntryPoint,
		EMPTY_OBJECT,
	);
	// Only load the entry point if the trigger position is available
	const hasTriggerPosition = Boolean(useTriggerPosition());
	const shouldLoad = fg('optimise_jira_highlight_actions_load') ? hasTriggerPosition : true;

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (shouldLoad) {
			entryPointActions.load();
		}
	}, [entryPointActions, shouldLoad]);

	return (
		<JiraEntryPointContainer
			id="jira.highlight.actions.popup.target"
			packageName="jiraHighlightActions"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<></>}
			runtimeProps={EMPTY_OBJECT}
		/>
	);
};

export const HighlightActionsTarget: FC = () => {
	const { highlightEnabled } = useIsHighlightEnabled();

	return (
		<HighlightActionsErrorBoundary id="highlight-actions-popup-target-async">
			{highlightEnabled ? <PopupTarget /> : null}
		</HighlightActionsErrorBoundary>
	);
};

export const HighlightActionsProvider: FC<PropsWithChildren<Props>> = ({ scope, children }) => (
	<HighlightActionsContainer scope={scope}>
		{children}
		<TargetContainer scope={scope}>
			<HighlightActionsTarget />
		</TargetContainer>
	</HighlightActionsContainer>
);
