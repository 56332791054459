// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addFlag: {
		id: 'issue.flag.add',
		defaultMessage: 'Add flag',
		description: 'Label for the option to add a flag (impediment) to the issue',
	},
	removeFlag: {
		id: 'issue.flag.remove',
		defaultMessage: 'Remove flag',
		description: 'Label for the option to remove the flag (impediment) from the issue',
	},
	addFlagFailedTitle: {
		id: 'issue.server-error-when-add-flag-title',
		defaultMessage: "We couldn't flag this issue",
		description:
			'Title for the error message when the there is an error adding a flag (impediment) to the issue',
	},
	addFlagFailedDescription: {
		id: 'issue.server-error-when-add-flag-description',
		defaultMessage: 'Check your connection and try adding the flag again.',
		description:
			'Description for the error message when the there is an error adding a flag (impediment) to the issue',
	},
	removeFlagFailedTitle: {
		id: 'issue.server-error-when-remove-flag-title',
		defaultMessage: "We couldn't remove the flag from this issue",
		description:
			'Title for the error message when the there is an error removing the flag (impediment) from the issue',
	},
	removeFlagFailedDescription: {
		id: 'issue.server-error-when-remove-flag-description',
		defaultMessage: 'Check your connection and try removing the flag again.',
		description:
			'Description for the error message when the there is an error removing the flag (impediment) from the issue',
	},
	addFlagFailedTitleIssueTermRefresh: {
		id: 'issue.server-error-when-add-flag-title-issue-term-refresh',
		defaultMessage: "We couldn't flag this work item",
		description:
			'Title for the error message when the there is an error adding a flag (impediment) to the issue',
	},
	removeFlagFailedTitleIssueTermRefresh: {
		id: 'issue.server-error-when-remove-flag-title-issue-term-refresh',
		defaultMessage: "We couldn't remove the flag",
		description:
			'Title for the error message when the there is an error removing the flag (impediment) from the issue',
	},
});
