import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	organizationFieldPlaceholder: {
		id: 'servicedesk-customer-service-organization-field.common.organization-edit-view-placeholder-non-final',
		defaultMessage: 'Select option',
		description: 'Placeholder for organization field select when select is in edit view.',
	},
	organizationFieldNoOptions: {
		id: 'servicedesk-customer-service-organization-field.common.organization-edit-view-no-options-non-final',
		defaultMessage: 'No options',
		description:
			'Text shown in the organization field dropdown when there are no options available.',
	},
	organizationFieldNoResults: {
		id: 'servicedesk-customer-service-organization-field.common.organization-edit-view-no-results-non-final',
		defaultMessage: 'No matches',
		description:
			'Text shown in the organization field dropdown when there are no options that match the search input.',
	},
	organizationFieldErrorState: {
		id: 'servicedesk-customer-service-organization-field.common.organization-edit-view-error-state-non-final',
		defaultMessage: 'Failed to fetch options',
		description:
			'Text shown in the organization field dropdown when there is an error retrieving the options.',
	},
});
