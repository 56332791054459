import messages from './messages.tsx';

const LEARN_MORE_TYPES = {
	IssueTypes: '#IssueTypes',
	PriorityLevels: '#PriorityLevels',
	ResolutionTypes: '#ResolutionTypes',
	SecurityLevels: '#SecurityLevels',
	TimeTracking: '#TimeTracking',
	LogWork: '#LogWork',
};

export const LEARN_MORE_MESSAGES = {
	[LEARN_MORE_TYPES.IssueTypes]: messages.fieldHelpIssueTypes,
	[LEARN_MORE_TYPES.PriorityLevels]: messages.fieldHelpPriorityLevels,
	[LEARN_MORE_TYPES.ResolutionTypes]: messages.fieldHelpResolutionDefinitions,
	[LEARN_MORE_TYPES.SecurityLevels]: messages.fieldHelpSecurityLevels,
	[LEARN_MORE_TYPES.TimeTracking]: messages.fieldHelpTimeTracking,
	[LEARN_MORE_TYPES.LogWork]: messages.fieldHelpLogWork,
};
