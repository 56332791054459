import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import {
	NEWEST_FIRST,
	type ActivitySortOrderType,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import { NUM_PAGED_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	FETCH_OLDER_SORTED_WORKLOGS_REQUEST,
	type FetchOlderSortedWorklogsRequestAction,
	fetchOlderSortedWorklogsSuccess,
	type FetchOlderSortedWorklogsSuccessAction,
	FETCH_NEWER_SORTED_WORKLOGS_REQUEST,
	type FetchNewerSortedWorklogsRequestAction,
	fetchNewerSortedWorklogsSuccess,
	type FetchNewerSortedWorklogsSuccessAction,
	fetchSortedWorklogsFailure,
	type FetchSortedWorklogsFailureAction,
} from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	sortedWorklogsSelector,
	totalWorklogsSelector,
	worklogsStartIndexSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { getSelectedActivitySortOrder } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { worklogExperienceDescription } from '../../common/experience-description/index.tsx';
import fetchMoreWorklogs from '../../services/fetch-more-worklogs-sorted/index.tsx';

export const getMaxResultsAndStartAt = (
	type: string,
	itemsStartIndex: number,
	totalItems: number,
	loadedItems: number,
	selectedSortOrder: ActivitySortOrderType,
): {
	maxResults: number;
	startAt: number;
} => {
	let maxResults = 0;
	let startFetchingFrom = 0;

	if (type === FETCH_OLDER_SORTED_WORKLOGS_REQUEST) {
		startFetchingFrom =
			selectedSortOrder === NEWEST_FIRST
				? itemsStartIndex + loadedItems
				: Math.max(itemsStartIndex - NUM_PAGED_ITEMS_TO_LOAD, 0);

		return {
			maxResults:
				selectedSortOrder === NEWEST_FIRST
					? Math.min(totalItems - startFetchingFrom, NUM_PAGED_ITEMS_TO_LOAD)
					: itemsStartIndex - startFetchingFrom,
			startAt: startFetchingFrom,
		};
	}

	startFetchingFrom =
		selectedSortOrder === NEWEST_FIRST
			? Math.max(0, itemsStartIndex - NUM_PAGED_ITEMS_TO_LOAD)
			: itemsStartIndex + loadedItems;
	maxResults =
		selectedSortOrder === NEWEST_FIRST
			? itemsStartIndex - startFetchingFrom
			: Math.min(totalItems - startFetchingFrom, NUM_PAGED_ITEMS_TO_LOAD);

	return { maxResults, startAt: startFetchingFrom };
};

export const fetchOlderSortedWorklogsEpic = (
	action$: ActionsObservable<
		FetchOlderSortedWorklogsSuccessAction | FetchSortedWorklogsFailureAction
	>,
	store: MiddlewareAPI<State>,
) =>
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	(
		action$.ofType(
			FETCH_OLDER_SORTED_WORKLOGS_REQUEST,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		) as any as Observable<FetchOlderSortedWorklogsRequestAction>
	)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
		.switchMap(() => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);
			const projectType = projectTypeSelector(state);
			const selectedSortOrder = getSelectedActivitySortOrder(state);
			const startIndex = worklogsStartIndexSelector(state);
			const totalWorklogsNum = totalWorklogsSelector(state);
			const loadedWorklogsNum = sortedWorklogsSelector(state).length;

			const { maxResults, startAt } = getMaxResultsAndStartAt(
				FETCH_OLDER_SORTED_WORKLOGS_REQUEST,
				startIndex,
				totalWorklogsNum,
				loadedWorklogsNum,
				selectedSortOrder,
			);

			return fetchMoreWorklogs(
				baseUrl,
				issueKey,
				maxResults,
				selectedSortOrder,
				startAt,
				FETCH_OLDER_SORTED_WORKLOGS_REQUEST,
				startIndex,
			)
				.map(({ worklogs, totalWorklogs, sortedWorklogIds, startIndex: newStartIndex }) => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: true,
								action: 'FETCH_MORE',
								analyticsSource: 'fetchOlderSortedWorklogsEpic',
								projectType,
							}),
					});
					return fetchOlderSortedWorklogsSuccess({
						worklogs,
						totalWorklogs,
						startIndex: newStartIndex,
						sortedWorklogIds,
						selectedSortOrder,
					});
				})
				.catch((error) => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: false,
								action: 'FETCH_MORE',
								analyticsSource: 'fetchOlderSortedWorklogsEpic',
								projectType,
								errorMessage: error.message,
								...(fg('thor_add_missing_attributes_across_issue_view_2')
									? {
											statusCode: error?.statusCode,
											traceId: error?.traceId,
										}
									: {}),
							}),
						error,
					});
					log.safeErrorWithoutCustomerData(
						'issue.activity.worklog.fetch-more-sorted-worklogs',
						'Could not fetch more sorted worklogs:',
						error,
					);
					return Observable.of(fetchSortedWorklogsFailure());
				});
		});

export const fetchNewerSortedWorklogsEpic = (
	action$: ActionsObservable<
		FetchNewerSortedWorklogsSuccessAction | FetchSortedWorklogsFailureAction
	>,
	store: MiddlewareAPI<State>,
) =>
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	(
		action$.ofType(
			FETCH_NEWER_SORTED_WORKLOGS_REQUEST,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		) as any as Observable<FetchNewerSortedWorklogsRequestAction>
	)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
		.switchMap(() => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);
			const projectType = projectTypeSelector(state);
			const selectedSortOrder = getSelectedActivitySortOrder(state);
			const startIndex = worklogsStartIndexSelector(state);
			const totalWorklogsNum = totalWorklogsSelector(state);
			const loadedWorklogsNum = sortedWorklogsSelector(state).length;

			const { maxResults, startAt } = getMaxResultsAndStartAt(
				FETCH_NEWER_SORTED_WORKLOGS_REQUEST,
				startIndex,
				totalWorklogsNum,
				loadedWorklogsNum,
				selectedSortOrder,
			);

			return fetchMoreWorklogs(
				baseUrl,
				issueKey,
				maxResults,
				selectedSortOrder,
				startAt,
				FETCH_NEWER_SORTED_WORKLOGS_REQUEST,
				startIndex,
			)
				.map(({ worklogs, totalWorklogs, sortedWorklogIds, startIndex: newStartIndex }) => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: true,
								action: 'FETCH_MORE',
								analyticsSource: 'fetchNewerSortedWorklogsEpic',
								projectType,
							}),
					});
					return fetchNewerSortedWorklogsSuccess({
						worklogs,
						totalWorklogs,
						startIndex: newStartIndex,
						sortedWorklogIds,
						selectedSortOrder,
					});
				})
				.catch((error) => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: false,
								action: 'FETCH_MORE',
								analyticsSource: 'fetchNewerSortedWorklogsEpic',
								projectType,
								errorMessage: error.message,
								...(fg('thor_add_missing_attributes_across_issue_view_2')
									? {
											statusCode: error?.statusCode,
											traceId: error?.traceId,
										}
									: {}),
							}),
						error,
					});
					log.safeErrorWithoutCustomerData(
						'issue.activity.worklog.fetch-more-sorted-worklogs',
						'Could not fetch more sorted worklogs:',
						error,
					);
					return Observable.of(fetchSortedWorklogsFailure());
				});
		});
