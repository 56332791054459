import React from 'react';
import {
	issueTypeAvatarSizesMap,
	makeAvatarUrlFromId,
	type IssueTypeAvatarSizes,
} from '../../common/utils/make-avatar-url-from-id.tsx';

type Props = {
	avatarId: string;
	size: IssueTypeAvatarSizes;
	name?: string;
};

export const IssueTypeAvatar = ({ avatarId, size, name = '' }: Props) => {
	const sizeInPx = issueTypeAvatarSizesMap[size];
	const avatarUrl = makeAvatarUrlFromId(avatarId, size);

	return <img src={avatarUrl} alt={name} width={sizeInPx} height={sizeInPx} />;
};
