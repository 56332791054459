import React, { useCallback, memo, useMemo, useEffect, type RefCallback } from 'react';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import {
	useExperienceStart,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { GOALS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { JiraPopup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { VIEW_GOAL_PICKER_EXPERIENCE } from '../common/constants/index.tsx';
import type { Goal } from '../common/types.tsx';
import { LoadingContent } from '../common/ui/loading-content/index.tsx';
import { GoalsCellContent } from './goals-cell-content/index.tsx';
import { useGoalsCellAriaLabel } from './goals-cell-content/utils.tsx';
import { goalPickerPopoverEntryPoint } from './goals-popup-content/entrypoint.tsx';
import { ERROR_FLAG_ID, POPUP_ENTRYPOINT_ERROR_FLAG } from './utils.tsx';

const POPUP_OFFSET: [number, number] = [-8, 8];

export type GoalPickerProps = {
	issueAri: string;
	goals: Goal[];
	goalCount: number;
	isEditable: boolean;
	editMode: boolean;
	onClose: () => void;
	onGoalPicked: (goal: Goal) => void;
	onGoalUnlinked: (goalUnlinkedId: string, remainingGoals: Goal[]) => void;
	shouldRenderToParent?: boolean;
	showPlaceholder?: boolean;
};

const GoalsPicker = ({
	issueAri,
	goals,
	goalCount,
	isEditable,
	editMode,
	onClose,
	onGoalPicked,
	onGoalUnlinked,
	shouldRenderToParent = false,
	showPlaceholder,
}: GoalPickerProps) => {
	const cloudId = useCloudId();
	const pressTracing = usePressTracing('platform-goal-picker');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const ariaLabel = useGoalsCellAriaLabel({ goalCount, goals, showPlaceholder });
	const startExperience = useExperienceStart(VIEW_GOAL_PICKER_EXPERIENCE);
	const { showFlag, hasFlag } = useFlagsService();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		goalPickerPopoverEntryPoint,
		{
			cloudId,
			issueAri,
		},
	);
	const buttonTrigger = useEntryPointButtonTrigger(entryPointActions);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (editMode) {
			// load the entry point when the popup is opened
			entryPointActions.load();
		}
	}, [editMode, entryPointActions]);

	// Analytics
	useEffect(() => {
		if (editMode) {
			startExperience();
			pressTracing();

			// fire analytics start
			getUpdateAnalyticsFlowHelper().fireAnalyticsStart(GOALS_CF_TYPE, {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: 'goals',
				},
			});
		}
	}, [editMode, startExperience, pressTracing, createAnalyticsEvent]);

	const handleGoalPicked = useCallback(
		(goal: Goal) => {
			// fire analytics end
			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(GOALS_CF_TYPE, {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: 'goals',
					goalId: goal.id,
					action: 'goal linked',
					// it'll take some time for goalCount to get updated, so we increment it here
					numLinkedGoals: goalCount + 1,
				},
			});
			onGoalPicked(goal);
		},
		[createAnalyticsEvent, goalCount, onGoalPicked],
	);

	const handleGoalUnlinked = useCallback(
		(goalUnlinkedId: string, remainingGoals: Goal[]) => {
			// fire analytics end
			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(GOALS_CF_TYPE, {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: 'goals',
					goalId: goalUnlinkedId,
					action: 'goal unlinked',
					numLinkedGoals: remainingGoals.length,
				},
			});
			onGoalUnlinked(goalUnlinkedId, remainingGoals);
		},
		[createAnalyticsEvent, onGoalUnlinked],
	);

	const popupTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<Box
				role="button"
				aria-label={ariaLabel}
				xcss={triggerStyles}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...triggerProps}
				ref={(element: HTMLElement) => {
					buttonTrigger(element);
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(triggerProps.ref as RefCallback<HTMLElement>)(element);
				}}
			>
				<GoalsCellContent
					showPlaceholder={showPlaceholder}
					goals={goals}
					goalCount={goalCount}
					isEditable={isEditable}
				/>
			</Box>
		),

		[ariaLabel, buttonTrigger, goalCount, goals, isEditable, showPlaceholder],
	);

	const errorFallback = useCallback(
		({ error }: { error: Error }) => {
			if (!hasFlag?.(ERROR_FLAG_ID)) {
				showFlag(POPUP_ENTRYPOINT_ERROR_FLAG);
			}

			return <ExperienceFailed experience={VIEW_GOAL_PICKER_EXPERIENCE} error={error} />;
		},
		[hasFlag, showFlag],
	);

	const runtimeProps = useMemo(
		() => ({
			issueAri,
			goalCount,
			onGoalPicked: handleGoalPicked,
			onGoalUnlinked: handleGoalUnlinked,
			onClose,
		}),
		[goalCount, handleGoalPicked, handleGoalUnlinked, issueAri, onClose],
	);

	const content = useCallback(
		({ update }: { update: () => void }) => (
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="jwm.goals.picker.edit.popup"
				packageName="jiraBusinessGoalPicker"
				teamName="jira-yurree"
				runtimeProps={{ ...runtimeProps, onContentsResized: update }}
				fallback={<LoadingContent />}
				errorFallback={errorFallback}
			/>
		),
		[entryPointReferenceSubject, errorFallback, runtimeProps],
	);

	return (
		<Flex xcss={goalPickerStyles} alignItems="center" direction="column">
			<JiraPopup
				messageType="transactional"
				messageId="business-goal-picker.ui.jira-popup"
				autoFocus={false}
				isOpen={editMode}
				content={content}
				trigger={popupTrigger}
				offset={POPUP_OFFSET}
				placement="bottom-start"
				shouldRenderToParent={shouldRenderToParent}
				onClose={onClose}
				shouldUseCaptureOnOutsideClick
			/>
		</Flex>
	);
};

export default memo(GoalsPicker);

const triggerStyles = xcss({
	display: 'flex',
	height: '100%',
	width: '100%',
	flexGrow: 1,
	flexDirection: 'column',
	alignItems: 'center',
});

const goalPickerStyles = xcss({
	minHeight: '100%',
	width: '100%',
});
