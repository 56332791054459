import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { createGetLinkedIssuesCount } from '../../../../selectors/properties/linked-issues/index.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';

const FORMULA_TEMPLATE = 'num_linked_issues';

export const linkedIssuesCountDynamicFieldResolver: DynamicFieldResolver<number> = (
	formula: DynamicFieldFormula,
) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}

	return createGetLinkedIssuesCount;
};
