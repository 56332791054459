/**
 * @generated SignedSource<<a7b80001a3afd1cda2239b0d08823ccf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly number: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberReadviewFull_NumberReadView">;
  readonly " $fragmentType": "number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef";
};
export type number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef$key = {
  readonly " $data"?: number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "number_issueFieldNumberInlineEditFull_NumberInlineEditViewOld_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "number_issueFieldNumberReadviewFull_NumberReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "number"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "30654c0fb3d781e263ba3a0f270acaf3";

export default node;
