import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import type { IconProps } from '@atlaskit/icon';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	iconForPolarisFieldType,
	iconForPolarisFieldTypeNew,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import {
	FIELD_TYPES,
	FIELD_TYPES_CATEGORIES,
	isDeliveryFieldType,
	isSystemFieldType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { isIssueTypeIdFilter } from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import type {
	Field,
	FieldKey,
	FieldMap,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { filterOutTeamFieldIfDisabled } from '@atlassian/jira-polaris-domain-field/src/global-field/utils.tsx';
import { parseAdfString } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import { isLinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/index.tsx';
import {
	isGlobalNonSystemField,
	isGlobalSystemField,
} from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import type { State } from '../types.tsx';

export const getAllFields = createSelector(
	(state: State): FieldMap => state.fields,
	(state: State) => state.containerProps?.isAtlasIntegrationEnabled,
	(fields, isAtlasIntegrationEnabled) =>
		isAtlasIntegrationEnabled
			? pickBy(fields, filterOutTeamFieldIfDisabled)
			: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(Object.keys(pickBy(fields, filterOutTeamFieldIfDisabled))
					.map((key) => fields[key])
					.filter((field) => !FIELD_TYPES_CATEGORIES.ATLAS.some((type) => type === field.type))
					.reduce(
						(result, field) =>
							Object.assign(result, {
								[field.key]: field,
							}),
						{},
					) as FieldMap),
);

export const getFields = createSelector(getAllFields, (fields) =>
	pickBy(fields, (field) => !FIELD_TYPES_CATEGORIES.ARCHIVED.some((type) => type === field.type)),
);

export const getAllFieldArray = createSelector(getAllFields, (fields) => Object.values(fields));

export const getAllVisibleFields = createSelector(getAllFields, (fields) =>
	pickBy(fields, (field) => {
		const isHidden = field.configuration?.hidden;
		const isRestricted = field.hasRestrictedContext;
		return !isHidden && !isRestricted;
	}),
);

export const getVisibleFieldArray = createSelector(getAllVisibleFields, (fields) =>
	Object.values(fields),
);

export const getGlobalFieldsInUseArray = createSelector(getAllFieldArray, (fields) =>
	fields.filter((field) => field.global && !field.hasRestrictedContext),
);

export const getFieldCount = createSelector(getAllFieldArray, (fields) => fields.length);

export const getGlobalFieldCount = createSelector(
	getAllFieldArray,
	(fields) => fields.filter((f) => f.global && f.custom).length,
);

export const getArchivedField = createSelector(getAllFieldArray, (fields) =>
	fields.find((field) => field.type === FIELD_TYPES.ARCHIVED),
);

export const getArchivedByField = createSelector(getAllFieldArray, (fields) =>
	fields.find((field) => field.type === FIELD_TYPES.ARCHIVED_BY),
);

export const getArchivedOnField = createSelector(getAllFieldArray, (fields) =>
	fields.find((field) => field.type === FIELD_TYPES.ARCHIVED_ON),
);

export const getAtlasFieldsEnabled = createSelector(
	(state: State): FieldMap => state.fields,
	(fields) =>
		Object.keys(fields)
			.map((key) => fields[key])
			.find(
				(field) =>
					field.type === FIELD_TYPES.ATLAS_PROJECT || field.type === FIELD_TYPES.ATLAS_GOAL,
			) !== undefined,
);

export const getNewlyAddedGlobalFields = (state: State) => state.newlyAddedGlobalFields;

export const getArchivedFieldsConfig = createSelector(
	getArchivedField,
	getArchivedByField,
	getArchivedOnField,
	(archivedField, archivedByField, archivedOnField) => ({
		archivedField,
		archivedByField,
		archivedOnField,
	}),
);

export const getIsArchivingEnabled = createSelector(
	getArchivedField,
	getArchivedByField,
	getArchivedOnField,
	(archivedField, archivedByField, archivedOnField) =>
		archivedField !== undefined && archivedByField !== undefined && archivedOnField !== undefined,
);

export const getFieldLabels = createSelector(getFields, (fields) =>
	mapValues(fields, (field) => field.label),
);

export const getNewFieldTypes = createSelector(getFields, (fields) =>
	mapValues(fields, (field) => field.type),
);

export const getFieldKeys = createSelector(getFields, (fieldMap) => Object.keys(fieldMap));

export const createGetFieldByKey = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) => (fieldKey ? fields[fieldKey] : undefined));

export const getFieldArray = createSelector(getFields, (fields) => Object.values(fields));

export const createGetFieldType = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) =>
		fieldKey !== undefined ? fields[fieldKey]?.type : undefined,
	);

export const createGetFieldLabel = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) =>
		fieldKey !== undefined ? fields[fieldKey]?.label : undefined,
	);

export const createIsGlobalCustomField = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) =>
		fieldKey !== undefined
			? (fields[fieldKey]?.global && fields[fieldKey]?.custom) ?? false
			: false,
	);

export const createIsGlobalSystemField = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) => {
		if (fieldKey === undefined || !fields[fieldKey]) {
			return false;
		}

		const field = fields[fieldKey];

		return isGlobalSystemField(field);
	});

export const createIsGlobalNonSystemField = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) => {
		if (fieldKey === undefined || !fields[fieldKey]) {
			return false;
		}

		const field = fields[fieldKey];

		return isGlobalNonSystemField(field);
	});

export const createGetFieldPlay = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) =>
		fieldKey !== undefined ? fields[fieldKey]?.play : undefined,
	);

export const createGetEditable = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) =>
		fieldKey !== undefined ? fields[fieldKey]?.editable : false,
	);

export const createGetFieldOfType = (ofType: FieldType) =>
	createSelector(getAllFieldArray, (fields) => fields.find((field) => field.type === ofType));

export const createGetIsDeletable = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) => {
		if (fieldKey === undefined || fields[fieldKey] === undefined) {
			return false;
		}
		return !isSystemFieldType(fields[fieldKey].type);
	});

export const createGetFieldDescription = (fieldKey: FieldKey) =>
	createSelector(getFields, (fields) => {
		const fixedDescriptionFields = [
			'summary',
			'assignee',
			'created',
			'creator',
			'key',
			'reporter',
			'status',
			'updated',
			'labels',
		];

		const value = fields[fieldKey]?.description ?? '';

		return {
			value,
			adf: parseAdfString(value),
			fixed: fixedDescriptionFields.includes(fieldKey),
		};
	});

export const createGetFieldEmoji = (fieldKey: FieldKey) =>
	createSelector(getFields, (fields) => fields[fieldKey]?.emoji);

export const createGetFieldFormula = (fieldKey: FieldKey) =>
	createSelector(getFields, (fields) => fields[fieldKey]?.formula);

export const createGetFieldPresentation = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) =>
		fieldKey !== undefined ? fields[fieldKey]?.presentation : undefined,
	);

export const createGetFieldByPlayId = (playId: Ari) =>
	createSelector(getFields, (fields) =>
		Object.keys(fields)
			.map((key) => fields[key])
			.find(({ play }) => play?.id === playId),
	);

export const createGetFieldKeysOfType = (ofType: FieldType) =>
	createSelector(getAllFieldArray, (fields) =>
		fields.filter(({ type }) => ofType === type).map(({ key }) => key),
	);

export const createHasFieldFormula = (fieldKey: FieldKey) => {
	const getFieldFormula = createGetFieldFormula(fieldKey);

	return createSelector(getFieldFormula, (fieldFormula) => !!fieldFormula);
};

export const createGetFieldConfiguration = (fieldKey: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);

	return createSelector(getFieldByKey, (field) => field?.configuration);
};

export const createGetFieldHidden = (fieldKey: FieldKey) => {
	const getFieldConfiguration = createGetFieldConfiguration(fieldKey);

	return createSelector(getFieldConfiguration, (configuration) => configuration?.hidden ?? false);
};

export const createGetFieldRestricted = (fieldKey: FieldKey) => {
	const getFieldByKey = createGetFieldByKey(fieldKey);

	return createSelector(getFieldByKey, (field) => !!field?.hasRestrictedContext);
};

export const createGetFieldOptionWeightTypeIsSet = (fieldKey: FieldKey) => {
	const getFieldConfiguration = createGetFieldConfiguration(fieldKey);

	return createSelector(
		getFieldConfiguration,
		(configuration) => configuration?.optionWeightType !== undefined,
	);
};

// Get the count of standard and votes fields
export const createGetFieldCountByType = createSelector(getAllFieldArray, (fields) => {
	const count = {
		standardCount: 0,
		votesCount: 0,
	};

	fields.forEach(({ type }) => {
		if (type === FIELD_TYPES.VOTES) {
			count.votesCount++;
		} else {
			count.standardCount++;
		}
	});

	return count;
});

export const getFieldTypeIcon = (fieldKey: FieldKey, iconProps?: IconProps) => {
	if (fg('jpd_icon_for_polaris_field_type_new')) {
		const getFieldType = createGetFieldType(fieldKey);
		const getFieldConfiguration = createGetFieldConfiguration(fieldKey);

		return createSelector(getFieldType, getFieldConfiguration, (fieldType, configuration) =>
			iconForPolarisFieldTypeNew(fieldType, iconProps, configuration),
		);
	}

	const getFieldType = createGetFieldType(fieldKey);
	const getFieldOptionWeightTypeIsSet = createGetFieldOptionWeightTypeIsSet(fieldKey);

	return createSelector(
		getFieldType,
		getFieldOptionWeightTypeIsSet,
		(fieldType, fieldOptionWeightTypeIsSet) =>
			iconForPolarisFieldType(fieldType, iconProps, fieldOptionWeightTypeIsSet),
	);
};

export const getAllEditableFields = createSelector(getAllFieldArray, (fields) =>
	fields.filter((field) => field.editable).map(({ key }) => key),
);

export const getAllStringFields = createSelector(getAllFieldArray, (fields) =>
	fields.filter((field) => field.type === FIELD_TYPES.SHORT_TEXT),
);

export const getHasFieldsError = (state: State) => state.meta.error !== undefined;

export const getHighlightedFields = (state: State) => state.highlightedFields;

const createGetConnectionFieldFilters = (fieldKey: FieldKey) => {
	const getFieldConfiguration = createGetFieldConfiguration(fieldKey);

	return createSelector(
		getFieldConfiguration,
		(configuration) => configuration?.issueTypeFilters || [],
	);
};

export const createGetConnectionFieldIssueTypeIds = (fieldKey: FieldKey) => {
	const getConnectionFieldFilters = createGetConnectionFieldFilters(fieldKey);

	return createSelector(getConnectionFieldFilters, (filters) => {
		const issueTypeIdFilter = filters.find(isIssueTypeIdFilter);

		return issueTypeIdFilter?.ids ?? [];
	});
};

const createGetConnectionFieldIssueViewLayoutConfig = (fieldKey: FieldKey) => {
	const getFieldConfiguration = createGetFieldConfiguration(fieldKey);

	return createSelector(getFieldConfiguration, (configuration) => configuration?.issueViewLayout);
};

export const createGetConnectionFieldHighlightedFieldKey = (fieldKey: FieldKey) => {
	const getConnectionFieldIssueViewLayoutConfig =
		createGetConnectionFieldIssueViewLayoutConfig(fieldKey);

	return createSelector(
		getConnectionFieldIssueViewLayoutConfig,
		(issueViewLayout) => issueViewLayout?.fields?.[0],
	);
};

export const createGetConnectionFieldIssueViewLayoutSort = (fieldKey: FieldKey) => {
	const getConnectionFieldIssueViewLayoutConfig =
		createGetConnectionFieldIssueViewLayoutConfig(fieldKey);

	return createSelector(
		getConnectionFieldIssueViewLayoutConfig,
		(issueViewLayout) => issueViewLayout?.sort?.[0],
	);
};

export const getConnectionFieldHighlightableFieldsList = createSelector(
	getVisibleFieldArray,
	(fields) => {
		const excludedFieldTypes: FieldType[] = [
			...FIELD_TYPES_CATEGORIES.ARCHIVED,
			FIELD_TYPES.DESCRIPTION,
			FIELD_TYPES.ISSUE_ID,
			FIELD_TYPES.ISSUE_TYPE,
			FIELD_TYPES.CONNECTION,
			FIELD_TYPES.SUMMARY,
		];

		return fields.filter(({ type }) => !excludedFieldTypes.includes(type));
	},
);

export const createIsConnectionFieldFilterConfigured = (fieldKey: FieldKey) =>
	createSelector(createGetConnectionFieldFilters(fieldKey), (filters) => filters.length > 0);

export const createGetAllDeliveryFieldsWithData = createSelector(getFieldArray, (fieldsArray) => {
	return fieldsArray
		.filter(
			(field): field is Field & { formula: LinkedIssuesFormula | undefined } =>
				isDeliveryFieldType(field.type) && (!field.formula || isLinkedIssuesFormula(field.formula)),
		)
		.map((field) => ({
			key: field.key,
			type: field.type,
			formula: field.formula,
			presentation: field.presentation,
		}));
});
