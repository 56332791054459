import { createSelector, createStateHook } from '@atlassian/react-sweet-state';
import type {
	FieldMap,
	FieldMapFromIframe,
} from '@atlassian/ui-modifications-core/src/common/types/field.tsx';
import { store } from '../../main.tsx';
import type { IssueAdjustmentsState } from '../../types.tsx';

const selectHiddenFields = createSelector<
	IssueAdjustmentsState,
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	void,
	string[],
	Readonly<FieldMap | null>,
	Readonly<FieldMapFromIframe | null>
>(
	(state) => state.formData,
	(state) => state.appliedChanges,
	(formData, appliedChanges) => {
		if (formData === null) {
			return [];
		}
		return Object.entries(formData)
			.filter(([fieldId, field]) =>
				appliedChanges?.[fieldId]?.isVisible !== undefined
					? !appliedChanges[fieldId].isVisible
					: !field.isVisible,
			)
			.map(([fieldId]) => fieldId);
	},
);

export const useHiddenFields = createStateHook(store, {
	selector: selectHiddenFields,
});
