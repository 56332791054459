import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	commitOnboardingGroupTitle: {
		id: 'development-summary-commit.create-commit-dropdown.commit-onboarding-group-title',
		defaultMessage: 'Link commits to Jira issues',
		description: 'The title grouping all of the information to create a commit in an external tool',
	},
	commitOnboardingGroupMessage: {
		id: 'development-summary-commit.create-commit-dropdown.commit-onboarding-group-message',
		defaultMessage:
			'Include issue keys in your commit messages to link them to your Jira issues. {linkStart}Learn more{linkEnd}',
		description: 'The messages describing how to create a commit in an external tool',
	},
	commitOnboardingGroupTitleIssueTermRefresh: {
		id: 'development-summary-commit.create-commit-dropdown.commit-onboarding-group-title-issue-term-refresh',
		defaultMessage: 'Link commits to Jira work items',
		description: 'The title grouping all of the information to create a commit in an external tool',
	},
	commitOnboardingGroupMessageIssueTermRefresh: {
		id: 'development-summary-commit.create-commit-dropdown.commit-onboarding-group-message-issue-term-refresh',
		defaultMessage:
			'Include keys in your commit messages to link them to your Jira work items. {linkStart}Learn more{linkEnd}',
		description: 'The messages describing how to create a commit in an external tool',
	},
});
