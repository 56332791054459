/**
 * @generated SignedSource<<b58ee7f35e681ee0f53355a7ee094017>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewFoundation_ConfigurationButton$data = {
  readonly configurationUrl: AGG$URL | null | undefined;
  readonly key: string;
  readonly " $fragmentType": "view_issueViewFoundation_ConfigurationButton";
};
export type view_issueViewFoundation_ConfigurationButton$key = {
  readonly " $data"?: view_issueViewFoundation_ConfigurationButton$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewFoundation_ConfigurationButton">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "view_issueViewFoundation_ConfigurationButton",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "ScalarField",
      "name": "configurationUrl"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "da19747dd8137f35fd8c678cccda78fb";

export default node;
