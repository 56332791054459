import { SET_VALUE_ARRAY_SIZE_LIMIT } from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isAriResourceIdValid } from '@atlassian/ui-modifications-public-api-utils/is-ari-resource-id-valid';
import { isNotEmptyString } from '@atlassian/ui-modifications-public-api-utils/is-not-empty-string';

export type MultiCheckboxSetPublicValue = string[];

export const validateMultiCheckboxSetValue = (
	value: unknown,
): value is MultiCheckboxSetPublicValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const validateMultiCheckboxWithAriSetValue = (
	value: unknown,
): value is MultiCheckboxSetPublicValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every((val) => isNotEmptyString(val) && isAriResourceIdValid(val));
