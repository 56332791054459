/**
 * @generated SignedSource<<7fc0502ff549a8c4e06e6b3d59e246d2>>
 * @relayHash 3f86665263bd67a53812b5dcdb4dfcce
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e6b07a8e81c1be6f46a10a4231b4e1bc71690c0bcb8ce848c3281cd3f4f0cc2d

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateTeamFieldInput = {
  id: string;
  operation: JiraTeamFieldOperationInput;
};
export type JiraTeamFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type services_issueViewLayoutTeamField_IssueViewTeamField_Mutation$variables = {
  input: JiraUpdateTeamFieldInput;
};
export type services_issueViewLayoutTeamField_IssueViewTeamField_Mutation$data = {
  readonly jira: {
    readonly updateTeamField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly issue: {
          readonly key: string;
        } | null | undefined;
        readonly selectedTeam: {
          readonly jiraSuppliedId: string;
          readonly jiraSuppliedIsVerified: boolean | null | undefined;
          readonly jiraSuppliedName: string | null | undefined;
          readonly jiraSuppliedTeamId: string;
          readonly jiraSuppliedVisibility: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type services_issueViewLayoutTeamField_IssueViewTeamField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateTeamField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly issue: {
          readonly id: string;
          readonly key: string;
        } | null | undefined;
        readonly selectedTeam: {
          readonly jiraSuppliedId: string;
          readonly jiraSuppliedIsVerified: boolean | null | undefined;
          readonly jiraSuppliedName: string | null | undefined;
          readonly jiraSuppliedTeamId: string;
          readonly jiraSuppliedVisibility: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type services_issueViewLayoutTeamField_IssueViewTeamField_Mutation = {
  rawResponse: services_issueViewLayoutTeamField_IssueViewTeamField_Mutation$rawResponse;
  response: services_issueViewLayoutTeamField_IssueViewTeamField_Mutation$data;
  variables: services_issueViewLayoutTeamField_IssueViewTeamField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "key"
},
v6 = {
  "concreteType": "JiraTeamView",
  "kind": "LinkedField",
  "name": "selectedTeam",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedId"
    },
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedName"
    },
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedVisibility"
    },
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedTeamId"
    },
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedIsVerified"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "services_issueViewLayoutTeamField_IssueViewTeamField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraTeamFieldPayload",
            "kind": "LinkedField",
            "name": "updateTeamField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraTeamViewField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "issue",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  },
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "services_issueViewLayoutTeamField_IssueViewTeamField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraTeamFieldPayload",
            "kind": "LinkedField",
            "name": "updateTeamField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraTeamViewField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "issue",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e6b07a8e81c1be6f46a10a4231b4e1bc71690c0bcb8ce848c3281cd3f4f0cc2d",
    "metadata": {},
    "name": "services_issueViewLayoutTeamField_IssueViewTeamField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "dca06ae4c83255d09cc392c66e52117b";

export default node;
