import React, { useMemo } from 'react';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { ForgeUIExtension } from '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension/index.tsx';
import type { IssueViewBackgroundScriptExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type {
	ExtensionNextProps,
	IssueViewBackgroundScript,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { IssueViewBackgroundScriptModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import { InvisibleDiv } from '../../common/ui/invisible-div/index.tsx';
import type { LazyProps, LazyRenderer } from '../../types.tsx';

type Props = ExtensionNextProps<
	IssueViewBackgroundScriptModule,
	IssueViewBackgroundScript,
	IssueViewBackgroundScriptExtensionData
>;

type PropsWithTestId = Props & { testId?: string };

const getModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-extension" */ '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension'
	);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const LazyForgeUIExtension: LazyRenderer<typeof ForgeUIExtension> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.ForgeUIExtension), {
		ssr: false,
	}),
);

export const AsyncIssueViewBackgroundScript = (props: LazyProps<PropsWithTestId>) => {
	const extensionData = useMemo(
		() => ({
			...props.extensionData,
			type: ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
		}),
		[props.extensionData],
	);

	if (fg('ui_kit_support_for_background_script_extensions')) {
		if (!isCustomUIExtension(props.extension) && !isNativeUiExtension(props.extension)) return null;
	} else if (!isCustomUIExtension(props.extension)) return null;

	return (
		<InvisibleDiv>
			<Placeholder name="issue-view-background-script" fallback={null}>
				<LazyForgeUIExtension
					{...props}
					module={ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE}
					extensionData={extensionData}
					contextToken={CONTEXT_TOKEN_TYPE_JIRA}
					hasModalConsentPrompt
					isIframeHidden
				/>
			</Placeholder>
		</InvisibleDiv>
	);
};
