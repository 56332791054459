/**
 * @generated SignedSource<<f40062a2be98e2326a59e07934b77750>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$data = {
  readonly __typename: "JiraOriginalTimeEstimateField";
  readonly fieldId: string;
  readonly issue: {
    readonly timeTrackingField: {
      readonly __typename: "JiraTimeTrackingField";
      readonly fieldId: string;
      readonly type: string;
      readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewNew_fragmentRef" | "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef">;
    } | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField";
};
export type ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$key = {
  readonly " $data"?: ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v1 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v2 = {
  "kind": "ScalarField",
  "name": "type"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraTimeTrackingField",
          "kind": "LinkedField",
          "name": "timeTrackingField",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef"
            },
            {
              "kind": "FragmentSpread",
              "name": "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewNew_fragmentRef"
            }
          ]
        }
      ]
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v0/*: any*/)
  ],
  "type": "JiraOriginalTimeEstimateField"
};
})();

(node as any).hash = "1cff3b3dc144cbc6d56335a4c8a0d558";

export default node;
