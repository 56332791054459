import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import InformationIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type HideDoneIconWithToolTipProps = {
	completedChildIssuesCount: number;
};

export const HideDoneIconWithToolTip = ({
	completedChildIssuesCount,
}: HideDoneIconWithToolTipProps) => {
	const { formatMessage } = useIntl();

	const TooltipContent = (
		<>
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.hideDoneTooltipHiddenIssuesIssueTermRefresh
					: messages.hideDoneTooltipHiddenIssues,
				{
					completedChildIssuesCount,
				},
			)}
			<br />
			{formatMessage(messages.hideDoneTooltipHowToShow)}
		</>
	);

	const oldHideDoneIcon = (
		<OldHideDoneInfoIconButton appearance="subtle">
			<InformationIcon
				color={token('color.icon.subtle', colors.N700)}
				label={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.hideDoneIconLabelIssueTermRefresh
						: messages.hideDoneIconLabel,
					{
						completedChildIssuesCount,
					},
				)}
				LEGACY_size="large"
			/>
		</OldHideDoneInfoIconButton>
	);

	const newHideDoneIcon = (
		<HideDoneInfoIconButton appearance="subtle">
			<InformationIcon
				color={token('color.icon.subtle', colors.N700)}
				label={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.hideDoneIconLabelIssueTermRefresh
						: messages.hideDoneIconLabel,
					{
						completedChildIssuesCount,
					},
				)}
				LEGACY_size="large"
			/>
		</HideDoneInfoIconButton>
	);

	const HideDoneIcon = expVal(
		'jira-nike-chin-m1a-peek-a-boo-child-issue-table',
		'isChildIssueEnabled',
		false,
	)
		? newHideDoneIcon
		: oldHideDoneIcon;

	return <Tooltip content={TooltipContent}>{HideDoneIcon}</Tooltip>;
};

// Currently using line version of the Editor Info Icon
// as global info icon only has filled and no line version.
// https://product-fabric.atlassian.net/browse/DSP-11293
// Width changes is necessary here since editor icon is smaller

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OldHideDoneInfoIconButton = styled(Button)({
	padding: '0',
	margin: '0',
	width: '30px',
	'&:hover': {
		background: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HideDoneInfoIconButton = styled(Button)({
	padding: '0',
	margin: '0',
	marginRight: token('space.075', '6px'),
	'&:hover': {
		background: 'none',
	},
});
