import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props, State } from '../../types.tsx';
import { loadSelectedFields } from '../load-selected-fields.tsx';
import { setNewlyAddedGlobalFields } from '../newly-added-global-fields/index.tsx';

/**
 * The `dependencies` array is intended for the dependencies of Global Formula fields
 */
export const associateGlobalFields =
	(fieldKeys: FieldKey[], dependencies: FieldKey[] = []): Action<State, Props> =>
	async (
		{ dispatch },
		{ projectId, issueTypeIds, createAnalyticsEvent, globalFieldRemote },
	): Promise<void> => {
		if (fg('jpd_issues_relationships')) {
			if (projectId === undefined) {
				throw new Error('cannot associate field for unknown project');
			}
			await wrapWithExperience(experience.globalFieldsAdministration.globalFieldAssociate, () =>
				globalFieldRemote.addGlobalFieldsToProject({
					fieldKeys,
					projectId,
				}),
			);
		} else {
			const issueTypeId = head(issueTypeIds);

			if (issueTypeId === undefined) {
				throw new Error('cannot associate field for unknown idea type');
			}
			if (projectId === undefined) {
				throw new Error('cannot associate field for unknown project');
			}
			await wrapWithExperience(experience.globalFieldsAdministration.globalFieldAssociate, () =>
				globalFieldRemote.addGlobalFieldsToProjectOld({
					fieldKeys,
					issueType: {
						issueTypeId,
						projectId,
					},
				}),
			);
		}

		const allNewFields: FieldKey[] = [...fieldKeys, ...dependencies];
		await dispatch(loadSelectedFields(allNewFields));
		dispatch(setNewlyAddedGlobalFields(allNewFields));

		fireTrackAnalytics(createAnalyticsEvent({}), 'field associated', {
			issueFieldKeys: fieldKeys,
		});
	};
