import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { fg } from '@atlassian/jira-feature-gating';
import parameters, {
	type singleSelect_issueSelectableFieldQuery$variables as EntryPointParams,
} from '@atlassian/jira-relay/src/__generated__/singleSelect_issueSelectableFieldQuery.graphql';

const SingleSelectEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-single-select-edit-view" */ './ui/single-select'),
	),
	getPreloadProps: ({ id, filterById }: EntryPointParams) =>
		fg('jsc_single_select_preload_on_hover')
			? {
					queries: {
						singleSelectEditViewData: {
							parameters,
							options: {
								fetchPolicy: 'store-or-network',
							},
							variables: {
								id,
								filterById,
							},
						},
					},
				}
			: {},
});

export default SingleSelectEditViewEntryPoint;
