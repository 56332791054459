/**
 * @generated SignedSource<<67afb8d2623528b80091b06f50b54c62>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type headerActions_issueViewFoundation_HeaderActionsView$data = {
  readonly votesField?: {
    readonly " $fragmentSpreads": FragmentRefs<"voters_issueViewFoundation_VotersItemNew">;
  } | null | undefined;
  readonly watchesField?: {
    readonly " $fragmentSpreads": FragmentRefs<"header_issueViewWatchers_WatchersItem">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueActions_issueViewFoundation_IssueActionsMenuWithRelay" | "issueRestriction_issueViewFoundation_IssueRestrictionWrapper" | "ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField">;
  readonly " $fragmentType": "headerActions_issueViewFoundation_HeaderActionsView";
};
export type headerActions_issueViewFoundation_HeaderActionsView$key = {
  readonly " $data"?: headerActions_issueViewFoundation_HeaderActionsView$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "ignoreMissingField",
  "value": true
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "headerActions_issueViewFoundation_HeaderActionsView",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "watchesField",
          "args": [
            {
              "kind": "Literal",
              "name": "idOrAlias",
              "value": "watches"
            },
            (v0/*: any*/)
          ],
          "kind": "LinkedField",
          "name": "fieldByIdOrAlias",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "header_issueViewWatchers_WatchersItem"
                }
              ],
              "type": "JiraWatchesField"
            }
          ],
          "storageKey": "fieldByIdOrAlias(idOrAlias:\"watches\",ignoreMissingField:true)"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "votesField",
          "args": [
            {
              "kind": "Literal",
              "name": "idOrAlias",
              "value": "votes"
            },
            (v0/*: any*/)
          ],
          "kind": "LinkedField",
          "name": "fieldByIdOrAlias",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "voters_issueViewFoundation_VotersItemNew"
                }
              ],
              "type": "JiraVotesField"
            }
          ],
          "storageKey": "fieldByIdOrAlias(idOrAlias:\"votes\",ignoreMissingField:true)"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "issueRestriction_issueViewFoundation_IssueRestrictionWrapper"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "415beb566c0ebd8ffe114293616aa337";

export default node;
