import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button/new';
import { Box, Text, xcss } from '@atlaskit/primitives';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import Link from '@atlaskit/link';
import {
	AdControlDropdownMenu,
	DismissalButton,
	ReasonsButton,
} from '@atlassian/ad-control-toolkit';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	MODAL,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';

import messages from './messages.tsx';
import { DATA_PRIVACY_LINK } from './constants.tsx';

type Props = {
	onDismiss: () => Promise<void>;
};

export const AdControls = ({ onDismiss }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<AdControlDropdownMenu
			itemId="ad-control-dropdown-ad-controls"
			trigger={({ triggerRef, ...props }) => (
				<Button
					{...props}
					testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.button"
					appearance="subtle"
					spacing="compact"
					ref={triggerRef}
				>
					<ShowMoreHorizontalIcon label="" />
				</Button>
			)}
		>
			{(props) => (
				<Box testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.box">
					<DismissalButton
						{...props}
						flagProps={{
							title: formatMessage(messages.dismissFlagTitle),
							description: formatMessage(messages.dismissFlagDescription),
						}}
						onClick={onDismiss}
						testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.dismissal-button"
					/>
					<ReasonsButton
						{...props}
						testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.reasons-button"
						modalProps={{
							title: formatMessage(messages.reasonsModalTitle),
							testId:
								'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.modal',
							body: (
								<ContextualAnalyticsData sourceName="whyAmISeeingThis" sourceType={MODAL}>
									<MountEvent
										onMount={
											(/* analyticsEvent: AnalyticsEvent */) => {
												// noop
											}
										}
									/>
									{formatMessage(messages.reasonsModalExplanation, {
										strong: (chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
									})}
									<ul>
										<li>{formatMessage(messages.reasonsModalBulletOne)}</li>
										<li>{formatMessage(messages.reasonsModalBulletTwo)}</li>
									</ul>
									<Box xcss={privacyLinkStyles}>
										<Link
											href={DATA_PRIVACY_LINK}
											target="_blank"
											onClick={() => {
												// noop
											}}
										>
											{formatMessage(messages.privacyPolicy)}
										</Link>
									</Box>
								</ContextualAnalyticsData>
							),
						}}
					/>
				</Box>
			)}
		</AdControlDropdownMenu>
	);
};

const privacyLinkStyles = xcss({
	marginTop: 'space.150',
});
