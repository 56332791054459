import React from 'react';
import ArchiveBoxIcon from '@atlaskit/icon/core/archive-box';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useArchiveIssueUrl } from '@atlassian/jira-issue-archival-archives/src/controllers/useArchiveIssueUrl.tsx';
import { useIsArchived, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	PROJECT_ACTIVE,
	PROJECT_ARCHIVED,
} from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import {
	useProjectKey,
	useProjectContext,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useUnarchiveIssueModalActions } from '@atlassian/jira-unarchive-modal/src/controllers/main.tsx';
import messages from './messages.tsx';

export const Header = ({ title }: { title: string }) => <h3>{title}</h3>;
export const MainContent = ({ message }: { message: string }) => <p>{message}</p>;

export const ArchivedProjectsLink = ({ text }: { text: string }) => (
	<p>
		<a href="/jira/settings/projects/archive">{text}</a>
	</p>
);

export const ArchivedIssuesViewLink = ({ text }: { text: string }) => {
	const archiveIssueUrl = useArchiveIssueUrl();
	return <SectionMessageAction href={archiveIssueUrl}>{text}</SectionMessageAction>;
};

const ArchiveBoxIconWrapper = () => (
	<Box xcss={iconWrapperStyles}>
		<ArchiveBoxIcon label="" color={token('color.icon.accent.blue')} spacing="spacious" />
	</Box>
);

export const ArchivedIssuesRestoreLink = ({ text }: { text: string }) => {
	const { openUnarchiveIssuesModal } = useUnarchiveIssueModalActions();
	const issueKey = useIssueKey();
	const [issueType] = useFieldValue({ issueKey, fieldKey: 'issuetype' });
	const isSubtask = issueType?.subtask;
	const [parentIssueKey] = useFieldValue({ issueKey, fieldKey: 'parent' });
	if (isSubtask && parentIssueKey) {
		return (
			<>
				<SectionMessageAction
					onClick={() => {
						openUnarchiveIssuesModal({
							selectedIssuesDetails: [
								{
									issueKey: parentIssueKey,
									issueId: issueType?.id,
									hierarchyLevel: issueType?.hierarchyLevel,
								},
							],
							onSuccessCallback: () => {
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								window.location.reload();
							},
						});
					}}
				>
					{text}
				</SectionMessageAction>
			</>
		);
	}
	return (
		<>
			<SectionMessageAction
				onClick={() => {
					openUnarchiveIssuesModal({
						selectedIssuesDetails: [
							{ issueKey, issueId: issueType?.id, hierarchyLevel: issueType?.hierarchyLevel },
						],
						onSuccessCallback: () => {
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							window.location.reload();
						},
					});
				}}
			>
				{text}
			</SectionMessageAction>
		</>
	);
};

export const ReadOnlyIssueInfo = () => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const projectContext = useProjectContext(projectKey);
	const [issueType] = useFieldValue({ issueKey, fieldKey: 'issuetype' });
	const isSubtask = issueType?.subtask;
	const isArchived = useIsArchived();
	const [permissions] = useProjectPermissions(projectKey);

	if (projectContext?.projectStatus === PROJECT_ACTIVE && isArchived) {
		const restoreMessage = isSubtask
			? formatMessage(messages.readOnlyArchivedSubtaskRestoreLink)
			: formatMessage(messages.readOnlyArchivedIssueRestoreLink);

		const mainMessage = isSubtask
			? formatMessage(messages.readOnlyArchivedSubtaskMessageAdminUser)
			: formatMessage(messages.readOnlyArchivedIssueMessageAdminUser);

		if (permissions.canUnarchiveIssue) {
			return (
				<SectionMessage
					appearance="information"
					icon={ArchiveBoxIconWrapper}
					actions={[
						<ArchivedIssuesRestoreLink key="restore-archived-issue-button" text={restoreMessage} />,

						<ArchivedIssuesViewLink
							key="achived-issues-view-link"
							text={formatMessage(messages.readOnlyArchivedIssueLink)}
						/>,
					]}
				>
					<MainContent message={mainMessage} />
				</SectionMessage>
			);
		}

		return (
			<SectionMessage appearance="information" icon={ArchiveBoxIconWrapper}>
				<Text>
					{`${formatMessage(messages.readOnlyArchivedIssueMessageNonAdminUser)} `}
					<SectionMessageAction href="https://support.atlassian.com/jira-software-cloud/docs/archive-an-issue">
						{formatMessage(messages.archiveIssueSupportMessage)}
					</SectionMessageAction>
				</Text>
			</SectionMessage>
		);
	}

	if (projectContext?.projectStatus !== PROJECT_ARCHIVED) {
		return null;
	}

	const isServiceDeskProject = projectType === SERVICE_DESK_PROJECT;
	const message = isServiceDeskProject
		? formatMessage(messages.jsmReadOnlyIssueMessage)
		: formatMessage(messages.readOnlyIssueMessage);

	return (
		<SectionMessage appearance="information" icon={ArchiveBoxIconWrapper}>
			<Header title={formatMessage(messages.readOnlyIssueTitle)} />
			<MainContent message={message} />
			{permissions.canUnarchiveIssue ? (
				<ArchivedProjectsLink text={formatMessage(messages.readOnlyIssueLink)} />
			) : null}
		</SectionMessage>
	);
};

const iconWrapperStyles = xcss({
	width: '24px',
});
