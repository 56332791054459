import {
	createStore,
	createContainer,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';

export type State = {
	addToSprintCTAsEnabled: boolean;
};

const initialState = {
	addToSprintCTAsEnabled: true,
} as const;

const actions = {
	disableOtherAddToSprintCTAs:
		(): Action<State> =>
		({ setState }) => {
			setState({
				addToSprintCTAsEnabled: false,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'SuccessFlagAddToSprintActionStateController',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AddToSprintActionStatesContainer = createContainer<State, Actions, Record<any, any>>(
	Store,
);

export const useAddToSprintFlagActionStateController = createHook(Store);
