/**
 * @generated SignedSource<<3c9fa4cc29432746d5c47ff473bcc9f9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef$data = {
  readonly dateTime: AGG$DateTime | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView">;
  readonly " $fragmentType": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef";
};
export type dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef$key = {
  readonly " $data"?: dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "dateTime"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "524086c2c1d813c84e1e36bbc0676a40";

export default node;
