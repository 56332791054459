import type { PlaybooksPanelBodyQuery$variables } from '@atlassian/jira-relay/src/__generated__/PlaybooksPanelBodyQuery.graphql';
import PlaybooksPanelBodyQuery$parameters from '@atlassian/jira-relay/src/__generated__/PlaybooksPanelBodyQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const playbooksPanelBodyEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-playbooks-panel-body" */ './PlaybooksPanelBody').then(
			(module) => module.PlaybooksPanelBody,
		),
	),
	getPreloadProps: (variables: PlaybooksPanelBodyQuery$variables) => ({
		queries: {
			PlaybooksPanelBodyQuery: {
				parameters: PlaybooksPanelBodyQuery$parameters,
				variables,
			},
		},
	}),
});
