import {
	VERSION_RELATED_LINKS_PROVIDER_ID_PROD,
	VERSION_RELATED_LINKS_PROVIDER_ID_STG,
	DEVINFO_DIALOG_PR_SUGGESTIONS_TASK_TYPES,
} from '../../model/constants.tsx';

export const isVersionRelatedLinksProvider = (id: string): boolean =>
	id === VERSION_RELATED_LINKS_PROVIDER_ID_PROD || id === VERSION_RELATED_LINKS_PROVIDER_ID_STG;

export const randomizeDevinfoDetailsDialogPrSuggestions = () =>
	Math.random() < 0.5
		? DEVINFO_DIALOG_PR_SUGGESTIONS_TASK_TYPES.PULL_REQUEST_REVIEW
		: DEVINFO_DIALOG_PR_SUGGESTIONS_TASK_TYPES.PR_MERGEABLE;
