/**
 * @generated SignedSource<<63b14e199759744cdf583eb0c109846a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly uri: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlReadviewFull_UrlReadView">;
  readonly " $fragmentType": "url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef";
};
export type url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef$key = {
  readonly " $data"?: url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "uri"
    },
    {
      "kind": "FragmentSpread",
      "name": "url_issueFieldUrlReadviewFull_UrlReadView"
    }
  ],
  "type": "JiraUrlField"
};

(node as any).hash = "f560c76a2ba024cc2bd7b741c47d14d9";

export default node;
