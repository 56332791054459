import React, { useState, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import countBy from 'lodash/countBy';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import LockFilledIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';
import UnlockIcon from '@atlaskit/icon/core/migration/lock-unlocked--unlock';
import { PopupSelect, components } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	type CommentVisibility,
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	COMMENT_VISIBILITY_TYPE_GROUP,
	COMMENT_VISIBILITY_TYPE_ROLE,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import {
	AnalyticsEventToProps,
	fireUIAnalytics,
	fireOperationalAnalytics,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import TargetButton, { buttonMaxWidth } from './button/index.tsx';
import ErrorImage from './error-image/index.tsx';
import messages from './messages.tsx';
import {
	transformOption,
	getEmptyOptionsWithInitialValue,
	getDefaultOptionGroups,
} from './options/utils.tsx';
import { LoadingSpinner } from './spinner/index.tsx';
import type { Option, Props, MenuListProps } from './types.tsx';

const OPTION_FILTER_THRESHOLD = 30;
const OPTION_TOOLTIP_DELAY = 2000;
const actionSubjectId = 'commentVisibilityView';

const formatGroupLabel = ({ label }: { label: string }) =>
	label && <GroupLabel>{label}</GroupLabel>;

// @ts-expect-error - TS7031 - Binding element 'selected' implicitly has an 'any' type. | TS7031 - Binding element 'isFocused' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customOptionStyles = (baseStyles: any, { data: { selected }, isFocused }) => {
	const maybeFocusedColor = isFocused
		? token('color.background.neutral.hovered', colors.N20)
		: baseStyles.backgroundColor;
	const backgroundColor = selected
		? token('color.background.selected', colors.B50)
		: maybeFocusedColor;

	return {
		...baseStyles,
		backgroundColor,
	};
};

const styles = {
	// @ts-expect-error - TS7006 - Parameter 'baseStyles' implicitly has an 'any' type.
	container: (baseStyles) => ({
		...baseStyles,
		paddingBottom: '0',
		paddingTop: '0',
	}),
	// @ts-expect-error - TS7006 - Parameter 'baseStyles' implicitly has an 'any' type.
	group: (baseStyles) => ({
		...baseStyles,
		paddingBottom: token('space.050', '4px'),
		paddingTop: '0',
	}),
	option: customOptionStyles,
} as const;

const popperProps = () => ({
	placement: 'bottom-end',
	modifiers: [
		{
			name: 'offset',
			options: {
				offset: [0, gridSize],
			},
		},
		{
			// Update the z-index of the portaled popper (menu) component so menu is visible when viewing issue in dialog.
			// The same thing could be achieved with setting positionFixed to true but that caused us to loose the ability
			// to interact with the menu using keyboard (see BENTO-5561).
			name: 'setZIndex',
			enabled: true,
			phase: 'read',
			// @ts-expect-error - TS7031 - Binding element 'state' implicitly has an 'any' type.
			fn: ({ state }) => ({
				...state,
				styles: {
					...state.styles,
					popper: {
						...state.styles?.popper,
						zIndex: layers.modal,
					},
				},
			}),
		},
		{
			// Ensure menu is not detached from the trigger (button) and remain in view when trigger is no longer in view (see BENTO-5551).
			name: 'preventOverflow',
			options: {
				padding: 5,
				escapeWithReference: true,
			},
		},
	],
});

const PopupSelectWithAnalytics = AnalyticsEventToProps('commentVisibilitySelect', {
	onOpen: 'opened',
	onChange: 'changed',
})(PopupSelect);

export const OptionLabel = ({
	value,
	selected,
	isPublic,
	toolTipDelay = OPTION_TOOLTIP_DELAY,
}: {
	value: string;
	selected: boolean;
	isPublic: boolean;
	// exposed for vr testing
	toolTipDelay?: number;
}) => {
	const Icon = isPublic ? UnlockIcon : LockFilledIcon;
	return (
		<Tooltip content={value} delay={toolTipDelay}>
			<OptionContainer data-testid="issue-comment-base.ui.comment.comment-visibility.option-label">
				<IconContainer>
					<Icon color={token('color.text')} label="" />
				</IconContainer>
				<OptionText>{value}</OptionText>
				<CheckedContainer>
					{selected ? (
						<CheckIcon color={token('color.text.selected', colors.B500)} label="" />
					) : null}
				</CheckedContainer>
			</OptionContainer>
		</Tooltip>
	);
};

export const CommentVisibilityView = ({
	isLoading,
	options,
	getCommentVisibilityData,
	initialValue,
	onChange,
	error,
}: Props) => {
	const { formatMessage } = useIntl();
	const isErrorOrLoading = isLoading || error;
	const [selectedOption, setValue] = useState(initialValue);
	const onChangeAction = (option: CommentVisibility) => {
		onChange(option);
		setValue(option);
	};
	const [isOpen, setOpen] = useState(false);
	const onOpen = useCallback(() => setOpen(true), []);
	const onClose = useCallback(() => setOpen(false), []);

	useEffect(() => {
		if (ff('lower-comment-visibility-list-fetch')) {
			if (options === null && !isErrorOrLoading) {
				getCommentVisibilityData();
			}
		}
	}, [getCommentVisibilityData, options, isErrorOrLoading]);

	const isInitialOptionPublic = initialValue.type === COMMENT_VISIBILITY_TYPE_PUBLIC;
	if ((isErrorOrLoading || isEmpty(options)) && isInitialOptionPublic) {
		return null;
	}

	const optionGroups =
		isErrorOrLoading || options == null
			? getEmptyOptionsWithInitialValue(initialValue, selectedOption, formatMessage)
			: getDefaultOptionGroups(options, formatMessage, selectedOption);

	// Unstable components cause bugs and performance problems - please fix this! go/unstable-components
	// eslint-disable-next-line react/no-unstable-nested-components
	const MenuList = (props: MenuListProps) => (
		<>
			{/* @ts-expect-error - TS2740 - Type '{ children: ReactNode; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more. */}
			<components.MenuList {...props}>{props.children}</components.MenuList>
			<StyledFooter>{error ? <ErrorImage /> : <LoadingSpinner />}</StyledFooter>
		</>
	);
	const componentsList = isErrorOrLoading ? { MenuList } : undefined;

	return (
		<>
			<MountEvent
				onMount={(analyticsEvent: UIAnalyticsEvent) =>
					fireOperationalAnalytics(analyticsEvent, 'commentVisibilitySelect rendered')
				}
			/>
			<CommentVisibilityWrapper data-testid="issue-comment-base.ui.comment.comment-visibility.comment-visibility-wrapper">
				<PopupSelectWithAnalytics
					onClose={onClose}
					onOpen={(analyticsEvent: UIAnalyticsEvent) => {
						onOpen();
						const optionTypeCounts = countBy(options, 'type');
						fireUIAnalytics(analyticsEvent, actionSubjectId, {
							roleCount: optionTypeCounts[COMMENT_VISIBILITY_TYPE_ROLE] || 0,
							groupCount: optionTypeCounts[COMMENT_VISIBILITY_TYPE_GROUP] || 0,
							// the +1 is for the public option
							totalCount: options == null ? 1 : options.length + 1,
						});
					}}
					maxMenuWidth={buttonMaxWidth}
					minMenuWidth={175}
					maxMenuHeight={340}
					formatOptionLabel={({ type, value, selected }: Option) => {
						const isPublic = type === COMMENT_VISIBILITY_TYPE_PUBLIC;
						return (
							<OptionLabel
								isPublic={isPublic}
								selected={selected}
								value={isPublic ? formatMessage(messages.visibleToAllUsers) : value}
							/>
						);
					}}
					popperProps={popperProps()}
					components={componentsList}
					styles={styles}
					formatGroupLabel={formatGroupLabel}
					options={optionGroups}
					// @ts-expect-error - TS7031 - Binding element 'ref' implicitly has an 'any' type.
					target={({ ref }) => (
						<div ref={ref}>
							<TargetButton selectedOption={selectedOption} isSelected={isOpen} />
						</div>
					)}
					isLoading={isLoading}
					error={error}
					// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
					onChange={(opt: Option, _, analyticsEvent: UIAnalyticsEvent) => {
						onChangeAction(transformOption(opt));
						fireUIAnalytics(analyticsEvent, actionSubjectId);
					}}
					isSearchable={
						// The +1 is for the public option
						options == null ? false : options.length + 1 > OPTION_FILTER_THRESHOLD
					}
					searchThreshold={OPTION_FILTER_THRESHOLD}
					classNamePrefix="comment-visibility"
				/>
			</CommentVisibilityWrapper>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContainer = styled.div({
	display: 'flex',
	justifyContent: 'left',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.span({
	padding: '0 5px 0 0',
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 3}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckedContainer = styled.span({
	marginLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 3}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupLabel = styled.div({
	font: token('font.body.small'),
	paddingTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionText = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledFooter = styled.div({
	display: 'flex',
	justifyContent: 'center',
	paddingTop: token('space.200', '16px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.200', '16px'),
	paddingLeft: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentVisibilityWrapper = styled.div({
	/* to make buttons on small screen look better */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 4.5}px`,
	marginLeft: token('space.050', '4px'),
});
