/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useScrollCarousel } from '../../../../controllers/use-scroll-carousel/index.tsx';
import messages from '../../../messages.tsx';

export type Props = {
	renderCannedCommentOptions: (
		onFocusCannedComment: (event: React.FocusEvent<HTMLButtonElement, Element>) => void,
	) => React.ReactNode;
};

export const CannedCommentList = ({ renderCannedCommentOptions }: Props) => {
	const {
		setRef,
		setCanScrollDebounced,
		shouldHideScrollLeftButton,
		shouldHideScrollRightButton,
		scrollToNext,
		scrollToPrevious,
		onFocusCannedComment,
	} = useScrollCarousel();
	const { formatMessage } = useIntl();

	return (
		<Box xcss={containerStyles}>
			<Box
				xcss={[hoverButtonStyle, leftButtonStyle, shouldHideScrollLeftButton && hiddenButtonStyle]}
			>
				<IconButton
					shape="circle"
					appearance="subtle"
					icon={ChevronLeftIcon}
					isTooltipDisabled
					label={formatMessage(messages.moreOptions)}
					onClick={scrollToPrevious}
					tabIndex={-1}
				/>
			</Box>
			<div
				ref={setRef}
				css={commentsContainerStyle}
				onScroll={setCanScrollDebounced}
				role="group"
				aria-label={formatMessage(messages.buttonGroupLabel)}
			>
				{renderCannedCommentOptions(onFocusCannedComment)}
			</div>
			<Box
				xcss={[
					hoverButtonStyle,
					rightButtonStyle,
					shouldHideScrollRightButton && hiddenButtonStyle,
				]}
			>
				<IconButton
					shape="circle"
					appearance="subtle"
					icon={ChevronRightIcon}
					isTooltipDisabled
					label={formatMessage(messages.moreOptions)}
					onClick={scrollToNext}
					tabIndex={-1}
				/>
			</Box>
		</Box>
	);
};

const containerStyles = xcss({
	display: 'flex',
	position: 'absolute',
	bottom: token('space.150'),
	left: token('space.150'),
	right: token('space.150'),
	alignItems: 'center',
});

const commentsContainerStyle = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: token('space.100'),
	// Sufficient padding needed for outline styles on focus to not be hidden by overflow: hidden
	paddingTop: token('space.075'),
	paddingRight: token('space.075'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.075'),

	// this needs to be 'scroll' rather than 'none' due to a Chrome bug - https://hello.jira.atlassian.cloud/browse/WAR-1542
	overflowX: 'scroll',
	// visually hide the scrollbar rather than disable with overflow: none
	scrollbarWidth: 'none',
	'-ms-overflow-style': 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	'::-webkit-scrollbar': {
		display: 'none',
	},
});

const hoverButtonStyle = xcss({
	zIndex: 'layer',
	alignItems: 'center',
	position: 'absolute',
	// NOTE: before changing this.
	// see jira/src/packages/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx:71
	background: `var(--jira-canned-comments-background-color, ${token('color.background.input')})`,
});

const rightButtonStyle = xcss({
	right: 'space.0',
});

const leftButtonStyle = xcss({
	left: 'space.0',
});

const hiddenButtonStyle = xcss({
	display: 'none',
});
