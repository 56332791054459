import { useCallback } from 'react';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagsService, type FlagMessage } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { getErrorMessage } from './utils.tsx';

export const useFieldFlagsOld = () => {
	const { showFlag } = useFlagsService();
	const onSuccess = useCallback(
		(title: FlagMessage, description?: FlagMessage) => {
			showFlag({
				type: 'success',
				title,
				description,
			});
		},
		[showFlag],
	);
	const onFailure = useCallback(
		(title: FlagMessage, description?: FlagMessage) => {
			showFlag({
				type: 'error',
				title,
				description,
			});
		},
		[showFlag],
	);
	return { onSuccess, onFailure };
};

export const useFieldFlags = () => {
	const { showFlag } = useFlagsService();
	const onSuccess = useCallback(
		({ title, description }: { title: FlagMessage; description?: FlagMessage }) => {
			showFlag({
				type: 'success',
				title,
				description,
			});
		},
		[showFlag],
	);
	const onFailure = useCallback(
		({
			error,
			title,
			description,
		}: {
			error: Error | ValidationError;
			title: FlagMessage;
			description?: FlagMessage;
		}) => {
			if (error instanceof ValidationError) {
				const errorMessage = getErrorMessage(error, description);
				showFlag({
					type: 'warning',
					title,
					description: errorMessage,
				});
			} else {
				showFlag({
					type: 'error',
					title,
					description,
				});
			}
		},
		[showFlag],
	);
	return { onSuccess, onFailure };
};
