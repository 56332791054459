import { createSelector } from 'reselect';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import {
	ISSUETYPE_FIELDKEY,
	KEY_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { Props, State } from '../types.tsx';
import { isMatchingConnectionFieldFilter } from '../utils/connection-field-filters.tsx';
import { fieldMapping } from '../utils/field-mapping/index.tsx';
import { createGetField, getAllFieldsByKey, getFields } from './fields.tsx';
import { getIssueIdsConsideringArchived } from './filters.tsx';
import {
	getJiraIdToLocalIssueId,
	getLocalIssueIdsByJiraIssueId,
	getIssuesWithBasicPropertiesMap,
} from './issue-ids.tsx';
import {
	createGetIssueType,
	getConnectionProperties,
	getIssueTypeProperties,
	getSelectedIssueLocalIssueId,
	getStringProperties,
} from './properties/index.tsx';
import {
	createCombinedComparator,
	createIdeaComparatorForFieldComparator,
	type IdeaComparator,
} from './sort.tsx';
import { getExternalIssues } from './properties/linked-issues/index.tsx';

export const createGetAllIssueIdsMatchingConnectionFieldFilters = (
	localIssueId: LocalIssueId,
	fieldKey: FieldKey,
) =>
	createSelector(
		getIssueIdsConsideringArchived,
		createGetField(fieldKey),
		(state, props) => ({ state, props }),
		(issueIds, connectionField, { state, props }) =>
			issueIds.filter(
				(issueId) =>
					issueId !== localIssueId &&
					isMatchingConnectionFieldFilter({
						filters: connectionField?.configuration?.issueTypeFilters || [],
						issueType: createGetIssueType(issueId)(state, props)?.id,
						issueTypeNameFilter: props?.getIssueTypeNameFilter,
					}),
			),
	);

export const createIsIssueMatchingConnectionFieldFilter = (
	localIssueId: LocalIssueId = '',
	fieldKey: FieldKey,
) =>
	createSelector(
		createGetField(fieldKey),
		(state: State, props?: Props) => ({ state, props }),
		(connectionField, { state, props }) =>
			isMatchingConnectionFieldFilter({
				filters: connectionField?.configuration?.issueTypeFilters || [],
				issueType: createGetIssueType(localIssueId)(state, props)?.id,
				issueTypeNameFilter: props?.getIssueTypeNameFilter,
			}),
	);

const filterArchivedConnectionFieldIssueIds = (
	connections: ConnectionFieldValue[] = [],
	issuesWithBasicPropertiesMap: ReturnType<typeof getIssuesWithBasicPropertiesMap>,
	jiraIdToLocalIssueId: Record<number, string>,
) =>
	connections.filter(({ id }) => {
		const localId = jiraIdToLocalIssueId[parseInt(id, 10)];
		return (
			issuesWithBasicPropertiesMap[localId] && !issuesWithBasicPropertiesMap[localId]?.isArchived
		);
	});

export const createGetConnectionFieldIssueIds = (localIssueId: LocalIssueId, fieldKey: FieldKey) =>
	createSelector(
		getConnectionProperties,
		getIssuesWithBasicPropertiesMap,
		getJiraIdToLocalIssueId,
		(connectionProperties, issuesWithBasicPropertiesMap, jiraIdToLocalIssueId) =>
			filterArchivedConnectionFieldIssueIds(
				connectionProperties[fieldKey]?.[localIssueId],
				issuesWithBasicPropertiesMap,
				jiraIdToLocalIssueId,
			),
	);

const createGetSelectedIssueConnectionFieldIssueIds = (connectionFieldKey: FieldKey) =>
	createSelector(
		getSelectedIssueLocalIssueId,
		getConnectionProperties,
		getIssuesWithBasicPropertiesMap,
		getJiraIdToLocalIssueId,
		(localIssueId, connectionProperties, issuesWithBasicPropertiesMap, jiraIdToLocalIssueId) => {
			if (!localIssueId) {
				return [];
			}

			return filterArchivedConnectionFieldIssueIds(
				connectionProperties[connectionFieldKey]?.[localIssueId],
				issuesWithBasicPropertiesMap,
				jiraIdToLocalIssueId,
			);
		},
	);

export const createGetSelectedIssueConnectionFieldSortedIssueIds = (connectionFieldKey: FieldKey) =>
	createSelector(
		createGetSelectedIssueConnectionFieldIssueIds(connectionFieldKey),
		createGetField(connectionFieldKey),
		getAllFieldsByKey,
		getFields,
		getJiraIdToLocalIssueId,
		({ containerProps }) => containerProps?.issuesRemote,
		(state, props) => ({ state, props }),
		(
			ids,
			connectionField,
			fieldsByKey,
			fields,
			jiraIdToLocalIssueId,
			issuesRemote,
			{ state, props },
		) => {
			if (!connectionField || !issuesRemote) {
				return [];
			}

			const sortBy = connectionField.configuration?.issueViewLayout?.sort || [];

			const configuredComparators = sortBy.reduce<Array<IdeaComparator>>((comparators, sort) => {
				const mapping = fieldMapping(issuesRemote, fields, fieldsByKey[sort.fieldKey]);

				const comparator = createIdeaComparatorForFieldComparator(
					state,
					props,
					mapping.valueAccessor,
					mapping.comparator,
					mapping.comparatorWithMapping,
					sort.order === 'ASC',
				);

				comparators.push(comparator);

				return comparators;
			}, []);

			const combinedComparator = createCombinedComparator(configuredComparators);

			const idsCopy = [...ids];

			idsCopy.sort((a, b) =>
				combinedComparator(
					jiraIdToLocalIssueId[parseInt(a.id, 10)],
					jiraIdToLocalIssueId[parseInt(b.id, 10)],
				),
			);

			return idsCopy;
		},
	);

export const createGetSelectedIssuesConnectionFieldIssuesCount = (fieldKey: FieldKey) =>
	createSelector(createGetSelectedIssueConnectionFieldIssueIds(fieldKey), (ids) => ids.length);

export const getSelectedIssueConfiguredConnectionFields = createSelector(
	getSelectedIssueLocalIssueId,
	getFields,
	(state, props) => ({ state, props }),
	(localIssueId, fields, { state, props }) =>
		fields.reduce<Field[]>((res, field) => {
			if (
				field.type === FIELD_TYPES.CONNECTION &&
				field.configuration?.issueTypeFilters &&
				field.configuration.issueTypeFilters.length > 0 &&
				!isMatchingConnectionFieldFilter({
					filters: field.configuration.issueTypeFilters,
					issueType: createGetIssueType(localIssueId)(state, props)?.id,
					issueTypeNameFilter: props?.getIssueTypeNameFilter,
				})
			) {
				res.push(field);
			}
			return res;
		}, []),
);

export const getSelectedIssueConfiguredConnectionFieldsCount = createSelector(
	getSelectedIssueConfiguredConnectionFields,
	(fields) => fields.length,
);

export const createGetConnectedIssuesData = (connections: ConnectionFieldValue[]) =>
	createSelector(
		getLocalIssueIdsByJiraIssueId,
		getExternalIssues,
		getStringProperties,
		getIssueTypeProperties,
		(jiraIdToLocalIssueId, externalIssues, stringProperties, issueTypeProperties) =>
			connections
				.map(({ id }) => {
					const localIssueId = jiraIdToLocalIssueId[id];

					if (localIssueId) {
						return {
							id,
							summary: stringProperties[SUMMARY_FIELDKEY][localIssueId],
							issueKey: stringProperties[KEY_FIELDKEY][localIssueId],
							issueType: issueTypeProperties[ISSUETYPE_FIELDKEY][localIssueId],
						};
					}

					const externalIssue = externalIssues.find(({ issueId }) => issueId === parseInt(id, 10));

					if (externalIssue) {
						return {
							id,
							summary: externalIssue.summary,
							issueKey: externalIssue.issueKey,
							issueType: externalIssue.issueType,
						};
					}

					return null;
				})
				.filter(Boolean),
	);
