import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { CollapsibleGroupWithHeaderIcon } from '@atlassian/jira-issue-view-layout-group/src/ui/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ContextPanelGroupProps } from './types.tsx';

export const ContextPanelGroup = ({
	onChange,
	children,
	initialOpened,
	additionalEventAttributes,
	title,
	icon,
	status,
	subtitle,
	groupId,
	extension,
}: ContextPanelGroupProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);

	const isVisualRefreshDrop4Enabled = isVisualRefreshEnabled() && fg('visual-refresh_drop_4');

	return (
		<ContextGroupContainer
			data-testid={`issue-view-layout-templates-views.ui.context.visible-hidden.ui.ecosystem-context-group.container.${groupId}`}
		>
			<CollapsibleGroupWithHeaderIcon
				title={
					(extension && (
						<ExtensionTitle
							extensionName={title}
							extensionEnvType={extension.environmentType}
							extensionEnvKey={extension.environmentKey}
						/>
					)) ||
					title
				}
				id={groupId}
				projectKey={projectKey}
				onChange={onChange}
				initialOpened={initialOpened}
				additionalEventAttributes={additionalEventAttributes}
				icon={icon}
				subtitle={subtitle}
				status={status}
				extension={extension}
				enableVisualRefreshForTitle={isVisualRefreshDrop4Enabled}
			>
				<div
					data-testid={`issue-view-layout-templates-views.ui.context.visible-hidden.ui.ecosystem-context-group.${groupId}`}
				>
					{children}
				</div>
			</CollapsibleGroupWithHeaderIcon>
		</ContextGroupContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContextGroupContainer = styled.div({
	marginBottom: token('space.100', '8px'),
});
