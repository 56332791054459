import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getFieldMappings } from '../../selectors/fields.tsx';
import type { State, Props } from '../../types.tsx';
import type { AdditionalFields } from './types.tsx';

export const exportStringValues =
	(visibleFields: Field[], issueIds: string[], additionalFields: AdditionalFields) =>
	({ getState }: StoreActionApi<State>, props: Props) => {
		const { locale, statusCategories, messages, formatMessage } = additionalFields;

		const state = getState();
		const mappings = getFieldMappings(state, props);

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return issueIds.map((id) =>
			visibleFields.map(({ type, key: fieldKey, options }) => {
				let additionalParamsToConversion;
				let isFieldSupported = true;

				switch (type) {
					case FIELD_TYPES.SINGLE_SELECT:
					case FIELD_TYPES.MULTI_SELECT:
					case FIELD_TYPES.JSW_MULTI_SELECT:
						additionalParamsToConversion = options;
						break;
					case FIELD_TYPES.DATE:
					case FIELD_TYPES.UPDATED:
					case FIELD_TYPES.CREATED:
					case FIELD_TYPES.INTERVAL:
						additionalParamsToConversion = locale;
						break;
					case FIELD_TYPES.DELIVERY_PROGRESS:
					case FIELD_TYPES.DELIVERY_STATUS:
						additionalParamsToConversion = {
							statusCategories,
							formatMessage,
							messages,
						};
						break;
					case FIELD_TYPES.REACTIONS:
					case FIELD_TYPES.ATLAS_PROJECT:
					case FIELD_TYPES.ATLAS_PROJECT_STATUS:
					case FIELD_TYPES.ATLAS_GOAL:
					case FIELD_TYPES.PLATFORM_GOALS:
						isFieldSupported = false;
						break;
					default:
						break;
				}

				return isFieldSupported
					? `"${mappings[fieldKey].valueAccessorToExport(
							state,
							props,
							id,
							additionalParamsToConversion,
						)}"`
					: `${formatMessage(messages.fieldNotSupported)}`;
			}),
		) as string[][];
	};
