import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { ConfigurationButton as OldButton } from './configuration-button-old.tsx';
import { ConfigurationButton as NewButton } from './view.tsx';

export const ConfigurationButton = componentWithCondition(
	() => fg('jiv-replace-configuration-button-in-context-panel'),
	NewButton,
	OldButton,
);
