/**
 * Feature flags convention: go/frontend-feature-flags
 */
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';

// </lli7>
// Rach slot
// start

// 2
// 3
// Rach slot

// @pierrelingat slot start

// <crobertson>

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isSwitcherooRealtimeExposuresTestFlagEnabled = () =>
	ff('switcheroo-realtime-exposures-test-flag_648u4', false);

// </crobertson>

// <dxu>
export const aggUseIsEditableInViewFlag = () =>
	ff('jiv-17158-agg-use-iseditableinview_8nogh', false);
// </dxu>

// vyakimenko
export const isCascadingSelectSupportForUIMEnabled = () =>
	ff('jsw.xflow.issue-view-nudge-experiment', false);
// vyakimenko

// <sms2>
export const is3pIssueWatchNudgeEnabled = () =>
	ff('third_party_app_nudge_on_issues_watch', false) &&
	expValEquals('third_party_app_nudge_on_issues_watch', 'cohort', 'variation');
// </sms2>

// <aoshannessy>
export const isTeamAttributeAdded = () => ff('add-team-id-analytics-data');
// </aoshannessy>
