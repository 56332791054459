import type { AIFeatureTypeRegistry } from './types.tsx';

type AIFeaturesResponse = {
	ai_feature_output: object;
};

export const invokeAIFeature = async <K extends keyof AIFeatureTypeRegistry>(
	aiFeatureExperienceId: K,
	request: AIFeatureTypeRegistry[K]['request'],
	signal?: AbortSignal,
): Promise<AIFeatureTypeRegistry[K]['response']> => {
	const response = await fetch(`/gateway/api/assist/ai/v2/${request.ai_feature_path}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-Product': 'jira',
			'X-Experience-Id': aiFeatureExperienceId,
		},
		body: JSON.stringify({
			ai_feature_input: request.ai_feature_input,
		}),
		signal,
	});

	if (!response.ok) {
		throw new Error(`API call failed: ${response.status}`);
	}

	const data: AIFeaturesResponse = await response.json();
	const result: AIFeatureTypeRegistry[K]['response'] = data;
	return result;
};
