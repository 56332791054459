// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'issue.details.web-links.heading',
		defaultMessage: 'Web links',
		description: 'The heading used for the web links content section',
	},
	showMoreLabel: {
		id: 'issue.details.web-links.show-more-label',
		defaultMessage:
			'{nextPageCount, plural, one {Show {nextPageCount} more} other {Show {nextPageCount} more}}',
		description: 'Label to action to "show more" web links',
	},
	webLinkAddedFlagTitle: {
		id: 'issue.details.web-links.web-link-added-flag-title',
		defaultMessage: 'We’ve added your web link',
		description: 'Title of flag shown when a web link is added but is hidden',
	},
	webLinkAddedFlagDescription: {
		id: 'issue.details.web-links.web-link-added-flag-description',
		defaultMessage:
			'If you can’t find the web link, go to the issue’s web links section and select Show more.',
		description: 'Description of flag shown when a web link is added but is hidden',
	},
	linksPerIssueLimitExceededTitle: {
		id: 'issue.details.web-links.links-per-issue-limit-exceeded-title',
		defaultMessage: 'We couldn’t add the link',
		description: 'Title for warning flag when links per issue limit is exceeded',
	},
	linksPerIssueLimitExceededDescription: {
		id: 'issue.details.web-links.links-per-issue-limit-exceeded-description',
		defaultMessage: 'We can’t add any more links to this issue as the limit has been reached.',
		description: 'Description for warning flag when links per issue limit is exceeded',
	},
	linksPerIssueLimitExceededNotifyAdminLink: {
		id: 'issue.details.web-links.links-per-issue-limit-exceeded.contact-administrator',
		defaultMessage: 'Notify your admin',
		description: 'A link to notify your admin which opens the comment in new tab',
	},
	createErrorHeading: {
		id: 'issue.details.web-links.error-message.create-error-heading',
		defaultMessage: "We couldn't create the web link",
		description:
			'Inline message heading shown when web link creation failed and user clicks on the error icon.',
	},
	createErrorBody: {
		id: 'issue.details.web-links.error-message.create-error-body',
		defaultMessage: 'Wait a few moments, then give it another try.',
		description:
			'Inline message body shown when web link creation failed and user clicks on the error icon.',
	},
	retryAction: {
		id: 'issue.details.web-links.error-message.retry-action',
		defaultMessage: 'Try again',
		description: 'Action label to retry when a creation or deletion error occurs.',
	},
	cancelAction: {
		id: 'issue.details.web-links.line-card.error-message.cancel-action',
		defaultMessage: 'Cancel',
		description: 'Action label to cancel when a creation or deletion error occurs.',
	},
	webLinkAddedFlagDescriptionIssueTermRefresh: {
		id: 'issue.details.web-links.web-link-added-flag-description-issue-term-refresh',
		defaultMessage: 'If you can’t find the link, go to the Link section and select Show more.',
		description: 'Description of flag shown when a web link is added but is hidden',
	},
	linksPerIssueLimitExceededDescriptionIssueTermRefresh: {
		id: 'issue.details.web-links.links-per-issue-limit-exceeded-description-issue-term-refresh',
		defaultMessage: 'We can’t add any more links as the limit has been reached on this work item.',
		description: 'Description for warning flag when links per issue limit is exceeded',
	},
});
export default messages;
