export const PLATFORM_PROJECT_STABLE_ID = 'sfid:jira-product-discovery:atlassian-project';
export const PLATFORM_PROJECT_STATUS_STABLE_ID =
	'sfid:jira-product-discovery:atlassian-project-status';
export const ISSUE_COMMENTS_STABLE_ID = 'sfid:jira-product-discovery:count-issue-comments';
export const DELIVERY_PROGRESS_STABLE_ID = 'sfid:jira-product-discovery:delivery-progress';
export const DELIVERY_STATUS_STABLE_ID = 'sfid:jira-product-discovery:delivery-status';
export const ARCHIVED_STABLE_ID = 'sfid:jira-product-discovery:archived';
export const ARCHIVED_BY_STABLE_ID = 'sfid:jira-product-discovery:archived-by';
export const ARCHIVED_ON_STABLE_ID = 'sfid:jira-product-discovery:archived-on';
export const INSIGHTS_STABLE_ID = 'sfid:jira-product-discovery:count-insights';
export const LINKED_ISSUES_STABLE_ID = 'sfid:jira-product-discovery:count-linked-issues';
