import React from 'react';
import { ISSUE_CONTEXT_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { ForgeAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import type {
	AnalyticsWrapperPropsTrigger,
	AnalyticsWrapperPropsView,
} from '@atlassian/jira-forge-ui-analytics/src/common/ui/types.tsx';
import { MODULE_TYPE_TRIGGER } from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';

export const ViewAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper module={ISSUE_CONTEXT_MODULE} {...props} />
);

export const TriggerAnalyticsWrapper = (props: AnalyticsWrapperPropsTrigger) => (
	<ForgeAnalyticsWrapper
		module={ISSUE_CONTEXT_MODULE}
		moduleType={MODULE_TYPE_TRIGGER}
		{...props}
	/>
);
