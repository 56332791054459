/**
 * @generated SignedSource<<2b9b556714e043c134344fab947d26bf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly uri: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlReadviewFull_UrlReadView">;
  readonly " $fragmentType": "url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef";
};
export type url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef$key = {
  readonly " $data"?: url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "url_issueFieldUrlInlineEditFull_UrlInlineEditViewOld_fragmentRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "uri"
    },
    {
      "kind": "FragmentSpread",
      "name": "url_issueFieldUrlReadviewFull_UrlReadView"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraUrlField"
};

(node as any).hash = "29215cce3863b3be49bd2b2a229e3ca5";

export default node;
