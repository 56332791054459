import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Divider = styled.hr({
	width: '100%',
	borderWidth: '1px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: `${token('color.border', colors.N40)}`,
	borderBottom: 'none',
	marginTop: token('space.200', '16px'),
	marginRight: 0,
	marginBottom: 0,
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Header = styled.div({
	position: 'relative',
	top: token('space.050', '4px'),
	marginTop: token('space.050', '4px'),
	marginBottom: token('space.025', '2px'),
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});
