import {
	ARCHIVED_BY_STABLE_ID,
	ARCHIVED_ON_STABLE_ID,
	ARCHIVED_STABLE_ID,
	DELIVERY_PROGRESS_STABLE_ID,
	DELIVERY_STATUS_STABLE_ID,
	INSIGHTS_STABLE_ID,
	ISSUE_COMMENTS_STABLE_ID,
	LINKED_ISSUES_STABLE_ID,
	PLATFORM_PROJECT_STABLE_ID,
	PLATFORM_PROJECT_STATUS_STABLE_ID,
} from './constants.tsx';
import type { StableId, StableIdMap } from './types.tsx';

export const STABLE_IDS: StableIdMap = {
	PLATFORM_PROJECT: PLATFORM_PROJECT_STABLE_ID,
	PLATFORM_PROJECT_STATUS: PLATFORM_PROJECT_STATUS_STABLE_ID,
	ISSUE_COMMENTS: ISSUE_COMMENTS_STABLE_ID,
	DELIVERY_PROGRESS: DELIVERY_PROGRESS_STABLE_ID,
	DELIVERY_STATUS: DELIVERY_STATUS_STABLE_ID,
	ARCHIVED: ARCHIVED_STABLE_ID,
	ARCHIVED_BY: ARCHIVED_BY_STABLE_ID,
	ARCHIVED_ON: ARCHIVED_ON_STABLE_ID,
	INSIGHTS: INSIGHTS_STABLE_ID,
	LINKED_ISSUES: LINKED_ISSUES_STABLE_ID,
};

const GLOBAL_SYSTEM_FIELDS_STABLE_IDS: Set<StableId> = new Set([
	PLATFORM_PROJECT_STABLE_ID,
	PLATFORM_PROJECT_STATUS_STABLE_ID,
	ISSUE_COMMENTS_STABLE_ID,
	DELIVERY_PROGRESS_STABLE_ID,
	DELIVERY_STATUS_STABLE_ID,
	ARCHIVED_STABLE_ID,
	ARCHIVED_BY_STABLE_ID,
	ARCHIVED_ON_STABLE_ID,
	INSIGHTS_STABLE_ID,
	LINKED_ISSUES_STABLE_ID,
]);

export const isGlobalSystemFieldStableId = (stableId?: StableId) =>
	stableId ? GLOBAL_SYSTEM_FIELDS_STABLE_IDS.has(stableId) : false;
