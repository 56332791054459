import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	storageLimitsBannerText: {
		id: 'storage-limits-banner.storage-limits-banner-text',
		defaultMessage: "You have exceeded the Free plan's {freePlanStorageLimit} GB storage limit.",
		description:
			'Banner message shown when the user has exceeded the storage limit for Jira Software.',
	},
	storageLimits90PercentBannerText: {
		id: 'storage-limits-banner.storage-limits-90-percent-banner-text',
		defaultMessage: 'You’ve used 90% of the Free plan’s {freePlanStorageLimit} GB storage limit.',
		description:
			'Banner message shown when the user has exceeded the storage limit for Jira Software.',
	},
	hamsLinkText: {
		id: 'storage-limits-banner.hams-link-text',
		defaultMessage: 'Upgrade to Standard for {standardPlanStorageLimit} GB.',
		description:
			'Link text shown when the user has exceeded the storage limit for Jira Software on a HAMS site.',
	},
});
