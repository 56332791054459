import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	empty: {
		id: 'issue-selectable-field-edit-view.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-selectable-field-edit-view.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails from the server',
	},
	loading: {
		id: 'issue-selectable-field-edit-view.loading',
		defaultMessage: 'Searching...',
		description: 'Message when options are being loaded from the server',
	},
	placeholder: {
		id: 'issue-selectable-field-edit-view.placeholder',
		defaultMessage: 'Select...',
		description: 'Placeholder message when no option is selected',
	},
	recent: {
		id: 'issue-selectable-field-edit-view.recent',
		defaultMessage: 'Recent',
		description: 'Default header displayed in the list used to mark recently selected items',
	},
	all: {
		id: 'issue-selectable-field-edit-view.all',
		defaultMessage: 'All',
		description: 'Default header displayed in the list used to mark all items',
	},
	other: {
		id: 'issue-selectable-field-edit-view.other',
		defaultMessage: 'Other',
		description: 'Header displayed in the list used to mark other items',
	},
	selectLabel: {
		id: 'issue-selectable-field-edit-view.select-label',
		defaultMessage: 'Select with footer',
		description: 'Aria-label for the Select component with footer',
	},
	newOptionLabel: {
		id: 'issue-selectable-field-edit-view.new-option-label',
		defaultMessage: ' (New option)',
		description: 'Option label for a new option',
	},
});
