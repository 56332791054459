import React from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { IssueViewPinFieldIcon } from './IssueViewPinFieldIcon.tsx';
import FieldPinNotConnected from './main.tsx';
import messages from './messages.tsx';
import type { AnalyticsProps } from './types.tsx';
import { isFieldPinned, usePinnedFields, encodePinnedFields } from './utils.tsx';

export type Props = {
	fieldId: string;
	label?: string;
};

/**
 * Icon to add or remove a field from the pinned panel in the sidebar.
 */
export const FieldPinOld = (props: Props) => {
	const { fieldId, label = '' } = props;
	const issueKey = useIssueKey();

	const [pinnedFields, setPinnedFields] = usePinnedFields(issueKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();

	const dispatchPinnedFieldAnalytics = ({
		isPinned,
		newPinnedFieldLength,
		pinnedFieldPosition,
		setPinSuccess,
		savedFieldIdLength,
		fieldName,
	}: AnalyticsProps) => {
		const payload = {
			isPinned,
			newPinnedFieldCount: newPinnedFieldLength,
			pinnedFieldPosition,
			setPinSuccess,
			savedFieldIdLength,
			fieldName,
		};

		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'pinnedFieldButton',
		});
		fireUIAnalytics(event, 'pinnedFields', payload);
	};

	// Without the issueKey, there is not point pinning the field, as we won't know the project key either.
	// The code block has to be after the hook ^
	if (!issueKey) {
		return null;
	}

	const isPinned = isFieldPinned(pinnedFields, fieldId);
	const userHasPinnedFields = pinnedFields.length > 0;
	const onClick = (): void => {
		const clonedPinnedFields = [...pinnedFields];

		let pinnedFieldIndex = -1;
		if (isPinned) {
			// Unpin the field
			pinnedFieldIndex = clonedPinnedFields.indexOf(fieldId);
			clonedPinnedFields.splice(pinnedFieldIndex, 1);
		} else {
			// Pin the field
			clonedPinnedFields.push(fieldId);
		}

		const setPinSuccess = setPinnedFields(clonedPinnedFields);

		dispatchPinnedFieldAnalytics({
			isPinned: !isPinned,
			newPinnedFieldLength: clonedPinnedFields.length,
			pinnedFieldPosition: isPinned ? pinnedFieldIndex : clonedPinnedFields.indexOf(fieldId),
			setPinSuccess,
			savedFieldIdLength: encodePinnedFields(clonedPinnedFields).length,
			fieldName: label,
		});

		if (!isPinned) {
			showFlag(
				setPinSuccess
					? {
							type: 'success',
							title: [messages.fieldPinned, { fieldLabel: label }],
						}
					: {
							title: messages.errorTooManyPinnedTitle,
							description: messages.errorTooManyPinnedDescription,
							type: 'error',
						},
			);
		}
	};

	return (
		<FieldPinNotConnected
			label={label}
			onClick={onClick}
			isPinned={isPinned}
			hasPinnedFields={userHasPinnedFields}
		/>
	);
};

export const FieldPin = componentWithFG(
	'issue_view_field_config_edit',
	IssueViewPinFieldIcon,
	FieldPinOld,
);
