import React from 'react';

import { Box, Grid, Stack, xcss } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';

import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';

import {
	formatDistanceStrictWithLocale,
	formatWithLocale,
} from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';

import type { AuditlogSummary, ProjectAdminDetails } from '../../../common/types.tsx';
import RuleDisplayIcon from './rule-display-icon/index.tsx';

type RuleDisplayProps = { recentItems: AuditlogSummary[] } & {
	projectAdminDetails: ProjectAdminDetails;
};

const RuleName: React.FC<
	{
		ruleName: AuditlogSummary['ruleName'];
		ruleId: AuditlogSummary['ruleId'];
		ruleIdUuid?: AuditlogSummary['ruleIdUuid'];
	} & { projectAdminDetails: ProjectAdminDetails }
> = (props) => {
	const { ruleName, ruleId, ruleIdUuid, projectAdminDetails } = props;
	if (projectAdminDetails.isProjectAdmin) {
		const ruleIdInLink = ruleIdUuid ?? ruleId;
		const manageSpecificRuleUrl = `${projectAdminDetails.manageRuleUrl}/${ruleIdInLink}`;
		return (
			<Anchor
				xcss={anchorStyle}
				title={ruleName}
				href={manageSpecificRuleUrl}
				target="_blank"
				rel="noopener noreferrer"
			>
				{ruleName}
			</Anchor>
		);
	}
	return (
		<Box title={ruleName} xcss={textContainerStyle}>
			{ruleName}
		</Box>
	);
};

const RuleDisplay: React.FC<RuleDisplayProps> = (props) => {
	const { recentItems, projectAdminDetails } = props;
	const { data } = useCurrentUser();
	const locale = data?.user?.locale || defaultLocale;
	const currentDate = new Date();

	return (
		<>
			{recentItems.map((item) => {
				const execTime = item.endTime ?? item.startTime;
				const formattedDate = formatDistanceStrictWithLocale(execTime, currentDate, locale, true);
				const fullAbsoluteDate = formatWithLocale(execTime, 'dd/MM/yy hh:mm:ss a', locale);
				return (
					<Stack key={`recent-item-${item.id}-${item.ruleName}`} xcss={ruleContainerStyle}>
						<Grid
							gap="space.050"
							templateAreas={['icon name', 'empty time']}
							templateColumns="16px 1fr"
						>
							<RuleDisplayIcon category={item.category} />

							<RuleName
								ruleName={item.ruleName}
								ruleId={item.ruleId}
								ruleIdUuid={item.ruleIdUuid}
								projectAdminDetails={projectAdminDetails}
							/>

							<Box title={fullAbsoluteDate} xcss={ruleTimeStyle}>
								{formattedDate}
							</Box>
						</Grid>
					</Stack>
				);
			})}
		</>
	);
};

const ruleContainerStyle = xcss({
	marginTop: 'space.050',
	width: '100%',
});

const anchorStyle = xcss({
	width: 'fit-content',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	gridArea: 'name',
});

const textContainerStyle = xcss({
	color: 'color.text',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	gridArea: 'name',
});

const ruleTimeStyle = xcss({
	font: 'font.body', // should be 12px
	color: 'color.text',
	gridArea: 'time',
});

export default RuleDisplay;
