import React, { type ReactNode, useCallback, type MouseEvent, useRef, useEffect } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	DropdownItemGroup,
	type CustomTriggerProps,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/core/migration/add';
import AppIcon from '@atlaskit/icon/core/migration/app--addon';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import AkToolTip from '@atlaskit/tooltip';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { EcosystemContentPanel } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useShowSpotlight } from '../quick-actions-changeboarding/use-show-spotlight/index.tsx';
import { AdControlSection } from '../ad-control-section/view.tsx';
import type { QuickActionComponent } from '../types.tsx';
import { useCrossflowAdControls } from '../ad-control-section/use-crossflow-ad-controls/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	actions: ReactNode[];
	apps: ReactNode;
	availableAddons?: EcosystemContentPanel[];
	setMenuOpen: (menuName: string, isOpen: boolean) => void;
	openMenu: string | null;
	canInstallAddons?: boolean;
	crossflow?: {
		actions: QuickActionComponent[];
	};
	onSearch: (query: string) => void;
	searchQuery?: string;
};

export const QuickAddItemsCompact = (props: Props) => {
	const {
		actions,
		apps,
		crossflow,
		availableAddons,
		setMenuOpen,
		openMenu,
		canInstallAddons,
		onSearch,
		searchQuery,
	} = props;
	const { formatMessage } = useIntl();
	const { userHasDismissedAd, dismissAd } = useCrossflowAdControls();
	const add = formatMessage(messages.add);
	const appsMessage = formatMessage(messages.apps);
	const spotlightRef = useRef<HTMLElement>(null);
	const searchRef = useRef<HTMLInputElement | null>(null);

	const { shouldShowSpotlight, updateShouldShowSpotlight } = fg('quick_actions_menu_ga')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShowSpotlight()
		: { shouldShowSpotlight: false, updateShouldShowSpotlight: noop };

	const filteredAddons =
		availableAddons &&
		availableAddons.filter(
			(addon) =>
				!(
					addon.moduleKey.includes('linked-goals-content-panel') &&
					expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
				),
		);

	const availableApps = filteredAddons && filteredAddons.length >= 1;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickWithAnalytics = useCallback(
		(originalOnClick?: (event: MouseEvent<HTMLElement>) => void, buttonState?: string) =>
			(event: MouseEvent<HTMLElement>) => {
				if (originalOnClick) {
					originalOnClick(event);
				}

				if (buttonState) {
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'button clicked',
						`quickActionButtonRevamp${buttonState}`,
					);
				}
			},
		[createAnalyticsEvent],
	);

	const toggleMenuOpen: (menuName: string) => (params: OnOpenChangeArgs) => void = useCallback(
		(menuName: string) =>
			({ isOpen, event }: OnOpenChangeArgs) => {
				// eslint-disable-next-line jira/ff/no-preconditioning
				if (fg('quick_actions_menu_ga') && menuName === 'addButton' && shouldShowSpotlight) {
					if (
						spotlightRef.current &&
						event.target &&
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						spotlightRef.current.contains(event.target as Node)
					) {
						return;
					}

					if (!isOpen) {
						updateShouldShowSpotlight();
					}
				}

				setMenuOpen(menuName, isOpen);
			},
		[shouldShowSpotlight, setMenuOpen, updateShouldShowSpotlight],
	);

	const renderDropdownTrigger = ({ triggerRef, onClick, ...rest }: CustomTriggerProps) => {
		const triggerButton = expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) ? (
			<IconButton
				{...rest}
				icon={() => <AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />}
				ref={triggerRef}
				label={formatMessage(messages.buttonAddCreateWork)}
				onClick={onClickWithAnalytics(onClick, '+')}
			/>
		) : (
			<Button
				{...rest}
				label={formatMessage(messages.buttonAddContent)}
				ref={triggerRef}
				onClick={onClickWithAnalytics(onClick, 'Add')}
				iconBefore={<AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />}
			>
				{add}
			</Button>
		);

		if (fg('quick_actions_menu_ga')) {
			return (
				<AkToolTip
					content={
						expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
							? formatMessage(messages.buttonAddCreateWork)
							: formatMessage(messages.buttonAddContent)
					}
				>
					<SpotlightPulse radius={3} pulse={shouldShowSpotlight}>
						{triggerButton}
					</SpotlightPulse>
				</AkToolTip>
			);
		}

		return triggerButton;
	};

	useEffect(() => {
		if (searchRef.current) {
			searchRef.current.style.padding = '4px 4px 4px 8px';
			if (openMenu === 'addButton') {
				searchRef.current.focus();
			}
		}
	}, [searchQuery, openMenu]);

	return (
		<>
			<Inline space="space.100">
				<UFOSegment name="issue-quick-add-button">
					<DropdownMenu
						trigger={renderDropdownTrigger}
						testId="issue-view-foundation.quick-add.quick-add-items-compact.add-button-dropdown"
						shouldRenderToParent
						onOpenChange={toggleMenuOpen('addButton')}
						isOpen={openMenu === 'addButton'}
					>
						{expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) && (
							<Box xcss={searchBoxStyles}>
								<TextField
									testId="issue-view-foundation.quick-add.quick-add-items-compact.search-input"
									aria-label={formatMessage(messages.findMenuItem)}
									aria-autocomplete="list"
									elemBeforeInput={
										<Inline xcss={searchIconStyles}>
											<SearchIcon label={formatMessage(messages.findMenuItem)} size="small" />
										</Inline>
									}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										onSearch(event.target.value)
									}
									placeholder={formatMessage(messages.findMenuItem)}
									value={searchQuery}
									ref={searchRef}
								/>
							</Box>
						)}

						{expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) ? (
							<>
								{Array.isArray(actions) &&
									actions.map(
										(actionGroup, index) =>
											Array.isArray(actionGroup) &&
											actionGroup.length > 0 && (
												<DropdownItemGroup key={`group${index}`} hasSeparator={index !== 0}>
													{actionGroup}
												</DropdownItemGroup>
											),
									)}
								{!userHasDismissedAd && crossflow?.actions?.length ? (
									<AdControlSection
										key="ad-control-section"
										actions={crossflow.actions}
										onDismiss={dismissAd}
									/>
								) : null}
							</>
						) : (
							<DropdownItemGroup>{actions}</DropdownItemGroup>
						)}
					</DropdownMenu>
				</UFOSegment>
				{(availableApps || canInstallAddons) && (
					<UFOSegment name="issue-quick-apps-button">
						<DropdownMenu
							trigger={({ triggerRef, onClick, ...rest }) => {
								const appsButton = expVal(
									'quick_actions_m2_experiment',
									'isM2ExperimentEnabled',
									false,
								) ? (
									<IconButton
										{...rest}
										ref={triggerRef}
										label={formatMessage(messages.buttonViewAppActions)}
										onClick={onClickWithAnalytics(onClick, '...')}
										icon={() => (
											<AppIcon color="currentColor" label="" spacing="none" LEGACY_size="small" />
										)}
									/>
								) : (
									<Button
										{...rest}
										label={formatMessage(messages.buttonAddApps)}
										ref={triggerRef}
										onClick={onClickWithAnalytics(onClick, 'Apps')}
										iconBefore={
											<AppIcon color="currentColor" label="" spacing="none" LEGACY_size="small" />
										}
									>
										{appsMessage}
									</Button>
								);

								const appsTooltipMessage = expVal(
									'quick_actions_m2_experiment',
									'isM2ExperimentEnabled',
									false,
								)
									? formatMessage(messages.buttonViewAppActions)
									: formatMessage(messages.buttonAddApps);

								return (
									<AkToolTip
										content={
											availableApps
												? appsTooltipMessage
												: formatMessage(messages.buttonAddAppsNoApps)
										}
									>
										{availableApps || fg('quick_actions_menu_ga') ? (
											appsButton
										) : (
											<IconButton
												{...rest}
												ref={triggerRef}
												label={formatMessage(messages.buttonAddAppsNoApps)}
												onClick={onClickWithAnalytics(onClick, '...')}
												icon={() => (
													<ShowMoreHorizontalIcon
														color="currentColor"
														spacing="spacious"
														label=""
														LEGACY_size="small"
													/>
												)}
											/>
										)}
									</AkToolTip>
								);
							}}
							testId="issue-view-foundation.quick-add.quick-add-items-compact.apps-button-dropdown"
							onOpenChange={toggleMenuOpen('appsButton')}
							isOpen={openMenu === 'appsButton'}
							shouldRenderToParent
						>
							{apps}
						</DropdownMenu>
					</UFOSegment>
				)}
			</Inline>
		</>
	);
};

export default QuickAddItemsCompact;

const searchIconStyles = xcss({
	marginInlineStart: 'space.100',
});

const searchBoxStyles = xcss({
	marginBlockStart: 'space.100',
	marginInlineStart: 'space.100',
	marginInlineEnd: 'space.100',
	width: '250px',
});
