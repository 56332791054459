import React, { Component, useEffect, useState, Fragment } from 'react';
import type { Dispatch } from 'redux';
import { styled } from '@compiled/react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import {
	withAnalyticsEvents,
	type UIAnalyticsEvent,
	type CreateUIAnalyticsEvent,
} from '@atlaskit/analytics-next';
import { PlaceholderNew } from '@atlassian/jira-placeholder/src/index.tsx';
import ReduxAnalyticsData from '@atlassian/jira-analytics-web-react/src/components/redux-analytics-data.tsx';
import ViewTracker from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import type { AnalyticsDataPayload } from '@atlassian/jira-analytics-web-react/src/index.tsx';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { jiraBridge } from '@atlassian/jira-common-bridge';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ExperienceFailureTracker as ViewExperienceFailureTracker,
	ExperienceSuccessTracker as ViewExperienceSuccessTracker,
} from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { useDevOpsAppRecommendationsStore } from '@atlassian/jira-dev-ops-app-recommendations/src/controllers/recommendation-panel/index.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { UiModificationsExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { AsyncLimitExceedContactAdminModal } from '@atlassian/jira-issue-hard-limit-exceed-modal/src/async.tsx';
import {
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	UNKNOWN_ERROR,
	type IssueError,
} from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import AddModal from '@atlassian/jira-issue-view-activity/src/worklog/view/add-modal/index.tsx';
import EditModal from '@atlassian/jira-issue-view-activity/src/worklog/view/edit-modal/index.tsx';
import ClipboardAttachmentPicker from '@atlassian/jira-issue-view-base/src/content/attachment/picker/common/clipboard/index.tsx';
import AddAttachmentFrontendSlaTracker from '@atlassian/jira-issue-view-base/src/content/attachment/picker/common/sla-wrapper/view.tsx';
import DropzoneAttachmentPicker from '@atlassian/jira-issue-view-base/src/content/attachment/picker/dropzone/view.tsx';
import PopupAttachmentPicker from '@atlassian/jira-issue-view-base/src/content/attachment/picker/popup/index.tsx';
import type { AnalyticsPayload } from '@atlassian/jira-issue-view-common-types/src/analytics-types.tsx';
import type {
	ErrorMessageUi,
	State as ReduxState,
} from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	ADD_MODAL,
	EDIT_MODAL,
	NO_MODAL,
	type WorklogModal,
} from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import { trackLoadedIssueView } from '@atlassian/jira-issue-view-common-utils/src/analytics/index.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import DropzoneOverlay from '@atlassian/jira-issue-view-common-views/src/dropzone-overlay/index.tsx';
import withCompactMode from '@atlassian/jira-issue-view-compact-mode/src/index.tsx';
import EcosystemBackgroundScripts from '@atlassian/jira-issue-view-ecosystem/src/ecosystem-background-scripts-view.tsx';
import { EcosystemRealtimeUpdatesView } from '@atlassian/jira-issue-view-ecosystem/src/ecosystem-realtime-updates-view.tsx';
import AuthenticationErrorView from '@atlassian/jira-issue-view-error-boundary/src/auth-error-view-legacy.tsx';
import { PermissionErrorView } from '@atlassian/jira-issue-view-errors/src/ui/permission-error-view/index.tsx';
import { UnknownErrorView } from '@atlassian/jira-issue-view-errors/src/ui/unknown-error-view/index.tsx';
import { useForgeDataComplete } from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import KeyboardShortcuts from '@atlassian/jira-issue-view-keyboard-shortcuts/src/index.tsx';
import { IssueViewTimeTrackingAddModal } from '@atlassian/jira-issue-view-layout-time-tracking-add-modal/src/ui/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import ServiceDeskClipboardPicker from '@atlassian/jira-issue-view-servicedesk-attachments/src/ui/clipboard/view.tsx';
import ServiceDeskDropzonePicker from '@atlassian/jira-issue-view-servicedesk-attachments/src/ui/dropzone/view.tsx';
import ServiceDeskPopupAttachmentPicker from '@atlassian/jira-issue-view-servicedesk-attachments/src/ui/popup/view.tsx';
import { AiOptInService } from '@atlassian/jira-issue-view-services/src/issue/ai-optin-service/index.tsx';
import {
	type IssueViewMountedAction,
	issueViewMounted,
} from '@atlassian/jira-issue-view-store/src/actions/view-mount-status-actions.tsx';
import {
	useIssueMountCounterActions,
	type IncrementIssueMountCountType,
} from '@atlassian/jira-issue-view-store/src/common/state/stores/issue-mount-counter.tsx';
import {
	type RefreshIssueRequestAction,
	type RefreshIssueRequestPayload,
	refreshIssueRequest,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	isModalSelector,
	isServiceDeskSelector,
	issueKeySelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	errorMessageSelector,
	errorSelector,
	isCompletedLoadingSelector,
	isLoadingSelector,
	doNotUseIsLoadingPromiseSelector,
	isPreviewSelector,
	projectTypeSelector,
	isSimplifiedProjectSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { issueSidebarRatiosSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/my-preferences-selector.tsx';
import { openModalSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { forgeUiModificationsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import { hierarchyLevelSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector.tsx';
import { analyticsSelector as securityAnalyticsSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/security-level-selector.tsx';
import { analyticsPayloadSelector } from '@atlassian/jira-issue-view-store/src/selectors/analytics-payload-selector.tsx';
import type { IssueKey, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/IssueViewSkeleton.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { IssueActionsEntryPointContextProvider } from '@atlassian/jira-command-palette-issue-actions-context/src/context.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import { withAnalyticsAttributes } from '../../analytics/analytics-attributes.tsx';
import { BentoMetricsWithSpaTimingInfo } from '../../common/metrics/component/with-spa-timing-info.tsx';
import withMetrics from '../../common/metrics/context/consumer.tsx';
import type { Metrics } from '../../common/metrics/context/index.tsx';
import { isSynthetic } from '../../utils.tsx';
import Flags from '../flag-view.tsx';
import { CommandPaletteIssueActions } from './command-palette-issue-actions/index.tsx';
import { CommandPaletteSubscriber } from './command-palette-subscriber/index.tsx';
import { IncidentManagementChangeboarding } from './incident-management-changeboarding.tsx';
import IssueLayout from './issue-layout/index.tsx';
import { getLayoutStyle, getSidebarRatio } from './issue-layout/utils.tsx';
import type { IssueViewRenderProps } from './issue-view.types.tsx';
import { MaybePrefetchFieldSuggestions } from './prefetch-field-suggestions/index.tsx';
import { RenderCommandPaletteHeader } from './register-command-palette-header/index.tsx';
import RegisterShortcutsDialogHelper from './register-shortcuts-dialog/index.tsx';

type Props = {
	isLoading: boolean;
	doNotUseIsLoadingPromise: Promise<void> | null;
	isPreview: boolean;
	// critical render for the page has been completed
	isComplete: boolean;
	// TODO: when cleaning up issue.details.analytics.forge-data-issue-view remove maybe type
	isForgeDataComplete?: boolean;
	clearRecommendation?: () => void;
	issueKey: IssueKey;
	isCompact?: boolean;
	isModal?: boolean;
	isServiceDesk: boolean;
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
	projectType: ProjectType | null;
	sidebarRatios: string | null;
	projectKey?: ProjectKey;
	isSimplifiedProject?: boolean;
	uiModificationsModules: UiModificationsExtension[];
	incrementIssueMountCount: IncrementIssueMountCountType;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
} & IssueViewRenderProps & {
		error?: IssueError;
		errorMessage?: ErrorMessageUi;
		metrics: Metrics;
		worklogModal: WorklogModal;
		onRefresh: () => void;
		onIssueViewDataLoaded: () => void;
		onMount: () => void;
	};

type State = {
	isIssueContainerReady: boolean;
	attachmentHover: boolean;
};

// TODO Decomp BENTO-12515 - convert to functional component
// eslint-disable-next-line jira/react/no-class-components
export class Issue extends Component<Props, State> {
	static displayName = 'Issue';

	static defaultProps = {
		projectType: null,
		onIssueViewDataLoaded: noop,
		onMount: noop,
	};

	state = {
		isIssueContainerReady: false,
		attachmentHover: false,
	};

	componentDidMount() {
		const { onRefresh, onMount, issueKey, createAnalyticsEvent, incrementIssueMountCount } =
			this.props;
		onMount();
		jiraBridge.addRefreshIssueListener(onRefresh);

		if (fg('issue_view_mount_count_analytic')) {
			incrementIssueMountCount(
				issueKey,
				createAnalyticsEvent({ action: 'viewed', actionSubject: 'issueMountCount' }),
				{ currentTime: Math.floor(performance.now()) },
			);
		}
	}

	shouldComponentUpdate(nextProps: Props) {
		const { error, isLoading } = this.props;

		const issueStillLoading = isLoading && nextProps.isLoading;
		const errorChanged = nextProps.error !== error;
		return errorChanged || !issueStillLoading;
	}

	componentDidUpdate() {
		const { error } = this.props;
		if (error !== undefined && error !== null) {
			this.hasErrored = true;
		}
	}

	componentWillUnmount() {
		jiraBridge.removeRefreshIssueListener(this.props.onRefresh);

		this.props.clearRecommendation && this.props.clearRecommendation();
	}

	setIssueBodyContainerRef = (ref: HTMLDivElement | null) => {
		if (ref) {
			this.issueBodyContainerRef = ref;
			this.setState({ isIssueContainerReady: true });
		}
	};

	setAttachmentHover = (value: boolean) => {
		this.setState({ attachmentHover: value });
	};

	setAttachmentHoverFalse = () => {
		this.setState({ attachmentHover: false });
	};

	setAttachmentHoverTrue = () => {
		this.setState({ attachmentHover: true });
	};

	hasErrored = false;

	issueBodyContainerRef: HTMLDivElement | null | undefined;

	renderError = (error: string, errorContext?: ErrorMessageUi) => {
		/**
    From an experience tracking perspective, permission/auth errors are
    considered *successes* (as they are *expected* error scenarios). Failure
    in this context is limited only to bugs or downtime on our end (which
    should fall into the "unknown error") case.

     We also only want to track on isComplete and ignore preview mode
    so we only wrap the error components with the experience trackers when isComplete
    */
		const { isComplete } = this.props;
		const errorObj = { ...errorContext };

		switch (error) {
			case AUTHENTICATION_ERROR:
				return isComplete ? (
					<ViewExperienceSuccessTracker location="auth-error">
						<AuthenticationErrorView />
					</ViewExperienceSuccessTracker>
				) : (
					<AuthenticationErrorView />
				);
			case NOT_FOUND_OR_NO_PERMISSION_ERROR: {
				return isComplete ? (
					<ViewExperienceSuccessTracker location="not-found-or-no-permission-error">
						<PermissionErrorView />
					</ViewExperienceSuccessTracker>
				) : (
					<PermissionErrorView />
				);
			}
			case UNKNOWN_ERROR:
			case CONNECTIVITY_ERROR:
			default:
				return isComplete && error !== CONNECTIVITY_ERROR ? (
					<ViewExperienceFailureTracker location="unknown-error" failureEventAttributes={errorObj}>
						{/* moved to src/packages/issue/issue-view/src/views/issue-details/issue-modal/index.tsx for relay */}
						<UnknownErrorView />
					</ViewExperienceFailureTracker>
				) : (
					<UnknownErrorView />
				);
		}
	};

	renderAttachmentPickers = () => {
		const { isServiceDesk, isComplete, projectType } = this.props;
		if (isComplete) {
			return (
				<AddAttachmentFrontendSlaTracker
					experience="renderAttachmentPickers"
					analyticsSource={isServiceDesk ? 'issue-view-jsd' : 'issue-view-non-jsd'}
					projectType={projectType}
					isReady={this.state.isIssueContainerReady && !!this.issueBodyContainerRef}
				>
					{isServiceDesk ? (
						// Service desk has its own attachment pickers, which will add attachments
						// directly to a new comment editor.
						<>
							<ServiceDeskPopupAttachmentPicker />
							<ServiceDeskClipboardPicker />
							{
								// The media picker is only initialised once, so we want to wait until
								// we have the container before rendering the dropzone picker.
								this.state.isIssueContainerReady && this.issueBodyContainerRef && (
									<ServiceDeskDropzonePicker
										container={this.issueBodyContainerRef}
										onDragEnter={this.setAttachmentHoverTrue}
										onDragLeave={this.setAttachmentHoverFalse}
										onDrop={this.setAttachmentHoverFalse}
									/>
								)
							}
						</>
					) : (
						<div>
							<PopupAttachmentPicker />
							<ClipboardAttachmentPicker />
							{
								// The media picker is only initialised once, so we want to wait until
								// we have the container before rendering the dropzone picker.
								this.state.isIssueContainerReady && this.issueBodyContainerRef && (
									<DropzoneAttachmentPicker
										container={this.issueBodyContainerRef}
										onDragEnter={this.setAttachmentHoverTrue}
										onDragLeave={this.setAttachmentHoverFalse}
										onDrop={this.setAttachmentHoverFalse}
									/>
								)
							}
						</div>
					)}
				</AddAttachmentFrontendSlaTracker>
			);
		}

		return null;
	};

	renderWorklogModal() {
		switch (this.props.worklogModal) {
			case ADD_MODAL:
				return fg('relay-migration-issue-fields-time-tracking') ? (
					this.props.issueViewRelayFragment?.timeTrackingField && (
						// @ts-expect-error - Property 'activityProvider' is missing
						<IssueViewTimeTrackingAddModal
							fragmentKey={this.props.issueViewRelayFragment.timeTrackingField}
							externalId=""
						/>
					)
				) : (
					// @ts-expect-error - Property 'activityProvider' is missing in type '{ externalId: string; }' but required in type '{ key?: Key | null | undefined; analyticsContext?: Record<string, any> | undefined; activityProvider: any; externalId: string; context?: Context<ReactReduxContextValue<any, AnyAction>> | undefined; store?: Store<...> | undefined; }'.
					<AddModal externalId="" />
				);
			case EDIT_MODAL:
				// @ts-expect-error - Type '{}' is not assignable to type 'IntrinsicAttributes & ({ key?: Key | null | undefined; forwardedRef?: Ref<any> | undefined; analyticsContext?: Record<string, any> | undefined; activityProvider: any; externalId: string; context?: Context<...> | undefined; store?: Store<...> | undefined; } | { ...; })'.
				return <EditModal />;
			case NO_MODAL:
			default:
				return null;
		}
	}

	renderAiOptInFetchService() {
		return this.props.isComplete && <AiOptInService />;
	}

	renderMainContent() {
		const { isCompact, issueMaxWidth, shouldShowCloseButton, onClose, isModal } = this.props;
		const layoutStyle = getLayoutStyle(isModal, issueMaxWidth);

		return (
			<IssueBodyContainer ref={this.setIssueBodyContainerRef}>
				<IssueBodyContainerDropzone attachmentHover={this.state.attachmentHover} />
				{this.state.attachmentHover && <DropzoneOverlay />}
				<IssueLayout
					layoutStyle={layoutStyle}
					renderFeedback={this.props.renderFeedback}
					issueDeleteCallbacks={this.props.issueDeleteCallbacks}
					viewModeOptions={this.props.viewModeOptions}
					isStickyHeaderOffset={this.props.isStickyHeaderOffset}
					issueViewRelayFragment={this.props.issueViewRelayFragment}
					rootRelayFragment={this.props.rootRelayFragment}
					onClose={onClose}
					// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
					isCompact={isCompact}
					issueMaxWidth={issueMaxWidth}
					shouldSetInitialFocus={this.props.shouldSetInitialFocus}
					shouldShowProjectLevelBreadcrumb={this.props.shouldShowProjectLevelBreadcrumb}
					shouldShowRootProjectsBreadcrumb={this.props.shouldShowProjectLevelBreadcrumb}
					shouldShowCloseButton={shouldShowCloseButton}
					isScrollingHotkeysDisabled={this.props.isScrollingHotkeysDisabled}
					uiModificationsModules={this.props.uiModificationsModules}
				/>
				{this.renderWorklogModal()}
				{this.renderAttachmentPickers()}
				{this.props.isServiceDesk && this.props.isComplete && <IncidentManagementChangeboarding />}
				<EcosystemRealtimeUpdatesView />
				{this.props.isComplete && <EcosystemBackgroundScripts />}
				{!fg('issue_view_prefetch_relay_label_fields') && this.props.issueViewRelayFragment && (
					<MaybePrefetchFieldSuggestions
						issueViewRelayFragment={this.props.issueViewRelayFragment}
					/>
				)}
			</IssueBodyContainer>
		);
	}

	render() {
		/**
		 * We throw a promise here so that we can trigger the suspense boundary above that will load the
		 * issueViewSkeleton above in modal-issue-app and issue-app
		 * The Suspense boundary will then be the fallback for both the AGG query and gira.
		 * The reason we want to trigger the suspense boundary and not directly return the
		 * <IssueViewSkeleton/> here is that we want to keep the skeletons in one consistent
		 * place where they cannot be showing differently from any div's in between and will
		 * not remount causing the skeleton animation to change and cause small visual differences
		 * for customers.
		 * We are storing this promise in redux so that we can resolve it when the loading has finished.
		 */
		if (this.props.doNotUseIsLoadingPromise != null) {
			throw this.props.doNotUseIsLoadingPromise;
		}

		const renderViewTracker = this.props.isComplete && this.props.isForgeDataComplete;
		const MaybeIssueActionsEntryPointContextProvider = fg('llc-command-palette-issue-actions-core')
			? IssueActionsEntryPointContextProvider
			: Fragment;

		return (
			<>
				<MaybeIssueActionsEntryPointContextProvider>
					<UFOSegment name="issue-view-details">
						<BentoMetricsWithSpaTimingInfo
							isLoading={this.props.isLoading}
							isPreview={this.props.isPreview}
							isComplete={this.props.isComplete}
							error={this.props.error}
							metrics={this.props.metrics}
							issueKey={this.props.issueKey}
						/>
						{renderViewTracker ? <ViewTracker /> : null}
						<Flags externalId="issue.flags" />
						<KeyboardShortcuts />
						{this.renderMainContent()}
						<RegisterShortcutsDialogHelper
							onClose={this.props.onClose}
							onIssueDeleteSuccess={this.props.issueDeleteCallbacks?.onIssueDeleteSuccess}
						/>
						<CommandPaletteIssueActions issueKey={this.props.issueKey} />
						<CommandPaletteSubscriber />
						<AsyncLimitExceedContactAdminModal />
						<RenderCommandPaletteHeader issueKey={this.props.issueKey} />
					</UFOSegment>
				</MaybeIssueActionsEntryPointContextProvider>
			</>
		);
	}
}

type OwnProps = IssueViewRenderProps & {
	externalId?: string;
	isModal?: boolean;
	sidebarRatios: string | null;
};

export const IssueViewReadyTracker = (props: Props) => {
	const [hadError, setHadError] = useState(false);

	const { error, isComplete, onIssueViewDataLoaded, isForgeDataComplete } = props;

	useEffect(() => {
		if (error) {
			setHadError(true);
		}
	}, [error]);

	// FIXME: reading useForgeDataComplete here causes "too early" update
	// we need to let ReduxAnalyticsData to run first
	// double check behavior after 'platform.analytics-next-use-modern-context_fqgbx' rollout
	// const [isForgeDataComplete] = useForgeDataComplete();
	const analyticsIsReady = isComplete && isForgeDataComplete;

	useEffect(
		() => {
			// note - for unknown reason we are not recording views that "had error"
			// probably to track only "good views"
			if (analyticsIsReady && !hadError) {
				onIssueViewDataLoaded();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			analyticsIsReady /* explicitly ignoring hadError. Event should be sent strictly once no matter what */,
		],
	);

	return fg('issue_view_app_concurrent_mode_suspense_') ? (
		<PlaceholderNew name="issue-view-issue-details" fallback={<IssueViewSkeleton />}>
			<Issue {...props} />
		</PlaceholderNew>
	) : (
		<Issue {...props} />
	);
};

const flowFF = functionWithCondition(
	() => fg('remove_issue_flowwithsafecomponent_error_boundary_'),
	flow,
	flowWithSafeComponent,
);

// FIXME: JIV-17455 should be fully typed
const IssueViewClassComponent = flowFF(
	withAnalyticsEvents({
		onIssueViewDataLoaded: { action: 'loaded' },
	}),
	connect(
		null,
		(
			dispatch: Dispatch<RefreshIssueRequestAction | IssueViewMountedAction>,
			ownProps: OwnProps,
		) => ({
			onIssueViewDataLoaded: (analyticsEvent: UIAnalyticsEvent) => {
				const ratio = getSidebarRatio(
					getLayoutStyle(ownProps.isModal, ownProps.issueMaxWidth),
					ownProps.sidebarRatios,
				);

				trackLoadedIssueView(analyticsEvent, ratio);
			},
		}),
	),
	connect(
		(state: ReduxState) => ({
			isLoading: isLoadingSelector(state),
			doNotUseIsLoadingPromise: doNotUseIsLoadingPromiseSelector(state),
			isPreview: isPreviewSelector(state),
			// note: altering isComplete has to be reflected at jira/src/packages/issue/issue-view-ecosystem/src/ecosystem-background-scripts-view.tsx:PreventUnstableRender
			isComplete: isCompletedLoadingSelector(state),
			isModal: isModalSelector(state),
			error: errorSelector(state),
			errorMessage: errorMessageSelector(state),
			issueKey: issueKeySelector(state),
			isServiceDesk: isServiceDeskSelector(state),
			projectType: projectTypeSelector(state),
			worklogModal: openModalSelector(state),
			sidebarRatios: issueSidebarRatiosSelector(state),
			projectKey: projectKeySelector(state),
			isSimplifiedProject: isSimplifiedProjectSelector(state),
			uiModificationsModules: forgeUiModificationsSelector(state),
		}),
		(dispatch: Dispatch<RefreshIssueRequestAction | IssueViewMountedAction>) => ({
			onRefresh: (
				event?: CustomEvent<[string, RefreshIssueRequestPayload & { mergeIntoCurrent: boolean }]>,
			) => {
				if (event) {
					const [_, payload] = event.detail ?? [];
					dispatch(refreshIssueRequest({ ...payload }));
				} else {
					dispatch(refreshIssueRequest());
				}
			},
			onMount: () => {
				dispatch(issueViewMounted());
			},
		}),
	),
	withMetrics(),
	ReduxAnalyticsData((state, { analyticsAttributesFactory }): AnalyticsDataPayload => {
		const analyticsData: AnalyticsPayload = analyticsPayloadSelector(state);
		const analyticsAttributes = analyticsAttributesFactory?.() ?? {};
		// We need this data in the issue view analytics event because
		// it will be used to decide whether to display EP onboarding
		// for users with edit security level permissions.
		const issueSecurityData: Readonly<{
			[key: string]: unknown;
		}> = securityAnalyticsSelector(state);

		const attributes: Readonly<{
			[key: string]: unknown;
		}> = {
			...analyticsData.attributes,
			...analyticsAttributes,
			...issueSecurityData,
			issueHierarchyLevel: hierarchyLevelSelector(state),
			synthetic: isSynthetic(),
		};

		return {
			...analyticsData,
			attributes,
		};
	}),
	withAnalyticsAttributes(),
)(withCompactMode(IssueViewReadyTracker));

const IssueViewWithHooks = (props: React.ComponentProps<typeof IssueViewClassComponent>) => {
	const [isForgeDataComplete] = useForgeDataComplete();
	const [, actions] = useDevOpsAppRecommendationsStore();
	const { incrementIssueMountCount } = useIssueMountCounterActions();
	const clearRecommendationAndOptOutPanel = () => {
		actions.clearRecommendation();
		actions.clearOptOutPanel();
	};

	return (
		<IssueViewClassComponent
			{...props}
			isForgeDataComplete={isForgeDataComplete}
			clearRecommendation={
				fg('link_paste_recommendations') ? clearRecommendationAndOptOutPanel : null
			}
			incrementIssueMountCount={incrementIssueMountCount}
		/>
	);
};

export default IssueViewWithHooks;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const IssueBodyContainer = styled.div({
	height: '100%',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @typescript-eslint/no-explicit-any
const IssueBodyContainerDropzone = styled.div<{ attachmentHover: any }>({
	position: 'absolute',
	height: '100%',
	boxSizing: 'border-box',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: (props) => (props.attachmentHover ? '2px dashed #2684FF' : '2px dashed transparent'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) => (props.attachmentHover ? '#deebff' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	mixBlendMode: (props) => (props.attachmentHover ? 'normal' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.attachmentHover ? '0.54' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: (props) => (props.attachmentHover ? '100' : undefined),
});
