import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	crossflowIdeaSummary: {
		id: 'issue-view-jpd-ideas.crossflow-ideas-summary',
		defaultMessage: 'Supporting idea with customer insights',
		description:
			'An invitation to link an issue to a JPD idea, displayed in an issue view smart link',
	},
	crossflowIdeaTooltip: {
		id: 'issue-view-jpd-ideas.crossflow-ideas-tooltip',
		defaultMessage: 'Create and link an idea to this issue',
		description: 'An invitation to link an issue to a JPD idea, displayed in a tooltip',
	},
	tryLozenge: {
		id: 'issue-view-jpd-ideas.crossflow-ideas-try-lozenge',
		defaultMessage: 'Try',
		description: 'An lozenge indicating that the user should try JPD',
	},
	privacyPolicy: {
		id: 'issue-view-jpd-ideas.generic-templates.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
	dismissFlagTitle: {
		id: 'issue-view-jpd-ideas.dismiss-flag-title',
		defaultMessage: 'Thanks for letting us know',
		description: 'Title of the flag that shows up when a user dismisses the placeholder template',
	},
	dismissFlagDescription: {
		id: 'issue-view-jpd-ideas.dismiss-flag-description',
		defaultMessage: "You won't be seeing this here anymore",
		description:
			'Description of the flag that shows up when a user dismisses the placeholder template',
	},
	closeIdeasTitle: {
		id: 'issue-view-jpd-ideas.close-title',
		defaultMessage: 'Hide section',
		description: 'Toole tip title for the close button',
	},
	reasonsModalTitle: {
		id: 'issue-view-jpd-ideas.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description: 'Title of the reasons modal',
	},
	reasonsModalExplanation: {
		id: 'issue-view-jpd-ideas.reasons-modal-explanation',
		defaultMessage:
			"We think <strong>Jira Product Discovery</strong> will improve your team's productivity. The recommendation is based on:",
		description: 'Explanation as to why the placeholder template is being shown',
	},
	reasonsModalBulletOne: {
		id: 'issue-view-jpd-ideas.reasons-modal-bullet-one',
		defaultMessage: 'the size of your team',
		description: 'First bullet point of the reasons modal',
	},
	reasonsModalBulletTwo: {
		id: 'issue-view-jpd-ideas.reasons-modal-bullet-two',
		defaultMessage: 'your existing Atlassian products',
		description: 'Second bullet point of the reasons modal',
	},
	ideasDismissLabel: {
		id: 'issue-view-jpd-ideas.dismiss-idea-card',
		defaultMessage: 'Dismiss Idea Card',
		description: 'Label for the dismiss idea card button',
	},
});
