import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import uniq from 'lodash/uniq';
import { Observable } from 'rxjs/Observable';
import {
	PREFETCH_ISSUES,
	type PrefetchIssuesAction,
} from '@atlassian/jira-issue-view-store/src/actions/prefetch-issues-actions.tsx';
import { getDataProvider } from '@atlassian/jira-providers-issue/src/service/index.tsx';

type ReduxAction = {
	readonly type: string;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<ReduxAction>) =>
	action$
		.ofType(PREFETCH_ISSUES)
		.do((action: PrefetchIssuesAction) => {
			const { issueKeys, issueIds } = action.payload;
			const DataProvider = getDataProvider();

			DataProvider.loadIssues(uniq(issueKeys));
			DataProvider.loadIssuesById(uniq(issueIds));
		})
		.switchMap(() => Observable.empty<never>());
