export type ColorOptionData = {
	name: string;
	color: string;
};
export type OptionsInput = {
	color?: string | null;
	optionId?: string | number | null;
	value: string;
};
export const ACTION: Record<string, OperationType> = {
	ADD: 'add',
	DELETE: 'delete',
	UPDATE: 'update',
};
export type OperationType = 'add' | 'delete' | 'update';
export type ProjectType = 'software' | 'business';
export type ColorPaletteVariant = 'new' | 'old';
export type ColorCardVariant = 'fill' | 'outline';

export type ColorPickerStateType = undefined | 'edit' | 'create';
