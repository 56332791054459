import { v4 } from 'uuid';
import { setActiveTrace, clearActiveTrace } from '@atlaskit/react-ufo/experience-trace-id-context';

export const setTraceId = (type?: string) => {
	const traceId = v4().replace(/-/g, '');
	const spanId = v4().replace(/-/g, '').slice(0, 16);

	setActiveTrace(traceId, spanId, type || 'jcs-boysenberry');
	setTimeout(clearActiveTrace, 200);
	return traceId;
};
