import React, { useMemo } from 'react';
import { Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import ErrorIcon from '@atlaskit/icon/core/error';
import { token } from '@atlaskit/tokens';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { RoundedSection } from '../../common/ui/rounded-section/index.tsx';
import { PlaybookPanelMessages } from './messages.tsx';
import { playbooksPanelBodyEntryPoint } from './playbook-panel-body/entrypoint.tsx';

type PlaybooksPanelProps = {
	issueId: string;
	cloudId: string;
	projectKey: string;
};

export const PlaybooksPanel = ({ issueId, cloudId, projectKey }: PlaybooksPanelProps) => {
	const { formatMessage } = useIntl();

	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		playbooksPanelBodyEntryPoint,
		{
			issueId,
			cloudId,
			projectKey,
		},
	);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);
	const runtimeProps = useMemo(() => ({}), []);

	return (
		<RoundedSection
			triggerRef={triggerRef}
			header={
				<Stack space="space.050">
					<Inline space="space.050" alignBlock="center">
						<Text weight="bold">{formatMessage(PlaybookPanelMessages.playbooks)}</Text>
						<Lozenge appearance="new">{formatMessage(PlaybookPanelMessages.new)}</Lozenge>
					</Inline>
					<Text color="color.text.subtlest">
						{formatMessage(PlaybookPanelMessages.description)}
					</Text>
				</Stack>
			}
		>
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="PlaybooksPanelBody"
				packageName="jiraPlaybooksAgentView"
				teamName="bifrost"
				runtimeProps={runtimeProps}
				fallback={
					<Stack space="space.150">
						<SkeletonCompiled width="100%" height="32px" />
						<SkeletonCompiled width="100%" height="32px" />
					</Stack>
				}
				errorFallback={() => (
					<Inline space="space.100" xcss={errorViewStyles} alignBlock="center">
						<ErrorIcon label="" color={token('color.icon.accent.red')} />
						<Text color="color.text.danger">
							{formatMessage(PlaybookPanelMessages.errorViewDescription)}
						</Text>
					</Inline>
				)}
			/>
		</RoundedSection>
	);
};

const errorViewStyles = xcss({
	paddingTop: 'space.100',
});
