import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	optionNameLabel: {
		id: 'business-categories.option-form.option-name-label',
		defaultMessage: 'Option name',
		description: 'Accessibility label for option name input',
	},
	optionNamePlaceholder: {
		id: 'business-categories.option-form.option-name-placeholder',
		defaultMessage: 'New option',
		description: 'Placeholder text for option name input',
	},
	cancelButton: {
		id: 'business-categories.option-form.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel button text',
	},
	saveButton: {
		id: 'business-categories.option-form.save-button',
		defaultMessage: 'Save',
		description: 'Save button text',
	},
	addButton: {
		id: 'business-categories.option-form.add-button',
		defaultMessage: 'Add',
		description: 'Add button text',
	},
	errorMessageBlankOptionName: {
		id: 'business-categories.option-form.error-message-blank-option-name',
		defaultMessage: 'Option name cannot be blank',
		description: 'Error message for blank option name',
	},
	editOptionTitle: {
		id: 'business-categories.option-form.edit-option-title',
		defaultMessage: 'Edit option',
		description: 'Title for editing option',
	},
	newOptionTitle: {
		id: 'business-categories.option-form.new-option-title',
		defaultMessage: 'New option',
		description: 'Title for adding option',
	},
});
