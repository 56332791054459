import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	disabledWarningMessage: {
		id: 'share-button.pre-share-view.warning-content.disabled-warning-message',
		defaultMessage: 'Outgoing mail is not configured.',
		description: 'Warning message is outgoing mail is disabled',
	},
	adminEnableOutgoingMail: {
		id: 'share-button.pre-share-view.warning-content.admin-enable-outgoing-mail',
		defaultMessage: 'You can configure it here',
		description: 'Message to enable outgoing mail',
	},
	outgoingMail: {
		id: 'share-button.pre-share-view.warning-content.outgoing-mail',
		defaultMessage: 'Outgoing Mail',
		description: 'Link text Outgoing mail',
	},
	nonAdminWarningMessage: {
		id: 'share-button.pre-share-view.warning-content.non-admin-warning-message',
		defaultMessage: 'Please contact your Jira administrator to enable sharing.',
		description: 'Message for non-admin users to request enable sharing',
	},
	disabledWarningMessageIfHipaa: {
		id: 'share-button.pre-share-view.warning-content.disabled-warning-message-if-hipaa',
		defaultMessage:
			'You cannot share issue as your organization has signed up for HIPAA compliance',
		description: 'Warning message is tenant is HIPAA',
	},
	disabledWarningMessageIfHipaaIssueTermRefresh: {
		id: 'share-button.pre-share-view.warning-content.disabled-warning-message-if-hipaa-issue-term-refresh',
		defaultMessage:
			"You can't share work item as your organization has signed up for HIPAA compliance",
		description: 'Warning message is tenant is HIPAA',
	},
	outgoingMailSettingsLink: {
		id: 'share-button.pre-share-view.warning-content.enable-outgoing-mail-link',
		defaultMessage: 'Set up email sharing',
		description: 'Text for a link to outgoing mail settings',
	},
});
