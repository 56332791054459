/**
 * @generated SignedSource<<0d401699a4224a9fc64fc8e8b628f528>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutDateTimeField_IssueViewDateTimeField$data = {
  readonly __typename: "JiraDateTimePickerField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewNew_fragmentRef" | "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutDateTimeField_IssueViewDateTimeField";
};
export type ui_issueViewLayoutDateTimeField_IssueViewDateTimeField$key = {
  readonly " $data"?: ui_issueViewLayoutDateTimeField_IssueViewDateTimeField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutDateTimeField_IssueViewDateTimeField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutDateTimeField_IssueViewDateTimeField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewOld_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewNew_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "f1a3289cb5623de727a306e0a9a578f0";

export default node;
