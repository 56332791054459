import { metrics } from '@atlassian/browser-metrics';
import type { StopTTICustomData } from '@atlassian/ui-modifications-analytics/types';

const uimInIssueViewTTI = metrics.interaction({
	key: 'ui-modifications-in-issue-view',
	timings: [
		{ key: 'issue-view-tti-delta', startMark: 'issue-view-tti' },
		{ key: 'script-execution', startMark: 'iframe-load' },
		{ key: 'loading-indicators-visibility', startMark: 'fields-registered' },
		{ key: 'time-until-oninit-resolved', endMark: 'oninit-resolved' },
	],
});

export const startUimTti = () => {
	uimInIssueViewTTI.start();
};

export const markUimTtiIssueViewLoaded = () => {
	uimInIssueViewTTI.mark('issue-view-tti');
};

export const stopTTI = (customData: StopTTICustomData) => {
	uimInIssueViewTTI.stop({
		customData,
	});
};

export const cancelTTI = () => {
	uimInIssueViewTTI.cancel();
};

export const markIframeLoad = () => {
	uimInIssueViewTTI.mark('iframe-load');
};

export const markFieldsRegistered = () => {
	uimInIssueViewTTI.mark('fields-registered');
};

export const markOnInitResolved = () => {
	uimInIssueViewTTI.mark('oninit-resolved');
};
