import { fg } from '@atlassian/jira-feature-gating';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import {
	getFieldType,
	isRichTextSupported,
} from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import type { FieldChangedEvent } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import {
	ASSIGNEE_TYPE,
	PRIORITY_TYPE,
	NUMBER_CF_TYPE,
	SPRINT_TYPE,
	TEXT_GQL_FIELD,
	PARENT_TYPE,
	DATE_CF_TYPE,
	STATUS_TYPE,
	DATETIME_CF_TYPE,
	PROJECT_PICKER_CF_TYPE,
	URL_CF_TYPE,
	STORY_POINTS_TYPE,
	REPORTER_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SECURITY_LEVEL_TYPE,
	SECURITY_LEVEL_TYPE_OVERRIDE,
	SUMMARY_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	USER_CF_TYPE,
	TIME_TRACKING_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	PEOPLE_CF_TYPE,
	COLOR_CF_TYPE,
	VOTES_TYPE,
	EPIC_COLOR_TYPE,
	COMPONENTS_TYPE,
	APPROVERS_LIST_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	WATCHES_TYPE,
	MULTI_USER_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	ISSUE_TYPE,
	RESOLUTION_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	ENVIRONMENT_TYPE,
	DESCRIPTION_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	UI_MODIFICATIONS_CHANGE,
	type changeSource,
} from '@atlassian/jira-ui-modifications-view-issue-view/src/common/constants.tsx';

export const getSoftRefreshSupportedFields = (): string[] => {
	const fields: string[] = [STATUS_TYPE];
	if (fg('relay-migration-issue-fields-assignee-fg')) {
		fields.push(ASSIGNEE_TYPE);
	}
	if (fg('relay-migration-issue-fields-reporter')) {
		fields.push(REPORTER_TYPE);
	}
	if (fg('relay-migration-issue-fields-user-fg')) {
		fields.push(USER_CF_TYPE);
	}
	if (fg('relay-migration-issue-fields-priority')) {
		fields.push(PRIORITY_TYPE);
	}

	if (fg('relay-migration-issue-fields-number')) {
		fields.push(NUMBER_CF_TYPE, STORY_POINT_ESTIMATE_CF_TYPE, STORY_POINTS_TYPE);
	}

	if (fg('relay-migration-issue-fields-sprint')) {
		fields.push(SPRINT_TYPE);
	}

	if (fg('relay-migration-issue-fields-single-line-text-fg')) {
		fields.push(TEXT_GQL_FIELD);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(PARENT_TYPE);
	}

	if (fg('relay-migration-issue-fields-date-sg')) {
		fields.push(DATE_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-project-select')) {
		fields.push(PROJECT_PICKER_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-date-time-fg')) {
		fields.push(DATETIME_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-url')) {
		fields.push(URL_CF_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(SECURITY_LEVEL_TYPE, SECURITY_LEVEL_TYPE_OVERRIDE);
	}

	if (fg('relay-migration-issue-fields-summary')) {
		fields.push(SUMMARY_TYPE);
	}

	if (fg('relay-migration-issue-fields-single-line-text-msg')) {
		fields.push(MESSAGE_VIEW_CF_TYPE, MESSAGE_EDIT_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-time-tracking')) {
		fields.push(TIME_TRACKING_TYPE);
	}

	if (fg('relay-migration-issue-fields-checkbox-select')) {
		fields.push(MULTI_CHECKBOXES_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-people-fg')) {
		fields.push(PEOPLE_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-approvers-fg')) {
		fields.push(APPROVERS_LIST_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-color')) {
		fields.push(COLOR_CF_TYPE, EPIC_COLOR_TYPE);
	}

	if (fg('relay-migration-issue-fields-components-select')) {
		fields.push(COMPONENTS_TYPE);
	}

	if (fg('relay-migration-issue-fields-original-estimate')) {
		fields.push(TIME_ESTIMATE_TYPE);
	}

	if (fg('relay-migration-issue-fields-team-field-next')) {
		fields.push(TEAMS_PLATFORM_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-user-fg')) {
		fields.push(MULTI_USER_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-user-fg')) {
		fields.push(PARTICIPANTS_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-user-fg')) {
		fields.push(REQUEST_PARTICIPANTS_CF_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(ISSUE_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(WATCHES_TYPE);
	}

	if (fg('relay-migration-issue-fields-resolution')) {
		fields.push(RESOLUTION_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-group-picker-fg')) {
		fields.push(MULTI_GROUP_CF_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(VOTES_TYPE);
	}

	if (fg('relay-migration-issue-fields-group-picker-sg')) {
		fields.push(GROUP_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-status')) {
		fields.push(STATUS_TYPE);
	}

	if (fg('relay-migration-issue-fields-radio')) {
		fields.push(RADIO_BUTTONS_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-line-text-fg')) {
		fields.push(TEXT_AREA_CF_TYPE, ENVIRONMENT_TYPE, DESCRIPTION_TYPE);
	}

	return fields;
};

// We may want to skip soft refresh when it is redundant, due to other state syncing mechanisms.
export const shouldSkipSoftRefresh = (
	event: FieldChangedEvent,
	field?: Field | FieldConfiguration<unknown> | null,
): boolean => {
	const multilineTextFieldTypes: string[] = [TEXT_AREA_CF_TYPE, ENVIRONMENT_TYPE, DESCRIPTION_TYPE];
	const fieldType = getFieldType(field);

	const eventSourceIsUIM =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(event.meta.additionalProperties as typeof changeSource)?.source === UI_MODIFICATIONS_CHANGE;
	const shouldSkipMultilineTextField =
		fieldType && multilineTextFieldTypes.includes(fieldType) && isRichTextSupported(field);

	if (
		// UIM allows for fields to be updated and these are done via REST calls. We want to trigger soft refresh so that the Relay store is updated.
		!eventSourceIsUIM &&
		// We don't trigger soft refresh for the multi-line rich text field as this field updates the Redux and sweet state stores itself.
		// See: onConfirm in jira/src/packages/issue/issue-view-common-views/src/connect-field/connect-field.tsx
		shouldSkipMultilineTextField &&
		fg('relay-migration-issue-fields-multi-line-text-fg')
	) {
		return true;
	}
	return false;
};
