import React, {
	type MouseEvent,
	type SyntheticEvent,
	useCallback,
	useMemo,
	type ReactNode,
} from 'react';
import { SmartCardProvider as Provider } from '@atlaskit/link-provider';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { toFlagId, useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useEmbeddedPageTracking } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-page-tracking/index.tsx';
import { useWhiteboardIssueLinking } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-whiteboard-issue-linking/index.tsx';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari/index.tsx';
import type { ConfluenceWhiteboard } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { deleteConfluenceWhiteboardLinkSuccess } from '@atlassian/jira-issue-view-store/src/actions/confluence-whiteboards-actions.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import type {
	ConfluenceWhiteboardId,
	RefObject,
	RemoteLinkGlobalId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import SmartLinkCard from '../../../smart-link-content/index.tsx';
import { JiraSmartLinkLocations } from '../../../smart-link-content/smart-link-analytics.tsx';
import { getContentWithHostname, shouldLoadConfluenceInNewTab } from '../../utils.tsx';
import { confirmationModalEntryPoint } from '../confirmation-modal/entrypoint.tsx';
import messages from './messages.tsx';

export type Props = {
	groupId: string;
	whiteboards: ConfluenceWhiteboard[];
	systemConfluenceAppLinkUrl?: string | undefined;
	onClick?: (
		event: MouseEvent<HTMLElement, globalThis.MouseEvent> | SyntheticEvent<Element, Event>,
	) => void;
	onDeleteConfluenceWhiteboardLinkSuccess: (id: ConfluenceWhiteboardId) => void;
};

export const EntryPointWrapper = ({
	children,
	linkId,
	onDeleteConfluenceWhiteboard,
	title,
	description,
}: {
	children: ReactNode;
	linkId: RemoteLinkGlobalId;
	onDeleteConfluenceWhiteboard: (id: RemoteLinkGlobalId) => void;
	title: string;
	description: string;
}) => {
	const entryPointProps = useMemo(
		() => ({
			onDelete: (linkToDeleteId: RemoteLinkGlobalId) => {
				onDeleteConfluenceWhiteboard(linkToDeleteId);
			},
			linkId,
			title,
			description,
		}),
		[onDeleteConfluenceWhiteboard, linkId, title, description],
	);
	return (
		<ModalEntryPointPressableTrigger
			entryPoint={confirmationModalEntryPoint}
			entryPointProps={entryPointProps}
			interactionName="delete-confluence-whiteboard-line-card-modal"
			useInternalModal={false}
		>
			{({ ref }) => (
				<div
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as RefObject<HTMLDivElement>}
				>
					{children}
				</div>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export const ConfluenceWhiteboardLineCardGroupComponent = ({
	whiteboards,
	systemConfluenceAppLinkUrl,
	onClick,
	onDeleteConfluenceWhiteboardLinkSuccess,
}: Props) => {
	const trackEmbeddedConfluence = useEmbeddedPageTracking();
	const projectContext = useProjectContext();
	const issueAri = useIssueAri();
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const { unlinkIssueFromWhiteboard } = useWhiteboardIssueLinking({ issueAri });

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const currentHostname = window?.location?.hostname || '';

	const onDeleteConfluenceWhiteboard = useCallback(
		async (id: ConfluenceWhiteboardId) => {
			const embeddedConfluenceSource = 'issueViewLinkedWhiteboards';
			try {
				await unlinkIssueFromWhiteboard(id, undefined);
				trackEmbeddedConfluence({
					action: 'success',
					eventName: 'deleteWhiteboardLink',
					projectType: projectContext.data?.projectType,
					eventType: 'operational',
					embeddedConfluenceSource,
				});
				onDeleteConfluenceWhiteboardLinkSuccess(id);
			} catch (error) {
				if (error instanceof Error && error.message?.includes('permission')) {
					showFlag({
						key: toFlagId('deleteConfluenceWhiteboardLinkPermissionFailed'),
						type: 'error',
						title: formatMessage(messages.deleteConfluenceWhiteboardLinkFailedTitle),
						description: formatMessage(
							messages.deleteConfluenceWhiteboardLinkPermissionFailedDescription,
						),
					});
				} else {
					fireErrorAnalytics({
						meta: {
							id: 'deleteWhiteboardLink',
							teamName: 'bento',
						},
						attributes: {
							embeddedConfluenceSource,
							projectType: projectContext.data?.projectType,
						},
						error: new Error(
							`Failed to delete Confluence whiteboard link, received error: ${error}`,
						),
					});
					showFlag({
						key: toFlagId('deleteConfluenceWhiteboardLinkFailed'),
						type: 'error',
						title: formatMessage(messages.deleteConfluenceWhiteboardLinkFailedTitle),
						description: formatMessage(messages.deleteConfluenceWhiteboardLinkFailedDescription),
					});
				}
			}
		},
		[
			formatMessage,
			onDeleteConfluenceWhiteboardLinkSuccess,
			showFlag,
			unlinkIssueFromWhiteboard,
			projectContext.data?.projectType,
			trackEmbeddedConfluence,
		],
	);

	const onClickConfluenceWhiteboard = useCallback(
		(event: MouseEvent<HTMLElement, globalThis.MouseEvent> | SyntheticEvent<Element, Event>) => {
			trackEmbeddedConfluence({
				action: 'clicked',
				eventName: 'openLinkedConfluenceWhiteboard',
				projectType: projectContext.data?.projectType,
			});
			return onClick?.(event);
		},
		[trackEmbeddedConfluence, projectContext.data?.projectType, onClick],
	);

	const getWhiteboardWithHostname = useCallback(
		(whiteboard: ConfluenceWhiteboard) =>
			getContentWithHostname({
				content: whiteboard,
				systemConfluenceAppLinkUrl,
				currentHostname,
			}),
		[currentHostname, systemConfluenceAppLinkUrl],
	);

	const loadConfluenceInNewTab = useCallback(
		(whiteboard: ConfluenceWhiteboard) =>
			shouldLoadConfluenceInNewTab({
				content: whiteboard,
				systemConfluenceAppLinkUrl,
				currentHostname,
			}),
		[currentHostname, systemConfluenceAppLinkUrl],
	);

	return (
		<>
			<Provider>
				{whiteboards.map((whiteboard) => (
					<SmartLinkCard
						key={whiteboard.id}
						analyticsLocation={JiraSmartLinkLocations.CONFLUENCE_WHITEBOARDS}
						url={whiteboard.href}
						onClick={onClickConfluenceWhiteboard}
						canDelete
						text={whiteboard.title || ''}
						linkId={whiteboard.id}
						smartLinkId={whiteboard.id}
						whiteboard={getWhiteboardWithHostname(whiteboard)}
						loadConfluenceInNewTab={loadConfluenceInNewTab(whiteboard)}
						entryPointWrapper={(p) => (
							<EntryPointWrapper
								{...p}
								onDeleteConfluenceWhiteboard={onDeleteConfluenceWhiteboard}
								linkId={whiteboard.id}
								title={formatMessage(messages.deleteConfirmationModalTitle)}
								description={formatMessage(messages.deleteConfirmationModalDescription)}
							/>
						)}
					/>
				))}
			</Provider>
		</>
	);
};

export const ConfluenceWhiteboardLineCardGroup = connect(null, {
	onDeleteConfluenceWhiteboardLinkSuccess: deleteConfluenceWhiteboardLinkSuccess,
})(ConfluenceWhiteboardLineCardGroupComponent);
