import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import AKUserPicker, { type OptionData, type Value } from '@atlaskit/user-picker';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AccountId, IssueKey, RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FeatureFlagValue } from '@atlassian/jira-feature-flagging-using-meta';
import fetchUsersWithViewPermissions from '../../../../rest/fetch-users-with-view-permissions/index.tsx';
import { useFetchUsersForWatchersField } from './fetch-users-for-watchers-field/index.tsx';
import messages from './messages.tsx';

export type Props = {
	issueKey: IssueKey;
	watchers: User[];
	menuPortalTarget?: RefObject<HTMLElement> | null;
	onAddWatcher: (arg1: User, arg2: (accountId: AccountId | null) => void, arg3: () => void) => void;
};

type UserPickerUser = {
	id: string | undefined | null;
	avatarUrl: string | undefined | null;
	name: string;
	userObject: User;
};

/**
 * AK's "name" is equivalent to JFE's "displayName", so we need to transform the object.
 * We also need a reference to the "original" JFE User object, so we can use it in the
 * "onUserSelect" function, so this is stored in the "userObject" property.
 */
const userPickerTransformer = (user: User): UserPickerUser => ({
	id: user.id,
	avatarUrl: user.avatarUrl,
	name: user.displayName,
	userObject: user,
});

// Visibile for Visreg testing
export const useIsOpen = () => useState(false);
export const useUsers = () => useState<UserPickerUser[]>([]);

export const UserPicker = ({ issueKey, watchers, menuPortalTarget, onAddWatcher }: Props) => {
	const [open, setOpen] = useIsOpen();
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useUsers();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const onSuccess = useCallback(
		(accountId: AccountId | null) => {
			sendExperienceAnalytics({
				wasExperienceSuccesful: true,
				action: TASK_SUCCESS,
				analyticsSource: 'button',
				experience: 'addUserAsWatcher',
				application: application ?? null,
				edition: edition ?? null,
			});

			if (fg('one-event-rule-all-watcher-analytics')) {
				getUpdateAnalyticsFlowHelper().fireAnalyticsEnd('watchers', {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: 'watchers',
						actionTaken: 'add',
						oldValId: '',
						newValId: accountId,
					},
				});
			}
		},
		[application, createAnalyticsEvent, edition],
	);

	const onError = useCallback(
		(error?: Error | FetchError) => {
			const additionalAttributes: Record<
				string,
				string | boolean | number | Record<string, FeatureFlagValue>
			> = {};

			if (error?.message && fg('thor_add_missing_attributes_across_issue_view_1')) {
				additionalAttributes.errorMessage = error.message;
			}

			if (error instanceof FetchError && fg('thor_add_missing_attributes_across_issue_view_1')) {
				if (error?.traceId) additionalAttributes.traceId = error.traceId;
				if (error?.statusCode) additionalAttributes.statusCode = error.statusCode;
			}
			sendExperienceAnalytics({
				wasExperienceSuccesful: false,
				action: TASK_FAIL,
				analyticsSource: 'button',
				experience: 'addUserAsWatcher',
				application: application ?? null,
				edition: edition ?? null,
				...(fg('thor_add_missing_attributes_across_issue_view_1') && {
					additionalAttributes,
				}),
			});
		},
		[application, edition],
	);

	const resetState = useCallback(() => {
		setOpen(false);
		setLoading(false);
		setUsers([]);
	}, [setOpen, setUsers]);

	const { formatMessage } = useIntl();

	const autoFocusOnSelect = useRef<boolean>(false);

	const abortController = fg('relay-migration-issue-header-and-parent')
		? undefined
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useRef<AbortController>(new AbortController());

	const userPickerRef = useRef<HTMLElement | null>(null);

	const userInputElement = useRef<HTMLInputElement | null>(null);

	const isUserNotWatcher = useCallback(
		(user: User) => !watchers.find((watcher) => watcher.id === user.id),
		[watchers],
	);

	const width = 220;
	const menuPortalTargetCurrent =
		menuPortalTarget && menuPortalTarget.current ? menuPortalTarget.current : undefined;

	const { fetchUsers } = useFetchUsersForWatchersField();
	const onSearchUsersDebounce = useMemo(
		() =>
			debounce(
				(
					query: string | null | undefined,
					searchedIssueKey: IssueKey,
					searchedIsUserNotWatcher: (user: User) => boolean,
				) => {
					if (query === undefined || query === null || query === '') {
						resetState();
					} else {
						setLoading(true);
						setOpen(true);
						if (fg('relay-migration-issue-header-and-parent')) {
							fetchUsers({
								issueKey: searchedIssueKey,
								query,
							}).then((userList) => {
								setLoading(false);
								setUsers(userList.filter(searchedIsUserNotWatcher).map(userPickerTransformer));
							});
						} else {
							const signal = abortController?.current?.signal;

							fetchUsersWithViewPermissions({
								issueKey: searchedIssueKey,
								query,
								signal,
							}).then((userList) => {
								setLoading(false);
								setUsers(userList.filter(searchedIsUserNotWatcher).map(userPickerTransformer));
							});
						}
					}
				},
				300,
			),
		[abortController, fetchUsers, resetState, setOpen, setUsers],
	);

	const onSearchUsers = useCallback(
		(query?: string) => {
			onSearchUsersDebounce(query, issueKey, isUserNotWatcher);
		},
		[isUserNotWatcher, issueKey, onSearchUsersDebounce],
	);

	// component did mount
	useEffect(() => {
		if (userPickerRef && userPickerRef.current) {
			const userPickerNode = ReactDOM.findDOMNode(userPickerRef.current); // eslint-disable-line react/no-find-dom-node
			if (userPickerNode instanceof HTMLElement) {
				userInputElement.current = userPickerNode.querySelector('input');
			}
		}

		const currentAbortController = abortController?.current;
		// component will unmount
		return () => {
			userInputElement.current = null;
			if (!fg('relay-migration-issue-header-and-parent')) {
				currentAbortController?.abort?.();
			}
			onSearchUsersDebounce.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// component did update
	useEffect(() => {
		if (userInputElement.current && autoFocusOnSelect.current) {
			userInputElement.current.focus();
			autoFocusOnSelect.current = false;
		}
	}, []);

	const onUserSelect = (user: Value) => {
		resetState();
		// set to auto focus on input if a user was selected.
		autoFocusOnSelect.current = true;
		// FIXME: user can array
		// consider if (user && !Array.isArray(user)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		onAddWatcher((user as UserPickerUser).userObject, onSuccess, onError);
	};

	const onNoOptionsMessage = useCallback(
		({ inputValue }: { inputValue: string }) => {
			if (!inputValue) {
				return null;
			}
			return formatMessage(messages.noUsersFound);
		},
		[formatMessage],
	);

	return (
		<Container width={width}>
			<AKUserPicker
				autoFocus
				fieldId="watchers"
				isLoading={loading}
				noOptionsMessage={onNoOptionsMessage}
				open={open}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				options={users as OptionData[]}
				onBlur={resetState}
				onChange={onUserSelect}
				onInputChange={onSearchUsers}
				placeholder={formatMessage(messages.placeholder)}
				value={null}
				appearance="compact"
				subtle
				width={width}
				ref={userPickerRef}
				menuPortalTarget={menuPortalTargetCurrent}
			/>
		</Container>
	);
};

export default UserPicker;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${props.width}px`,
	margin: 'auto',
});
