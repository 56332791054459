import React from 'react';
import {
	FULL_ISSUE,
	ISSUE_NAVIGATOR,
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
	type AnalyticsSource as AnalyticsSourceType,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';

type RequiredProps = {
	location: AnalyticsSourceType;
};

export const ExtractedDocumentTitle = ({ location }: RequiredProps) => {
	const issueKey = useIssueKey();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = fg('relay-migration-issue-fields-summary') ? useIssueId() : undefined;
	const [{ value }] = useSummaryField({ issueKey, issueId });
	if (
		location !== FULL_ISSUE &&
		location !== SERVICEDESK_QUEUES_ISSUE &&
		location !== SERVICEDESK_REPORTS_ISSUE &&
		location !== ISSUE_NAVIGATOR
	) {
		return null;
	}
	if (value === null || value === undefined) {
		return null;
	}
	return <DocumentTitle title={`[${issueKey}] ${value}`} />;
};

export default ExtractedDocumentTitle;
