/**
 * @generated SignedSource<<4c9cba42067579c42fd758f266a34497>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly originalEstimate: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly timeTrackingSettings: {
    readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView">;
  readonly " $fragmentType": "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef";
};
export type originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef$key = {
  readonly " $data"?: originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "concreteType": "JiraEstimate",
      "kind": "LinkedField",
      "name": "originalEstimate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "timeInSeconds"
        }
      ]
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraTimeTrackingSettings",
      "kind": "LinkedField",
      "name": "timeTrackingSettings",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView"
        }
      ]
    }
  ],
  "type": "JiraTimeTrackingField"
};

(node as any).hash = "32b30c5826b27ef620db3c90ee6d0b20";

export default node;
