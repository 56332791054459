/* eslint-disable @atlassian/relay/unused-fields */
import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import OriginTracer from '@atlassiansox/origin-tracing';
import {
	graphql,
	usePreloadedQuery,
	useQueryLoader,
	useFragment,
	type PreloadedQuery,
} from 'react-relay';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { extractCanUpgradeAndPayDetails } from '@atlassian/jira-billing/src/controllers/can-upgrade-and-pay/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getProductOfferingKeyOrThrow } from '@atlassian/jira-growth-utils/src/services/get-product-offering-key/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsJiraSoftwareFree } from '@atlassian/jira-jsw-feature-gates/src/ui/index.tsx';
import { useIsProductUsingCcp } from '@atlassian/jira-billing/src/controllers/billing-system/use-is-product-using-ccp.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import Query, {
	type ui_storageLimitsBanner_canUpgradeAndPayQuery,
} from '@atlassian/jira-relay/src/__generated__/ui_storageLimitsBanner_canUpgradeAndPayQuery.graphql';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery$key } from '@atlassian/jira-relay/src/__generated__/ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery.graphql';
import { ERROR_BOUNDARY_ID, PACKAGE_NAME, STORAGE_RATIO_TO_SHOW_BANNER } from './constants.tsx';
import messages from './messages.tsx';
import { useCheckRequisitesAndFetchUpgradeLink, useGetUsedStorageData } from './utils.tsx';

export type StorageLimitsBannerProps = {
	isFullWidth?: boolean;
};

export type IssueViewStorageLimitsBannerProps = {
	isFullWidth?: boolean;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

const FREE_PLAN_STORAGE_LIMIT = 2;
const STANDARD_PLAN_STORAGE_LIMIT = 250;

function BannerContent({
	isFullWidth,
	upgradeLinkWithOrigin,
	openBillingPage,
	isUsingCcp,
	storageLimit,
	usedStorageRatio,
}: {
	isFullWidth: boolean;
	upgradeLinkWithOrigin: string | undefined;
	openBillingPage: () => void;
	isUsingCcp: boolean;
	storageLimit: number;
	usedStorageRatio: number;
}) {
	const { formatMessage } = useIntl();

	const bannerText =
		usedStorageRatio < 1
			? formatMessage(messages.storageLimits90PercentBannerText, {
					freePlanStorageLimit: storageLimit,
				})
			: formatMessage(messages.storageLimitsBannerText, {
					freePlanStorageLimit: storageLimit,
				});

	return (
		<ContextualAnalyticsData
			attributes={{
				billingSourceSystem: isUsingCcp ? 'CCP' : 'HAMS',
				usedStorage: storageLimit * usedStorageRatio,
			}}
		>
			<Box xcss={isFullWidth ? fullWidthBannerWrapperStyles : partialWidthBannerWrapperStyles}>
				<FireUiAnalytics
					action="viewed"
					actionSubject="banner"
					actionSubjectId="storageLimitsBanner"
				/>
				<BannerContainer data-testid="storage-limits-banner.ui.storage-limits-banner">
					<WarningIcon
						label=""
						primaryColor={token('color.icon.warning', colors.Y500)}
						secondaryColor={token('color.background.warning', '#FFF7D6')}
					/>
					<BannerText
						data-testid="storage-limits-banner.ui.storage-limits-banner-text"
						title={bannerText}
					>
						{bannerText}
					</BannerText>

					<BannerLink
						onClick={openBillingPage}
						href={upgradeLinkWithOrigin}
						target="_blank"
						data-testid="storage-limits-banner.ui.storage-limits-banner-link"
					>
						{formatMessage(messages.hamsLinkText, {
							standardPlanStorageLimit: STANDARD_PLAN_STORAGE_LIMIT,
						})}
					</BannerLink>
				</BannerContainer>
			</Box>
		</ContextualAnalyticsData>
	);
}

function StorageLimitsBannerComponent(props: StorageLimitsBannerProps) {
	const { isFullWidth = false } = props;
	const isUsingCcp = useIsProductUsingCcp(SOFTWARE_PROJECT);
	const {
		canView,
		upgradeLink,
		isLoading,
		error: upgradeAndPayError,
	} = useCheckRequisitesAndFetchUpgradeLink();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const origin = new OriginTracer({ product: 'jira' });
	const upgradeLinkWithOrigin = upgradeLink ? origin.addToUrl(upgradeLink) : undefined;

	const usedStorageData = useGetUsedStorageData({ skip: !canView });
	const storageLimit =
		usedStorageData?.storageLimit !== undefined
			? usedStorageData.storageLimit
			: FREE_PLAN_STORAGE_LIMIT;
	const usedStorageRatio = usedStorageData?.storageRatio;
	const enoughStorageUsed =
		usedStorageRatio !== undefined && usedStorageRatio >= STORAGE_RATIO_TO_SHOW_BANNER;

	useEffect(() => {
		if (upgradeAndPayError) {
			fireErrorAnalytics({
				error: upgradeAndPayError,
				meta: {
					id: 'canUpgradeAndPay',
					packageName: PACKAGE_NAME,
					teamName: 'growth-tako',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [upgradeAndPayError]);

	if (
		!canView ||
		!enoughStorageUsed ||
		isLoading ||
		upgradeAndPayError ||
		usedStorageData?.edition !== 'free'
	) {
		return null;
	}

	const openBillingPage = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'storageLimitsBannerLink',
		);
	};

	return (
		<BannerContent
			isFullWidth={isFullWidth}
			upgradeLinkWithOrigin={upgradeLinkWithOrigin}
			openBillingPage={openBillingPage}
			isUsingCcp={isUsingCcp}
			storageLimit={storageLimit}
			usedStorageRatio={usedStorageRatio}
		/>
	);
}

export function StorageLimitsBannerUsingMainIssueAggQuery({
	isFullWidth = false,
	rootRelayFragment,
}: IssueViewStorageLimitsBannerProps) {
	const isUsingCcp = useIsProductUsingCcp(SOFTWARE_PROJECT);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isJswFree = useIsJiraSoftwareFree();
	if (!isJswFree) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const data = useFragment<ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery$key>(
		graphql`
			fragment ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery on Query {
				tenantContexts(cloudIds: [$cloudId]) {
					entitlementInfo(hamsProductKey: $productKey) {
						entitlement {
							transactionAccount {
								experienceCapabilities {
									addPaymentMethod {
										isAvailableToUser
									}
								}
							}
							subscription {
								accountDetails {
									invoiceGroup {
										experienceCapabilities {
											configurePayment {
												isAvailableToUser
											}
										}
									}
								}
							}
							experienceCapabilities {
								changeOffering(offeringKey: $offeringKey) {
									experienceUrl
									isAvailableToUser
								}
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	if (data == null) {
		return null;
	}

	const { canUpgradeAndPay, upgradeLink } = extractCanUpgradeAndPayDetails(data);

	const origin = new OriginTracer({ product: 'jira' });
	const upgradeLinkWithOrigin = upgradeLink ? origin.addToUrl(upgradeLink) : undefined;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const usedStorageData = useGetUsedStorageData({ skip: !canUpgradeAndPay });
	const storageLimit =
		usedStorageData?.storageLimit !== undefined
			? usedStorageData.storageLimit
			: FREE_PLAN_STORAGE_LIMIT;
	const usedStorageRatio = usedStorageData?.storageRatio;
	const enoughStorageUsed =
		usedStorageRatio !== undefined && usedStorageRatio >= STORAGE_RATIO_TO_SHOW_BANNER;

	if (!canUpgradeAndPay || !enoughStorageUsed || usedStorageData?.edition !== 'free') {
		return null;
	}

	const openBillingPage = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'storageLimitsBannerLink',
		);
	};

	return (
		<JSErrorBoundary
			id={ERROR_BOUNDARY_ID}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			<BannerContent
				isFullWidth={isFullWidth}
				upgradeLinkWithOrigin={upgradeLinkWithOrigin}
				openBillingPage={openBillingPage}
				isUsingCcp={isUsingCcp}
				storageLimit={storageLimit}
				usedStorageRatio={usedStorageRatio}
			/>
		</JSErrorBoundary>
	);
}

function StorageLimitsBannerComponentWithPreloadedQuery(
	props: StorageLimitsBannerProps & {
		queryRef: PreloadedQuery<ui_storageLimitsBanner_canUpgradeAndPayQuery>;
	},
) {
	const { isFullWidth = false, queryRef } = props;
	const isUsingCcp = useIsProductUsingCcp(SOFTWARE_PROJECT);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const data = usePreloadedQuery(
		graphql`
			query ui_storageLimitsBanner_canUpgradeAndPayQuery(
				$cloudId: ID!
				$productKey: String!
				$offeringKey: ID!
			) {
				tenantContexts(cloudIds: [$cloudId]) {
					entitlementInfo(hamsProductKey: $productKey) {
						entitlement {
							transactionAccount {
								experienceCapabilities {
									addPaymentMethod {
										isAvailableToUser
									}
								}
							}
							subscription {
								accountDetails {
									invoiceGroup {
										experienceCapabilities {
											configurePayment {
												isAvailableToUser
											}
										}
									}
								}
							}
							experienceCapabilities {
								changeOffering(offeringKey: $offeringKey) {
									experienceUrl
									isAvailableToUser
								}
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	const { canUpgradeAndPay, upgradeLink } = extractCanUpgradeAndPayDetails(data);

	const origin = new OriginTracer({ product: 'jira' });
	const upgradeLinkWithOrigin = upgradeLink ? origin.addToUrl(upgradeLink) : undefined;

	const usedStorageData = useGetUsedStorageData({ skip: !canUpgradeAndPay });
	const storageLimit =
		usedStorageData?.storageLimit !== undefined
			? usedStorageData.storageLimit
			: FREE_PLAN_STORAGE_LIMIT;
	const usedStorageRatio = usedStorageData?.storageRatio;
	const enoughStorageUsed =
		usedStorageRatio !== undefined && usedStorageRatio >= STORAGE_RATIO_TO_SHOW_BANNER;

	if (!canUpgradeAndPay || !enoughStorageUsed || usedStorageData?.edition !== 'free') {
		return null;
	}

	const openBillingPage = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'storageLimitsBannerLink',
		);
	};

	return (
		<BannerContent
			isFullWidth={isFullWidth}
			upgradeLinkWithOrigin={upgradeLinkWithOrigin}
			openBillingPage={openBillingPage}
			isUsingCcp={isUsingCcp}
			storageLimit={storageLimit}
			usedStorageRatio={usedStorageRatio}
		/>
	);
}

export function StorageLimitsBannerLoader(props: StorageLimitsBannerProps) {
	const cloudId = useCloudId();
	const isJswFree = useIsJiraSoftwareFree();

	const [preloadedQuery, loadQuery] =
		useQueryLoader<ui_storageLimitsBanner_canUpgradeAndPayQuery>(Query);

	useEffect(() => {
		if (isJswFree) {
			loadQuery({
				cloudId,
				productKey: SOFTWARE,
				offeringKey: getProductOfferingKeyOrThrow(SOFTWARE, STANDARD_EDITION),
			});
		}
	}, [cloudId, loadQuery, isJswFree]);

	if (!isJswFree) {
		return null;
	}

	return (
		<Placeholder name="storage-limits-banner-loader" fallback={null}>
			{preloadedQuery != null ? (
				<StorageLimitsBannerComponentWithPreloadedQuery {...props} queryRef={preloadedQuery} />
			) : null}
		</Placeholder>
	);
}

export default function StorageLimitsBanner(props: StorageLimitsBannerProps) {
	return (
		<JSErrorBoundary
			id={ERROR_BOUNDARY_ID}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			{fg('tako-1741_migrate_storage_limits_to_preloadedquery') ? (
				<StorageLimitsBannerLoader {...props} />
			) : (
				<StorageLimitsBannerComponent {...props} />
			)}
		</JSErrorBoundary>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContainer = styled.div({
	backgroundColor: token('color.background.warning', '#FFF7D6'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: token('font.weight.medium'),
	color: token('color.text.subtle', '#44546F'),
	minHeight: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	maxHeight: `${token('space.300', '24px')} !important`,
	gap: token('space.050', '4px'),
	whiteSpace: 'nowrap',
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.050', '4px'),
	paddingLeft: token('space.300', '24px'),
});

const fullWidthBannerWrapperStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingRight: 'space.0',
	paddingLeft: 'space.0',
});

const partialWidthBannerWrapperStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingRight: 'space.500',
	paddingLeft: 'space.500',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerLink = styled.a({
	color: token('color.text.subtle', '#44546F'),
	textDecoration: 'underline',
	cursor: 'pointer',
	'&:hover': {
		color: token('color.text.subtle', '#44546F'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerText = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
