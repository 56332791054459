import React from 'react';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { useRouterActions, useRouter } from '@atlassian/react-resource-router';
import { useIsSharedView } from '../environment/index.tsx';
import { useCurrentViewSharingSettings } from '../sharing/selectors/hooks.tsx';
import * as actions from './actions/index.tsx';
import {
	getFullscreenQueryParameter,
	getSelectedIssueQueryParameter,
	getSidebarQueryParameter,
	getRoadmapQueryParameter,
	getPolarisQuerySelector,
	getCurrentRouteSelector,
	getCurrentRouteSectionSelector,
	getIsIssueOpenInFullscreen,
	getIsIssueOpenInSidebar,
	getIssueViewLayout,
	getIssueViewSection,
} from './selectors/index.tsx';
import {
	type Props,
	type ExternalProps,
	type State,
	DEFAULT_ISSUE_VIEW_SECTION_SIDEBAR,
} from './types.tsx';
import { useUrlResource, useUrlSection } from './utils/index.tsx';

type Actions = typeof actions;

const initialState: State = {
	containerProps: undefined,
};

export const PolarisRouteStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisRouteStore',
});

const { Container, useActions, createHook } = createJpdContainer<Props, State, Actions>(
	PolarisRouteStore,
	{
		onInit:
			() =>
			({ dispatch }) => {
				dispatch(actions.init());
				dispatch(actions.updateDebouncedRouterActions());
			},

		onUpdate:
			() =>
			({ dispatch }) => {
				dispatch(actions.updateDebouncedRouterActions());
			},
	},
);

export const PolarisRouteContainer = (props: ExternalProps) => {
	const [{ query }] = useRouter();
	const section = useUrlSection();
	const resource = useUrlResource();
	const { push, replace } = useRouterActions();
	return (
		<Container
			{...props}
			query={query}
			section={section}
			resource={resource}
			useNativePushMethod={push}
			useNativeReplaceMethod={replace}
		>
			{props.children}
		</Container>
	);
};

export const usePolarisQuery = createHook(getPolarisQuerySelector);

export const useFullscreenQueryParameter = createHook(getFullscreenQueryParameter);

export const useSidebarQueryParameter = createHook(getSidebarQueryParameter);

export const useRoadmapQueryParameter = createHook(getRoadmapQueryParameter);

export const useSelectedIssueQueryParameter = createHook(getSelectedIssueQueryParameter);

export const useIsIssueOpenInFullscreen = createHook(getIsIssueOpenInFullscreen);

export const useIsIssueOpenInSidebar = createHook(getIsIssueOpenInSidebar);

export const useIssueViewLayout = createHook(getIssueViewLayout);

export const useIssueViewSectionInternal = createHook(getIssueViewSection);

export const useIssueViewSection = () => {
	const issueViewSection = useIssueViewSectionInternal();
	const isSharedView = useIsSharedView();
	const sharingSettings = useCurrentViewSharingSettings();

	if (!isSharedView) return issueViewSection;

	if (
		(!sharingSettings?.showIdeaComments && issueViewSection === 'comments') ||
		(!sharingSettings?.showIdeaInsights && issueViewSection === 'capture')
	) {
		return DEFAULT_ISSUE_VIEW_SECTION_SIDEBAR;
	}

	return issueViewSection;
};

export const useCurrentPolarisRoute = createHook(getCurrentRouteSelector);
export const useCurrentPolarisRouteSection = createHook(getCurrentRouteSectionSelector);

export const usePolarisRouter = () => {
	const { routeTo, setQuery, closeIssueView, openIssueView } = useActions();
	return { routeTo, setQuery, closeIssueView, openIssueView };
};
