import React, { type KeyboardEventHandler, useCallback, useEffect, useRef } from 'react';

import { toJSON } from '@atlaskit/editor-common/utils';
// eslint-disable-next-line @atlaskit/editor/warn-no-restricted-imports
import type { EditorActions } from '@atlaskit/editor-core';
import { ComposableEditor } from '@atlaskit/editor-core/composable-editor';
import { EditorContext } from '@atlaskit/editor-core/editor-context';
import { usePreset } from '@atlaskit/editor-core/use-preset';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { AllSelection } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { Flex } from '@atlaskit/primitives';
import { TextSerializer } from '@atlaskit/renderer/text-serializer';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { Decoration } from '../components/Decoration/Decoration';
import { LinkPickerButton } from '../components/LinkPickerButton/LinkPickerButton';
import { MentionButton } from '../components/MentionButton/MentionButton';
import type { AIPromptPresetOptions } from '../presets/prompt';
import { createAIPromptPreset } from '../presets/prompt';
import { maybeBuild1pAutoFormattingProvider } from '../utils/smart-link-unfurl';

import type { PromptEditorType } from './types';

export type CreatePromptEditorOptions = Omit<AIPromptPresetOptions, 'placeholder' | 'onSave'>;

// PromptEditorProps is mirrored at `packages/editor/generative-ai-modal/src/ui/components/PromptEditorWrapper/PromptEditorWrapper.tsx`
// since editor-ai-injected-editors cannot be a dependency of generative-ai-modal or editor-plugin-ai.
type PromptEditorProps = {
	type?: PromptEditorType;
	isInvalid?: boolean;
	autoFocus?: boolean;
	defaultValue?: JSONDocNode;
	placeholder?: string;
	ariaLabel?: string;
	onChange?: (value: { text: string; adf: JSONDocNode }) => void;
	onInputChange?: (input: string) => void;
	onADFChange?: (input: JSONDocNode) => void;
	onBlur?: () => void;
	onFocus?: () => void;
	setFocusHandlers?: (focusFn?: () => boolean, blurFn?: () => boolean) => void;
	/**
	 * Using separate setter function for clear as focus and blur
	 *  is set from editorApi while clear from editorActions.
	 * They both can be available at different time.
	 * So it's safest to have separate setter function.
	 */
	setClearHandler?: (clearFn?: () => boolean) => void;
	/**
	 * Having links in Editor is giving false impression to customers that
	 *  we have started supporting links, but we haven't yet as backend will
	 *  still point to xp-gen-ai and leading to hallucinations.
	 * So we have tied enabling links to ConvoAI FF through this prop.
	 */
	enableLinks?: boolean;
};

type EditorWrapperProps = {
	editorProps: PromptEditorProps;
	options: CreatePromptEditorOptions;
};

const EditorWrapper = (props: EditorWrapperProps) => {
	const appearance = 'chromeless';
	const { editorProps, options } = props;
	const {
		type = 'user-input',
		isInvalid,
		onBlur,
		onFocus,
		onChange,
		onInputChange,
		onADFChange,
		defaultValue,
		enableLinks,
		setFocusHandlers,
		setClearHandler,
		ariaLabel,
	} = editorProps;

	const handleChange = useCallback(
		(editorView: EditorView) => {
			const textSerializer = TextSerializer.fromSchema(editorView.state.schema);
			const text = textSerializer.serializeFragment(editorView.state.doc.content);
			const adf = toJSON(editorView.state.doc);

			onChange?.({ text, adf });
			onADFChange?.(adf);
			onInputChange?.(text);
		},
		[onChange, onADFChange, onInputChange],
	);

	const onSave = useCallback(
		(editorView: EditorView) => {
			handleChange(editorView);
		},
		[handleChange],
	);

	// We could have created preset outside PromptEditor component,
	//  but currently placeholder prop ComposableEditor is not working.
	// We have to pass placeholder to placeholder plugin.
	// That's why using usePreset, who memoize preset.
	const { preset, editorApi } = usePreset(
		() =>
			createAIPromptPreset({
				...options,
				placeholder: {
					placeholder: editorProps.placeholder,
				},
				onSave,
				enableLinks,
			}),
		[options, editorProps.placeholder],
	);

	const editorViewRef = useRef<EditorView | null>(null);

	const onEditorReady = useCallback(
		(editorActions: EditorActions) => {
			if (setClearHandler) {
				setClearHandler(() => editorActions.clear());
			}
			const editorView = editorActions._privateGetEditorView();
			if (editorView) {
				editorViewRef.current = editorView;

				if (!editorActions.isDocumentEmpty()) {
					editorView.dispatch(
						editorView.state.tr.setSelection(new AllSelection(editorView.state.doc)),
					);
				}
			}
		},
		[setClearHandler],
	);

	useEffect(() => {
		if (setFocusHandlers) {
			/**
			 * We need to expose clear function through ref because
			 *  changing defaultValue does not clear editor.
			 */
			setFocusHandlers(editorApi?.core?.actions.blur, editorApi?.core?.actions.focus);
		}
	}, [editorApi, setFocusHandlers]);

	// Skip any toolbars that displays in the editor, like Link Picker toolbar
	// Yes, it's ugly, but it's easier way, because we can't have access to the PromptEditor
	// floating toolbar.
	const stopPropagationForFloatingToolbar: KeyboardEventHandler = useCallback((event) => {
		const eventFloatingToolbar =
			event.target instanceof HTMLElement
				? event.target.closest('[data-testid="editor-floating-toolbar"]')
				: null;

		// Check that floating toolbar is a child of the current target,
		// i.e. it's a child of current the editor.
		if (eventFloatingToolbar && event.currentTarget.contains(eventFloatingToolbar)) {
			event.stopPropagation();
		}
	}, []);

	const handleDecoratorClick = useCallback(() => {
		if (!editorViewRef.current?.hasFocus()) {
			// There is a small bug:
			// By default Textarea shows 2 lines, but the Editor has only 1 line.
			// We set Editor's minHeight to expand it to 2 lines, but the Editor doesn't process clicks outside the first line.
			// So we need to focus the editor manually.
			// But, because this process clicks outside the Editor, the Editor loses focus first,
			// and then we focus it again. It causes the editor to lose focus for short period.
			// Not a huge problem, but it's a bit annoying.
			editorViewRef.current?.focus();
		}
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onKeyDown={stopPropagationForFloatingToolbar} onKeyUp={stopPropagationForFloatingToolbar}>
			<Flex gap="space.050">
				<Decoration
					type={type}
					isInvalid={isInvalid}
					onClick={handleDecoratorClick}
					onBlur={onBlur}
					onFocus={onFocus}
				>
					<ComposableEditor
						mentionProvider={options.mentionProvider}
						autoformattingProvider={maybeBuild1pAutoFormattingProvider(options)}
						appearance={appearance}
						// 56 is a default height for 2 lines, 6 is top and bottom paddings.
						minHeight={type === 'advanced-prompt' ? 60 - 2 * 6 : 20}
						maxHeight={200}
						preset={preset}
						assistiveLabel={ariaLabel}
						shouldFocus={editorProps.autoFocus ?? true}
						defaultValue={defaultValue}
						onChange={handleChange}
						// We will remove use of onEditorReady when editorApi provides a matching "clear()" action.
						onEditorReady={onEditorReady}
					/>
				</Decoration>

				{(type === 'user-input' || type === 'interrogate') && (
					<>
						{options.enableLinks && options.linkPicker && (
							<LinkPickerButton editorApi={editorApi} promptType={type} />
						)}
						{options.mentionProvider &&
							editorExperiment('platform_editor_ai_mentions_support', true, {
								exposure: true,
							}) && <MentionButton editorApi={editorApi} promptType={type} />}
					</>
				)}
			</Flex>
		</div>
	);
};

export function createPromptEditor(options: CreatePromptEditorOptions) {
	return (props: PromptEditorProps): JSX.Element => {
		const editorProps = {
			editorProps: props,
			options,
		};

		return (
			<EditorContext>
				<EditorWrapper
					// Ignored via go/ees005
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...editorProps}
				/>
			</EditorContext>
		);
	};
}
