import React, { type ReactNode, useCallback, useMemo } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { HighlightActionsProvider } from '@atlassian/jira-highlight-actions/src/ui/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SIDEBAR_RESIZE_RATIO } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { defaultRatioRightColumn, getIssueContainerMaxWidth } from './constants.tsx';

export const LayoutStyles = {
	modal: 'modal',
	embedded: 'embedded',
	full: 'full',
} as const;

export type LayoutStyle = keyof typeof LayoutStyles;
export type onSaveRatioProps = (
	newRatio: number,
	isReset?: boolean,
	collapseBtnClicked?: boolean,
) => void;
export const getLayoutStyle = (isModal?: boolean, issueMaxWidth?: number): LayoutStyle => {
	if (isModal === true) {
		return LayoutStyles.modal;
	}
	if (issueMaxWidth !== getIssueContainerMaxWidth()) {
		return LayoutStyles.embedded;
	}
	return LayoutStyles.full;
};

const getStoredValues = (str: string | null) => {
	if (str === null || str === '') {
		return {};
	}
	try {
		return JSON.parse(str);
	} catch {
		return {};
	}
};

export const getSidebarRatio = (layoutStyle: LayoutStyle, storedValue: string | null) => {
	const storedValues = getStoredValues(storedValue);
	const r = storedValues[layoutStyle] ?? defaultRatioRightColumn;

	return !Number.isNaN(r) && Number.isFinite(r) && r >= 0 && r <= 1 ? r : defaultRatioRightColumn;
};

export const isSidebarCollapsible = (storedValue: string | null) => {
	const storedValues = getStoredValues(storedValue);
	return storedValues?.collapsible;
};

export const useSidebarResize = (layoutStyle: LayoutStyle) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [sidebarRatio, { setValue }] = useUserPreferencesValue(SIDEBAR_RESIZE_RATIO);

	const ratio = useMemo(
		() => getSidebarRatio(layoutStyle, sidebarRatio),
		[sidebarRatio, layoutStyle],
	);
	const isCollapsible = useMemo(
		() =>
			expVal('issue-view-collapsible-context-menu', 'isContextMenuCollapsible', false)
				? isSidebarCollapsible(sidebarRatio)
				: false,
		[sidebarRatio],
	);

	const onSaveRatio: onSaveRatioProps = useCallback(
		(newRatio, isReset = false, collapseBtnClicked = false) => {
			const analyticsEvent = createAnalyticsEvent({});
			fireUIAnalytics(analyticsEvent, `sidebar ${isReset ? 'reset' : 'dragged'}`, 'issueDetails', {
				sidebarRatio: newRatio,
				source: collapseBtnClicked ? 'collapseButton' : 'dragHandle',
			});
			const storedValues = getStoredValues(sidebarRatio);

			// TODO: Remove the logic for collapsible key once https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/experiments/issue-view-collapsible-context-menu/setup experiment is GA
			// Enable collapsible feature only if the user resizes the sidebar.
			let collapsible = {};
			if (
				(!storedValues?.collapsible &&
					expVal('issue-view-collapsible-context-menu', 'isContextMenuCollapsible', false)) ||
				expVal('issue-view-collapsible-context-menu', 'isContextMenuCollapsible', false)
			) {
				// FF is on and collapsible key is missing, so we need to add it to the user preferences object
				collapsible = { collapsible: true };
			} else if (storedValues?.collapsible) {
				// FF is off and collapsible key is present, so we need to remove it from the user preferences object
				delete storedValues.collapsible;
			}

			setValue(
				JSON.stringify({
					...storedValues,
					...collapsible,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					[layoutStyle as string]: newRatio,
				}),
				// eslint-disable-next-line @typescript-eslint/no-empty-function
			).catch(() => {}); // Ignore if server-side resize state update fails, this state is not critical;
		},
		[setValue, createAnalyticsEvent, layoutStyle, sidebarRatio],
	);

	const onResetRatio = useCallback(
		(newRatio: number) => {
			onSaveRatio(newRatio, true);
		},
		[onSaveRatio],
	);

	return [ratio, onSaveRatio, onResetRatio, isCollapsible] as const;
};

export const HighlightActionsWrapper = ({
	children,
	scope,
}: {
	children: ReactNode;
	scope: string;
}) => {
	const issueKey = useIssueKey();

	return (
		<HighlightActionsProvider scope={`${scope}.${issueKey}`}>{children}</HighlightActionsProvider>
	);
};
