import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueKey, BaseUrl, AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

type Params = {
	baseUrl: BaseUrl;
	issueKey: IssueKey;
	accountId: AccountId | null;
};

export const watchUrl = ({ baseUrl, issueKey }: { baseUrl: BaseUrl; issueKey: IssueKey }) =>
	`${baseUrl}/rest/api/2/issue/${issueKey}/watchers`;

export const unwatchUrl = ({ baseUrl, issueKey, accountId }: Params) =>
	accountId ? `${baseUrl}/rest/api/2/issue/${issueKey}/watchers?accountId=${accountId}` : '';

export const watch = ({ baseUrl, issueKey, accountId }: Params) => {
	return fetchJson$(watchUrl({ baseUrl, issueKey }), {
		method: 'post',
		body: JSON.stringify(accountId),
	});
};

export const unwatch = ({ baseUrl, issueKey, accountId }: Params) => {
	return fetchJson$(unwatchUrl({ baseUrl, issueKey, accountId }), {
		method: 'delete',
	});
};
