import React, { type ReactNode, useCallback } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlassian/jira-feature-gating';
import {
	NEWEST_FIRST,
	OLDEST_FIRST,
	type ActivitySortOrderType,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import AddCommentForm from '@atlassian/jira-issue-view-activity-comment/src/comment-add/index.tsx';
import {
	PERMALINK,
	SERVICEDESK_PLACEHOLDER_CREATE_COMMENT_ACTIONS,
} from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import {
	LOADING,
	SAVE_COMMENT_LOADING,
	type LoadingStage,
} from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import type { mainIssueAggQuery$data } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql.ts';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FeedContainer } from '../../styles/index.tsx';
import FeedSkeleton from '../feed-skeleton/view.tsx';

type Props = {
	canAddComments: boolean;
	list: ReactNode | null;
	numPrevCommentsToLoad: number;
	selectedSortOrder: ActivitySortOrderType | null;
	loadingStage: LoadingStage;
	rootRelayFragment?: mainIssueAggQuery$data | null;
	hasPrevComments?: boolean;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		list,
		loadingStage,
		canAddComments,
		numPrevCommentsToLoad,
		selectedSortOrder,
		rootRelayFragment,
		hasPrevComments,
	} = props;
	const [, { register }] = useGlobalRefStoreActions();
	const onRef = useCallback(
		(ref?: HTMLElement | null) => {
			register(PERMALINK, ref);
		},
		[register],
	);
	const onCommentActionRef = useCallback(
		(ref?: HTMLElement | null) => {
			register(SERVICEDESK_PLACEHOLDER_CREATE_COMMENT_ACTIONS, ref);
		},
		[register],
	);

	// Note: since the comments feed is only being fetched with orderBy="-created",
	// we can assume numPrevCommentsToLoad will always represents the number of
	// OLDER comments to load. With a dynamic orderBy value, we normally cannot
	// make this assumption as orderBy="created" would result in numPrevCommentsToLoad
	// to be the number of NEWER comments more to load.
	const isLatestCommentNotVisible = numPrevCommentsToLoad > 0 || hasPrevComments;
	if (
		loadingStage === LOADING ||
		(loadingStage === SAVE_COMMENT_LOADING && isLatestCommentNotVisible)
	) {
		return (
			<FeedContainer>
				<FeedSkeleton />
			</FeedContainer>
		);
	}

	return (
		<span>
			<SpotlightTarget name={PERMALINK}>
				<div ref={onRef} /> {/* this empty div will be a placeholder */}
			</SpotlightTarget>
			{!canAddComments && (
				<SpotlightTarget name={SERVICEDESK_PLACEHOLDER_CREATE_COMMENT_ACTIONS}>
					{/*
                        This empty div will be a placeholder for onboarding tour,
                        where user does not have permission to comment
                     */}
					<div ref={onCommentActionRef} />
				</SpotlightTarget>
			)}
			{canAddComments &&
				selectedSortOrder === NEWEST_FIRST &&
				!expVal('issue-view-side-panel-activity', 'isActivityInSidePanel', false) && (
					<AddCommentForm {...(fg('smart-replies-system-setting') ? { rootRelayFragment } : {})} />
				)}
			{list}
			{canAddComments &&
				(selectedSortOrder === OLDEST_FIRST ||
					expVal('issue-view-side-panel-activity', 'isActivityInSidePanel', false)) && (
					<AddCommentForm {...(fg('smart-replies-system-setting') ? { rootRelayFragment } : {})} />
				)}
		</span>
	);
};
