import type { AgentRegistry } from './types.tsx';

type AssistanceServiceResponse = {
	message: {
		content: string;
	};
};

export const invokeAgent = async <K extends keyof AgentRegistry>(
	agentNamedId: K,
	agentRequest: AgentRegistry[K]['agentRequest'],
	signal?: AbortSignal,
): Promise<AgentRegistry[K]['agentResponse']> => {
	const response = await fetch('/gateway/api/assist/chat/v1/invoke_agent', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-Product': 'jira',
			'X-Experience-Id': agentRequest.experienceId,
		},
		body: JSON.stringify({
			recipient_agent_named_id: agentNamedId,
			agent_input_context: agentRequest.agent_input_context,
		}),
		signal,
	});

	if (!response.ok) {
		throw new Error(`API call failed: ${response.status}`);
	}

	const result: AssistanceServiceResponse = await response.json();

	const data: AgentRegistry[K]['agentResponse'] = {
		content: JSON.parse(result.message.content),
	};

	return data;
};
