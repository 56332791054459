// TODO: go/restrict-enums

import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldRollup } from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import type { IssueId, IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StatusCategory } from '@atlassian/jira-shared-types/src/rest/jira/status-category.tsx';
import type { Filter } from '../filter/types.tsx';
import type { PolarisViewTableColumnSize } from '../list/types.tsx';
import type { Marker } from '../markers/types.tsx';
import type { PolarisMatrixConfig } from '../matrix/types.tsx';
import type { SortMode } from '../sort/types.tsx';
import type { PolarisTimelineConfig } from '../timeline/types.tsx';
import type {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_TWOXTWO,
	VIEW_KIND_SECTION,
} from './constants.tsx';

export type LocalViewId = string;

export type ViewUUID = string;

export type ViewKind =
	| typeof VIEW_KIND_BOARD
	| typeof VIEW_KIND_MATRIX
	| typeof VIEW_KIND_TABLE
	| typeof VIEW_KIND_TIMELINE
	| typeof VIEW_KIND_TWOXTWO
	| typeof VIEW_KIND_SECTION;

export type ViewDescription = ADF | undefined;

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum ViewLayoutType {
	SUMMARY = 'SUMMARY',
	COMPACT = 'COMPACT',
	DETAILED = 'DETAILED',
}

type ConfigError = {
	code: string;
	message: string;
	misconfiguredFields: {
		sort: string[];
		filter: string[];
		matrixAxis: string[];
		verticalGroupBy: string[];
		groupBy: string[];
	};
};

/** Properties of view that are synced with the remote server */
export type RemoteView = {
	// viewId may be undefined if the view has not yet been saved to the server
	viewId?: Ari;
	uuid?: ViewUUID;
	viewLegacyId?: number; // likewise the legacy id (xid) may be undefined,
	kind: ViewKind;
	title: string;
	emoji: string | undefined;
	description: ViewDescription;
	editable: boolean;
	filter: Filter[];
	groupBy?: FieldKey;
	verticalGroupBy?: FieldKey;
	sortBy?: SortField[];
	fields: FieldKey[];
	hidden: FieldKey[];
	fieldRollups: FieldRollup[];
	groupValues: GroupValue[];
	verticalGroupValues: GroupValue[];
	hideEmptyGroups: boolean;
	hideEmptyColumns: boolean;
	rank: number;
	viewSetId: Ari;
	comments?: PolarisComment[];
	containsArchived: boolean;
	lastCommentsViewedTimestamp: number | undefined;
	tableColumnSizes: PolarisViewTableColumnSize[];
	matrixConfig?: PolarisMatrixConfig;
	timelineConfig?: PolarisTimelineConfig;
	sortMode?: SortMode | undefined;
	isAutosaveEnabled: boolean;
	layoutType: ViewLayoutType | null;
	createdAtTimestamp: string | null;
	updatedAtTimestamp: string | null;
	markers?: Marker[];
};

/** properties that come from jpd-views-service for published view */
export type RemotePublishedView = RemoteView & {
	projectId?: number;
	configError: ConfigError | undefined;
	rankFieldKey?: FieldKey;
	statusCategories?: StatusCategory[];
};

export type SelectableId = string;

// Picking all properties from TimelineConfigDraft except 'arrangementInformation' since it is affected by autosave setting
export type TimelineConfigDraft = Pick<
	PolarisTimelineConfig,
	| 'startDateField'
	| 'dueDateField'
	| 'startTimestamp'
	| 'endTimestamp'
	| 'mode'
	| 'summaryCardField'
>;

export type ViewDraft = Pick<
	RemoteView,
	| 'verticalGroupBy'
	| 'verticalGroupValues'
	| 'groupBy'
	| 'groupValues'
	| 'fieldRollups'
	| 'filter'
	| 'sortBy'
	| 'sortMode'
	| 'fields'
	| 'hidden'
	| 'matrixConfig'
	| 'hideEmptyGroups'
	| 'layoutType'
	| 'hideEmptyColumns'
> & {
	timelineConfig?: TimelineConfigDraft;
};

type ViewTemporaryData = {
	filter?: RemoteView['filter'];
	sortBy?: RemoteView['sortBy'];
};

/**
 * helper type to avoid race conditions on loading additional props
 * see POL-10532 for bug details
 * this should become obsolete with view type cleanup and removal of the monolithic view container
 * NOTE: loading states should not be part of the domain model, but we're currently forced into this by
 * the way the view state is set up
 */
type PropertyLoadingState = {
	isLoading: boolean;
	loaded: boolean;
};

/** Properties of view that are managed only on the client */
type LocalView = {
	id: LocalViewId;
	// Short, url safe, unique (within this project) slug to identify this view. Undefined if view
	// is not yet saved. Equals the resource id of the view ari and is used to identify the current view
	// via the route
	slug?: string;
	isEditingTitle: boolean;
	saving: boolean;
	saveError?: Error;
	modified: boolean;
	fetchCommentsTimestamp?: number;
	/** Filters that were configured in normal view.
	 * This prop is used to filter out groups in shared view
	 * that were hidden by admin in normal view */
	initialFilter?: Filter[];
	sortBy?: SortField[]; // could get parsed from JQL for pre POL-515 views,
	selectedIssues: Record<SelectableId, true>;
	collapsedSwimlanes: {
		[key: string]: boolean;
	};
	issueRanking?: IssueId[] | undefined;
	draft: ViewDraft;
	temporaryData?: ViewTemporaryData;
	bulkEditHoveredFieldKey: FieldKey | undefined;
	commentsLoaded?: boolean;
	descriptionLoadingState?: PropertyLoadingState;
	lastSelectedIssueTypeId?: IssueTypeId;
};

export type View = LocalView & RemoteView & RemotePublishedView;
