import React, { createContext, type PropsWithChildren, useContext } from 'react';

import type { ExecutionContext } from '../../common/types/execution-context';

import type { ExecutionContextProviderProps } from './types';

type NullableExecutionContext = ExecutionContext | null;

export const ExecutionContextRoot: React.Context<NullableExecutionContext> =
	createContext<NullableExecutionContext>(null);

export const useExecutionContext = (): ExecutionContext => {
	const executionContext = useContext<NullableExecutionContext>(ExecutionContextRoot);
	if (executionContext === null) {
		throw new Error('executionContext value is null');
	}
	return executionContext;
};

export const ExecutionContextProvider = ({
	children,
	value,
}: PropsWithChildren<ExecutionContextProviderProps>) => {
	if (!value) {
		throw new Error('ExecutionContextProvider requires a value');
	}
	return <ExecutionContextRoot.Provider value={value}>{children}</ExecutionContextRoot.Provider>;
};
