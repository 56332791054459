import head from 'lodash/head';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import type { Props, State } from '../../types.tsx';

export const updateFieldFormula =
	(fieldKey: FieldKey, formula: DynamicFieldFormula) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ issueTypeIds, fieldRemote, createAnalyticsEvent }: Props,
	): Promise<Field> => {
		const currentField = getState().fields[fieldKey];

		fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
			updatedItems: [{ name: 'formula' }],
			issueFieldKey: currentField.key,
			issueFieldType: currentField.type,
		});

		const issueTypeId = head(issueTypeIds);

		if (issueTypeId === undefined) {
			throw new Error('cannot update field formula for unknown issue type');
		}

		await fieldRemote.updateFieldConfiguration({
			fieldKey,
			issueTypeId,
			oldConfiguration: currentField.formula ? { polarisFormula: currentField.formula } : {},
			configuration: { polarisFormula: formula },
			type: 'updateFieldFormula',
			isGlobalSystemField: fg('polaris_new_custom_types_global_system_fields')
				? isGlobalSystemField(currentField)
				: false,
		});

		const newField = {
			...currentField,
			formula,
		};
		setState({
			fields: {
				...getState().fields,
				[fieldKey]: newField,
			},
		});

		return newField;
	};
