import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira/issue-field-option';

const CLOUD_ID_MOCK = 'cloud-id';
const ACTIVATION_ID_MOCK = 'activation-id';
const FIELD_ID_MOCK = 'field-1';

export const isAriResourceIdValid = (optionId: string) => {
	try {
		JiraIssueFieldOptionAri.create({
			siteId: CLOUD_ID_MOCK,
			activationId: ACTIVATION_ID_MOCK,
			fieldId: FIELD_ID_MOCK,
			optionId,
		});

		return true;
	} catch (e) {
		return false;
	}
};
