/**
 * @generated SignedSource<<74a9feb39f86126015cbf7f2c165da48>>
 * @relayHash a36415c069bf48afbe1a27cf7ba911be
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 654a598e81ba739aa91122a3529d10235ad2934a74debc9bb73befe6f037ee6c

import type { ConcreteRequest, Query } from 'relay-runtime';
export type siteSwitcherQuery$variables = {
  hostNames: ReadonlyArray<string>;
};
export type siteSwitcherQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly cloudId: string | null | undefined;
    readonly hostName: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type siteSwitcherQuery = {
  response: siteSwitcherQuery$data;
  variables: siteSwitcherQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hostNames"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "hostNames",
        "variableName": "hostNames"
      }
    ],
    "concreteType": "TenantContext",
    "kind": "LinkedField",
    "name": "tenantContexts",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "hostName"
      },
      {
        "kind": "ScalarField",
        "name": "cloudId"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "siteSwitcherQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "siteSwitcherQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "654a598e81ba739aa91122a3529d10235ad2934a74debc9bb73befe6f037ee6c",
    "metadata": {},
    "name": "siteSwitcherQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "04838a6916e2206776270cafad369fc7";

export default node;
