import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
	forwardRef,
	type Ref,
	type FocusEvent,
} from 'react';
import {
	graphql,
	useLazyLoadQuery,
	usePaginationFragment,
	type EntryPointProps,
} from 'react-relay';
import Select from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import {
	FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
	SEARCH_DEBOUNCE_TIMEOUT,
} from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { filterOptionByLabelAndFilterValues } from '@atlassian/jira-issue-field-select-base/src/ui/filter-options-by-label-and-filter-values/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import { SelectableFieldEditViewWithFieldOptionsFragment } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/index.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import type { multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment$key as MultiSelectFragment } from '@atlassian/jira-relay/src/__generated__/multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment.graphql';
import type { multiSelect_issueSelectableFieldQuery } from '@atlassian/jira-relay/src/__generated__/multiSelect_issueSelectableFieldQuery.graphql';
import type { multiSelect_multiSelectSearchQuery } from '@atlassian/jira-relay/src/__generated__/multiSelect_multiSelectSearchQuery.graphql';
import multiSelectSearchRefetchQuery, {
	type multiSelect_multiSelectSearchRefetchQuery as MultiSelectRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/multiSelect_multiSelectSearchRefetchQuery.graphql';
import messages from './messages.tsx';
import type {
	Option,
	MultiSelectEditViewProps,
	MultiSelectEditViewDeprecatedProps,
	MultiSelectEditViewWithFieldOptionsFragmentProps,
} from './types.tsx';

/**
 * MultiSelectEditViewWithFieldOptionsFragment is a version of the edit view that allows
 * the passing of a fragment for experiences like GIC that might want to group fetch data on mount
 * instead of having a separate network request for it
 *
 * @param props [MultiSelectEditViewWithFieldOptionsFragmentProps](./types.tsx)
 * @deprecated use SelectableFieldEditViewWithFieldOptionsFragment instead
 */
export const MultiSelectEditViewWithFieldOptionsFragment = ({
	autoFocus = false,
	fieldId,
	fieldOptionsFragmentRef,
	onChange,
	value,
	noOptionsMessage,
	loadingMessage,
	fieldName,
	isInvalid = false,
	isDisabled = false,
	filterOptionsById = null,
	inputId,
	spacing = 'default',
}: MultiSelectEditViewWithFieldOptionsFragmentProps) => {
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(autoFocus);

	const { formatMessage } = useIntl();

	const getNoOptionsMessage = useMemo(() => {
		if (noOptionsMessage) {
			return ({ inputValue }: { inputValue: string }) => noOptionsMessage(inputValue);
		}

		return () => formatMessage(messages.empty);
	}, [formatMessage, noOptionsMessage]);

	const getLoadingMessage = useMemo(() => {
		if (loadingMessage) {
			return ({ inputValue }: { inputValue: string }) => loadingMessage(inputValue);
		}

		return () => formatMessage(messages.loading);
	}, [formatMessage, loadingMessage]);

	if (fg('fix_non_closing_dropdown_bug_on_multiselect')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (isDisabled) {
				setMenuIsOpen(false);
			}
		}, [isDisabled]);
	}

	// #region Relay
	// suggestions fragment with pagination and refetch
	const { data: fieldOptionsSearchData, refetch: refetchSuggestions } = usePaginationFragment<
		MultiSelectRefetchQuery,
		MultiSelectFragment
	>(
		graphql`
			fragment multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment on Query
			@refetchable(queryName: "multiSelect_multiSelectSearchRefetchQuery")
			@argumentDefinitions(
				id: { type: "ID!" }
				searchBy: { type: "String", defaultValue: "" }
				first: { type: "Int", defaultValue: 50 }
				after: { type: "String", defaultValue: null }
				filterById: { type: "JiraFieldOptionIdsFilterInput" }
			) {
				node(id: $id) {
					... on JiraMultipleSelectField {
						fieldOptions(
							searchBy: $searchBy
							first: $first
							after: $after
							filterById: $filterById
						) @connection(key: "multiSelect_issueFieldMultiSelectEditviewFull_fieldOptions") {
							edges {
								node {
									value: id
									label: value
									optionId
									isDisabled
								}
							}
						}
					}
				}
			}
		`,
		fieldOptionsFragmentRef,
	);

	const { edges: fieldOptionsData } = fieldOptionsSearchData.node?.fieldOptions || {};
	// #endregion

	// #region Common state (might be handy for other components)
	const [searchByString, setSearchByString] = useState<string>('');
	// #endregion

	// #region Debounced suspensless refetch helpers
	const [searchSuspenselessRefetch, isLoading, lastFetchError] = useSuspenselessRefetch<
		MultiSelectRefetchQuery,
		MultiSelectFragment
	>(multiSelectSearchRefetchQuery, refetchSuggestions);

	const [searchDebouncedSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetch,
		SEARCH_DEBOUNCE_TIMEOUT,
	);

	const [leadingSearchDebouncedSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetch,
		SEARCH_DEBOUNCE_TIMEOUT,
		{ leading: true },
	);

	// #region Common callbacks for the selector
	const onSelectFocus = useCallback(
		(_e: FocusEvent<HTMLElement>): void => {
			setMenuIsOpen(true);
			leadingSearchDebouncedSuspenselessRefetch({
				id: fieldId,
				searchBy: searchByString,
				filterById: filterOptionsById,
				first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
			});
		},
		[leadingSearchDebouncedSuspenselessRefetch, fieldId, searchByString, filterOptionsById],
	);

	const onSelectBlur = useCallback((_e: FocusEvent<HTMLElement>): void => {
		setMenuIsOpen(false);
	}, []);

	const onSearchByStringChangeFunction = useCallback(
		(newSearchByString: string): void => {
			setSearchByString(newSearchByString);
			searchDebouncedSuspenselessRefetch({
				id: fieldId,
				searchBy: newSearchByString || '',
				filterById: filterOptionsById,
				first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
			});
		},
		[fieldId, searchDebouncedSuspenselessRefetch, filterOptionsById],
	);
	// #endregion
	const defaultFailedOption = useMemo(
		() => ({
			label: formatMessage(messages.failedFetch),
			value: '',
			isDisabled: true,
			optionId: '',
		}),
		[formatMessage],
	);
	// #region Transform options data from relay to AK Select

	const selectFieldOptions: Option[] = useMemo(() => {
		// checks for query errors
		if (lastFetchError != null) {
			return [defaultFailedOption];
		}

		return (
			fieldOptionsData
				?.map((edge) => edge?.node)
				.filter(Boolean)
				.map(({ label, value: optionValue, optionId, isDisabled: isOptionDisabled }) => ({
					label: label ?? '',
					value: optionValue,
					optionId,
					isDisabled: isOptionDisabled,
				})) ?? []
		);
	}, [lastFetchError, fieldOptionsData, defaultFailedOption]);
	// #endregion

	return (
		<Select<Option, true>
			isMulti
			autoFocus={autoFocus}
			menuIsOpen={menuIsOpen}
			fieldId={fieldId}
			options={selectFieldOptions}
			value={value}
			onBlur={onSelectBlur}
			onFocus={onSelectFocus}
			noOptionsMessage={getNoOptionsMessage}
			loadingMessage={getLoadingMessage}
			onInputChange={onSearchByStringChangeFunction}
			onChange={onChange}
			isLoading={menuIsOpen && isLoading}
			// @ts-expect-error: Type '<TInternalOption extends InternalOptionShape>(option: TInternalOption, inputValue: string) => boolean' is not assignable to type '(option: FilterOptionOption<Option>, inputValue: string) => boolean'
			filterOption={filterOptionByLabelAndFilterValues}
			placeholder={formatMessage(messages.placeholder, {
				fieldName,
			})}
			inputId={inputId}
			isInvalid={isInvalid}
			isDisabled={isDisabled}
			menuPosition="fixed"
			styles={defaultSelectStyles}
			spacing={spacing}
			aria-label={fieldName}
		/>
	);
};

/**
 * Simple use case variant which handles its own fetching of suggestions data.
 * Use the MultiSelectEditViewWithFieldOptionsFragment variant if you want to handle
 * prefilling the suggestions data as part of a larger query instead of on mount / on focus.
 *
 * @param props [MultiSelectEditViewProps](./types.tsx)
 * @deprecated newer MultiSelectEditView will use SelectableFieldEditViewWithFieldOptionsFragment instead
 */
export const MultiSelectEditViewDeprecated = (props: MultiSelectEditViewDeprecatedProps) => {
	const suggestionsData = useLazyLoadQuery<multiSelect_multiSelectSearchQuery>(
		graphql`
			query multiSelect_multiSelectSearchQuery($id: ID!) {
				...multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment
					@arguments(id: $id, searchBy: "")
			}
		`,
		{
			id: props.fieldId,
		},
		{ fetchPolicy: 'store-only' },
	);

	return (
		<MultiSelectEditViewWithFieldOptionsFragment
			{...props}
			fieldOptionsFragmentRef={suggestionsData}
		/>
	);
};

export const MultiSelectEditView = forwardRef(
	(props: MultiSelectEditViewProps, ref: Ref<HTMLDivElement>) => {
		const { formatMessage } = useIntl();

		const suggestionsData = useLazyLoadQuery<multiSelect_issueSelectableFieldQuery>(
			graphql`
				query multiSelect_issueSelectableFieldQuery($id: ID!) {
					...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
						@arguments(id: $id)
				}
			`,
			{ id: props.fieldId },
			{ fetchPolicy: 'store-only' },
		);

		const content = (
			<SelectableFieldEditViewWithFieldOptionsFragment
				{...props}
				isMulti
				placeholder={
					props.fieldName
						? formatMessage(messages.placeholder, {
								fieldName: props.fieldName,
							})
						: undefined
				}
				fieldOptionsFragmentRef={suggestionsData}
			/>
		);

		return fg('jsc_inline_editing_field_refactor') ? <div ref={ref}>{content}</div> : content;
	},
);

const MultiSelectEditViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, MultiSelectEditViewProps, {}>) => <MultiSelectEditView {...props} />;

export default MultiSelectEditViewEntryPoint;
