import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMoreComments: {
		id: 'issue-view-activity.show-more-comments',
		defaultMessage: 'Show more comments',
		description:
			'The text is shown on a button when there are more older or newer comments available to load for an issue.',
	},
});
