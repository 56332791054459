import React from 'react';
import { ConfluenceIcon } from '@atlaskit/logo';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { ChoreographerMessageTypes } from '@atlassian/jira-choreographer-services/src/types.tsx';

import { type ShowFlagFn, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759

import type { TrackEmbeddedConfluence } from '../../common/types/types.tsx';

const EVENT_SUCCESS = 'success';
const EVENT_ERROR = 'error';
const OPERATIONAL_EVENT_TYPE = 'operational';
const TEAM_NAME = 'confluence-better-together';

interface SuccessFailHandler {
	fireAnalytics?: TrackEmbeddedConfluence;
	embeddedConfluenceSource: string;
	showFlag: ShowFlagFn;
}

interface ParamsBase {
	flagId?: string;
	title: string;
	description: string;
	messageId?: string;
	messageType?: ChoreographerMessageTypes;
}

interface SuccessParams extends ParamsBase {
	action?: string;
	actionSubject?: string;
	eventName?: string;
	eventType?: string;
	originProduct?: string;
	requestedProduct?: string;
	useConfIcon?: boolean;
}
interface FailParams extends ParamsBase {
	id: string;
	error: Error;
}

export const handleSuccessWithNotification =
	({ fireAnalytics, embeddedConfluenceSource, showFlag }: SuccessFailHandler) =>
	({
		action,
		actionSubject,
		eventName,
		eventType,
		originProduct,
		requestedProduct,
		flagId,
		title,
		description,
		messageId,
		messageType,
		useConfIcon,
	}: SuccessParams) => {
		fireAnalytics &&
			eventName &&
			fireAnalytics({
				action: action ?? EVENT_SUCCESS,
				actionSubject,
				eventName,
				embeddedConfluenceSource,
				originProduct,
				requestedProduct,
				eventType: eventType ?? OPERATIONAL_EVENT_TYPE,
			});
		flagId &&
			showFlag({
				key: toFlagId(flagId),
				type: 'success',
				title,
				description,
				messageId,
				messageType,
				icon: useConfIcon ? <ConfluenceIcon appearance="brand" size="small" /> : null,
			});
	};

export const handleFailureWithNotification =
	({ embeddedConfluenceSource, showFlag }: SuccessFailHandler) =>
	({ id, error, flagId, title, description, messageId, messageType }: FailParams) => {
		fireErrorAnalytics({
			meta: {
				id,
				teamName: TEAM_NAME,
			},
			attributes: {
				embeddedConfluenceSource,
			},
			error,
		});
		flagId &&
			showFlag({
				key: toFlagId(flagId),
				type: EVENT_ERROR,
				title,
				description,
				messageId,
				messageType,
			});
	};
