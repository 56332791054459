import { merge } from 'icepick';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { safeLinkTypeName } from '@atlassian/jira-common-constants/src/issue-link-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	UNKNOWN_ERROR,
} from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import { getTaskItemsMetadata } from '@atlassian/jira-issue-task-decision-provider/src/common/utils.tsx';
import type { Comment } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import containsValidEmailAddress from '@atlassian/jira-issue-view-common-utils/src/utils/contains-valid-email-address.tsx';
import { COMMENT } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	DELETE_COMMENT_REQUEST,
	type DeleteCommentPayload,
	type DeleteCommentRequestAction,
	EDIT_COMMENT_BEGIN,
	FETCH_NEWER_COMMENTS_REQUEST,
	FETCH_OLDER_COMMENTS_REQUEST,
	PASTED_INTO_COMMENT_FORM,
	SAVE_COMMENT_SUCCESS,
	type SaveCommentPayload,
	type SaveCommentSuccessAction,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import {
	DELETE_LINKED_ISSUE_REQUEST,
	SAVE_LINKED_ISSUE_CANCEL,
	SAVE_LINKED_ISSUE_FAILURE,
	SAVE_LINKED_ISSUE_REQUEST,
	SAVE_LINKED_ISSUE_RETRY,
	SAVE_LINKED_ISSUE_SUCCESS,
	type SaveLinkedIssueRequest,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { LINKED_ISSUE_PROJECT_ANALYTICS_SUCCESS } from '@atlassian/jira-issue-view-store/src/actions/issue-links-analytics-actions.tsx';
import { LOAD_NEW_ISSUE } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import { LOGIN_SUCCESSFUL } from '@atlassian/jira-issue-view-store/src/actions/login-actions.tsx';
import {
	SAVE_REMOTE_LINKED_ISSUE_FAILURE,
	SAVE_REMOTE_LINKED_ISSUE_REQUEST,
	SAVE_REMOTE_LINKED_ISSUE_RETRY,
	SAVE_REMOTE_LINKED_ISSUE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/remote-issue-links-actions.tsx';
import {
	FETCH_ISSUE_FAILURE,
	FETCH_ISSUE_REQUEST,
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_REQUEST,
	REFRESH_ISSUE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldEditSessionIdSelector,
	fieldTypeSelector,
	fieldAllowedValuesSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { errorSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	DELETE_DRAFT_FAILURE,
	DELETE_DRAFT_NEWER_CONTENT_FOUND,
	DELETE_DRAFT_REQUEST,
	DELETE_DRAFT_SUCCESS,
	type DeleteDraftAction,
	LOAD_DRAFT_FAILURE,
	LOAD_DRAFT_SUCCESS,
	type LoadDraftFailure,
	type LoadDraftSuccess,
} from '@atlassian/jira-issue-view-store/src/drafts/draft-actions.tsx';
import {
	FIELD_EDIT_BEGIN,
	FIELD_EDIT_CANCEL,
	FIELD_PASTE,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions.tsx';
import {
	FIELD_SAVE_REQUEST,
	type FieldAnalyticsPayload,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import { hierarchyLevelSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector.tsx';
import { analyticsPayloadSelector } from '@atlassian/jira-issue-view-store/src/selectors/analytics-payload-selector.tsx';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';
import { totalCommentsSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { issueLinkSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import {
	DESCRIPTION_TYPE,
	ENVIRONMENT_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	TEXT_AREA_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { AnalyticsActionsMap } from './types.tsx';

// visible for tests
export const FIELD_IDS = [COMMENT, DESCRIPTION_TYPE] as const;

const RICH_TEXT_FIELD_TYPES: string[] = [DESCRIPTION_TYPE, ENVIRONMENT_TYPE, TEXT_AREA_CF_TYPE];

// We've renamed these error strings in code to better reflect their meaning,
// however in order not to disrupt metrics we map the new strings to the older
// equivalents before sending analytics
const mapToLegacyErrorStrings = (error: null | string) => {
	switch (error) {
		case AUTHENTICATION_ERROR:
			return 'AUTHENTICATION';
		case NOT_FOUND_OR_NO_PERMISSION_ERROR:
			return 'PERMISSION';
		case UNKNOWN_ERROR:
		case CONNECTIVITY_ERROR:
		default:
			return 'GENERIC';
	}
};

const issueProjectData = (eventNameSuffix: string) => (state: State) => ({
	eventData: analyticsPayloadSelector(state),
	eventNameSuffix,
});

const stripCommentIdIfComment = (fieldId: string) =>
	fieldId.startsWith(COMMENT) ? COMMENT : fieldId;

const deleteDraftAnalytics =
	(eventNameSuffix: string) => (state: State, action: DeleteDraftAction) => {
		const {
			payload: { fieldId },
		} = action;
		const processedFieldId = fieldId !== null && stripCommentIdIfComment(fieldId);
		return {
			eventNameSuffix,
			eventData: {
				type: processedFieldId,
			},
		};
	};

const loadDraftAnalytics =
	(eventNameSuffix: string) => (state: State, action: LoadDraftSuccess | LoadDraftFailure) => {
		const {
			payload: { fieldId },
		} = action;
		const processedFieldId = fieldId != null ? stripCommentIdIfComment(fieldId) : fieldId;
		return {
			eventNameSuffix,
			eventData: {
				type: processedFieldId,
			},
		};
	};

const getAnalyticsProps = (
	updatedValues: FieldAnalyticsPayload | SaveCommentPayload | DeleteCommentPayload | null,
): Record<string, unknown> => {
	const props = ['updatedField', 'oldValId', 'newValId', 'oldValName', 'newValName'];

	return props
		.filter((key) => updatedValues && Object.hasOwnProperty.call(updatedValues, key)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Record<string, any>>((acc, next: string) => {
			const currentAnalyticsProp =
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'SaveCommentPayload | DeleteCommentPayload | FieldAnalyticsPayload'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'SaveCommentPayload | DeleteCommentPayload | FieldAnalyticsPayload'.
				updatedValues && updatedValues[next] ? { [next]: updatedValues[next] } : {};
			return { ...acc, ...currentAnalyticsProp }; // eslint-disable-line jira/js/no-reduce-accumulator-spread
		}, {});
};

const fetchIssueSuccessEventData = (state: State) => ({
	eventData: {
		commentCount: totalCommentsSelector(state),
	},
});

const addIssueLinkTypeData = (state: State, action: SaveLinkedIssueRequest) => {
	const {
		payload: {
			newIssueLinks: { issueLinkType, issueLinks, isOpenedFromJsmSimilarIssues },
		},
	} = action;
	const linkedIssues = issueLinks?.linkedIssues || {};
	const [issueId, issueProjectType, issueItsmPractice] = Object.keys(linkedIssues)?.map((key) => [
		linkedIssues[key].issueId,
		linkedIssues[key].issueProjectType,
		linkedIssues[key].issueItsmPractice,
	])[0] || ['', '', ''];
	return {
		eventData: {
			issueLinkTypeId: issueLinkType.id,
			issueLinkTypeName: safeLinkTypeName(issueLinkType.label),
			issueLinkLinkedIssueId: issueId,
			issueLinkLinkedIssueProjectType: issueProjectType,
			issueLinkLinkedIssueItsmPractice: issueItsmPractice,
			isOpenedFromJsmSimilarIssues,
		},
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteIssueLinkTypeData = (linkedIssue: any) => ({
	eventData: {
		issueLinkTypeId: linkedIssue.typeId,
		issueLinkTypeName: safeLinkTypeName(linkedIssue.typeName),
	},
});

const getAdfBody = (bodyAdf?: ADF) => (bodyAdf ? JSON.stringify(bodyAdf).length : 0);

const getAnalyticsDataForSavedComment = (comment: Comment) => {
	const charactersInCommentCount = getAdfBody(comment.bodyAdf);

	return {
		isInternal: comment.isInternal, // whether this is an internal comment in a JSD issue
		charactersInCommentCount,
	};
};

const getAnalyticsDataForActionItems = (adf?: ADF) =>
	expVal('issue_view_action_items', 'isActionItemsEnabled', false) ? getTaskItemsMetadata(adf) : {};

const actionsMap: AnalyticsActionsMap = {
	[SAVE_LINKED_ISSUE_REQUEST]: (state, action) =>
		merge(issueProjectData('issue-links.save.request'), addIssueLinkTypeData(state, action)),
	[SAVE_REMOTE_LINKED_ISSUE_REQUEST]: issueProjectData('issue-links.save.request'),
	[DELETE_LINKED_ISSUE_REQUEST]: (state, action) =>
		merge(
			issueProjectData('issue-links.delete.request'),
			deleteIssueLinkTypeData(issueLinkSelector(action.payload.linkedIssueId)(state)),
		),
	[SAVE_LINKED_ISSUE_SUCCESS]: issueProjectData('issue-links.save.success'),
	[SAVE_LINKED_ISSUE_FAILURE]: issueProjectData('issue-links.save.failure'),
	[SAVE_REMOTE_LINKED_ISSUE_SUCCESS]: issueProjectData('issue-links.save.success'),
	[SAVE_REMOTE_LINKED_ISSUE_FAILURE]: issueProjectData('issue-links.save.failure'),
	[SAVE_LINKED_ISSUE_RETRY]: issueProjectData('issue-links.save.retry'),
	[SAVE_REMOTE_LINKED_ISSUE_RETRY]: issueProjectData('issue-links.save.retry'),
	[SAVE_LINKED_ISSUE_CANCEL]: issueProjectData('issue-links.save.cancel'),

	[FETCH_OLDER_COMMENTS_REQUEST]: issueProjectData('comment.fetcholder.click'),
	[FETCH_NEWER_COMMENTS_REQUEST]: issueProjectData('comment.fetchnewer.click'),

	[EDIT_COMMENT_BEGIN]: (state) => issueProjectData('comment.edit.linkclicked')(state),

	[SAVE_COMMENT_SUCCESS]: (state, action: SaveCommentSuccessAction) =>
		merge(issueProjectData('comment.save.success')(state), {
			eventData: {
				...getAnalyticsProps(action.payload),
				actionSubjectId: action.payload.comment.id,
				actionSubjectOptimisticId: action.payload.optimisticId,
				commentSessionId: action.payload.commentSessionId,
				...getAnalyticsDataForSavedComment(action.payload.comment),
				...getAnalyticsDataForActionItems(action.payload.comment.bodyAdf),
			},
		}),

	[DELETE_COMMENT_REQUEST]: (state, action: DeleteCommentRequestAction) =>
		merge(issueProjectData('comment.delete.modal.confirm')(state), {
			eventData: {
				...getAnalyticsProps(action.payload),
				actionSubjectId: action.payload.id,
			},
		}),

	[PASTED_INTO_COMMENT_FORM]: (state, action) =>
		merge(issueProjectData('comment.paste')(state), {
			eventData: {
				isNewComment: action.payload.id === NEW_COMMENT_ID,
				pastedContentLength: (action.payload.pastedContent || '').length,
				containsEmailAddress: containsValidEmailAddress(action.payload.pastedContent),
			},
		}),

	[FETCH_ISSUE_SUCCESS]: (state) =>
		merge(issueProjectData('fetchissue.succeeded')(state), fetchIssueSuccessEventData(state)),

	[REFRESH_ISSUE_SUCCESS]: (state) =>
		merge(issueProjectData('refreshissue.succeeded')(state), fetchIssueSuccessEventData(state)),

	[REFRESH_ISSUE_REQUEST]: issueProjectData('refreshissue.request'),

	[FETCH_ISSUE_REQUEST]: issueProjectData('fetchissue.request'),

	[LOAD_NEW_ISSUE]: issueProjectData('load-new-issue'),

	[FETCH_ISSUE_FAILURE]: (state, action) => ({
		eventNameSuffix: 'fetchissue.failed',
		eventData: {
			error: mapToLegacyErrorStrings(errorSelector(state)),
			statusCode: action.payload.statusCode,
			analyticsSource: analyticsSourceSelector(state),
		},
	}),

	[DELETE_DRAFT_REQUEST]: deleteDraftAnalytics('issue.delete-draft.request'),
	[DELETE_DRAFT_SUCCESS]: deleteDraftAnalytics('issue.delete-draft.success'),
	[DELETE_DRAFT_FAILURE]: deleteDraftAnalytics('issue.delete-draft.failure'),
	[DELETE_DRAFT_NEWER_CONTENT_FOUND]: deleteDraftAnalytics(
		'issue.delete-draft.replaced-by-new-content',
	),
	// LOAD_DRAFT_REQUEST not logged as this is triggered every time BENTO opens
	[LOAD_DRAFT_SUCCESS]: loadDraftAnalytics('issue.load-draft.success'),
	[LOAD_DRAFT_FAILURE]: loadDraftAnalytics('issue.load-draft.failure'),

	[LOGIN_SUCCESSFUL]: (state) => ({
		eventNameSuffix: 'login.succeeded',
		eventData: {
			analyticsSource: analyticsSourceSelector(state),
		},
	}),

	// New re-usable fields analytics events
	[FIELD_EDIT_BEGIN]: (state, action) =>
		merge(issueProjectData('field.edit')(state), {
			eventData: {
				fieldType: fieldTypeSelector(action.payload.fieldId)(state),
				// uuid should be safe
				fieldEditSessionId: action.payload.fieldEditSessionId,
				isReusableField: true,
			},
		}),

	[FIELD_SAVE_REQUEST]: (state, action) => {
		const fieldType = fieldTypeSelector(action.payload.fieldId)(state);
		return merge(issueProjectData('field.save')(state), {
			eventData: {
				...getAnalyticsProps(action.payload.eventData || null),
				fieldType,
				...(fieldType === MULTI_CHECKBOXES_CF_TYPE &&
					fg('issue_view_checkbox_analytics_options_count') && {
						fieldOptionsCount: fieldAllowedValuesSelector(action.payload.fieldId)(state).length,
					}),
				fieldEditSessionId: fieldEditSessionIdSelector(action.payload.fieldId)(state),
				isReusableField: true,
				issueHierarchyLevel: hierarchyLevelSelector(state),
				...(RICH_TEXT_FIELD_TYPES.includes(fieldType ?? '')
					? getAnalyticsDataForActionItems(action.payload.value)
					: {}),
			},
		});
	},

	[FIELD_EDIT_CANCEL]: (state, action) =>
		merge(issueProjectData('field.cancel')(state), {
			eventData: {
				fieldType: fieldTypeSelector(action.payload.fieldId)(state),
				fieldEditSessionId: fieldEditSessionIdSelector(action.payload.fieldId)(state),
				isReusableField: true,
			},
		}),

	[FIELD_PASTE]: (state, action) =>
		merge(issueProjectData('field.paste')(state), {
			eventData: {
				fieldType: fieldTypeSelector(action.payload.fieldId)(state),
				isReusableField: true,
				pastedContentLength: action.payload.pastedContent.length,
				containsEmailAddress: containsValidEmailAddress(action.payload.pastedContent),
			},
		}),
	[LINKED_ISSUE_PROJECT_ANALYTICS_SUCCESS]: issueProjectData('issue-links.analytics'),
};

export default actionsMap;
