import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';
import { fg } from '@atlassian/jira-feature-gating';
import type { PolarisRoute } from '../types.tsx';

const storage = createLocalStorageProvider('polaris');

type StorageRouteSlug = PolarisRoute & {
	storageKey?: string;
};

const ROUTE_SLUG_STORAGE_KEY = 'polaris-route-slug';

export const setStorageRouteSlug = (route: StorageRouteSlug) => {
	if (fg('jpd-refactor-route-slug-storage')) {
		AtlBrowserStorageLocal.setItem(ROUTE_SLUG_STORAGE_KEY, JSON.stringify(route));
		return;
	}
	storage.set('routeslug', route);
};

// remove with `jpd-refactor-route-slug-storage` FG cleanup
export const getStorageRouteSlugWithLegacySupport = (): StorageRouteSlug | undefined => {
	const localRouteSlug = storage.get('routeslug');

	if (localRouteSlug !== null && localRouteSlug !== undefined) {
		return localRouteSlug;
	}

	const legacyLocalRouteSlug = storage.get('viewslug');

	if (legacyLocalRouteSlug !== null && legacyLocalRouteSlug !== undefined) {
		return {
			section: legacyLocalRouteSlug.section,
			resource: legacyLocalRouteSlug.id,
		};
	}

	return undefined;
};

export const getStorageRouteSlug = (): StorageRouteSlug | undefined => {
	const routeSlug = AtlBrowserStorageLocal.getItem(ROUTE_SLUG_STORAGE_KEY);

	if (routeSlug !== null && routeSlug !== undefined) {
		try {
			return JSON.parse(routeSlug);
		} catch {
			// do nothing
		}
	}

	return undefined;
};
