import React, { useCallback, useState, type ReactNode, type ReactChild } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import {
	JiraPopup as Popup,
	type JiraPopupProps as PopupProps,
} from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	useResponseFeedbackContext,
	useSubmittedPrompt,
} from '../../../controllers/prompt-state/index.tsx';
import { useFeedbackService } from '../../../services/feedback/index.tsx';
import type { FeedbackData } from '../../types.tsx';
import { FeedbackPopupContent } from './feedback-popup-content/index.tsx';

type Props = {
	appearance?: ButtonProps['appearance'];
	children?: ReactNode;
	iconBefore?: ReactChild;
	isErrorReport?: boolean;
	isDisabled?: boolean;
	isPositive: boolean;
	isSelected?: boolean;
	label?: string;
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	onClick: () => boolean | void;
	onSubmit?: () => void;
	offset?: PopupProps['offset'];
	placement?: PopupProps['placement'];
	spacing?: ButtonProps['spacing'];
	testId?: string;
	tooltipContent?: ReactNode;
};
export const FeedbackButton = ({
	appearance,
	children,
	iconBefore,
	isErrorReport,
	isDisabled,
	isPositive,
	isSelected,
	label,
	onClick,
	onSubmit,
	offset,
	placement,
	spacing,
	testId,
	tooltipContent,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const [submittedPrompt] = useSubmittedPrompt();
	const [feedbackContext] = useResponseFeedbackContext();
	const { onSubmitFeedback } = useFeedbackService(submittedPrompt, feedbackContext);

	const handleClick = useCallback(() => {
		// Prevent opening of the popup if the click event has been explicity handled by the parent
		const handled = onClick();
		if (handled) {
			return;
		}
		setIsOpen(true);
	}, [onClick]);

	const handleSubmit = useCallback(
		async (data: FeedbackData, isPositiveFeedback: boolean) => {
			await onSubmitFeedback(data, isPositiveFeedback);
			onSubmit && onSubmit();
		},
		[onSubmit, onSubmitFeedback],
	);

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<Popup
			isOpen={isOpen}
			onClose={handleClose}
			placement={placement}
			offset={offset}
			content={({ setInitialFocusRef }) => (
				<FeedbackPopupContent
					isErrorReport={isErrorReport}
					isPositive={isPositive}
					onClose={handleClose}
					onSubmit={handleSubmit}
					setInitialFocusRef={setInitialFocusRef}
				/>
			)}
			messageType="transactional"
			trigger={(triggerProps) => (
				<Tooltip content={tooltipContent ?? null}>
					<Button
						{...triggerProps}
						aria-label={label}
						appearance={appearance}
						spacing={spacing}
						iconBefore={iconBefore}
						onClick={handleClick}
						isSelected={isSelected}
						isDisabled={isDisabled}
						testId={testId}
					>
						{children}
					</Button>
				</Tooltip>
			)}
		/>
	);
};
