import { isAriResourceIdValid } from '../is-ari-resource-id-valid';
import { isNotEmptyString } from '../is-not-empty-string';

export type SelectPublicSetValue = null | string;

export const selectSetValueValidator = (value: unknown): value is SelectPublicSetValue =>
	value === null || isNotEmptyString(value);

export const selectWithAriSetValueValidator = (value: unknown): value is SelectPublicSetValue =>
	value === null || (isNotEmptyString(value) && isAriResourceIdValid(value));
