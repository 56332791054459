import React, { memo } from 'react';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { PlaybooksPanel } from '@atlassian/jira-playbooks-agent-view/src/ui/playbook-panel/PlaybooksPanel.tsx';

const Playbooks = () => {
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const cloudId = useCloudId();
	const projectKey = useProjectKey(issueKey);

	if (!isIssueOfIncidentsPractice || !issueId) {
		return null;
	}

	return (
		<JSErrorBoundary id="PlaybooksPanel" packageName="jiraIssueViewBase" fallback="unmount">
			<PlaybooksPanel issueId={issueId} cloudId={cloudId} projectKey={projectKey} />
		</JSErrorBoundary>
	);
};

export default memo(Playbooks);
