import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import { getAriConfig, type Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { getMediaIds } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '../../../types.tsx';
import { getProperties } from '../index.tsx';

const EMPTY_CONTRIBUTIONS = Object.freeze({});

export const getPlayContributionsMap = (state: State) => getProperties(state).plays;

export const getPlayContributions = (playId: Ari) =>
	createSelector(getPlayContributionsMap, (playContributionsMap) => {
		const playResourceId = getAriConfig(playId).resourceId;
		return playContributionsMap[playResourceId] ?? EMPTY_CONTRIBUTIONS;
	});
/**
 * Returns sum of all contributions amouts for a specific play/idea
 */
export const createGetIdeaPlayTotalBudget = (playId: Ari, id: LocalIssueId) => {
	const playContributions = getPlayContributions(playId);
	return createSelector(playContributions, (contributionsMap) =>
		(contributionsMap[id] ?? []).reduce((acc, { amount = 0 }) => acc + amount, 0),
	);
};

export const createGetPlaysMediaIds = createSelector(getPlayContributionsMap, (contributionsMap) =>
	flattenDeep(
		flattenDeep(
			Object.values(contributionsMap).map((contributions) => Object.values(contributions)),
		).map((contribution: PolarisPlayContribution) => getMediaIds(contribution?.comment?.body)),
	),
);

export const createGetIdeaPlaysIdsWithContributions = (localIssueId: LocalIssueId) =>
	createSelector(getPlayContributionsMap, (contributionsMap) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.keys(contributionsMap).reduce<Record<string, any>>((result, playId) => {
			Object.keys(contributionsMap[playId]).forEach((id) => {
				if (localIssueId !== id || contributionsMap[playId][id].length === 0) {
					return;
				}
				Object.assign(result, {
					[playId]: contributionsMap[playId][id],
				});
			});
			return result;
		}, {}),
	);
/**
 * Returns total amout of contributions for a specific play/idea
 */
export const createGetIdeaPlayTotalContributors = (playId: Ari, id: LocalIssueId) => {
	const playContributions = getPlayContributions(playId);
	return createSelector(
		playContributions,
		(contributionsMap) => (contributionsMap[id] ?? []).length,
	);
};

/**
 * Returns all contributions for a specific play/idea
 */
export const createGetIdeaPlayContributions = (playId: Ari, id: LocalIssueId) => {
	const playContributions = getPlayContributions(playId);
	return createSelector(playContributions, (contributionsMap) => contributionsMap[id] ?? []);
};

/**
 * Returns user's contribution for a specific play/idea
 */
export const createGetIdeaPlayUserContribution = (
	playId: Ari,
	localIssueId: LocalIssueId,
	accountId: AccountId | null,
) => {
	const getIdeaPlayContributions = createGetIdeaPlayContributions(playId, localIssueId);

	return createSelector(
		getIdeaPlayContributions,
		(contributions) => contributions.filter(({ aaid }) => aaid === accountId)[0],
	);
};

/**
 * Returns array of contributors avatars for a specific play/idea
 */
export const createGetIdeaPlayContributorsAvatars = (playId: Ari, localIssueId: LocalIssueId) => {
	const getIdeaPlayContributions = createGetIdeaPlayContributions(playId, localIssueId);

	return createSelector(getIdeaPlayContributions, (contributions) => {
		const c = contributions
			.map(({ author }) => {
				if (author === undefined) {
					return undefined;
				}
				return {
					href: '#',
					src: author.avatarUrls[fg('jpd-retina-avatars') ? '48x48' : '24x24'],
				};
			})
			.filter(Boolean);
		return uniqBy(c, 'src');
	});
};

// TODO: remove during FG "jpd_votes_exceeding_max_amount_fix" cleanup
/**
 * Returns array of contributors by user for a specific play/idea
 */
const createGetPlayContributionsByUser = (playId: Ari, userId: AccountId | null) => {
	const playContributions = getPlayContributions(playId);

	return createSelector(playContributions, (contributionsMap) => {
		const contributionsArray = Object.keys(contributionsMap).map((key) => contributionsMap[key]);
		return contributionsArray.reduce<Array<PolarisPlayContribution>>((acc, contributions) => {
			const userContributions = contributions.filter(({ aaid }) => aaid === userId);
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			return [...acc, ...userContributions];
		}, []);
	});
};

// TODO: remove during FG "jpd_votes_exceeding_max_amount_fix" cleanup
/**
 * Returns total amout of contributors by user for a specific play/idea
 */
export const createGetTotalPlayContributionsAmoutByUser = (
	playId: Ari,
	userId: AccountId | null,
) => {
	const getPlayContributionsByUser = createGetPlayContributionsByUser(playId, userId);

	return createSelector(getPlayContributionsByUser, (contributions) =>
		contributions.reduce((acc, { amount }) => acc + (amount ?? 0), 0),
	);
};

/**
 * Returns contributions stats for a specific play
 */
export const createGetPlayContributionsStats = (playId: Ari) => {
	const playContributions = getPlayContributions(playId);

	return createSelector(playContributions, (contributionsMap) => {
		const contributions = flatten(
			Object.keys(contributionsMap).map((key) => contributionsMap[key]),
		);
		const stats = contributions.reduce(
			(acc, { amount }: PolarisPlayContribution) => ({
				votes: acc.votes + (amount ?? 0),
				comments: acc.comments + 1,
			}),
			{
				votes: 0,
				comments: 0,
			},
		);
		const voters = [...new Set(contributions.map(({ aaid }) => aaid))].length;
		return { ...stats, ...{ voters } };
	});
};

export const createGetPlayContributionsAmountIssuesCounts = (playId: Ari, ids: LocalIssueId[]) => {
	const playContributions = getPlayContributions(playId);
	return createSelector(playContributions, (contributions) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		ids.forEach((localId) => {
			result[localId] = (contributions[localId] ?? []).reduce(
				(acc, { amount = 0 }) => acc + amount,
				0,
			);
		});
		return result;
	});
};
