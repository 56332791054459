import { useCallback, useMemo } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import {
	ACTION_TAKEN_REMOVE,
	ACTION_TAKEN_ADD,
} from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFlaggedField } from '@atlassian/jira-issue-field-flagged/src/services/main.tsx';
import { transformToStateValue } from '@atlassian/jira-issue-field-flagged/src/services/utils.tsx';
import { useIssueTypeField } from '@atlassian/jira-issue-field-issue-type/src/services/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { FLAGGED_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	type IssueForFlagWithCommentModal,
	FlagAction,
} from '@atlassian/jira-software-add-issue-flag-with-comment/src/common/types.tsx';
import { useFlagIssueModalActions } from '@atlassian/jira-software-add-issue-flag-with-comment/src/controllers/flag-with-comment/index.tsx';
import messages from './messages.tsx';

const useToggleFlagWithComment = (itemKey: string, onClick?: () => void) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const issueId = useIssueId() || '';
	const [{ value: summary }] = useSummaryField({ issueKey, issueId });
	const [{ value: issueType }] = useIssueTypeField({ issueKey });

	const { showFlag } = useFlagsService();
	const { open } = useFlagIssueModalActions();
	const addFlagFailedTitleMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.addFlagFailedTitleIssueTermRefresh
		: messages.addFlagFailedTitle;

	const removeFlagFailedTitleMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.removeFlagFailedTitleIssueTermRefresh
		: messages.removeFlagFailedTitle;
	const onFailure = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'failedValue' implicitly has an 'any' type.
		(_, failedValue) => {
			showFlag({
				type: 'error',
				title: failedValue ? addFlagFailedTitleMessage : removeFlagFailedTitleMessage,
				description: failedValue
					? messages.addFlagFailedDescription
					: messages.removeFlagFailedDescription,
			});
		},
		[showFlag, addFlagFailedTitleMessage, removeFlagFailedTitleMessage],
	);
	const [fieldKey] = useSearchCustomFieldKeys(issueKey, FLAGGED_CF_TYPE);
	const [{ value: isFlagged }, { saveValue }] = useFlaggedField({
		issueKey,
		fieldKey: fieldKey || '',
		// @ts-expect-error - TS2322 - Type '(_: any, failedValue: any) => void' is not assignable to type '(arg1: Error) => void'.
		onFailure,
	});

	const issue: IssueForFlagWithCommentModal = useMemo(
		() => ({
			issueId,
			issueKey,
			summary,
			type: {
				id: issueType?.id ?? '',
				name: issueType?.name ?? '',
				iconUrl: issueType?.iconUrl ?? '',
			},
		}),
		[issueId, issueKey, issueType?.iconUrl, issueType?.id, issueType?.name, summary],
	);

	const markAsFlagged = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		const newValue = transformToStateValue(!isFlagged);

		if (fieldKey && fg('one_event_rules_them_all_fg')) {
			getUpdateAnalyticsFlowHelper().setAttributes(fieldKey, {
				actionTaken: newValue ? ACTION_TAKEN_ADD : ACTION_TAKEN_REMOVE,
			});
		}

		saveValue(newValue, null, analyticsEvent);
		onClick?.();
	}, [onClick, saveValue, isFlagged, fieldKey, createAnalyticsEvent]);

	return useCallback(() => {
		open({
			issues: [issue],
			flagAction: isFlagged ? FlagAction.REMOVE : FlagAction.ADD,
			callback: markAsFlagged,
			source: 'issueView',
		});
	}, [isFlagged, issue, open, markAsFlagged]);
};

export default useToggleFlagWithComment;
