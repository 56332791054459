import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { getIsClassicSubtaskPanel } from '../../../state/context/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import { showInlineCreate, type ShowInlineCreateAction } from '../../../state/ui/actions.tsx';
import type { AddChildEventHandler, DispatchProps, StateProps } from './types.tsx';
import { AddChild } from './view.tsx';

export default flow(
	ComponentWithAnalytics('button', {
		onClick: 'clicked',
	}),
	withFireUiAnalytics({
		onClick: (...args: Parameters<AddChildEventHandler>) => {
			const [, , issueCreateSessionId] = args;
			return {
				name: 'showChildIssuesInlineCreate',
				sessionId: issueCreateSessionId,
			};
		},
	}),
	connect(
		(state: State): StateProps => ({
			isClassicSubtaskPanel: getIsClassicSubtaskPanel(state),
		}),
		(dispatch: Dispatch<ShowInlineCreateAction>, props: DispatchProps): DispatchProps => ({
			onClick: (...args) => {
				dispatch(showInlineCreate());
				props.onClick(...args);
			},
		}),
	),
)(AddChild);
