import React, { type ComponentType, useRef, useEffect } from 'react';
import type { MediaProvider } from '@atlaskit/editor-common/provider-factory';
import { PromiseProvider } from '../../common/utils/index.tsx';
import createMediaProvider from '../create-media-provider/index.tsx';
import {
	useTokenProvider,
	type WithMediaContextProps,
	type WithTokenProviderProps,
} from '../token-provider/index.tsx';

export type WithMediaProviderProps = {
	mediaProvider: Promise<MediaProvider>;
};

type RequiredProps = {
	baseUrl: string;
	disableMediaDownload?: boolean;
};

/**
 * HOC to add a mediaProvider:promise prop for use in Atlaskit components.
 * Funky promise logic waits until the viewContext is available before resolving
 * @see https://atlaskit.atlassian.com/packages/media/media-picker#auth-provider
 * @see also ../token-provider which we consume here, and works in much the same way
 */
const withMediaProvider =
	<Props,>(
		WrappedComponent: ComponentType<Props & RequiredProps & WithMediaProviderProps>,
	): ComponentType<Props & RequiredProps & WithMediaContextProps & WithTokenProviderProps> =>
	(props: Props & RequiredProps & WithTokenProviderProps & WithMediaContextProps) => {
		const mediaProviderPromise = useRef(new PromiseProvider<MediaProvider>());

		const { mediaContext, onViewRefresh, onUploadRefresh, disableMediaDownload, ...otherProps } =
			props;
		const tokenProvider = useTokenProvider(mediaContext, onViewRefresh, onUploadRefresh);
		useEffect(() => {
			if (mediaProviderPromise.current.status !== 'resolved' && mediaContext.viewContext) {
				const mediaProvider = createMediaProvider(
					mediaContext,
					tokenProvider,
					props.baseUrl,
					disableMediaDownload,
				);

				mediaProviderPromise.current.resolve(mediaProvider);
			}
		}, [mediaContext, props.baseUrl, disableMediaDownload, tokenProvider]);

		const mediaProvider: Promise<MediaProvider> = mediaProviderPromise.current.get();

		return (
			<WrappedComponent
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{...(otherProps as Props & RequiredProps & WithMediaProviderProps)}
				mediaProvider={mediaProvider}
			/>
		);
	};

export default withMediaProvider;
