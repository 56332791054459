import React, { useState, useEffect, useMemo, useCallback, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { layers, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import isSubstringFound from '@atlassian/jira-common-util-is-substring-found/src/index.tsx';
import type {
	DropdownTriggerParams,
	CreatePullRequestTarget,
} from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { SearchField } from '@atlassian/jira-searchfield/src/index.tsx';
import { DevSummaryCreatePullRequestPopupSkeleton } from '@atlassian/jira-skeletons/src/ui/dev-summary-create-pull-request-popup/DevSummaryCreatePullRequestPopupSkeleton.tsx';
import { CreatePullRequestAuthenticationRow } from './create-pull-request-authentication-row/index.tsx';
import {
	CreatePullRequestTargetRow,
	getTargetDisplayName,
} from './create-pull-request-target-row/index.tsx';
import messages from './messages.tsx';
import { NoEligibleBranches } from './no-eligible-branches/index.tsx';
import { NoResultsFound } from './no-results-found/index.tsx';

export type Props = {
	hasErrors: boolean;
	renderDropdownTrigger: (props: DropdownTriggerParams) => ReactElement;
	createPullRequestTargets: CreatePullRequestTarget[] | null | undefined;
	onAuthErrorClick?: () => void;
	onOpenDropdown?: (arg1: { isOpen: boolean }) => void;
};
export const useIsDropdownOpen = (isOpen = false) => useState(isOpen);

const CreatePullRequestDropdownInner = ({
	createPullRequestTargets,
	renderDropdownTrigger,
	onOpenDropdown,
	hasErrors,
	onAuthErrorClick,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useIsDropdownOpen();
	const [filteredTargets, setFilteredTargets] = useState(createPullRequestTargets ?? []);
	const wasOpen = usePreviousWithInitial(isOpen);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setFilteredTargets(createPullRequestTargets ?? []);
	}, [createPullRequestTargets]);

	const filterTargetsBySearch = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'searchTerm' implicitly has an 'any' type.
		(searchTerm) => {
			setFilteredTargets(
				createPullRequestTargets
					? createPullRequestTargets.filter((target) => {
							const targetDisplayName = getTargetDisplayName(target);
							return isSubstringFound(targetDisplayName, searchTerm);
						})
					: [],
			);
		},
		[createPullRequestTargets],
	);

	useEffect(() => {
		if (wasOpen !== isOpen) {
			isOpen &&
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown opened', 'createPullRequestDropdown');
			!isOpen &&
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown closed', 'createPullRequestDropdown');
			onOpenDropdown && onOpenDropdown({ isOpen });
		}
	}, [createAnalyticsEvent, wasOpen, isOpen, onOpenDropdown]);

	const CreatePullRequestTargets = useMemo(
		() => (
			<TargetsContainer>
				{hasErrors && (
					<CreatePullRequestAuthenticationRow
						onClick={() => {
							onAuthErrorClick && onAuthErrorClick();
							setIsOpen(false);
						}}
					/>
				)}
				{filteredTargets.map((target, index) => (
					<CreatePullRequestTargetRow
						key={`${index}-${target.createPullRequestUrl}`}
						createPullRequestTarget={target}
					/>
				))}
			</TargetsContainer>
		),
		[filteredTargets, hasErrors, onAuthErrorClick, setIsOpen],
	);

	const DropDownWithTargetsContent = useMemo(() => {
		let targetsContent;

		if (
			createPullRequestTargets &&
			createPullRequestTargets.length > 0 &&
			filteredTargets.length === 0
		) {
			targetsContent = <NoResultsFound />;
		} else if (createPullRequestTargets && createPullRequestTargets.length >= 0) {
			targetsContent = CreatePullRequestTargets;
		} else {
			targetsContent = <DevSummaryCreatePullRequestPopupSkeleton />;
		}

		return (
			<Box xcss={dropDownWithTargetsContentWrapperStyles}>
				<SearchFieldWrapper>
					<SearchField
						onChange={(value: string) => filterTargetsBySearch(value)}
						onBlur={() => {
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'input blurred',
								'createPullRequestListFilter',
							);
						}}
						placeholder={formatMessage(messages.searchButtonPlaceholder)}
						shouldFitContainerWidth
						isAutocompleteDisabled
						placeholderAlwaysVisible
						searchIconVisible={false}
					/>
				</SearchFieldWrapper>
				<Hr />
				{targetsContent}
			</Box>
		);
	}, [
		createPullRequestTargets,
		filteredTargets,
		CreatePullRequestTargets,
		createAnalyticsEvent,
		filterTargetsBySearch,
		formatMessage,
	]);

	const DropDownContent =
		createPullRequestTargets && createPullRequestTargets.length === 0 && !hasErrors ? (
			<NoEligibleBranches />
		) : (
			DropDownWithTargetsContent
		);

	return (
		<Popup
			id="create-pull-request-dropdown"
			testId="development-summary-pull-request.ui.create-pull-request-summary-item.create-pull-request-dropdown.popup"
			zIndex={layers.modal + 1}
			placement="bottom-end"
			shouldFlip
			trigger={(triggerProps) =>
				renderDropdownTrigger({
					...triggerProps,
					isSelected: isOpen,
					onClick: () => setIsOpen(!isOpen),
				})
			}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={() => (
				<ContextualAnalyticsData
					attributes={{
						linkListLength: createPullRequestTargets ? createPullRequestTargets.length : 0,
						filteredListLength: filteredTargets.length,
					}}
				>
					{DropDownContent}
				</ContextualAnalyticsData>
			)}
		/>
	);
};

export const CreatePullRequestDropdown = ({
	renderDropdownTrigger,
	createPullRequestTargets,
	onOpenDropdown,
	hasErrors,
	onAuthErrorClick,
}: Props) => (
	<CreatePullRequestDropdownInner
		renderDropdownTrigger={renderDropdownTrigger}
		createPullRequestTargets={createPullRequestTargets}
		onOpenDropdown={onOpenDropdown}
		hasErrors={hasErrors}
		onAuthErrorClick={onAuthErrorClick}
	/>
);

const dropDownWithTargetsContentWrapperStyles = xcss({
	marginTop: 'space.300',
	marginBottom: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchFieldWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 46}px`,
	marginTop: '0px',
	marginRight: token('space.200', '16px'),
	marginBottom: token('space.200', '16px'),
	marginLeft: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Hr = styled.hr({
	border: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `2px solid ${token('color.border', colors.N30)}`,
	margin: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TargetsContainer = styled.div({
	maxHeight: '190px',
	overflow: 'auto',
});
