import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isNotEmptyString } from '@atlassian/ui-modifications-public-api-utils/is-not-empty-string';
import { commonIssueViewGranularScopes } from './fields-scopes.tsx';

type StatusInternalIdShapeValue = { transitionId: string };

type StatusInternalFullShapeValue = {
	id: string;
	name: string;
	statusCategory: {
		id: string;
		name: string;
	};
};

export type StatusPublicGetValue = {
	id: string;
	name: string;
};

export type StatusPublicSetValue = string;

export const validateStatusSetValue = (value: unknown): value is StatusPublicSetValue =>
	isNotEmptyString(value);

export const selectScopes = baseScopesRequiredPerField;

export const transformStatusSetValue = (
	value: StatusPublicSetValue,
): StatusInternalIdShapeValue => ({ transitionId: value });

export const transformStatusGetValue = (
	value: StatusInternalFullShapeValue | null,
): StatusPublicGetValue | null => (value ? { id: value.id, name: value.name } : null);

export const statusIssueViewScopes = {
	...selectScopes,
	granular: [...selectScopes.granular, ...commonIssueViewGranularScopes, 'read:issue-status:jira'],
};
