import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIssueKey,
	useAnalyticsSource,
	useIssueId,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { HEADER } from '@atlassian/jira-issue-field-summary/src/common/constants.tsx';
import SummaryField from '@atlassian/jira-issue-field-summary/src/ui/main.tsx';
import type { Props } from '@atlassian/jira-issue-field-summary/src/ui/types.tsx';
import { withExperienceTracking } from '@atlassian/jira-issue-fields-experience/src/index.tsx';
import { ItemBusyWrapperByIssueKey } from '@atlassian/jira-issue-view-common/src/component/item-busy-wrapper/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { sweetStateFieldUpdated } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

const ExperienceTrackedSummaryField = withExperienceTracking<Props>(SummaryField);

type UpdateProps = {
	onUpdate?: <T>(arg1: T) => void;
};
export const ExtractedSummaryField = (props: UpdateProps) => {
	const issueKey = useIssueKey();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = fg('relay-migration-issue-fields-summary') ? useIssueId() : undefined;
	const source = useAnalyticsSource();
	return (
		<ItemBusyWrapperByIssueKey fieldId={SUMMARY_TYPE} issueKey={issueKey}>
			<ExperienceTrackedSummaryField
				forField={SUMMARY_TYPE}
				issueId={issueId}
				issueKey={issueKey}
				source={source}
				appearance={HEADER}
				{...props}
			/>
		</ItemBusyWrapperByIssueKey>
	);
};

const ConnectedExtractedSummaryField = connect(null, (dispatch) => ({
	// This is fired after successful update from the API, and fire an action
	// that is picked up by backlog.
	onUpdate: (value: unknown) => dispatch(sweetStateFieldUpdated(SUMMARY_TYPE, value)),
}))(ExtractedSummaryField);

export default ConnectedExtractedSummaryField;
