import React, { useMemo } from 'react';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	ISSUE_ACTION_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { LazyIssueViewForgeExtension } from '@atlassian/jira-forge-issue-view-base/src/ui/async/index.tsx';
import type {
	LazyComponentProps,
	LazyRenderer,
} from '@atlassian/jira-forge-ui-async/src/types.tsx';
import type { IssueAction as IssueActionType } from '../IssueAction.tsx';

export const LazyIssueActionRenderer: LazyRenderer<typeof IssueActionType> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint<LazyRenderer<typeof IssueActionType>>(
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-issue-action" */ '@atlassian/jira-forge-issue-action/src/ui/IssueAction.tsx'
			),
		{ ssr: false },
	),
);

export const LazyIssueAction = (props: LazyComponentProps<typeof IssueActionType>) => {
	const { extension, extensionData } = props;
	const extensionDataValue = useMemo(
		() => ({
			...extensionData,
			type: ISSUE_ACTION_MODULE,
		}),
		[extensionData],
	);
	const extraProps = useMemo(
		() => ({
			module: ISSUE_ACTION_MODULE,
			extensionData: extensionDataValue,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue],
	);

	if (isNativeUiExtension(extension) || isCustomUIExtension(extension)) {
		return (
			<Placeholder name="issue-action" fallback={null}>
				<LazyIssueViewForgeExtension
					{...props}
					{...extraProps}
					modalExtension={{
						closeModalExtension: props.onClose,
						title: extension.properties.title,
						modalWidth: extension.properties.viewportSize,
					}}
					onModalExtensionClose={props.onClose}
				/>
			</Placeholder>
		);
	}

	return (
		<Placeholder name="issue-action" fallback={null}>
			<LazyIssueActionRenderer {...props} {...extraProps} />
		</Placeholder>
	);
};

export const AsyncIssueAction = LazyIssueAction;
