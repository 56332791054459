/**
 * @generated SignedSource<<bf44c1d99ad8ee8656c194a9a3f356f4>>
 * @relayHash 1b42d3ae71d08906518143d93b5393d4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2c4a5297dcf7456a85d37e509ecffb6458d390684805ae3fdc6ac19dec04468f

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateDateTimeFieldInput = {
  id: string;
  operation: JiraDateTimeFieldOperationInput;
};
export type JiraDateTimeFieldOperationInput = {
  datetime?: AGG$DateTime | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation$variables = {
  input: JiraUpdateDateTimeFieldInput;
};
export type dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation$data = {
  readonly jira: {
    readonly updateDateTimeField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly dateTime: AGG$DateTime | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation$rawResponse = {
  readonly jira: {
    readonly updateDateTimeField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly dateTime: AGG$DateTime | null | undefined;
        readonly id: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation = {
  rawResponse: dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation$rawResponse;
  response: dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation$data;
  variables: dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "dateTime"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraDateTimeFieldPayload",
            "kind": "LinkedField",
            "name": "updateDateTimeField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraDateTimePickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraDateTimeFieldPayload",
            "kind": "LinkedField",
            "name": "updateDateTimeField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraDateTimePickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2c4a5297dcf7456a85d37e509ecffb6458d390684805ae3fdc6ac19dec04468f",
    "metadata": {},
    "name": "dateTime_issueFieldDateTime_DateTimeFieldOld_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7520fca6dd1c12ddc4a3f71f7cea7484";

export default node;
