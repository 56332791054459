import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const TODO_SEGMENT_COLOR_HIGHER_CONTRAST = token('color.chart.neutral', colors.N500);

export const IN_PROGRESS_SEGMENT_COLOR_HIGHER_CONTRAST = token(
	'color.border.information',
	colors.B200,
);
export const DONE_SEGMENT_COLOR = token('color.border.success', colors.G300);
