import { useEffect, useMemo } from 'react';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import { useFieldKeyToAriMapAsync } from '@atlassian/jira-polaris-component-field-ari/src/controllers/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { getChangedProps } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/changed-props/index.tsx';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { createHook as createStoreHook, type StoreActionApi } from '@atlassian/react-sweet-state';
import type {
	PolarisIdeasCommonContextType,
	PolarisInsightUpdateSignal,
} from '../context/types.tsx';
import { useFieldActions } from '../field/main.tsx';
import { useNewlyAddedGlobalFields } from '../field/selectors/field-hooks.tsx';
import { useIssueViewLayout } from '../route/index.tsx';
import { useIsViewsLoading } from '../views/selectors/meta-hooks.tsx';
import { useAllViewFields } from '../views/selectors/view-hooks.tsx';
import actions from './actions/index.tsx';
import { updateCheckboxOrRatingNumberFieldMapping } from './actions/update-checkbox-or-rating-number-field-mapping/index.tsx';
import { EMPTY_STATE } from './constants.tsx';
import type { State, Props } from './types.tsx';

export type Actions = typeof actions;

export const IssueStore = createPolarisStore<State, Actions>({
	initialState: { ...EMPTY_STATE },
	actions,
	name: 'PolarisIssueStore',
});

const onUpdateContainer = (
	{ getState, setState, dispatch }: StoreActionApi<State>,
	props: Props,
) => {
	const {
		rankField,
		projectId,
		issueTypeIds,
		fields = [],
		polarisIssueLinkType,
		selectedIssue,
		singleIdeaVisible,
	} = props;

	const changedProps = getChangedProps<Props>(props, getState().prevContainerProps);
	const onlySelectedIssueChanged = isEqual(changedProps, ['currentViewSelectedIssueId']);

	if (changedProps.includes('plays')) {
		// since getChangedProps does a shallow check, 'plays' is added to changedProps even with no actual changes
		// it then causes the contributions in the issue store to be overwritten with the stale data in the project store
		// we still want the code here to execute the first time around as the initial loading takes place in the project store
		const playContributionsChanged = !isEqual(props.plays, getState().prevContainerProps?.plays);
		playContributionsChanged && dispatch(actions.updatePlayContributionsFromProps());
	}

	if (changedProps.includes('insights')) {
		dispatch(actions.updateInsightsFromProps());
	}

	setState({
		selectedIssue,
		externalIssueRanking: props.externalIssueRanking,
	});

	if (!onlySelectedIssueChanged) {
		dispatch(actions.resetLastUpdatedIssueIds());
	}

	if (selectedIssue === undefined) {
		dispatch(actions.clearCreatedProperty());
	}

	if (
		!getState().meta.fullDeliveryDataInitialized &&
		!singleIdeaVisible &&
		getState().prevContainerProps &&
		getState().prevContainerProps?.singleIdeaVisible
	) {
		dispatch(actions.loadDeliveryProgress());
	}

	const newLoadingPropsWithoutFields = {
		rankField,
		projectId,
		issueTypeIds,
		polarisIssueLinkType,
	};
	const newFieldKeys = keys(fields);

	const oldFieldKeys = getState().meta.loadingProps?.fieldKeys || [];
	const oldLoadingPropsWithoutFields = omit(getState().meta.loadingProps, 'fieldKeys');

	if (
		isShallowEqual(oldLoadingPropsWithoutFields, newLoadingPropsWithoutFields) &&
		isShallowEqual(oldFieldKeys, newFieldKeys)
	) {
		setState({
			meta: {
				...getState().meta,
				loadingProps: {
					...newLoadingPropsWithoutFields,
					fieldKeys: newFieldKeys,
				},
			},
		});
		// do nothing, loading props have not changed
		return;
	}

	setState({
		meta: {
			...getState().meta,
			loadingProps: {
				...newLoadingPropsWithoutFields,
				fieldKeys: newFieldKeys,
			},
		},
	});

	const isInitialized = oldFieldKeys.length > 0;
	const lastCreatedField =
		isInitialized && newFieldKeys.length > oldFieldKeys.length
			? fields[fields.length - 1]
			: undefined;

	const newCheckboxOrRatingField =
		lastCreatedField &&
		(lastCreatedField.type === FIELD_TYPES.CHECKBOX || lastCreatedField.type === FIELD_TYPES.RATING)
			? lastCreatedField
			: undefined;

	// checkbox and rating fields should be present in the properties
	// after they are created for the card height calculation since
	// a value of undefined is treated like a value of 0
	if (newCheckboxOrRatingField) {
		dispatch(actions.dispatch(updateCheckboxOrRatingNumberFieldMapping(newCheckboxOrRatingField)));
	}

	if (!onlySelectedIssueChanged) {
		dispatch(actions.resetLastUpdatedIssueIds());
	}

	if (props.fields !== undefined && props.fields.length > 0) {
		// create dynamic field selectors. the factory functions are solely based on the available fields, so
		// we can safely create them here, even before the ideas have been loaded
		dispatch(actions.createDynamicFieldSelectors());
	}
};

const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(IssueStore, {
	onUpdate: () => onUpdateContainer,
	onInit: () => onUpdateContainer,
});

export const useIssueActions = useActions;
export const createIssueHook = createHook;
export const createHigherLevelIssueHook = createHigherLevelHook;
export const IssuesSweetStateContainer = Container;

export const IssueIdeaContextHandler = ({
	context,
}: {
	context: PolarisIdeasCommonContextType;
}) => {
	const { register, unregister } = context;
	const { updateInsights } = useIssueActions();

	const listener = useMemo(
		() => ({
			onUpdateInsight: ({ jiraIssueId, insights }: PolarisInsightUpdateSignal) => {
				updateInsights(jiraIssueId, insights);
			},
		}),
		[updateInsights],
	);

	useEffect(() => {
		register(listener);
		return () => unregister(listener);
	}, [listener, register, unregister]);

	return null;
};

export const useIssueStore = createStoreHook(IssueStore);

export const IssuesLoader = () => {
	const [
		{ prefechedIssues, archivedIssues, meta },
		{ loadIssues, initialize, initializeArchived, loadArchivedIssues, getContainerProps },
	] = useIssueStore();
	const {
		fields,
		permissionsLoaded,
		hasNoProjectPermissions,
		onIssueLoadingFailed,
		isSharedView,
		selectedIssue,
		projectId,
		containsArchived,
	} = getContainerProps();
	const { initialized, loading, error } = meta;
	const isViewsLoading = useIsViewsLoading();
	const viewsLoaded = !isSharedView || !isViewsLoading;
	const issueViewLayout = useIssueViewLayout();
	const ideaIsOpenInFullscreen = selectedIssue && issueViewLayout === undefined;
	const [newlyAddedGlobalFields] = useNewlyAddedGlobalFields();
	const allViewFields = useAllViewFields();
	const { refreshIssues } = useIssueActions();
	const { resetNewlyAddedGlobalFields } = useFieldActions();

	const fieldKeys = useMemo(() => fields?.map(({ key }) => key), [fields]);
	const fieldKeyToAriMapPromise = useFieldKeyToAriMapAsync({ fieldKeys });

	useMemo(() => {
		// triggering the error callback only:
		// - when permissions are loaded
		// - when the user has access to the project
		// otherwise we shallow the error has user is not supposed to call the issue loading anyway.
		// Site admins is a special case are they can access project settings while not having access to the project
		if (error && permissionsLoaded) {
			if (!hasNoProjectPermissions) {
				onIssueLoadingFailed(error);
			}
		}
	}, [error, permissionsLoaded, hasNoProjectPermissions, onIssueLoadingFailed]);

	useEffect(() => {
		const shouldLoadIssues = isSharedView || !ideaIsOpenInFullscreen;

		if (shouldLoadIssues && !archivedIssues && containsArchived) {
			loadArchivedIssues();
		}

		if (initialized && fields && fields.length && archivedIssues && viewsLoaded) {
			initializeArchived(fieldKeyToAriMapPromise);
		}

		if (initialized) {
			return;
		}

		if (shouldLoadIssues && !prefechedIssues && !loading) {
			loadIssues();
		}

		if (fields && fields.length && prefechedIssues && viewsLoaded) {
			initialize(fieldKeyToAriMapPromise);
		}
	}, [
		containsArchived,
		isSharedView,
		projectId,
		initialized,
		prefechedIssues,
		archivedIssues,
		loading,
		fields,
		viewsLoaded,
		loadIssues,
		loadArchivedIssues,
		initialize,
		initializeArchived,
		ideaIsOpenInFullscreen,
		fieldKeyToAriMapPromise,
	]);

	// When adding a global field that has been used in the past, we need to refresh issues
	// This is to be able to render the values assigned to those fields
	// Otherwise we'd potentially cause users to override existing data since they'd see an empty field and edit away
	useEffect(() => {
		if (!viewsLoaded || !newlyAddedGlobalFields?.length) {
			return;
		}

		const newlyAddedGlobalFieldsUsedInViews = intersection(newlyAddedGlobalFields, allViewFields);
		if (!newlyAddedGlobalFieldsUsedInViews.length) {
			return;
		}

		refreshIssues({
			refreshAllLoaded: true,
			loadIssueDeliveryProgress: false,
		});
		resetNewlyAddedGlobalFields();
	}, [
		viewsLoaded,
		newlyAddedGlobalFields,
		resetNewlyAddedGlobalFields,
		allViewFields,
		refreshIssues,
	]);

	return null;
};
