import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const OriginalEstimateEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-original-estimate-edit-view" */ './ui/original-estimate'),
	),
	getPreloadProps: () => ({}),
});

export default OriginalEstimateEditViewEntryPoint;
