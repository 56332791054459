import { invokeAIFeature } from '@atlassian/jira-ai-assistance-service-client/src/services/ai-features-client/index.tsx';
import { invokeAgent } from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/index.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ConfluenceSiteAri } from '@atlassian/ari/confluence';
import {
	createActionsHook,
	createStateHook,
	type Action,
	createStore,
	createContainer,
	createHook,
} from '@atlassian/react-sweet-state';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import {
	fireTrackAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import {
	RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY,
	type SiteSwitcherSelection,
} from '../common/types.tsx';
import { createConfluencePageLinkRequest } from './link-confluence-page-api.tsx';

export type RelatedResource = {
	id: string;
	url: string;
};
export type InvokedFrom = 'suggestConfluencePagesButton' | 'improveIssueDropdown';

export type ResourceSuggestion = {
	resource: RelatedResource;
	isLinking: boolean;
	errorMessage?: string;
	resourceStatus: 'DRAFT' | 'ACCEPTED' | 'DISCARDED';
};

export type Step = 'HIDDEN' | 'FETCHING' | 'DISPLAY';
export const steps = {
	hidden: 'HIDDEN',
	fetching: 'FETCHING',
	display: 'DISPLAY',
} as const;

// State of the Related resources context
type State = {
	confluenceSiteAri: string | null;
	currentStep: Step;
	resourceSuggestions?: ResourceSuggestion[];
	/**
	 * Remove isLoadingSuggestions in GRAVITYAI-2076
	 */
	isLoadingSuggestions: boolean;
	errorMessage: string | null;
	invokedFrom: InvokedFrom | null;
	confluenceAppLinks: ConfluenceAppLink[] | [];
	restController: AbortController | null;
};
export const INITIAL_STATE: State = {
	confluenceSiteAri: null,
	currentStep: steps.hidden,
	resourceSuggestions: undefined,
	isLoadingSuggestions: false,
	errorMessage: null,
	invokedFrom: null,
	confluenceAppLinks: [],
	restController: null,
};

export type ConfluencePageSet = (ConfluencePage | FailedRemoteLink)[];
export type ContainerProps = {
	issueId: string | undefined;
	issueKey: string | undefined;
	onCloseAISuggestions: () => void;
	onCreateConfluencePageLinkSuccess: (confluencePageLink: ConfluencePage | FailedRemoteLink) => {
		type: string;
		payload: { confluencePageLink: ConfluencePage | FailedRemoteLink };
	};
	linkedConfluencePages?: ConfluencePageSet;
	mentionedConfluencePages?: ConfluencePageSet;
	confluenceAppLinks?: ConfluenceAppLink[] | [];
};

const fetchFromAssistanceService = async (
	issueKey: string,
	signal?: AbortSignal,
): Promise<ResourceSuggestion[]> => {
	const result = await invokeAgent(
		'jira_issue_related_resources_agent',
		{
			experienceId: 'jira-issue-related-resources',
			agent_input_context: { issue_key: issueKey || '' },
		},
		signal,
	);
	return (result?.content?.suggested_resources || []).map((suggestedResource) => {
		return { resource: suggestedResource, isLinking: false, resourceStatus: 'DRAFT' };
	});
};

const fetchFromConvoAI = async (
	issueKey: string,
	confluenceCloudAri: string | null,
	signal?: AbortSignal,
): Promise<ResourceSuggestion[]> => {
	const result = await invokeAIFeature(
		'jira-issue-related-resources',
		{
			ai_feature_path: 'ai-feature/relatedconfluencepages',
			ai_feature_input: {
				issue_key: issueKey,
				...(confluenceCloudAri && fg('jira-ai-related-resources-site-switcher')
					? { confluence_cloud_ari: confluenceCloudAri }
					: {}),
			},
		},
		signal,
	);
	return (result?.ai_feature_output?.related_confluence_pages || []).map((suggestedResource) => {
		return { resource: suggestedResource, isLinking: false, resourceStatus: 'DRAFT' };
	});
};

const fetchSuggestions =
	(invokedFrom: InvokedFrom, analyticsEvent?: UIAnalyticsEvent): Action<State, ContainerProps> =>
	async ({ setState, getState }, { issueKey }) => {
		setState({
			...INITIAL_STATE,
			invokedFrom,
			isLoadingSuggestions: true,
			...(fg('jira-ai-related-resources-site-switcher')
				? {
						confluenceSiteAri: getState().confluenceSiteAri,
						confluenceAppLinks: getState().confluenceAppLinks,
					}
				: {}),
			...(fg('aligning-jira-ai-features-ui-improvements') ? { currentStep: steps.fetching } : {}),
			restController: new AbortController(),
		});
		const { restController } = getState();
		try {
			if (issueKey == null) {
				/**
				 * Throwing here will go into the catch block & trigger error state + analytics
				 */
				throw new Error('invalid issueKey');
			}
			const resourceSuggestions = expVal(
				'use_convoai_for_related_confluence_pages',
				'isEnabled',
				false,
			)
				? await fetchFromConvoAI(issueKey, getState().confluenceSiteAri, restController?.signal)
				: await fetchFromAssistanceService(issueKey, restController?.signal);

			setState({
				isLoadingSuggestions: false,
				restController: null,
				resourceSuggestions,
				...(fg('aligning-jira-ai-features-ui-improvements') ? { currentStep: steps.display } : {}),
				errorMessage: null,
			});
		} catch (error) {
			// Abort is not treated as an error
			if (error instanceof DOMException && error.name === 'AbortError') {
				setState({
					isLoadingSuggestions: false,
					restController: null,
					currentStep: steps.hidden,
					errorMessage: null,
				});
			} else {
				setState({
					isLoadingSuggestions: false,
					restController: null,
					...(fg('aligning-jira-ai-features-ui-improvements')
						? { currentStep: steps.display }
						: {}),
					errorMessage: 'Error occured while fetching suggestions',
				});
				if (analyticsEvent && fg('jira-ai-related-resources-site-switcher')) {
					fireTrackAnalytics(analyticsEvent, 'aiResult error');
				}
			}
		}
	};

const hasNoDrafts = (suggestions: ResourceSuggestion[] | undefined): boolean => {
	return (
		suggestions !== undefined &&
		suggestions.filter((suggestion) => suggestion.resourceStatus === 'DRAFT').length === 0
	);
};

const isPageError = (page: ConfluencePage | FailedRemoteLink): page is FailedRemoteLink =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(page as FailedRemoteLink).error !== undefined;

const getlinkedConfluenceUrls = (
	linkedConfluencePages: ConfluencePageSet | undefined,
	mentionedConfluencePages: ConfluencePageSet | undefined,
): Set<String> => {
	const urlSet = new Set<string>();
	[...(linkedConfluencePages || []), ...(mentionedConfluencePages || [])].forEach((page) => {
		const url = isPageError(page) ? page?.link?.href : page.href;
		urlSet.add(url);
	});
	return urlSet;
};

const acceptSuggestions =
	(linkResourcesRequest: RelatedResource[]): Action<State, ContainerProps> =>
	async (
		{ getState, setState },
		{
			onCloseAISuggestions,
			issueId,
			onCreateConfluencePageLinkSuccess,
			linkedConfluencePages,
			mentionedConfluencePages,
		},
	) => {
		const linkedUrls = getlinkedConfluenceUrls(linkedConfluencePages, mentionedConfluencePages);

		// Set isLinking to True
		const idsToBeLinked = linkResourcesRequest.map((res) => res.id);
		const resourceSuggestionsLinking = getState().resourceSuggestions?.map((res) =>
			idsToBeLinked.includes(res.resource.id) ? { ...res, isLinking: true } : res,
		);
		setState({
			resourceSuggestions: resourceSuggestionsLinking,
		});

		// keep track of all links transformed in the same order of linkResourceRequest
		const linkStack: { index: number; createdLink?: ConfluencePage | FailedRemoteLink }[] = [];

		// Call api to create the links
		linkResourcesRequest.forEach(async (request, index) => {
			const isSuccess = await (async () => {
				try {
					if (linkedUrls.has(request.url)) {
						// the requested url is already linked to the issue
						// then consider linking to be successful so that it goes to accepted state
						linkStack.push({ index });
						return true;
					}
					const createdLink = await createConfluencePageLinkRequest({
						issueId: issueId || '',
						pageHref: request.url,
					});
					linkStack.push({ index, createdLink });
					return true;
				} catch (error) {
					linkStack.push({ index });
					return false;
				}
			})();

			// maintain the order of suggestions using index before saving
			if (linkStack.length === linkResourcesRequest.length) {
				const sortedLinks = linkStack.sort((a, b) => a.index - b.index);
				sortedLinks.map(
					(link) => link.createdLink && onCreateConfluencePageLinkSuccess(link.createdLink),
				);
			}

			const resourceSuggestionsAfterLinking: ResourceSuggestion[] | undefined =
				getState().resourceSuggestions?.map((res: ResourceSuggestion) => {
					if (request.id === res.resource.id) {
						return {
							...res,
							isLinking: false,
							resourceStatus: isSuccess ? 'ACCEPTED' : res.resourceStatus,
							errorMessage: !isSuccess
								? 'Error occured while linking resource to issue'
								: undefined,
						};
					}
					return res;
				});
			setState({
				resourceSuggestions: resourceSuggestionsAfterLinking,
			});
			if (hasNoDrafts(resourceSuggestionsAfterLinking)) {
				setState({
					currentStep: steps.hidden,
				});
				onCloseAISuggestions();
			}
		});
	};

const discardSuggestion =
	(resourceToBeDiscarded: RelatedResource): Action<State, ContainerProps> =>
	({ getState, setState }, { onCloseAISuggestions }) => {
		const resourceSuggestionsAfterDiscarding: ResourceSuggestion[] | undefined =
			getState().resourceSuggestions?.map((res: ResourceSuggestion) =>
				resourceToBeDiscarded.id === res.resource.id
					? { ...res, resourceStatus: 'DISCARDED' }
					: res,
			);
		setState({
			resourceSuggestions: resourceSuggestionsAfterDiscarding,
		});

		if (hasNoDrafts(resourceSuggestionsAfterDiscarding)) {
			setState({
				currentStep: steps.hidden,
			});
			onCloseAISuggestions();
		}
	};

const discardAllSuggestions =
	(): Action<State, ContainerProps> =>
	({ setState, getState }, { onCloseAISuggestions }) => {
		const { isLoadingSuggestions, restController } = getState();
		if (isLoadingSuggestions) {
			restController?.abort();
		}
		setState({
			currentStep: steps.hidden,
		});
		onCloseAISuggestions();
	};

const setRelatedResourcesStep =
	(step: Step): Action<State> =>
	({ setState }) => {
		setState({ currentStep: step });
	};

const setConfluenceSiteAri =
	(cloudId: string): Action<State> =>
	({ setState }) => {
		setState({ confluenceSiteAri: ConfluenceSiteAri.create({ siteId: cloudId }).toString() });
	};

const isRelatedResourcesOpen =
	(): Action<State, void, Boolean> =>
	({ getState }) => {
		const { currentStep } = getState();
		return currentStep !== steps.hidden;
	};

export const actions = {
	acceptSuggestions,
	discardSuggestion,
	discardAllSuggestions,
	fetchSuggestions,
	setRelatedResourcesStep,
	isRelatedResourcesOpen,
	setConfluenceSiteAri,
};

type ActionsType = typeof actions;

export const JiraIssueRelatedResourcesContainer = createContainer<ContainerProps>({
	displayName: 'JiraIssueRelatedResourcesContainer',
});

export const Store = createStore<State, ActionsType, ContainerProps>({
	initialState: INITIAL_STATE,
	actions,
	name: 'jira-ai-related-resources',
	containedBy: JiraIssueRelatedResourcesContainer,
	handlers: {
		onUpdate:
			() =>
			({ setState }, { confluenceAppLinks }) => {
				setState({ confluenceAppLinks });
			},
		onInit:
			() =>
			({ setState }) => {
				if (fg('jira-ai-related-resources-site-switcher')) {
					/**
					 * On init, we initialise a local storage provider to store the selected site
					 * and also construct the initial confluenceSiteAri if one was stored in the browser
					 */
					const storage = createLocalStorageProvider(
						RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY,
					);
					const storedValue: SiteSwitcherSelection = storage.get(
						RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY,
					);

					if (storedValue && storedValue.value) {
						/**
						 * Construct ARI from site
						 */
						setState({
							confluenceSiteAri: ConfluenceSiteAri.create({ siteId: storedValue.value }).toString(),
						});
					}
				}
			},
	},
});
export const useRelatedResources = createHook(Store);
export const useRelatedResourcesActions = createActionsHook(Store);
export const useRelatedResourcesState = createStateHook(Store);
