import React, { useCallback, useEffect, useRef, type ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { SmartCardProvider } from '@atlaskit/link-provider';
import Lozenge from '@atlaskit/lozenge';
import { Anchor, Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import type { AIEventBaseAttributes } from '@atlassian/ai-analytics';
import { StreamingStatusBar } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-draft-list/streaming-status-bar/index.tsx';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container/index.tsx';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	useRelatedResources,
	useRelatedResourcesState,
	type InvokedFrom,
} from '../controllers/related-resources-context.tsx';
import messages from './messages.tsx';
import { RelatedResourcesDraftList } from './related-resources-draft-list/index.tsx';
import { SiteSwitcher } from './site-switcher/index.tsx';

const StatusPage = (chunks: ReactNode) => (
	<Anchor href="https://status.atlassian.com/" target="_blank">
		{chunks}
	</Anchor>
);

export const SuggestRelatedResourcesModalInternal = () => {
	const { formatMessage } = useIntl();

	const [
		{ isLoadingSuggestions, resourceSuggestions, errorMessage, confluenceAppLinks },
		{ discardAllSuggestions },
	] = useRelatedResources();

	// Unified Event Instrumentation For AI Features
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'aiInteraction initiated');
	}, [createAnalyticsEvent]);

	const areSuggestionsLoaded = resourceSuggestions !== undefined && errorMessage === null;
	const isError = errorMessage !== null;

	useEffect(() => {
		if (areSuggestionsLoaded) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiResult viewed');
		} else if (isError) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiResult error');
		}
	}, [areSuggestionsLoaded, isError, createAnalyticsEvent]);

	const onDismissSuggestions = useCallback(() => {
		if (isLoadingSuggestions) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiInteraction dismissed');
		}
		discardAllSuggestions();
	}, [createAnalyticsEvent, discardAllSuggestions, isLoadingSuggestions]);

	const onContainerRef = useCallback((element: HTMLDivElement | null) => {
		if (
			element &&
			typeof element.scrollIntoView === 'function' &&
			fg('jira_ai_related_resources_scrolling_view_fix')
		) {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, []);

	const renderContent = () => {
		if (isLoadingSuggestions) {
			return <StreamingStatusBar message={formatMessage(messages.generatingPageSuggestions)} />;
		}
		if (resourceSuggestions) {
			if (resourceSuggestions.length === 0) {
				return (
					<SectionMessage
						appearance="warning"
						title={formatMessage(messages.pageSuggestionsEmptyHeader)}
					>
						{formatMessage(messages.pageSuggestionsEmptyInfo)}
					</SectionMessage>
				);
			}
			return <RelatedResourcesDraftList />;
		}
		return (
			<SectionMessage appearance="warning">
				{formatMessage(messages.pageSuggestionsCouldNotBeGeneratedNew, { anchor: StatusPage })}
			</SectionMessage>
		);
	};

	return (
		<SmartCardProvider>
			<AIContainer
				hasNewBorderExperience
				shouldFitContainer
				isShadow
				isLoading={isLoadingSuggestions}
				spacing="none"
				testId="ai-related-resources.ui.ai-container"
			>
				<Box
					testId="ai-related-resources.ui.header"
					ref={onContainerRef}
					xcss={[headerContainerStyles, hoverableHeaderStyles, hoverableStyles]}
				>
					<Box testId="ai-related-resources.ui.button" xcss={headerButtonStyles}>
						<Box as="span" xcss={headerPromptStyles}>
							<Text>{formatMessage(messages.suggestConfluencePages)}</Text>
						</Box>
						<Lozenge appearance="new">{formatMessage(messages.beta)}</Lozenge>
					</Box>
					{confluenceAppLinks.length > 0 && fg('jira-ai-related-resources-site-switcher') && (
						<Placeholder name="JiraAIRelatedResourcesSiteSwitcher" fallback={null}>
							<SiteSwitcher confluenceAppLinks={confluenceAppLinks} />
						</Placeholder>
					)}
					<IconButton
						testId="ai-related-resources.ui.cross-button"
						appearance="subtle"
						icon={CrossIcon}
						label={formatMessage(messages.discardSuggestions)}
						onClick={onDismissSuggestions}
						isTooltipDisabled={false}
						tooltip={{ position: 'left' }}
					/>
				</Box>
				{fg('jira_ai_related_resources_new_error_state') ? (
					renderContent()
				) : (
					<>
						{isLoadingSuggestions && (
							<StreamingStatusBar message={formatMessage(messages.generatingPageSuggestions)} />
						)}
						{((!isLoadingSuggestions && resourceSuggestions && resourceSuggestions.length === 0) ||
							errorMessage != null) && (
							<SectionMessage appearance="warning">
								{formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.pageSuggestionsCouldNotBeGeneratedIssueTermRefresh
										: messages.pageSuggestionsCouldNotBeGenerated,
								)}
							</SectionMessage>
						)}
						{!isLoadingSuggestions && errorMessage === null && <RelatedResourcesDraftList />}
					</>
				)}
				<AiFooter isBeta={false} />
			</AIContainer>
		</SmartCardProvider>
	);
};
type RelatedResourcesAttributes = AIEventBaseAttributes & {
	singleInstrumentationId: string;
	invokedFrom: InvokedFrom;
};

export const SuggestRelatedResourcesModal = () => {
	const singleInstrumentationId = useRef(uuid());
	const { invokedFrom } = useRelatedResourcesState();

	if (invokedFrom == null) {
		return null;
	}
	const attributes: RelatedResourcesAttributes = {
		aiFeatureName: 'jiraIssueRelatedResources',
		isAIFeature: 1,
		proactiveGeneratedAI: 0,
		userGeneratedAI: 1,
		singleInstrumentationId: singleInstrumentationId.current,
		invokedFrom,
	};

	return (
		<JSErrorBoundary
			id="jira-ai.gravity-ai.related-resources"
			packageName="jiraAIRelatedResources"
			teamName="gravity-ai"
			fallback="unmount"
		>
			<ContextualAnalyticsData attributes={attributes}>
				<SuggestRelatedResourcesModalInternal />
			</ContextualAnalyticsData>
		</JSErrorBoundary>
	);
};
const headerButtonStyles = xcss({
	background: 'none',
	width: '100%',
	display: 'flex',
	justifyContent: 'baseline',
	alignItems: 'center',
	padding: 'space.200',
});

const headerPromptStyles = xcss({
	borderRadius: token('border.radius.100', '3px'),
	padding: 'space.025',
	paddingTop: '0',
	paddingBottom: '0',
	height: 'space.0',
	width: 'space.100',
	color: 'color.text.subtle',
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.medium', '400'),
	marginRight: 'space.100',
});

const headerContainerStyles = xcss({
	paddingRight: 'space.150',
	display: 'flex',
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', '#EBECF0')}`,
	alignItems: 'center',
});

const hoverableStyles = xcss({
	cursor: 'pointer',
});

const hoverableHeaderStyles = xcss({
	':hover': {
		background: token('color.background.neutral.subtle.hovered', '#EBECF0'),
		textDecoration: 'none',
	},
});
