/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@compiled/react';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { STATUS_AND_APPROVAL_SPOTLIGHT } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import StatusField from '@atlassian/jira-issue-view-foundation/src/status/view.tsx';

import GlobalSpotlightTargetDeprecated from '@atlassian/jira-servicedesk-common/src/ui/global-spotlight-target/index.tsx';
import ApprovalPanelWrapper from './approval-panel-wrapper/index.tsx';

const FoundationContext = ({
	issueViewRelayFragment,
	isStatusOnLHS = false,
	isStatusPositionExperimentEnabled = false,
}: {
	// TODO Decomp JIV-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	isStatusOnLHS?: boolean;
	isStatusPositionExperimentEnabled?: boolean;
}) => (
	<GlobalSpotlightTargetDeprecated id={STATUS_AND_APPROVAL_SPOTLIGHT}>
		<div
			css={[foundationContextWrapperStyle, isStatusOnLHS && foundationContextWrapperStyleOverflow]}
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
		>
			<StatusField
				key="WRAPPED_STATUS"
				isStatusPositionExperimentEnabled={isStatusPositionExperimentEnabled}
				fragmentKey={issueViewRelayFragment}
				isStatusOnLHS={isStatusOnLHS}
			/>
			{!isStatusOnLHS && <ApprovalPanelWrapper />}
		</div>
	</GlobalSpotlightTargetDeprecated>
);

export default FoundationContext;

const foundationContextWrapperStyle = css({
	marginBottom: 0,
});

const foundationContextWrapperStyleOverflow = css({
	overflow: 'visible',
});
