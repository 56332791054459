import { fg } from '@atlassian/jira-feature-gating';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import OldComponent from './view-old.tsx';
import NewComponent from './view.tsx';

export default componentWithCondition(
	() => fg('jiv-replace-configuration-button-in-context-panel'),
	NewComponent,
	OldComponent,
);
