import { useAdjustmentsEnabled } from '@atlassian/ui-modifications-core/src/controllers/adjustments-context/index.tsx';
import { useHiddenFields } from '../issue-adjustments/hooks/use-hidden-fields/index.tsx';

export const useEnabledHiddenFields = () => {
	const adjustmentsAreEnabled = useAdjustmentsEnabled();
	const hiddenFields = useHiddenFields();

	if (!adjustmentsAreEnabled) {
		return [];
	}
	return hiddenFields;
};
