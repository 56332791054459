import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableDateFieldsTitle: {
		id: 'issue-date-fields-message.enable-date-fields-title',
		defaultMessage: 'Enable date fields',
		description:
			'Title of the section message in issue view telling the user to enable start and due date fields',
	},
	enableDateFieldsDescriptionAdmin: {
		id: 'issue-date-fields-message.enable-date-fields-description-admin',
		defaultMessage:
			'Enable start and due date fields so you can schedule work from the issue view.',
		description:
			'Description of the section message in issue view telling the user to enable start and due date fields if the user is not an admin',
	},
	enableDateFieldsDescriptionNonAdmin: {
		id: 'issue-date-fields-message.enable-date-fields-description-non-admin',
		defaultMessage:
			'Ask your admin to enable start and due date fields so you can schedule work from the issue view.',
		description:
			'Description of the section message in issue view telling the user to enable start and due date fields if the user is an admin',
	},
	configureFields: {
		id: 'issue-date-fields-message.configure-fields',
		defaultMessage: 'Configure fields',
		description: 'Link to the configure fields screen in project settings for this issue type',
	},
	enable: {
		id: 'issue-date-fields-message.enable',
		defaultMessage: 'Enable',
		description: 'Link to enable start and due date fields for TMP projects',
	},
	dismiss: {
		id: 'issue-date-fields-message.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Link to dismiss the message telling the user to enable start and due date fields',
	},
});
