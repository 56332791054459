import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { fetchIssueGraphQlData } from '@atlassian/jira-issue-fetch-services/src/services/issue-graphql-data/index.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { NextGenChildIssue } from '@atlassian/jira-issue-view-common-types/src/issue-server-type.tsx';
import { extractFromObject } from '@atlassian/jira-issue-view-common-utils/src/utils/utils.tsx';
import { transformChild } from '@atlassian/jira-issue-view-services/src/issue/child-issue-transformer-new.tsx';
import { getGraphQlFields } from '@atlassian/jira-issue-view-services/src/issue/issue-transformer.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	getBaseUrl,
	getChildIssuesPanelApplication,
	getChildIssuesPanelEdition,
	getChildIssuesPanelType,
	getIsClassicSubtaskPanel,
	getIsSimplifiedProject,
} from '../../state/context/selectors.tsx';
import {
	CREATE_CHILD_SUCCESS,
	fetchGraphqlIssueSuccess,
	type CreateChildSuccessAction,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
		associatedIssuesContextActions?: AssociatedIssuesContextActions,
		fieldsValuesActions?: FieldValueServiceActions,
		fieldConfigActions?: FieldConfigServiceActions,
	) =>
	(action$: ActionsObservable<CreateChildSuccessAction>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(CREATE_CHILD_SUCCESS)
			.do(
				({ payload: { createdChild, usedGlobalCreateDialog }, meta }: CreateChildSuccessAction) => {
					const state = store.getState();

					// Global issue create dialog also fires its own `issue created` event.
					// To avoid duplication, suppressing the issue view event version in case the modal was opened
					// Only fire if the child issue was created inline.
					if (!usedGlobalCreateDialog) {
						// Feature monitoring
						const isClassicSubtask = getIsClassicSubtaskPanel(state);
						const additionalAttributes = meta.additionalAttributes ?? {};
						const attributes = {
							action: 'created',
							actionSubjectId: createdChild.id,
							attributes: {
								isInlineCreatedOnIssue: true,
								isClassicSubtask,
								...additionalAttributes,
							},
						};
						fireTrackAnalytics(meta.analyticsEvent, attributes);
					}

					/**
					SLA monitoring

					Ordinarily the Global Create dialog would fire its own Create Issue SLA events.
					However in the child issues panel we bind the `issueCreated` event which overrides
					this default behaviour. So we send the event here in both (global and inline) cases.
					*/
					const childIssuePanelType = getChildIssuesPanelType(state) || 'unknown_child';
					const application = getChildIssuesPanelApplication(state);
					const edition = getChildIssuesPanelEdition(state);

					sendExperienceAnalytics({
						getExperienceDescription: () => ({
							experience: 'createIssue',
							wasExperienceSuccesful: true,
							analyticsSource: childIssuePanelType,
							application,
							edition,
							additionalAttributes: {
								isCreated: true,
								wasCreatedInline: !usedGlobalCreateDialog,
							},
						}),
					});
				},
			)
			.mergeMap(({ payload: { createdChild } }: CreateChildSuccessAction) => {
				const state = store.getState();
				const baseUrl = getBaseUrl(state);
				const issueKey =
					createdChild.issueKey !== undefined && createdChild.issueKey !== null
						? toIssueKey(createdChild.issueKey)
						: undefined;
				const projectKey = issueKey !== undefined ? extractProjectKey(issueKey) : undefined;

				if (!issueKey || !projectKey) {
					const errorMessage = 'Issue or project key missing when handling create child success';
					log.safeErrorWithoutCustomerData(
						'issue.views.common.child-issues-panel.create-child-success.missing-key',
						errorMessage,
						new Error(errorMessage),
					);
					return Observable.empty<never>();
				}
				const fetchIssueFromGraphQL$ = () =>
					Observable.from(fetchIssueGraphQlData(baseUrl, issueKey, projectKey))
						.map(({ data: graphqlData }) => {
							const fieldsData: FieldsState = getGraphQlFields(graphqlData);
							const issue: NextGenChildIssue = {
								id: graphqlData.issue.id,
								key: issueKey,
								self: '',
								fields: Object.values(fieldsData),

								// Added to satisfy Flow; `transformChild` will not use these fields
								agile: {},
								customFields: {
									textareaAdf: [],
								},
								systemFields: {},
							};
							const transformedData = transformChild(issue, getIsSimplifiedProject(state));

							associatedIssuesContextActions &&
								associatedIssuesContextActions.mergeLocalAssociatedIssuesContext({
									[issueKey]: {
										estimateFieldId: transformedData.estimateFieldId,
										isResolved: transformedData.isResolved ?? false,
									},
								});

							if (fieldsData && fieldsValuesActions && fieldConfigActions) {
								const { fieldValues, fieldConfigs } = extractFromObject(fieldsData);
								fieldsValuesActions.setIssue(issueKey, fieldValues);
								fieldConfigActions.setIssueConfig(issueKey, fieldConfigs);
							}

							return fetchGraphqlIssueSuccess({
								childIssue: transformedData,
								fieldsData,
							});
						})
						.catch((error) => {
							log.safeErrorWithoutCustomerData(
								'issue.views.common.child-issues-panel.create-child-success.fetch-issue-graphql',
								'Failed to fetch issue after a successful create',
								error,
							);
							return Observable.empty<never>();
						});

				return fetchIssueFromGraphQL$();
			});
