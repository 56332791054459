import type { CommonFieldPropertyValidators } from '../../types';
import type { FieldInternalMetadata } from '../../types/field-internal-metadata';

export const getCommonFieldValidators = (): CommonFieldPropertyValidators => ({
	fieldName: (value: unknown) => typeof value === 'string',
	isVisible: (value: unknown) => typeof value === 'boolean',
	description: validateDescription,
	isReadOnly: (value: unknown, { isReadOnlyBySystem }: FieldInternalMetadata) =>
		typeof value === 'boolean' && (value || (!value && !isReadOnlyBySystem)),
	isRequired: (value: unknown, { isRequiredBySystem }: FieldInternalMetadata) =>
		typeof value === 'boolean' && (value || (!value && !isRequiredBySystem)),
});

export const validateDescription = async (value: unknown) => {
	if (typeof value !== 'string') {
		return false;
	}

	return true;
};
