import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { OriginalEstimateView } from '@atlassian/jira-issue-field-original-estimate/src/ui/view/index.tsx';
import type {
	OptionalTime,
	TimeTracking,
	TimeTrackingConfig,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import LoggedTime from '../../../logged-time/view.tsx';
import { RollupCheckbox } from '../roll-up-checkbox/index.tsx';

export type Props = {
	isDone: boolean;
	isRollingUpData: boolean;
	isClassicProject: boolean;
	shouldDisplayRollUpDataControl: boolean;
	value: TimeTracking;
	config: TimeTrackingConfig;
	estimatedTime: OptionalTime;
	onToggleRollingUpData: (arg1: boolean, arg2: UIAnalyticsEvent) => void;
};

const location = 'roll-up-time-tracking-toggle-from-dialog';
const actionSubjectId = 'toggleRollUpDataControlFromWorklogDialog';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		config,
		value,
		isDone,
		estimatedTime,
		shouldDisplayRollUpDataControl,
		isRollingUpData,
		onToggleRollingUpData,
		isClassicProject,
	} = props;
	const intl = useIntl();

	const renderEstimatedTime = useCallback(
		(time: number) => {
			const formattedEstimatedTime = timeTrackingFormatter(
				time,
				{
					workingHoursPerDay: config.hoursPerDay,
					workingDaysPerWeek: config.daysPerWeek,
					timeFormat: config.format || PRETTY,
					defaultUnit: config.defaultUnit,
				},
				intl,
			);

			const RolledUpEstimatesMessageOld = isClassicProject
				? () => (
						<FormattedMessage
							id="issue.log-time.modal.original-estimate.with-rollup-subtasks"
							defaultMessage="The original estimate for this issue and subtasks was {time}."
							values={{
								time: <OriginalEstimateView value={formattedEstimatedTime} />,
							}}
						/>
					)
				: () => (
						<FormattedMessage
							id="issue.log-time.modal.original-estimate.with-rollup-child-issues"
							defaultMessage="The original estimate for this issue and child issues was {time}."
							values={{
								time: <OriginalEstimateView value={formattedEstimatedTime} />,
							}}
						/>
					);

			const RolledUpEstimatesMessage = isClassicProject
				? () => (
						<FormattedMessage
							id="issue.log-time.modal.original-estimate.with-rollup-subtasks-issue-term-refresh"
							defaultMessage="The original estimate for this work item and subtasks was {time}."
							values={{
								time: <OriginalEstimateView value={formattedEstimatedTime} />,
							}}
						/>
					)
				: () => (
						<FormattedMessage
							id="issue.log-time.modal.original-estimate.with-rollup-child-issues-issue-term-refresh"
							defaultMessage="The original estimate for this work item and child work items was {time}."
							values={{
								time: <OriginalEstimateView value={formattedEstimatedTime} />,
							}}
						/>
					);

			const EstimatesMessage = fg('jira-issue-terminology-refresh-m3')
				? () => (
						<FormattedMessage
							id="issue.log-time.modal.original-estimate-issue-term-refresh"
							defaultMessage="The original estimate for this work item was {time}."
							values={{
								time: <OriginalEstimateView value={formattedEstimatedTime} />,
							}}
						/>
					)
				: () => (
						<FormattedMessage
							id="issue.log-time.modal.original-estimate"
							defaultMessage="The original estimate for this issue was {time}."
							values={{
								time: <OriginalEstimateView value={formattedEstimatedTime} />,
							}}
						/>
					);

			if (isRollingUpData && shouldDisplayRollUpDataControl) {
				if (fg('jira-issue-terminology-refresh-m3')) {
					return (
						<StyledParagraph>
							<RolledUpEstimatesMessage />
						</StyledParagraph>
					);
				}
				return (
					<StyledParagraph>
						<RolledUpEstimatesMessageOld />
					</StyledParagraph>
				);
			}
			return (
				<StyledParagraph>
					<EstimatesMessage />
				</StyledParagraph>
			);
		},
		[config, intl, isClassicProject, isRollingUpData, shouldDisplayRollUpDataControl],
	);

	return (
		<>
			<LoggedTime
				config={config}
				value={value}
				estimatedTime={estimatedTime}
				isDone={isDone}
				isCompact={false}
				isEditable={false}
			/>
			{shouldDisplayRollUpDataControl && (
				<RollUpEstimatesWrapper>
					<RollupCheckbox
						isRollingUpData={isRollingUpData}
						location={location}
						actionSubjectId={actionSubjectId}
						onToggleRollingUpData={onToggleRollingUpData}
					/>
				</RollUpEstimatesWrapper>
			)}
			{typeof estimatedTime === 'number' ? renderEstimatedTime(estimatedTime) : null}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledParagraph = styled.p({
	marginTop: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RollUpEstimatesWrapper = styled.div({
	marginLeft: token('space.negative.050', '-4px'),
	paddingTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > label > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N500),
	},
});
