import type { JobStatus, JobType } from './types.tsx';

export const INTERNAL_REMOVE_FEATURE_FLAG: JobType = 'INTERNAL_REMOVE_FEATURE_FLAG';

export const STATUS: Record<string, JobStatus> = {
	PENDING: 'PENDING',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETED: 'COMPLETED',
	FAILED: 'FAILED',
};
