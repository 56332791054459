import { useEffect, useState } from 'react';
import { useCanUpgradeAndPay } from '@atlassian/jira-billing/src/controllers/can-upgrade-and-pay/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type {
	UsageLimit,
	UsageLimitsData,
} from '@atlassian/jira-growth-data-usage/src/common/types.tsx';
import { useDataUsage } from '@atlassian/jira-growth-data-usage/src/utils.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { FREE_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { PACKAGE_NAME, STORAGE_RATIO_TO_SHOW_BANNER } from './constants.tsx';

export const useCheckRequisitesAndFetchUpgradeLink = () => {
	const { data: projectContext } = useProjectContext();
	const { appEditions } = useTenantContext();
	const isInsideFreeJswProject =
		appEditions.software === FREE_EDITION && projectContext?.projectType === SOFTWARE_PROJECT;
	const {
		fetch: fetchCanUpgradeAndPayData,
		data: { canUpgradeAndPay: canUpgradeAndPayForJsw, upgradeLink } = {},
		isLoading,
		error,
	} = useCanUpgradeAndPay({
		applicationKey: SOFTWARE,
		edition: STANDARD_EDITION,
	});

	useEffect(() => {
		if (isInsideFreeJswProject) {
			fetchCanUpgradeAndPayData();
		}
	}, [isInsideFreeJswProject, fetchCanUpgradeAndPayData]);

	return {
		canView: Boolean(canUpgradeAndPayForJsw && isInsideFreeJswProject),
		isLoading,
		upgradeLink,
		error,
	};
};

function getJswUsageLimit(response: UsageLimitsData | undefined | null): UsageLimit | undefined {
	return response?.limits.find((limit) => limit.product.startsWith('ari:cloud:jira-software'));
}

// Storage limit is returned in Bytes, need to manipulate data
function getStorageData(usageLimit: UsageLimit | undefined) {
	return {
		storageRatio:
			usageLimit && !usageLimit.total.unlimited
				? usageLimit.total.used / usageLimit.total.allowed
				: undefined,
		storageLimit:
			usageLimit && !usageLimit.total.unlimited
				? usageLimit.total.allowed / 1_000_000_000
				: undefined,
		edition: usageLimit?.edition,
	};
}

// When the site is using above this ratio of storage, we don't want to cache the used storage
const RATIO_TO_ALWAYS_REFETCH_STORAGE = STORAGE_RATIO_TO_SHOW_BANNER;
type UseGetUsedStorageDataResponse =
	| {
			storageRatio: number | undefined;
			storageLimit: number | undefined;
			edition: string | undefined;
	  }
	| undefined;

export function useGetUsedStorageData({ skip }: { skip: boolean }): UseGetUsedStorageDataResponse {
	const [storageData, setStorageData] = useState<UseGetUsedStorageDataResponse>();

	const appEditions = useAppEditions();
	const jswEdition = appEditions?.software;

	const storageLimitAboveThreshold =
		storageData?.storageRatio != null
			? storageData.storageRatio > RATIO_TO_ALWAYS_REFETCH_STORAGE &&
				// Stored cache edition is the same as the current edition
				jswEdition === FREE_EDITION &&
				storageData.edition === 'free'
			: false;

	// Migrating to common hook
	const { data: dataUsageFromHook } = useDataUsage({
		caller: PACKAGE_NAME,
		skip,
		shouldUseCache: true,
		// if above threshold we want to refresh every hour, else default (1 day)
		maxAge: storageLimitAboveThreshold ? 3_600_000 : undefined,
	});

	useEffect(() => {
		if (skip) {
			return;
		}

		const jswUsageLimit = getJswUsageLimit(dataUsageFromHook);
		const newStorageData = getStorageData(jswUsageLimit);

		setStorageData(newStorageData);
	}, [skip, dataUsageFromHook]);

	return storageData;
}
