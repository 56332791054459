import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const DateTimeEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-date-time-edit-view" */ './ui/date-time'),
	),
	getPreloadProps: () => ({}),
});

export default DateTimeEditViewEntryPoint;
