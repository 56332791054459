/**
 * @generated SignedSource<<b911ff03194677b5f7642db861c4ba75>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type organizationField_servicedeskCustomerServiceCustomFields$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly searchUrl: string | null | undefined;
  readonly selectedOrganization: {
    readonly organizationId: string | null | undefined;
    readonly organizationName: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "organizationField_servicedeskCustomerServiceCustomFields";
};
export type organizationField_servicedeskCustomerServiceCustomFields$key = {
  readonly " $data"?: organizationField_servicedeskCustomerServiceCustomFields$data;
  readonly " $fragmentSpreads": FragmentRefs<"organizationField_servicedeskCustomerServiceCustomFields">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "organizationField_servicedeskCustomerServiceCustomFields",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraServiceManagementOrganization",
      "kind": "LinkedField",
      "name": "selectedOrganization",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "organizationName"
        },
        {
          "kind": "ScalarField",
          "name": "organizationId"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "searchUrl"
    }
  ],
  "type": "JiraCustomerServiceOrganizationField"
};

(node as any).hash = "9c22bb4937c9e21c2a0ad9734f3c0470";

export default node;
