import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.draftReply.title',
		defaultMessage: 'Draft reply',
		description:
			'Title for "Draft reply" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	description: {
		id: 'fabric.editor.ai.config.item.draftReply.description',
		defaultMessage: 'Drafts a reply based on issues similar to the one you’re working on',
		description:
			'Describes the "Draft reply" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
});
