import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewIssueCta: {
		id: 'issue-create.modal.success-flag.actions.view-issue-cta',
		defaultMessage: 'View {issueCount, plural, one {issue} other {issues}}',
		description: 'CTA to view the new issues when issues are created',
	},
	copyLinkCta: {
		id: 'issue-create.modal.success-flag.actions.copy-link-cta',
		defaultMessage: 'Copy link',
		description: 'CTA for copying issueDiscovery link',
	},
	linkCopied: {
		id: 'issue-create.modal.success-flag.actions.link-copied',
		defaultMessage: 'Copied',
		description: 'Text to be displayed when user copies the link',
	},
	addAllIssuesToSprintCta: {
		id: 'issue-create.modal.success-flag.actions.add-all-issues-to-sprint-cta',
		defaultMessage: 'Add to {sprintName}',
		description: 'CTA to add all new issues to a sprint',
	},
	clearFiltersCta: {
		id: 'issue-create.modal.success-flag.actions.clear-filters-cta',
		defaultMessage: 'Clear filters',
		description: 'CTA to clear board filters if any are applied',
	},
	clearFiltersCtaLoading: {
		id: 'issue-create.modal.success-flag.actions.clear-filters-cta-loading',
		defaultMessage: 'Clearing...',
		description: 'Loading text shown when clearing filters',
	},
	clearFiltersCtaCompleted: {
		id: 'issue-create.modal.success-flag.actions.clear-filters-cta-completed',
		defaultMessage: 'Filters cleared',
		description: 'Text shown when clearing filters is completed',
	},
	addIssuesToSprintCtaLoading: {
		id: 'issue-create.modal.success-flag.actions.add-issues-to-sprint-cta-loading',
		defaultMessage: 'Adding...',
		description: 'Loading text shown when adding new issues to a sprint',
	},
	addIssuesToSprintCtaCompleted: {
		id: 'issue-create.modal.success-flag.actions.add-issues-to-sprint-cta-completed',
		defaultMessage: 'Added to sprint',
		description: 'Text shown when adding new issues to a sprint is completed',
	},
	viewIssueCtaIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.actions.view-issue-cta-issue-term-refresh',
		defaultMessage: 'View {issueCount, plural, one {work item} other {work items}}',
		description: 'CTA to view the new issues when issues are created',
	},
});
