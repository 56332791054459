/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import devSummaryCreatePullRequestPopup from './assets/dev-summary-create-pull-request-popup.tpl.svg';

const containerStyles = css({
	paddingTop: token('space.0', '0'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.0', '0'),
	paddingLeft: token('space.200', '16px'),
	height: '4em',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	object: {
		borderLeftWidth: 'unset',
		borderRightWidth: 'unset',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		borderLeftWidth: 'unset',
		borderRightWidth: 'unset',
	},
});

export const DevSummaryCreatePullRequestPopupSkeleton = () => (
	<div css={containerStyles}>
		<AppSkeletonImage src={devSummaryCreatePullRequestPopup} />
	</div>
);
