import React, { useCallback, useEffect, useState, useRef, forwardRef, type Ref } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql, type EntryPointProps } from 'react-relay';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SingleLineTextEditView } from '@atlassian/jira-issue-field-single-line-text-editview-full/src/ui/single-line-text/index.tsx';
import {
	parseTimeString,
	isValidTimeString,
} from '@atlassian/jira-issue-format-time/src/index.tsx';
import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import type { originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$key as TimeTrackingSettingsFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView.graphql';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import type { TimeFormat, TimeUnit } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import messages from './messages.tsx';
import type { OriginalEstimateEditViewProps } from './types.tsx';

const compactWidthPixels = `${gridSize * 14.5}px`;

const DEBOUCE_DELAY_MS = 500;

export const OriginalEstimateEditView = forwardRef(
	(
		{
			autoFocus = true,
			onChange,
			initialTimeInSeconds,
			isDisabled = false,
			isInvalid = false,
			placeholder = '',
			compact = false,
			onChangeInvalidMessage,
			invalidMessage,
			timeTrackingSettingsfragmentRef,
			onFocus,
			onBlur,
		}: OriginalEstimateEditViewProps,
		ref: Ref<HTMLInputElement>,
	) => {
		const allowValueOverrideRef = useRef(true);
		const isValueInitalisedRef = useRef(false);
		const [inputValue, setInputValue] = useState('');
		const timeTrackingSettingsData = useFragment<TimeTrackingSettingsFragment>(
			graphql`
				fragment originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView on JiraTimeTrackingSettings {
					workingHoursPerDay
					workingDaysPerWeek
					defaultFormat
					defaultUnit
				}
			`,
			timeTrackingSettingsfragmentRef,
		);

		const intl = useIntl();
		const { formatMessage } = useIntl();

		/*
		 * this hook have `initialTimeInSeconds` in its deps array so we could now track the changes
		 * and be able to verride the value by a third-party, e.g. ui modifications, in addition,
		 * there is a lock mechanism `allowValueOverrideRef` that blocks the value override when user does manual input
		 * so this useEffect won't do anything during that period of time, we unlock for value override on blur.
		 */
		useEffect(
			() => {
				if (!allowValueOverrideRef.current) {
					return;
				}

				if (isValueInitalisedRef.current && initialTimeInSeconds === null) {
					setInputValue('');
					onChange(null);

					return;
				}

				if (initialTimeInSeconds && !Number.isNaN(initialTimeInSeconds)) {
					const formattedInputValue = timeTrackingFormatter(
						initialTimeInSeconds,
						{
							workingHoursPerDay: timeTrackingSettingsData?.workingHoursPerDay || 8,
							workingDaysPerWeek: timeTrackingSettingsData?.workingDaysPerWeek || 5,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							timeFormat: (timeTrackingSettingsData?.defaultFormat as TimeFormat) || 'pretty',
							defaultUnit:
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								(timeTrackingSettingsData?.defaultUnit?.toLowerCase() as TimeUnit) || 'minute',
						},
						intl,
					);

					setInputValue(formattedInputValue);

					if (isValueInitalisedRef.current) {
						onChange(initialTimeInSeconds);
					}
				}

				if (!isValueInitalisedRef.current) {
					isValueInitalisedRef.current = true;
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[initialTimeInSeconds],
		);

		const [debouncedSetInputError, cancel] = useDebouncedCallback(
			onChangeInvalidMessage,
			DEBOUCE_DELAY_MS,
		);

		const validateString = useCallback(
			(timeString: string, skipDebounce = false) => {
				const timeTrackingSettings = {
					hoursPerDay: timeTrackingSettingsData?.workingHoursPerDay || 8,
					daysPerWeek: timeTrackingSettingsData?.workingDaysPerWeek || 5,
					format: timeTrackingSettingsData?.defaultFormat || 'pretty',
					defaultUnit: timeTrackingSettingsData?.defaultUnit?.toLowerCase() || 'minute',
					isTimeTrackingEnabled: true,
				};
				cancel();

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				if (isValidTimeString(timeTrackingSettings as TimeTrackingConfig)(timeString)) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const newTimeValue = parseTimeString(timeTrackingSettings as TimeTrackingConfig)(
						timeString,
					);
					onChange(newTimeValue);
				} else {
					const errorMessage = formatMessage(messages.invalidInput);
					skipDebounce
						? onChangeInvalidMessage(errorMessage)
						: debouncedSetInputError(errorMessage);
				}
			},
			[
				cancel,
				debouncedSetInputError,
				formatMessage,
				onChange,
				onChangeInvalidMessage,
				timeTrackingSettingsData?.defaultFormat,
				timeTrackingSettingsData?.defaultUnit,
				timeTrackingSettingsData?.workingDaysPerWeek,
				timeTrackingSettingsData?.workingHoursPerDay,
			],
		);

		const handleChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				/**
				 * `allowValueOverrideRef` is set to `false` when there is user input going on
				 * so the useEffect won't trigger value change with each `initialTimeInSeconds` update
				 * the value change logic is intended to work with value override when the field is unlocked
				 * for overriding.
				 */
				if (allowValueOverrideRef.current) {
					allowValueOverrideRef.current = false;
				}

				onChangeInvalidMessage(null);
				setInputValue(event.target.value);
				validateString(event.target.value);
			},
			[onChangeInvalidMessage, validateString],
		);

		const handleBlur = useCallback(
			(event: React.FocusEvent<HTMLInputElement>) => {
				/**
				 * `allowValueOverrideRef` is set to `true` when the user blurs from the field
				 * so features like UI modification could override the value when there is no user interaction.
				 */
				if (!allowValueOverrideRef.current) {
					allowValueOverrideRef.current = true;
				}

				validateString(inputValue, true);

				onBlur?.(event);
			},
			[inputValue, validateString, onBlur],
		);

		return (
			<OriginalEstimateEditContainer
				data-testid="issue-field-original-estimate-editview-full.ui.original-estimate.edit"
				isCompact={compact}
			>
				<SingleLineTextEditView
					ref={ref}
					isDisabled={isDisabled}
					isInvalid={isInvalid}
					onBlur={handleBlur}
					autoFocus={autoFocus}
					onFocus={onFocus}
					spacing={compact ? 'compact' : 'default'}
					value={inputValue}
					placeholder={placeholder}
					onChange={handleChange}
					invalidMessage={invalidMessage || undefined}
					ariaLabel={formatMessage(messages.label)}
				/>
			</OriginalEstimateEditContainer>
		);
	},
);

const OriginalEstimateEditViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, OriginalEstimateEditViewProps, {}>) => (
	<OriginalEstimateEditView {...props} />
);

export default OriginalEstimateEditViewEntryPoint;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const OriginalEstimateEditContainer = styled.div<{ isCompact: boolean }>({
	cursor: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isCompact }) => (isCompact ? compactWidthPixels : '100%'),
});
