import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createSuccessFlagTitle: {
		id: 'issue-create.modal.success-flag.create-success-flag-title',
		defaultMessage: 'You\'ve created "{issueKey}" issue',
		description: 'Description for success flag when one issue is created',
	},
	multiplesIssuesCreatedSuccessFlagTitle: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-success-flag-title',
		defaultMessage: "You've created {issueCount, plural, one {# issue} other {# issues}}",
		description: 'Description for success flag when multiple issues are created',
	},
	issueCreatedNoSprintOrInBacklogSuccessFlagTitle: {
		id: 'issue-create.modal.success-flag.issue-created-no-sprint-or-in-backlog-success-flag-title',
		defaultMessage: '{issueCount, plural, one {Issue} other {Issues}} added to the backlog',
		description:
			'Title for success flag when one or multiple issues have been created which has no sprint or has been placed in the backlog',
	},
	issueCreatedNoSprintSuccessFlagDescription: {
		id: 'issue-create.modal.success-flag.issue-created-no-sprint-success-flag-description',
		defaultMessage:
			'{issueCount, plural, one {Your issue {issueKey} has} other {Your issues have}} been created and added to the backlog.',
		description:
			'Description for success flag when one or multiple issues have been created which has no sprint',
	},
	multiplesIssuesCreatedSomeNoSprintSuccessFlagTitle: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-no-sprint-success-flag-title',
		defaultMessage: 'Issues added to sprint(s) and backlog',
		description:
			'Title for success flag when multiple issues are created, and some have not been assigned to a sprint',
	},
	multiplesIssuesCreatedSomeNoSprintSuccessFlagDescription: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-no-sprint-success-flag-description',
		defaultMessage: "Some of your issues weren't added to a sprint.",
		description:
			'Description for success flag when multiple issues are created, and some have not been assigned to a sprint',
	},
	createdHiddenIssueSuccessFlagTitle: {
		id: 'issue-create.modal.success-flag.created-hidden-issue-success-flag-title',
		defaultMessage: '{issueCount, plural, one {Issue} other {Issues}} created but not visible',
		description:
			'Title for success flag when one or more issues are created and the issue is hidden due to filters or is non-active',
	},
	scrumCreatedHiddenIssueSuccessFlagDescription: {
		id: 'issue-create.modal.success-flag.scrum-created-hidden-issue-success-flag-description',
		defaultMessage:
			'{issueCount, plural, one {If the board has active filters, or the issue was added to a different sprint, it may not be visible} other {If the board has active filters, or the issues were added to a different sprint, they may not be visible}}.',
		description:
			'Description for success flag when one or more issues are created and the issue is hidden due to filters or is non-active',
	},
	issueCreatedInBacklogSuccessFlagDescription: {
		id: 'issue-create.modal.success-flag.issue-created-in-backlog-success-flag-description',
		defaultMessage:
			"{issueCount, plural, one {Your issue {issueKey} has been created, but isn't on the board} other {Your issues have been created, but aren't on the board}}.",
		description:
			'Title for success flag when one or multiple issues have been created and has been placed in the backlog',
	},
	multiplesIssuesCreatedSomeInBacklogSuccessFlagTitle: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-in-backlog-success-flag-title',
		defaultMessage: 'Issues added to board and backlog',
		description:
			'Title for success flag when multiple issues are created, and some have been placed in the backlog',
	},
	multiplesIssuesCreatedSomeInBacklogSuccessFlagDescription: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-in-backlog-success-flag-description',
		defaultMessage: "Your issues have been created, but some aren't on the board.",
		description:
			'Description for success flag when multiple issues are created, and some have been placed in the backlog',
	},
	kanbanCreatedHiddenIssueSuccessFlagDescription: {
		id: 'issue-create.modal.success-flag.kanban-created-hidden-issue-success-flag-description',
		defaultMessage:
			"{issueCount, plural, one {If the board has active filters, or the issue wasn't added to the board, it may not be visible} other {If the board has active filters, or the issues weren't added to the board, they may not be visible}}.",
		description:
			'Description for success flag when one or more issues are created and the issue is hidden due to filters or is non-active in a Kanban board',
	},
	createSuccessFlagTitleIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.create-success-flag-title-issue-term-refresh',
		defaultMessage: 'You\'ve created "{issueKey}" work item',
		description: 'Description for success flag when one issue is created',
	},
	multiplesIssuesCreatedSuccessFlagTitleIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-success-flag-title-issue-term-refresh',
		defaultMessage: "You've created {issueCount, plural, one {# work item} other {# work items}}",
		description: 'Description for success flag when multiple issues are created',
	},
	issueCreatedNoSprintOrInBacklogSuccessFlagTitleIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.issue-created-no-sprint-or-in-backlog-success-flag-title-issue-term-refresh',
		defaultMessage: '{issueCount, plural, one {Work item} other {Work items}} added to the backlog',
		description:
			'Title for success flag when one or multiple issues have been created which has no sprint or has been placed in the backlog',
	},
	issueCreatedNoSprintSuccessFlagDescriptionIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.issue-created-no-sprint-success-flag-description-issue-term-refresh',
		defaultMessage:
			'{issueCount, plural, one {Your work item {issueKey} has} other {Your work items have}} been created and added to the backlog.',
		description:
			'Description for success flag when one or multiple issues have been created which has no sprint',
	},
	multiplesIssuesCreatedSomeNoSprintSuccessFlagTitleIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-no-sprint-success-flag-title-issue-term-refresh',
		defaultMessage: 'Work items added to sprint(s) and backlog',
		description:
			'Title for success flag when multiple issues are created, and some have not been assigned to a sprint',
	},
	multiplesIssuesCreatedSomeNoSprintSuccessFlagDescriptionIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-no-sprint-success-flag-description-issue-term-refresh',
		defaultMessage: "Some of your work items weren't added to a sprint.",
		description:
			'Description for success flag when multiple issues are created, and some have not been assigned to a sprint',
	},
	createdHiddenIssueSuccessFlagTitleIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.created-hidden-issue-success-flag-title-issue-term-refresh',
		defaultMessage:
			'{issueCount, plural, one {Work item} other {Work items}} created but not visible',
		description:
			'Title for success flag when one or more issues are created and the issue is hidden due to filters or is non-active',
	},
	scrumCreatedHiddenIssueSuccessFlagDescriptionIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.scrum-created-hidden-issue-success-flag-description-issue-term-refresh',
		defaultMessage:
			'{issueCount, plural, one {If the board has active filters, or the work item was added to a different sprint, it may not be visible} other {If the board has active filters, or the work items were added to a different sprint, they may not be visible}}.',
		description:
			'Description for success flag when one or more issues are created and the issue is hidden due to filters or is non-active',
	},
	issueCreatedInBacklogSuccessFlagDescriptionIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.issue-created-in-backlog-success-flag-description-issue-term-refresh',
		defaultMessage:
			"{issueCount, plural, one {Your work item {issueKey} has been created, but isn't on the board} other {Your work items have been created, but aren't on the board}}.",
		description:
			'Title for success flag when one or multiple issues have been created and has been placed in the backlog',
	},
	multiplesIssuesCreatedSomeInBacklogSuccessFlagTitleIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-in-backlog-success-flag-title-issue-term-refresh',
		defaultMessage: 'Work items added to board and backlog',
		description:
			'Title for success flag when multiple issues are created, and some have been placed in the backlog',
	},
	multiplesIssuesCreatedSomeInBacklogSuccessFlagDescriptionIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.multiples-issues-created-some-in-backlog-success-flag-description-issue-term-refresh',
		defaultMessage: "Your work items have been created, but some aren't on the board.",
		description:
			'Description for success flag when multiple issues are created, and some have been placed in the backlog',
	},
	kanbanCreatedHiddenIssueSuccessFlagDescriptionIssueTermRefresh: {
		id: 'issue-create.modal.success-flag.kanban-created-hidden-issue-success-flag-description-issue-term-refresh',
		defaultMessage:
			"{issueCount, plural, one {If the board has active filters, or the work item wasn't added to the board, it may not be visible} other {If the board has active filters, or the work items weren't added to the board, they may not be visible}}.",
		description:
			'Description for success flag when one or more issues are created and the issue is hidden due to filters or is non-active in a Kanban board',
	},
});
