import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	watchingThisIssue: {
		id: 'issue-view-watchers.watchers.dropdown.watchers-list.watching-this-issue',
		defaultMessage: 'Watching this issue',
		description: 'Header displayed on top of the list of watchers',
	},
	noWatchersYet: {
		id: 'issue-view-watchers.watchers.dropdown.watchers-list.no-watchers-yet',
		defaultMessage: 'No watchers yet',
		description: 'Message displayed to users when there is no one watching this issue',
	},
	watchingThisIssueIssueTermRefresh: {
		id: 'issue-view-watchers.watchers.dropdown.watchers-list.watching-this-issue-issue-term-refresh',
		defaultMessage: 'Watching this work item',
		description: 'Header displayed on top of the list of watchers',
	},
});
