import React, { useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { AIEventsInstrumentationProvider } from '@atlassian/ai-analytics';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';

import { AI_CONFIG } from '../common/analytics.tsx';
import type { AiContextServiceResource } from '../common/types.tsx';
import ServiceAIAnalyticsWrapper from './analytics/index.tsx';
import { Content } from './content.tsx';

export type Props = {
	resource: AiContextServiceResource;
};

export const AIContextServicePanel = ({ resource }: Props) => {
	const singleInstrumentationId = useRef(uuid());
	return (
		<AIEventsInstrumentationProvider
			config={{
				...AI_CONFIG,
				singleInstrumentationID: singleInstrumentationId.current,
				customAnalyticsFire: (payload, event) => {
					fireTrackAnalytics(event, payload);
				},
			}}
		>
			<ServiceAIAnalyticsWrapper resource={resource}>
				<Content resource={resource} />
			</ServiceAIAnalyticsWrapper>
		</AIEventsInstrumentationProvider>
	);
};
