import { combineReducers } from 'redux';
import app from './app/index.tsx';
import details from './details/index.tsx';
import ui from './ui/index.tsx';
import workSuggestions from './work-suggestions/index.tsx';

export default combineReducers({
	app,
	ui,
	details,
	workSuggestions,
});
