import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import IssueMeatballMenu from '@atlassian/jira-issue-meatball-menu/src/ui/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	analyticsSourceSelector,
	applicationKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	isCompletedLoadingSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canExportIssueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import {
	forgeActiveIssueActionSelector,
	forgeIssueActionsSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import { hasParentIssueSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/parent-issue-selector.tsx';
import {
	isBannerDismissed,
	shouldShowTourSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/changeboarding-selector.tsx';
import {
	shouldShowActionsSelector,
	shouldShowSwiftLinkSelector,
	shouldShowFindOutMoreSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-selector.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { issueActions_issueViewFoundation_IssueActionsMenuWithRelay$key } from '@atlassian/jira-relay/src/__generated__/issueActions_issueViewFoundation_IssueActionsMenuWithRelay.graphql';
import IssueActionsView from './issue-actions-view.tsx';
import type { IssueActionsProps, IssueActionsPropsOld, StateProps } from './types.tsx';

const IssueActions = connect(
	(state: State): StateProps => ({
		forgeIssueActions: forgeIssueActionsSelector(state),
		isLoading: !isCompletedLoadingSelector(state),
		isForgeLoading: !!forgeActiveIssueActionSelector(state)?.isLoading,
		shouldShowPulse: isBannerDismissed(state),
		hasParent: hasParentIssueSelector(state),
		shouldShowActions: shouldShowActionsSelector(state),
		shouldShowStartTour: shouldShowTourSelector(state),
		shouldShowFindOutMore: shouldShowFindOutMoreSelector(state),
		shouldShowConfiguration: !!shouldShowSwiftLinkSelector(state) && !!projectTypeSelector(state),
		shouldShowExportActions: canExportIssueSelector(state),
		isSoftwareProjectType: projectTypeSelector(state) === SOFTWARE_PROJECT,
		analyticsSource: analyticsSourceSelector(state),
		applicationKey: applicationKeySelector(state) || '',
	}),
	{},
)(IssueActionsView);

const IssueActionsMenu = (props: IssueActionsPropsOld) => {
	if (__SERVER__) {
		return <IssueMeatballMenu isDisabled getMeatballMenuData={() => []} />;
	}

	return (
		<IssueActions
			viewModeOptions={props.viewModeOptions}
			issueDeleteCallbacks={props.issueDeleteCallbacks}
			onClose={props.onClose}
		/>
	);
};

const IssueActionsMenuWithRelay = (props: IssueActionsProps) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<issueActions_issueViewFoundation_IssueActionsMenuWithRelay$key>(
		graphql`
			fragment issueActions_issueViewFoundation_IssueActionsMenuWithRelay on JiraIssue {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...view_issueViewFoundation_ConfigureIssueFF
			}
		`,
		props.issueViewRelayFragment,
	);
	// TODO: move these in to the relay fragment
	// TODO: collapse this component in to the IssueActionsView component
	const projectType = useSelector(projectTypeSelector);
	const forgeIssueActions = useSelector(forgeIssueActionsSelector);
	const forgeActiveIssue = useSelector(forgeActiveIssueActionSelector);
	const shouldShowPulse = useSelector(isBannerDismissed);
	const hasParent = useSelector(hasParentIssueSelector);
	const shouldShowActions = useSelector(shouldShowActionsSelector);
	const shouldShowStartTour = useSelector(shouldShowTourSelector);
	const shouldShowFindOutMore = useSelector(shouldShowFindOutMoreSelector);
	const shouldShowExportActions = useSelector(canExportIssueSelector);
	const analyticsSource = useSelector(analyticsSourceSelector);
	const applicationKey = useSelector(applicationKeySelector);
	const shouldShowSwiftLink = useSelector(shouldShowSwiftLinkSelector);
	const isCompletedLoading = useSelector(isCompletedLoadingSelector);

	if (__SERVER__) {
		return <IssueMeatballMenu isDisabled getMeatballMenuData={() => []} />;
	}

	const isSoftwareProjectType = projectType === SOFTWARE_PROJECT;
	const isForgeLoading = Boolean(forgeActiveIssue?.isLoading);
	const shouldShowConfiguration = Boolean(shouldShowSwiftLink && projectType);

	return (
		<IssueActionsView
			issueViewRelayFragment={data}
			viewModeOptions={props.viewModeOptions}
			issueDeleteCallbacks={props.issueDeleteCallbacks}
			forgeIssueActions={forgeIssueActions}
			isLoading={!isCompletedLoading}
			isForgeLoading={isForgeLoading}
			shouldShowPulse={shouldShowPulse}
			hasParent={hasParent}
			shouldShowActions={shouldShowActions}
			shouldShowStartTour={shouldShowStartTour}
			shouldShowFindOutMore={shouldShowFindOutMore}
			shouldShowConfiguration={shouldShowConfiguration}
			shouldShowExportActions={shouldShowExportActions}
			isSoftwareProjectType={isSoftwareProjectType}
			analyticsSource={analyticsSource}
			applicationKey={applicationKey || ''}
			onClose={props.onClose}
		/>
	);
};

const IssueActionsMenuGated = componentWithCondition(
	() => fg('jiv-replace-configuration-button-in-context-panel'),
	IssueActionsMenuWithRelay,
	IssueActionsMenu,
);

export default IssueActionsMenuGated;
