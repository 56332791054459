import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { CopyTextField } from '@atlassian/jira-platform-copy-text-field/src/index.tsx';
import { fireUIAnalytics, FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	GIT_COMMAND_MAX_LENGTH,
	COPY_PAGE_GIT_COMMAND_LENGTH_ERROR_MESSAGE_TEST_ID,
	WARNING,
} from '../../../common/constants.tsx';
import messages from '../../../common/messages.tsx';
import { useBranchCommandBuilderStore } from '../../../controllers/branch-command-builder-store/index.tsx';
import ErrorAlert from './branch-command-builder/error-alert/index.tsx';
import { getGitCommand } from './branch-command-builder/utils.tsx';

const CopyBranchCommand = () => {
	const { formatMessage } = useIntl();
	const [{ originalTemplates, branchData }] = useBranchCommandBuilderStore();
	const { projectKey, issueKey: branchConfigIssueKey, issueSummary, issueType } = branchData;

	const { gitCommand, gitCommandLength } = getGitCommand(
		originalTemplates,
		projectKey,
		branchConfigIssueKey,
		issueSummary,
		issueType,
	);

	const suggestedBranchName = gitCommand;
	const isGitCommandLengthValid = gitCommandLength <= GIT_COMMAND_MAX_LENGTH;

	return (
		<>
			<FireUiAnalytics
				eventName="createBranchCommand shown"
				{...{ attributes: { gitCommandLength } }}
			/>
			<CopyTextField
				value={suggestedBranchName}
				onCopyClick={(_, analyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'createBranchCommandCopyButton', {
						redirectToScmProviderPage: false,
						...{ gitCommandLength },
					});
				}}
				aria-label="Copy branch command"
			/>
			{!isGitCommandLengthValid && (
				<GitCommandErrorWrapper>
					<ErrorAlert
						testId={COPY_PAGE_GIT_COMMAND_LENGTH_ERROR_MESSAGE_TEST_ID}
						type={WARNING}
						alertMessage={formatMessage(messages.copyPageGitCommandErrorMessage, {
							maxLength: GIT_COMMAND_MAX_LENGTH,
						})}
					/>
				</GitCommandErrorWrapper>
			)}
		</>
	);
};

export default CopyBranchCommand;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GitCommandErrorWrapper = styled.div({
	marginTop: token('space.100', '8px'),
	marginRight: 0,
	marginBottom: 0,
	marginLeft: 0,
});
