import head from 'lodash/head';
import { fg } from '@atlassian/jira-feature-gating';
import type { LocalDecorationId } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import {
	dualWriteValueDecorationsBasedOnFieldType,
	performJiraConfigurationUpdate,
} from './utils.tsx';

type deleteAllValueDecorationsProps = {
	fieldKey: FieldKey;
};

export const deleteValueDecoration =
	(fieldKey: FieldKey, localDecorationId: LocalDecorationId) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{
			fieldRemote,
			createAnalyticsEvent,
			issueTypeIds,
			onDecorationUpdateFailed,
			onFieldUpdateFailed,
		}: Props,
	): Promise<void> => {
		const state = getState();
		const { fieldValueDecorations, fields } = state;
		const field = fields[fieldKey];
		const existingValueDecorations = fieldValueDecorations[fieldKey] || [];
		const newValueDecorations = [...existingValueDecorations];
		const issueTypeId = head(issueTypeIds);

		const decorationIdx = newValueDecorations.findIndex(
			({ localDecorationId: id }) => localDecorationId === id,
		);
		newValueDecorations.splice(decorationIdx, 1);

		setState({
			fieldValueDecorations: {
				...fieldValueDecorations,
				[fieldKey]: newValueDecorations,
			},
		});

		if (fg('polaris_improve-value-decoration-update-actions')) {
			try {
				try {
					await performJiraConfigurationUpdate({
						fieldKey,
						fieldType: field.type,
						issueTypeId,
						fieldRemote,
						existingDecorations: existingValueDecorations,
						newDecorations: newValueDecorations,
						isGlobalSystemField: fg('polaris_new_custom_types_global_system_fields')
							? isGlobalSystemField(field)
							: false,
					});

					fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
						issueFieldKey: fieldKey,
						issueFieldType: field.type,
					});
				} catch (error) {
					setState({
						fieldValueDecorations: {
							...getState().fieldValueDecorations,
							[fieldKey]: existingValueDecorations,
						},
					});

					throw error;
				}

				if (
					field.type === FIELD_TYPES.LINKED_ISSUES &&
					fg('polaris_new_custom_types_global_system_fields')
				) {
					await dualWriteValueDecorationsBasedOnFieldType({
						fieldKey,
						fieldType: field.type,
						issueTypeId,
						fieldRemote,
						newValueDecorations,
						getState,
						setState,
					});
				}
			} catch (error) {
				onDecorationUpdateFailed(
					error instanceof Error ? error : new Error('Failed to delete value decoration'),
				);
			}
		} else {
			try {
				await performJiraConfigurationUpdate({
					fieldKey,
					fieldType: field.type,
					issueTypeId,
					fieldRemote,
					existingDecorations: existingValueDecorations,
					newDecorations: newValueDecorations,
					isGlobalSystemField: fg('polaris_new_custom_types_global_system_fields')
						? isGlobalSystemField(field)
						: false,
				});

				fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
					issueFieldKey: fieldKey,
					issueFieldType: field.type,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				onFieldUpdateFailed(e);
			}
		}
	};

export const deleteAllValueDecorations =
	({ fieldKey }: deleteAllValueDecorationsProps) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, createAnalyticsEvent, issueTypeIds }: Props,
	): Promise<void> => {
		try {
			const state = getState();
			const { fieldValueDecorations, fields } = state;
			const field = fields[fieldKey];
			const existingValueDecorations = fieldValueDecorations[fieldKey] || [];
			const issueTypeId = head(issueTypeIds);

			setState({
				fieldValueDecorations: {
					...fieldValueDecorations,
					[fieldKey]: [],
				},
			});

			await performJiraConfigurationUpdate({
				fieldKey,
				fieldType: field.type,
				issueTypeId,
				fieldRemote,
				existingDecorations: existingValueDecorations,
				newDecorations: [],
				isGlobalSystemField: fg('polaris_new_custom_types_global_system_fields')
					? isGlobalSystemField(field)
					: false,
			});

			fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			onFieldUpdateFailed(e);
		}
		return undefined;
	};
