import { useCallback, useMemo } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { type FlagConfiguration, toFlagId, useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import type { FeedbackContext, FeedbackData } from '../../common/types.tsx';
import messages from './messages.tsx';

const FEEDBACK_ENDPOINT = '/gateway/api/feedback-collector-api/feedback';
const REQUEST_TYPE = '594';
const EMBED_KEY = 'a64ab7a8-2ce8-4942-884d-0f3090ef971e';
const MAX_SUMMARY_LENGTH = 100;

// The custom fields for consent are defined in this file: src/packages/platform/ui/help-panel/src/services/feedback/index.tsx
// HOWEVER, they are not exported and as this feedback collector is expected to be a temporary solution the change hasn't been
// made to export them.
const CONTACT_FIELD_ID = 'customfield_10043';
const CONTACT_TRUE_VALUE = '10109';
const CONTACT_FALSE_VALUE = '10111';
const RESEARCH_FIELD_ID = 'customfield_10044';
const RESEARCH_TRUE_VALUE = '10110';
const RESEARCH_FALSE_VALUE = '10112';

const FEEDBACK_SUBMITTED_FLAG: FlagConfiguration = {
	id: toFlagId('atlassian-intelligence.ui.prompt-response.feedback.submitted'),
	type: 'success',
	title: messages.feedbackSubmittedFlagTitle,
	description: messages.feedbackSubmittedFlagDescription,
	messageType: 'transactional',
};

const normalizeTextForSummary = (feedbackText: string) => {
	if (!feedbackText.length) return '<no summary>';
	const singleLineFeedbackText = feedbackText.replace(/\n/g, ' ');
	return singleLineFeedbackText.length > MAX_SUMMARY_LENGTH
		? `${singleLineFeedbackText.substring(0, MAX_SUMMARY_LENGTH)}...`
		: singleLineFeedbackText;
};

type FeedbackFieldOptionValue = { id: string }[];
type FeedbackField = { id: string; value: string | FeedbackFieldOptionValue };
type GetFeedbackFieldsWithConsent = (args: {
	data: FeedbackData;
	feedbackContext: string;
	isPositive: boolean;
	emailAddress: string | null | undefined;
	displayName: string | null | undefined;
}) => FeedbackField[];

const getFeedbackFieldsWithConsent: GetFeedbackFieldsWithConsent = ({
	data,
	feedbackContext,
	isPositive,
	emailAddress,
	displayName,
}) => {
	const { feedbackText, contactConsentPrompt, researchConsentPrompt } = data;
	return [
		{
			id: 'summary',
			value: `[${isPositive ? '+' : '-'}] ${normalizeTextForSummary(feedbackText)}`,
		},
		{ id: 'description', value: feedbackText },
		{
			id: 'email',
			value: emailAddress ?? 'do-not-reply@atlassian.com',
		},
		{
			id: 'customfield_10045',
			value: displayName ?? 'unknown',
		},
		{ id: 'aaidOrHash', value: '' },
		{ id: 'customfield_10047', value: feedbackContext },
		{
			id: CONTACT_FIELD_ID,
			value: [{ id: contactConsentPrompt ? CONTACT_TRUE_VALUE : CONTACT_FALSE_VALUE }],
		},
		{
			id: RESEARCH_FIELD_ID,
			value: [{ id: researchConsentPrompt ? RESEARCH_TRUE_VALUE : RESEARCH_FALSE_VALUE }],
		},
	];
};

const normalizeFeedbackContext = (feedbackContext: Array<{ id: string; value: string }>) =>
	feedbackContext.map(({ id, value }) => `${id}: ${value}`).join('\n');

const removeName = (prompt: string, name: string) => prompt.replace(new RegExp(name, 'gi'), 'XXX');

const removeNames = (prompt: string, userFullname: string) =>
	userFullname
		.split(' ')
		.reduce((acc, name) => (name === '' ? acc : removeName(acc, name)), prompt);

const removeEmailAddresses = (prompt: string) =>
	prompt.replace(
		new RegExp('([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+)', 'gi'),
		'XXX@XXX.XXX',
	);

const removePII = (prompt: string, userFullname: string) =>
	removeEmailAddresses(removeNames(prompt, userFullname));

export const useFeedbackService = (
	prompt: string,
	additionalFeedbackContext?: FeedbackContext[],
) => {
	const { showFlag } = useFlagsService();

	const [{ data: loggedInUser }] = useUserSubscriber();

	const onSubmitFeedback = useCallback(
		async (data: FeedbackData, isPositive: boolean) => {
			if (!data.feedbackText && !data.sharePrompt) {
				showFlag(FEEDBACK_SUBMITTED_FLAG);
				// Do nothing, probably the user just wanted to send a thumbs up / thumbs down rating
				return Promise.resolve();
			}

			const feedbackContext: FeedbackContext[] = [
				{
					id: 'Location',

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					value: normaliseUrl(window?.location?.href ?? ''),
				},
				{
					id: 'Screen Resolution',

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					value: `${window?.screen?.width ?? 'unknown'} x ${window?.screen?.height ?? 'unknown'}`,
				},
				{
					id: 'User-Agent',

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					value: `${window?.navigator?.userAgent ?? 'unknown'}`,
				},
				{
					id: 'Rating',
					value: isPositive ? 'Thumbs up' : 'Thumbs down',
				},
			];

			if (data.sharePrompt && prompt) {
				feedbackContext.push({
					id: 'Prompt',
					value: removePII(prompt, loggedInUser?.displayName ?? ''),
				});
			}
			if (additionalFeedbackContext?.length && data.sharePrompt) {
				feedbackContext.push(...additionalFeedbackContext);
			}

			try {
				await fetch(FEEDBACK_ENDPOINT, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						data: btoa(
							JSON.stringify({
								feedback: {
									requestType: REQUEST_TYPE,
									embedKey: EMBED_KEY,
									fields: getFeedbackFieldsWithConsent({
										data,
										feedbackContext: normalizeFeedbackContext(feedbackContext),
										isPositive,
										emailAddress: loggedInUser?.emailAddress,
										displayName: loggedInUser?.displayName,
									}),
								},
							}),
						),
					}),
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'onFeedbackSubmit',
						packageName: 'jiraAtlassianIntelligence',
						teamName: 'gravity-ai',
					},
					sendToPrivacyUnsafeSplunk: true,
					error,
				});
			}

			showFlag(FEEDBACK_SUBMITTED_FLAG);
			return Promise.resolve();
		},
		[
			loggedInUser?.displayName,
			loggedInUser?.emailAddress,
			prompt,
			showFlag,
			additionalFeedbackContext,
		],
	);

	return useMemo(() => ({ onSubmitFeedback }), [onSubmitFeedback]);
};
