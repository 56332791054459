import { isFedRamp } from '@atlaskit/atlassian-context';
import {
	LoomTouchpointVariant,
	type LoomTouchpointVariantType,
} from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './ui/messages.tsx';

export const LoomButtonType = {
	PROVISION: 'provisionButton',
	JOIN: 'joinButton',
	SDK: 'sdkButton',
};

export const getButtonType = (loomTouchpointVariant: LoomTouchpointVariantType) => {
	const isFedRampInstance = isFedRamp();
	if (loomTouchpointVariant === LoomTouchpointVariant.CROSSFLOW && !isFedRampInstance) {
		return LoomButtonType.PROVISION;
	}
	if (loomTouchpointVariant === LoomTouchpointVariant.CROSSJOIN && !isFedRampInstance) {
		return LoomButtonType.JOIN;
	}
	if (loomTouchpointVariant === LoomTouchpointVariant.COUSE) {
		return LoomButtonType.SDK;
	}

	return '';
};

export const getLabel = (loomTouchpointVariant: LoomTouchpointVariantType) => {
	const buttonType = getButtonType(loomTouchpointVariant);
	if (buttonType === LoomButtonType.SDK) {
		if (fg('quick_actions_menu_ga')) {
			if (fg('quick_actions_m2_experiment_gate')) {
				return messages.createVideoButtonLabel;
			}
			return messages.newLoomVideoButtonLabel;
		}
		return messages.loomRecordVideoButtonLabel;
	}

	return fg('quick_actions_menu_ga')
		? messages.loomVideoButtonLabel
		: messages.loomRecordVideoButtonLabel;
};
