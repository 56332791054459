import { useRef, useCallback, useEffect, useMemo } from 'react';
import {
	isLoadingLinkedIssues,
	isInitialized,
	isIssuesLoading,
	getError,
	isDeliveryIssueCountRestricted,
	isArchivedIssuesLoading,
	getArchivedError,
	getIsIssueStatusRollbackInProgress,
} from './meta.tsx';
import { createIssueHook } from './utils.tsx';

export const useIsLoadingLinkedIssues = createIssueHook(isLoadingLinkedIssues);
export const useIsInitialized = createIssueHook(isInitialized);
export const useIsIssuesLoading = createIssueHook(isIssuesLoading);
export const useError = createIssueHook(getError);
export const useIsArchivedIssuesLoading = createIssueHook(isArchivedIssuesLoading);
export const useArchivedError = createIssueHook(getArchivedError);

export const useIsDeliveryIssueCountRestricted = createIssueHook(isDeliveryIssueCountRestricted);

export const useIsInitializedPromise = (): (() => Promise<boolean>) => {
	const initialized = useIsInitialized();
	const resolverRef = useRef<((v: boolean) => void) | null>(null);

	useEffect(() => {
		if (!initialized) {
			return;
		}
		if (resolverRef.current) {
			resolverRef.current(true);
		}
	}, [initialized]);

	/**
	 * resolve the promise either
	 * - immediately if initialized is already true on first hook access
	 *   if the callback function gets recreated due to `initialized` changeing
	 *   this is fine, as any new listeners on the new promise will resolve immediately,
	 *   while the old listeners on the old promise still get served by the resolverRef effect
	 * - delayed, if initialized is false and switches from false to true
	 */

	const promise = useMemo(
		() =>
			initialized
				? Promise.resolve(true)
				: new Promise<boolean>((resolve) => {
						resolverRef.current = resolve;
					}),
		[initialized],
	);
	return useCallback(() => promise, [promise]);
};

export const useIsIssueStatusRollbackInProgress = createIssueHook(
	getIsIssueStatusRollbackInProgress,
);
