import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	toggleWatching: {
		id: 'issue-view-watchers.watchers.keyboard-shortcuts.toggle-watching',
		defaultMessage: 'watch / unwatch',
		description: 'Tooltip for the watchers shortcut',
	},
	startWatching: {
		id: 'issue-view-watchers.watchers.keyboard-shortcuts.start-watching',
		defaultMessage: 'Start watching issue',
		description: 'Command palette label to start watching current issue',
	},
	stopWatching: {
		id: 'issue-view-watchers.watchers.keyboard-shortcuts.stop-watching',
		defaultMessage: 'Stop watching issue',
		description: 'Command palette label to stop watching current issue',
	},
	startWatchingIssueTermRefresh: {
		id: 'issue-view-watchers.watchers.keyboard-shortcuts.start-watching-issue-term-refresh',
		defaultMessage: 'Start watching',
		description: 'Command palette label to start watching current issue',
	},
	stopWatchingIssueTermRefresh: {
		id: 'issue-view-watchers.watchers.keyboard-shortcuts.stop-watching-issue-term-refresh',
		defaultMessage: 'Stop watching',
		description: 'Command palette label to stop watching current issue',
	},
});
