import type { IssueMetaIssueLink } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { JPD_CONNECTION_ISSUE_LINK_TYPE } from '../../constants.tsx';

export const findConnectionIssueLink = (
	id: string,
	{ typeName, inwardIssue, outwardIssue }: IssueMetaIssueLink,
) =>
	typeName === JPD_CONNECTION_ISSUE_LINK_TYPE &&
	(outwardIssue.id.toString() === id || inwardIssue.id.toString() === id);

type Issue<IdType> = {
	id: IdType;
	issueTypeId: IssueTypeId;
};

type ConnectionDirection<IdType> = {
	inwardIssueId: IdType;
	outwardIssueId: IdType;
};

export const getConnectionDirection = <IdType,>(
	issue1: Issue<IdType>,
	issue2: Issue<IdType>,
): ConnectionDirection<IdType> => {
	if (issue1.issueTypeId === issue2.issueTypeId) {
		throw new Error('Cannot connect an issue to the same issue type');
	}
	return Number(issue1.issueTypeId) > Number(issue2.issueTypeId)
		? { inwardIssueId: issue1.id, outwardIssueId: issue2.id }
		: { inwardIssueId: issue2.id, outwardIssueId: issue1.id };
};
