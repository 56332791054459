import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	configureColumnsErrorTitle: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.configure-columns-error-title',
		defaultMessage: "We couldn't configure the columns",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to configure columns in a table.",
	},
	resetColumnsErrorTitle: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.reset-columns-error-title',
		defaultMessage: "We couldn't restore the default columns",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to configure columns in a table.",
	},
	columnConfigMutationErrorDescription: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.column-config-mutation-error-description',
		defaultMessage: 'Refresh and try again.',
		description:
			"Displayed as error flag's description when an unexpected error happens while user is trying to configure or edit columns in a table.",
	},
	getChildIssueErrorTitle: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.get-child-issue-error-title',
		defaultMessage: "We couldn't get your child issues",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to load child issue table.",
	},
	getChildIssueErrorDescription: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.get-child-issue-error-description',
		defaultMessage: 'Refresh and try again.',
		description:
			"Displayed as error flag's description when an unexpected error happens while user is trying to load child issue table.",
	},
	columnResizeMutationErrorTitle: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.column-resize-mutation-error-title',
		defaultMessage: "We couldn't resize the column",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to resize a column in the child issue table.",
	},
	columnResizeMutationErrorDescription: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.column-resize-mutation-error-description',
		defaultMessage: 'Refresh and try again.',
		description:
			"Displayed as error flag's description when an unexpected error happens while user is trying to resize a column in the child issue table.",
	},
	getChildIssueErrorTitleIssueTermRefresh: {
		id: 'issue-view-common-views.child-issues-panel.configurable-child-issues-panel.get-child-issue-error-title-issue-term-refresh',
		defaultMessage: "We couldn't get your child work items",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to load child issue table.",
	},
});
