import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewProfile: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.details-view.view-profile',
		defaultMessage: 'View profile',
		description:
			'Text for a link that opens the profile of the associated entity (an organization or customer).',
	},
});
