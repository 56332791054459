import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'issue-field-status.services.flags.transition-failed-error.main.title',
		defaultMessage: "We couldn't update the status",
		description: "Occurs when a user tries to update an issue's status but fails to do so",
	},
	description: {
		id: 'issue-field-status.services.flags.transition-failed-error.main.description',
		defaultMessage: 'Check your network connection, then try reloading the issue.',
		description: "Occurs when a user tries to update an issue's status but fails to do so",
	},
});

const statusTransitionErrorFlag = (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: messages.description,
	isAutoDismiss: true,
});

export default statusTransitionErrorFlag;
