/**
 * @generated SignedSource<<2535bb2c3a3717ca9d72d470db2f8013>>
 * @relayHash 117823cf028a8800f8adbd88dafeb183
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d016b14194995dd3daf62c8acb1fe154184a674548782fc7de0014dd78efa6fd

import type { ConcreteRequest, Query } from 'relay-runtime';
export type detailsWorkSuggestionsDataQuery$variables = {
  cloudId: string;
  issueId: string;
};
export type detailsWorkSuggestionsDataQuery$data = {
  readonly workSuggestions: {
    readonly suggestionsByIssues: {
      readonly recentPullRequests: {
        readonly mergeableSuggestions: ReadonlyArray<{
          readonly id: string;
          readonly isMergeActionEnabled: boolean | null | undefined;
          readonly url: string;
        }> | null | undefined;
        readonly pullRequestReviewSuggestions: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type detailsWorkSuggestionsDataQuery = {
  response: detailsWorkSuggestionsDataQuery$data;
  variables: detailsWorkSuggestionsDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueId"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = {
  "kind": "ScalarField",
  "name": "url"
},
v3 = [
  {
    "concreteType": "WorkSuggestions",
    "kind": "LinkedField",
    "name": "workSuggestions",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "items": [
              {
                "kind": "Variable",
                "name": "issueIds.0",
                "variableName": "issueId"
              }
            ],
            "kind": "ListValue",
            "name": "issueIds"
          }
        ],
        "concreteType": "WorkSuggestionsByIssuesResponse",
        "kind": "LinkedField",
        "name": "suggestionsByIssues",
        "plural": false,
        "selections": [
          {
            "concreteType": "WorkSuggestionsPullRequestSuggestionsResponse",
            "kind": "LinkedField",
            "name": "recentPullRequests",
            "plural": false,
            "selections": [
              {
                "concreteType": "WorkSuggestionsPullRequestReviewTask",
                "kind": "LinkedField",
                "name": "pullRequestReviewSuggestions",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ]
              },
              {
                "concreteType": "WorkSuggestionsPRMergeableTask",
                "kind": "LinkedField",
                "name": "mergeableSuggestions",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "isMergeActionEnabled"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "detailsWorkSuggestionsDataQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detailsWorkSuggestionsDataQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "d016b14194995dd3daf62c8acb1fe154184a674548782fc7de0014dd78efa6fd",
    "metadata": {},
    "name": "detailsWorkSuggestionsDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "fa7f47759a719a1bee80d344323bc43a";

export default node;
