// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	selfLinkInvalidIssue: {
		id: 'issue.issue-link-invalid-self-link',
		defaultMessage: "Can't link the issue {issueKey} to itself.",
		description:
			'The error message shown to the user when they attempt to link an issue to the currently opened issue.',
	},
	unauthenticatedAppLink: {
		id: 'issue.unauthenticated-app-link',
		defaultMessage: 'Unauthenticated site. Try authenticating before, then link again.',
		description:
			'Error message that is presented to the user when they try to link an issue from an unauthenticated remote jira site and the applink is not available.',
	},
	unauthenticatedAppLinkWithSiteName: {
		id: 'issue.unauthenticated-app-link-with-site-name',
		defaultMessage:
			'Unauthenticated site. Try authenticating the site {appLinkName}, then link again.',
		description:
			'Error message that is presented to the user when they try to link an issue from an unauthenticated remote jira site and the applink is available.',
	},
	invalidIssue: {
		id: 'issue.issue-link-invalid-issue',
		defaultMessage:
			"Can't find {issueKey}. Try searching for another issue key, or in another site.",
		description:
			'Error message that is presented to the user when they try to link one invalid issue.',
	},
	twoInvalidIssues: {
		id: 'issue.issue-link-two-invalid-issues',
		defaultMessage:
			"Can't find {issueKeys}. Try searching for other issue keys or in another site.",
		description:
			'Error message tha is presented to the user when they try to link two invalid issues. {issueKeys} is a list of issue keys split by comma (if more than one).',
	},
	multipleInvalidIssues: {
		id: 'issue.issue-link-multiple-invalid-issues',
		defaultMessage:
			'Can’t find {firstTwoIssueKeys} and {countRemainingIssues} other {countRemainingIssues, plural, one {issue} other {issues}}. Try searching for other issue keys or in another site.',
		description:
			'Error message that is presented to the user when they try to link multiple invalid issues.',
	},
	otherAppLink: {
		id: 'issue.other-applink',
		defaultMessage:
			'We can only link issues from one site at a time. Try linking issues from one site first.',
		description:
			'Error message that is presented to the user when they try to link an issue from another jira site.',
	},
	selfLinkInvalidIssueIssueTermRefresh: {
		id: 'issue.issue-link-invalid-self-link-issue-term-refresh',
		defaultMessage: "Can't link the work item {issueKey} to itself.",
		description:
			'The error message shown to the user when they attempt to link an issue to the currently opened issue.',
	},
	invalidIssueIssueTermRefresh: {
		id: 'issue.issue-link-invalid-issue-issue-term-refresh',
		defaultMessage: "Can't find {issueKey}. Try searching for another key, or in another site.",
		description:
			'Error message that is presented to the user when they try to link one invalid issue.',
	},
	twoInvalidIssuesIssueTermRefresh: {
		id: 'issue.issue-link-two-invalid-issues-issue-term-refresh',
		defaultMessage: "Can't find {issueKeys}. Try searching for other keys or in another site.",
		description:
			'Error message tha is presented to the user when they try to link two invalid issues. {issueKeys} is a list of issue keys split by comma (if more than one).',
	},
	multipleInvalidIssuesIssueTermRefresh: {
		id: 'issue.issue-link-multiple-invalid-issues-issue-term-refresh',
		defaultMessage:
			'Can’t find {firstTwoIssueKeys} and {countRemainingIssues} other {countRemainingIssues, plural, one {work item} other {work items}}. Try searching for other keys or in another site.',
		description:
			'Error message that is presented to the user when they try to link multiple invalid issues.',
	},
	otherAppLinkIssueTermRefresh: {
		id: 'issue.other-applink-issue-term-refresh',
		defaultMessage:
			'We can only link work items from one site at a time. Try linking from one site first.',
		description:
			'Error message that is presented to the user when they try to link an issue from another jira site.',
	},
});
