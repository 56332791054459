import React, { useMemo, useCallback, useEffect } from 'react';
import Avatar from '@atlaskit/avatar';
import { Grid, Text, Flex, Anchor, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIsFullPageIssueView } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { IdeaViewBentoSidebarEntryPoint } from '@atlassian/jira-polaris-component-idea-view-bento-sidebar/entrypoint.tsx';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	DRAWER,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useHoverState } from '../../common/ui/hover-state/index.tsx';
import {
	useActiveEntryPointSubjectActions,
	useOpenedOnce,
} from '../../controllers/entrypoint-reference-store.tsx';
import { useIdeaPanelChangeBoarding } from '../../controllers/idea-panel-change-boarding.tsx';
import { IdeaUnlinkCrossButton } from './idea-unlink-cross-button/index.tsx';

export const IdeaCard = ({
	summary,
	issueKey,
	issueId,
	issueTypeIconUrl,
	issueTypeName,
	issueLinkId,
	isUrlSelected,
	onUnlinkModalConfirmed,
}: {
	summary: string;
	issueKey: string;
	issueId: string;
	issueTypeIconUrl?: string;
	issueTypeName?: string;
	issueLinkId?: string;
	isUrlSelected?: boolean;
	onUnlinkModalConfirmed: (issueLinkId: string) => void;
}) => {
	const [_, setViewedChangeBoarding] = useIdeaPanelChangeBoarding();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isFullPageIssueView: boolean = useIsFullPageIssueView();
	const cloudId = useCloudId();
	const [isHovered, hoverProps] = useHoverState();
	const { setEntryPointReferenceSubject } = useActiveEntryPointSubjectActions();
	const [openedOnce] = useOpenedOnce();

	const entryPointParams = useMemo(() => ({ issueKey, cloudId }), [cloudId, issueKey]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		IdeaViewBentoSidebarEntryPoint,
		entryPointParams,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const handleOnClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (isFullPageIssueView) {
				e.preventDefault();
				setEntryPointReferenceSubject(entryPointReferenceSubject);
				setViewedChangeBoarding();
			}

			fireUIAnalytics(createAnalyticsEvent({}), 'card clicked', 'ideaLinkedCard', {
				target: isFullPageIssueView ? 'sidebar' : 'newTab',
				clickedIssueId: issueId,
			});
		},
		[
			createAnalyticsEvent,
			isFullPageIssueView,
			issueId,
			setEntryPointReferenceSubject,
			entryPointReferenceSubject,
			setViewedChangeBoarding,
		],
	);

	const handleOnDragStart = (event: React.DragEvent<HTMLAnchorElement>) => {
		event.preventDefault();
	};

	/* will not cause endless loop because of the `isUrlSelected` and `openedOnce` dependency */
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isUrlSelected && !openedOnce) {
			entryPointActions.load();
			setEntryPointReferenceSubject(entryPointReferenceSubject);
		}
	}, [
		entryPointActions,
		entryPointReferenceSubject,
		isUrlSelected,
		openedOnce,
		setEntryPointReferenceSubject,
		createAnalyticsEvent,
	]);

	return (
		<Anchor
			ref={triggerRef}
			href={`/browse/${issueKey}`}
			target={!isFullPageIssueView ? '_blank' : undefined}
			onClick={handleOnClick}
			onDragStart={handleOnDragStart}
			xcss={isVisualRefreshEnabled() ? ideaCardStyles : ideaCardStylesOld}
			{...hoverProps}
		>
			{isUrlSelected && !openedOnce && (
				<ContextualAnalyticsData
					sourceName="ideaViewBentoSidebarWithOnboardingIdeaKey"
					sourceType={DRAWER}
					attributes={{
						issueKey,
						issueId,
						issueLinkId,
					}}
				>
					<FireScreenAnalytics />
				</ContextualAnalyticsData>
			)}
			<Grid templateColumns={issueTypeIconUrl ? '20px 1fr' : '1fr'} gap="space.100">
				{issueTypeIconUrl ? (
					<Tooltip content={issueTypeName || null}>
						<Flex>
							<Avatar
								appearance="square"
								src={issueTypeIconUrl}
								size="xsmall"
								borderColor="transparent"
							/>
						</Flex>
					</Tooltip>
				) : null}
				<Text maxLines={1}>{summary}</Text>
			</Grid>
			{issueLinkId && (
				<IdeaUnlinkCrossButton
					issueKey={issueKey}
					issueLinkId={issueLinkId}
					isParentHovered={isHovered}
					onUnlinkModalConfirmed={onUnlinkModalConfirmed}
				/>
			)}
		</Anchor>
	);
};

const ideaCardStyles = xcss({
	textDecoration: 'none',
	background: 'none',
	cursor: 'pointer',

	border: 'solid',
	borderColor: 'color.border',
	borderWidth: '0px',

	borderBottomWidth: '1px',
	borderLeftWidth: '1px',
	borderRightWidth: '1px',

	paddingLeft: 'space.100',
	paddingRight: 'space.100',

	display: 'flex',
	alignItems: 'center',
	height: '40px',
	justifyContent: 'space-between',

	transition: '0.3s',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		color: 'color.text',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	':first-of-type': {
		borderTopWidth: '1px',
		borderTopLeftRadius: 'border.radius',
		borderTopRightRadius: 'border.radius',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':last-of-type': {
		borderBottomWidth: '1px',
		borderBottomLeftRadius: 'border.radius',
		borderBottomRightRadius: 'border.radius',
	},
});

const ideaCardStylesOld = xcss({
	borderBottom: `1px solid ${token('color.border', '#ebecf0')}`,
	display: 'flex',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	height: '40px',
	alignItems: 'center',
	transition: '0.3s',
	cursor: 'pointer',
	color: 'color.text',
	textDecoration: 'none',
	justifyContent: 'space-between',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		color: 'color.text',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	':last-child': {
		border: 'none',
	},
});
