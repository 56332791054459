import React, { type MouseEventHandler } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import type { LozengeThemes, ParentFieldOption } from '../../common/types.tsx';
import { getLozengeThemeByColor } from '../../common/utils.tsx';
import messages from './messages.tsx';

type Props = {
	value: ParentFieldOption | null;
	noValueText: string;
	isMobile: boolean;
	onClick?: MouseEventHandler;
};

type SummaryProps = Pick<Props, 'isMobile' | 'onClick'> & {
	label: string;
	lozengeTheme?: LozengeThemes;
	issueKey: string;
};

const Summary = ({ isMobile, onClick, label, lozengeTheme, issueKey }: SummaryProps) => {
	if (isMobile) {
		return (
			<WrapperNoClick
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={lozengeTheme}
			>
				{label}
			</WrapperNoClick>
		);
	}

	if (onClick != null) {
		return (
			<ButtonText
				onClick={onClick}
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={lozengeTheme}
			>
				{label}
			</ButtonText>
		);
	}

	return (
		<LinkText
			data-testid="issue-field-parent.ui.view-link"
			href={`/browse/${issueKey}`}
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={lozengeTheme}
		>
			{label}
		</LinkText>
	);
};

export const ParentViewField = (props: Props) => {
	const { value, noValueText, isMobile, onClick } = props;
	const { formatMessage } = useIntl();

	if (value) {
		const { key, fields } = value;

		const label = `${key} ${fields.summary}`;

		const style = value.color ? getLozengeThemeByColor(value.color) : undefined;

		return (
			<Tooltip content={label} position="bottom">
				<Container data-testid="issue-field-parent.ui.container">
					<Icon
						src={fields.issuetype.iconUrl}
						alt={formatMessage(messages.icon, {
							issueType: fields?.issuetype.name || '',
						})}
					/>
					<Summary
						isMobile={isMobile}
						label={label}
						lozengeTheme={style}
						onClick={onClick}
						issueKey={key}
					/>
				</Container>
			</Tooltip>
		);
	}
	return (
		<EmptyValue data-testid="issue-field-parent.ui.view-read-view-empty-value">
			{noValueText}
		</EmptyValue>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const LinkText = styled(Link)(singleLineClamp, {
	cursor: 'pointer',
	maxWidth: '100%',
	borderRadius: token('border.radius', '3px'),
	paddingTop: 0,
	paddingRight: token('space.050', '4px'),
	paddingBottom: 0,
	paddingLeft: token('space.050', '4px'),
	font: token('font.body'),
	marginRight: token('space.100', '8px'),
	'&:hover': {
		textDecoration: 'underline',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ButtonText = styled.button(singleLineClamp, {
	cursor: 'pointer',
	height: '20px',
	maxWidth: '100%',
	borderRadius: token('border.radius', '3px'),
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.050', '4px'),
	font: token('font.body'),
	marginRight: token('space.100', '8px'),
	'&:hover': {
		textDecoration: 'underline',
	},
	'&:focus': {
		outline: 'none',
	},
	// Disable buttons styles
	border: 'none',
	background: 'none',
	color: 'inherit',
	outline: 'inherit',
	lineHeight: 'normal',
	textOverflow: 'ellipsis',
	display: 'block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	marginRight: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 2}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const WrapperNoClick = styled.span(singleLineClamp, {
	display: 'block',
	height: '16px',
	borderRadius: token('border.radius', '3px'),
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.050', '4px'),
	font: token('font.body'),
	maxWidth: '100%',
	marginRight: token('space.100', '8px'),
	lineHeight: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyValue = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});
