import { useEffect, useMemo } from 'react';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

import { createResource, useResource } from '@atlassian/react-resource-router';
import type { JsmMimEntitlement } from './controllers/get-data/types.tsx';

const getDataLoader = () =>
	import(
		/*
			webpackChunkName: "async-resource-jsm-mim-entitlement",
			jiraSpaEntry: "async-resource-jsm-mim-entitlement"
		*/
		'./controllers/get-data'
	);

export const jsmMIMEntitlementResource = createResource<JsmMimEntitlement | undefined>({
	type: 'JSM_MIM_ENTITLEMENT',
	getKey: () => 'JSM_MIM_ENTITLEMENT',
	getDataLoader,
	maxAge: __SERVER__ ? 0 : Number.MAX_SAFE_INTEGER,
	isBrowserOnly: true,
});

export const useJsmMimEntitlementResource = (cloudId: CloudId | null): JsmMimEntitlement => {
	const { data, loading, error, refresh } = useResource(jsmMIMEntitlementResource);

	useEffect(() => {
		if (!data && !loading && !error) {
			refresh();
		}
	}, [data, loading, refresh, error]);

	return useMemo(() => {
		if (error || loading || !data || !cloudId) {
			return {
				isEntitled: false,
			};
		}

		const { isEntitled } = data;

		return {
			isEntitled,
		};
	}, [data, loading, error, cloudId]);
};
