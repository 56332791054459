import React, { useEffect } from 'react';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JIRA_ISSUE_LINK_PAGE } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { CreatePageMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-page-menu-item/index.tsx';
import { CreateWhiteboardMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-whiteboard-menu-item/index.tsx';
import { createConfluenceContentMenuEntrypoint } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/entrypoint.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';

export const CreateConfluenceContentMenu = ({
	onLinkPage,
}: {
	onLinkPage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
}) => {
	const hasConfluenceAccess = useUserHasConfluenceAccess(JIRA_ISSUE_LINK_PAGE).hasAccess;
	const {
		entryPointActions: confluenceContentMenuActions,
		entryPointReferenceSubject: confluenceContentMenuSubject,
	} = useEntryPoint(createConfluenceContentMenuEntrypoint, {});

	const tenantHasConfluence = useTenantHasConfluence();

	useEffect(() => {
		if (hasConfluenceAccess) {
			confluenceContentMenuActions.load();
		}
	}, [hasConfluenceAccess, confluenceContentMenuActions, tenantHasConfluence]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={confluenceContentMenuSubject}
			id="confluenceContentMenuEntryPointContainer"
			packageName="@atlassian/jira-issue-create-confluence-content"
			teamName="confluence-better-together"
			runtimeProps={{
				appearance: 'link',
				children: (
					<>
						<CreatePageMenuItem />
						<CreateWhiteboardMenuItem />
					</>
				),
				hasConfluenceAccess,
				cacheOnlyAccessStatus: true,
				embeddedConfluenceSource: JIRA_ISSUE_LINK_PAGE,
				onLinkPage,
			}}
		/>
	);
};
