import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsArchived,
	useProjectKey,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { SUBTASKS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { HIERARCHY_LEVEL_ABOVE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectIssueTypes,
	useIsSimplifiedProject,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type {
	ShortcutComponentProps,
	ShortcutOption,
} from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsConcealActionsInMeatballExpEnabled } from '@atlassian/jira-is-conceal-actions-experiment-enabled/src/useIsConcealActionsInMeatballExpEnabled.tsx';
import type { IssueDeleteCallbackArgs } from '../delete-issue/types.tsx';
import ArchiveIssueButtonItem from './archive-issue/button-item.tsx';
import { archiveIssueLabel } from './archive-issue/index.tsx';
import ArchiveIssue from './archive-issue/main.tsx';
import CloneIssue, { CloneIssueButtonItem, cloneIssueLabel } from './clone-issue/index.tsx';
import ConvertToIssueButtonItem from './convert-to-issue/button-item.tsx';
import { convertToIssueLabel } from './convert-to-issue/index.tsx';
import ConvertToIssue from './convert-to-issue/main.tsx';
import ConvertToSubtaskButtonItem from './convert-to-subtask/button-item.tsx';
import { convertToSubtaskLabel } from './convert-to-subtask/index.tsx';
import ConvertToSubtask from './convert-to-subtask/main.tsx';
import DeleteIssue, { DeleteIssueButtonItem, deleteIssueLabel } from './delete-issue/index.tsx';
import LinkToParent, {
	LinkToParentButtonItem,
	addToParentLevelLabel,
	changeParentLevelLabel,
} from './link-to-parent/index.tsx';
import MoveIssueButtonItem from './move-issue/button-item.tsx';
import { moveIssueLabel } from './move-issue/index.tsx';
import MoveIssue from './move-issue/main.tsx';
import UnarchiveIssueButtonItem from './unarchive-issue/button-item.tsx';
import { unarchiveIssueLabel } from './unarchive-issue/index.tsx';
import UnarchiveIssue from './unarchive-issue/main.tsx';

export const issueManipulationDropdownGroup = (
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
) => ({
	name: '',
	key: 'IssueManipulationDropdownGroup',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: actions.reduce<Array<any>>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []),
});

export const useIssueManipulationActions = (
	hasParent: boolean,
	onIssueDeleteSuccess?: ((arg1: IssueDeleteCallbackArgs) => void) | undefined,
	onClose?: () => void,
): ShortcutOption[] => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey();
	const [parentLevel] = useFieldValue({ issueKey, fieldKey: HIERARCHY_LEVEL_ABOVE });
	const [issueType] = useFieldValue({ issueKey, fieldKey: 'issuetype' });
	const [subtasks] = useFieldValue({ issueKey, fieldKey: SUBTASKS });
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const issueTypes = useProjectIssueTypes(projectKey);
	const isSubtask = issueType?.subtask;
	const [permissions] = useProjectPermissions(projectKey);
	const isArchived = useIsArchived();
	const isConcealActionsExpEnabled = fg('_conceal_items_into_meatball_menu')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsConcealActionsInMeatballExpEnabled()
		: false;
	const ArchiveIssueWrap = useMemo(
		() => (props: ShortcutComponentProps) => (
			<ArchiveIssue {...props} onIssueDeleteSuccess={onIssueDeleteSuccess} onClose={onClose} />
		),
		[onIssueDeleteSuccess, onClose],
	);

	const ArchiveIssueButtonItemWrap = useMemo(
		() => (props: ShortcutComponentProps) => (
			<ArchiveIssueButtonItem
				{...props}
				onIssueDeleteSuccess={onIssueDeleteSuccess}
				onClose={onClose}
			/>
		),
		[onClose, onIssueDeleteSuccess],
	);

	const shouldShowLinkToParent = useMemo(() => {
		const hasParentLevelName = !!parentLevel?.name;
		const hasParentLevelIssueTypes = parentLevel?.issueTypeIds.length > 0;

		return (
			hasParentLevelName &&
			permissions.canEditIssues &&
			hasParentLevelIssueTypes &&
			isSimplifiedProject
		);
	}, [isSimplifiedProject, parentLevel, permissions]);

	const shouldShowConvertToSubtask = useMemo(() => {
		const hasSubtasks = subtasks?.length > 0;
		const hasSubtaskTypes = (issueTypes || []).filter((type) => type.subtask).length > 0;
		return !isSubtask && !hasSubtasks && hasSubtaskTypes && !isSimplifiedProject;
	}, [subtasks, issueTypes, isSubtask, isSimplifiedProject]);

	const shouldShowConvertToIssue = useMemo(
		() => isSubtask && !isSimplifiedProject,
		[isSubtask, isSimplifiedProject],
	);

	const shouldShowArchiveIssue = !isArchived && permissions.canArchiveIssue && !isSubtask;

	const shouldShowUnarchiveIssue = isArchived && permissions.canUnarchiveIssue && !isSubtask;

	const updatedCloneMoveActionOrder = useMemo(
		() =>
			isConcealActionsExpEnabled
				? [
						...(permissions.canCloneIssue
							? [
									{
										Component: CloneIssue,
										DialogComponent: CloneIssueButtonItem,
										label: formatMessage(cloneIssueLabel),
										key: 'cloneIssue',
									},
								]
							: []),
						...(permissions.canMoveIssue
							? [
									{
										Component: MoveIssue,
										DialogComponent: MoveIssueButtonItem,
										label: formatMessage(moveIssueLabel),
										key: 'moveIssue',
									},
								]
							: []),
					]
				: [
						...(permissions.canMoveIssue
							? [
									{
										Component: MoveIssue,
										DialogComponent: MoveIssueButtonItem,
										label: formatMessage(moveIssueLabel),
										key: 'moveIssue',
									},
								]
							: []),
						...(permissions.canCloneIssue
							? [
									{
										Component: CloneIssue,
										DialogComponent: CloneIssueButtonItem,
										label: formatMessage(cloneIssueLabel),
										key: 'cloneIssue',
									},
								]
							: []),
					],
		[
			formatMessage,
			isConcealActionsExpEnabled,
			permissions.canCloneIssue,
			permissions.canMoveIssue,
		],
	);

	const items = useMemo(
		() => [
			...(shouldShowLinkToParent
				? [
						{
							Component: LinkToParent,
							DialogComponent: LinkToParentButtonItem,
							label: formatMessage(hasParent ? changeParentLevelLabel : addToParentLevelLabel),
							key: 'linkToParent',
						},
					]
				: []),
			...(shouldShowConvertToSubtask
				? [
						{
							Component: ConvertToSubtask,
							DialogComponent: ConvertToSubtaskButtonItem,
							label: formatMessage(convertToSubtaskLabel),
							key: 'convertToSubtask',
						},
					]
				: []),
			...(shouldShowConvertToIssue
				? [
						{
							Component: ConvertToIssue,
							DialogComponent: ConvertToIssueButtonItem,
							label: formatMessage(convertToIssueLabel),
							key: 'convertToIssue',
						},
					]
				: []),
			...updatedCloneMoveActionOrder,
			...(shouldShowArchiveIssue
				? [
						{
							Component: ArchiveIssueWrap,
							DialogComponent: ArchiveIssueButtonItemWrap,
							label: formatMessage(archiveIssueLabel),
							key: 'archiveIssue',
						},
					]
				: []),
			...(shouldShowUnarchiveIssue
				? [
						{
							Component: UnarchiveIssue,
							DialogComponent: UnarchiveIssueButtonItem,
							label: formatMessage(unarchiveIssueLabel),
							key: 'unarchiveIssue',
						},
					]
				: []),
			...(permissions.canDeleteIssue
				? [
						{
							Component: DeleteIssue,
							DialogComponent: DeleteIssueButtonItem,
							label: formatMessage(deleteIssueLabel),
							key: 'deleteIssue',
						},
					]
				: []),
		],
		[
			shouldShowLinkToParent,
			formatMessage,
			hasParent,
			shouldShowConvertToSubtask,
			shouldShowConvertToIssue,
			updatedCloneMoveActionOrder,
			shouldShowArchiveIssue,
			ArchiveIssueWrap,
			ArchiveIssueButtonItemWrap,
			shouldShowUnarchiveIssue,
			permissions.canDeleteIssue,
		],
	);

	return items;
};
