import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueTypeName: {
		id: 'smart-related-issues-panel.issue-line-card-group.issue-line-card.issue-type-name',
		defaultMessage: '{issueTypeName}',
		description: 'A text title for the issue type icon, mainly for accessibility purposes.',
	},
	issueLinkingButton: {
		id: 'smart-related-issues-panel.issue-line-card-group.issue-line-card.issue-linking-button',
		defaultMessage: 'Link issue',
		description:
			'Button for linking an issue shown in the Related Issues Panel to the current issue being shown in issue view',
	},
	issueLinkingButtonAriaLabel: {
		id: 'smart-related-issues-panel.issue-line-card-group.issue-line-card.issue-linking-button-aria-label',
		defaultMessage: 'Link issue',
		description: 'The aria label for issue linking button',
	},
	issueLinkingButtonIssueTermRefresh: {
		id: 'smart-related-issues-panel.issue-line-card-group.issue-line-card.issue-linking-button-issue-term-refresh',
		defaultMessage: 'Link work item',
		description:
			'Button for linking an issue shown in the Related Issues Panel to the current issue being shown in issue view',
	},
	issueLinkingButtonAriaLabelIssueTermRefresh: {
		id: 'smart-related-issues-panel.issue-line-card-group.issue-line-card.issue-linking-button-aria-label-issue-term-refresh',
		defaultMessage: 'Link work item',
		description: 'The aria label for issue linking button',
	},
});
