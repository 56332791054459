/** @jsx jsx */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import type { Dispatch } from 'redux';
import { css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { IconTile } from '@atlaskit/icon';
import Page16Icon from '@atlaskit/icon-object/glyph/page/16';
import PageIcon from '@atlaskit/icon/core/page';
import type { CreatePayload } from '@atlaskit/link-create';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullPageIssueView } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { createConfluencePageLinkRequest } from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import { hasActivatedConfluenceWithinFourteenDaysSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { systemConfluenceAppLinkSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-app-links-selector.tsx';
import { toHref } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ACTION_SUBJECTS, ACTIONS } from '../../../common/constants/index.tsx';
import {
	SET_ACTIVE_PAGE_DATA,
	SET_IS_CREATE_CONTENT_MODAL_OPEN,
	SET_IS_EP_MODAL_LOADING,
} from '../../../common/types/actions.tsx';
import { CONTENT_TYPE, type CreateContentModalCoreProps } from '../../../common/types/types.tsx';
import { ContentMenuContext } from '../../../controllers/create-content-menu-context/index.tsx';
import type { SpaceInfo } from '../../../controllers/types.tsx';
import { useConfluenceSite } from '../../../controllers/use-confluence-site/index.tsx';
import { useCreateContentClickBehavior } from '../../../controllers/use-create-content-click-behavior/index.tsx';
import { useEmbeddedConfluenceDefaultSpace } from '../../../controllers/use-embedded-confluence-default-space/index.tsx';
import { useEmbeddedPageTracking } from '../../../controllers/use-embedded-page-tracking/index.tsx';
import { useParentProduct } from '../../../controllers/use-parent-product/index.tsx';
import { createConfluenceDraft } from '../../../services/confluence-rest-api/index.tsx';
import type { CreateConfluenceDraftV2Response } from '../../../services/confluence-rest-api/types.tsx';
import {
	constructUrlIfCustomDomain,
	createActivePageUrl,
	createLinkCreatePayload,
	getURLFromConfluencePageResponse,
} from '../../../utils/index.tsx';
import messages from './messages.tsx';
import type { CreatePageMenuItemDispatchProps, CreatePageMenuItemStateProps } from './types.tsx';

const CreatePageMenuItemComponent = ({
	onCancel,
	onCloseComplete,
	onCreate,
	onFailure,
	onOpenComplete,
	onLinkPageOld,
	onCreateBehavior,
	isQuickActionsListView,
	setQuickActionListItem,
	hasActivatedConfluenceWithinFourteenDays,
	systemConfluenceAppLinkUrl,
}: CreateContentModalCoreProps &
	CreatePageMenuItemStateProps &
	// TODO remove this when fg('jira_issue_view_expand_modeless_ep') is removed
	CreatePageMenuItemDispatchProps) => {
	const { formatMessage } = useIntl();
	const {
		accessStatus,
		setCreateContentRuntimeProps,
		setContentMenuState,
		setIsEpModalOpen,
		embeddedConfluenceSource,
		userHasConfluenceAccess,
		setIsDeferSpaceSelection,
		onLinkPage,
	} = useContext(ContentMenuContext);

	const [, sidePanelActions] = useEmbeddedConfluenceSidePanel(embeddedConfluenceSource);
	const { openSidePanel, setIsLoadingForDeferSpaceSelection } = sidePanelActions;
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const getCreateContentClickBehavior = useCreateContentClickBehavior();
	const tenantHasConfluence = useTenantHasConfluence();
	const cloudId = useCloudId();
	const { getEmbeddedConfluenceDefaultSpace } = useEmbeddedConfluenceDefaultSpace();
	const { getConfluenceSite } = useConfluenceSite();
	const isFullIssueView = useIsFullPageIssueView();
	const actionSubjects = ACTION_SUBJECTS[CONTENT_TYPE.PAGE];
	const parentProduct = useParentProduct();

	const isEligibleForSidePanelEPExp = useMemo(
		() => isFullIssueView && !hasActivatedConfluenceWithinFourteenDays && parentProduct !== 'JSM',
		[isFullIssueView, hasActivatedConfluenceWithinFourteenDays, parentProduct],
	);

	const [sidePanelEPExperimentConfig, sidePanelEPExperimentfireExposureEvent] =
		UNSAFE_noExposureExp('jira_issue_view_side_by_side_modeless_ep_exp');

	const isInSidePanelEPExperimentCohort =
		sidePanelEPExperimentConfig.get('cohort', 'not-enrolled') === 'experiment';

	const openPageInEmbeddedConfluence = useCallback(
		(payload: CreatePayload) => {
			const openInModal = () => {
				setContentMenuState({
					type: SET_ACTIVE_PAGE_DATA,
					payload: { ...payload, url: activePageUrl },
				});

				setIsEpModalOpen(true);
			};

			const activePageUrl = createActivePageUrl(payload);
			onCreate && onCreate(payload);
			// Begin Side-by-side EP experiment exposure
			if (isEligibleForSidePanelEPExp) {
				sidePanelEPExperimentfireExposureEvent();

				if (isInSidePanelEPExperimentCohort) {
					return openSidePanel({
						contentId: payload.objectId ?? '',
						contentType: CONTENT_TYPE.PAGE,
						data: payload,
						isLive: payload.data?.subType === 'live',
						isDraft: true,
						url: activePageUrl,
						onLinkPage: fg('jira_issue_view_expand_modeless_ep') ? onLinkPage : onLinkPageOld,
						openInModal,
					});
				}
			}

			openInModal();
		},
		[
			isEligibleForSidePanelEPExp,
			isInSidePanelEPExperimentCohort,
			onCreate,
			onLinkPageOld,
			onLinkPage,
			openSidePanel,
			setContentMenuState,
			setIsEpModalOpen,
			sidePanelEPExperimentfireExposureEvent,
		],
	);

	const createConfluencePage = useCallback(async () => {
		const openCreateContentModal = () => {
			setContentMenuState({
				type: SET_IS_CREATE_CONTENT_MODAL_OPEN,
				payload: true,
			});
			setCreateContentRuntimeProps({
				isOpen: true,
				isQuickActionsListView,
				contentType: CONTENT_TYPE.PAGE,
				onCancel,
				onCloseComplete,
				onCreate: openPageInEmbeddedConfluence,
				onFailure,
				onOpenComplete,
				onCreateBehavior,
			});
		};
		const updateState = ({
			isLoading,
			isDeferSpaceSelection,
		}: {
			isLoading: boolean;
			isDeferSpaceSelection?: boolean;
		}) => {
			if (isInSidePanelEPExperimentCohort && isEligibleForSidePanelEPExp) {
				setIsLoadingForDeferSpaceSelection({ isLoading, isDeferSpaceSelection });
				setIsDeferSpaceSelection(!!isDeferSpaceSelection);
			} else {
				setContentMenuState({
					type: SET_IS_EP_MODAL_LOADING,
					payload: isLoading,
				});
				isDeferSpaceSelection && setIsDeferSpaceSelection(isDeferSpaceSelection);
			}
		};

		updateState({ isLoading: true });
		const defaultSpace: SpaceInfo = await getEmbeddedConfluenceDefaultSpace();
		const { spaceId, spaceKey } = defaultSpace;

		const hostname = await getConfluenceSite();

		if (!spaceKey || !spaceId) {
			openCreateContentModal();
			setContentMenuState({
				type: SET_IS_EP_MODAL_LOADING,
				payload: false,
			});
			return;
		}

		const fetchResponse = await createConfluenceDraft(cloudId, spaceId);
		if (!fetchResponse.success) {
			fireErrorAnalytics({
				meta: {
					id: actionSubjects.CREATE,
					teamName: 'confluence-better-together',
				},
				attributes: { embeddedConfluenceSource },
				error: new Error(`Failed to create Confluence content: ${fetchResponse.error}`),
			});
			// Don't want to run onFailure here because we are defaulting back to using the Create Content Modal
			openCreateContentModal();
			updateState({ isLoading: false });
			return;
		}

		const createResponse: CreateConfluenceDraftV2Response = fetchResponse.result;

		const urlFromPageResponse = getURLFromConfluencePageResponse({
			id: createResponse.id,
			spaceKey,
			hostname,
		});

		const pageUrl = constructUrlIfCustomDomain({
			id: createResponse.id,
			originalDomain: createResponse?._links?.base ?? '',
			originalUrl: urlFromPageResponse,
			spaceKey,
			systemConfluenceAppLinkUrl,
		});

		// TODO: If experiment is successful, de-couple this from CreatePayload and Link-Create
		const payload = createLinkCreatePayload(createResponse, pageUrl, defaultSpace, cloudId);

		openPageInEmbeddedConfluence(payload);

		fireAnalyticsEvent({
			action: ACTIONS.SUCCESS,
			eventName: actionSubjects.CREATE,
			embeddedConfluenceSource,
			eventType: 'operational',
		});

		updateState({ isLoading: false, isDeferSpaceSelection: true });
	}, [
		actionSubjects.CREATE,
		cloudId,
		embeddedConfluenceSource,
		fireAnalyticsEvent,
		getConfluenceSite,
		getEmbeddedConfluenceDefaultSpace,
		isEligibleForSidePanelEPExp,
		isInSidePanelEPExperimentCohort,
		isQuickActionsListView,
		onCancel,
		onCloseComplete,
		onCreateBehavior,
		onFailure,
		onOpenComplete,
		openPageInEmbeddedConfluence,
		setCreateContentRuntimeProps,
		setContentMenuState,
		setIsDeferSpaceSelection,
		setIsLoadingForDeferSpaceSelection,
		systemConfluenceAppLinkUrl,
	]);

	const onOpen = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'createPageMenuItem',
			embeddedConfluenceSource,
			userHasConfluenceAccess,
			tenantHasConfluence,
			crossJoinStatus: accessStatus,
		});
		return getCreateContentClickBehavior({
			accessStatus,
			createContent: createConfluencePage,
			tenantHasConfluence,
		})();
	}, [
		accessStatus,
		createConfluencePage,
		embeddedConfluenceSource,
		fireAnalyticsEvent,
		getCreateContentClickBehavior,
		tenantHasConfluence,
		userHasConfluenceAccess,
	]);

	const isQuickActionsGA = fg('quick_actions_menu_ga') && isQuickActionsListView;

	useEffect(() => {
		let iconComponent = <Page16Icon label="" />;

		if (isQuickActionsGA && fg('quick_actions_m2_experiment_gate')) {
			iconComponent = <PageIcon label="" />;
		} else if (isVisualRefreshEnabled()) {
			iconComponent = (
				<IconTile icon={PageIcon} label="" appearance="blueBold" shape="square" size="16" />
			);
		}

		const label = fg('quick_actions_m2_experiment_gate')
			? formatMessage(messages.createPageQuickActionsNestedList)
			: formatMessage(messages.newConfluencePageQuickActionsList);

		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('quick_actions_menu_ga') &&
			setQuickActionListItem &&
			setQuickActionListItem('confluence', 'new-page', {
				onClick: onOpen,
				isDisabled: false,
				testId:
					'issue-create-confluence-content.ui.create-confluence-content-menu.create-page-menu-item.create-page-menu-item',
				iconComponent: <Box paddingInlineStart="space.050">{iconComponent}</Box>,
				parentLabel: (
					<span css={messageStyles}>
						{formatMessage(messages.confluencePageQuickActionsParent)}
					</span>
				),
				labelComponent: fg('quick_actions_m2_experiment_gate') ? (
					label
				) : (
					<span css={messageStyles}>{label}</span>
				),
				label,
			});
	}, [formatMessage, isQuickActionsGA, onOpen, setQuickActionListItem]);

	if (isQuickActionsGA) {
		return null;
	}

	return (
		<DropdownItem
			testId="issue-create-confluence-content.ui.create-confluence-content-menu.create-page-menu-item.create-page-menu-item"
			onClick={onOpen}
			elemBefore={
				isVisualRefreshEnabled() ? (
					<IconTile icon={PageIcon} label="" appearance="blueBold" shape="square" size="16" />
				) : (
					<Page16Icon label="" />
				)
			}
		>
			{formatMessage(messages.createConfluencePageItem)}
		</DropdownItem>
	);
};

const messageStyles = css({
	paddingLeft: token('space.050'),
});

// TODO remove dispatch param when cleaning up fg('jira_issue_view_expand_modeless_ep')
const mapDispatchToProps = (dispatch: Dispatch) =>
	fg('jira_issue_view_expand_modeless_ep')
		? {}
		: {
				onLinkPageOld: (link: string, analyticsEvent: UIAnalyticsEvent) =>
					dispatch(createConfluencePageLinkRequest(toHref(link), analyticsEvent)),
			};

const mapStateToProps =
	() =>
	(state: State): CreatePageMenuItemStateProps => ({
		hasActivatedConfluenceWithinFourteenDays:
			hasActivatedConfluenceWithinFourteenDaysSelector(state),
		systemConfluenceAppLinkUrl: systemConfluenceAppLinkSelector(state)?.baseUrl ?? '',
	});

export const CreatePageMenuItem = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreatePageMenuItemComponent);
