import { useMutation } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import { isClientErrorResponse } from '../../common/utils.tsx';
import { UPDATE_FIELD_EXPERIENCE } from '../../constants.tsx';
import type {
	JwmUpdateField,
	JwmUpdateFieldVariables,
} from './__generated_apollo__/JwmUpdateField';
import { QUERY } from './gql.tsx';

export const QUERY_NAME = 'JwmUpdateField';

const findMutationBadRequestError = (data?: JwmUpdateField) =>
	data?.jira?.jwmUpdateField?.errors?.find((e) => e.message);

export const useUpdateField = () => {
	const onExperienceSuccess = useExperienceSuccess(UPDATE_FIELD_EXPERIENCE);
	const onExperienceFail = useExperienceFail(UPDATE_FIELD_EXPERIENCE);
	const onExperienceStart = useExperienceStart(UPDATE_FIELD_EXPERIENCE);

	const [updateField, { loading, error }] = useMutation<JwmUpdateField, JwmUpdateFieldVariables>(
		QUERY,
		{
			onError: (apolloError) => {
				fireErrorAnalytics({
					meta: {
						id: QUERY_NAME,
						packageName: 'jiraBusinessInlineFieldConfigSidebar',
						teamName: 'deliveroo',
					},
					error:
						Array.isArray(apolloError) && apolloError.length > 0
							? apolloError[0]
							: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								(apolloError as ApolloError),
					attributes: { message: 'Failed to update the field' },
					sendToPrivacyUnsafeSplunk: true,
				});

				onExperienceFail('Failed to update the field', apolloError);
			},
			onCompleted: (apolloData) => {
				const mutationBadRequestError = findMutationBadRequestError(apolloData);

				if (mutationBadRequestError) {
					const isClientError = isClientErrorResponse(
						mutationBadRequestError.extensions?.statusCode,
					);

					isClientError
						? onExperienceSuccess()
						: onExperienceFail(
								'Failed to update the field',
								new MutationError(mutationBadRequestError),
							);
				} else {
					onExperienceSuccess();
				}
			},
		},
	);

	const update = ({ input }: { input: JwmUpdateFieldVariables['input'] }) => {
		onExperienceStart();

		return updateField({
			variables: { input },
		});
	};

	return { update, loading, error };
};
