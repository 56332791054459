import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { IssueCreatedHistoryItem as IssueCreatedHistoryItemType } from '../../../../types.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: IssueCreatedHistoryItemType;
};

const IssueCreatedHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { actor, timestamp } = historyItem;
	return (
		<FormattedMessage
			id="issue.history.created-issue-type"
			defaultMessage="created the {issue}"
			values={{
				issue: (
					<Emphasise key="issueType">
						{fg('jira-issue-terminology-refresh-m3') ? (
							<FormattedMessage
								id="issue.history.created-issue-type.issue-issue-term-refresh"
								defaultMessage="Work item"
							/>
						) : (
							<FormattedMessage
								id="issue.history.created-issue-type.issue"
								defaultMessage="Issue"
							/>
						)}
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange field="Issue" from={null} to={null} />
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.issue-created-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={null}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default IssueCreatedHistoryItem;
