/**
 * @generated SignedSource<<e2bb6e9d9f47d8c506cd6ab217811d1b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraWorkManagementUserLicenseSeatEdition = "FREE" | "PREMIUM" | "STANDARD" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type main_issueActivityFeed_FeedDisplay$data = {
  readonly jwmLicensing: {
    readonly currentUserSeatEdition: JiraWorkManagementUserLicenseSeatEdition | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_issueActivityFeed_FeedDisplay";
};
export type main_issueActivityFeed_FeedDisplay$key = {
  readonly " $data"?: main_issueActivityFeed_FeedDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueActivityFeed_FeedDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueActivityFeed_FeedDisplay",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraWorkManagementLicensing",
      "kind": "LinkedField",
      "name": "jwmLicensing",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "currentUserSeatEdition"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "46a1eff5f905144ecac69ba7668f3d3f";

export default node;
