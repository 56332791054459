import React, { useCallback, useState } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_SOFTWARE_PROJECT,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';

import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import messages from './messages.tsx';

const FEEDBACK_GROUP_ID = 'sow-nike-issue-view-configurable-child-issues';

const CollectFeedbackActionItem = () => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const isAdmin = useIsAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const appEditions = useAppEditions();
	const route = useCurrentRoute();
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const [isFeedbackDisabled, setIsFeedbackDisabled] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleFeedbackSent = useCallback((wasSent: boolean) => {
		setIsFeedbackDisabled(wasSent);
	}, []);

	const feedbackCollectorContext = [
		{
			id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
			value: FEEDBACK_GROUP_ID,
		},
		{
			id: EntryKeys.ROUTE_NAME,
			value: route.name ?? 'unknown',
		},
		{
			id: EntryKeys.ROUTE_GROUP,
			value: route.group ?? 'unknown',
		},
		{
			id: EntryKeys.IS_ADMIN,
			value: isAdmin ?? 'unknown',
		},
		{
			id: EntryKeys.IS_SITE_ADMIN,
			value: isSiteAdmin ?? 'unknown',
		},
		{
			id: EntryKeys.EDITION,
			value: appEditions?.software ?? 'unknown',
		},
		{
			id: 'product',
			value: projectType ?? 'unknown',
		},
		{
			id: 'component_served',
			value: `isChildIssuesEnabled: ${expVal('jira-nike-chin-m1a-peek-a-boo-child-issue-table', 'isChildIssueEnabled', false)}, isPeekabooEnabled: ${expVal(
				'jira-nike-chin-m1a-peek-a-boo-child-issue-table',
				'isPeekABooChildIssuesEnabled',
				false,
			)}`,
		},
	];
	const handleOnGiveFeedbackClick = useCallback(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'childIssuePanelFeedback clicked');
		if (!isFeedbackDisabled) {
			setIsFeedbackDisabled(true);
			openFeedbackCollector({
				entrypointId: ENTRYPOINT_ID_JIRA_SOFTWARE_PROJECT,
				feedbackGroupId: FEEDBACK_GROUP_ID,
				feedbackContext: feedbackCollectorContext,
				onSubmit() {
					handleFeedbackSent(true);
					fireTrackAnalytics(createAnalyticsEvent({}), 'childIssuePanelFeedback submitted');
				},
				onClose() {
					handleFeedbackSent(false);
				},
			});
		}
		// This function does not need to be triggered with the variables in the feedbackContext
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleFeedbackSent, isFeedbackDisabled, openFeedbackCollector]);

	return (
		<DropdownItem
			onClick={() => handleOnGiveFeedbackClick()}
			isDisabled={isFeedbackDisabled}
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.common.child-issues-panel.heading.meatballs.give-feedback"
		>
			{formatMessage(messages.giveFeedback)}
		</DropdownItem>
	);
};

export default CollectFeedbackActionItem;
