import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	add: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.add',
		defaultMessage: 'Add',
		description:
			'Command name in the command palette to add an attachment to an issue with quick actions list enabled',
	},
	apps: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.apps',
		defaultMessage: 'Apps',
		description:
			'Command name in the command palette to add an attachment to an issue with quick actions list enabled',
	},
	buttonAddContent: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-add-content',
		defaultMessage: 'Add content',
		description: 'Button label for adding content in the quick add list',
	},
	buttonAddCreateWork: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-add-create-work',
		defaultMessage: 'Add or create work related to this issue',
		description: 'Button label for adding or creating content in the quick add list for M2',
	},
	buttonViewAppActions: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-view-app-actions',
		defaultMessage: 'View app actions',
		description: 'Button label for viewing ecosystem app actions in the quick add list for M2',
	},
	buttonAddApps: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-add-apps',
		defaultMessage: 'Open or add apps',
		description: 'Button label for adding apps in the quick add list',
	},
	buttonAddAppsNoApps: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-add-apps-no-apps',
		defaultMessage: 'Add apps',
		description: 'Button label for adding apps in the quick add list',
	},
	search: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.search',
		defaultMessage: 'Search',
		description:
			'Search for an item in the quick action add list. This is a placeholder text for the search input',
	},
	findMenuItem: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.find-menu-item',
		defaultMessage: 'Find menu item',
		description:
			'Search for an item in the quick action add list. This is a placeholder text for the input to search menu items.',
	},
});
