import {
	baseScopesRequiredPerField,
	type InternalToPublicPropertyTransformer,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import type { SingleLineTextPublicValue } from '@atlassian/ui-modifications-public-api-utils/field/single-line-text';

type InternalValue = string | undefined | null;

export const transformSingleLineTextToPublicValue: InternalToPublicPropertyTransformer<
	InternalValue,
	SingleLineTextPublicValue
> = (internalValue) => internalValue || '';

export const singleLineTextScopes = baseScopesRequiredPerField;
