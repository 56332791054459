import { createSelector } from 'reselect';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';

export const confluenceWhiteboardsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.confluenceWhiteboards || [],
);

export const hasFetchedConfluenceWhiteboardsSelector = createSelector(
	confluenceWhiteboardsSelector,
	(confluenceWhiteboards) => confluenceWhiteboards.hasFetchedLinkedWhiteboards,
);
