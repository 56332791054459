/**
 * @generated SignedSource<<e3fe3feb0da07d17a3cd9dbf40687abb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewNew_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef">;
  readonly " $fragmentType": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewNew_fragmentRef";
};
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewNew_fragmentRef$key = {
  readonly " $data"?: singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewNew_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewNew_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewNew_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraSingleSelectField"
};

(node as any).hash = "bc537df14c041707c77e3b1219c10d5c";

export default node;
