import { useCallback } from 'react';
import OriginTracer from '@atlassiansox/origin-tracing';
import { useInvisibleRecaptcha } from '@atlassian/google-recaptcha';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { toFlagId, useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { postInviteUsers } from '../../services/invite-users/index.tsx';
import { useHandleInviteUserResult } from './handle-invite-user-result/index.tsx';
import { messages } from './messages.tsx';
import type { InviteUserRequest, InviteUserResponse } from './types.tsx';
import { getJiraSoftwareAri } from './utils/get-jira-software-ari/index.tsx';
import { getRecaptchaSiteKey } from './utils/get-recaptcha-site-key/index.tsx';

export const INVITE_USER_ERROR_FLAG_ID = toFlagId('INVITE_USER_ERROR');

export const useInviteUser = ({ analyticsSource }: { analyticsSource: string }) => {
	const environment = useEnvironment();
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const handleInvitedUserResult = useHandleInviteUserResult();
	const { getRecaptchaToken } = useInvisibleRecaptcha({
		siteKey: getRecaptchaSiteKey(environment),
		displayBadge: false,
		analyticsChannel: 'jira',
		analyticsSource,
	});

	return useCallback(
		async ({ email, issueKey }: InviteUserRequest): Promise<InviteUserResponse> => {
			try {
				const jiraSoftwareAri = getJiraSoftwareAri(cloudId);
				const recaptchaToken = await getRecaptchaToken?.('v1_invitations_invite').catch(
					// If token generation fails we want to just continue with the
					// request without a recaptcha token (the lib will fire an event)
					() => undefined,
				);

				const atlOrigin = new OriginTracer({ product: 'jira' });

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const continueUrl = atlOrigin.addToUrl(`${window.location.origin}/browse/${issueKey}`);

				const invitedUsers = await postInviteUsers({
					resources: [jiraSoftwareAri],
					users: [
						{
							email,
						},
					],
					continueUrl,
					recaptchaToken,
				});
				const invitedUser = invitedUsers?.[0];

				return handleInvitedUserResult({
					invitedUser,
					resourceAri: jiraSoftwareAri,
					integrationSource: analyticsSource,
					atlOrigin,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'useInviteUser',
						packageName: 'jiraInviteAndAssign',
						teamName: 'jlove-makkuro',
					},
					error,
					attributes: {
						analyticsSource,
					},
				});

				showFlag({
					id: INVITE_USER_ERROR_FLAG_ID,
					type: 'error',
					title: formatMessage(messages.errorFlagTitle),
					description: formatMessage(messages.errorFlagDescription),
					isAutoDismiss: true,
				});

				return {
					userId: null,
					isInviteSuccessful: false,
				};
			}
		},
		[analyticsSource, cloudId, formatMessage, getRecaptchaToken, handleInvitedUserResult, showFlag],
	);
};
