import { createSelector, createStateHook, type HookStateFunction } from 'react-sweet-state';

import { store } from '../../index';
import {
	type AdjustmentsContextState,
	UIM_CONSENT_CONSENTED,
	UIM_CONSENT_REQUIRED,
	UIM_CONSENT_UNKNOWN,
	type UIMConsentMap,
	type UIMConsentType,
} from '../../types';

const selectOverallConsentStatus = createSelector(
	(state: AdjustmentsContextState) => state.uiModificationsConsentStatus,
	(consentMap: UIMConsentMap) =>
		Object.values(consentMap).includes(UIM_CONSENT_REQUIRED)
			? UIM_CONSENT_REQUIRED
			: UIM_CONSENT_CONSENTED,
);

/*
 * the purpose of this hook is to provide consent status to GIC
 * if any of the apps require consent, return UIM_CONSENT_REQUIRED (fields should be hidden)
 */
export const useUIModificationsConsentStatus: HookStateFunction<UIMConsentType> = createStateHook(
	store,
	{
		selector: selectOverallConsentStatus,
	},
);

export const useUIModificationsConsentStatusForApp: HookStateFunction<
	UIMConsentType,
	{ appId: string }
> = createStateHook(store, {
	selector: (state: AdjustmentsContextState, { appId }: { appId: string }) =>
		state.uiModificationsConsentStatus[appId] || UIM_CONSENT_UNKNOWN,
});

export const useUIModificationsConsentStatusForApps: HookStateFunction<UIMConsentMap> =
	createStateHook(store, {
		selector: (state: AdjustmentsContextState) => state.uiModificationsConsentStatus,
	});
