import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { PropertyMaps, State, GoalsArisProperty } from '../../../types.tsx';
import { safeStringListComparator } from '../../comparators/index.tsx';
import type { FieldMapping, GroupIdMap } from '../types.tsx';
import { defaultMapping } from '../default/index.tsx';

export type Value = string[] | undefined;

const mapValueToEntityValue = (state: State, value: string): string | undefined =>
	state.properties.externalReferenceEntities[value]?.name;

const getGroupIdentitiesForIssue = (goals: GoalsArisProperty, issueId: LocalIssueId) => {
	const values = goals?.[issueId];
	if (!values) {
		return [];
	}
	if (Array.isArray(values)) {
		return values.map((value: string) => ({
			groupIdentity: value,
			value: [value],
		}));
	}
	return [
		{
			groupIdentity: values,
			value: values,
		},
	];
};

export const getGroupIdentitiesSelector = (
	fieldKey: FieldKey,
	issueIdsSelector: (state: State) => LocalIssueId[],
) =>
	createSelector(
		issueIdsSelector,
		(state: State) => state.properties.goals[fieldKey],
		(ids, goals) =>
			ids.reduce(
				(result, issueId) =>
					Object.assign(result, {
						[issueId]: getGroupIdentitiesForIssue(goals, issueId),
					}),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{} as GroupIdMap<Value>,
			),
	);

export const goalsMapping = (field: Field): FieldMapping<Value> => {
	const valueAccessor: FieldMapping<string[]>['valueAccessor'] = (state, props, issueId) =>
		state.properties?.goals[field.key] !== undefined
			? state.properties.goals[field.key][issueId]
			: undefined;
	return {
		...defaultMapping,
		field,
		allowEmptyGroup: true,
		isMultiValueField: true,
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: Value) =>
			set(maps.goals, [field.key, issueId], value),
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: Value) => {
			if (maps.goals[field.key] && isEqual(maps.goals[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				goals: {
					...maps.goals,
					[field.key]: {
						...maps.goals[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (propertyMaps) => propertyMaps,
		modifyImmutableIfMultiValueField: (propertyMaps) => propertyMaps,
		comparator: () => 0,
		comparatorWithMapping(state, props, a, b, direction) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const mappedA = (a || [])
				.map((v) => mapValueToEntityValue(state, v))
				.filter(Boolean) as string[];
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const mappedB = (b || [])
				.map((v) => mapValueToEntityValue(state, v))
				.filter(Boolean) as string[];
			return safeStringListComparator(mappedA, mappedB, direction);
		},
		valueAccessor,
		getAllValues: (state) =>
			state.properties.goals[field.key] ? state.properties.goals[field.key] : {},
		getDependencyValues: (state) => state.properties.externalReferenceEntities,
		getGroupIdentitiesSelector,
		getGroupIdentities: (state, props, issueId) =>
			getGroupIdentitiesForIssue(state.properties.goals[field.key], issueId),
		getFilter: () => undefined,
		getLabel: (groupIdentity, value) => {
			if (Array.isArray(value)) {
				return value[0] || groupIdentity;
			}
			return value || groupIdentity;
		},
	};
};
