import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	// Keep up to date with jira/src/packages/issue/issue-view/src/views/messages.tsx
	titleAssignToMe: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.title-assign-to-me',
		defaultMessage: 'Successfully assigned to you',
		description:
			'Flag title that displays when user assigns themselves to an issue via a keyboard shortcut',
	},
	descriptionAssignToMe: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.description-assign-to-me',
		defaultMessage: "You're now the assignee for {issueKey}.",
		description:
			'Flag description that displays when user assigns themselves to an issue via a keyboard shortcut',
	},
	titleUnassign: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.title-unassign',
		defaultMessage: 'Successfully unassigned issue',
		description: 'Title of the flag displayed when users remove themselves from the assignees',
	},
	descriptionUnassign: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.description-unassign',
		defaultMessage:
			"You've been removed as the assignee from {issueKey}. (To reassign yourself, select 'i' again.)",
		description:
			'Description of the flag displayed when users remove themselves from the assignees by pressing hotkey i',
	},
	assignee: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.assignee',
		defaultMessage: 'Assignee',
		description:
			'Field name for issue view Assignee. Default fieldName for issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.submit-failed-title',
	},
	// Keep up to date with jira/src/packages/issue/fields/platform/private/validation-handler/src/controllers/server-validation-handler/messages.tsx
	submitFailedTitle: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.submit-failed-title',
		defaultMessage: 'We couldn’t update {fieldName}',
		description: 'Flag title shown when an error occurred trying to update Assignee issue field.',
	},
	submitFailedDescription: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.submit-failed-description',
		defaultMessage: 'You can try again in a few minutes, or refresh the page.',
		description:
			'Flag description shown when an error occurred trying to update Assignee issue field.',
	},
	submitFailedTryAgain: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.submit-failed-try-again',
		defaultMessage: 'Try editing the field again',
		description: 'Action button text to allow the user to try again to edit Assignee field.',
	},
	titleUnassignIssueTermRefresh: {
		id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.title-unassign-issue-term-refresh',
		defaultMessage: 'Successfully unassigned',
		description: 'Title of the flag displayed when users remove themselves from the assignees',
	},
});
