import { format, parse, isAfter } from 'date-fns';
import intervals from '@atlassian/jira-common-constants/src/datetimepicker-available-times.tsx';

export function getNext30MinuteInterval(date?: Date) {
	// Get the current date and time
	const now = date ?? new Date();
	// Format current time to 'HH:mm'
	const currentTime = format(now, 'HH:mm');
	// Find the next interval
	for (const interval of intervals) {
		// Parse the interval time to a Date object
		const intervalTime = parse(interval, 'HH:mm', now);
		// Check if the interval is after the current time
		if (isAfter(intervalTime, now) || interval === currentTime) {
			return interval;
		}
	}
	// If no interval is found that is after the current time, return the first interval of the next day
	return intervals[0];
}

export const getFormattedDate = (userLocale: string) => {
	return new Intl.DateTimeFormat(userLocale).format(new Date());
};

export const constructDateTimeFromPartialData = (
	date: string | null,
	time: string | null,
): string => {
	if (!date && !time) {
		return '';
	}

	const referenceDate = new Date();

	let resultDate;

	if (date && !time) {
		resultDate = parse(date, 'yyyy-MM-dd', referenceDate);
		const nextInterval = getNext30MinuteInterval();
		const parsedTime = parse(nextInterval, 'HH:mm', referenceDate);
		resultDate.setHours(parsedTime.getHours(), parsedTime.getMinutes());
	} else if (time && !date) {
		resultDate = parse(time, 'HH:mm', referenceDate);
		resultDate.setFullYear(
			referenceDate.getFullYear(),
			referenceDate.getMonth(),
			referenceDate.getDate(),
		);
	}

	return resultDate ? resultDate.toISOString() : '';
};
