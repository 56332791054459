import React from 'react';
import { AiIssueBreakdownEntryPointContextProvider } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider.tsx';
import { useIsAiEnabledForIssue } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	IssueSmartRequestSummaryEntryPointContextProvider,
	JsmSmartRequestSummaryEntryPointContextProvider,
} from '@atlassian/jira-issue-view-smart-request-summary-entrypoint/src/index.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useSubProduct } from '@atlassian/jira-subproduct/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const MultipleEntrypointsContextProvider = ({ children }: { children: React.ReactNode }) => {
	const subProduct = useSubProduct();

	const SmartRequestSummaryEntryPointContextProvider =
		subProduct !== 'serviceDesk'
			? IssueSmartRequestSummaryEntryPointContextProvider
			: JsmSmartRequestSummaryEntryPointContextProvider;

	if (!fg('jira_ai_fix_improved_button_remount')) {
		// TODO GRAVITYAI-1511 Check if these checks are correct for all AI features
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isAiOptInEnabled = useIsAiEnabledForIssue();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isPremium = useIsPremium();
		const isFeatureEnabled = isPremium && isAiOptInEnabled;

		if (!isFeatureEnabled) {
			return children;
		}
	}

	return (
		<AiIssueBreakdownEntryPointContextProvider>
			<SmartRequestSummaryEntryPointContextProvider>
				{children}
			</SmartRequestSummaryEntryPointContextProvider>
		</AiIssueBreakdownEntryPointContextProvider>
	);
};
