import React from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { SERVICEDESK_CREATE_COMMENT_ACTIONS } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import type { CommentId } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { changeCommentVisibility } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { canUseServiceDeskAgentFeaturesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import {
	isEditingInternalCommentSelector,
	hasPublicCommentsSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import ServiceDeskCommentEditorContainer, {
	type OwnProps as ComponentOwnProps,
	type StateProps,
	type DispatchProps,
	type Props,
} from './view.tsx';

export type OwnProps = ComponentOwnProps;

export const ServiceDeskCommentEditorContainerWithSpotlight = (props: Props) => (
	<SpotlightTarget name={SERVICEDESK_CREATE_COMMENT_ACTIONS}>
		<ServiceDeskCommentEditorContainer {...props} />
	</SpotlightTarget>
);

export default flowWithSafeComponent(
	connect(
		(state: State, ownProps: { commentId: CommentId }): StateProps => ({
			isInternal: isEditingInternalCommentSelector(ownProps.commentId)(state),
			isPublicCommentAllowed: canUseServiceDeskAgentFeaturesSelector(state),
			hasPublicComments: fg('loom_jsm_experiment_controller')
				? hasPublicCommentsSelector(state)
				: false,
		}),
		(dispatch, ownProps): DispatchProps => ({
			onCommentVisibilityChange: (isInternal) => {
				dispatch(changeCommentVisibility(ownProps.commentId, isInternal));
			},
		}),
		(stateProps, dispatchProps, allOwnProps) => {
			// Omit commentId from the props that get passed to the component
			const { commentId, ...ownProps } = allOwnProps;
			return {
				...ownProps,
				...stateProps,
				...dispatchProps,
			};
		},
	),
)(ServiceDeskCommentEditorContainerWithSpotlight);
