/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { IconButton } from '@atlaskit/button/new';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	ASSIGNED_USER_CANNED_COMMENTS_KEY,
	CANNED_COMMENTS_KEY,
	CANNED_COMMENTS_TYPE,
} from '../../../common/constants.tsx';
import messages from '../../../common/messages.tsx';
import { getCannedCommentContent } from '../../../common/utils.tsx';
import { useScrollCarousel } from '../../../controllers/use-scroll-carousel/index.tsx';
import CannedComment from './canned-comment/index.tsx';

export type Props = {
	onClickCannedComment: (value: ADF) => void;
	commentsType?: string;
};

export const CannedCommentList = ({ onClickCannedComment, commentsType }: Props) => {
	const {
		setRef,
		setCanScrollDebounced,
		shouldHideScrollLeftButton,
		shouldHideScrollRightButton,
		scrollToNext,
		scrollToPrevious,
		onFocusCannedComment,
	} = useScrollCarousel();
	const { formatMessage } = useIntl();

	const commentsKey =
		commentsType === CANNED_COMMENTS_TYPE.ASSIGNED_USER
			? ASSIGNED_USER_CANNED_COMMENTS_KEY
			: CANNED_COMMENTS_KEY;

	return (
		<Box xcss={containerStyles}>
			<Box
				xcss={[hoverButtonStyle, leftButtonStyle, shouldHideScrollLeftButton && hiddenButtonStyle]}
			>
				<IconButton
					shape="circle"
					appearance="subtle"
					icon={ChevronLeftIcon}
					isTooltipDisabled
					label={formatMessage(messages.moreOptions)}
					onClick={scrollToPrevious}
					tabIndex={-1}
				/>
			</Box>
			<div
				ref={setRef}
				css={commentsContainerStyle}
				onScroll={setCanScrollDebounced}
				role="group"
				aria-label={formatMessage(messages.buttonGroupLabel)}
			>
				{commentsKey.map((key) => (
					<CannedComment
						commentKey={key.commentKey}
						commentText={getCannedCommentContent(
							formatMessage(messages[key.commentKey]),
							key.emoji,
						)}
						buttonText={formatMessage(messages[key.buttonTextKey])}
						key={key.commentKey}
						onClick={onClickCannedComment}
						onFocus={onFocusCannedComment}
					/>
				))}
			</div>
			<Box
				xcss={[
					hoverButtonStyle,
					rightButtonStyle,
					shouldHideScrollRightButton && hiddenButtonStyle,
				]}
			>
				<IconButton
					shape="circle"
					appearance="subtle"
					icon={ChevronRightIcon}
					isTooltipDisabled
					label={formatMessage(messages.moreOptions)}
					onClick={scrollToNext}
					tabIndex={-1}
				/>
			</Box>
		</Box>
	);
};

export type SmartRepliesCannedCommentListProps = {
	onClickCannedComment: (value: ADF) => void;
	commentsType?: string;
	onFocusCannedComment: (event: React.FocusEvent<HTMLButtonElement, Element>) => void;
};

export const SmartRepliesCannedCommentList = ({
	onClickCannedComment,
	commentsType,
	onFocusCannedComment,
}: SmartRepliesCannedCommentListProps) => {
	const { formatMessage } = useIntl();

	const commentsKey =
		commentsType === CANNED_COMMENTS_TYPE.ASSIGNED_USER
			? ASSIGNED_USER_CANNED_COMMENTS_KEY
			: CANNED_COMMENTS_KEY;

	return (
		<>
			{commentsKey.map((key) => (
				<CannedComment
					commentKey={key.commentKey}
					commentText={getCannedCommentContent(formatMessage(messages[key.commentKey]), key.emoji)}
					buttonText={formatMessage(messages[key.buttonTextKey])}
					key={key.commentKey}
					onClick={onClickCannedComment}
					onFocus={onFocusCannedComment}
				/>
			))}
		</>
	);
};

const containerStyles = xcss({
	display: 'flex',
	position: 'absolute',
	bottom: token('space.150'),
	left: token('space.150'),
	right: token('space.150'),
	alignItems: 'center',
});

const commentsContainerStyle = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: token('space.100'),
	// Sufficient padding needed for outline styles on focus to not be hidden by overflow: hidden
	paddingTop: token('space.075'),
	paddingRight: token('space.075'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.075'),

	// this needs to be 'scroll' rather than 'none' due to a Chrome bug - https://hello.jira.atlassian.cloud/browse/WAR-1542
	overflowX: 'scroll',
	// visually hide the scrollbar rather than disable with overflow: none
	scrollbarWidth: 'none',
	'-ms-overflow-style': 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	'::-webkit-scrollbar': {
		display: 'none',
	},
});

const hoverButtonStyle = xcss({
	zIndex: 'layer',
	alignItems: 'center',
	position: 'absolute',
	// NOTE: before changing this.
	// see jira/src/packages/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx:71
	background: `var(--jira-canned-comments-background-color, ${token('color.background.input')})`,
});

const rightButtonStyle = xcss({
	right: 'space.0',
});

const leftButtonStyle = xcss({
	left: 'space.0',
});

const hiddenButtonStyle = xcss({
	display: 'none',
});
