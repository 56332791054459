import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/brainstorm';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';

import { messages } from './messages';

export const createDraftReply = (issueId?: string): EditorPluginAIConfigItem => ({
	key: CONFIG_ITEM_KEYS.SUGGEST_REPONSE,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	endpoint: `/gateway/api/firebolt/1/agent-response/issues/${issueId}/stream`,
	intentSchemaId: 'brainstorm_intent_schema.json',
	alwaysNoDocAndSelection: true,
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		selection: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	},
	getBackendModel: () => {
		return 'assistance-service';
	},
	statusLozengeType: fg('platform_remove_jsm_beta_tags') ? undefined : 'beta',
});

export const createDraftReplyWithOptions = (
	issueId?: string,
): EditorPluginAIConfigItemWithOptions => ({
	config: createDraftReply(issueId),
	triggers: { empty: { docMinSize: 0 }, selection: false },
});
