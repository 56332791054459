import { createSelector, createStructuredSelector } from 'reselect';
import { getTypedKeys } from '@atlassian/jira-shared-types/src/narrowing.tsx';
import { ViewSectionTypeViews } from '../../views/types.tsx';
import {
	type PolarisQueryValidValues,
	type PolarisQuery,
	POLARIS_QUERY_VALID_VALUES,
	POLARIS_QUERY_ISSUE_VIEW_SIDEBAR,
	type State,
	type PolarisRoute,
	DEFAULT_ISSUE_VIEW_SECTION_SIDEBAR,
	DEFAULT_ISSUE_VIEW_SECTION_FULLSCREEN,
} from '../types.tsx';

export const getPropsSelector = (state: State) => state.containerProps;

const getConfiguredRouteSelector = createSelector(
	(state: State) => state.containerProps?.resource,
	(state: State) => state.containerProps?.section,
	(state: State) => state.routeFromStorage,
	(resource, section, routeFromStorage) => {
		if (section !== undefined && section !== null) {
			return {
				section,
				resource,
			};
		}
		return routeFromStorage;
	},
);

const getDefaultRouteSelector = createSelector(
	(state: State) => state.containerProps?.defaultSection,
	(state: State) => state.containerProps?.defaultResource,
	(section, resource) => ({
		section: section || ViewSectionTypeViews,
		resource,
	}),
);

const getHasSelectedIssueQueryParameter = createSelector(
	(state: State) => state.containerProps?.query.selectedIssue,
	(selectedIssue) =>
		selectedIssue !== undefined && !!POLARIS_QUERY_VALID_VALUES.selectedIssue(selectedIssue),
);

export const getCurrentRouteSelector = createSelector(
	getDefaultRouteSelector,
	getConfiguredRouteSelector,
	getHasSelectedIssueQueryParameter,
	(state: State) => state.containerProps?.allowedSections,
	(
		defaultRoute: PolarisRoute,
		configuredRoute: PolarisRoute | undefined,
		hasSelectedIssueQueryParam: boolean,
		allowedSections: string[] | undefined,
	) => {
		if (
			configuredRoute !== undefined &&
			configuredRoute.section !== undefined &&
			allowedSections?.includes(configuredRoute.section) &&
			!(configuredRoute.section !== ViewSectionTypeViews && hasSelectedIssueQueryParam)
		) {
			return configuredRoute;
		}

		return defaultRoute;
	},
);

export const getCurrentRouteSectionSelector = createSelector(
	getCurrentRouteSelector,
	(currentRoute) => currentRoute.section,
);

const getQueryValidValues = (state: State) => state.containerProps?.queryValidValues;

export const getPolarisQuerySelector = createSelector<
	State,
	PolarisQueryValidValues | undefined,
	{
		[key: string]: string | undefined;
	},
	PolarisQuery
>(
	getQueryValidValues,
	createStructuredSelector<
		State,
		{
			[key: string]: string | undefined;
		}
	>(
		getTypedKeys(POLARIS_QUERY_VALID_VALUES).reduce(
			(result, key) =>
				Object.assign(result, {
					[key]: createSelector(
						(state: State) => state.containerProps?.query[key],
						(queryValue) => {
							if (!queryValue) {
								return undefined;
							}
							const validator = POLARIS_QUERY_VALID_VALUES[key];
							return validator(queryValue) ? queryValue : undefined;
						},
					),
				}),
			{},
		),
	),
	(queryValidValues, values) => {
		const query: PolarisQuery = {};
		if (!queryValidValues) {
			return query;
		}
		return Object.keys(queryValidValues).reduce((result, key) => {
			if (values[key] !== undefined) {
				return Object.assign(result, {
					[key]: values[key],
				});
			}
			return result;
		}, query);
	},
);

export const getIssueViewLayoutQueryParameter = createSelector(
	getPolarisQuerySelector,
	(query) => query.issueViewLayout,
);

export const getIssueViewSectionQueryParameter = createSelector(
	getPolarisQuerySelector,
	(query) => query.issueViewSection,
);

export const getFullscreenQueryParameter = createSelector(
	getPolarisQuerySelector,
	(query) => query.fullscreen === 'true',
);

export const getSelectedIssueQueryParameter = createSelector(
	getPolarisQuerySelector,
	(query) => query.selectedIssue,
);

export const getSidebarQueryParameter = createSelector(
	getPolarisQuerySelector,
	(query) => query.sidebar,
);

export const getRoadmapQueryParameter = createSelector(
	getPolarisQuerySelector,
	(query) => query.roadmap,
);

export const getIssueViewLayout = createSelector(
	getIssueViewLayoutQueryParameter,
	(issueViewLayout) =>
		issueViewLayout === POLARIS_QUERY_ISSUE_VIEW_SIDEBAR ? issueViewLayout : undefined,
);

export const getIsIssueOpenInFullscreen = createSelector(
	getIssueViewLayout,
	getSelectedIssueQueryParameter,
	(issueViewLayout, selectedIssue) => issueViewLayout === undefined && selectedIssue !== undefined,
);

export const getIsIssueOpenInSidebar = createSelector(
	getIssueViewLayout,
	getSelectedIssueQueryParameter,
	(issueViewLayout, selectedIssue) => issueViewLayout !== undefined && selectedIssue !== undefined,
);

export const getIssueViewSection = createSelector(
	getIsIssueOpenInSidebar,
	getIssueViewSectionQueryParameter,
	getSelectedIssueQueryParameter,
	(isIssueOpenInSidebar, issueViewSection, selectedIssue) => {
		if (selectedIssue === undefined) return undefined;
		return isIssueOpenInSidebar
			? issueViewSection ?? DEFAULT_ISSUE_VIEW_SECTION_SIDEBAR
			: issueViewSection ?? DEFAULT_ISSUE_VIEW_SECTION_FULLSCREEN;
	},
);
