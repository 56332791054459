import {
	useIsIssueOfIncidentsPractice,
	useIsIssueOfPostIncidentReviewPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { useHasCurrentUserOpsgenieAccount } from '@atlassian/jira-servicedesk-incident-management-common/src/services/has-opsgenie-account/index.tsx';
import { useServiceDeskContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/index.tsx';
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';
import {
	jiraWithApprovalsOptions,
	jsmIncidentOptions,
	selectedFiltersDefault,
	jsmBaseOptions,
} from '../common/constants.tsx';
import type { IncidentActivityFeedEntryId } from '../common/types.tsx';
import { valueIds, intersect } from '../common/utils.tsx';

//
// Types
// ------------------------------------------------------------------------------------------------
type State = {
	selectedFilters: readonly IncidentActivityFeedEntryId[];
};

type ContextProps = {
	isIssueOfIncidentsPractice: boolean;
	hasOpsgenieAccount?: boolean;
	isTimeLineModalPirIssueOpen: boolean;
	isJiraServiceManagementRequest: boolean;
};

//
// State Management Setup
// ------------------------------------------------------------------------------------------------

const initialState: State = {
	selectedFilters: selectedFiltersDefault,
};

const actions = {
	setSelectedFilters:
		(newSelectedFilters: readonly IncidentActivityFeedEntryId[]): Action<State> =>
		({ setState }) => {
			setState({ selectedFilters: newSelectedFilters });
		},
} as const;

const store = createStore({
	initialState,
	actions,
	name: 'activity-feed-filter-context',
});

//
// Service Logic
// ------------------------------------------------------------------------------------------------

type EligibleFiltersProps = {
	showJSMIncidentFilters: boolean;
	isJiraServiceManagementRequest: boolean;
};

const getEligibleFilters = ({
	showJSMIncidentFilters,
	isJiraServiceManagementRequest,
}: EligibleFiltersProps): readonly IncidentActivityFeedEntryId[] => {
	if (showJSMIncidentFilters) return valueIds(jsmIncidentOptions);
	if (isJiraServiceManagementRequest) return valueIds(jsmBaseOptions);
	return valueIds(jiraWithApprovalsOptions);
};

export const selector = (
	{ selectedFilters }: State,
	props: ContextProps,
): readonly IncidentActivityFeedEntryId[] => {
	const {
		isTimeLineModalPirIssueOpen,
		isIssueOfIncidentsPractice,
		hasOpsgenieAccount,
		isJiraServiceManagementRequest,
	} = props;
	const showJSMIncidentFilters =
		isTimeLineModalPirIssueOpen === true ||
		(isIssueOfIncidentsPractice && hasOpsgenieAccount === true);
	const eligibleFilters = getEligibleFilters({
		showJSMIncidentFilters,
		isJiraServiceManagementRequest,
	});

	return intersect(eligibleFilters, selectedFilters);
};

const useActivityFeedSelectedFiltersInternal = createHook(store, {
	selector,
});

export const useActivityFeedSelectedFilters = (
	isIncidentTimelineModalOpen: boolean | undefined = false,
) => {
	const [{ data: serviceDeskContext }] = useServiceDeskContext();
	const isJiraServiceManagementRequest = !!serviceDeskContext;

	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const [hasOpsgenieAccount] = useHasCurrentUserOpsgenieAccount();
	const isTimeLineModalPirIssueOpen =
		useIsIssueOfPostIncidentReviewPractice() && isIncidentTimelineModalOpen;

	return useActivityFeedSelectedFiltersInternal({
		isIssueOfIncidentsPractice,
		hasOpsgenieAccount,
		isTimeLineModalPirIssueOpen,
		isJiraServiceManagementRequest,
	});
};
