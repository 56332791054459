/**
 * We may eventually need to split the Jira presets out to the various
 * - JSW
 * - JSM
 * - JWM
 * - JPD
 *
 * Variations, however we'll start simple for now.
 */
/**
 * Please reference
 * https://hello.atlassian.net/wiki/spaces/CONF/pages/2714833063/Gen+AI+in+Editor+EAP2+-+Product-specific+requirements
 * Also see: https://hello.atlassian.net/wiki/spaces/EDAI/pages/2731933104/Editor+AI+EAP+Part+2+Dev+Documentation+Prebuilt+Providers+Plugin+Presets
 */

import { isEditorPluginAIConfigItemWithOptions } from '../config-items/config-items';
import { type AIPlugin, EditorPluginAI, NextEditorPluginAI } from '../editor-plugin-ai';
import { createEditorPluginAIProvider } from '../provider/provider';
import type { ActionOverrides, ActionSideEffects, EditorPluginAIProvider } from '../types';

import { brainstormWithOptions } from './config-items/brainstorm/brainstorm';
import {
	changeToneToCasualWithOptions,
	changeToneToEducationalWithOptions,
	changeToneToEmpatheticWithOptions,
	changeToneToNeutralWithOptions,
	changeToneToProfessionalWithOptions,
} from './config-items/change-tone/change-tone';
import { createDraftReplyWithOptions } from './config-items/draft-reply/draft-reply';
import {
	fixSpellingAndGrammarWithOptions,
	improveWritingWithOptions,
} from './config-items/enhance/enhance';
import { freeGenerate } from './config-items/free-generate/free-generate';
import type { GetAdditionalProps } from './config-items/improve-description/improve-description';
import { createImproveDescriptionWithOptions } from './config-items/improve-description/improve-description';
import { makeShorterWithOptions } from './config-items/make-shorter/make-shorter';
import { summarizeWritingWithOptions } from './config-items/summarize-writing/summarize-writing';
import { translationConfigItemWithOptions } from './config-items/translate/translate';
import type { BaseCreateProviderProps, CreatePrebuiltEditorPluginAI } from './types';
import { _getFetchCustomHeaders, deriveProactiveAIConfig } from './utils';

// Jira
/**
 * This is written here as a "wrapper" in order to retain control of
 * the other values passed into the provider, e.g. configItems,
 * etc. - due to the high level of uncertainty, we'll keep this in place until
 * past phase 3.
 *
 * (design ethos: having control/flexibility in the API)
 *
 */

type CreateEditorPluginAIProviderProps = BaseCreateProviderProps & {
	product: 'JSW' | 'JWM' | 'JSM' | 'JPD';
	allowIssueReformatter?: boolean;
	allowSuggestResponse?: boolean;
	getAdditionalProps?: GetAdditionalProps;
	issueId?: string;
	actionSideEffects?: ActionSideEffects;
	actionOverrides?: ActionOverrides;
};

export const createJiraEditorPluginAIProvider = (
	props: CreateEditorPluginAIProviderProps,
): EditorPluginAIProvider => {
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = props?.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	// Suggest response is only enabled for JSM
	const isSuggestResponseEnabled =
		props.allowSuggestResponse && props.issueId !== undefined && props.product === 'JSM';

	return createEditorPluginAIProvider({
		baseGenerate: freeGenerate,
		configItemWithOptions: [
			!!props.allowIssueReformatter &&
				createImproveDescriptionWithOptions({
					getAdditionalProps: props.getAdditionalProps || (() => ({})),
				}),
			!!isSuggestResponseEnabled && createDraftReplyWithOptions(props.issueId),
			summarizeWritingWithOptions,
			improveWritingWithOptions,
			fixSpellingAndGrammarWithOptions,
			!isSuggestResponseEnabled && brainstormWithOptions,
			makeShorterWithOptions,
			changeToneToProfessionalWithOptions,
			changeToneToEmpatheticWithOptions,
			changeToneToCasualWithOptions,
			changeToneToNeutralWithOptions,
			changeToneToEducationalWithOptions,
			...translationConfigItemWithOptions,
		].filter(isEditorPluginAIConfigItemWithOptions),
		disableAISelectionToolbar: true,
		generativeAIApiUrl: props.generativeAIApiUrl,
		product: props.product,
		getFetchCustomHeaders: props?.getFetchCustomHeaders || _getFetchCustomHeaders,
		onExperienceEvent,
		handleFeedbackSubmission: props?.handleFeedbackSubmission,
		AIButtonWrapper: props.AIButtonWrapper,
		proactiveAIToolbarButtonWrapper: props.proactiveAIToolbarButtonWrapper,
		PromptEditor: props.PromptEditor,
		disableInterrogation: props.disableInterrogation ?? false,
		proactiveAIConfig: {
			...deriveProactiveAIConfig(),
			...props.proactiveAIConfig,
		},
		getChannelVariables: props.getChannelVariables,
		actionSideEffects: props.actionSideEffects,
		actionOverrides: props.actionOverrides,
		isRovoEnabled: props.isRovoEnabled ?? true,
		aiLifeCycleDynamicAttributesGetter: props.aiLifeCycleDynamicAttributesGetter,
		auditLogSettings: props.auditLogSettings,
	});
};

/**
 * Intended for use with Jira editors
 *
 */
export const createJiraEditorPluginAI: CreatePrebuiltEditorPluginAI = ({
	editorPluginAIProvider,
	/**
	 * While you don't have a aiGlobalOptIn setup, you can pass in a dummy
	 * object with the status set to 'enabled' and a no-op triggerOptInFlow
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createJiraEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: 'enabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Before setting up the opt-in nag, you may want to refactor to use the
	 * disabled state, rather than the dynamically added plugin strategy.
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createJiraEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: aiOptInStatus ? 'enabled' : 'disabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 */
	aiGlobalOptIn,
}) => {
	const editorPluginAI = new EditorPluginAI({
		editorPluginAIProvider,
		aiGlobalOptIn,
	});

	return {
		editorPluginAI,
		nextEditorPluginAI: NextEditorPluginAI,
	};
};

/**
 * Intended for use with Jira editors (when using the new editor)
 * (Currently only used for the 12-jira-clone-with-pubsub example).
 *
 */
export const PageNextEditorPlugin: AIPlugin = ({
	config: { editorPluginAIProvider, aiGlobalOptIn },
	api,
}): ReturnType<typeof NextEditorPluginAI> => {
	const nextEditorPluginAI = NextEditorPluginAI({
		config: {
			editorPluginAIProvider,
			aiGlobalOptIn: aiGlobalOptIn || {
				status: 'enabled',
				triggerOptInFlow: () => {},
			},
		},
		api,
	});
	return nextEditorPluginAI;
};
