/** @jsx jsx */
import React, { useState, useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import UserPicker, { type Value } from '@atlaskit/user-picker';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SEARCH_DEBOUNCE_TIMEOUT } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { useCreateTeamDialog } from '@atlassian/jira-issue-field-create-team-dialog/src/ui/use-create-team-dialog/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import type { Team } from '@atlassian/jira-issue-shared-types/src/common/types/team-type.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import type { team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef$key as TeamFragment } from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef.graphql';
import type { team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef$key as TeamFragmentNew } from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef.graphql';
import type { team_newTeamSearchQuery as TeamSearchQueryNew } from '@atlassian/jira-relay/src/__generated__/team_newTeamSearchQuery.graphql';
import type { team_teamSearchQuery as TeamSearchQuery } from '@atlassian/jira-relay/src/__generated__/team_teamSearchQuery.graphql';
import teamSearchRefetchQuery, {
	type team_teamSearchRefetchQuery as TeamRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/team_teamSearchRefetchQuery.graphql';
import teamSearchRefetchQueryNew, {
	type team_teamSearchRefetchQueryNew as TeamRefetchQueryNew,
} from '@atlassian/jira-relay/src/__generated__/team_teamSearchRefetchQueryNew.graphql';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { CreateTeamOption } from '../../utils/create-team-option/index.tsx';
import { TeamPickerEmptyState } from '../../utils/team-picker-empty-state/index.tsx';
import { useTeamOptions, useTeamOptionsNew } from '../../utils/use-team-options/index.tsx';
import { NO_TEAM_ID } from './constants.tsx';
import messages from './messages.tsx';
import type {
	TeamEditViewProps,
	TeamEditViewWithFieldOptionsFragmentProps,
	NullableTeamOption,
	TeamEditViewWithFieldOptionsFragmentNewProps,
} from './types.tsx';

const stylesOverrides = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	valueContainer: (base: any) => ({ ...base, display: 'grid' }),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	placeholder: (base: any) => ({
		...base,
		position: undefined,

		top: undefined,

		left: undefined,
		transform: undefined,
	}),
};

const finalStyles = {
	...defaultSelectStyles,
	...stylesOverrides,
};

const stopPropagation = (e: React.FormEvent<HTMLFormElement>) => {
	e.preventDefault();
	e.stopPropagation();
};

export const TeamEditViewWithFieldOptionsFragment = ({
	autoFocus,
	fieldId,
	fieldName = '',
	fieldOptionsFragmentRef,
	value,
	onChange,
	isDisabled = false,
	organisationId,
	sessionId,
	appearance,
	siteId,
	inputId,
	isDropdownMenuFixedAndLayered = false,
	ariaLabel,
	ariaLabelledBy,
}: TeamEditViewWithFieldOptionsFragmentProps) => {
	const { formatMessage } = useIntl();
	const formattedNoValueText = formatMessage(messages.defaultNoValueText);
	const placeholder = formatMessage(messages.placeholder, {
		fieldName,
	});

	const [updatedPlaceholder, setUpdatedPlaceholder] = useState(placeholder);
	const [searchByString, setSearchByString] = useState<string>('');
	const [shouldForceNetworkRequest, setShouldForceNetworkRequest] = useState<boolean>(false);

	const { data: fieldOptionsSearchData, refetch: refetchSuggestions } = usePaginationFragment<
		TeamRefetchQuery,
		TeamFragment
	>(
		graphql`
			fragment team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef on Query
			@refetchable(queryName: "team_teamSearchRefetchQuery")
			@argumentDefinitions(
				id: { type: "ID!" }
				searchBy: { type: "String", defaultValue: "" }
				first: { type: "Int", defaultValue: 1000 }
				after: { type: "String", defaultValue: null }
				organisationId: { type: "ID!" }
				sessionId: { type: "ID!" }
				siteId: { type: "String!" }
			) {
				node(id: $id) @optIn(to: "JiraTeamViewFieldOptions") {
					... on JiraTeamViewField {
						teams(
							searchBy: $searchBy
							first: $first
							after: $after
							organisationId: $organisationId
							sessionId: $sessionId
						) @connection(key: "team_issueFieldTeamEditviewFull_teams") {
							# eslint-disable-next-line @atlassian/relay/unused-fields
							edges {
								node {
									fullTeam(siteId: $siteId) {
										id
										name: displayName
										avatarUrl: smallAvatarImageUrl
										isVerified
									}
								}
							}
						}
					}
				}
			}
		`,
		fieldOptionsFragmentRef,
	);

	const { teams: fieldOptionsData } = fieldOptionsSearchData.node || {};

	const [searchSuspenselessRefetch, isLoading, lastFetchError] = useSuspenselessRefetch<
		TeamRefetchQuery,
		TeamFragment
	>(teamSearchRefetchQuery, refetchSuggestions, {
		/**
		 * On creation of new team, we want to force network request to get the newly created team
		 */
		fetchPolicy: shouldForceNetworkRequest ? 'network-only' : 'store-or-network',
	});

	const [searchDebouncedSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetch,
		SEARCH_DEBOUNCE_TIMEOUT,
	);

	const [searchLeadingEdgeSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetch,
		SEARCH_DEBOUNCE_TIMEOUT,
		{ leading: true },
	);

	const [options, hasTeams, isCreateButtonFocused] = useTeamOptions({
		fieldOptionsData,
		searchByString,
		value,
		formattedNoValueText,
		lastFetchError,
	});

	const onFocus = useCallback(() => {
		searchLeadingEdgeSuspenselessRefetch({
			id: fieldId,
			searchBy: searchByString || '',
			organisationId,
			sessionId,
			siteId,
		});
	}, [
		fieldId,
		searchByString,
		searchLeadingEdgeSuspenselessRefetch,
		sessionId,
		siteId,
		organisationId,
	]);

	const handleOnChange = useCallback(
		(newValue: Value): void => {
			if (Array.isArray(newValue)) return;

			if (onChange) {
				onChange(
					!newValue || newValue.id === NO_TEAM_ID
						? null
						: { avatarUrl: newValue?.avatarUrl, name: newValue.name, id: newValue.id },
				);
				setUpdatedPlaceholder(newValue?.id === NO_TEAM_ID ? formattedNoValueText : placeholder);
			}
		},
		[formattedNoValueText, onChange, placeholder],
	);

	const onCreate = useCallback(
		(team?: NullableTeamOption) => {
			if (team && team?.id !== value?.id) {
				handleOnChange(team);
				setShouldForceNetworkRequest(true);
			}
		},
		[handleOnChange, value?.id],
	);
	const [createTeamDialog, createTeamDialogTrigger] = useCreateTeamDialog({
		onCreate,
	});

	const handleCreateTeam = useCallback(() => {
		createTeamDialogTrigger(searchByString);
	}, [searchByString, createTeamDialogTrigger]);

	const handleOnKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (isCreateButtonFocused && event.key === 'Enter') {
				handleCreateTeam();
			}
		},
		[isCreateButtonFocused, handleCreateTeam],
	);

	const onSearchByStringChangeFunction = useCallback(
		(newSearchByString?: string) => {
			setSearchByString(newSearchByString || '');
			searchDebouncedSuspenselessRefetch({
				id: fieldId,
				searchBy: newSearchByString || '',
				organisationId,
				sessionId,
				siteId,
			});
		},
		[fieldId, searchDebouncedSuspenselessRefetch, organisationId, sessionId, siteId],
	);

	const menuListStyle = useMemo(
		() =>
			!hasTeams
				? {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						menuList: (base: any) => ({
							...base,
							maxHeight: '350px',
						}),
					}
				: {},
		[hasTeams],
	);

	const noOptionsMessage = useMemo(
		() => (!hasTeams ? () => <TeamPickerEmptyState onActionClick={handleCreateTeam} /> : undefined),
		[hasTeams, handleCreateTeam],
	);

	const footer = useMemo(
		() =>
			hasTeams && <CreateTeamOption isFocused={isCreateButtonFocused} onClick={handleCreateTeam} />,
		[hasTeams, handleCreateTeam, isCreateButtonFocused],
	);

	return (
		<>
			<UserPicker
				width="100%"
				autoFocus={autoFocus}
				appearance={appearance}
				fieldId={fieldId}
				value={value}
				options={options}
				isLoading={isLoading}
				isMulti={false}
				isDisabled={isDisabled}
				onChange={handleOnChange}
				onKeyDown={handleOnKeyDown}
				onInputChange={onSearchByStringChangeFunction}
				placeholder={updatedPlaceholder}
				menuPosition={isDropdownMenuFixedAndLayered ? 'fixed' : 'absolute'}
				isClearable
				styles={{ ...finalStyles, ...menuListStyle }}
				noOptionsMessage={noOptionsMessage}
				footer={footer}
				onFocus={onFocus}
				inputId={inputId}
				aria-label={ariaLabel || fieldName}
				aria-labelledby={ariaLabelledBy}
			/>
			<ErrorBoundary id="issue-field-team-editview-full-create-dialog">
				{/* Preventing the parent form from submitting */}
				<form
					tabIndex={-1}
					css={formWithoutMarginStyles}
					name="its-team-field-create-team-dialog-form"
					onSubmit={stopPropagation}
				>
					{createTeamDialog}
				</form>
			</ErrorBoundary>
		</>
	);
};

// This teams options component mimics SmartUserPicker from atlaskit for TeamType but has integration with relay
export const TeamEditViewWithFieldOptionsFragmentNew = ({
	autoFocus,
	fieldId,
	fieldName = '',
	fieldOptionsFragmentRef,
	value,
	onChange,
	isDisabled = false,
	organisationId,
	sessionId,
	appearance,
	siteId,
	inputId,
	isDropdownMenuFixedAndLayered = false,
	menuPortalTarget,
	ariaLabel,
	ariaLabelledBy,
}: TeamEditViewWithFieldOptionsFragmentNewProps) => {
	const { formatMessage } = useIntl();
	const formattedNoValueText = formatMessage(messages.defaultNoValueText);
	const placeholder = formatMessage(messages.placeholder, {
		fieldName,
	});

	const [updatedPlaceholder, setUpdatedPlaceholder] = useState(placeholder);
	const [searchByString, setSearchByString] = useState<string>('');
	const [shouldForceNetworkRequest, setShouldForceNetworkRequest] = useState<boolean>(false);

	const { data: fieldOptionsSearchData, refetch: refetchSuggestions } = usePaginationFragment<
		TeamRefetchQueryNew,
		TeamFragmentNew
	>(
		graphql`
			fragment team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef on Query
			@refetchable(queryName: "team_teamSearchRefetchQueryNew")
			@argumentDefinitions(
				id: { type: "ID!" }
				searchBy: { type: "String", defaultValue: "" }
				first: { type: "Int", defaultValue: 1000 }
				after: { type: "String", defaultValue: null }
				organisationId: { type: "ID!" }
				sessionId: { type: "ID!" }
				siteId: { type: "String!" }
			) {
				node(id: $id) @optIn(to: "JiraTeamViewFieldOptions") {
					... on JiraTeamViewField {
						teams(
							searchBy: $searchBy
							first: $first
							after: $after
							organisationId: $organisationId
							sessionId: $sessionId
						) @connection(key: "team_issueFieldTeamEditviewFullNew_teams") {
							# eslint-disable-next-line @atlassian/relay/unused-fields
							edges {
								node {
									jiraSuppliedId
									jiraMemberCount
									jiraIncludesYou
									jiraSuppliedIsVerified
									fullTeam(siteId: $siteId) {
										id
										name: displayName
										avatarUrl: smallAvatarImageUrl
										description
										isVerified
									}
								}
							}
						}
					}
				}
			}
		`,
		fieldOptionsFragmentRef,
	);

	const { teams: fieldOptionsData } = fieldOptionsSearchData.node || {};

	const [searchSuspenselessRefetch, isLoading, lastFetchError] = useSuspenselessRefetch<
		TeamRefetchQueryNew,
		TeamFragmentNew
	>(teamSearchRefetchQueryNew, refetchSuggestions, {
		/**
		 * On creation of new team, we want to force network request to get the newly created team
		 */
		fetchPolicy: shouldForceNetworkRequest ? 'network-only' : 'store-or-network',
	});

	const [searchDebouncedSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetch,
		SEARCH_DEBOUNCE_TIMEOUT,
	);

	const [searchLeadingEdgeSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetch,
		SEARCH_DEBOUNCE_TIMEOUT,
		{ leading: true },
	);

	const [options, hasTeams, isCreateButtonFocused] = useTeamOptionsNew({
		fieldOptionsData,
		searchByString,
		value,
		formattedNoValueText,
		lastFetchError,
	});

	const onFocus = useCallback(() => {
		searchLeadingEdgeSuspenselessRefetch({
			id: fieldId,
			searchBy: searchByString || '',
			organisationId,
			sessionId,
			siteId,
		});
	}, [
		fieldId,
		searchByString,
		searchLeadingEdgeSuspenselessRefetch,
		sessionId,
		siteId,
		organisationId,
	]);

	const handleOnChange = useCallback(
		(newValue: Value): void => {
			if (Array.isArray(newValue)) return;

			if (onChange) {
				onChange(
					!newValue || newValue.id === NO_TEAM_ID
						? null
						: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(newValue as Team),
				);
				setUpdatedPlaceholder(newValue?.id === NO_TEAM_ID ? formattedNoValueText : placeholder);
			}
		},
		[formattedNoValueText, onChange, placeholder],
	);

	const onCreate = useCallback(
		(team?: NullableTeamOption) => {
			if (team && team?.id !== value?.id) {
				handleOnChange(team);
				setShouldForceNetworkRequest(true);
			}
		},
		[handleOnChange, value?.id],
	);
	const [createTeamDialog, createTeamDialogTrigger] = useCreateTeamDialog({
		onCreate,
	});

	const handleCreateTeam = useCallback(() => {
		createTeamDialogTrigger(searchByString);
	}, [searchByString, createTeamDialogTrigger]);

	const handleOnKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (isCreateButtonFocused && event.key === 'Enter') {
				handleCreateTeam();
			}
		},
		[isCreateButtonFocused, handleCreateTeam],
	);

	const onSearchByStringChangeFunction = useCallback(
		(newSearchByString?: string) => {
			setSearchByString(newSearchByString || '');
			searchDebouncedSuspenselessRefetch({
				id: fieldId,
				searchBy: newSearchByString || '',
				organisationId,
				sessionId,
				siteId,
			});
		},
		[fieldId, searchDebouncedSuspenselessRefetch, organisationId, sessionId, siteId],
	);

	const menuListStyle = useMemo(
		() =>
			!hasTeams
				? {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						menuList: (base: any) => ({
							...base,
							maxHeight: '350px',
						}),
					}
				: {},
		[hasTeams],
	);

	const noOptionsMessage = useMemo(
		() => (!hasTeams ? () => <TeamPickerEmptyState onActionClick={handleCreateTeam} /> : undefined),
		[hasTeams, handleCreateTeam],
	);

	const footer = useMemo(
		() =>
			hasTeams && <CreateTeamOption isFocused={isCreateButtonFocused} onClick={handleCreateTeam} />,
		[hasTeams, handleCreateTeam, isCreateButtonFocused],
	);

	return (
		<>
			<UserPicker
				width="100%"
				autoFocus={autoFocus}
				appearance={appearance}
				fieldId={fieldId}
				value={value}
				options={options}
				isLoading={isLoading}
				isMulti={false}
				isDisabled={isDisabled}
				onChange={handleOnChange}
				onKeyDown={handleOnKeyDown}
				onInputChange={onSearchByStringChangeFunction}
				placeholder={updatedPlaceholder}
				menuPosition={isDropdownMenuFixedAndLayered ? 'fixed' : 'absolute'}
				menuPortalTarget={menuPortalTarget}
				isClearable
				styles={{ ...finalStyles, ...menuListStyle }}
				noOptionsMessage={noOptionsMessage}
				footer={footer}
				onFocus={onFocus}
				inputId={inputId}
				aria-label={ariaLabel || fieldName}
				aria-labelledby={ariaLabelledBy}
			/>
			<ErrorBoundary id="issue-field-team-editview-full-create-dialog">
				{/* Preventing the parent form from submitting */}
				<form
					tabIndex={-1}
					css={formWithoutMarginStyles}
					name="its-team-field-create-team-dialog-form"
					onSubmit={stopPropagation}
				>
					{createTeamDialog}
				</form>
			</ErrorBoundary>
		</>
	);
};

export const TeamEditView = (props: TeamEditViewProps) => {
	const { data: organisationId = '' } = useOrgId();
	const { cloudId: siteId } = useTenantContext();

	const suggestionsData = useLazyLoadQuery<TeamSearchQuery>(
		graphql`
			query team_teamSearchQuery(
				$id: ID!
				$sessionId: ID!
				$organisationId: ID!
				$siteId: String!
			) {
				...team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef
					@arguments(
						id: $id
						sessionId: $sessionId
						organisationId: $organisationId
						siteId: $siteId
					)
			}
		`,
		{
			id: props.fieldId,
			organisationId,
			sessionId: props.sessionId,
			siteId,
		},
		{ fetchPolicy: 'store-only' },
	);

	return (
		<TeamEditViewWithFieldOptionsFragment
			{...props}
			siteId={siteId}
			organisationId={organisationId}
			fieldOptionsFragmentRef={suggestionsData}
		/>
	);
};

export const TeamEditViewNew = (props: TeamEditViewProps) => {
	const { data: organisationId = '' } = useOrgId();
	const { cloudId: siteId } = useTenantContext();

	const suggestionsData = useLazyLoadQuery<TeamSearchQueryNew>(
		graphql`
			query team_newTeamSearchQuery(
				$id: ID!
				$sessionId: ID!
				$organisationId: ID!
				$siteId: String!
			) {
				...team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef
					@arguments(
						id: $id
						sessionId: $sessionId
						organisationId: $organisationId
						siteId: $siteId
					)
			}
		`,
		{
			id: props.fieldId,
			organisationId,
			sessionId: props.sessionId,
			siteId,
		},
		{ fetchPolicy: 'store-only' },
	);

	return (
		<TeamEditViewWithFieldOptionsFragmentNew
			{...props}
			siteId={siteId}
			organisationId={organisationId}
			fieldOptionsFragmentRef={suggestionsData}
		/>
	);
};

const formWithoutMarginStyles = css({
	margin: 0,
});
