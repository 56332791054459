/**
 * @generated SignedSource<<0f24a7ab16e796acb58c9935994ff075>>
 * @relayHash 536439f426bbcc1a1aac60b9266edf30
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID beb849917120f6c69dc7032718043cf93557e869b57e3855bb62bfba42430856

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type JiraCmdbAttributeType = "BITBUCKET_REPO" | "CONFLUENCE" | "DEFAULT" | "GROUP" | "OPSGENIE_TEAM" | "PROJECT" | "REFERENCED_OBJECT" | "STATUS" | "USER" | "VERSION" | "%future added value";
export type JiraGoalStatus = "ARCHIVED" | "AT_RISK" | "CANCELLED" | "COMPLETED" | "DONE" | "OFF_TRACK" | "ON_TRACK" | "PAUSED" | "PENDING" | "%future added value";
export type JiraIssueLinkDirection = "INWARD" | "OUTWARD" | "%future added value";
export type JiraIssueViewActivityFeedSortOrder = "NEWEST_FIRST" | "OLDEST_FIRST" | "%future added value";
export type JiraIssueViewAttachmentPanelViewMode = "LIST_VIEW" | "STRIP_VIEW" | "%future added value";
export type JiraIssueViewTimestampDisplayMode = "ABSOLUTE" | "RELATIVE" | "%future added value";
export type JiraProjectStatus = "ACTIVE" | "ARCHIVED" | "DELETED" | "%future added value";
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type JiraServiceManagementApprovalDecisionResponseType = "approved" | "declined" | "pending" | "%future added value";
export type JiraServiceManagementApprovalState = "INSUFFICIENT_APPROVERS" | "OK" | "%future added value";
export type JiraServiceManagementCommentVisibility = "INTERNAL" | "VISIBLE_TO_HELPSEEKER" | "%future added value";
export type JiraServiceManagementMajorIncident = "MAJOR_INCIDENT" | "%future added value";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type JiraStatusCategoryColor = "BLUE_GRAY" | "BROWN" | "GREEN" | "MEDIUM_GRAY" | "WARM_RED" | "YELLOW" | "%future added value";
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type mainIssueAggQuery$variables = {
  amountOfColumns: number;
  chinEmbedQueryToAgg: boolean;
  cloudId: string;
  commentTargetId?: string | null | undefined;
  customFieldConfigurationFlag: boolean;
  dateFieldsMessageEnabled: boolean;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsContext?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  forgeInMainIssueAggFlag: boolean;
  forgeTypes: ReadonlyArray<string>;
  giraAGGMigrationCommentsFlag: boolean;
  includeHiddenForge: boolean;
  includeScopesForge: boolean;
  includeUserAccessForge: boolean;
  isCompassComponentsEnabled: boolean;
  isJPDDeliveryLinkTypeFilteringEnabled: boolean;
  isJPDPanelEnabled: boolean;
  isPinFieldConfigurabilityEnabled: boolean;
  issueKey: string;
  issueSearchInput: JiraIssueSearchInput;
  issueViewDesignsPanelFlag: boolean;
  issueViewMoveJiraQueryIsAiEnabledProjectField: boolean;
  issueViewRelayAssigneeFieldFlag: boolean;
  issueViewRelayAttachmentsFlag: boolean;
  issueViewRelayColorFieldFlag: boolean;
  issueViewRelayConfigurationUrlFlag: boolean;
  issueViewRelayDateFieldFlag: boolean;
  issueViewRelayDateTimeFieldFlag: boolean;
  issueViewRelayHeaders: boolean;
  issueViewRelayMetadataFieldFlag: boolean;
  issueViewRelayNumberFieldFlag: boolean;
  issueViewRelayParentFieldFlag: boolean;
  issueViewRelayPeopleAndApproversFieldFlag: boolean;
  issueViewRelayPriorityFieldFlag: boolean;
  issueViewRelayProjectFieldFlag: boolean;
  issueViewRelaySingleLineTextFieldFlag: boolean;
  issueViewRelaySprintFieldFlag: boolean;
  issueViewRelaySummaryFieldFlag: boolean;
  issueViewRelayTimeTrackingFieldFlag: boolean;
  issueViewRelayUrlFieldFlag: boolean;
  issueViewRemoveCheckboxSelectFieldOptionsFlag: boolean;
  issueViewRemoveComponentsFieldFlag: boolean;
  issueViewRemoveRadioSelectFieldOptionsFlag: boolean;
  issueViewRemoveSingleVersionFieldOptionsFlag: boolean;
  issueViewSmartRepliesSettingsFlag: boolean;
  issueViewStopFetchingJiraQueryIsAiEnabledForIssue: boolean;
  issueViewStorageLimitsBannerFlag: boolean;
  jcsIssueLayoutEapFlag: boolean;
  jiraCommentsAGGPagination: boolean;
  jiraThreadedCommentsEnabled: boolean;
  jsmSentimentCustomFieldFlag: boolean;
  namespace?: string | null | undefined;
  offeringKey: string;
  productKey: string;
  projectKey: string;
  shouldQueryFieldSetsById: boolean;
  siteAri: string;
  viewId?: string | null | undefined;
};
export type mainIssueAggQuery$data = {
  readonly jira: {
    readonly childIssuesLimit: AGG$Long | null | undefined;
    readonly epicLinkFieldKey: string | null | undefined;
    readonly globalTimeTrackingSettings: {
      readonly defaultFormat: JiraTimeFormat | null | undefined;
      readonly defaultUnit: JiraTimeUnit | null | undefined;
      readonly isJiraConfiguredTimeTrackingEnabled: boolean | null | undefined;
      readonly workingDaysPerWeek: number | null | undefined;
      readonly workingHoursPerDay: number | null | undefined;
    } | null | undefined;
    readonly isAiEnabledForIssue?: boolean | null | undefined;
    readonly isSubtasksEnabled: boolean | null | undefined;
    readonly issueByKey: {
      readonly attachments: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "JiraPlatformAttachment";
            readonly attachmentId: string;
            readonly author: {
              readonly accountId: string;
            } | null | undefined;
            readonly created: AGG$DateTime;
            readonly fileName: string | null | undefined;
            readonly fileSize: AGG$Long | null | undefined;
            readonly hasRestrictedParent: boolean | null | undefined;
            readonly mediaApiFileId: string | null | undefined;
            readonly mimeType: string | null | undefined;
            readonly parentId: string | null | undefined;
            readonly parentName: string | null | undefined;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly childIssues: {
        readonly __typename: "JiraChildIssuesExceedingLimit";
        readonly search: string | null | undefined;
      } | {
        readonly __typename: "JiraChildIssuesWithinLimit";
        readonly issues: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fieldsById: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraDateTimePickerField";
                    readonly dateTime: AGG$DateTime | null | undefined;
                    readonly fieldId: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraIssueTypeField";
                    readonly fieldId: string;
                    readonly issueType: {
                      readonly avatar: {
                        readonly medium: string;
                      } | null | undefined;
                      readonly hierarchy: {
                        readonly level: number;
                      };
                      readonly issueTypeId: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraPriorityField";
                    readonly fieldId: string;
                    readonly priority: {
                      readonly iconUrl: AGG$URL | null | undefined;
                      readonly name: string | null | undefined;
                      readonly priorityId: string;
                      readonly sequence: number | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleLineTextField";
                    readonly fieldId: string;
                    readonly text: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleSelectUserPickerField";
                    readonly fieldId: string;
                    readonly type: string;
                    readonly user: {
                      readonly __typename: string;
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly name: string;
                      readonly picture: AGG$URL;
                    } | null | undefined;
                  } | {
                    readonly __typename: "JiraStatusField";
                    readonly fieldId: string;
                    readonly status: {
                      readonly name: string;
                      readonly statusCategory: {
                        readonly statusCategoryId: string;
                      };
                      readonly statusId: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraTimeTrackingField";
                    readonly fieldId: string;
                    readonly originalEstimate: {
                      readonly timeInSeconds: AGG$Long | null | undefined;
                    } | null | undefined;
                    readonly remainingEstimate: {
                      readonly timeInSeconds: AGG$Long | null | undefined;
                    } | null | undefined;
                    readonly timeSpent: {
                      readonly timeInSeconds: AGG$Long | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly isResolved: boolean | null | undefined;
              readonly issueId: string;
              readonly key: string;
              readonly storyPointEstimateField: {
                readonly __typename: "JiraNumberField";
                readonly fieldId: string;
                readonly isStoryPointField: boolean | null | undefined;
                readonly number: number | null | undefined;
                readonly type: string;
              } | null | undefined;
              readonly storyPointsField: {
                readonly __typename: "JiraNumberField";
                readonly fieldId: string;
                readonly isStoryPointField: boolean | null | undefined;
                readonly number: number | null | undefined;
                readonly type: string;
              } | null | undefined;
              readonly webUrl: AGG$URL | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
      readonly comments?: {
        readonly edges: ReadonlyArray<{
          readonly cursor?: string;
          readonly node: {
            readonly __typename: string;
            readonly author: {
              readonly accountId: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
            readonly authorCanSeeRequest?: boolean | null | undefined;
            readonly childComments?: {
              readonly edges: ReadonlyArray<{
                readonly cursor: string;
                readonly node: {
                  readonly __typename: string;
                  readonly author: {
                    readonly accountId: string;
                    readonly name: string;
                    readonly picture: AGG$URL;
                  } | null | undefined;
                  readonly commentId: string;
                  readonly created: AGG$DateTime;
                  readonly permissionLevel: {
                    readonly group: {
                      readonly groupId: string;
                      readonly id: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly role: {
                      readonly id: string;
                      readonly name: string | null | undefined;
                    } | null | undefined;
                  } | null | undefined;
                  readonly richText: {
                    readonly adfValue: {
                      readonly json: AGG$JSON | null | undefined;
                    } | null | undefined;
                  } | null | undefined;
                  readonly updateAuthor: {
                    readonly accountId: string;
                    readonly name: string;
                    readonly picture: AGG$URL;
                  } | null | undefined;
                  readonly updated: AGG$DateTime | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
              readonly pageInfo: {
                readonly endCursor: string | null | undefined;
                readonly hasNextPage: boolean;
                readonly hasPreviousPage: boolean;
                readonly startCursor: string | null | undefined;
              };
            } | null | undefined;
            readonly commentId: string;
            readonly created: AGG$DateTime;
            readonly eventOccurredAt?: AGG$DateTime | null | undefined;
            readonly jsdIncidentActivityViewHidden?: boolean | null | undefined;
            readonly permissionLevel: {
              readonly group: {
                readonly groupId: string;
                readonly id: string;
                readonly name: string;
              } | null | undefined;
              readonly role: {
                readonly id: string;
                readonly name: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly richText: {
              readonly adfValue: {
                readonly json: AGG$JSON | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly updateAuthor: {
              readonly accountId: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
            readonly updated: AGG$DateTime | null | undefined;
            readonly visibility?: JiraServiceManagementCommentVisibility | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly pageInfo?: {
          readonly endCursor: string | null | undefined;
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
          readonly startCursor: string | null | undefined;
        };
      } | null | undefined;
      readonly errorRetrievingData: boolean;
      readonly fields: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "JiraAffectedServicesField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedAffectedServicesConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly name: string | null | undefined;
                  readonly serviceId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraAssetField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedAssetsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly appKey: string | null | undefined;
                  readonly originId: string | null | undefined;
                  readonly serializedOrigin: string | null | undefined;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraBooleanField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly value: boolean | null | undefined;
          } | {
            readonly __typename: "JiraCMDBField";
            readonly cmdbFieldConfig: {
              readonly attributesDisplayedOnIssue: {
                readonly edges: ReadonlyArray<{
                  readonly node: string | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly attributesIncludedInAutoCompleteSearch: {
                readonly edges: ReadonlyArray<{
                  readonly node: string | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly multiple: boolean | null | undefined;
              readonly objectSchemaId: string;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly isInsightAvailable: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedCmdbObjectsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly attributes: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly objectAttributeValues: {
                          readonly edges: ReadonlyArray<{
                            readonly node: {
                              readonly bitbucketRepo: {
                                readonly avatarUrl: AGG$URL | null | undefined;
                                readonly name: string | null | undefined;
                                readonly url: AGG$URL | null | undefined;
                                readonly uuid: string | null | undefined;
                              } | null | undefined;
                              readonly displayValue: string | null | undefined;
                              readonly group: {
                                readonly name: string;
                              } | null | undefined;
                              readonly opsgenieTeam: {
                                readonly id: string;
                                readonly name: string | null | undefined;
                                readonly url: string | null | undefined;
                              } | null | undefined;
                              readonly project: {
                                readonly avatar: {
                                  readonly large: string | null | undefined;
                                } | null | undefined;
                                readonly id: string;
                                readonly key: string;
                                readonly name: string;
                              } | null | undefined;
                              readonly referencedObject: {
                                readonly avatar: {
                                  readonly mediaClientConfig: {
                                    readonly clientId: string | null | undefined;
                                    readonly fileId: string | null | undefined;
                                    readonly issuer: string | null | undefined;
                                    readonly mediaBaseUrl: AGG$URL | null | undefined;
                                    readonly mediaJwtToken: string | null | undefined;
                                  } | null | undefined;
                                  readonly url48: string | null | undefined;
                                } | null | undefined;
                                readonly label: string | null | undefined;
                                readonly objectGlobalId: string | null | undefined;
                                readonly objectId: string | null | undefined;
                                readonly objectKey: string | null | undefined;
                                readonly workspaceId: string | null | undefined;
                              } | null | undefined;
                              readonly searchValue: string | null | undefined;
                              readonly status: {
                                readonly category: number | null | undefined;
                                readonly id: string | null | undefined;
                                readonly name: string | null | undefined;
                              } | null | undefined;
                              readonly user: {
                                readonly accountId: string;
                                readonly name: string;
                                readonly picture: AGG$URL;
                              } | null | undefined;
                              readonly value: string | null | undefined;
                            } | null | undefined;
                          } | null | undefined> | null | undefined;
                        } | null | undefined;
                        readonly objectTypeAttribute: {
                          readonly defaultType: {
                            readonly id: string | null | undefined;
                            readonly name: string | null | undefined;
                          } | null | undefined;
                          readonly label: boolean | null | undefined;
                          readonly name: string | null | undefined;
                          readonly type: JiraCmdbAttributeType | null | undefined;
                        };
                        readonly objectTypeAttributeId: string | null | undefined;
                      } | null | undefined;
                    } | null | undefined> | null | undefined;
                  } | null | undefined;
                  readonly avatar: {
                    readonly mediaClientConfig: {
                      readonly clientId: string | null | undefined;
                      readonly fileId: string | null | undefined;
                      readonly issuer: string | null | undefined;
                      readonly mediaBaseUrl: AGG$URL | null | undefined;
                      readonly mediaJwtToken: string | null | undefined;
                    } | null | undefined;
                    readonly url48: string;
                  } | null | undefined;
                  readonly label: string | null | undefined;
                  readonly objectGlobalId: string | null | undefined;
                  readonly objectId: string | null | undefined;
                  readonly objectKey: string | null | undefined;
                  readonly objectType: {
                    readonly icon: {
                      readonly id: string;
                      readonly name: string | null | undefined;
                      readonly url16: string | null | undefined;
                      readonly url48: string | null | undefined;
                    };
                    readonly name: string | null | undefined;
                    readonly objectSchemaId: string | null | undefined;
                    readonly objectTypeId: string;
                  };
                  readonly webUrl: string | null | undefined;
                  readonly workspaceId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
            readonly wasInsightRequestSuccessful: boolean | null | undefined;
          } | {
            readonly __typename: "JiraCascadingSelectField";
            readonly cascadingOption: {
              readonly childOptionValue: {
                readonly id: string;
                readonly isDisabled: boolean | null | undefined;
                readonly optionId: string;
                readonly value: string | null | undefined;
              } | null | undefined;
              readonly parentOptionValue: {
                readonly id: string;
                readonly isDisabled: boolean | null | undefined;
                readonly optionId: string;
                readonly value: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraCheckboxesField";
            readonly checkboxesFieldOptions?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraColorField";
            readonly color: {
              readonly colorKey: string | null | undefined;
              readonly id: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraComponentsField";
            readonly components?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly ari?: string | null | undefined;
                  readonly componentId: string;
                  readonly description: string | null | undefined;
                  readonly id: string;
                  readonly metadata?: AGG$JSON | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedComponentsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly ari?: string | null | undefined;
                  readonly componentId: string;
                  readonly description: string | null | undefined;
                  readonly id: string;
                  readonly metadata?: AGG$JSON | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectDateTimeField";
            readonly dateTime: AGG$DateTime | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectMultipleSelectField";
            readonly connectMultiSelectFieldOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectNumberField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly number: number | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectRichTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly richText: {
              readonly adfValue: {
                readonly json: AGG$JSON | null | undefined;
              } | null | undefined;
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectSingleSelectField";
            readonly connectSingleSelectFieldOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly fieldOption: {
              readonly id: string;
              readonly isDisabled: boolean | null | undefined;
              readonly optionId: string;
              readonly value: string | null | undefined;
            } | null | undefined;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly text: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraCustomerServiceOrganizationField";
            readonly __id: string;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedOrganization: {
              readonly organizationId: string | null | undefined;
              readonly organizationName: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraDataClassificationField";
            readonly classificationLevel: {
              readonly color: {
                readonly colorKey: string | null | undefined;
              } | null | undefined;
              readonly guidelines: string | null | undefined;
              readonly name: string | null | undefined;
            } | null | undefined;
            readonly fieldConfig: {
              readonly isEditable: boolean | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"editViewPopup_issueClassificationBadge_ClassificationEditViewPopup">;
          } | {
            readonly __typename: "JiraDatePickerField";
            readonly date: AGG$Date | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraDateTimePickerField";
            readonly dateTime: AGG$DateTime | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraEpicLinkField";
            readonly description: string | null | undefined;
            readonly epic: {
              readonly color: string | null | undefined;
              readonly done: boolean | null | undefined;
              readonly id: string;
              readonly issueId: string;
              readonly key: string | null | undefined;
              readonly name: string | null | undefined;
              readonly summary: string | null | undefined;
            } | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraFlagField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly flag: {
              readonly isFlagged: boolean | null | undefined;
            } | null | undefined;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeDateField";
            readonly date: AGG$Date | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeDatetimeField";
            readonly dateTime: AGG$DateTime | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeGroupField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly selectedGroup: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeGroupsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly selectedGroupsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly groupId: string;
                  readonly id: string;
                  readonly name: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeNumberField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly number: number | null | undefined;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeObjectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly object: string | null | undefined;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeStringField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly text: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeStringsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly selectedLabelsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly labelId: string | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeUserField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
            readonly user: {
              readonly __typename: string;
              readonly accountId: string;
              readonly accountStatus: AccountStatus;
              readonly email?: string | null | undefined;
              readonly locale?: string | null | undefined;
              readonly name: string;
              readonly picture: AGG$URL;
              readonly zoneinfo?: string | null | undefined;
            } | null | undefined;
          } | {
            readonly __typename: "JiraForgeUsersField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraGoalsField";
            readonly __typename: "JiraGoalsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedGoals: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly status: JiraGoalStatus | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
              readonly totalCount: number | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraIssueRestrictionField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedRolesConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly roleId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraIssueTypeField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly issueType: {
              readonly avatar: {
                readonly large: string | null | undefined;
                readonly medium: string;
                readonly small: string | null | undefined;
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly description: string | null | undefined;
              readonly hierarchy: {
                readonly level: number;
                readonly name: string;
              };
              readonly id: string;
              readonly issueTypeId: string;
              readonly name: string;
            } | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraLabelsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedLabelsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly labelId: string | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleGroupPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedGroupsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly groupId: string;
                  readonly name: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleSelectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleSelectUserPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleVersionPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedVersionsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly description: string | null | undefined;
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly status: JiraVersionStatus | null | undefined;
                  readonly versionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraNumberField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly isStoryPointField: boolean | null | undefined;
            readonly name: string;
            readonly number: number | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraOriginalTimeEstimateField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isEditable: boolean | null | undefined;
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly originalEstimate: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraParentIssueField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly parentIssue: {
              readonly fieldsById: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraIssueTypeField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly issueType: {
                      readonly avatar: {
                        readonly large: string | null | undefined;
                        readonly medium: string;
                        readonly small: string | null | undefined;
                        readonly xsmall: string | null | undefined;
                      } | null | undefined;
                      readonly description: string | null | undefined;
                      readonly hierarchy: {
                        readonly level: number;
                        readonly name: string;
                      };
                      readonly id: string;
                      readonly issueTypeId: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraProjectField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly name: string;
                    readonly project: {
                      readonly avatar: {
                        readonly large: string;
                        readonly medium: string | null | undefined;
                      };
                      readonly canSetIssueRestriction: boolean | null | undefined;
                      readonly key: string;
                      readonly name: string;
                      readonly navigationMetadata: {
                        readonly boardId?: string;
                      } | null | undefined;
                      readonly projectId: string;
                      readonly projectStyle: JiraProjectStyle | null | undefined;
                      readonly projectType: JiraProjectType | null | undefined;
                      readonly similarIssues: {
                        readonly featureEnabled: boolean;
                      } | null | undefined;
                      readonly status: JiraProjectStatus | null | undefined;
                    } | null | undefined;
                    readonly searchUrl: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleLineTextField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly name: string;
                    readonly text: string | null | undefined;
                    readonly type: string;
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly issueColorField: {
                readonly color: {
                  readonly colorKey: string | null | undefined;
                } | null | undefined;
              } | null | undefined;
              readonly issueId: string;
              readonly key: string;
              readonly webUrl: AGG$URL | null | undefined;
            } | null | undefined;
            readonly parentVisibility: {
              readonly canUseParentLinkField: boolean | null | undefined;
              readonly hasEpicLinkFieldDependency: boolean | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraPeopleField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly isMulti: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraPriorityField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly priorities: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly color: string | null | undefined;
                  readonly iconUrl: AGG$URL | null | undefined;
                  readonly name: string | null | undefined;
                  readonly priorityId: string;
                  readonly sequence: number | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly priority: {
              readonly color: string | null | undefined;
              readonly iconUrl: AGG$URL | null | undefined;
              readonly name: string | null | undefined;
              readonly priorityId: string;
              readonly sequence: number | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraProformaFormsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly proformaForms: {
              readonly hasIssueForms: boolean | null | undefined;
              readonly hasProjectForms: boolean | null | undefined;
              readonly isHarmonisationEnabled: boolean | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraProjectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly project: {
              readonly avatar: {
                readonly large: string;
                readonly medium: string | null | undefined;
              };
              readonly canSetIssueRestriction: boolean | null | undefined;
              readonly isAIEnabled?: boolean | null | undefined;
              readonly key: string;
              readonly name: string;
              readonly navigationMetadata: {
                readonly boardId?: string;
              } | null | undefined;
              readonly projectId: string;
              readonly projectStyle: JiraProjectStyle | null | undefined;
              readonly projectType: JiraProjectType | null | undefined;
              readonly similarIssues: {
                readonly featureEnabled: boolean;
              } | null | undefined;
              readonly status: JiraProjectStatus | null | undefined;
            } | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraRadioSelectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly radioSelectFieldOptions?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly selectedOption: {
              readonly id: string;
              readonly isDisabled: boolean | null | undefined;
              readonly optionId: string;
              readonly value: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraResolutionField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly resolution: {
              readonly id: string;
              readonly name: string | null | undefined;
              readonly resolutionId: string;
            } | null | undefined;
            readonly resolutions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly resolutionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraRichTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly richText: {
              readonly adfValue: {
                readonly json: AGG$JSON | null | undefined;
              } | null | undefined;
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSecurityLevelField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly securityLevel: {
              readonly description: string | null | undefined;
              readonly name: string | null | undefined;
              readonly securityId: string;
            } | null | undefined;
            readonly securityLevels: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly description: string | null | undefined;
                  readonly name: string | null | undefined;
                  readonly securityId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementApprovalField";
            readonly activeApproval: {
              readonly approvalState: JiraServiceManagementApprovalState | null | undefined;
              readonly approvedStatus: {
                readonly categoryId: string | null | undefined;
                readonly name: string | null | undefined;
              } | null | undefined;
              readonly approverPrincipals: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraServiceManagementGroupApproverPrincipal";
                    readonly approvedCount: number;
                    readonly groupId: string;
                    readonly memberCount: number;
                    readonly name: string;
                  } | {
                    readonly __typename: "JiraServiceManagementUserApproverPrincipal";
                    readonly jiraRest: AGG$URL;
                    readonly user: {
                      readonly accountId: string;
                      readonly name: string;
                      readonly picture: AGG$URL;
                    };
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly approvers: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly approver: {
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly email?: string | null | undefined;
                      readonly name: string;
                      readonly picture: AGG$URL;
                      readonly zoneinfo?: string | null | undefined;
                    };
                    readonly approverDecision: JiraServiceManagementApprovalDecisionResponseType;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly canAnswerApproval: boolean;
              readonly configurations: ReadonlyArray<{
                readonly approversConfigurations: ReadonlyArray<{
                  readonly fieldId: string;
                  readonly fieldName: string;
                  readonly type: string;
                } | null | undefined>;
                readonly condition: {
                  readonly type: string;
                  readonly value: string;
                };
              } | null | undefined>;
              readonly createdDate: AGG$DateTime | null | undefined;
              readonly decisions: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly approver: {
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly email?: string | null | undefined;
                      readonly name: string;
                      readonly picture: AGG$URL;
                      readonly zoneinfo?: string | null | undefined;
                    };
                    readonly approverDecision: JiraServiceManagementApprovalDecisionResponseType;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly excludedApprovers: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly accountId: string;
                    readonly accountStatus: AccountStatus;
                    readonly email?: string | null | undefined;
                    readonly name: string;
                    readonly picture: AGG$URL;
                    readonly zoneinfo?: string | null | undefined;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly finalDecision: JiraServiceManagementApprovalDecisionResponseType;
              readonly id: string;
              readonly name: string;
              readonly pendingApprovalCount: number;
              readonly status: {
                readonly categoryId: string | null | undefined;
                readonly id: string | null | undefined;
                readonly name: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly completedApprovalsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly approvers: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly approver: {
                          readonly accountId: string;
                          readonly accountStatus: AccountStatus;
                          readonly email?: string | null | undefined;
                          readonly name: string;
                          readonly picture: AGG$URL;
                          readonly zoneinfo?: string | null | undefined;
                        };
                        readonly approverDecision: JiraServiceManagementApprovalDecisionResponseType;
                      } | null | undefined;
                    } | null | undefined> | null | undefined;
                  } | null | undefined;
                  readonly completedDate: AGG$DateTime;
                  readonly createdDate: AGG$DateTime | null | undefined;
                  readonly finalDecision: JiraServiceManagementApprovalDecisionResponseType;
                  readonly id: string;
                  readonly name: string;
                  readonly status: {
                    readonly categoryId: string | null | undefined;
                    readonly name: string | null | undefined;
                  } | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementEntitlementField";
            readonly __id: string;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedEntitlement: {
              readonly entity: {
                readonly __typename: "JiraServiceManagementEntitlementCustomer";
                readonly id: string;
              } | {
                readonly __typename: "JiraServiceManagementEntitlementOrganization";
                readonly id: string;
              } | {
                // This will never be '%other', but we need some
                // value in case none of the concrete values match.
                readonly __typename: "%other";
              } | null | undefined;
              readonly id: string;
              readonly product: {
                readonly id: string;
                readonly name: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementMajorIncidentField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly majorIncident: JiraServiceManagementMajorIncident | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementOrganizationField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedOrganizationsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly domain: string | null | undefined;
                  readonly organizationId: string | null | undefined;
                  readonly organizationName: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementPeopleField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRequestFeedbackField";
            readonly description: string | null | undefined;
            readonly feedback: {
              readonly rating: number | null | undefined;
            } | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRequestLanguageField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly language: {
              readonly displayName: string | null | undefined;
              readonly languageCode: string | null | undefined;
            } | null | undefined;
            readonly languages: ReadonlyArray<{
              readonly displayName: string | null | undefined;
              readonly languageCode: string | null | undefined;
            } | null | undefined> | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRequestTypeField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly requestType: {
              readonly avatar: {
                readonly large: string | null | undefined;
                readonly medium: string | null | undefined;
                readonly small: string | null | undefined;
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly issueType: {
                readonly id: string;
                readonly issueTypeId: string | null | undefined;
              } | null | undefined;
              readonly name: string | null | undefined;
              readonly requestTypeId: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRespondersField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly respondersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: "JiraServiceManagementTeamResponder";
                  readonly teamId: string | null | undefined;
                  readonly teamName: string | null | undefined;
                } | {
                  readonly __typename: "JiraServiceManagementUserResponder";
                  readonly user: {
                    readonly canonicalAccountId: string;
                    readonly name: string;
                    readonly picture: AGG$URL;
                  } | null | undefined;
                } | {
                  // This will never be '%other', but we need some
                  // value in case none of the concrete values match.
                  readonly __typename: "%other";
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementSentimentField";
            readonly __id: string;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly sentiment: {
              readonly name: string | null | undefined;
              readonly sentimentId: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleGroupPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedGroup: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleLineTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly text: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleSelectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly fieldOption: {
              readonly id: string;
              readonly isDisabled: boolean | null | undefined;
              readonly optionId: string;
              readonly value: string | null | undefined;
            } | null | undefined;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleSelectUserPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
            readonly user: {
              readonly __typename: string;
              readonly accountId: string;
              readonly accountStatus: AccountStatus;
              readonly locale?: string | null | undefined;
              readonly name: string;
              readonly picture: AGG$URL;
              readonly zoneinfo?: string | null | undefined;
            } | null | undefined;
          } | {
            readonly __typename: "JiraSingleVersionPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly singlePickerVersions?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly description: string | null | undefined;
                  readonly iconUrl: AGG$URL | null | undefined;
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly releaseDate: AGG$DateTime | null | undefined;
                  readonly startDate: AGG$DateTime | null | undefined;
                  readonly status: JiraVersionStatus | null | undefined;
                  readonly versionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
            readonly version: {
              readonly description: string | null | undefined;
              readonly iconUrl: AGG$URL | null | undefined;
              readonly id: string;
              readonly name: string | null | undefined;
              readonly releaseDate: AGG$DateTime | null | undefined;
              readonly startDate: AGG$DateTime | null | undefined;
              readonly status: JiraVersionStatus | null | undefined;
              readonly versionId: string;
            } | null | undefined;
          } | {
            readonly __typename: "JiraSprintField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedSprintsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly boardName: string | null | undefined;
                  readonly completionDate: AGG$DateTime | null | undefined;
                  readonly endDate: AGG$DateTime | null | undefined;
                  readonly goal: string | null | undefined;
                  readonly id: string;
                  readonly name: string;
                  readonly sprintId: string;
                  readonly startDate: AGG$DateTime | null | undefined;
                  readonly state: JiraSprintState | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraStatusField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly status: {
              readonly description: string | null | undefined;
              readonly id: string;
              readonly name: string;
              readonly statusCategory: {
                readonly id: string;
                readonly statusCategoryId: string;
              };
              readonly statusId: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraTeamField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedTeam: {
              readonly avatar: {
                readonly medium: string | null | undefined;
              } | null | undefined;
              readonly isShared: boolean | null | undefined;
              readonly name: string | null | undefined;
              readonly teamId: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraTeamViewField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedTeam: {
              readonly jiraSuppliedAvatar: {
                readonly large: string | null | undefined;
                readonly medium: string | null | undefined;
                readonly small: string | null | undefined;
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly jiraSuppliedId: string;
              readonly jiraSuppliedIsVerified: boolean | null | undefined;
              readonly jiraSuppliedName: string | null | undefined;
              readonly jiraSuppliedTeamId: string;
              readonly jiraSuppliedVisibility: boolean | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraTimeTrackingField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly originalEstimate: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly remainingEstimate: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly timeSpent: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraUrlField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly uri: string | null | undefined;
          } | {
            readonly __typename: "JiraVotesField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly vote: {
              readonly count: AGG$Long | null | undefined;
              readonly hasVoted: boolean | null | undefined;
            } | null | undefined;
          } | {
            readonly __typename: "JiraWatchesField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly watch: {
              readonly count: AGG$Long | null | undefined;
              readonly isWatching: boolean | null | undefined;
            } | null | undefined;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly hierarchyLevelAbove: {
        readonly level: number;
        readonly name: string;
      } | null | undefined;
      readonly hierarchyLevelBelow: {
        readonly level: number;
        readonly name: string;
      } | null | undefined;
      readonly isArchived: boolean | null | undefined;
      readonly issueColorField?: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutColorField_IssueViewColorField">;
      } | null | undefined;
      readonly issueId: string;
      readonly issueLinks: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly direction: JiraIssueLinkDirection;
            readonly id: string | null | undefined;
            readonly issue: {
              readonly fieldsById: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraIssueTypeField";
                    readonly fieldId: string;
                    readonly issueType: {
                      readonly avatar: {
                        readonly large: string | null | undefined;
                        readonly medium: string;
                        readonly small: string | null | undefined;
                        readonly xsmall: string | null | undefined;
                      } | null | undefined;
                      readonly hierarchy: {
                        readonly level: number;
                        readonly name: string;
                      };
                      readonly id: string;
                      readonly issueTypeId: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraPriorityField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly priority: {
                      readonly color: string | null | undefined;
                      readonly iconUrl: AGG$URL | null | undefined;
                      readonly name: string | null | undefined;
                      readonly priorityId: string;
                      readonly sequence: number | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraProjectField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly name: string;
                    readonly project: {
                      readonly avatar: {
                        readonly large: string;
                        readonly medium: string | null | undefined;
                      };
                      readonly canSetIssueRestriction: boolean | null | undefined;
                      readonly key: string;
                      readonly name: string;
                      readonly navigationMetadata: {
                        readonly boardId?: string;
                      } | null | undefined;
                      readonly projectId: string;
                      readonly projectStyle: JiraProjectStyle | null | undefined;
                      readonly projectType: JiraProjectType | null | undefined;
                      readonly similarIssues: {
                        readonly featureEnabled: boolean;
                      } | null | undefined;
                      readonly status: JiraProjectStatus | null | undefined;
                    } | null | undefined;
                    readonly searchUrl: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleLineTextField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly text: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleSelectUserPickerField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly type: string;
                    readonly user: {
                      readonly __typename: string;
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly locale?: string | null | undefined;
                      readonly name: string;
                      readonly picture: AGG$URL;
                      readonly zoneinfo?: string | null | undefined;
                    } | null | undefined;
                  } | {
                    readonly __typename: "JiraStatusField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly status: {
                      readonly id: string;
                      readonly name: string;
                      readonly statusCategory: {
                        readonly colorName: JiraStatusCategoryColor | null | undefined;
                        readonly id: string;
                        readonly key: string | null | undefined;
                        readonly name: string;
                        readonly statusCategoryId: string;
                      };
                      readonly statusId: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly isResolved: boolean | null | undefined;
              readonly issueId: string;
              readonly key: string;
              readonly webUrl: AGG$URL | null | undefined;
            } | null | undefined;
            readonly issueLinkId: string;
            readonly relationName: string;
            readonly type: {
              readonly linkTypeId: string;
            };
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewJpdIdeas_JPDIdeasPanel">;
      } | null | undefined;
      readonly issueTypesForHierarchyAbove: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avatar: {
              readonly large: string | null | undefined;
              readonly medium: string;
              readonly small: string | null | undefined;
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly hierarchy: {
              readonly level: number;
              readonly name: string;
            };
            readonly id: string;
            readonly issueTypeId: string;
            readonly name: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly issueTypesForHierarchyBelow: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avatar: {
              readonly large: string | null | undefined;
              readonly medium: string;
              readonly small: string | null | undefined;
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly hierarchy: {
              readonly level: number;
              readonly name: string;
            };
            readonly id: string;
            readonly issueTypeId: string;
            readonly name: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly issueTypesForHierarchySame: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avatar: {
              readonly large: string | null | undefined;
              readonly medium: string;
              readonly small: string | null | undefined;
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly hierarchy: {
              readonly level: number;
              readonly name: string;
            };
            readonly id: string;
            readonly issueTypeId: string;
            readonly name: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly key: string;
      readonly mediaReadToken?: {
        readonly clientId: string | null | undefined;
        readonly endpointUrl: string | null | undefined;
        readonly tokenLifespanInSeconds: AGG$Long | null | undefined;
        readonly tokensWithFiles: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly token: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly mediaUploadToken?: {
        readonly clientId?: string | null | undefined;
        readonly endpointUrl?: AGG$URL | null | undefined;
        readonly errorId?: string | null | undefined;
        readonly errorMessage?: string | null | undefined;
        readonly targetCollection?: string | null | undefined;
        readonly token?: string | null | undefined;
        readonly tokenDurationInMin?: number | null | undefined;
      } | null | undefined;
      readonly projectField?: {
        readonly project: {
          readonly avatar: {
            readonly large: string;
            readonly medium: string | null | undefined;
          };
          readonly canSetIssueRestriction: boolean | null | undefined;
          readonly isAIEnabled: boolean | null | undefined;
          readonly key: string;
          readonly name: string;
          readonly navigationMetadata: {
            readonly boardId?: string;
          } | null | undefined;
          readonly projectId: string;
          readonly projectStyle: JiraProjectStyle | null | undefined;
          readonly projectType: JiraProjectType | null | undefined;
          readonly similarIssues: {
            readonly featureEnabled: boolean;
          } | null | undefined;
          readonly status: JiraProjectStatus | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly screenId: AGG$Long | null | undefined;
      readonly timeTrackingField?: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ConditionalInlineFieldConfigContent" | "headerActions_issueViewFoundation_HeaderActionsView" | "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView" | "header_issueViewFoundation_HeaderItemWithoutCompactMode" | "prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions" | "src_issueViewLayoutTemplatesItemList_ItemList" | "ui_issueFieldDataClassification_IssueViewDataClassification" | "ui_issueViewLayoutMetaContainer_IssueViewMetaContainer" | "ui_issueViewLayoutSummaryField_IssueViewSummaryField" | "view_issueViewFoundation_ConfigurationButton" | "view_issueViewFoundation_StatusView">;
    } | null | undefined;
    readonly issueLinkTypes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly inwards: string | null | undefined;
          readonly linkTypeId: string | null | undefined;
          readonly outwards: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly jpdDeliveryIssueLinkTypeId?: string | null | undefined;
    readonly userPreferences: {
      readonly isIssueViewAttachmentReferenceFlagDismissed: boolean | null | undefined;
      readonly isIssueViewChildIssuesLimitBestPracticeFlagDismissed: boolean | null | undefined;
      readonly isIssueViewHideDoneChildIssuesFilterEnabled: boolean | null | undefined;
      readonly isIssueViewPinnedFieldsBannerDismissed: boolean | null | undefined;
      readonly issueViewActivityFeedSortOrder: JiraIssueViewActivityFeedSortOrder | null | undefined;
      readonly issueViewAttachmentPanelViewMode: JiraIssueViewAttachmentPanelViewMode | null | undefined;
      readonly issueViewDefaultPinnedFieldsBannerProject?: string | null | undefined;
      readonly issueViewPinnedFields: string | null | undefined;
      readonly issueViewPinnedFieldsBannerLastInteracted: AGG$DateTime | null | undefined;
      readonly issueViewSidebarResizeRatio: string | null | undefined;
      readonly issueViewTimestampDisplayMode: JiraIssueViewTimestampDisplayMode | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"main_issueActivityFeed_FeedDisplay" | "ui_issueViewLayoutTemplates_DateFieldsMessage" | "ui_issueViewLayoutTemplates_UserPreferences" | "view_forgeDataStoreInitFragment" | "view_issueViewActivityComment_SmartRepliesSettings">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueDesignSection_DesignPanelWrapper" | "ui_issueViewCommonViews_ConfigurableChildIssuesPanelWithFragment_rootRelayFragment" | "ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery">;
};
export type mainIssueAggQuery = {
  response: mainIssueAggQuery$data;
  variables: mainIssueAggQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider": isIssueViewFieldConfigEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider": issueViewRelayTimeTrackingFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider": issueViewRelayCheckboxSelectFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider": issueViewRelayComponentsFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider": issueViewRelayOriginalEstimateFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider": issueViewRelayMultiUserAndParticipantsAndRequestParticipantsFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider": issueViewRelayGroupPickerFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider": issueViewRelayMultiGroupPickerFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider": issueViewRelayTeamFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider": issueViewRelayRadioSelectFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider": issueViewRelayRichTextFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrelayprovider": issueViewRelayMultiUserParticipantsFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrequestparticipantsrelayprovider": issueViewRelayRequestParticipantsFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider": issueViewRelayPeopleFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider": issueViewRelayApproversFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider": issueViewRelaySingleLineTextMessageFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider": includeSingleSelectColorField_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider": issueViewRelayHeaderActionsFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider": issueViewRelayWatchersFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider": issueViewRelaySecurityLevelFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider": issueViewRelayVotesFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider": issueViewRelayIssueRestrictionsFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider": issueViewRelayConfigurationUrlFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider": issueViewRelayIssueTypeSwitcherFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider": issueViewRelayResolutionFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider": issueViewRelayStatusFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider": issueViewRelayStatusPanelFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider": isJiraListInlineEditingEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrequestparticipantsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJiraListInlineEditingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-inline-editing.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import issueViewRelayConfigurationUrlFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-configuration-url.relayprovider';
import issueViewRelayApproversFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-approvers.relayprovider';
import issueViewRelayAssigneeFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider';
import issueViewRelayCheckboxSelectFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-checkbox-select.relayprovider';
import issueViewRelayComponentsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-components.relayprovider';
import isIssueViewFieldConfigEditEnabled_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-custom-field-config.relayprovider';
import issueViewRelayGroupPickerFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-group-picker.relayprovider';
import issueViewRelayHeaderActionsFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-header-actions.relayprovider';
import issueViewRelayIssueRestrictionsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-restrictions.relayprovider';
import issueViewRelayIssueTypeSwitcherFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-type-switcher.relayprovider';
import issueViewRelayMultiGroupPickerFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-group-picker.relayprovider';
import issueViewRelayMultiUserAndParticipantsAndRequestParticipantsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user-participants-request-participants.relayprovider';
import issueViewRelayMultiUserParticipantsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user-participants.relayprovider';
import issueViewRelayOriginalEstimateFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-original-estimate.relayprovider';
import issueViewRelayParentFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent';
import issueViewRelayPeopleFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-people.relayprovider';
import issueViewRelayRadioSelectFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-radio-select.relayprovider';
import issueViewRelayReporterFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider';
import issueViewRelayRequestParticipantsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-request-participants.relayprovider';
import issueViewRelayResolutionFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-resolution.relayprovider';
import issueViewRelayRichTextFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-rich-text.relayprovider';
import issueViewRelaySecurityLevelFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-security-level.relayprovider';
import issueViewRelaySingleLineTextMessageFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-single-line-text-message.relayprovider';
import issueViewRelayStatusPanelFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status-panel.relayprovider';
import issueViewRelayStatusFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status.relayprovider';
import issueViewRelayTeamFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-team.relayprovider';
import issueViewRelayTimeTrackingFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-time-tracking.relayprovider';
import issueViewRelayUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider';
import issueViewRelayVotesFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-votes.relayprovider';
import issueViewRelayWatchersFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-watchers.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone1-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chinEmbedQueryToAgg"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentTargetId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customFieldConfigurationFlag"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFieldsMessageEnabled"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsContext"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forgeInMainIssueAggFlag"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forgeTypes"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "giraAGGMigrationCommentsFlag"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeHiddenForge"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeScopesForge"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeUserAccessForge"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCompassComponentsEnabled"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isJPDDeliveryLinkTypeFilteringEnabled"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isJPDPanelEnabled"
},
v19 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPinFieldConfigurabilityEnabled"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v21 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v22 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewDesignsPanelFlag"
},
v23 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewMoveJiraQueryIsAiEnabledProjectField"
},
v24 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayAssigneeFieldFlag"
},
v25 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayAttachmentsFlag"
},
v26 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayColorFieldFlag"
},
v27 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayConfigurationUrlFlag"
},
v28 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayDateFieldFlag"
},
v29 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayDateTimeFieldFlag"
},
v30 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayHeaders"
},
v31 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayMetadataFieldFlag"
},
v32 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayNumberFieldFlag"
},
v33 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayParentFieldFlag"
},
v34 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayPeopleAndApproversFieldFlag"
},
v35 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayPriorityFieldFlag"
},
v36 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayProjectFieldFlag"
},
v37 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelaySingleLineTextFieldFlag"
},
v38 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelaySprintFieldFlag"
},
v39 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelaySummaryFieldFlag"
},
v40 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayTimeTrackingFieldFlag"
},
v41 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayUrlFieldFlag"
},
v42 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRemoveCheckboxSelectFieldOptionsFlag"
},
v43 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRemoveComponentsFieldFlag"
},
v44 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRemoveRadioSelectFieldOptionsFlag"
},
v45 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRemoveSingleVersionFieldOptionsFlag"
},
v46 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewSmartRepliesSettingsFlag"
},
v47 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewStopFetchingJiraQueryIsAiEnabledForIssue"
},
v48 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewStorageLimitsBannerFlag"
},
v49 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jcsIssueLayoutEapFlag"
},
v50 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jiraCommentsAGGPagination"
},
v51 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jiraThreadedCommentsEnabled"
},
v52 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jsmSentimentCustomFieldFlag"
},
v53 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v54 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offeringKey"
},
v55 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productKey"
},
v56 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v57 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v58 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteAri"
},
v59 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v60 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v61 = [
  (v60/*: any*/)
],
v62 = {
  "args": (v61/*: any*/),
  "kind": "ScalarField",
  "name": "childIssuesLimit"
},
v63 = {
  "kind": "ScalarField",
  "name": "epicLinkFieldKey"
},
v64 = {
  "args": (v61/*: any*/),
  "kind": "ScalarField",
  "name": "isSubtasksEnabled"
},
v65 = {
  "kind": "ScalarField",
  "name": "isJiraConfiguredTimeTrackingEnabled"
},
v66 = {
  "kind": "ScalarField",
  "name": "workingHoursPerDay"
},
v67 = {
  "kind": "ScalarField",
  "name": "workingDaysPerWeek"
},
v68 = {
  "kind": "ScalarField",
  "name": "defaultFormat"
},
v69 = {
  "kind": "ScalarField",
  "name": "defaultUnit"
},
v70 = {
  "args": (v61/*: any*/),
  "concreteType": "JiraTimeTrackingSettings",
  "kind": "LinkedField",
  "name": "globalTimeTrackingSettings",
  "plural": false,
  "selections": [
    (v65/*: any*/),
    (v66/*: any*/),
    (v67/*: any*/),
    (v68/*: any*/),
    (v69/*: any*/)
  ]
},
v71 = [
  {
    "kind": "Variable",
    "name": "projectKey",
    "variableName": "projectKey"
  }
],
v72 = {
  "args": (v61/*: any*/),
  "concreteType": "JiraUserPreferences",
  "kind": "LinkedField",
  "name": "userPreferences",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isIssueViewAttachmentReferenceFlagDismissed"
    },
    {
      "kind": "ScalarField",
      "name": "isIssueViewChildIssuesLimitBestPracticeFlagDismissed"
    },
    {
      "kind": "ScalarField",
      "name": "isIssueViewHideDoneChildIssuesFilterEnabled"
    },
    {
      "kind": "ScalarField",
      "name": "isIssueViewPinnedFieldsBannerDismissed"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewActivityFeedSortOrder"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewAttachmentPanelViewMode"
    },
    {
      "args": (v71/*: any*/),
      "kind": "ScalarField",
      "name": "issueViewPinnedFields"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewPinnedFieldsBannerLastInteracted"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewSidebarResizeRatio"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewTimestampDisplayMode"
    },
    {
      "condition": "isPinFieldConfigurabilityEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": (v71/*: any*/),
          "kind": "ScalarField",
          "name": "issueViewDefaultPinnedFieldsBannerProject"
        }
      ]
    }
  ]
},
v73 = {
  "kind": "ScalarField",
  "name": "linkTypeId"
},
v74 = {
  "kind": "ScalarField",
  "name": "inwards"
},
v75 = {
  "kind": "ScalarField",
  "name": "outwards"
},
v76 = [
  (v60/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v77 = {
  "kind": "ScalarField",
  "name": "screenId"
},
v78 = {
  "kind": "ScalarField",
  "name": "errorRetrievingData"
},
v79 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v80 = {
  "kind": "ScalarField",
  "name": "key"
},
v81 = {
  "kind": "ScalarField",
  "name": "isArchived"
},
v82 = {
  "kind": "ScalarField",
  "name": "level"
},
v83 = {
  "kind": "ScalarField",
  "name": "name"
},
v84 = {
  "kind": "ScalarField",
  "name": "id"
},
v85 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v86 = {
  "kind": "ScalarField",
  "name": "description"
},
v87 = {
  "kind": "ScalarField",
  "name": "xsmall"
},
v88 = {
  "kind": "ScalarField",
  "name": "small"
},
v89 = {
  "kind": "ScalarField",
  "name": "medium"
},
v90 = {
  "kind": "ScalarField",
  "name": "large"
},
v91 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v92 = {
  "kind": "ScalarField",
  "name": "isEditable"
},
v93 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    (v92/*: any*/)
  ]
},
v94 = {
  "kind": "ScalarField",
  "name": "guidelines"
},
v95 = {
  "kind": "ScalarField",
  "name": "colorKey"
},
v96 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    (v95/*: any*/)
  ]
},
v97 = {
  "kind": "ScalarField",
  "name": "isEditableInIssueView"
},
v98 = {
  "kind": "ScalarField",
  "name": "isRequired"
},
v99 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    (v98/*: any*/)
  ]
},
v100 = {
  "kind": "InlineFragment",
  "selections": [
    (v99/*: any*/)
  ],
  "type": "JiraIssueFieldConfiguration",
  "abstractKey": "__isJiraIssueFieldConfiguration"
},
v101 = {
  "kind": "InlineFragment",
  "selections": [
    (v97/*: any*/),
    (v100/*: any*/),
    (v84/*: any*/)
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
},
v102 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v103 = {
  "kind": "ScalarField",
  "name": "type"
},
v104 = {
  "kind": "ScalarField",
  "name": "serviceId"
},
v105 = {
  "kind": "ScalarField",
  "name": "searchUrl"
},
v106 = {
  "kind": "ScalarField",
  "name": "value"
},
v107 = {
  "concreteType": "JiraAssetConnection",
  "kind": "LinkedField",
  "name": "selectedAssetsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraAssetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraAsset",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "appKey"
            },
            {
              "kind": "ScalarField",
              "name": "originId"
            },
            {
              "kind": "ScalarField",
              "name": "serializedOrigin"
            },
            (v106/*: any*/)
          ]
        }
      ]
    }
  ]
},
v108 = {
  "kind": "ScalarField",
  "name": "isInsightAvailable"
},
v109 = {
  "kind": "ScalarField",
  "name": "wasInsightRequestSuccessful"
},
v110 = [
  {
    "concreteType": "JiraCmdbConfigAttributeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "node"
      }
    ]
  }
],
v111 = {
  "kind": "ScalarField",
  "name": "objectSchemaId"
},
v112 = {
  "concreteType": "JiraCmdbFieldConfig",
  "kind": "LinkedField",
  "name": "cmdbFieldConfig",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraCmdbConfigAttributeConnection",
      "kind": "LinkedField",
      "name": "attributesDisplayedOnIssue",
      "plural": false,
      "selections": (v110/*: any*/)
    },
    {
      "concreteType": "JiraCmdbConfigAttributeConnection",
      "kind": "LinkedField",
      "name": "attributesIncludedInAutoCompleteSearch",
      "plural": false,
      "selections": (v110/*: any*/)
    },
    (v111/*: any*/),
    {
      "kind": "ScalarField",
      "name": "multiple"
    }
  ]
},
v113 = {
  "kind": "ScalarField",
  "name": "label"
},
v114 = {
  "kind": "ScalarField",
  "name": "objectGlobalId"
},
v115 = {
  "kind": "ScalarField",
  "name": "objectId"
},
v116 = {
  "kind": "ScalarField",
  "name": "objectKey"
},
v117 = {
  "kind": "ScalarField",
  "name": "workspaceId"
},
v118 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v119 = {
  "kind": "ScalarField",
  "name": "clientId"
},
v120 = {
  "kind": "ScalarField",
  "name": "fileId"
},
v121 = {
  "kind": "ScalarField",
  "name": "mediaBaseUrl"
},
v122 = {
  "kind": "ScalarField",
  "name": "mediaJwtToken"
},
v123 = {
  "concreteType": "JiraCmdbMediaClientConfig",
  "kind": "LinkedField",
  "name": "mediaClientConfig",
  "plural": false,
  "selections": [
    (v119/*: any*/),
    (v120/*: any*/),
    {
      "kind": "ScalarField",
      "name": "issuer"
    },
    (v121/*: any*/),
    (v122/*: any*/)
  ]
},
v124 = {
  "kind": "ScalarField",
  "name": "url48"
},
v125 = {
  "kind": "ScalarField",
  "name": "objectTypeId"
},
v126 = {
  "concreteType": "JiraCmdbIcon",
  "kind": "LinkedField",
  "name": "icon",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v83/*: any*/),
    {
      "kind": "ScalarField",
      "name": "url16"
    },
    (v124/*: any*/)
  ]
},
v127 = {
  "kind": "ScalarField",
  "name": "objectTypeAttributeId"
},
v128 = [
  (v84/*: any*/),
  (v83/*: any*/)
],
v129 = {
  "concreteType": "JiraCmdbObjectTypeAttribute",
  "kind": "LinkedField",
  "name": "objectTypeAttribute",
  "plural": false,
  "selections": [
    (v113/*: any*/),
    (v83/*: any*/),
    {
      "concreteType": "JiraCmdbDefaultType",
      "kind": "LinkedField",
      "name": "defaultType",
      "plural": false,
      "selections": (v128/*: any*/)
    },
    (v103/*: any*/)
  ]
},
v130 = {
  "kind": "ScalarField",
  "name": "displayValue"
},
v131 = {
  "kind": "ScalarField",
  "name": "searchValue"
},
v132 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v133 = {
  "kind": "ScalarField",
  "name": "picture"
},
v134 = {
  "concreteType": "JiraCmdbStatusType",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "category"
    },
    (v84/*: any*/),
    (v83/*: any*/)
  ]
},
v135 = [
  (v83/*: any*/)
],
v136 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v90/*: any*/)
  ]
},
v137 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v83/*: any*/),
    (v80/*: any*/),
    (v136/*: any*/)
  ]
},
v138 = {
  "kind": "ScalarField",
  "name": "url"
},
v139 = {
  "concreteType": "JiraCmdbBitbucketRepository",
  "kind": "LinkedField",
  "name": "bitbucketRepo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    (v83/*: any*/),
    (v138/*: any*/),
    {
      "kind": "ScalarField",
      "name": "avatarUrl"
    }
  ]
},
v140 = {
  "concreteType": "JiraOpsgenieTeam",
  "kind": "LinkedField",
  "name": "opsgenieTeam",
  "plural": false,
  "selections": [
    (v83/*: any*/),
    (v84/*: any*/),
    (v138/*: any*/)
  ]
},
v141 = {
  "concreteType": "JiraCmdbAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v123/*: any*/),
    (v124/*: any*/)
  ]
},
v142 = {
  "concreteType": "JiraCmdbObject",
  "kind": "LinkedField",
  "name": "referencedObject",
  "plural": false,
  "selections": [
    (v114/*: any*/),
    (v117/*: any*/),
    (v116/*: any*/),
    (v113/*: any*/),
    (v141/*: any*/),
    (v115/*: any*/)
  ]
},
v143 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v144 = {
  "kind": "ScalarField",
  "name": "isDisabled"
},
v145 = [
  (v84/*: any*/),
  (v143/*: any*/),
  (v106/*: any*/),
  (v144/*: any*/)
],
v146 = {
  "concreteType": "JiraCascadingOption",
  "kind": "LinkedField",
  "name": "cascadingOption",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "parentOptionValue",
      "plural": false,
      "selections": (v145/*: any*/)
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "childOptionValue",
      "plural": false,
      "selections": (v145/*: any*/)
    }
  ]
},
v147 = [
  {
    "concreteType": "JiraOptionEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraOption",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v145/*: any*/)
      }
    ]
  }
],
v148 = {
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "selectedOptions",
  "plural": false,
  "selections": (v147/*: any*/)
},
v149 = {
  "condition": "issueViewRemoveCheckboxSelectFieldOptionsFlag",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": "checkboxesFieldOptions",
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": (v147/*: any*/)
    }
  ]
},
v150 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v95/*: any*/)
  ]
},
v151 = {
  "kind": "ScalarField",
  "name": "componentId"
},
v152 = {
  "kind": "ScalarField",
  "name": "ari"
},
v153 = {
  "kind": "ScalarField",
  "name": "metadata"
},
v154 = [
  {
    "concreteType": "JiraComponentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraComponent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v84/*: any*/),
          (v151/*: any*/),
          (v83/*: any*/),
          (v86/*: any*/),
          {
            "condition": "isCompassComponentsEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              (v152/*: any*/),
              (v153/*: any*/)
            ]
          }
        ]
      }
    ]
  }
],
v155 = {
  "concreteType": "JiraComponentConnection",
  "kind": "LinkedField",
  "name": "selectedComponentsConnection",
  "plural": false,
  "selections": (v154/*: any*/)
},
v156 = {
  "condition": "issueViewRemoveComponentsFieldFlag",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "components",
      "plural": false,
      "selections": (v154/*: any*/)
    }
  ]
},
v157 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v158 = [
  (v101/*: any*/),
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v157/*: any*/)
],
v159 = {
  "alias": "connectMultiSelectFieldOptions",
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "fieldOptions",
  "plural": false,
  "selections": (v147/*: any*/)
},
v160 = {
  "kind": "ScalarField",
  "name": "number"
},
v161 = {
  "kind": "ScalarField",
  "name": "plainText"
},
v162 = {
  "kind": "ScalarField",
  "name": "json"
},
v163 = {
  "concreteType": "JiraADF",
  "kind": "LinkedField",
  "name": "adfValue",
  "plural": false,
  "selections": [
    (v162/*: any*/)
  ]
},
v164 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    (v161/*: any*/),
    (v163/*: any*/)
  ]
},
v165 = {
  "kind": "ScalarField",
  "name": "renderer"
},
v166 = [
  (v101/*: any*/),
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v164/*: any*/),
  (v165/*: any*/)
],
v167 = {
  "concreteType": "JiraOption",
  "kind": "LinkedField",
  "name": "fieldOption",
  "plural": false,
  "selections": (v145/*: any*/)
},
v168 = {
  "alias": "connectSingleSelectFieldOptions",
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "fieldOptions",
  "plural": false,
  "selections": (v147/*: any*/)
},
v169 = {
  "kind": "ScalarField",
  "name": "text"
},
v170 = [
  (v101/*: any*/),
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v169/*: any*/)
],
v171 = {
  "kind": "ScalarField",
  "name": "date"
},
v172 = {
  "kind": "ScalarField",
  "name": "summary"
},
v173 = {
  "kind": "ScalarField",
  "name": "color"
},
v174 = {
  "concreteType": "JiraEpic",
  "kind": "LinkedField",
  "name": "epic",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v79/*: any*/),
    (v83/*: any*/),
    (v80/*: any*/),
    (v172/*: any*/),
    (v173/*: any*/),
    {
      "kind": "ScalarField",
      "name": "done"
    }
  ]
},
v175 = {
  "concreteType": "JiraFlag",
  "kind": "LinkedField",
  "name": "flag",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isFlagged"
    }
  ]
},
v176 = {
  "kind": "ScalarField",
  "name": "groupId"
},
v177 = [
  (v84/*: any*/),
  (v176/*: any*/),
  (v83/*: any*/)
],
v178 = {
  "concreteType": "JiraGroup",
  "kind": "LinkedField",
  "name": "selectedGroup",
  "plural": false,
  "selections": (v177/*: any*/)
},
v179 = {
  "concreteType": "JiraGroupConnection",
  "kind": "LinkedField",
  "name": "selectedGroupsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v177/*: any*/)
        }
      ]
    }
  ]
},
v180 = {
  "kind": "ScalarField",
  "name": "object"
},
v181 = {
  "concreteType": "JiraLabelConnection",
  "kind": "LinkedField",
  "name": "selectedLabelsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraLabelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraLabel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "labelId"
            },
            (v83/*: any*/)
          ]
        }
      ]
    }
  ]
},
v182 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v183 = {
  "kind": "ScalarField",
  "name": "email"
},
v184 = {
  "kind": "ScalarField",
  "name": "zoneinfo"
},
v185 = {
  "kind": "ScalarField",
  "name": "locale"
},
v186 = [
  (v183/*: any*/),
  (v184/*: any*/),
  (v185/*: any*/)
],
v187 = {
  "kind": "InlineFragment",
  "selections": (v186/*: any*/),
  "type": "AtlassianAccountUser"
},
v188 = {
  "kind": "InlineFragment",
  "selections": (v186/*: any*/),
  "type": "CustomerUser"
},
v189 = [
  (v91/*: any*/),
  (v132/*: any*/),
  (v182/*: any*/),
  (v83/*: any*/),
  (v133/*: any*/),
  (v187/*: any*/),
  (v188/*: any*/)
],
v190 = {
  "concreteType": "JiraUserConnection",
  "kind": "LinkedField",
  "name": "selectedUsersConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraUserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v189/*: any*/)
        }
      ]
    }
  ]
},
v191 = {
  "kind": "ScalarField",
  "name": "roleId"
},
v192 = {
  "concreteType": "JiraRoleConnection",
  "kind": "LinkedField",
  "name": "selectedRolesConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRoleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraRole",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v84/*: any*/),
            (v191/*: any*/)
          ]
        }
      ]
    }
  ]
},
v193 = [
  (v101/*: any*/),
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v190/*: any*/),
  (v105/*: any*/)
],
v194 = {
  "kind": "ScalarField",
  "name": "versionId"
},
v195 = {
  "kind": "ScalarField",
  "name": "status"
},
v196 = {
  "concreteType": "JiraVersionConnection",
  "kind": "LinkedField",
  "name": "selectedVersionsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraVersionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraVersion",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v84/*: any*/),
            (v194/*: any*/),
            (v83/*: any*/),
            (v195/*: any*/),
            (v86/*: any*/)
          ]
        }
      ]
    }
  ]
},
v197 = {
  "kind": "ScalarField",
  "name": "isStoryPointField"
},
v198 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    (v98/*: any*/),
    (v92/*: any*/)
  ]
},
v199 = {
  "kind": "InlineFragment",
  "selections": [
    (v198/*: any*/)
  ],
  "type": "JiraIssueFieldConfiguration",
  "abstractKey": "__isJiraIssueFieldConfiguration"
},
v200 = [
  {
    "kind": "ScalarField",
    "name": "timeInSeconds"
  }
],
v201 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "originalEstimate",
  "plural": false,
  "selections": (v200/*: any*/)
},
v202 = {
  "concreteType": "JiraParentVisibility",
  "kind": "LinkedField",
  "name": "parentVisibility",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasEpicLinkFieldDependency"
    },
    {
      "kind": "ScalarField",
      "name": "canUseParentLinkField"
    }
  ]
},
v203 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "issuetype",
      "project",
      "summary"
    ]
  }
],
v204 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v205 = {
  "kind": "ScalarField",
  "name": "canSetIssueRestriction"
},
v206 = {
  "kind": "ScalarField",
  "name": "projectStyle"
},
v207 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v208 = {
  "concreteType": "JiraSimilarIssues",
  "kind": "LinkedField",
  "name": "similarIssues",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "featureEnabled"
    }
  ]
},
v209 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v210 = {
  "kind": "LinkedField",
  "name": "navigationMetadata",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v209/*: any*/)
      ],
      "type": "JiraSoftwareProjectNavigationMetadata"
    }
  ]
},
v211 = {
  "kind": "InlineFragment",
  "selections": [
    (v102/*: any*/),
    (v83/*: any*/),
    (v103/*: any*/),
    (v86/*: any*/),
    (v169/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v212 = {
  "kind": "ScalarField",
  "name": "isMulti"
},
v213 = {
  "kind": "ScalarField",
  "name": "priorityId"
},
v214 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v215 = {
  "kind": "ScalarField",
  "name": "sequence"
},
v216 = [
  (v213/*: any*/),
  (v83/*: any*/),
  (v214/*: any*/),
  (v215/*: any*/),
  (v173/*: any*/)
],
v217 = {
  "concreteType": "JiraProformaForms",
  "kind": "LinkedField",
  "name": "proformaForms",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasProjectForms"
    },
    {
      "kind": "ScalarField",
      "name": "hasIssueForms"
    },
    {
      "kind": "ScalarField",
      "name": "isHarmonisationEnabled"
    }
  ]
},
v218 = {
  "kind": "ScalarField",
  "name": "isAIEnabled"
},
v219 = {
  "condition": "issueViewMoveJiraQueryIsAiEnabledProjectField",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    (v218/*: any*/)
  ]
},
v220 = {
  "concreteType": "JiraOption",
  "kind": "LinkedField",
  "name": "selectedOption",
  "plural": false,
  "selections": (v145/*: any*/)
},
v221 = {
  "condition": "issueViewRemoveRadioSelectFieldOptionsFlag",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": "radioSelectFieldOptions",
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": (v147/*: any*/)
    }
  ]
},
v222 = {
  "kind": "ScalarField",
  "name": "resolutionId"
},
v223 = [
  (v84/*: any*/),
  (v222/*: any*/),
  (v83/*: any*/)
],
v224 = {
  "concreteType": "JiraResolution",
  "kind": "LinkedField",
  "name": "resolution",
  "plural": false,
  "selections": (v223/*: any*/)
},
v225 = {
  "concreteType": "JiraResolutionConnection",
  "kind": "LinkedField",
  "name": "resolutions",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraResolutionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraResolution",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v223/*: any*/)
        }
      ]
    }
  ]
},
v226 = {
  "kind": "ScalarField",
  "name": "securityId"
},
v227 = [
  (v83/*: any*/),
  (v226/*: any*/),
  (v86/*: any*/)
],
v228 = {
  "kind": "ScalarField",
  "name": "approvalState"
},
v229 = {
  "kind": "ScalarField",
  "name": "categoryId"
},
v230 = {
  "concreteType": "JiraServiceManagementApprovalStatus",
  "kind": "LinkedField",
  "name": "approvedStatus",
  "plural": false,
  "selections": [
    (v229/*: any*/),
    (v83/*: any*/)
  ]
},
v231 = {
  "kind": "ScalarField",
  "name": "jiraRest"
},
v232 = {
  "kind": "ScalarField",
  "name": "memberCount"
},
v233 = {
  "kind": "ScalarField",
  "name": "approvedCount"
},
v234 = [
  (v183/*: any*/),
  (v184/*: any*/)
],
v235 = {
  "kind": "InlineFragment",
  "selections": (v234/*: any*/),
  "type": "AtlassianAccountUser"
},
v236 = {
  "kind": "InlineFragment",
  "selections": (v234/*: any*/),
  "type": "CustomerUser"
},
v237 = {
  "kind": "ScalarField",
  "name": "approverDecision"
},
v238 = {
  "kind": "ScalarField",
  "name": "canAnswerApproval"
},
v239 = {
  "kind": "ScalarField",
  "name": "fieldName"
},
v240 = {
  "kind": "ScalarField",
  "name": "createdDate"
},
v241 = {
  "kind": "ScalarField",
  "name": "finalDecision"
},
v242 = {
  "kind": "ScalarField",
  "name": "pendingApprovalCount"
},
v243 = {
  "concreteType": "JiraServiceManagementApprovalStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v83/*: any*/),
    (v229/*: any*/)
  ]
},
v244 = {
  "kind": "ScalarField",
  "name": "completedDate"
},
v245 = {
  "concreteType": "JiraServiceManagementApprovalStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v83/*: any*/),
    (v229/*: any*/)
  ]
},
v246 = {
  "kind": "ScalarField",
  "name": "majorIncident"
},
v247 = {
  "kind": "ScalarField",
  "name": "organizationId"
},
v248 = {
  "kind": "ScalarField",
  "name": "organizationName"
},
v249 = {
  "kind": "ScalarField",
  "name": "domain"
},
v250 = {
  "concreteType": "JiraServiceManagementFeedback",
  "kind": "LinkedField",
  "name": "feedback",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "rating"
    }
  ]
},
v251 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v252 = [
  {
    "kind": "ScalarField",
    "name": "languageCode"
  },
  (v251/*: any*/)
],
v253 = {
  "concreteType": "JiraServiceManagementLanguage",
  "kind": "LinkedField",
  "name": "language",
  "plural": false,
  "selections": (v252/*: any*/)
},
v254 = {
  "concreteType": "JiraServiceManagementLanguage",
  "kind": "LinkedField",
  "name": "languages",
  "plural": true,
  "selections": (v252/*: any*/)
},
v255 = [
  (v87/*: any*/),
  (v88/*: any*/),
  (v89/*: any*/),
  (v90/*: any*/)
],
v256 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": (v255/*: any*/)
},
v257 = {
  "concreteType": "JiraServiceManagementRequestType",
  "kind": "LinkedField",
  "name": "requestType",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    {
      "kind": "ScalarField",
      "name": "requestTypeId"
    },
    (v83/*: any*/),
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v84/*: any*/),
        (v85/*: any*/)
      ]
    },
    (v256/*: any*/)
  ]
},
v258 = {
  "kind": "ScalarField",
  "name": "canonicalAccountId"
},
v259 = {
  "kind": "ScalarField",
  "name": "teamId"
},
v260 = {
  "kind": "InlineFragment",
  "selections": [
    (v259/*: any*/),
    {
      "kind": "ScalarField",
      "name": "teamName"
    }
  ],
  "type": "JiraServiceManagementTeamResponder"
},
v261 = [
  (v184/*: any*/),
  (v185/*: any*/)
],
v262 = {
  "kind": "InlineFragment",
  "selections": (v261/*: any*/),
  "type": "AtlassianAccountUser"
},
v263 = {
  "kind": "InlineFragment",
  "selections": (v261/*: any*/),
  "type": "CustomerUser"
},
v264 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v91/*: any*/),
    (v132/*: any*/),
    (v182/*: any*/),
    (v83/*: any*/),
    (v133/*: any*/),
    (v262/*: any*/),
    (v263/*: any*/)
  ]
},
v265 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v266 = [
  (v84/*: any*/),
  (v194/*: any*/),
  (v83/*: any*/),
  (v214/*: any*/),
  (v195/*: any*/),
  (v86/*: any*/),
  (v265/*: any*/),
  {
    "kind": "ScalarField",
    "name": "releaseDate"
  }
],
v267 = {
  "concreteType": "JiraVersion",
  "kind": "LinkedField",
  "name": "version",
  "plural": false,
  "selections": (v266/*: any*/)
},
v268 = {
  "condition": "issueViewRemoveSingleVersionFieldOptionsFlag",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": "singlePickerVersions",
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "versions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v266/*: any*/)
            }
          ]
        }
      ]
    }
  ]
},
v269 = {
  "kind": "ScalarField",
  "name": "sprintId"
},
v270 = {
  "kind": "ScalarField",
  "name": "state"
},
v271 = {
  "kind": "ScalarField",
  "name": "boardName"
},
v272 = {
  "kind": "ScalarField",
  "name": "endDate"
},
v273 = {
  "kind": "ScalarField",
  "name": "completionDate"
},
v274 = {
  "kind": "ScalarField",
  "name": "goal"
},
v275 = {
  "kind": "ScalarField",
  "name": "statusId"
},
v276 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v277 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v276/*: any*/)
  ]
},
v278 = {
  "kind": "ScalarField",
  "name": "isShared"
},
v279 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v89/*: any*/)
  ]
},
v280 = {
  "kind": "ScalarField",
  "name": "jiraSuppliedVisibility"
},
v281 = {
  "kind": "ScalarField",
  "name": "jiraSuppliedName"
},
v282 = {
  "kind": "ScalarField",
  "name": "jiraSuppliedId"
},
v283 = {
  "kind": "ScalarField",
  "name": "jiraSuppliedTeamId"
},
v284 = {
  "kind": "ScalarField",
  "name": "jiraSuppliedIsVerified"
},
v285 = {
  "concreteType": "JiraTeamView",
  "kind": "LinkedField",
  "name": "selectedTeam",
  "plural": false,
  "selections": [
    (v280/*: any*/),
    (v281/*: any*/),
    (v282/*: any*/),
    (v283/*: any*/),
    (v284/*: any*/),
    {
      "concreteType": "JiraAvatar",
      "kind": "LinkedField",
      "name": "jiraSuppliedAvatar",
      "plural": false,
      "selections": (v255/*: any*/)
    }
  ]
},
v286 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "remainingEstimate",
  "plural": false,
  "selections": (v200/*: any*/)
},
v287 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "timeSpent",
  "plural": false,
  "selections": (v200/*: any*/)
},
v288 = {
  "kind": "ScalarField",
  "name": "uri"
},
v289 = {
  "kind": "ScalarField",
  "name": "hasVoted"
},
v290 = {
  "kind": "ScalarField",
  "name": "count"
},
v291 = {
  "concreteType": "JiraVote",
  "kind": "LinkedField",
  "name": "vote",
  "plural": false,
  "selections": [
    (v289/*: any*/),
    (v290/*: any*/)
  ]
},
v292 = {
  "kind": "ScalarField",
  "name": "isWatching"
},
v293 = {
  "concreteType": "JiraWatch",
  "kind": "LinkedField",
  "name": "watch",
  "plural": false,
  "selections": [
    (v292/*: any*/),
    (v290/*: any*/)
  ]
},
v294 = [
  (v84/*: any*/)
],
v295 = {
  "concreteType": "JiraServiceManagementEntitlement",
  "kind": "LinkedField",
  "name": "selectedEntitlement",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    {
      "concreteType": "JiraServiceManagementProduct",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": (v128/*: any*/)
    },
    {
      "kind": "LinkedField",
      "name": "entity",
      "plural": false,
      "selections": [
        (v91/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v294/*: any*/),
          "type": "JiraServiceManagementEntitlementCustomer"
        },
        {
          "kind": "InlineFragment",
          "selections": (v294/*: any*/),
          "type": "JiraServiceManagementEntitlementOrganization"
        }
      ]
    }
  ]
},
v296 = {
  "kind": "InlineFragment",
  "selections": [
    (v97/*: any*/),
    (v100/*: any*/)
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
},
v297 = {
  "kind": "ScalarField",
  "name": "__id"
},
v298 = {
  "kind": "ClientExtension",
  "selections": [
    (v297/*: any*/)
  ]
},
v299 = {
  "concreteType": "JiraServiceManagementSentiment",
  "kind": "LinkedField",
  "name": "sentiment",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "sentimentId"
    },
    (v83/*: any*/)
  ]
},
v300 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v301 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v302 = {
  "args": (v300/*: any*/),
  "concreteType": "JiraGoalConnection",
  "kind": "LinkedField",
  "name": "selectedGoals",
  "plural": false,
  "selections": [
    (v301/*: any*/),
    {
      "concreteType": "JiraGoalEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraGoal",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v84/*: any*/),
            (v83/*: any*/),
            (v195/*: any*/)
          ]
        }
      ]
    }
  ],
  "storageKey": "selectedGoals(first:1)"
},
v303 = [
  {
    "kind": "Literal",
    "name": "activeProjectsOnly",
    "value": true
  }
],
v304 = {
  "kind": "ScalarField",
  "name": "isResolved"
},
v305 = [
  (v91/*: any*/),
  (v102/*: any*/),
  (v103/*: any*/),
  (v160/*: any*/),
  (v197/*: any*/)
],
v306 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "assignee",
      "created",
      "issuetype",
      "priority",
      "status",
      "summary",
      "timetracking",
      "updated"
    ]
  }
],
v307 = {
  "kind": "InlineFragment",
  "selections": [
    (v102/*: any*/),
    (v103/*: any*/),
    (v169/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v308 = {
  "kind": "InlineFragment",
  "selections": [
    (v102/*: any*/),
    (v103/*: any*/),
    (v201/*: any*/),
    (v286/*: any*/),
    (v287/*: any*/)
  ],
  "type": "JiraTimeTrackingField"
},
v309 = {
  "kind": "InlineFragment",
  "selections": [
    (v102/*: any*/),
    (v103/*: any*/),
    (v157/*: any*/)
  ],
  "type": "JiraDateTimePickerField"
},
v310 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "search"
    }
  ],
  "type": "JiraChildIssuesExceedingLimit"
},
v311 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v312 = {
  "kind": "ScalarField",
  "name": "issueLinkId"
},
v313 = {
  "kind": "ScalarField",
  "name": "direction"
},
v314 = {
  "kind": "ScalarField",
  "name": "relationName"
},
v315 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "assignee",
      "issuetype",
      "priority",
      "status",
      "summary",
      "project"
    ]
  }
],
v316 = {
  "kind": "ScalarField",
  "name": "colorName"
},
v317 = {
  "kind": "InlineFragment",
  "selections": [
    (v102/*: any*/),
    (v83/*: any*/),
    (v103/*: any*/),
    (v169/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v318 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": {
    "field": "CREATED",
    "order": "DESC"
  }
},
v319 = {
  "kind": "ScalarField",
  "name": "attachmentId"
},
v320 = {
  "kind": "ScalarField",
  "name": "fileName"
},
v321 = {
  "kind": "ScalarField",
  "name": "mimeType"
},
v322 = {
  "kind": "ScalarField",
  "name": "mediaApiFileId"
},
v323 = {
  "kind": "ScalarField",
  "name": "created"
},
v324 = {
  "kind": "ScalarField",
  "name": "fileSize"
},
v325 = {
  "kind": "ScalarField",
  "name": "parentName"
},
v326 = {
  "kind": "ScalarField",
  "name": "parentId"
},
v327 = {
  "kind": "ScalarField",
  "name": "hasRestrictedParent"
},
v328 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v329 = {
  "kind": "ScalarField",
  "name": "endCursor"
},
v330 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v331 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v329/*: any*/),
    (v330/*: any*/)
  ]
},
v332 = {
  "kind": "ScalarField",
  "name": "endpointUrl"
},
v333 = {
  "kind": "ScalarField",
  "name": "token"
},
v334 = {
  "args": [
    {
      "kind": "Literal",
      "name": "maxTokenLength",
      "value": 65536
    }
  ],
  "concreteType": "JiraMediaReadToken",
  "kind": "LinkedField",
  "name": "mediaReadToken",
  "plural": false,
  "selections": [
    (v119/*: any*/),
    (v332/*: any*/),
    {
      "kind": "ScalarField",
      "name": "tokenLifespanInSeconds"
    },
    {
      "concreteType": "JiraMediaTokenWithFilesConnection",
      "kind": "LinkedField",
      "name": "tokensWithFiles",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraMediaTokenWithFilesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraMediaTokenWithFiles",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v333/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "storageKey": "mediaReadToken(maxTokenLength:65536)"
},
v335 = {
  "kind": "InlineFragment",
  "selections": [
    (v332/*: any*/),
    (v119/*: any*/),
    {
      "kind": "ScalarField",
      "name": "targetCollection"
    },
    (v333/*: any*/),
    {
      "kind": "ScalarField",
      "name": "tokenDurationInMin"
    }
  ],
  "type": "JiraMediaUploadToken"
},
v336 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "errorId",
      "kind": "ScalarField",
      "name": "identifier"
    },
    {
      "alias": "errorMessage",
      "kind": "ScalarField",
      "name": "message"
    }
  ],
  "type": "QueryError"
},
v337 = [
  {
    "kind": "Literal",
    "name": "idOrAlias",
    "value": "timetracking"
  }
],
v338 = [
  {
    "kind": "Literal",
    "name": "afterTarget",
    "value": 5
  },
  {
    "kind": "Literal",
    "name": "beforeTarget",
    "value": 5
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  },
  (v318/*: any*/),
  {
    "kind": "Variable",
    "name": "targetId",
    "variableName": "commentTargetId"
  }
],
v339 = {
  "condition": "jiraCommentsAGGPagination",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v328/*: any*/)
  ]
},
v340 = {
  "kind": "ScalarField",
  "name": "commentId"
},
v341 = {
  "kind": "ScalarField",
  "name": "updated"
},
v342 = [
  (v83/*: any*/),
  (v132/*: any*/),
  (v133/*: any*/)
],
v343 = {
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": (v342/*: any*/)
},
v344 = {
  "kind": "LinkedField",
  "name": "updateAuthor",
  "plural": false,
  "selections": (v342/*: any*/)
},
v345 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    (v163/*: any*/)
  ]
},
v346 = [
  (v84/*: any*/),
  (v83/*: any*/),
  (v176/*: any*/)
],
v347 = {
  "concreteType": "JiraPermissionLevel",
  "kind": "LinkedField",
  "name": "permissionLevel",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": (v346/*: any*/)
    },
    {
      "concreteType": "JiraRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": (v128/*: any*/)
    }
  ]
},
v348 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2
  }
],
v349 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v330/*: any*/),
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    },
    {
      "kind": "ScalarField",
      "name": "startCursor"
    },
    (v329/*: any*/)
  ]
},
v350 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "authorCanSeeRequest"
    },
    {
      "kind": "ScalarField",
      "name": "eventOccurredAt"
    },
    {
      "kind": "ScalarField",
      "name": "visibility"
    },
    {
      "kind": "ScalarField",
      "name": "jsdIncidentActivityViewHidden"
    }
  ],
  "type": "JiraServiceManagementComment"
},
v351 = {
  "condition": "jiraCommentsAGGPagination",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v349/*: any*/)
  ]
},
v352 = {
  "condition": "isJPDDeliveryLinkTypeFilteringEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": (v61/*: any*/),
      "kind": "ScalarField",
      "name": "jpdDeliveryIssueLinkTypeId"
    }
  ]
},
v353 = {
  "kind": "Variable",
  "name": "issueKey",
  "variableName": "issueKey"
},
v354 = [
  (v353/*: any*/)
],
v355 = {
  "condition": "issueViewStopFetchingJiraQueryIsAiEnabledForIssue",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": [
        {
          "fields": (v354/*: any*/),
          "kind": "ObjectValue",
          "name": "issueInput"
        }
      ],
      "kind": "ScalarField",
      "name": "isAiEnabledForIssue"
    }
  ]
},
v356 = [
  (v82/*: any*/),
  (v83/*: any*/)
],
v357 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": (v356/*: any*/)
},
v358 = [
  (v84/*: any*/),
  (v85/*: any*/),
  (v83/*: any*/),
  (v86/*: any*/),
  (v256/*: any*/),
  (v357/*: any*/)
],
v359 = [
  {
    "concreteType": "JiraIssueTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v358/*: any*/)
      }
    ]
  }
],
v360 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v361 = {
  "concreteType": "JiraFieldOperation",
  "kind": "LinkedField",
  "name": "fieldOperations",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "canEdit"
    }
  ]
},
v362 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    (v92/*: any*/),
    (v98/*: any*/)
  ]
},
v363 = [
  (v80/*: any*/),
  (v84/*: any*/)
],
v364 = {
  "concreteType": "JiraIssue",
  "kind": "LinkedField",
  "name": "issue",
  "plural": false,
  "selections": (v363/*: any*/)
},
v365 = {
  "concreteType": "JiraAdfToConvertedPlainText",
  "kind": "LinkedField",
  "name": "convertedPlainText",
  "plural": false,
  "selections": [
    (v161/*: any*/)
  ]
},
v366 = [
  (v84/*: any*/),
  (v80/*: any*/)
],
v367 = {
  "concreteType": "JiraIssue",
  "kind": "LinkedField",
  "name": "issue",
  "plural": false,
  "selections": (v366/*: any*/)
},
v368 = {
  "kind": "InlineFragment",
  "selections": [
    (v183/*: any*/)
  ],
  "type": "AtlassianAccountUser"
},
v369 = [
  (v83/*: any*/),
  (v86/*: any*/),
  (v93/*: any*/),
  (v364/*: any*/),
  {
    "concreteType": "JiraUserConnection",
    "kind": "LinkedField",
    "name": "selectedUsersConnection",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraUserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v91/*: any*/),
              (v132/*: any*/),
              (v84/*: any*/),
              (v83/*: any*/),
              (v133/*: any*/),
              (v368/*: any*/)
            ]
          }
        ]
      }
    ]
  }
],
v370 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v364/*: any*/)
  ]
},
v371 = {
  "kind": "InlineFragment",
  "selections": [
    (v86/*: any*/),
    (v370/*: any*/)
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
},
v372 = [
  (v83/*: any*/),
  {
    "concreteType": "JiraUserConnection",
    "kind": "LinkedField",
    "name": "selectedUsersConnection",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraUserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v91/*: any*/),
              (v132/*: any*/),
              (v84/*: any*/),
              (v182/*: any*/),
              (v83/*: any*/),
              (v133/*: any*/),
              (v368/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  (v93/*: any*/),
  (v371/*: any*/)
],
v373 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v276/*: any*/),
    (v84/*: any*/)
  ]
},
v374 = {
  "concreteType": "JiraStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v373/*: any*/),
    (v84/*: any*/)
  ]
},
v375 = {
  "concreteType": "JiraStatusField",
  "kind": "LinkedField",
  "name": "statusField",
  "plural": false,
  "selections": [
    (v374/*: any*/),
    (v84/*: any*/)
  ]
},
v376 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v206/*: any*/),
    (v84/*: any*/)
  ]
},
v377 = {
  "concreteType": "JiraProjectField",
  "kind": "LinkedField",
  "name": "projectField",
  "plural": false,
  "selections": [
    (v376/*: any*/),
    (v84/*: any*/)
  ]
},
v378 = {
  "kind": "LinkedField",
  "name": "childIssues",
  "plural": false,
  "selections": [
    (v91/*: any*/),
    (v310/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraIssueConnection",
          "kind": "LinkedField",
          "name": "issues",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": "timeTrackingField",
                      "args": (v337/*: any*/),
                      "kind": "LinkedField",
                      "name": "fieldByIdOrAlias",
                      "plural": false,
                      "selections": [
                        (v91/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v201/*: any*/),
                            (v287/*: any*/),
                            (v286/*: any*/)
                          ],
                          "type": "JiraTimeTrackingField"
                        },
                        (v84/*: any*/)
                      ],
                      "storageKey": "fieldByIdOrAlias(idOrAlias:\"timetracking\")"
                    },
                    (v84/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ],
      "type": "JiraChildIssuesWithinLimit"
    }
  ]
},
v379 = {
  "concreteType": "JiraTimeTrackingSettings",
  "kind": "LinkedField",
  "name": "timeTrackingSettings",
  "plural": false,
  "selections": [
    (v65/*: any*/),
    (v67/*: any*/),
    (v66/*: any*/),
    (v69/*: any*/),
    (v68/*: any*/)
  ]
},
v380 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v91/*: any*/),
    (v83/*: any*/),
    (v133/*: any*/),
    (v182/*: any*/),
    (v84/*: any*/),
    (v132/*: any*/)
  ]
},
v381 = [
  (v83/*: any*/),
  (v380/*: any*/),
  (v198/*: any*/),
  (v371/*: any*/)
],
v382 = {
  "concreteType": "JiraLabelEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "concreteType": "JiraLabel",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": (v135/*: any*/)
    }
  ]
},
v383 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    (v95/*: any*/),
    (v84/*: any*/)
  ]
},
v384 = {
  "concreteType": "JiraOption",
  "kind": "LinkedField",
  "name": "fieldOption",
  "plural": false,
  "selections": [
    (v106/*: any*/),
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        (v383/*: any*/)
      ]
    },
    (v84/*: any*/),
    (v143/*: any*/)
  ]
},
v385 = [
  (v106/*: any*/),
  (v84/*: any*/),
  (v144/*: any*/)
],
v386 = {
  "concreteType": "JiraPriority",
  "kind": "LinkedField",
  "name": "priority",
  "plural": false,
  "selections": [
    (v83/*: any*/),
    (v214/*: any*/),
    (v84/*: any*/)
  ]
},
v387 = {
  "concreteType": "JiraSprintConnection",
  "kind": "LinkedField",
  "name": "selectedSprintsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraSprintEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v84/*: any*/),
            (v269/*: any*/),
            (v83/*: any*/),
            (v270/*: any*/),
            (v272/*: any*/)
          ]
        }
      ]
    }
  ]
},
v388 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v383/*: any*/),
    (v84/*: any*/)
  ]
},
v389 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v87/*: any*/)
  ]
},
v390 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v389/*: any*/),
        (v83/*: any*/),
        (v84/*: any*/)
      ]
    },
    (v84/*: any*/)
  ]
},
v391 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v169/*: any*/),
    (v84/*: any*/)
  ]
},
v392 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    (v82/*: any*/)
  ]
},
v393 = {
  "kind": "InlineFragment",
  "selections": [
    (v102/*: any*/),
    (v374/*: any*/)
  ],
  "type": "JiraStatusField"
},
v394 = {
  "args": [
    {
      "kind": "Literal",
      "name": "ids",
      "value": [
        "summary",
        "issuetype",
        "status"
      ]
    }
  ],
  "concreteType": "JiraIssueFieldConnection",
  "kind": "LinkedField",
  "name": "fieldsById",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v91/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v102/*: any*/),
                (v169/*: any*/)
              ],
              "type": "JiraSingleLineTextField"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v102/*: any*/),
                {
                  "concreteType": "JiraIssueType",
                  "kind": "LinkedField",
                  "name": "issueType",
                  "plural": false,
                  "selections": [
                    (v85/*: any*/),
                    (v83/*: any*/),
                    (v389/*: any*/),
                    (v392/*: any*/),
                    (v84/*: any*/)
                  ]
                }
              ],
              "type": "JiraIssueTypeField"
            },
            (v393/*: any*/),
            (v84/*: any*/)
          ]
        }
      ]
    }
  ],
  "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
},
v395 = {
  "concreteType": "JiraServiceManagementOrganization",
  "kind": "LinkedField",
  "name": "selectedOrganization",
  "plural": false,
  "selections": [
    (v247/*: any*/),
    (v248/*: any*/),
    (v84/*: any*/)
  ]
},
v396 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "status"
    ]
  }
],
v397 = [
  (v91/*: any*/),
  (v132/*: any*/),
  (v84/*: any*/)
],
v398 = [
  {
    "concreteType": "JiraIssueFieldEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v91/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v397/*: any*/)
              }
            ],
            "type": "JiraSingleSelectUserPickerField"
          },
          (v84/*: any*/)
        ]
      }
    ]
  }
],
v399 = {
  "kind": "ScalarField",
  "name": "definition"
},
v400 = [
  (v84/*: any*/),
  (v83/*: any*/),
  (v383/*: any*/),
  (v399/*: any*/)
],
v401 = {
  "concreteType": "JiraAffectedServiceConnection",
  "kind": "LinkedField",
  "name": "selectedAffectedServicesConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraAffectedServiceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraAffectedService",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v104/*: any*/),
            (v83/*: any*/),
            (v84/*: any*/)
          ]
        }
      ]
    }
  ]
},
v402 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v91/*: any*/),
    (v132/*: any*/),
    (v83/*: any*/),
    (v133/*: any*/),
    (v84/*: any*/)
  ]
},
v403 = [
  (v83/*: any*/),
  (v84/*: any*/)
],
v404 = [
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v157/*: any*/),
  (v296/*: any*/)
],
v405 = [
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v164/*: any*/),
  (v165/*: any*/),
  (v296/*: any*/)
],
v406 = [
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v169/*: any*/),
  (v296/*: any*/)
],
v407 = [
  (v91/*: any*/),
  (v132/*: any*/),
  (v182/*: any*/),
  (v83/*: any*/),
  (v133/*: any*/),
  (v187/*: any*/),
  (v188/*: any*/),
  (v84/*: any*/)
],
v408 = {
  "concreteType": "JiraUserConnection",
  "kind": "LinkedField",
  "name": "selectedUsersConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraUserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v407/*: any*/)
        }
      ]
    }
  ]
},
v409 = {
  "concreteType": "JiraIssueType",
  "kind": "LinkedField",
  "name": "issueType",
  "plural": false,
  "selections": (v358/*: any*/)
},
v410 = [
  (v102/*: any*/),
  (v83/*: any*/),
  (v103/*: any*/),
  (v86/*: any*/),
  (v408/*: any*/),
  (v105/*: any*/),
  (v296/*: any*/)
],
v411 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v89/*: any*/),
    (v90/*: any*/)
  ]
},
v412 = {
  "kind": "LinkedField",
  "name": "navigationMetadata",
  "plural": false,
  "selections": [
    (v91/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v209/*: any*/),
        (v84/*: any*/)
      ],
      "type": "JiraSoftwareProjectNavigationMetadata"
    }
  ]
},
v413 = [
  (v213/*: any*/),
  (v83/*: any*/),
  (v214/*: any*/),
  (v215/*: any*/),
  (v173/*: any*/),
  (v84/*: any*/)
],
v414 = [
  (v83/*: any*/),
  (v226/*: any*/),
  (v86/*: any*/),
  (v84/*: any*/)
],
v415 = [
  (v91/*: any*/),
  (v132/*: any*/),
  (v182/*: any*/),
  (v83/*: any*/),
  (v133/*: any*/),
  (v235/*: any*/),
  (v236/*: any*/),
  (v84/*: any*/)
],
v416 = [
  {
    "kind": "LinkedField",
    "name": "approver",
    "plural": false,
    "selections": (v415/*: any*/)
  },
  (v237/*: any*/)
],
v417 = {
  "concreteType": "JiraServiceManagementApproverConnection",
  "kind": "LinkedField",
  "name": "approvers",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraServiceManagementApproverEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraServiceManagementApprover",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v416/*: any*/)
        }
      ]
    }
  ]
},
v418 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v91/*: any*/),
    (v132/*: any*/),
    (v182/*: any*/),
    (v83/*: any*/),
    (v133/*: any*/),
    (v262/*: any*/),
    (v263/*: any*/),
    (v84/*: any*/)
  ]
},
v419 = [
  (v91/*: any*/),
  (v102/*: any*/),
  (v103/*: any*/),
  (v160/*: any*/),
  (v197/*: any*/),
  (v84/*: any*/)
],
v420 = {
  "concreteType": "JiraStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v83/*: any*/),
    (v275/*: any*/),
    (v373/*: any*/),
    (v84/*: any*/)
  ]
},
v421 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v276/*: any*/),
    (v80/*: any*/),
    (v83/*: any*/),
    (v316/*: any*/)
  ]
},
v422 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 12
  },
  (v318/*: any*/)
],
v423 = {
  "kind": "InlineFragment",
  "selections": (v294/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v424 = [
  (v157/*: any*/),
  (v84/*: any*/)
],
v425 = {
  "kind": "Literal",
  "name": "ignoreMissingField",
  "value": true
},
v426 = [
  (v84/*: any*/),
  (v226/*: any*/),
  (v83/*: any*/),
  (v86/*: any*/)
],
v427 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "security"
        },
        (v425/*: any*/)
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        (v91/*: any*/),
        (v84/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v83/*: any*/),
            {
              "concreteType": "JiraSecurityLevel",
              "kind": "LinkedField",
              "name": "securityLevel",
              "plural": false,
              "selections": (v426/*: any*/)
            },
            {
              "concreteType": "JiraSecurityLevelConnection",
              "kind": "LinkedField",
              "name": "securityLevels",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraSecurityLevelEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraSecurityLevel",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v426/*: any*/)
                    }
                  ]
                }
              ]
            },
            (v97/*: any*/),
            (v102/*: any*/),
            (v103/*: any*/)
          ],
          "type": "JiraSecurityLevelField"
        }
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"security\",ignoreMissingField:true)"
    }
  ]
},
v428 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "watchesField",
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "watches"
        },
        (v425/*: any*/)
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        (v91/*: any*/),
        (v84/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraWatch",
              "kind": "LinkedField",
              "name": "watch",
              "plural": false,
              "selections": [
                (v292/*: any*/),
                (v290/*: any*/),
                (v91/*: any*/)
              ]
            },
            (v103/*: any*/),
            (v102/*: any*/)
          ],
          "type": "JiraWatchesField"
        }
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"watches\",ignoreMissingField:true)"
    }
  ]
},
v429 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "votesField",
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "votes"
        },
        (v425/*: any*/)
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        (v91/*: any*/),
        (v84/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v102/*: any*/),
            (v103/*: any*/),
            {
              "concreteType": "JiraVote",
              "kind": "LinkedField",
              "name": "vote",
              "plural": false,
              "selections": [
                (v290/*: any*/),
                (v289/*: any*/)
              ]
            },
            {
              "concreteType": "JiraIssue",
              "kind": "LinkedField",
              "name": "issue",
              "plural": false,
              "selections": [
                (v375/*: any*/),
                (v84/*: any*/)
              ]
            }
          ],
          "type": "JiraVotesField"
        }
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"votes\",ignoreMissingField:true)"
    }
  ]
},
v430 = [
  {
    "kind": "ScalarField",
    "name": "configurationUrl"
  }
],
v431 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": (v430/*: any*/)
},
v432 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v392/*: any*/),
        (v84/*: any*/)
      ]
    },
    (v84/*: any*/)
  ]
},
v433 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v432/*: any*/),
    {
      "concreteType": "JiraProjectField",
      "kind": "LinkedField",
      "name": "projectField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v80/*: any*/),
            (v205/*: any*/),
            (v84/*: any*/)
          ]
        },
        (v84/*: any*/)
      ]
    },
    {
      "alias": "issueRestriction",
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "issuerestriction"
        }
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        (v91/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraRoleConnection",
              "kind": "LinkedField",
              "name": "selectedRolesConnection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraRoleEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraRole",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v191/*: any*/),
                        (v84/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          "type": "JiraIssueRestrictionField"
        },
        (v84/*: any*/)
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"issuerestriction\")"
    }
  ]
},
v434 = [
  (v83/*: any*/),
  (v389/*: any*/),
  (v84/*: any*/)
],
v435 = [
  {
    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "concreteType": "JiraIssueTypeField",
        "kind": "LinkedField",
        "name": "issueTypeField",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraIssueType",
            "kind": "LinkedField",
            "name": "issueType",
            "plural": false,
            "selections": [
              (v83/*: any*/),
              (v85/*: any*/),
              {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  (v89/*: any*/),
                  (v87/*: any*/)
                ]
              },
              (v392/*: any*/),
              (v84/*: any*/)
            ]
          },
          (v84/*: any*/),
          (v97/*: any*/),
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              (v102/*: any*/),
              (v103/*: any*/),
              (v91/*: any*/),
              {
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  (v79/*: any*/),
                  (v377/*: any*/),
                  (v84/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v84/*: any*/),
                          (v85/*: any*/),
                          (v83/*: any*/),
                          (v389/*: any*/),
                          (v392/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "concreteType": "JiraParentIssueField",
        "kind": "LinkedField",
        "name": "parentIssueField",
        "plural": false,
        "selections": [
          (v84/*: any*/),
          (v102/*: any*/),
          (v103/*: any*/),
          (v83/*: any*/),
          {
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "parentIssue",
            "plural": false,
            "selections": [
              (v84/*: any*/),
              (v79/*: any*/),
              (v80/*: any*/),
              (v391/*: any*/),
              (v118/*: any*/),
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "issueTypeField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": (v434/*: any*/)
                  },
                  (v84/*: any*/)
                ]
              }
            ]
          },
          (v97/*: any*/)
        ]
      },
      {
        "concreteType": "JiraProjectField",
        "kind": "LinkedField",
        "name": "projectField",
        "plural": false,
        "selections": [
          (v83/*: any*/),
          {
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v204/*: any*/),
              (v206/*: any*/),
              (v84/*: any*/),
              (v83/*: any*/),
              (v118/*: any*/),
              (v279/*: any*/)
            ]
          },
          (v84/*: any*/),
          (v97/*: any*/)
        ]
      },
      (v118/*: any*/),
      {
        "args": [
          {
            "kind": "Literal",
            "name": "ids",
            "value": [
              "parent",
              "project"
            ]
          }
        ],
        "concreteType": "JiraIssueFieldConnection",
        "kind": "LinkedField",
        "name": "fieldsById",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraIssueFieldEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v91/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v376/*: any*/)
                    ],
                    "type": "JiraProjectField"
                  },
                  (v84/*: any*/)
                ]
              }
            ]
          }
        ],
        "storageKey": "fieldsById(ids:[\"parent\",\"project\"])"
      },
      (v391/*: any*/)
    ]
  }
],
v436 = [
  (v91/*: any*/),
  (v83/*: any*/),
  (v132/*: any*/),
  (v133/*: any*/),
  (v84/*: any*/)
],
v437 = {
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": (v436/*: any*/)
},
v438 = {
  "kind": "LinkedField",
  "name": "updateAuthor",
  "plural": false,
  "selections": (v436/*: any*/)
},
v439 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v440 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v441 = {
  "concreteType": "QueryError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v442 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v443 = [
  (v301/*: any*/)
],
v444 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v103/*: any*/),
          (v440/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v91/*: any*/),
                      (v102/*: any*/),
                      (v103/*: any*/),
                      (v360/*: any*/),
                      (v84/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v401/*: any*/)
                        ],
                        "type": "JiraAffectedServicesField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraCmdbObjectConnection",
                            "kind": "LinkedField",
                            "name": "selectedCmdbObjectsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraCmdbObjectEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraCmdbObject",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v115/*: any*/),
                                      (v113/*: any*/),
                                      {
                                        "concreteType": "JiraCmdbAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraCmdbMediaClientConfig",
                                                "kind": "LinkedField",
                                                "name": "mediaClientConfig",
                                                "plural": false,
                                                "selections": [
                                                  (v119/*: any*/),
                                                  (v120/*: any*/),
                                                  (v121/*: any*/),
                                                  (v122/*: any*/)
                                                ]
                                              }
                                            ]
                                          },
                                          (v124/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v441/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraCMDBField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraComponentConnection",
                            "kind": "LinkedField",
                            "name": "selectedComponentsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraComponentEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraComponent",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v403/*: any*/)
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraComponentsField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v171/*: any*/),
                          (v83/*: any*/),
                          (v99/*: any*/),
                          (v442/*: any*/)
                        ],
                        "type": "JiraDatePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v157/*: any*/),
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              (v83/*: any*/),
                              (v442/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraDateTimePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraEpic",
                            "kind": "LinkedField",
                            "name": "epic",
                            "plural": false,
                            "selections": [
                              (v80/*: any*/),
                              (v172/*: any*/),
                              (v173/*: any*/),
                              (v84/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraEpicLinkField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "renderedFieldHtml"
                          }
                        ],
                        "type": "JiraFallbackField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v169/*: any*/),
                          (v83/*: any*/),
                          (v442/*: any*/)
                        ],
                        "type": "JiraSingleLineTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v83/*: any*/),
                              (v276/*: any*/),
                              (v84/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v420/*: any*/)
                        ],
                        "type": "JiraStatusField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v311/*: any*/),
                            "concreteType": "JiraLabelConnection",
                            "kind": "LinkedField",
                            "name": "selectedLabelsConnection",
                            "plural": false,
                            "selections": [
                              (v382/*: any*/),
                              (v301/*: any*/)
                            ],
                            "storageKey": "selectedLabelsConnection(first:1000)"
                          },
                          (v83/*: any*/),
                          (v442/*: any*/)
                        ],
                        "type": "JiraLabelsField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  (v88/*: any*/)
                                ]
                              },
                              (v83/*: any*/),
                              (v84/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "parentIssue",
                            "plural": false,
                            "selections": [
                              (v80/*: any*/),
                              (v391/*: any*/),
                              (v84/*: any*/)
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v388/*: any*/),
                                  (v390/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v388/*: any*/),
                                  (v390/*: any*/),
                                  (v79/*: any*/),
                                  (v118/*: any*/),
                                  (v394/*: any*/)
                                ]
                              },
                              (v83/*: any*/),
                              (v442/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraParentIssueField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v386/*: any*/),
                          (v83/*: any*/),
                          (v442/*: any*/)
                        ],
                        "type": "JiraPriorityField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v83/*: any*/),
                              (v80/*: any*/),
                              (v136/*: any*/),
                              (v84/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraProjectField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraResolution",
                            "kind": "LinkedField",
                            "name": "resolution",
                            "plural": false,
                            "selections": (v403/*: any*/)
                          }
                        ],
                        "type": "JiraResolutionField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v387/*: any*/),
                          (v83/*: any*/),
                          (v442/*: any*/)
                        ],
                        "type": "JiraSprintField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v91/*: any*/),
                              (v132/*: any*/),
                              (v133/*: any*/),
                              (v83/*: any*/),
                              (v84/*: any*/),
                              (v182/*: any*/)
                            ]
                          },
                          (v83/*: any*/),
                          (v99/*: any*/),
                          (v442/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v83/*: any*/),
                          {
                            "concreteType": "JiraVersionConnection",
                            "kind": "LinkedField",
                            "name": "selectedVersionsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraVersionEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraVersion",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v403/*: any*/)
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraMultipleVersionPickerField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraCommentSummary",
                                "kind": "LinkedField",
                                "name": "commentSummary",
                                "plural": false,
                                "selections": (v443/*: any*/)
                              }
                            ],
                            "type": "JiraCommentSummaryField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v83/*: any*/),
                              (v288/*: any*/),
                              (v442/*: any*/)
                            ],
                            "type": "JiraUrlField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v83/*: any*/),
                              (v384/*: any*/),
                              (v361/*: any*/),
                              (v442/*: any*/)
                            ],
                            "type": "JiraSingleSelectField"
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraRichText",
                            "kind": "LinkedField",
                            "name": "richText",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraADF",
                                "kind": "LinkedField",
                                "name": "adfValue",
                                "plural": false,
                                "selections": [
                                  (v365/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraRichTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraServiceManagementRequestType",
                            "kind": "LinkedField",
                            "name": "requestType",
                            "plural": false,
                            "selections": (v434/*: any*/)
                          }
                        ],
                        "type": "JiraServiceManagementRequestTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "first",
                                "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
                              }
                            ],
                            "concreteType": "JiraIssueLinkConnection",
                            "kind": "LinkedField",
                            "name": "issueLinkConnection",
                            "plural": false,
                            "selections": [
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                      (v330/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": (v443/*: any*/)
                              },
                              {
                                "concreteType": "JiraIssueLinkEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueLink",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          (v118/*: any*/),
                                          (v80/*: any*/),
                                          {
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "ids",
                                                "value": [
                                                  "statusCategory"
                                                ]
                                              }
                                            ],
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fieldsById",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v91/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v373/*: any*/)
                                                        ],
                                                        "type": "JiraStatusCategoryField"
                                                      },
                                                      (v84/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                          },
                                          (v84/*: any*/)
                                        ]
                                      },
                                      (v314/*: any*/),
                                      (v84/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraIssueLinkField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v197/*: any*/),
                              (v160/*: any*/),
                              (v83/*: any*/),
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  (v160/*: any*/),
                                  (v83/*: any*/),
                                  (v442/*: any*/)
                                ]
                              },
                              (v442/*: any*/)
                            ],
                            "type": "JiraNumberField"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v445 = [
  (v328/*: any*/),
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  {
    "kind": "ScalarField",
    "name": "isCurrent"
  }
],
v446 = {
  "kind": "ScalarField",
  "name": "isAvailableToUser"
},
v447 = [
  (v91/*: any*/),
  (v446/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v23/*: any*/),
      (v24/*: any*/),
      (v25/*: any*/),
      (v26/*: any*/),
      (v27/*: any*/),
      (v28/*: any*/),
      (v29/*: any*/),
      (v30/*: any*/),
      (v31/*: any*/),
      (v32/*: any*/),
      (v33/*: any*/),
      (v34/*: any*/),
      (v35/*: any*/),
      (v36/*: any*/),
      (v37/*: any*/),
      (v38/*: any*/),
      (v39/*: any*/),
      (v40/*: any*/),
      (v41/*: any*/),
      (v42/*: any*/),
      (v43/*: any*/),
      (v44/*: any*/),
      (v45/*: any*/),
      (v46/*: any*/),
      (v47/*: any*/),
      (v48/*: any*/),
      (v49/*: any*/),
      (v50/*: any*/),
      (v51/*: any*/),
      (v52/*: any*/),
      (v53/*: any*/),
      (v54/*: any*/),
      (v55/*: any*/),
      (v56/*: any*/),
      (v57/*: any*/),
      (v58/*: any*/),
      (v59/*: any*/)
    ],
    "kind": "Fragment",
    "name": "mainIssueAggQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            (v62/*: any*/),
            (v63/*: any*/),
            (v64/*: any*/),
            (v70/*: any*/),
            (v72/*: any*/),
            {
              "args": (v61/*: any*/),
              "concreteType": "JiraIssueLinkTypeConnection",
              "kind": "LinkedField",
              "name": "issueLinkTypes",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueLinkTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraIssueLinkType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v73/*: any*/),
                        (v74/*: any*/),
                        (v75/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "args": (v76/*: any*/),
              "concreteType": "JiraIssue",
              "kind": "LinkedField",
              "name": "issueByKey",
              "plural": false,
              "selections": [
                (v77/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": (v78/*: any*/),
                  "action": "LOG",
                  "path": "jira.issueByKey.errorRetrievingData"
                },
                (v79/*: any*/),
                (v80/*: any*/),
                (v81/*: any*/),
                {
                  "concreteType": "JiraIssueTypeHierarchyLevel",
                  "kind": "LinkedField",
                  "name": "hierarchyLevelAbove",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v82/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelAbove.level"
                    },
                    {
                      "kind": "RequiredField",
                      "field": (v83/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelAbove.name"
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeHierarchyLevel",
                  "kind": "LinkedField",
                  "name": "hierarchyLevelBelow",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v82/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelBelow.level"
                    },
                    {
                      "kind": "RequiredField",
                      "field": (v83/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelBelow.name"
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeConnection",
                  "kind": "LinkedField",
                  "name": "issueTypesForHierarchyAbove",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v84/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v85/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.issueTypeId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v83/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.name"
                            },
                            (v86/*: any*/),
                            {
                              "concreteType": "JiraAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                (v87/*: any*/),
                                (v88/*: any*/),
                                {
                                  "kind": "RequiredField",
                                  "field": (v89/*: any*/),
                                  "action": "LOG",
                                  "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.avatar.medium"
                                },
                                (v90/*: any*/)
                              ]
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                "kind": "LinkedField",
                                "name": "hierarchy",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v82/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.hierarchy.level"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v83/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.hierarchy.name"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.hierarchy"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeConnection",
                  "kind": "LinkedField",
                  "name": "issueTypesForHierarchyBelow",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v84/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v85/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.issueTypeId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v83/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.name"
                            },
                            (v86/*: any*/),
                            {
                              "concreteType": "JiraAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                (v87/*: any*/),
                                (v88/*: any*/),
                                {
                                  "kind": "RequiredField",
                                  "field": (v89/*: any*/),
                                  "action": "LOG",
                                  "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.avatar.medium"
                                },
                                (v90/*: any*/)
                              ]
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                "kind": "LinkedField",
                                "name": "hierarchy",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v82/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.hierarchy.level"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v83/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.hierarchy.name"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.hierarchy"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeConnection",
                  "kind": "LinkedField",
                  "name": "issueTypesForHierarchySame",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v84/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v85/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.issueTypeId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v83/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.name"
                            },
                            (v86/*: any*/),
                            {
                              "concreteType": "JiraAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                (v87/*: any*/),
                                (v88/*: any*/),
                                {
                                  "kind": "RequiredField",
                                  "field": (v89/*: any*/),
                                  "action": "LOG",
                                  "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.avatar.medium"
                                },
                                (v90/*: any*/)
                              ]
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                "kind": "LinkedField",
                                "name": "hierarchy",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v82/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.hierarchy.level"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v83/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.hierarchy.name"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.hierarchy"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v91/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "kind": "FragmentSpread",
                                  "name": "editViewPopup_issueClassificationBadge_ClassificationEditViewPopup"
                                },
                                (v84/*: any*/),
                                (v93/*: any*/),
                                {
                                  "concreteType": "JiraClassificationLevel",
                                  "kind": "LinkedField",
                                  "name": "classificationLevel",
                                  "plural": false,
                                  "selections": [
                                    (v83/*: any*/),
                                    (v94/*: any*/),
                                    (v96/*: any*/)
                                  ]
                                }
                              ],
                              "type": "JiraDataClassificationField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraAffectedServiceConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedAffectedServicesConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraAffectedServiceEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraAffectedService",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "RequiredField",
                                              "field": (v104/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedAffectedServicesConnection.edges.node.serviceId"
                                            },
                                            (v83/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v105/*: any*/)
                              ],
                              "type": "JiraAffectedServicesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v107/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraAssetField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v106/*: any*/)
                              ],
                              "type": "JiraBooleanField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v105/*: any*/),
                                (v108/*: any*/),
                                (v109/*: any*/),
                                (v112/*: any*/),
                                {
                                  "concreteType": "JiraCmdbObjectConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedCmdbObjectsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraCmdbObjectEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraCmdbObject",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v113/*: any*/),
                                            (v114/*: any*/),
                                            (v115/*: any*/),
                                            (v116/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v117/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.workspaceId"
                                            },
                                            (v118/*: any*/),
                                            {
                                              "concreteType": "JiraCmdbAvatar",
                                              "kind": "LinkedField",
                                              "name": "avatar",
                                              "plural": false,
                                              "selections": [
                                                (v123/*: any*/),
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v124/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.avatar.url48"
                                                }
                                              ]
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": {
                                                "concreteType": "JiraCmdbObjectType",
                                                "kind": "LinkedField",
                                                "name": "objectType",
                                                "plural": false,
                                                "selections": [
                                                  (v125/*: any*/),
                                                  (v83/*: any*/),
                                                  (v111/*: any*/),
                                                  {
                                                    "kind": "RequiredField",
                                                    "field": (v126/*: any*/),
                                                    "action": "LOG",
                                                    "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.objectType.icon"
                                                  }
                                                ]
                                              },
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.objectType"
                                            },
                                            {
                                              "concreteType": "JiraCmdbAttributeConnection",
                                              "kind": "LinkedField",
                                              "name": "attributes",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "concreteType": "JiraCmdbAttributeEdge",
                                                  "kind": "LinkedField",
                                                  "name": "edges",
                                                  "plural": true,
                                                  "selections": [
                                                    {
                                                      "concreteType": "JiraCmdbAttribute",
                                                      "kind": "LinkedField",
                                                      "name": "node",
                                                      "plural": false,
                                                      "selections": [
                                                        (v127/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v129/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.attributes.edges.node.objectTypeAttribute"
                                                        },
                                                        {
                                                          "concreteType": "JiraCmdbObjectAttributeValueConnection",
                                                          "kind": "LinkedField",
                                                          "name": "objectAttributeValues",
                                                          "plural": false,
                                                          "selections": [
                                                            {
                                                              "concreteType": "JiraCmdbObjectAttributeValueEdge",
                                                              "kind": "LinkedField",
                                                              "name": "edges",
                                                              "plural": true,
                                                              "selections": [
                                                                {
                                                                  "concreteType": "JiraCmdbObjectAttributeValue",
                                                                  "kind": "LinkedField",
                                                                  "name": "node",
                                                                  "plural": false,
                                                                  "selections": [
                                                                    (v130/*: any*/),
                                                                    (v131/*: any*/),
                                                                    (v106/*: any*/),
                                                                    {
                                                                      "kind": "LinkedField",
                                                                      "name": "user",
                                                                      "plural": false,
                                                                      "selections": [
                                                                        (v132/*: any*/),
                                                                        (v83/*: any*/),
                                                                        (v133/*: any*/)
                                                                      ]
                                                                    },
                                                                    (v134/*: any*/),
                                                                    {
                                                                      "concreteType": "JiraGroup",
                                                                      "kind": "LinkedField",
                                                                      "name": "group",
                                                                      "plural": false,
                                                                      "selections": (v135/*: any*/)
                                                                    },
                                                                    (v137/*: any*/),
                                                                    (v139/*: any*/),
                                                                    (v140/*: any*/),
                                                                    (v142/*: any*/)
                                                                  ]
                                                                }
                                                              ]
                                                            }
                                                          ]
                                                        }
                                                      ]
                                                    }
                                                  ]
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraCMDBField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v146/*: any*/)
                              ],
                              "type": "JiraCascadingSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v148/*: any*/),
                                (v149/*: any*/)
                              ],
                              "type": "JiraCheckboxesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v150/*: any*/)
                              ],
                              "type": "JiraColorField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v155/*: any*/),
                                (v156/*: any*/)
                              ],
                              "type": "JiraComponentsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v158/*: any*/),
                              "type": "JiraConnectDateTimeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v148/*: any*/),
                                (v159/*: any*/)
                              ],
                              "type": "JiraConnectMultipleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v160/*: any*/)
                              ],
                              "type": "JiraConnectNumberField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v166/*: any*/),
                              "type": "JiraConnectRichTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v167/*: any*/),
                                (v168/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraConnectSingleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v170/*: any*/),
                              "type": "JiraConnectTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v171/*: any*/)
                              ],
                              "type": "JiraDatePickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v158/*: any*/),
                              "type": "JiraDateTimePickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v174/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraEpicLinkField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v175/*: any*/)
                              ],
                              "type": "JiraFlagField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v178/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraForgeGroupField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v179/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraForgeGroupsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v160/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraForgeNumberField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v180/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraForgeObjectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v169/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraForgeStringField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v181/*: any*/),
                                (v165/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraForgeStringsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": (v189/*: any*/)
                                },
                                (v165/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraForgeUserField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v190/*: any*/),
                                (v165/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraForgeUsersField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v192/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraIssueRestrictionField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraIssueType",
                                  "kind": "LinkedField",
                                  "name": "issueType",
                                  "plural": false,
                                  "selections": [
                                    (v84/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v85/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.issueType.issueTypeId"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v83/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.issueType.name"
                                    },
                                    (v86/*: any*/),
                                    {
                                      "concreteType": "JiraAvatar",
                                      "kind": "LinkedField",
                                      "name": "avatar",
                                      "plural": false,
                                      "selections": [
                                        (v87/*: any*/),
                                        (v88/*: any*/),
                                        {
                                          "kind": "RequiredField",
                                          "field": (v89/*: any*/),
                                          "action": "LOG",
                                          "path": "jira.issueByKey.fields.edges.node.issueType.avatar.medium"
                                        },
                                        (v90/*: any*/)
                                      ]
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JiraIssueTypeHierarchyLevel",
                                        "kind": "LinkedField",
                                        "name": "hierarchy",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v82/*: any*/),
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.issueType.hierarchy.level"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": (v83/*: any*/),
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.issueType.hierarchy.name"
                                          }
                                        ]
                                      },
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.issueType.hierarchy"
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraIssueTypeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v181/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraLabelsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraGroupConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedGroupsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraGroupEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraGroup",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v176/*: any*/),
                                            (v83/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v105/*: any*/)
                              ],
                              "type": "JiraMultipleGroupPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v148/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraMultipleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v193/*: any*/),
                              "type": "JiraMultipleSelectUserPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v196/*: any*/)
                              ],
                              "type": "JiraMultipleVersionPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v160/*: any*/),
                                (v197/*: any*/)
                              ],
                              "type": "JiraNumberField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v97/*: any*/),
                                    (v199/*: any*/),
                                    (v84/*: any*/)
                                  ],
                                  "type": "JiraIssueField",
                                  "abstractKey": "__isJiraIssueField"
                                },
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v201/*: any*/)
                              ],
                              "type": "JiraOriginalTimeEstimateField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v202/*: any*/),
                                {
                                  "concreteType": "JiraIssue",
                                  "kind": "LinkedField",
                                  "name": "parentIssue",
                                  "plural": false,
                                  "selections": [
                                    (v84/*: any*/),
                                    (v79/*: any*/),
                                    (v80/*: any*/),
                                    (v118/*: any*/),
                                    {
                                      "concreteType": "JiraColorField",
                                      "kind": "LinkedField",
                                      "name": "issueColorField",
                                      "plural": false,
                                      "selections": [
                                        (v96/*: any*/)
                                      ]
                                    },
                                    {
                                      "args": (v203/*: any*/),
                                      "concreteType": "JiraIssueFieldConnection",
                                      "kind": "LinkedField",
                                      "name": "fieldsById",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraIssueFieldEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v91/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v102/*: any*/),
                                                    (v83/*: any*/),
                                                    (v103/*: any*/),
                                                    (v86/*: any*/),
                                                    {
                                                      "concreteType": "JiraIssueType",
                                                      "kind": "LinkedField",
                                                      "name": "issueType",
                                                      "plural": false,
                                                      "selections": [
                                                        (v84/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v85/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.issueTypeId"
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v83/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.name"
                                                        },
                                                        (v86/*: any*/),
                                                        {
                                                          "concreteType": "JiraAvatar",
                                                          "kind": "LinkedField",
                                                          "name": "avatar",
                                                          "plural": false,
                                                          "selections": [
                                                            (v87/*: any*/),
                                                            (v88/*: any*/),
                                                            {
                                                              "kind": "RequiredField",
                                                              "field": (v89/*: any*/),
                                                              "action": "LOG",
                                                              "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.avatar.medium"
                                                            },
                                                            (v90/*: any*/)
                                                          ]
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraIssueTypeHierarchyLevel",
                                                            "kind": "LinkedField",
                                                            "name": "hierarchy",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v82/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.hierarchy.level"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v83/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.hierarchy.name"
                                                              }
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.hierarchy"
                                                        }
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraIssueTypeField"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v102/*: any*/),
                                                    (v83/*: any*/),
                                                    (v103/*: any*/),
                                                    (v86/*: any*/),
                                                    (v105/*: any*/),
                                                    {
                                                      "concreteType": "JiraProject",
                                                      "kind": "LinkedField",
                                                      "name": "project",
                                                      "plural": false,
                                                      "selections": [
                                                        (v80/*: any*/),
                                                        (v83/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v204/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.project.projectId"
                                                        },
                                                        (v205/*: any*/),
                                                        (v206/*: any*/),
                                                        (v207/*: any*/),
                                                        (v195/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraAvatar",
                                                            "kind": "LinkedField",
                                                            "name": "avatar",
                                                            "plural": false,
                                                            "selections": [
                                                              (v89/*: any*/),
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v90/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.project.avatar.large"
                                                              }
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.project.avatar"
                                                        },
                                                        (v208/*: any*/),
                                                        (v210/*: any*/)
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraProjectField"
                                                },
                                                (v211/*: any*/)
                                              ]
                                            }
                                          ]
                                        }
                                      ],
                                      "storageKey": "fieldsById(ids:[\"issuetype\",\"project\",\"summary\"])"
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraParentIssueField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v190/*: any*/),
                                (v212/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraPeopleField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraPriority",
                                  "kind": "LinkedField",
                                  "name": "priority",
                                  "plural": false,
                                  "selections": (v216/*: any*/)
                                },
                                {
                                  "concreteType": "JiraPriorityConnection",
                                  "kind": "LinkedField",
                                  "name": "priorities",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraPriorityEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraPriority",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": (v216/*: any*/)
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraPriorityField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v217/*: any*/)
                              ],
                              "type": "JiraProformaFormsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v105/*: any*/),
                                {
                                  "concreteType": "JiraProject",
                                  "kind": "LinkedField",
                                  "name": "project",
                                  "plural": false,
                                  "selections": [
                                    (v80/*: any*/),
                                    (v83/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v204/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.project.projectId"
                                    },
                                    (v205/*: any*/),
                                    (v206/*: any*/),
                                    (v207/*: any*/),
                                    (v195/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JiraAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          (v89/*: any*/),
                                          {
                                            "kind": "RequiredField",
                                            "field": (v90/*: any*/),
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.project.avatar.large"
                                          }
                                        ]
                                      },
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.project.avatar"
                                    },
                                    (v219/*: any*/),
                                    (v208/*: any*/),
                                    (v210/*: any*/)
                                  ]
                                }
                              ],
                              "type": "JiraProjectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v220/*: any*/),
                                (v221/*: any*/)
                              ],
                              "type": "JiraRadioSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v224/*: any*/),
                                (v225/*: any*/)
                              ],
                              "type": "JiraResolutionField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v166/*: any*/),
                              "type": "JiraRichTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraSecurityLevel",
                                  "kind": "LinkedField",
                                  "name": "securityLevel",
                                  "plural": false,
                                  "selections": (v227/*: any*/)
                                },
                                {
                                  "concreteType": "JiraSecurityLevelConnection",
                                  "kind": "LinkedField",
                                  "name": "securityLevels",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraSecurityLevelEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraSecurityLevel",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": (v227/*: any*/)
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraSecurityLevelField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraServiceManagementActiveApproval",
                                  "kind": "LinkedField",
                                  "name": "activeApproval",
                                  "plural": false,
                                  "selections": [
                                    (v84/*: any*/),
                                    (v228/*: any*/),
                                    (v230/*: any*/),
                                    {
                                      "concreteType": "JiraServiceManagementApproverPrincipalConnection",
                                      "kind": "LinkedField",
                                      "name": "approverPrincipals",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementApproverPrincipalEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v91/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "kind": "LinkedField",
                                                        "name": "user",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v132/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user.accountId"
                                                          },
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v83/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user.name"
                                                          },
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v133/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user.picture"
                                                          }
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v231/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.jiraRest"
                                                    }
                                                  ],
                                                  "type": "JiraServiceManagementUserApproverPrincipal"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v176/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.groupId"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v83/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.name"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v232/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.memberCount"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v233/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.approvedCount"
                                                    }
                                                  ],
                                                  "type": "JiraServiceManagementGroupApproverPrincipal"
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "concreteType": "JiraServiceManagementApproverConnection",
                                      "kind": "LinkedField",
                                      "name": "approvers",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementApproverEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "concreteType": "JiraServiceManagementApprover",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": {
                                                    "kind": "LinkedField",
                                                    "name": "approver",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v132/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.accountId"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v182/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.accountStatus"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v83/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.name"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v133/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.picture"
                                                      },
                                                      (v235/*: any*/),
                                                      (v236/*: any*/)
                                                    ]
                                                  },
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v237/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approverDecision"
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v238/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.canAnswerApproval"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JiraServiceManagementApprovalConfiguration",
                                        "kind": "LinkedField",
                                        "name": "configurations",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": {
                                              "concreteType": "JiraServiceManagementApproversConfiguration",
                                              "kind": "LinkedField",
                                              "name": "approversConfigurations",
                                              "plural": true,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v103/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations.type"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v239/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations.fieldName"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v102/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations.fieldId"
                                                }
                                              ]
                                            },
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": {
                                              "concreteType": "JiraServiceManagementApprovalCondition",
                                              "kind": "LinkedField",
                                              "name": "condition",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v103/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.condition.type"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v106/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.condition.value"
                                                }
                                              ]
                                            },
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.condition"
                                          }
                                        ]
                                      },
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations"
                                    },
                                    (v240/*: any*/),
                                    {
                                      "concreteType": "JiraServiceManagementDecisionConnection",
                                      "kind": "LinkedField",
                                      "name": "decisions",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementDecisionEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "concreteType": "JiraServiceManagementDecision",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": {
                                                    "kind": "LinkedField",
                                                    "name": "approver",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v132/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.accountId"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v182/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.accountStatus"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v83/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.name"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v133/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.picture"
                                                      },
                                                      (v235/*: any*/),
                                                      (v236/*: any*/)
                                                    ]
                                                  },
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v237/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approverDecision"
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "concreteType": "JiraUserConnection",
                                      "kind": "LinkedField",
                                      "name": "excludedApprovers",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraUserEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v132/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.accountId"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v182/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.accountStatus"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v83/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.name"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v133/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.picture"
                                                },
                                                (v235/*: any*/),
                                                (v236/*: any*/)
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v241/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.finalDecision"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v83/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v242/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.pendingApprovalCount"
                                    },
                                    (v243/*: any*/)
                                  ]
                                },
                                {
                                  "concreteType": "JiraServiceManagementCompletedApprovalConnection",
                                  "kind": "LinkedField",
                                  "name": "completedApprovalsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraServiceManagementCompletedApprovalEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementCompletedApproval",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v84/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v83/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.name"
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": (v241/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.finalDecision"
                                            },
                                            {
                                              "concreteType": "JiraServiceManagementApproverConnection",
                                              "kind": "LinkedField",
                                              "name": "approvers",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "concreteType": "JiraServiceManagementApproverEdge",
                                                  "kind": "LinkedField",
                                                  "name": "edges",
                                                  "plural": true,
                                                  "selections": [
                                                    {
                                                      "concreteType": "JiraServiceManagementApprover",
                                                      "kind": "LinkedField",
                                                      "name": "node",
                                                      "plural": false,
                                                      "selections": [
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "kind": "LinkedField",
                                                            "name": "approver",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v132/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.accountId"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v182/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.accountStatus"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v83/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.name"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v133/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.picture"
                                                              },
                                                              (v235/*: any*/),
                                                              (v236/*: any*/)
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver"
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v237/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approverDecision"
                                                        }
                                                      ]
                                                    }
                                                  ]
                                                }
                                              ]
                                            },
                                            (v240/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v244/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.completedDate"
                                            },
                                            (v245/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraServiceManagementApprovalField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v246/*: any*/)
                              ],
                              "type": "JiraServiceManagementMajorIncidentField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraServiceManagementOrganizationConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedOrganizationsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraServiceManagementOrganizationEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementOrganization",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v247/*: any*/),
                                            (v248/*: any*/),
                                            (v249/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v105/*: any*/)
                              ],
                              "type": "JiraServiceManagementOrganizationField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v193/*: any*/),
                              "type": "JiraServiceManagementPeopleField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v250/*: any*/)
                              ],
                              "type": "JiraServiceManagementRequestFeedbackField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v253/*: any*/),
                                (v254/*: any*/)
                              ],
                              "type": "JiraServiceManagementRequestLanguageField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v257/*: any*/)
                              ],
                              "type": "JiraServiceManagementRequestTypeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v105/*: any*/),
                                {
                                  "concreteType": "JiraServiceManagementResponderConnection",
                                  "kind": "LinkedField",
                                  "name": "respondersConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraServiceManagementResponderEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v91/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                {
                                                  "kind": "LinkedField",
                                                  "name": "user",
                                                  "plural": false,
                                                  "selections": [
                                                    (v258/*: any*/),
                                                    (v133/*: any*/),
                                                    (v83/*: any*/)
                                                  ]
                                                }
                                              ],
                                              "type": "JiraServiceManagementUserResponder"
                                            },
                                            (v260/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraServiceManagementRespondersField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v178/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraSingleGroupPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v170/*: any*/),
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v167/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraSingleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v264/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v267/*: any*/),
                                (v268/*: any*/)
                              ],
                              "type": "JiraSingleVersionPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraSprintConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedSprintsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraSprintEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraSprint",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v84/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v269/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedSprintsConnection.edges.node.sprintId"
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": (v83/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedSprintsConnection.edges.node.name"
                                            },
                                            (v270/*: any*/),
                                            (v271/*: any*/),
                                            (v265/*: any*/),
                                            (v272/*: any*/),
                                            (v273/*: any*/),
                                            (v274/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v105/*: any*/)
                              ],
                              "type": "JiraSprintField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraStatus",
                                  "kind": "LinkedField",
                                  "name": "status",
                                  "plural": false,
                                  "selections": [
                                    (v84/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v83/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.status.name"
                                    },
                                    (v86/*: any*/),
                                    (v275/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v277/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.status.statusCategory"
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraStatusField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                {
                                  "concreteType": "JiraTeam",
                                  "kind": "LinkedField",
                                  "name": "selectedTeam",
                                  "plural": false,
                                  "selections": [
                                    (v259/*: any*/),
                                    (v83/*: any*/),
                                    (v278/*: any*/),
                                    (v279/*: any*/)
                                  ]
                                }
                              ],
                              "type": "JiraTeamField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v285/*: any*/),
                                (v105/*: any*/)
                              ],
                              "type": "JiraTeamViewField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v201/*: any*/),
                                (v286/*: any*/),
                                (v287/*: any*/)
                              ],
                              "type": "JiraTimeTrackingField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v288/*: any*/)
                              ],
                              "type": "JiraUrlField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v291/*: any*/)
                              ],
                              "type": "JiraVotesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v293/*: any*/)
                              ],
                              "type": "JiraWatchesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v157/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraForgeDatetimeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v101/*: any*/),
                                (v171/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v86/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraForgeDateField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v84/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v102/*: any*/),
                                (v295/*: any*/),
                                (v296/*: any*/),
                                (v298/*: any*/)
                              ],
                              "type": "JiraServiceManagementEntitlementField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v84/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v102/*: any*/),
                                (v86/*: any*/),
                                (v299/*: any*/),
                                (v296/*: any*/),
                                (v298/*: any*/)
                              ],
                              "type": "JiraServiceManagementSentimentField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v103/*: any*/),
                                (v102/*: any*/),
                                (v83/*: any*/),
                                (v86/*: any*/),
                                (v302/*: any*/),
                                (v101/*: any*/)
                              ],
                              "type": "JiraGoalsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v84/*: any*/),
                                (v83/*: any*/),
                                (v103/*: any*/),
                                (v102/*: any*/),
                                {
                                  "concreteType": "JiraServiceManagementOrganization",
                                  "kind": "LinkedField",
                                  "name": "selectedOrganization",
                                  "plural": false,
                                  "selections": [
                                    (v247/*: any*/),
                                    (v248/*: any*/)
                                  ]
                                },
                                (v105/*: any*/),
                                (v296/*: any*/),
                                (v298/*: any*/)
                              ],
                              "type": "JiraCustomerServiceOrganizationField"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "name": "childIssues",
                  "plural": false,
                  "selections": [
                    (v91/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "args": (v303/*: any*/),
                          "concreteType": "JiraIssueConnection",
                          "kind": "LinkedField",
                          "name": "issues",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "JiraIssueEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "concreteType": "JiraIssue",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v80/*: any*/),
                                    (v79/*: any*/),
                                    (v118/*: any*/),
                                    (v304/*: any*/),
                                    {
                                      "concreteType": "JiraNumberField",
                                      "kind": "LinkedField",
                                      "name": "storyPointEstimateField",
                                      "plural": false,
                                      "selections": (v305/*: any*/)
                                    },
                                    {
                                      "concreteType": "JiraNumberField",
                                      "kind": "LinkedField",
                                      "name": "storyPointsField",
                                      "plural": false,
                                      "selections": (v305/*: any*/)
                                    },
                                    {
                                      "args": (v306/*: any*/),
                                      "concreteType": "JiraIssueFieldConnection",
                                      "kind": "LinkedField",
                                      "name": "fieldsById",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraIssueFieldEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v91/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v102/*: any*/),
                                                    (v103/*: any*/),
                                                    {
                                                      "concreteType": "JiraIssueType",
                                                      "kind": "LinkedField",
                                                      "name": "issueType",
                                                      "plural": false,
                                                      "selections": [
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v85/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.issueTypeId"
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v83/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.name"
                                                        },
                                                        {
                                                          "concreteType": "JiraAvatar",
                                                          "kind": "LinkedField",
                                                          "name": "avatar",
                                                          "plural": false,
                                                          "selections": [
                                                            {
                                                              "kind": "RequiredField",
                                                              "field": (v89/*: any*/),
                                                              "action": "LOG",
                                                              "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.avatar.medium"
                                                            }
                                                          ]
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraIssueTypeHierarchyLevel",
                                                            "kind": "LinkedField",
                                                            "name": "hierarchy",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v82/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.hierarchy.level"
                                                              }
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.hierarchy"
                                                        }
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraIssueTypeField"
                                                },
                                                (v307/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v102/*: any*/),
                                                    (v103/*: any*/),
                                                    {
                                                      "concreteType": "JiraPriority",
                                                      "kind": "LinkedField",
                                                      "name": "priority",
                                                      "plural": false,
                                                      "selections": [
                                                        (v213/*: any*/),
                                                        (v83/*: any*/),
                                                        (v215/*: any*/),
                                                        (v214/*: any*/)
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraPriorityField"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v102/*: any*/),
                                                    (v103/*: any*/),
                                                    {
                                                      "concreteType": "JiraStatus",
                                                      "kind": "LinkedField",
                                                      "name": "status",
                                                      "plural": false,
                                                      "selections": [
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v83/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.status.name"
                                                        },
                                                        (v275/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraStatusCategory",
                                                            "kind": "LinkedField",
                                                            "name": "statusCategory",
                                                            "plural": false,
                                                            "selections": [
                                                              (v276/*: any*/)
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.status.statusCategory"
                                                        }
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraStatusField"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v102/*: any*/),
                                                    (v103/*: any*/),
                                                    {
                                                      "kind": "LinkedField",
                                                      "name": "user",
                                                      "plural": false,
                                                      "selections": [
                                                        (v91/*: any*/),
                                                        (v132/*: any*/),
                                                        (v182/*: any*/),
                                                        (v83/*: any*/),
                                                        (v133/*: any*/)
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraSingleSelectUserPickerField"
                                                },
                                                (v308/*: any*/),
                                                (v309/*: any*/)
                                              ]
                                            }
                                          ]
                                        }
                                      ],
                                      "storageKey": "fieldsById(ids:[\"assignee\",\"created\",\"issuetype\",\"priority\",\"status\",\"summary\",\"timetracking\",\"updated\"])"
                                    }
                                  ]
                                }
                              ]
                            }
                          ],
                          "storageKey": "issues(activeProjectsOnly:true)"
                        }
                      ],
                      "type": "JiraChildIssuesWithinLimit"
                    },
                    (v310/*: any*/)
                  ]
                },
                {
                  "args": (v311/*: any*/),
                  "concreteType": "JiraIssueLinkConnection",
                  "kind": "LinkedField",
                  "name": "issueLinks",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueLinkEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueLink",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v84/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v312/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.issueLinkId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v313/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.direction"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v314/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.relationName"
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueLinkType",
                                "kind": "LinkedField",
                                "name": "type",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v73/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueLinks.edges.node.type.linkTypeId"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.type"
                            },
                            {
                              "concreteType": "JiraIssue",
                              "kind": "LinkedField",
                              "name": "issue",
                              "plural": false,
                              "selections": [
                                (v84/*: any*/),
                                (v79/*: any*/),
                                (v80/*: any*/),
                                (v118/*: any*/),
                                (v304/*: any*/),
                                {
                                  "args": (v315/*: any*/),
                                  "concreteType": "JiraIssueFieldConnection",
                                  "kind": "LinkedField",
                                  "name": "fieldsById",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraIssueFieldEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v91/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v102/*: any*/),
                                                (v83/*: any*/),
                                                (v103/*: any*/),
                                                {
                                                  "concreteType": "JiraStatus",
                                                  "kind": "LinkedField",
                                                  "name": "status",
                                                  "plural": false,
                                                  "selections": [
                                                    (v84/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v83/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.status.name"
                                                    },
                                                    (v275/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "concreteType": "JiraStatusCategory",
                                                        "kind": "LinkedField",
                                                        "name": "statusCategory",
                                                        "plural": false,
                                                        "selections": [
                                                          (v84/*: any*/),
                                                          (v276/*: any*/),
                                                          (v80/*: any*/),
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v83/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.status.statusCategory.name"
                                                          },
                                                          (v316/*: any*/)
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.status.statusCategory"
                                                    }
                                                  ]
                                                }
                                              ],
                                              "type": "JiraStatusField"
                                            },
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v102/*: any*/),
                                                (v83/*: any*/),
                                                (v103/*: any*/),
                                                {
                                                  "concreteType": "JiraPriority",
                                                  "kind": "LinkedField",
                                                  "name": "priority",
                                                  "plural": false,
                                                  "selections": [
                                                    (v213/*: any*/),
                                                    (v83/*: any*/),
                                                    (v215/*: any*/),
                                                    (v214/*: any*/),
                                                    (v173/*: any*/)
                                                  ]
                                                }
                                              ],
                                              "type": "JiraPriorityField"
                                            },
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v102/*: any*/),
                                                (v83/*: any*/),
                                                (v103/*: any*/),
                                                {
                                                  "concreteType": "JiraIssueType",
                                                  "kind": "LinkedField",
                                                  "name": "issueType",
                                                  "plural": false,
                                                  "selections": [
                                                    (v84/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v85/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.issueTypeId"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v83/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.name"
                                                    },
                                                    {
                                                      "concreteType": "JiraAvatar",
                                                      "kind": "LinkedField",
                                                      "name": "avatar",
                                                      "plural": false,
                                                      "selections": [
                                                        (v87/*: any*/),
                                                        (v88/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v89/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.avatar.medium"
                                                        },
                                                        (v90/*: any*/)
                                                      ]
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "concreteType": "JiraIssueTypeHierarchyLevel",
                                                        "kind": "LinkedField",
                                                        "name": "hierarchy",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v82/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.hierarchy.level"
                                                          },
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v83/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.hierarchy.name"
                                                          }
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.hierarchy"
                                                    }
                                                  ]
                                                }
                                              ],
                                              "type": "JiraIssueTypeField"
                                            },
                                            (v317/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v102/*: any*/),
                                                (v83/*: any*/),
                                                (v103/*: any*/),
                                                (v264/*: any*/)
                                              ],
                                              "type": "JiraSingleSelectUserPickerField"
                                            },
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v102/*: any*/),
                                                (v83/*: any*/),
                                                (v103/*: any*/),
                                                (v86/*: any*/),
                                                (v105/*: any*/),
                                                {
                                                  "concreteType": "JiraProject",
                                                  "kind": "LinkedField",
                                                  "name": "project",
                                                  "plural": false,
                                                  "selections": [
                                                    (v80/*: any*/),
                                                    (v83/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v204/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.project.projectId"
                                                    },
                                                    (v206/*: any*/),
                                                    (v205/*: any*/),
                                                    (v207/*: any*/),
                                                    (v195/*: any*/),
                                                    (v208/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          (v89/*: any*/),
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v90/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.project.avatar.large"
                                                          }
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.project.avatar"
                                                    },
                                                    (v210/*: any*/)
                                                  ]
                                                }
                                              ],
                                              "type": "JiraProjectField"
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ],
                                  "storageKey": "fieldsById(ids:[\"assignee\",\"issuetype\",\"priority\",\"status\",\"summary\",\"project\"])"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "condition": "isJPDPanelEnabled",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "ui_issueViewJpdIdeas_JPDIdeasPanel"
                        }
                      ]
                    }
                  ],
                  "storageKey": "issueLinks(first:1000)"
                },
                {
                  "alias": "attachments",
                  "args": [
                    (v318/*: any*/)
                  ],
                  "concreteType": "JiraAttachmentConnection",
                  "kind": "LinkedField",
                  "name": "__issue_attachments_connection",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraAttachmentEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v91/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v319/*: any*/),
                                (v320/*: any*/),
                                (v321/*: any*/),
                                (v322/*: any*/),
                                (v323/*: any*/),
                                (v324/*: any*/),
                                (v325/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "author",
                                  "plural": false,
                                  "selections": [
                                    (v132/*: any*/)
                                  ]
                                },
                                (v326/*: any*/),
                                (v327/*: any*/)
                              ],
                              "type": "JiraPlatformAttachment"
                            }
                          ]
                        },
                        (v328/*: any*/)
                      ]
                    },
                    (v331/*: any*/)
                  ],
                  "storageKey": "__issue_attachments_connection(sortBy:{\"field\":\"CREATED\",\"order\":\"DESC\"})"
                },
                {
                  "condition": "issueViewMoveJiraQueryIsAiEnabledProjectField",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "concreteType": "JiraProjectField",
                      "kind": "LinkedField",
                      "name": "projectField",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraProject",
                          "kind": "LinkedField",
                          "name": "project",
                          "plural": false,
                          "selections": [
                            (v80/*: any*/),
                            (v83/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v204/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.projectField.project.projectId"
                            },
                            (v205/*: any*/),
                            (v206/*: any*/),
                            (v207/*: any*/),
                            (v195/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  (v89/*: any*/),
                                  {
                                    "kind": "RequiredField",
                                    "field": (v90/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.projectField.project.avatar.large"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.projectField.project.avatar"
                            },
                            (v218/*: any*/),
                            (v208/*: any*/),
                            (v210/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayAttachmentsFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    (v334/*: any*/),
                    {
                      "kind": "LinkedField",
                      "name": "mediaUploadToken",
                      "plural": false,
                      "selections": [
                        (v335/*: any*/),
                        (v336/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayAssigneeFieldFlag",
                      "variableName": "issueViewRelayAssigneeFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayDateFieldFlag",
                      "variableName": "issueViewRelayDateFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayDateTimeFieldFlag",
                      "variableName": "issueViewRelayDateTimeFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayNumberFieldFlag",
                      "variableName": "issueViewRelayNumberFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayPeopleAndApproversFieldFlag",
                      "variableName": "issueViewRelayPeopleAndApproversFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayPriorityFieldFlag",
                      "variableName": "issueViewRelayPriorityFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayProjectFieldFlag",
                      "variableName": "issueViewRelayProjectFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelaySingleLineTextFieldFlag",
                      "variableName": "issueViewRelaySingleLineTextFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelaySprintFieldFlag",
                      "variableName": "issueViewRelaySprintFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayUrlFieldFlag",
                      "variableName": "issueViewRelayUrlFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "jcsIssueLayoutEapFlag",
                      "variableName": "jcsIssueLayoutEapFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "jsmSentimentCustomFieldFlag",
                      "variableName": "jsmSentimentCustomFieldFlag"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "src_issueViewLayoutTemplatesItemList_ItemList"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ui_issueFieldDataClassification_IssueViewDataClassification"
                },
                {
                  "condition": "issueViewRelayMetadataFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_issueViewLayoutMetaContainer_IssueViewMetaContainer"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayHeaders",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "header_issueViewFoundation_HeaderItemWithoutCompactMode"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "headerActions_issueViewFoundation_HeaderActionsView"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayParentFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelaySummaryFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_issueViewLayoutSummaryField_IssueViewSummaryField"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayColorFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "concreteType": "JiraColorField",
                      "kind": "LinkedField",
                      "name": "issueColorField",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "ui_issueViewLayoutColorField_IssueViewColorField"
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "view_issueViewFoundation_StatusView"
                },
                {
                  "condition": "issueViewRelayConfigurationUrlFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "view_issueViewFoundation_ConfigurationButton"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayTimeTrackingFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "timeTrackingField",
                      "args": (v337/*: any*/),
                      "kind": "LinkedField",
                      "name": "fieldByIdOrAlias",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "FragmentSpread",
                              "name": "ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner"
                            }
                          ],
                          "type": "JiraTimeTrackingField"
                        }
                      ],
                      "storageKey": "fieldByIdOrAlias(idOrAlias:\"timetracking\")"
                    }
                  ]
                },
                {
                  "condition": "customFieldConfigurationFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ConditionalInlineFieldConfigContent"
                    }
                  ]
                },
                {
                  "condition": "giraAGGMigrationCommentsFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "args": (v338/*: any*/),
                      "concreteType": "JiraCommentConnection",
                      "kind": "LinkedField",
                      "name": "comments",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraCommentEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            (v339/*: any*/),
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v91/*: any*/),
                                (v340/*: any*/),
                                (v323/*: any*/),
                                (v341/*: any*/),
                                (v343/*: any*/),
                                (v344/*: any*/),
                                (v345/*: any*/),
                                (v347/*: any*/),
                                {
                                  "condition": "jiraThreadedCommentsEnabled",
                                  "kind": "Condition",
                                  "passingValue": true,
                                  "selections": [
                                    {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "args": (v348/*: any*/),
                                          "concreteType": "JiraCommentConnection",
                                          "kind": "LinkedField",
                                          "name": "childComments",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "concreteType": "JiraCommentEdge",
                                              "kind": "LinkedField",
                                              "name": "edges",
                                              "plural": true,
                                              "selections": [
                                                (v328/*: any*/),
                                                {
                                                  "kind": "LinkedField",
                                                  "name": "node",
                                                  "plural": false,
                                                  "selections": [
                                                    (v91/*: any*/),
                                                    (v340/*: any*/),
                                                    (v323/*: any*/),
                                                    (v341/*: any*/),
                                                    (v343/*: any*/),
                                                    (v344/*: any*/),
                                                    (v345/*: any*/),
                                                    (v347/*: any*/)
                                                  ]
                                                }
                                              ]
                                            },
                                            (v349/*: any*/)
                                          ],
                                          "storageKey": "childComments(first:2)"
                                        }
                                      ],
                                      "type": "JiraPlatformComment"
                                    }
                                  ]
                                },
                                (v350/*: any*/)
                              ]
                            }
                          ]
                        },
                        (v351/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            },
            (v352/*: any*/),
            {
              "condition": "issueViewSmartRepliesSettingsFlag",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "view_issueViewActivityComment_SmartRepliesSettings"
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueActivityFeed_FeedDisplay"
            },
            (v355/*: any*/),
            {
              "condition": "dateFieldsMessageEnabled",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ui_issueViewLayoutTemplates_UserPreferences"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ui_issueViewLayoutTemplates_DateFieldsMessage"
                }
              ]
            },
            {
              "condition": "forgeInMainIssueAggFlag",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "view_forgeDataStoreInitFragment"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "condition": "chinEmbedQueryToAgg",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ui_issueViewCommonViews_ConfigurableChildIssuesPanelWithFragment_rootRelayFragment"
          }
        ]
      },
      {
        "condition": "issueViewDesignsPanelFlag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ui_issueDesignSection_DesignPanelWrapper"
          }
        ]
      },
      {
        "condition": "issueViewStorageLimitsBannerFlag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v20/*: any*/),
      (v56/*: any*/),
      (v2/*: any*/),
      (v58/*: any*/),
      (v54/*: any*/),
      (v55/*: any*/),
      (v16/*: any*/),
      (v52/*: any*/),
      (v18/*: any*/),
      (v12/*: any*/),
      (v3/*: any*/),
      (v11/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v10/*: any*/),
      (v24/*: any*/),
      (v32/*: any*/),
      (v35/*: any*/),
      (v38/*: any*/),
      (v25/*: any*/),
      (v37/*: any*/),
      (v28/*: any*/),
      (v36/*: any*/),
      (v29/*: any*/),
      (v31/*: any*/),
      (v17/*: any*/),
      (v41/*: any*/),
      (v30/*: any*/),
      (v33/*: any*/),
      (v39/*: any*/),
      (v34/*: any*/),
      (v19/*: any*/),
      (v26/*: any*/),
      (v40/*: any*/),
      (v22/*: any*/),
      (v42/*: any*/),
      (v44/*: any*/),
      (v45/*: any*/),
      (v43/*: any*/),
      (v47/*: any*/),
      (v4/*: any*/),
      (v49/*: any*/),
      (v46/*: any*/),
      (v50/*: any*/),
      (v48/*: any*/),
      (v23/*: any*/),
      (v27/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v21/*: any*/),
      (v53/*: any*/),
      (v59/*: any*/),
      (v9/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v57/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v51/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrequestparticipantsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "mainIssueAggQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          {
            "args": (v61/*: any*/),
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          },
          (v70/*: any*/),
          (v72/*: any*/),
          {
            "args": (v61/*: any*/),
            "concreteType": "JiraIssueLinkTypeConnection",
            "kind": "LinkedField",
            "name": "issueLinkTypes",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueLinkTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v73/*: any*/),
                      (v74/*: any*/),
                      (v75/*: any*/),
                      (v84/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "args": (v76/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v77/*: any*/),
              (v78/*: any*/),
              (v79/*: any*/),
              (v80/*: any*/),
              (v81/*: any*/),
              {
                "concreteType": "JiraIssueTypeHierarchyLevel",
                "kind": "LinkedField",
                "name": "hierarchyLevelAbove",
                "plural": false,
                "selections": (v356/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeHierarchyLevel",
                "kind": "LinkedField",
                "name": "hierarchyLevelBelow",
                "plural": false,
                "selections": (v356/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypesForHierarchyAbove",
                "plural": false,
                "selections": (v359/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypesForHierarchyBelow",
                "plural": false,
                "selections": (v359/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypesForHierarchySame",
                "plural": false,
                "selections": (v359/*: any*/)
              },
              {
                "alias": "fieldFragments",
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fields",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v91/*: any*/),
                          (v360/*: any*/),
                          (v102/*: any*/),
                          (v103/*: any*/),
                          (v84/*: any*/),
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              (v361/*: any*/)
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      (v86/*: any*/),
                                      (v362/*: any*/),
                                      (v364/*: any*/),
                                      {
                                        "concreteType": "JiraOption",
                                        "kind": "LinkedField",
                                        "name": "selectedOption",
                                        "plural": false,
                                        "selections": [
                                          (v84/*: any*/),
                                          (v106/*: any*/),
                                          (v143/*: any*/)
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraOptionConnection",
                                        "kind": "LinkedField",
                                        "name": "fieldOptions",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraOptionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraOption",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v84/*: any*/),
                                                  (v106/*: any*/),
                                                  (v144/*: any*/),
                                                  (v143/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraRadioSelectField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      (v165/*: any*/),
                                      (v86/*: any*/),
                                      (v97/*: any*/),
                                      {
                                        "concreteType": "JiraRichText",
                                        "kind": "LinkedField",
                                        "name": "richText",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraADF",
                                            "kind": "LinkedField",
                                            "name": "adfValue",
                                            "plural": false,
                                            "selections": [
                                              (v162/*: any*/),
                                              (v365/*: any*/)
                                            ]
                                          },
                                          (v91/*: any*/)
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          (v79/*: any*/),
                                          (v84/*: any*/)
                                        ]
                                      },
                                      (v93/*: any*/)
                                    ],
                                    "type": "JiraRichTextField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      (v86/*: any*/),
                                      (v362/*: any*/),
                                      (v367/*: any*/),
                                      {
                                        "concreteType": "JiraTeamView",
                                        "kind": "LinkedField",
                                        "name": "selectedTeam",
                                        "plural": false,
                                        "selections": [
                                          (v282/*: any*/),
                                          (v281/*: any*/),
                                          (v280/*: any*/),
                                          (v283/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "name": "jiraIncludesYou"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "jiraMemberCount"
                                          },
                                          (v284/*: any*/)
                                        ]
                                      }
                                    ],
                                    "type": "JiraTeamViewField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v83/*: any*/),
                                          (v86/*: any*/),
                                          (v362/*: any*/),
                                          (v364/*: any*/),
                                          {
                                            "concreteType": "JiraGroupConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedGroupsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraGroupEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraGroup",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v346/*: any*/)
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraMultipleGroupPickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      (v86/*: any*/),
                                      (v362/*: any*/),
                                      (v364/*: any*/),
                                      {
                                        "concreteType": "JiraGroup",
                                        "kind": "LinkedField",
                                        "name": "selectedGroup",
                                        "plural": false,
                                        "selections": (v346/*: any*/)
                                      }
                                    ],
                                    "type": "JiraSingleGroupPickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v369/*: any*/)
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrequestparticipantsrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v369/*: any*/)
                                      }
                                    ],
                                    "type": "JiraMultipleSelectUserPickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayPeopleAndApproversFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v372/*: any*/)
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v372/*: any*/)
                                      }
                                    ],
                                    "type": "JiraPeopleField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          (v375/*: any*/),
                                          (v377/*: any*/),
                                          (v378/*: any*/),
                                          (v84/*: any*/),
                                          (v80/*: any*/),
                                          (v79/*: any*/)
                                        ]
                                      },
                                      (v201/*: any*/),
                                      (v287/*: any*/),
                                      (v286/*: any*/),
                                      (v379/*: any*/),
                                      (v83/*: any*/)
                                    ],
                                    "type": "JiraTimeTrackingField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      (v86/*: any*/),
                                      (v93/*: any*/),
                                      {
                                        "concreteType": "JiraOptionConnection",
                                        "kind": "LinkedField",
                                        "name": "selectedOptions",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraOptionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraOption",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v84/*: any*/),
                                                  (v143/*: any*/),
                                                  (v106/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraOptionConnection",
                                        "kind": "LinkedField",
                                        "name": "fieldOptions",
                                        "plural": false,
                                        "selections": (v147/*: any*/)
                                      }
                                    ],
                                    "type": "JiraCheckboxesField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      (v86/*: any*/),
                                      (v93/*: any*/),
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraProjectField",
                                            "kind": "LinkedField",
                                            "name": "projectField",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraProject",
                                                "kind": "LinkedField",
                                                "name": "project",
                                                "plural": false,
                                                "selections": (v363/*: any*/)
                                              },
                                              (v84/*: any*/)
                                            ]
                                          },
                                          (v84/*: any*/)
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraComponentConnection",
                                        "kind": "LinkedField",
                                        "name": "selectedComponentsConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraComponentEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraComponent",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v84/*: any*/),
                                                  (v83/*: any*/),
                                                  (v152/*: any*/),
                                                  (v153/*: any*/),
                                                  (v151/*: any*/),
                                                  (v86/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraComponentsField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraTimeTrackingField",
                                            "kind": "LinkedField",
                                            "name": "timeTrackingField",
                                            "plural": false,
                                            "selections": [
                                              (v91/*: any*/),
                                              (v102/*: any*/),
                                              (v103/*: any*/),
                                              (v201/*: any*/),
                                              {
                                                "concreteType": "JiraTimeTrackingSettings",
                                                "kind": "LinkedField",
                                                "name": "timeTrackingSettings",
                                                "plural": false,
                                                "selections": [
                                                  (v66/*: any*/),
                                                  (v67/*: any*/),
                                                  (v68/*: any*/),
                                                  (v69/*: any*/)
                                                ]
                                              },
                                              (v84/*: any*/),
                                              (v83/*: any*/),
                                              (v93/*: any*/)
                                            ]
                                          },
                                          (v84/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v83/*: any*/),
                                          (v86/*: any*/),
                                          (v370/*: any*/)
                                        ],
                                        "type": "JiraIssueField",
                                        "abstractKey": "__isJiraIssueField"
                                      }
                                    ],
                                    "type": "JiraOriginalTimeEstimateField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayProjectFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      {
                                        "concreteType": "JiraProject",
                                        "kind": "LinkedField",
                                        "name": "project",
                                        "plural": false,
                                        "selections": [
                                          (v80/*: any*/),
                                          (v83/*: any*/),
                                          {
                                            "concreteType": "JiraAvatar",
                                            "kind": "LinkedField",
                                            "name": "avatar",
                                            "plural": false,
                                            "selections": [
                                              (v88/*: any*/),
                                              (v90/*: any*/)
                                            ]
                                          },
                                          (v84/*: any*/),
                                          (v204/*: any*/)
                                        ]
                                      },
                                      (v93/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraProjectField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayUrlFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v83/*: any*/),
                                      (v288/*: any*/),
                                      (v93/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraUrlField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelaySingleLineTextFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v169/*: any*/),
                                      (v83/*: any*/),
                                      (v93/*: any*/),
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v86/*: any*/)
                                        ]
                                      },
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraSingleLineTextField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayAssigneeFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v381/*: any*/)
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v381/*: any*/)
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v381/*: any*/)
                                      },
                                      {
                                        "condition": "jcsIssueLayoutEapFlag",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v83/*: any*/),
                                          (v380/*: any*/),
                                          (v198/*: any*/)
                                        ]
                                      }
                                    ],
                                    "type": "JiraSingleSelectUserPickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayDateFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v171/*: any*/),
                                      (v83/*: any*/),
                                      (v198/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraDatePickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayDateTimeFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v157/*: any*/),
                                      (v93/*: any*/),
                                      (v83/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraDateTimePickerField"
                                  }
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v311/*: any*/),
                                    "concreteType": "JiraLabelConnection",
                                    "kind": "LinkedField",
                                    "name": "selectedLabelsConnection",
                                    "plural": false,
                                    "selections": [
                                      (v301/*: any*/),
                                      (v382/*: any*/)
                                    ],
                                    "storageKey": "selectedLabelsConnection(first:1000)"
                                  },
                                  (v83/*: any*/),
                                  (v93/*: any*/),
                                  (v371/*: any*/)
                                ],
                                "type": "JiraLabelsField"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v83/*: any*/),
                                  (v384/*: any*/),
                                  (v93/*: any*/),
                                  (v361/*: any*/),
                                  (v371/*: any*/)
                                ],
                                "type": "JiraSingleSelectField"
                              },
                              {
                                "condition": "jsmSentimentCustomFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v299/*: any*/),
                                      (v83/*: any*/),
                                      (v93/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraServiceManagementSentimentField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayNumberFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v160/*: any*/),
                                      (v83/*: any*/),
                                      (v93/*: any*/),
                                      (v97/*: any*/),
                                      (v197/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraNumberField"
                                  }
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v83/*: any*/),
                                  {
                                    "concreteType": "JiraCascadingOption",
                                    "kind": "LinkedField",
                                    "name": "cascadingOption",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraOption",
                                        "kind": "LinkedField",
                                        "name": "parentOptionValue",
                                        "plural": false,
                                        "selections": (v385/*: any*/)
                                      },
                                      {
                                        "concreteType": "JiraOption",
                                        "kind": "LinkedField",
                                        "name": "childOptionValue",
                                        "plural": false,
                                        "selections": (v385/*: any*/)
                                      }
                                    ]
                                  },
                                  (v93/*: any*/),
                                  (v371/*: any*/)
                                ],
                                "type": "JiraCascadingSelectField"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v83/*: any*/),
                                  (v93/*: any*/),
                                  (v295/*: any*/)
                                ],
                                "type": "JiraServiceManagementEntitlementField"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v83/*: any*/),
                                  (v93/*: any*/),
                                  {
                                    "args": (v300/*: any*/),
                                    "concreteType": "JiraGoalConnection",
                                    "kind": "LinkedField",
                                    "name": "selectedGoals",
                                    "plural": false,
                                    "selections": [
                                      (v301/*: any*/),
                                      {
                                        "concreteType": "JiraGoalEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraGoal",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v84/*: any*/),
                                              (v83/*: any*/),
                                              (v195/*: any*/),
                                              (v91/*: any*/)
                                            ]
                                          },
                                          (v328/*: any*/)
                                        ]
                                      },
                                      (v331/*: any*/)
                                    ],
                                    "storageKey": "selectedGoals(first:1)"
                                  },
                                  {
                                    "args": (v300/*: any*/),
                                    "handle": "connection",
                                    "key": "issue_view_goals_field_selectedGoals",
                                    "kind": "LinkedHandle",
                                    "name": "selectedGoals"
                                  },
                                  (v371/*: any*/)
                                ],
                                "type": "JiraGoalsField"
                              },
                              {
                                "condition": "issueViewRelayPriorityFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v386/*: any*/),
                                      (v83/*: any*/),
                                      (v93/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraPriorityField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelaySprintFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v387/*: any*/),
                                      (v83/*: any*/),
                                      (v93/*: any*/),
                                      (v371/*: any*/)
                                    ],
                                    "type": "JiraSprintField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "parentIssue",
                                        "plural": false,
                                        "selections": [
                                          (v80/*: any*/),
                                          (v388/*: any*/),
                                          (v390/*: any*/),
                                          (v391/*: any*/),
                                          (v84/*: any*/),
                                          (v79/*: any*/),
                                          (v118/*: any*/),
                                          (v394/*: any*/)
                                        ]
                                      },
                                      (v83/*: any*/),
                                      (v93/*: any*/)
                                    ],
                                    "type": "JiraParentIssueField"
                                  }
                                ]
                              },
                              {
                                "condition": "jcsIssueLayoutEapFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v395/*: any*/),
                                      (v83/*: any*/),
                                      (v93/*: any*/),
                                      (v105/*: any*/)
                                    ],
                                    "type": "JiraCustomerServiceOrganizationField"
                                  }
                                ]
                              }
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "alias": "status",
                "args": (v396/*: any*/),
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v91/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v374/*: any*/)
                            ],
                            "type": "JiraStatusField"
                          },
                          (v84/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "fieldsById(ids:[\"status\"])"
              },
              {
                "alias": "reporter",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "ids",
                    "value": [
                      "reporter"
                    ]
                  }
                ],
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": (v398/*: any*/),
                "storageKey": "fieldsById(ids:[\"reporter\"])"
              },
              {
                "alias": "assignee",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "ids",
                    "value": [
                      "assignee"
                    ]
                  }
                ],
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": (v398/*: any*/),
                "storageKey": "fieldsById(ids:[\"assignee\"])"
              },
              {
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fields",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v91/*: any*/),
                          (v84/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraClassificationLevel",
                                "kind": "LinkedField",
                                "name": "classificationLevel",
                                "plural": false,
                                "selections": [
                                  (v84/*: any*/),
                                  (v83/*: any*/),
                                  (v383/*: any*/),
                                  (v399/*: any*/),
                                  (v94/*: any*/)
                                ]
                              },
                              {
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "filterByCriteria",
                                    "value": {
                                      "filterByStatus": [
                                        "PUBLISHED"
                                      ],
                                      "filterByType": [
                                        "USER"
                                      ]
                                    }
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 10
                                  }
                                ],
                                "concreteType": "JiraClassificationLevelConnection",
                                "kind": "LinkedField",
                                "name": "classificationLevels",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraClassificationLevelEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraClassificationLevel",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v400/*: any*/)
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "classificationLevels(filterByCriteria:{\"filterByStatus\":[\"PUBLISHED\"],\"filterByType\":[\"USER\"]},first:10)"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "classificationLevelSource"
                              },
                              {
                                "concreteType": "JiraClassificationLevel",
                                "kind": "LinkedField",
                                "name": "defaultClassificationLevel",
                                "plural": false,
                                "selections": (v400/*: any*/)
                              },
                              (v93/*: any*/)
                            ],
                            "type": "JiraDataClassificationField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v401/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraAffectedServicesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v107/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraAssetField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v106/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraBooleanField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v105/*: any*/),
                              (v108/*: any*/),
                              (v109/*: any*/),
                              (v112/*: any*/),
                              {
                                "concreteType": "JiraCmdbObjectConnection",
                                "kind": "LinkedField",
                                "name": "selectedCmdbObjectsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraCmdbObjectEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraCmdbObject",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v113/*: any*/),
                                          (v114/*: any*/),
                                          (v115/*: any*/),
                                          (v116/*: any*/),
                                          (v117/*: any*/),
                                          (v118/*: any*/),
                                          (v141/*: any*/),
                                          {
                                            "concreteType": "JiraCmdbObjectType",
                                            "kind": "LinkedField",
                                            "name": "objectType",
                                            "plural": false,
                                            "selections": [
                                              (v125/*: any*/),
                                              (v83/*: any*/),
                                              (v111/*: any*/),
                                              (v126/*: any*/)
                                            ]
                                          },
                                          {
                                            "concreteType": "JiraCmdbAttributeConnection",
                                            "kind": "LinkedField",
                                            "name": "attributes",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraCmdbAttributeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCmdbAttribute",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v127/*: any*/),
                                                      (v129/*: any*/),
                                                      {
                                                        "concreteType": "JiraCmdbObjectAttributeValueConnection",
                                                        "kind": "LinkedField",
                                                        "name": "objectAttributeValues",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraCmdbObjectAttributeValueEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraCmdbObjectAttributeValue",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v130/*: any*/),
                                                                  (v131/*: any*/),
                                                                  (v106/*: any*/),
                                                                  (v402/*: any*/),
                                                                  (v134/*: any*/),
                                                                  {
                                                                    "concreteType": "JiraGroup",
                                                                    "kind": "LinkedField",
                                                                    "name": "group",
                                                                    "plural": false,
                                                                    "selections": (v403/*: any*/)
                                                                  },
                                                                  (v137/*: any*/),
                                                                  (v139/*: any*/),
                                                                  (v140/*: any*/),
                                                                  (v142/*: any*/)
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraCMDBField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v146/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraCascadingSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v148/*: any*/),
                              (v296/*: any*/),
                              (v149/*: any*/)
                            ],
                            "type": "JiraCheckboxesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v150/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraColorField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v155/*: any*/),
                              (v296/*: any*/),
                              (v156/*: any*/)
                            ],
                            "type": "JiraComponentsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v404/*: any*/),
                            "type": "JiraConnectDateTimeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v148/*: any*/),
                              (v159/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraConnectMultipleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v160/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraConnectNumberField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v405/*: any*/),
                            "type": "JiraConnectRichTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v167/*: any*/),
                              (v168/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraConnectSingleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v406/*: any*/),
                            "type": "JiraConnectTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v171/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraDatePickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v404/*: any*/),
                            "type": "JiraDateTimePickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v174/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraEpicLinkField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v175/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraFlagField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v178/*: any*/),
                              (v165/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeGroupField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v179/*: any*/),
                              (v165/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeGroupsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v160/*: any*/),
                              (v165/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeNumberField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v180/*: any*/),
                              (v165/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeObjectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v169/*: any*/),
                              (v165/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeStringField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v181/*: any*/),
                              (v165/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeStringsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": (v407/*: any*/)
                              },
                              (v165/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeUserField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v408/*: any*/),
                              (v165/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeUsersField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v192/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraIssueRestrictionField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v409/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraIssueTypeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v181/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraLabelsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraGroupConnection",
                                "kind": "LinkedField",
                                "name": "selectedGroupsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraGroupEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraGroup",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v176/*: any*/),
                                          (v83/*: any*/),
                                          (v84/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraMultipleGroupPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v148/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraMultipleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v410/*: any*/),
                            "type": "JiraMultipleSelectUserPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v196/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraMultipleVersionPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v160/*: any*/),
                              (v197/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraNumberField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v201/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v97/*: any*/),
                                  (v199/*: any*/)
                                ],
                                "type": "JiraIssueField",
                                "abstractKey": "__isJiraIssueField"
                              }
                            ],
                            "type": "JiraOriginalTimeEstimateField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v202/*: any*/),
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v84/*: any*/),
                                  (v79/*: any*/),
                                  (v80/*: any*/),
                                  (v118/*: any*/),
                                  (v388/*: any*/),
                                  {
                                    "args": (v203/*: any*/),
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldsById",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v91/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v102/*: any*/),
                                                  (v83/*: any*/),
                                                  (v103/*: any*/),
                                                  (v86/*: any*/),
                                                  (v409/*: any*/)
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v102/*: any*/),
                                                  (v83/*: any*/),
                                                  (v103/*: any*/),
                                                  (v86/*: any*/),
                                                  (v105/*: any*/),
                                                  {
                                                    "concreteType": "JiraProject",
                                                    "kind": "LinkedField",
                                                    "name": "project",
                                                    "plural": false,
                                                    "selections": [
                                                      (v80/*: any*/),
                                                      (v83/*: any*/),
                                                      (v204/*: any*/),
                                                      (v205/*: any*/),
                                                      (v206/*: any*/),
                                                      (v207/*: any*/),
                                                      (v195/*: any*/),
                                                      (v411/*: any*/),
                                                      (v208/*: any*/),
                                                      (v412/*: any*/),
                                                      (v84/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraProjectField"
                                              },
                                              (v211/*: any*/),
                                              (v84/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "fieldsById(ids:[\"issuetype\",\"project\",\"summary\"])"
                                  }
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraParentIssueField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v408/*: any*/),
                              (v212/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraPeopleField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": (v413/*: any*/)
                              },
                              {
                                "concreteType": "JiraPriorityConnection",
                                "kind": "LinkedField",
                                "name": "priorities",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraPriorityEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraPriority",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v413/*: any*/)
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraPriorityField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v217/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraProformaFormsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v105/*: any*/),
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  (v80/*: any*/),
                                  (v83/*: any*/),
                                  (v204/*: any*/),
                                  (v205/*: any*/),
                                  (v206/*: any*/),
                                  (v207/*: any*/),
                                  (v195/*: any*/),
                                  (v411/*: any*/),
                                  (v219/*: any*/),
                                  (v208/*: any*/),
                                  (v412/*: any*/),
                                  (v84/*: any*/)
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraProjectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v220/*: any*/),
                              (v296/*: any*/),
                              (v221/*: any*/)
                            ],
                            "type": "JiraRadioSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v224/*: any*/),
                              (v225/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraResolutionField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v405/*: any*/),
                            "type": "JiraRichTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraSecurityLevel",
                                "kind": "LinkedField",
                                "name": "securityLevel",
                                "plural": false,
                                "selections": (v414/*: any*/)
                              },
                              {
                                "concreteType": "JiraSecurityLevelConnection",
                                "kind": "LinkedField",
                                "name": "securityLevels",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraSecurityLevelEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraSecurityLevel",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v414/*: any*/)
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraSecurityLevelField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraServiceManagementActiveApproval",
                                "kind": "LinkedField",
                                "name": "activeApproval",
                                "plural": false,
                                "selections": [
                                  (v84/*: any*/),
                                  (v228/*: any*/),
                                  (v230/*: any*/),
                                  {
                                    "concreteType": "JiraServiceManagementApproverPrincipalConnection",
                                    "kind": "LinkedField",
                                    "name": "approverPrincipals",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementApproverPrincipalEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v91/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v402/*: any*/),
                                                  (v231/*: any*/)
                                                ],
                                                "type": "JiraServiceManagementUserApproverPrincipal"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v176/*: any*/),
                                                  (v83/*: any*/),
                                                  (v232/*: any*/),
                                                  (v233/*: any*/)
                                                ],
                                                "type": "JiraServiceManagementGroupApproverPrincipal"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v417/*: any*/),
                                  (v238/*: any*/),
                                  {
                                    "concreteType": "JiraServiceManagementApprovalConfiguration",
                                    "kind": "LinkedField",
                                    "name": "configurations",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementApproversConfiguration",
                                        "kind": "LinkedField",
                                        "name": "approversConfigurations",
                                        "plural": true,
                                        "selections": [
                                          (v103/*: any*/),
                                          (v239/*: any*/),
                                          (v102/*: any*/)
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraServiceManagementApprovalCondition",
                                        "kind": "LinkedField",
                                        "name": "condition",
                                        "plural": false,
                                        "selections": [
                                          (v103/*: any*/),
                                          (v106/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v240/*: any*/),
                                  {
                                    "concreteType": "JiraServiceManagementDecisionConnection",
                                    "kind": "LinkedField",
                                    "name": "decisions",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementDecisionEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraServiceManagementDecision",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v416/*: any*/)
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "concreteType": "JiraUserConnection",
                                    "kind": "LinkedField",
                                    "name": "excludedApprovers",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraUserEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v415/*: any*/)
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v241/*: any*/),
                                  (v83/*: any*/),
                                  (v242/*: any*/),
                                  (v243/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "JiraServiceManagementCompletedApprovalConnection",
                                "kind": "LinkedField",
                                "name": "completedApprovalsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementCompletedApprovalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementCompletedApproval",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v84/*: any*/),
                                          (v83/*: any*/),
                                          (v241/*: any*/),
                                          (v417/*: any*/),
                                          (v240/*: any*/),
                                          (v244/*: any*/),
                                          (v245/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraServiceManagementApprovalField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v246/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraServiceManagementMajorIncidentField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraServiceManagementOrganizationConnection",
                                "kind": "LinkedField",
                                "name": "selectedOrganizationsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementOrganizationEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementOrganization",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v247/*: any*/),
                                          (v248/*: any*/),
                                          (v249/*: any*/),
                                          (v84/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraServiceManagementOrganizationField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v410/*: any*/),
                            "type": "JiraServiceManagementPeopleField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v250/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraServiceManagementRequestFeedbackField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v253/*: any*/),
                              (v254/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraServiceManagementRequestLanguageField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v257/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraServiceManagementRequestTypeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v105/*: any*/),
                              {
                                "concreteType": "JiraServiceManagementResponderConnection",
                                "kind": "LinkedField",
                                "name": "respondersConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementResponderEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v91/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                  (v91/*: any*/),
                                                  (v258/*: any*/),
                                                  (v133/*: any*/),
                                                  (v83/*: any*/),
                                                  (v84/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraServiceManagementUserResponder"
                                          },
                                          (v260/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraServiceManagementRespondersField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v178/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraSingleGroupPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v406/*: any*/),
                            "type": "JiraSingleLineTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v167/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraSingleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v418/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraSingleSelectUserPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v267/*: any*/),
                              (v296/*: any*/),
                              (v268/*: any*/)
                            ],
                            "type": "JiraSingleVersionPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraSprintConnection",
                                "kind": "LinkedField",
                                "name": "selectedSprintsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraSprintEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraSprint",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v84/*: any*/),
                                          (v269/*: any*/),
                                          (v83/*: any*/),
                                          (v270/*: any*/),
                                          (v271/*: any*/),
                                          (v265/*: any*/),
                                          (v272/*: any*/),
                                          (v273/*: any*/),
                                          (v274/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraSprintField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "status",
                                "plural": false,
                                "selections": [
                                  (v84/*: any*/),
                                  (v83/*: any*/),
                                  (v86/*: any*/),
                                  (v275/*: any*/),
                                  (v277/*: any*/)
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraStatusField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              {
                                "concreteType": "JiraTeam",
                                "kind": "LinkedField",
                                "name": "selectedTeam",
                                "plural": false,
                                "selections": [
                                  (v259/*: any*/),
                                  (v83/*: any*/),
                                  (v278/*: any*/),
                                  (v279/*: any*/),
                                  (v84/*: any*/)
                                ]
                              },
                              (v296/*: any*/)
                            ],
                            "type": "JiraTeamField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v285/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraTeamViewField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v201/*: any*/),
                              (v286/*: any*/),
                              (v287/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraTimeTrackingField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v288/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraUrlField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v291/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraVotesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v293/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraWatchesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v157/*: any*/),
                              (v165/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeDatetimeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v171/*: any*/),
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v86/*: any*/),
                              (v165/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraForgeDateField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v102/*: any*/),
                              (v295/*: any*/),
                              (v296/*: any*/),
                              (v298/*: any*/)
                            ],
                            "type": "JiraServiceManagementEntitlementField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v102/*: any*/),
                              (v86/*: any*/),
                              (v299/*: any*/),
                              (v296/*: any*/),
                              (v298/*: any*/)
                            ],
                            "type": "JiraServiceManagementSentimentField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v103/*: any*/),
                              (v102/*: any*/),
                              (v83/*: any*/),
                              (v86/*: any*/),
                              (v302/*: any*/),
                              (v296/*: any*/)
                            ],
                            "type": "JiraGoalsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v83/*: any*/),
                              (v103/*: any*/),
                              (v102/*: any*/),
                              (v395/*: any*/),
                              (v105/*: any*/),
                              (v296/*: any*/),
                              (v298/*: any*/)
                            ],
                            "type": "JiraCustomerServiceOrganizationField"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "childIssues",
                "plural": false,
                "selections": [
                  (v91/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v303/*: any*/),
                        "concreteType": "JiraIssueConnection",
                        "kind": "LinkedField",
                        "name": "issues",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v80/*: any*/),
                                  (v79/*: any*/),
                                  (v118/*: any*/),
                                  (v304/*: any*/),
                                  {
                                    "concreteType": "JiraNumberField",
                                    "kind": "LinkedField",
                                    "name": "storyPointEstimateField",
                                    "plural": false,
                                    "selections": (v419/*: any*/)
                                  },
                                  {
                                    "concreteType": "JiraNumberField",
                                    "kind": "LinkedField",
                                    "name": "storyPointsField",
                                    "plural": false,
                                    "selections": (v419/*: any*/)
                                  },
                                  {
                                    "args": (v306/*: any*/),
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldsById",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v91/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v102/*: any*/),
                                                  (v103/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v85/*: any*/),
                                                      (v83/*: any*/),
                                                      (v279/*: any*/),
                                                      (v392/*: any*/),
                                                      (v84/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              (v307/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v102/*: any*/),
                                                  (v103/*: any*/),
                                                  {
                                                    "concreteType": "JiraPriority",
                                                    "kind": "LinkedField",
                                                    "name": "priority",
                                                    "plural": false,
                                                    "selections": [
                                                      (v213/*: any*/),
                                                      (v83/*: any*/),
                                                      (v215/*: any*/),
                                                      (v214/*: any*/),
                                                      (v84/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraPriorityField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v102/*: any*/),
                                                  (v103/*: any*/),
                                                  (v420/*: any*/)
                                                ],
                                                "type": "JiraStatusField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v102/*: any*/),
                                                  (v103/*: any*/),
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v91/*: any*/),
                                                      (v132/*: any*/),
                                                      (v182/*: any*/),
                                                      (v83/*: any*/),
                                                      (v133/*: any*/),
                                                      (v84/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              (v308/*: any*/),
                                              (v309/*: any*/),
                                              (v84/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "fieldsById(ids:[\"assignee\",\"created\",\"issuetype\",\"priority\",\"status\",\"summary\",\"timetracking\",\"updated\"])"
                                  },
                                  (v84/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "issues(activeProjectsOnly:true)"
                      }
                    ],
                    "type": "JiraChildIssuesWithinLimit"
                  },
                  (v310/*: any*/)
                ]
              },
              {
                "args": (v311/*: any*/),
                "concreteType": "JiraIssueLinkConnection",
                "kind": "LinkedField",
                "name": "issueLinks",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueLink",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v84/*: any*/),
                          (v312/*: any*/),
                          (v313/*: any*/),
                          (v314/*: any*/),
                          {
                            "concreteType": "JiraIssueLinkType",
                            "kind": "LinkedField",
                            "name": "type",
                            "plural": false,
                            "selections": [
                              (v73/*: any*/),
                              (v84/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "issue",
                            "plural": false,
                            "selections": [
                              (v84/*: any*/),
                              (v79/*: any*/),
                              (v80/*: any*/),
                              (v118/*: any*/),
                              (v304/*: any*/),
                              {
                                "args": (v315/*: any*/),
                                "concreteType": "JiraIssueFieldConnection",
                                "kind": "LinkedField",
                                "name": "fieldsById",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v91/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v102/*: any*/),
                                              (v83/*: any*/),
                                              (v103/*: any*/),
                                              {
                                                "concreteType": "JiraStatus",
                                                "kind": "LinkedField",
                                                "name": "status",
                                                "plural": false,
                                                "selections": [
                                                  (v84/*: any*/),
                                                  (v83/*: any*/),
                                                  (v275/*: any*/),
                                                  (v421/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraStatusField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v102/*: any*/),
                                              (v83/*: any*/),
                                              (v103/*: any*/),
                                              {
                                                "concreteType": "JiraPriority",
                                                "kind": "LinkedField",
                                                "name": "priority",
                                                "plural": false,
                                                "selections": [
                                                  (v213/*: any*/),
                                                  (v83/*: any*/),
                                                  (v215/*: any*/),
                                                  (v214/*: any*/),
                                                  (v173/*: any*/),
                                                  (v84/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraPriorityField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v102/*: any*/),
                                              (v83/*: any*/),
                                              (v103/*: any*/),
                                              {
                                                "concreteType": "JiraIssueType",
                                                "kind": "LinkedField",
                                                "name": "issueType",
                                                "plural": false,
                                                "selections": [
                                                  (v84/*: any*/),
                                                  (v85/*: any*/),
                                                  (v83/*: any*/),
                                                  (v256/*: any*/),
                                                  (v357/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraIssueTypeField"
                                          },
                                          (v317/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v102/*: any*/),
                                              (v83/*: any*/),
                                              (v103/*: any*/),
                                              (v418/*: any*/)
                                            ],
                                            "type": "JiraSingleSelectUserPickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v102/*: any*/),
                                              (v83/*: any*/),
                                              (v103/*: any*/),
                                              (v86/*: any*/),
                                              (v105/*: any*/),
                                              {
                                                "concreteType": "JiraProject",
                                                "kind": "LinkedField",
                                                "name": "project",
                                                "plural": false,
                                                "selections": [
                                                  (v80/*: any*/),
                                                  (v83/*: any*/),
                                                  (v204/*: any*/),
                                                  (v206/*: any*/),
                                                  (v205/*: any*/),
                                                  (v207/*: any*/),
                                                  (v195/*: any*/),
                                                  (v208/*: any*/),
                                                  (v411/*: any*/),
                                                  (v412/*: any*/),
                                                  (v84/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraProjectField"
                                          },
                                          (v84/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "fieldsById(ids:[\"assignee\",\"issuetype\",\"priority\",\"status\",\"summary\",\"project\"])"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "isJPDPanelEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v298/*: any*/)
                    ]
                  }
                ],
                "storageKey": "issueLinks(first:1000)"
              },
              {
                "args": (v422/*: any*/),
                "concreteType": "JiraAttachmentConnection",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraAttachmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v91/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v319/*: any*/),
                              (v320/*: any*/),
                              (v321/*: any*/),
                              (v322/*: any*/),
                              (v323/*: any*/),
                              (v324/*: any*/),
                              (v325/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "author",
                                "plural": false,
                                "selections": (v397/*: any*/)
                              },
                              (v326/*: any*/),
                              (v327/*: any*/)
                            ],
                            "type": "JiraPlatformAttachment"
                          },
                          (v423/*: any*/)
                        ]
                      },
                      (v328/*: any*/)
                    ]
                  },
                  (v331/*: any*/)
                ],
                "storageKey": "attachments(first:12,sortBy:{\"field\":\"CREATED\",\"order\":\"DESC\"})"
              },
              {
                "args": (v422/*: any*/),
                "filters": [
                  "sortBy"
                ],
                "handle": "connection",
                "key": "issue_attachments",
                "kind": "LinkedHandle",
                "name": "attachments"
              },
              (v84/*: any*/),
              {
                "condition": "issueViewMoveJiraQueryIsAiEnabledProjectField",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraProjectField",
                    "kind": "LinkedField",
                    "name": "projectField",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v80/*: any*/),
                          (v83/*: any*/),
                          (v204/*: any*/),
                          (v205/*: any*/),
                          (v206/*: any*/),
                          (v207/*: any*/),
                          (v195/*: any*/),
                          (v411/*: any*/),
                          (v218/*: any*/),
                          (v208/*: any*/),
                          (v412/*: any*/),
                          (v84/*: any*/)
                        ]
                      },
                      (v84/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "issueViewRelayAttachmentsFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  (v334/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "mediaUploadToken",
                    "plural": false,
                    "selections": [
                      (v91/*: any*/),
                      (v335/*: any*/),
                      (v336/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "issueViewRelayMetadataFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraDateTimePickerField",
                    "kind": "LinkedField",
                    "name": "createdField",
                    "plural": false,
                    "selections": (v424/*: any*/)
                  },
                  {
                    "concreteType": "JiraDateTimePickerField",
                    "kind": "LinkedField",
                    "name": "updatedField",
                    "plural": false,
                    "selections": (v424/*: any*/)
                  },
                  {
                    "concreteType": "JiraDateTimePickerField",
                    "kind": "LinkedField",
                    "name": "resolutionDateField",
                    "plural": false,
                    "selections": (v424/*: any*/)
                  }
                ]
              },
              {
                "condition": "issueViewRelayHeaders",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v427/*: any*/),
                      (v428/*: any*/),
                      (v429/*: any*/),
                      (v431/*: any*/),
                      (v433/*: any*/)
                    ]
                  },
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": (v435/*: any*/)
                  },
                  (v427/*: any*/),
                  (v428/*: any*/),
                  (v429/*: any*/),
                  (v431/*: any*/),
                  (v433/*: any*/)
                ]
              },
              {
                "condition": "issueViewRelayParentFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v435/*: any*/)
              },
              {
                "condition": "issueViewRelaySummaryFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraSingleLineTextField",
                    "kind": "LinkedField",
                    "name": "summaryField",
                    "plural": false,
                    "selections": [
                      (v84/*: any*/),
                      (v83/*: any*/),
                      (v102/*: any*/),
                      (v97/*: any*/),
                      (v103/*: any*/),
                      (v169/*: any*/),
                      (v99/*: any*/),
                      (v91/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "issueViewRelayColorFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraColorField",
                    "kind": "LinkedField",
                    "name": "issueColorField",
                    "plural": false,
                    "selections": [
                      (v84/*: any*/),
                      (v102/*: any*/),
                      (v103/*: any*/),
                      (v83/*: any*/),
                      (v86/*: any*/),
                      (v91/*: any*/),
                      (v93/*: any*/),
                      (v383/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraResolutionField",
                    "kind": "LinkedField",
                    "name": "resolutionField",
                    "plural": false,
                    "selections": [
                      (v91/*: any*/),
                      (v84/*: any*/),
                      (v102/*: any*/),
                      (v103/*: any*/),
                      (v97/*: any*/),
                      (v367/*: any*/),
                      {
                        "concreteType": "JiraResolution",
                        "kind": "LinkedField",
                        "name": "resolution",
                        "plural": false,
                        "selections": [
                          (v84/*: any*/),
                          (v83/*: any*/),
                          (v91/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraResolutionConnection",
                        "kind": "LinkedField",
                        "name": "resolutions",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraResolutionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraResolution",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v84/*: any*/),
                                  (v83/*: any*/),
                                  (v222/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v93/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraStatusField",
                    "kind": "LinkedField",
                    "name": "statusField",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraStatus",
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                          (v84/*: any*/),
                          (v83/*: any*/),
                          (v275/*: any*/),
                          (v86/*: any*/),
                          (v421/*: any*/)
                        ]
                      },
                      (v84/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraProjectField",
                    "kind": "LinkedField",
                    "name": "projectField",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v207/*: any*/),
                          (v84/*: any*/)
                        ]
                      },
                      (v84/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "JiraStatusField",
                    "kind": "LinkedField",
                    "name": "statusField",
                    "plural": false,
                    "selections": [
                      (v84/*: any*/),
                      (v102/*: any*/),
                      (v103/*: any*/),
                      (v83/*: any*/),
                      (v86/*: any*/),
                      (v91/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "issue",
                        "plural": false,
                        "selections": [
                          (v79/*: any*/),
                          (v80/*: any*/),
                          (v84/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraStatus",
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                          (v84/*: any*/),
                          (v275/*: any*/),
                          (v83/*: any*/),
                          (v86/*: any*/),
                          (v421/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "issueViewRelayConfigurationUrlFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v430/*: any*/)
              },
              {
                "condition": "issueViewRelayTimeTrackingFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "timeTrackingField",
                    "args": (v337/*: any*/),
                    "kind": "LinkedField",
                    "name": "fieldByIdOrAlias",
                    "plural": false,
                    "selections": [
                      (v91/*: any*/),
                      (v84/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "issue",
                            "plural": false,
                            "selections": [
                              (v375/*: any*/),
                              (v377/*: any*/),
                              (v378/*: any*/),
                              (v84/*: any*/)
                            ]
                          },
                          (v201/*: any*/),
                          (v287/*: any*/),
                          (v286/*: any*/),
                          (v379/*: any*/),
                          (v102/*: any*/),
                          (v103/*: any*/),
                          (v298/*: any*/)
                        ],
                        "type": "JiraTimeTrackingField"
                      }
                    ],
                    "storageKey": "fieldByIdOrAlias(idOrAlias:\"timetracking\")"
                  }
                ]
              },
              {
                "condition": "customFieldConfigurationFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraProjectField",
                    "kind": "LinkedField",
                    "name": "projectField",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v366/*: any*/)
                      },
                      (v84/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "giraAGGMigrationCommentsFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": (v338/*: any*/),
                    "concreteType": "JiraCommentConnection",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraCommentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v339/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v91/*: any*/),
                              (v340/*: any*/),
                              (v323/*: any*/),
                              (v341/*: any*/),
                              (v437/*: any*/),
                              (v438/*: any*/),
                              (v345/*: any*/),
                              (v347/*: any*/),
                              {
                                "condition": "jiraThreadedCommentsEnabled",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "args": (v348/*: any*/),
                                        "concreteType": "JiraCommentConnection",
                                        "kind": "LinkedField",
                                        "name": "childComments",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraCommentEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              (v328/*: any*/),
                                              {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v91/*: any*/),
                                                  (v340/*: any*/),
                                                  (v323/*: any*/),
                                                  (v341/*: any*/),
                                                  (v437/*: any*/),
                                                  (v438/*: any*/),
                                                  (v345/*: any*/),
                                                  (v347/*: any*/),
                                                  (v423/*: any*/)
                                                ]
                                              }
                                            ]
                                          },
                                          (v349/*: any*/)
                                        ],
                                        "storageKey": "childComments(first:2)"
                                      }
                                    ],
                                    "type": "JiraPlatformComment"
                                  }
                                ]
                              },
                              (v350/*: any*/),
                              (v423/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v351/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v352/*: any*/),
          {
            "condition": "issueViewSmartRepliesSettingsFlag",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "isIssueViewSmartRepliesAdminEnabled",
                "args": [
                  (v60/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "keys",
                    "value": "jira.smart.replies.admin.is-enabled"
                  }
                ],
                "concreteType": "JiraApplicationProperty",
                "kind": "LinkedField",
                "name": "applicationPropertiesByKey",
                "plural": true,
                "selections": [
                  (v80/*: any*/),
                  (v106/*: any*/),
                  (v84/*: any*/)
                ]
              },
              {
                "args": (v61/*: any*/),
                "concreteType": "JiraUserPreferences",
                "kind": "LinkedField",
                "name": "userPreferences",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "isIssueViewSmartRepliesUserEnabled"
                  }
                ]
              }
            ]
          },
          (v355/*: any*/),
          {
            "condition": "dateFieldsMessageEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v61/*: any*/),
                "concreteType": "JiraUserPreferences",
                "kind": "LinkedField",
                "name": "userPreferences",
                "plural": false,
                "selections": [
                  {
                    "args": (v354/*: any*/),
                    "kind": "ScalarField",
                    "name": "showDateFieldAssociationMessageByIssueKey"
                  }
                ]
              },
              {
                "args": (v76/*: any*/),
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issueByKey",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectField",
                    "kind": "LinkedField",
                    "name": "projectField",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v206/*: any*/),
                          {
                            "alias": "canAdministerProject",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "type",
                                "value": "EDIT_PROJECT_CONFIG"
                              }
                            ],
                            "concreteType": "JiraProjectAction",
                            "kind": "LinkedField",
                            "name": "action",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "canPerform"
                              }
                            ],
                            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                          },
                          (v84/*: any*/)
                        ]
                      },
                      (v84/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "condition": "forgeInMainIssueAggFlag",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "concreteType": "JiraForgeQuery",
                "kind": "LinkedField",
                "name": "forge",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      (v60/*: any*/),
                      {
                        "fields": (v354/*: any*/),
                        "kind": "ObjectValue",
                        "name": "context"
                      },
                      {
                        "kind": "Variable",
                        "name": "includeHidden",
                        "variableName": "includeHiddenForge"
                      },
                      {
                        "kind": "Variable",
                        "name": "types",
                        "variableName": "forgeTypes"
                      }
                    ],
                    "concreteType": "JiraForgeExtension",
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      {
                        "condition": "includeHiddenForge",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "hiddenBy"
                          }
                        ]
                      },
                      {
                        "condition": "includeScopesForge",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "scopes"
                          }
                        ]
                      },
                      (v103/*: any*/),
                      (v84/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "environmentId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "environmentKey"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "environmentType"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "installationId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "appVersion"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "consentUrl"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "properties"
                      },
                      {
                        "condition": "includeUserAccessForge",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "concreteType": "JiraUserAppAccess",
                            "kind": "LinkedField",
                            "name": "userAccess",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "hasAccess"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "concreteType": "JiraForgeAppEgressDeclaration",
                        "kind": "LinkedField",
                        "name": "egress",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "addresses"
                          },
                          (v103/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraForgeExtensionLicense",
                        "kind": "LinkedField",
                        "name": "license",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "active"
                          },
                          (v103/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "supportEntitlementNumber"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "trialEndDate"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "subscriptionEndDate"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "isEvaluation"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "billingPeriod"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "ccpEntitlementId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "ccpEntitlementSlug"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "capabilitySet"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "chinEmbedQueryToAgg",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v60/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "fieldSetsInput",
                    "variableName": "fieldSetsInput"
                  },
                  {
                    "kind": "Variable",
                    "name": "issueSearchInput",
                    "variableName": "issueSearchInput"
                  }
                ],
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearch",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v80/*: any*/),
                          (v79/*: any*/),
                          (v84/*: any*/),
                          (v432/*: any*/),
                          (v304/*: any*/),
                          {
                            "args": (v396/*: any*/),
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v91/*: any*/),
                                      (v393/*: any*/),
                                      (v84/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"status\"])"
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "hasChildren"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "canHaveChildIssues"
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Variable",
                                        "name": "fieldSetIds",
                                        "variableName": "fieldSetIds"
                                      },
                                      (v439/*: any*/)
                                    ],
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsById",
                                    "plural": false,
                                    "selections": (v444/*: any*/)
                                  }
                                ]
                              },
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Variable",
                                        "name": "context",
                                        "variableName": "fieldSetsContext"
                                      },
                                      {
                                        "kind": "Variable",
                                        "name": "filterId",
                                        "variableName": "filterId"
                                      },
                                      (v439/*: any*/),
                                      {
                                        "kind": "Variable",
                                        "name": "namespace",
                                        "variableName": "namespace"
                                      },
                                      {
                                        "kind": "Variable",
                                        "name": "viewId",
                                        "variableName": "viewId"
                                      }
                                    ],
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsForIssueSearchView",
                                    "plural": false,
                                    "selections": (v444/*: any*/)
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isHighlightedIssueRow"
                              },
                              (v297/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v91/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 500
                              }
                            ],
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": (v444/*: any*/),
                            "storageKey": "fieldSets(first:500)"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isCappingIssueSearchResult"
                  },
                  {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "firstIssuePosition"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "lastIssuePosition"
                      }
                    ]
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "maxCursors",
                        "value": 7
                      }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "first",
                        "plural": false,
                        "selections": (v445/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": (v445/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "last",
                        "plural": false,
                        "selections": (v445/*: any*/)
                      }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  },
                  (v301/*: any*/),
                  (v441/*: any*/),
                  (v298/*: any*/)
                ]
              },
              {
                "args": [
                  (v60/*: any*/),
                  (v353/*: any*/)
                ],
                "kind": "LinkedField",
                "name": "fieldSetViewQueryByProject",
                "plural": false,
                "selections": [
                  (v91/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "filter",
                            "value": {
                              "fieldSetSelectedState": "SELECTED"
                            }
                          },
                          (v439/*: any*/)
                        ],
                        "concreteType": "JiraIssueSearchFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueSearchFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueSearchFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v440/*: any*/),
                                  (v103/*: any*/),
                                  (v251/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "jqlTerm"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "isSortable"
                                  },
                                  {
                                    "concreteType": "JiraFieldSetPreferences",
                                    "kind": "LinkedField",
                                    "name": "fieldSetPreferences",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "width"
                                      }
                                    ]
                                  },
                                  {
                                    "concreteType": "JiraFieldType",
                                    "kind": "LinkedField",
                                    "name": "fieldType",
                                    "plural": false,
                                    "selections": [
                                      (v251/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v301/*: any*/),
                          (v298/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraFieldSetView"
                  },
                  (v423/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "issueViewDesignsPanelFlag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "DevOps",
            "kind": "LinkedField",
            "name": "devOps",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "siteAri"
                  },
                  {
                    "kind": "Literal",
                    "name": "providerTypes",
                    "value": [
                      "DESIGN"
                    ]
                  }
                ],
                "concreteType": "DevOpsProviders",
                "kind": "LinkedField",
                "name": "providers",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "DevOpsDesignProvider",
                    "kind": "LinkedField",
                    "name": "designProviders",
                    "plural": true,
                    "selections": [
                      (v84/*: any*/),
                      {
                        "args": (v61/*: any*/),
                        "concreteType": "JiraAppConfigState",
                        "kind": "LinkedField",
                        "name": "configState",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraConfigStateConnection",
                            "kind": "LinkedField",
                            "name": "config",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraConfigState",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v195/*: any*/),
                                  (v117/*: any*/)
                                ]
                              }
                            ]
                          },
                          (v84/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "DevOpsSupportedActions",
                        "kind": "LinkedField",
                        "name": "supportedActions",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "getEntityByUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "checkAuth"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "issueViewStorageLimitsBannerFlag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "items": [
                  {
                    "kind": "Variable",
                    "name": "cloudIds.0",
                    "variableName": "cloudId"
                  }
                ],
                "kind": "ListValue",
                "name": "cloudIds"
              }
            ],
            "concreteType": "TenantContext",
            "kind": "LinkedField",
            "name": "tenantContexts",
            "plural": true,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "hamsProductKey",
                    "variableName": "productKey"
                  }
                ],
                "kind": "LinkedField",
                "name": "entitlementInfo",
                "plural": false,
                "selections": [
                  (v91/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "entitlement",
                    "plural": false,
                    "selections": [
                      (v91/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "transactionAccount",
                        "plural": false,
                        "selections": [
                          (v91/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "experienceCapabilities",
                            "plural": false,
                            "selections": [
                              (v91/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "addPaymentMethod",
                                "plural": false,
                                "selections": (v447/*: any*/)
                              }
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v294/*: any*/),
                            "type": "CcpTransactionAccount"
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          (v91/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "accountDetails",
                            "plural": false,
                            "selections": [
                              (v91/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "invoiceGroup",
                                "plural": false,
                                "selections": [
                                  (v91/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "experienceCapabilities",
                                    "plural": false,
                                    "selections": [
                                      (v91/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "name": "configurePayment",
                                        "plural": false,
                                        "selections": (v447/*: any*/)
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v294/*: any*/),
                                    "type": "CcpInvoiceGroup"
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v294/*: any*/),
                            "type": "CcpSubscription"
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "experienceCapabilities",
                        "plural": false,
                        "selections": [
                          (v91/*: any*/),
                          {
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "offeringKey",
                                "variableName": "offeringKey"
                              }
                            ],
                            "kind": "LinkedField",
                            "name": "changeOffering",
                            "plural": false,
                            "selections": [
                              (v91/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "experienceUrl"
                              },
                              (v446/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v84/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "beb849917120f6c69dc7032718043cf93557e869b57e3855bb62bfba42430856",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "issueByKey",
            "attachments"
          ]
        }
      ]
    },
    "name": "mainIssueAggQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider": isIssueViewFieldConfigEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider": issueViewRelayTimeTrackingFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider": issueViewRelayCheckboxSelectFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider": issueViewRelayComponentsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider": issueViewRelayOriginalEstimateFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider": issueViewRelayMultiUserAndParticipantsAndRequestParticipantsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider": issueViewRelayGroupPickerFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider": issueViewRelayMultiGroupPickerFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider": issueViewRelayTeamFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider": issueViewRelayRadioSelectFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider": issueViewRelayRichTextFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrelayprovider": issueViewRelayMultiUserParticipantsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrequestparticipantsrelayprovider": issueViewRelayRequestParticipantsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider": issueViewRelayPeopleFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider": issueViewRelayApproversFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider": issueViewRelaySingleLineTextMessageFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider": includeSingleSelectColorField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider": issueViewRelayHeaderActionsFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider": issueViewRelayWatchersFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider": issueViewRelaySecurityLevelFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider": issueViewRelayVotesFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider": issueViewRelayIssueRestrictionsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider": issueViewRelayConfigurationUrlFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider": issueViewRelayIssueTypeSwitcherFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider": issueViewRelayResolutionFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider": issueViewRelayStatusFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider": issueViewRelayStatusPanelFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider": isJiraListInlineEditingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
    }
  }
};
})();

(node as any).hash = "487435525bb00c59505c0df43f57c469";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
