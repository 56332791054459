import React, { useState, useCallback, useMemo, useRef, useEffect, type ReactElement } from 'react';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import { graphql, useFragment, useQueryLoader } from 'react-relay';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Avatar from '@atlaskit/avatar';
import type { EditorActions } from '@atlaskit/editor-core';
import Lozenge from '@atlaskit/lozenge';
import { Text } from '@atlaskit/primitives';
import { useCannedCommentAnalytics } from '@atlassian/jira-canned-comments/src/controllers/use-canned-comments-analytics/index.tsx';
import { useSignature } from '@atlassian/jira-canned-response-common/src/controllers/store/index.tsx';
import { useProjectAri } from '@atlassian/jira-canned-response-common/src/ui/use-project-ari/index.tsx';
import { CannedResponseToolbarButton } from '@atlassian/jira-canned-response-extension/src/ui/canned-response-toolbar-button/index.tsx';
import { SignatureCrFetchWrapper } from '@atlassian/jira-canned-response-extension/src/ui/signature-cr-fetch-wrapper/index.tsx';
import { SignatureCrFetchComponentQuery as SignatureCrFetchQuery } from '@atlassian/jira-canned-response-extension/src/ui/signature-cr-fetch-wrapper/signature-cr-fetch-component/index.tsx';
import { isIE11 } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { EditorContainer } from '@atlassian/jira-issue-comment-base/src/ui/comment/content/view.tsx';
import {
	useIsModal,
	useIssueKey,
	useIsAiEnabledForIssue,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	type StickyRef,
	useStickyHeaderRegistration,
} from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import {
	OLDEST_FIRST,
	NEWEST_FIRST,
	type ActivitySortOrderType,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import { useSmartRepliesPreferencesResources } from '@atlassian/jira-issue-smart-replies-preferences/src/index.tsx';
import {
	issueAppModalWrapperClassName,
	EDITOR_END_CLASS_NAME,
	EDITOR_START_CLASS_NAME,
} from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { smoothScrollIntoViewIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import type { RenderCustomSecondaryToolbar } from '@atlassian/jira-issue-view-common/src/component/editor/index.tsx';
import { useIsCompactMode } from '@atlassian/jira-issue-view-compact-mode/src/index.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import ServiceDeskCommentEditorContainer from '@atlassian/jira-issue-view-servicedesk-editor-container/src/index.tsx';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';
import { isJsmLoomExperimentEnabled } from '@atlassian/jira-loom-jsm-agent-response/src/common/utils.tsx';
import { useOptInStatus } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useAIUpsellInEditorFree } from '@atlassian/jira-platform-react-hooks-use-ai-upsell-in-editor-free/src/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { getProductForEditorAIPlugin } from '@atlassian/jira-platform-utils-shared-editor-config/src/index.tsx';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	SCREEN,
	FireScreenAnalytics,
	ContextualAnalyticsData,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { signatureCrFetchComponentQuery as signatureCrFetchQuery } from '@atlassian/jira-relay/src/__generated__/signatureCrFetchComponentQuery.graphql';
import type { view_issueViewActivityComment_SmartRepliesSettings$key as JiraCommentRelayFragment } from '@atlassian/jira-relay/src/__generated__/view_issueViewActivityComment_SmartRepliesSettings.graphql';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	IssueAddCommentEditorLoading,
	IssueAddCommentEditorBoundary,
} from './add-comment-editor/async.tsx';
import IssueAddCommentEditor from './add-comment-editor/index.tsx';
import CommentShortcutHint from './comment-shortcut-hint.tsx';
import { messages } from './messages.tsx';
import { AvatarContainer, Wrapper, StickyWrapper } from './styled.tsx';
import { useMobileSectionMessage } from './use-section-message.tsx';

export type ConnectedProps = {
	isExpanded: boolean;
	isServiceDesk?: boolean;
	isEditorFocused: boolean;
	isLoading?: boolean;
	isCompact: boolean;
	isCannedResponseEnabled: boolean;
	isSmartRepliesEnabled?: boolean;
	isQuickRepliesEnabled?: boolean;
	hasDraft?: boolean;
	avatarUrl?: string | null;
	displayName?: string | null;
	newCommentDraft?: ADF | null;
	editorSecondaryToolbarComponents?: ReactElement[];
	sortOrder: ActivitySortOrderType;
	containerWidth?: number;
	forceFocus: number;
	commentSessionId?: string | null;
	// eslint-disable-next-line jira/react/handler-naming
	registerSticky: (arg1: string, stickyRef: StickyRef) => void;
	// eslint-disable-next-line jira/react/handler-naming
	deregisterSticky: (arg1: string) => void;
	onExpand?: () => void;
	onBlur?: () => void;
	onSave?: (adf: ADF) => void;
	onEditorExpandedWithAnalytics: () => void;
	onEdit?: (adf: ADF) => void;
	onPaste?: (content: string) => void;
	onCancel?: () => void;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OwnProps = Record<any, any>;

type Props = OwnProps & ConnectedProps;

export type State = {
	isKeylineVisible: boolean;
	shouldHide: boolean;
};

const useIsAIUpsellInEditorFreeEnabled = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const product = getProductForEditorAIPlugin(projectType);
	const isAiOptInEnabled = useIsAiEnabledForIssue();
	const optInStatus = useOptInStatus(isAiOptInEnabled, product);
	const { isExperimentEnabled: isAIUpsellInEditorFreeEnabled } = useAIUpsellInEditorFree(
		optInStatus,
		product,
	);
	return isAIUpsellInEditorFreeEnabled;
};

const useIsAIRepliesEnabled = (isSmartRepliesEnabled: boolean | undefined) => {
	const { core, software } = useAppEditions();
	const isAiOptInEnabled = useIsAiEnabledForIssue();
	return (
		isAiOptInEnabled &&
		(core === PREMIUM_EDITION || software === PREMIUM_EDITION) &&
		isSmartRepliesEnabled
	);
};

const checkIsAIRepliesEnabled = functionWithCondition(
	() => fg('ai_smart_replies_gate'),
	(isSmartRepliesEnabled: boolean | undefined) => {
		const isAIRepliesEnabled = useIsAIRepliesEnabled(isSmartRepliesEnabled);
		return isAIRepliesEnabled;
	},
	() => {
		return false;
	},
);

const isSmartRepliesExperimentEnrolled = () => {
	const [expConfig] = UNSAFE_noExposureExp('smart_replies_m1');

	return expConfig.get('isEnabled', null) !== null;
};

const STICKY_NAME = 'comment-add';
const COMPACT_EDITOR_WIDTH = 550;

export const AddCommentForm = ({
	isServiceDesk = false,
	isLoading = false,
	hasDraft = false,
	avatarUrl = undefined,
	displayName = null,
	newCommentDraft = null,
	onExpand = noop,
	onSave = noop,
	onEdit = noop,
	onPaste = noop,
	onBlur = noop,
	onCancel = noop,
	isExpanded,
	isEditorFocused,
	editorSecondaryToolbarComponents,
	sortOrder,
	onEditorExpandedWithAnalytics,
	containerWidth,
	isCannedResponseEnabled,
	isSmartRepliesEnabled: isSmartRepliesEnabledState,
	isQuickRepliesEnabled,
	forceFocus,
	commentSessionId,
	rootRelayFragment,
}: Props) => {
	const { formatMessage } = useIntl();
	const isEmbedMode = useIsEmbedMode();
	const isSiteAdmin = useIsSiteAdmin();

	const addCommentContainerRef = useRef<HTMLElement | null>(null);
	const addCommentContainerBlockRef = useRef<HTMLElement | null>(null);
	const editorContainerRef = useRef<HTMLElement | null>(null);
	const containerBottomRef = useRef<HTMLElement | null>(null);
	const containerTopRef = useRef<HTMLElement | null>(null);
	const editorEndOrStartElementRef = useRef<Element | null>(null);
	const overflowNodeRef = useRef<Element | null>(null);
	const observerRef = useRef<IntersectionObserver | null>(null);
	const mountedRef = useRef<boolean>(false);
	const editorActionsRef = useRef<EditorActions | null>(null);

	const [isKeylineVisible, setIsKeylineVisible] = useState(false);
	const [shouldHide, setShouldHide] = useState(false);
	const [touchingTop, setTouchingTop] = useState(false); // is the form touching (or past) the top of the scroll area
	const [previousForceFocus, setPreviousForceFocus] = useState(forceFocus);

	const previousIsExpanded = usePrevious(isExpanded);
	const previousIsEditorFocused = usePrevious(isEditorFocused);

	const isCompact = useIsCompactMode();
	const isModal = useIsModal();
	const isLayered = isModal || isEmbedMode;
	const { registerSticky, deregisterSticky } = useStickyHeaderRegistration();
	const [signatureCrQueryReference, loadSignatureCrQuery] =
		useQueryLoader<signatureCrFetchQuery>(SignatureCrFetchQuery);
	const projectAri = useProjectAri();
	const [signature, { setSignature, resetSignature }] = useSignature();
	const { checkAndDisplayMessage } = useMobileSectionMessage();

	const setEditorContainerRef = useCallback((container: HTMLElement | null) => {
		editorContainerRef.current = container;
	}, []);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	let isSmartRepliesEnabled = isSmartRepliesEnabledState;
	if (fg('smart-replies-system-setting')) {
		// eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
		const jira = useFragment<JiraCommentRelayFragment>(
			graphql`
				fragment view_issueViewActivityComment_SmartRepliesSettings on JiraQuery {
					isIssueViewSmartRepliesAdminEnabled: applicationPropertiesByKey(
						cloudId: $cloudId
						keys: "jira.smart.replies.admin.is-enabled"
					) {
						key
						value
					}
					userPreferences(cloudId: $cloudId) {
						isIssueViewSmartRepliesUserEnabled
					}
				}
			`,
			rootRelayFragment?.jira || null,
		);

		isSmartRepliesEnabled =
			isSmartRepliesEnabled &&
			jira?.isIssueViewSmartRepliesAdminEnabled?.find(
				({ key }) => key === 'jira.smart.replies.admin.is-enabled',
			)?.value !== 'false' &&
			jira?.userPreferences?.isIssueViewSmartRepliesUserEnabled !== false;
	}

	if (isSmartRepliesEnabled && !fg('smart-replies-system-setting')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { isSmartRepliesPreferenceEnabled } = useSmartRepliesPreferencesResources();
		isSmartRepliesEnabled = isSmartRepliesPreferenceEnabled;
	}

	const isAIRepliesEnabled = checkIsAIRepliesEnabled(isSmartRepliesEnabled);
	const { fireAnalyticsForCannedComment, startEditing } = useCannedCommentAnalytics({
		isSmartRepliesEnabled: !!isSmartRepliesEnabled && isSmartRepliesExperimentEnrolled(),
	});

	const handleOnEditorExpandedWithAnalytics = useCallback((): void => {
		onEditorExpandedWithAnalytics();
		if (startEditing) {
			startEditing();
		}
	}, [onEditorExpandedWithAnalytics, startEditing]);

	const handleOnSave = useCallback(
		(value: ADF) => {
			const isValueEmpty = isOnlyWhitespaceAdf(value);
			if (!isValueEmpty) {
				onSave?.(value);
				onCancel?.();

				if (
					fireAnalyticsForCannedComment &&
					!!isSmartRepliesEnabled &&
					isSmartRepliesExperimentEnrolled()
				) {
					fireAnalyticsForCannedComment(value);
				}

				if (fg('jira_issue_mobile_section_message')) {
					checkAndDisplayMessage();
				}

				isCannedResponseEnabled &&
					signature &&
					fireTrackAnalytics(createAnalyticsEvent({}), 'signatureCannedResponse inserted');
			}
		},
		[
			onSave,
			onCancel,
			fireAnalyticsForCannedComment,
			isSmartRepliesEnabled,
			isCannedResponseEnabled,
			signature,
			createAnalyticsEvent,
			checkAndDisplayMessage,
		],
	);

	const prefetchSignatureCr = useCallback(() => {
		!signatureCrQueryReference && loadSignatureCrQuery({ projectAri });
	}, [loadSignatureCrQuery, projectAri, signatureCrQueryReference]);

	const handleOnExpand = useCallback(() => {
		onExpand?.();
	}, [onExpand]);

	const setSticky = useCallback(() => {
		if (sortOrder === OLDEST_FIRST) {
			if (overflowNodeRef.current && containerBottomRef.current) {
				const { current: currentOverflowNodeRef } = overflowNodeRef;
				const { current: currentContainerBottomRef } = containerBottomRef;

				const overflowNodeRect = currentOverflowNodeRef.getBoundingClientRect();
				const containerRect = currentContainerBottomRef.getBoundingClientRect();

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const overflowNodeBottom = Math.min(overflowNodeRect.bottom, window.innerHeight);
				const isKeylineVisibleNew = !isExpanded && containerRect.bottom >= overflowNodeBottom;
				setIsKeylineVisible(isKeylineVisibleNew);
			}
		} else if (sortOrder === NEWEST_FIRST) {
			if (overflowNodeRef.current && containerTopRef.current && containerBottomRef.current) {
				const { current: currentOverflowNodeRef } = overflowNodeRef;
				const { current: currentContainerBottomRef } = containerBottomRef;
				const { current: currentContainerTopRef } = containerTopRef;

				const overflowNodeRect = currentOverflowNodeRef.getBoundingClientRect();
				const containerTopRect = currentContainerTopRef.getBoundingClientRect();
				const containerBottomRect = currentContainerBottomRef.getBoundingClientRect();

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const overflowNodeBottom = Math.min(overflowNodeRect.bottom, window.innerHeight);

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const overflowNodeTop = Math.min(overflowNodeRect.top, window.innerHeight);
				const isKeylineVisibleToTop = !isExpanded && containerTopRect.top <= overflowNodeTop;
				const isKeylineVisibleToBottom =
					!isExpanded && containerBottomRect.bottom >= overflowNodeBottom;

				const isKeylineVisibleNew = isKeylineVisibleToTop || isKeylineVisibleToBottom;
				setIsKeylineVisible(isKeylineVisibleNew);
			}
		}
	}, [isExpanded, sortOrder]);

	const throttledSticky = useMemo(() => throttle(setSticky, 100), [setSticky]);

	const getScrollParent = useCallback((node?: Element | null): Element | null => {
		// If we reach the issue app wrapper class we have gone too far, this check is in place to
		// ensure we don't stick to the view port or another scrollable div further up the DOM tree.
		if (!node || node.className === issueAppModalWrapperClassName) {
			return null;
		}

		if (
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.getComputedStyle(node).overflowY === 'auto' ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.getComputedStyle(node).overflowY === 'scroll'
		) {
			return node;
		}

		return getScrollParent(node.parentElement);
	}, []);

	const setOverflowNode = useCallback(() => {
		if (!overflowNodeRef.current) {
			overflowNodeRef.current = getScrollParent(containerBottomRef.current);
		}
		setSticky();
	}, [getScrollParent, setSticky]);

	const scrollEditorIntoView = useCallback(() => {
		// The purpose of the timeout is to wait until the editor has expanded,
		// so we get the correct height for this calculation.
		setTimeout(() => {
			smoothScrollIntoViewIfNeeded(addCommentContainerRef.current, {
				scrollMode: 'if-needed',
				behavior: 'auto', // This takes into account a user who may have turned off scrolling animations at the browser level who may have animation sickness etc.
				block: 'end',
			});
		});
	}, []);

	/**
	 * This sets up an IntersectionObserver to check whether the comment form is outside of the viewport. In that case
	 * we need to update the `isKeylineVisible` state to show the keyline above the comment form. There is no other good way to be notified
	 * whether an element has entered the 'sticky' position or not.
	 *
	 * We also use the same IntersectionObserver to monitor when the
	 * editor has entered or left the viewport to decide if we should hide the sticky add comment box
	 *
	 * It is required for cases where the position of the comment box changes independent of resizes, for example when
	 * content above the comment form pushes it down below the viewport (for example, when an image has finished loading).
	 *
	 * This does not work in Safari / IE11, so they won't see the keyline. The comment form will still be sticky however, as
	 * that's solved by just CSS (except for IE11).
	 */
	const setupStickyObserver = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window.IntersectionObserver && containerBottomRef.current && containerTopRef.current) {
			const { current: currentContainerBottomRef } = containerBottomRef;
			const { current: currentContainerTopRef } = containerTopRef;

			const handleIntersect: IntersectionObserverCallback = (
				entries: IntersectionObserverEntry[],
			) => {
				entries.forEach((entry) => {
					const isKeylineVisibleNewValue = !isExpanded && !entry.isIntersecting;

					switch (entry.target) {
						case currentContainerTopRef: {
							if (
								entry.boundingClientRect &&
								entry.rootBounds &&
								entry.boundingClientRect.top <= entry.rootBounds.top
							) {
								setTouchingTop(true);
							} else {
								setTouchingTop(false);
							}
							setIsKeylineVisible(isKeylineVisibleNewValue);
							break;
						}
						case editorEndOrStartElementRef.current: {
							// We are checking boundingClientRect.y to ensure that the editor
							// is trailing off the bottom of the page, not the top
							const oldestFirstShouldHide =
								!entry.rootBounds ||
								(!entry.isIntersecting &&
									entry.boundingClientRect.y >= 0 &&
									entry.boundingClientRect.y >= entry.rootBounds.y);

							const newestFirstShouldHide =
								!entry.rootBounds ||
								(!entry.isIntersecting && entry.boundingClientRect.y <= entry.rootBounds.y);

							const shouldHideNew =
								sortOrder === OLDEST_FIRST ? oldestFirstShouldHide : newestFirstShouldHide;

							setShouldHide(shouldHideNew);
							break;
						}
						default:
							break;
					}
				});
			};

			const options: IntersectionObserverInit = {
				root: overflowNodeRef.current,
				rootMargin: '0px',
				threshold: 0,
			};

			observerRef.current = new IntersectionObserver(handleIntersect, options);

			const { current: observer } = observerRef;

			observer.observe(currentContainerBottomRef);
			observer.observe(currentContainerTopRef);
		}
	}, [isExpanded, sortOrder]);

	const setupStickyHidingObserver = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window.IntersectionObserver && observerRef.current) {
			setTimeout(() => {
				// find markers pointing to the Editor's "nearest" edge
				const nodes =
					overflowNodeRef.current?.getElementsByClassName(
						sortOrder === OLDEST_FIRST ? EDITOR_END_CLASS_NAME : EDITOR_START_CLASS_NAME,
					) || [];

				// expectation is to have two nodes - one is "add-comment-form", and another is the real editor
				// the order depends on the sortOrder
				editorEndOrStartElementRef.current =
					nodes.length === 2
						? nodes[sortOrder === OLDEST_FIRST ? 0 : 1]
						: // if we have any other number of nodes - consider as undefined behavior and ignore
							null;

				// FIXME: editorEndOrStartElementRef.current should be stored in State, not in ref
				// that will simplify and unify logic to observe and unobserve
				if (editorEndOrStartElementRef.current && observerRef.current) {
					observerRef.current.observe(editorEndOrStartElementRef.current);
				}
			});
		}
	}, [sortOrder]);

	const deferredComponentDidMount = useCallback(() => {
		overflowNodeRef.current = getScrollParent(containerBottomRef.current);

		// We only want to react to resize and scroll events and ensure stickiness when
		// *NOT* running in IE11. In IE11, sticky simply does not work and by registering
		// these listeners in IE11, we end up sending a lot of errors to Sentry.
		if (!isIE11()) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('resize', setOverflowNode);
			if (overflowNodeRef.current) {
				overflowNodeRef.current.addEventListener('scroll', throttledSticky);
				setSticky();
			}
		}

		if (isExpanded) {
			scrollEditorIntoView();
		}

		setupStickyObserver();

		if (isEditorFocused) {
			setupStickyHidingObserver();
		}
	}, [
		getScrollParent,
		isEditorFocused,
		isExpanded,
		scrollEditorIntoView,
		setOverflowNode,
		setSticky,
		throttledSticky,
		setupStickyHidingObserver,
		setupStickyObserver,
	]);

	const removeStickyHidingObserver = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window.IntersectionObserver && observerRef.current && editorEndOrStartElementRef.current) {
			observerRef.current.unobserve(editorEndOrStartElementRef.current);
		}
	}, []);

	useEffect(() => {
		requestAnimationFrame(() => deferredComponentDidMount());

		return () => {
			if (!isIE11()) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.removeEventListener('resize', setOverflowNode);
				if (overflowNodeRef.current) {
					overflowNodeRef.current.removeEventListener('scroll', throttledSticky);
				}
			}
			const { current: currentContainerBottomRef } = containerBottomRef;
			const { current: currentContainerTopRef } = containerTopRef;

			if (observerRef.current && currentContainerBottomRef) {
				observerRef.current.unobserve(currentContainerBottomRef);
			}
			if (observerRef.current && currentContainerTopRef) {
				observerRef.current.unobserve(currentContainerTopRef);
			}
			if (observerRef.current && editorEndOrStartElementRef.current) {
				observerRef.current.unobserve(editorEndOrStartElementRef.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => () => {
			resetSignature();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		if (!mountedRef.current) {
			mountedRef.current = true;
		} else {
			if (Boolean(previousIsExpanded) === false && isExpanded) {
				scrollEditorIntoView();
				const jsmLoomExperiment = isJsmLoomExperimentEnabled();
				fireUIAnalytics(createAnalyticsEvent({ actionSubject: 'commentAdd', action: 'started' }), {
					hasDraft,
					commentSessionId,
					isSiteAdmin,
					...(jsmLoomExperiment?.enabled && {
						loomJsmAgentExperimentCohort: jsmLoomExperiment?.cohort,
					}),
				});
			}

			if (previousIsEditorFocused !== isEditorFocused) {
				if (isEditorFocused) {
					setupStickyHidingObserver();
				} else {
					// Since the DOM element we are observing will be removed from the page, we should stop observing
					removeStickyHidingObserver();
				}
			}
		}
	}, [
		isEditorFocused,
		isExpanded,
		previousIsEditorFocused,
		previousIsExpanded,
		removeStickyHidingObserver,
		scrollEditorIntoView,
		setupStickyHidingObserver,
		deregisterSticky,
		createAnalyticsEvent,
		hasDraft,
		commentSessionId,
		isSiteAdmin,
	]);

	useEffect(() => {
		if (
			addCommentContainerBlockRef.current &&
			touchingTop &&
			!shouldHide &&
			!isCompact &&
			sortOrder === NEWEST_FIRST &&
			!isExpanded
		) {
			registerSticky(STICKY_NAME, addCommentContainerBlockRef);
		} else {
			deregisterSticky(STICKY_NAME);
		}

		return () => {
			deregisterSticky(STICKY_NAME);
		};
	}, [shouldHide, touchingTop, isCompact, sortOrder, isExpanded, deregisterSticky, registerSticky]);

	useEffect(() => {
		if (forceFocus !== previousForceFocus && editorActionsRef?.current) {
			editorActionsRef?.current.focus();
			setPreviousForceFocus(forceFocus);
		}
	}, [editorActionsRef, forceFocus, previousForceFocus]);

	useEffect(() => {
		if (isCannedResponseEnabled && !signatureCrQueryReference && isExpanded) {
			loadSignatureCrQuery && loadSignatureCrQuery({ projectAri });
		}
	}, [
		isExpanded,
		isCannedResponseEnabled,
		signatureCrQueryReference,
		loadSignatureCrQuery,
		projectAri,
	]);

	const isHidden = isEditorFocused && shouldHide && !isExpanded;

	// We need to be able to determine if add comment should stick to the top.
	// Normally it should when sorted NEWEST_FIRST, but we want to avoid this when the issue isCompact
	// to prevent it from blocking the issue actions at the top.
	const shouldApplyTopSticky = sortOrder !== OLDEST_FIRST && !isCompact;

	const setSignatureCRData = useCallback(
		(signatureCR: string | null) => {
			setSignature(signatureCR);
		},
		[setSignature],
	);

	// AI Upsell In Editor Experiment
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [config] = UNSAFE_noExposureExp('jira_free_ai_feature_gate');
	const isAIUpsellInEditorFreeEnabled = config.get<boolean>('experimentEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAIUpsellInEditorFreeEnabled()
		: false;

	const addCommentEditor = useMemo(() => {
		const placeholder =
			hasDraft && !isOnlyWhitespaceAdf(newCommentDraft)
				? `• ${formatMessage(messages.draftPlaceholder)}`
				: formatMessage(messages.placeholder);

		const fallback = (
			<IssueAddCommentEditorLoading
				{...(fg('smart_replies_m1_kill_switch') && {
					isSmartRepliesEnabled: isSmartRepliesEnabled && !hasDraft,
				})}
				placeholder={placeholder}
				onExpanded={handleOnEditorExpandedWithAnalytics}
			/>
		);
		const isEditorCompact = !!containerWidth && containerWidth < COMPACT_EDITOR_WIDTH;
		const primaryToolbarComponents = isCannedResponseEnabled
			? (editorActions?: EditorActions) => [
					<CannedResponseToolbarButton
						key="cannedResponseToolbarButton"
						editorActions={editorActions}
						isCompact={isEditorCompact}
					/>,
				]
			: undefined;

		const commentButtonActions = isCannedResponseEnabled
			? {
					onFocus: prefetchSignatureCr,
					onMouseEnter: prefetchSignatureCr,
				}
			: undefined;
		const focusAtStart = !!(isCannedResponseEnabled && !newCommentDraft && signature);

		const addCommentEditorComponent = ({
			assistiveLabel = '',
			renderCustomSecondaryToolbar,
			isLoomJSMBannerEnabled,
		}: {
			assistiveLabel: string;
			renderCustomSecondaryToolbar?: RenderCustomSecondaryToolbar;
			isLoomJSMBannerEnabled?: boolean;
		}) => {
			if (isCannedResponseEnabled && signatureCrQueryReference && signature === undefined) {
				return (
					<SignatureCrFetchWrapper
						queryReference={signatureCrQueryReference}
						setSignatureCR={setSignatureCRData}
					/>
				);
			}

			return (
				<IssueAddCommentEditorBoundary packageName="issue" fallback={fallback}>
					<UFOSegment name="IssueAddCommentEditorReadView">
						<IssueAddCommentEditor
							externalId="issue.comment.add-form"
							defaultValue={newCommentDraft || signature || undefined}
							isExpanded={isExpanded}
							shouldFocus
							collapsedPlaceholder={placeholder}
							placeholder={
								// eslint-disable-next-line no-nested-ternary
								isCannedResponseEnabled
									? formatMessage(messages.cannedResponsesPlaceholder)
									: isAIUpsellInEditorFreeEnabled
										? formatMessage(messages.aiUpsellPlaceholder)
										: formatMessage(messages.easyMentionsPlaceholder)
							}
							onExpanded={handleOnEditorExpandedWithAnalytics}
							onChange={onEdit}
							onSave={handleOnSave}
							onPaste={onPaste}
							onBlur={onBlur}
							onCancel={onCancel}
							isLoading={isLoading}
							secondaryToolbarComponents={editorSecondaryToolbarComponents}
							isCannedResponseEnabled={isCannedResponseEnabled}
							{...(fg('smart_replies_m1_kill_switch') && {
								isSmartRepliesEnabled: isSmartRepliesEnabled && !hasDraft,
								isAIRepliesEnabled,
								hasDraft,
							})}
							isQuickRepliesEnabled={isQuickRepliesEnabled && !hasDraft}
							isLoomJSMBannerEnabled={isLoomJSMBannerEnabled}
							primaryToolbarComponents={primaryToolbarComponents}
							actionsRef={editorActionsRef}
							{...(assistiveLabel && { assistiveLabel })}
							focusAtStart={focusAtStart}
							renderCustomSecondaryToolbar={renderCustomSecondaryToolbar}
						/>
					</UFOSegment>
				</IssueAddCommentEditorBoundary>
			);
		};

		return isServiceDesk ? (
			<ServiceDeskCommentEditorContainer
				isExpanded={isExpanded}
				onExpanded={handleOnExpand}
				commentId={NEW_COMMENT_ID}
				commentButtonActions={commentButtonActions}
			>
				{addCommentEditorComponent}
			</ServiceDeskCommentEditorContainer>
		) : (
			addCommentEditorComponent({
				assistiveLabel: formatMessage(messages.comment),
			})
		);
	}, [
		hasDraft,
		newCommentDraft,
		formatMessage,
		handleOnEditorExpandedWithAnalytics,
		containerWidth,
		isCannedResponseEnabled,
		isExpanded,
		onEdit,
		handleOnSave,
		onPaste,
		onBlur,
		onCancel,
		isLoading,
		editorSecondaryToolbarComponents,
		editorActionsRef,
		isServiceDesk,
		handleOnExpand,
		signatureCrQueryReference,
		signature,
		setSignatureCRData,
		prefetchSignatureCr,
		isSmartRepliesEnabled,
		isAIUpsellInEditorFreeEnabled,
		isQuickRepliesEnabled,
		isAIRepliesEnabled,
	]);

	const shortcutKey = useMemo(() => <Lozenge>M</Lozenge>, []);
	const proTipText = useMemo(
		() => (
			<Text as="strong" color="color.text.subtle" size="UNSAFE_small">
				<FormattedMessage {...messages.commentShortcutHintHeading} />
			</Text>
		),
		[],
	);
	const avatarAriaLabel = useMemo(
		() =>
			displayName !== null && displayName !== ''
				? formatMessage(messages.userAvatarAriaLabel, {
						displayName,
					})
				: undefined,
		[displayName, formatMessage],
	);

	const commentShortCutHint = useMemo(
		() =>
			!isExpanded && (
				<CommentShortcutHint
					messageDescriptor={messages.commentShortcutHintMessage}
					values={{
						shortcutKey,
						proTipText,
					}}
				/>
			),
		[isExpanded, proTipText, shortcutKey],
	);

	return (
		<span ref={addCommentContainerRef}>
			<span ref={containerTopRef} />
			<StickyWrapper
				sortOrder={sortOrder}
				isModal={isLayered}
				isHidden={isHidden}
				isKeylineVisible={isKeylineVisible}
				shouldApplySticky={!isExpanded}
				shouldApplyTopSticky={shouldApplyTopSticky}
				isCompact={isCompact}
			>
				<Wrapper
					isModal={isLayered}
					isKeylineVisible={isKeylineVisible}
					sortOrder={sortOrder}
					shouldApplySticky={!isExpanded}
					shouldApplyTopSticky={shouldApplyTopSticky}
					ref={(container: HTMLElement | null) => {
						addCommentContainerBlockRef.current = container;
					}}
				>
					{expVal('issue-view-side-panel-activity', 'isActivityInSidePanel', false) ? null : (
						<AvatarContainer>
							<Avatar
								size={isVisualRefreshEnabled() ? 'small' : 'medium'}
								src={avatarUrl || undefined}
								name={avatarAriaLabel}
							/>
						</AvatarContainer>
					)}
					<EditorContainer
						ref={setEditorContainerRef}
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						data-testid="issue.activity.comment"
					>
						<ContextualAnalyticsData sourceType={SCREEN} sourceName="addCommentEditor">
							<FireScreenAnalytics />
							{addCommentEditor}
						</ContextualAnalyticsData>
						{commentShortCutHint}
					</EditorContainer>
				</Wrapper>
			</StickyWrapper>
			{/* This is needed for the 'real' position of the bottom of the add comment form
                    to dock the form.

                    In Firefox when the form becomes sticky, the position of the sticky element
                    changes to be the stuck position.

                    Therefore if we get the position from the sticky element it would cause a loop
                    of rerending sticking then unsticking. */}
			<span ref={containerBottomRef} />
		</span>
	);
};
