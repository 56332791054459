import keyBy from 'lodash/keyBy';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { wrapPromiseWithFetchInteraction } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/interaction/index.tsx';
import { performGetIssueTypesForProject } from '../../services/jira/fetch-issue-types.tsx';
import type { State } from '../types.tsx';
import { transformIssueTypeResponse } from './utils.tsx';

export const fetchIssueTypesForProject =
	(projectId: ProjectId) =>
	({ setState, getState }: StoreActionApi<State>) =>
		wrapPromiseWithFetchInteraction(
			performGetIssueTypesForProject(projectId),
			'getIssueTypesForProject',
		).then((issueTypes) => {
			const issueTypesById = keyBy(issueTypes.map(transformIssueTypeResponse), ({ id }) => id);
			setState({
				issueTypesById: {
					...getState().issueTypesById,
					...issueTypesById,
				},
				issueTypesByProjectId: {
					...getState().issueTypesByProjectId,
					[projectId]: Object.keys(issueTypesById),
				},
			});
		});
