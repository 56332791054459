import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import type { Hooks, LoomVideo, SDKResult, SDKState } from '@loomhq/record-sdk';

import { ExperienceTrackerContext } from '@atlassian/experience-tracker';
import type { EnvironmentType } from '@atlassian/iap-types';

import {
	OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC,
	type ProductType,
	RECORD_LOOM_ENTRY_POINT,
} from '../../constants';
import { initLoomSDK, type LoomSDKInitErrorType } from '../../utils/initLoomSDK';

export type EventHandler = Omit<Hooks, 'onInsertClicked'> & {
	onInsertClicked: (isInsertFallbackRendered: boolean) => void;
};

export type UseLoomRecorderEntryPointProps = {
	entryPointLocation: string;
	environment: EnvironmentType;
	cloudId: string;
	productName: ProductType;
	onInsert: (loomVideoUrl: string, video: LoomVideo) => void;
	insertButtonText: string;
	renderOnInsertFallbackWhen?: 'unmount' | (() => boolean);
	skipInitialisation?: boolean;
	eventHandler?: EventHandler;
};

export type CantRecordReason = 'already-recording' | 'still-initialising';

export type OpenLoomRecorderType = () => { isSuccess: boolean; failureReason?: CantRecordReason };

export type RecorderStateType =
	| 'initialisation-failed'
	| 'initialising'
	| 'ready'
	| 'pre-recording'
	| 'recording'
	| 'post-recording';
export type UseLoomRecorderEntryPointResponse =
	| {
			recorderState: Omit<RecorderStateType, 'initialisation-failed'>;
			openLoomRecorder: OpenLoomRecorderType;
			loomInitError: null;
	  }
	| {
			recorderState: 'initialisation-failed';
			loomInitError: LoomSDKInitErrorType;
			openLoomRecorder: null;
	  };

export type UseLoomRecorderEntryPointType = (
	props: UseLoomRecorderEntryPointProps,
) => UseLoomRecorderEntryPointResponse;

export const useLoomRecorderEntryPoint: UseLoomRecorderEntryPointType = ({
	entryPointLocation,
	environment,
	cloudId,
	productName,
	onInsert,
	insertButtonText,
	renderOnInsertFallbackWhen,
	skipInitialisation,
	eventHandler,
}: UseLoomRecorderEntryPointProps) => {
	const singletonLoomSDKRef = useRef<SDKResult>();
	const [loomInitError, setLoomInitError] = useState<LoomSDKInitErrorType>();
	const [recorderState, setRecorderState] = useState<RecorderStateType>('initialising');
	const updateOnInsertIfUnmountedRef = useRef<() => void>();
	const experienceTracker = useContext(ExperienceTrackerContext);
	useEffect(() => {
		async function initLoom() {
			const initResult = await initLoomSDK({
				environment,
				cloudId,
				productName,
				experienceTracker,
				entryPointLocation,
			});
			if (initResult.kind === 'success') {
				singletonLoomSDKRef.current = initResult.loomSDKInstance;
				// TODO - we need to set this to the current state of the singleton (maybe it's already recording), but currently no way to subscibe to changes in the global state
				setRecorderState('ready');
			} else {
				setLoomInitError(initResult.initError);
				setRecorderState('initialisation-failed');
			}
		}
		if (!skipInitialisation) {
			initLoom();
		}
		// Assumption - the dependencies cannot change without a full page regresh
	}, [
		environment,
		cloudId,
		productName,
		experienceTracker,
		skipInitialisation,
		entryPointLocation,
	]);

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		// this will run on unmount
		return function changeOnInsertBehaviorOnUnmount() {
			if (!singletonLoomSDKRef.current || !updateOnInsertIfUnmountedRef.current) {
				return;
			}
			if (renderOnInsertFallbackWhen === 'unmount') {
				updateOnInsertIfUnmountedRef.current();
			}
		};
	}, [renderOnInsertFallbackWhen]);

	const openLoomRecorder = useCallback<OpenLoomRecorderType>(() => {
		OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC.start();
		experienceTracker.start({
			id: RECORD_LOOM_ENTRY_POINT,
			name: RECORD_LOOM_ENTRY_POINT,
			attributes: { entryPointLocation },
		});
		// TODO - another instance can try to open the recorder while it's doing something else, we should disallow that by subscribing to the singleton state
		if (singletonLoomSDKRef.current === undefined) {
			OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC.cancel();
			const failureReason = 'still-initialising';
			experienceTracker.fail({
				name: RECORD_LOOM_ENTRY_POINT,
				error: new Error(failureReason),
				attributes: { entryPointLocation },
			});
			return { isSuccess: false, failureReason };
		}
		if (singletonLoomSDKRef.current.status().state === 'active-recording') {
			OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC.cancel();
			const failureReason = 'already-recording';
			experienceTracker.abort({
				name: RECORD_LOOM_ENTRY_POINT,
				reason: failureReason,
				attributes: { entryPointLocation },
			});
			return { isSuccess: false, failureReason };
		}

		const { configureButton, updateConfig } = singletonLoomSDKRef.current;

		const sdkButton = configureButton();

		updateConfig({
			config: {
				insertButtonText,
				entryPointName: entryPointLocation,
			},
		});
		sdkButton.on('start', () => {
			eventHandler?.onStart?.();
		});

		sdkButton.on('recording-start', () => {
			eventHandler?.onRecordingStarted?.();
			setRecorderState('recording');
		});
		sdkButton.on('cancel', () => {
			eventHandler?.onCancel?.();
		});
		sdkButton.on('complete', () => {
			eventHandler?.onComplete?.();
		});
		sdkButton.on('recording-complete', (video: LoomVideo) => {
			eventHandler?.onRecordingComplete?.(video);
		});
		sdkButton.on('upload-complete', (video: LoomVideo) => {
			eventHandler?.onUploadComplete?.(video);
		});
		sdkButton.on('insert-click', (video: LoomVideo) => {
			onInsert(video.sharedUrl, video);
			eventHandler?.onInsertClicked?.(false);
			experienceTracker.succeed({
				name: RECORD_LOOM_ENTRY_POINT,
				attributes: {
					inserted: true,
					entryPointLocation,
				},
			});
		});
		updateOnInsertIfUnmountedRef.current = () => {
			updateConfig({
				config: {
					// TODO - format message?
					insertButtonText: 'Open on Loom',
				},
			});
			sdkButton.off('insert-click');
			sdkButton.on('insert-click', (video: LoomVideo) => {
				if (typeof window !== 'undefined') {
					window.open(video.sharedUrl, '_blank');
					experienceTracker.succeed({
						name: RECORD_LOOM_ENTRY_POINT,
						attributes: {
							inserted: true,
							insertFunctionWasReset: true,
							entryPointLocation,
						},
					});
				}
				eventHandler?.onInsertClicked?.(true);
			});
		};
		sdkButton.on('lifecycle-update', (lifecycleState: SDKState) => {
			if (lifecycleState === 'pre-recording') {
				setRecorderState('pre-recording');
				OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC.stop();
			} else if (lifecycleState === 'post-recording') {
				if (
					typeof renderOnInsertFallbackWhen === 'function' &&
					renderOnInsertFallbackWhen() &&
					!!updateOnInsertIfUnmountedRef.current
				) {
					updateOnInsertIfUnmountedRef.current();
				}
				setRecorderState('post-recording');
			} else if (lifecycleState === 'closed') {
				setRecorderState('ready');
				experienceTracker.succeed({
					name: RECORD_LOOM_ENTRY_POINT,
					attributes: {
						closed: true,
						entryPointLocation,
					},
				});
				updateOnInsertIfUnmountedRef.current = undefined;
			}
		});

		sdkButton.openPreRecordPanel();
		return { isSuccess: true };
	}, [
		onInsert,
		entryPointLocation,
		insertButtonText,
		renderOnInsertFallbackWhen,
		experienceTracker,
		eventHandler,
	]);

	return loomInitError
		? {
				recorderState: 'initialisation-failed',
				loomInitError,
				openLoomRecorder: null,
			}
		: {
				openLoomRecorder,
				loomInitError: null,
				recorderState,
			};
};
