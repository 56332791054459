import { fg } from '@atlassian/jira-feature-gating';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	DELIVERY_CALCULATION_STRATEGIES,
	DELIVERY_CALCULATION_MODE,
} from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import type { ArchivedFilter } from '@atlassian/jira-polaris-remote-issue/src/common/types.tsx';
import type {
	AggregatedDeliveryProgressResponse,
	DateFieldConfiguration,
} from '@atlassian/jira-polaris-remote-issue/src/services/jira/types.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import type { IssueId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdsByJiraIssueId } from '../../../selectors/issue-ids.tsx';
import { getAllIssuesProjectIds } from '../../../selectors/properties/project/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { setLoadingLinkedIssuesState } from '../../utils/index.tsx';
import { fireErrorAnalytics } from '../fire-error-analytics/index.tsx';
import { getFieldConfigurations } from '../get-field-configurations/index.tsx';
import type { DeliveryAnalyticsAttributes } from '../types.tsx';
import { replaceOmitRecordKeys } from './replace-omit-record-keys/index.tsx';

export const loadAggregatedDeliveryProgress =
	(
		shouldLoadLinkedIssues = false,
		providedDeliveryStrategy?: 'default' | 'simple',
		providedCalculationMode?: 'issue_count' | 'story_points',
		issueIds?: IssueId[],
		archivedOnly?: boolean,
	) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		if (shouldLoadLinkedIssues) {
			setState(setLoadingLinkedIssuesState(getState(), true));
		}

		const projectIds = props.projectId ? [props.projectId] : getAllIssuesProjectIds(getState());

		projectIds.forEach((projectId) => {
			dispatch(
				loadAggregatedDeliveryProgressWithProject(
					projectId,
					shouldLoadLinkedIssues,
					providedDeliveryStrategy,
					providedCalculationMode,
					issueIds,
					archivedOnly,
				),
			);
		});
	};

const getDeliveryStatusField = (fields: Field[] | undefined): Field | undefined => {
	const deliveryStatusFields = fields?.filter(
		(field) => field.type === FIELD_TYPES.DELIVERY_STATUS,
	);

	if (!deliveryStatusFields || deliveryStatusFields.length === 0) {
		return undefined;
	}

	if (deliveryStatusFields.length === 1) {
		return deliveryStatusFields[0];
	}

	// This part will be triggered only when BE returns both global and project scoped delivery fields
	if (deliveryStatusFields.length > 1) {
		const globalDeliveryStatusField = deliveryStatusFields.find(({ global }) => global);
		const projectDeliveryStatusField = deliveryStatusFields.find(({ global }) => !global);

		if (fg('polaris_new_custom_types_global_system_fields')) {
			return globalDeliveryStatusField ?? projectDeliveryStatusField;
		}
		return projectDeliveryStatusField ?? globalDeliveryStatusField;
	}
};

const loadAggregatedDeliveryProgressWithProject =
	(
		projectId?: ProjectId,
		shouldLoadLinkedIssues = false,
		providedDeliveryStrategy?: 'default' | 'simple',
		providedCalculationMode?: 'issue_count' | 'story_points',
		issueIds?: IssueId[],
		archivedOnly?: boolean,
	) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		if (shouldLoadLinkedIssues) {
			setState(setLoadingLinkedIssuesState(getState(), true));
		}
		const jiraIdToLocalIdMap = getLocalIssueIdsByJiraIssueId(getState(), props);
		const { fields, createAnalyticsEvent } = props;

		if (projectId === undefined) {
			return;
		}

		const deliveryField = getDeliveryStatusField(fields);

		const calculationStrategy =
			providedDeliveryStrategy ||
			deliveryField?.presentation?.type ||
			DELIVERY_CALCULATION_STRATEGIES.DEFAULT;
		const calculationMode =
			providedCalculationMode ||
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(deliveryField?.formula as LinkedIssuesFormula)?.parameters?.calculationMode ||
			DELIVERY_CALCULATION_MODE.ISSUE_COUNT;

		const analyticsAttributes: DeliveryAnalyticsAttributes = {
			deliveryDatesCount: 0,
			deliveryDatesPresent: false,
			calculationMode,
		};

		const dateFieldConfigurations: DateFieldConfiguration[] = getFieldConfigurations(fields);
		if (dateFieldConfigurations.length > 0) {
			analyticsAttributes.deliveryDatesCount = dateFieldConfigurations.length;
			analyticsAttributes.deliveryDatesPresent = true;
		}

		const fetchAggregatedStartTimestamp = Date.now();

		let archivedFilter: ArchivedFilter | undefined;
		if (!Array.isArray(issueIds) || issueIds?.length === 0) {
			archivedFilter = archivedOnly ? 'ARCHIVED_ONLY' : 'ACTIVE_ONLY';
		}

		const handleResponse = (response: Awaited<AggregatedDeliveryProgressResponse>) => {
			const { properties, meta } = getState();

			const aggregatedDeliveryProgressByLocalId = replaceOmitRecordKeys(
				response.ideaToProgressAggregation,
				jiraIdToLocalIdMap,
			);

			const aggregatedDeliveryDatesByLocalId = replaceOmitRecordKeys(
				response.ideaDateAggregation,
				jiraIdToLocalIdMap,
			);

			if (!issueIds || issueIds.length === 0) {
				const sumOfAllProgressIssues = Object.values(response.ideaToProgressAggregation)
					.flat()
					.reduce((acc, val) => acc + val, 0);

				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadDeliveryData success', {
					durationMs: Date.now() - fetchAggregatedStartTimestamp,
					sumOfAllProgressIssues,
					archived: archivedFilter === 'ARCHIVED_ONLY',
				});
			}

			setState({
				properties: {
					...properties,
					aggregatedDeliveryProgress: {
						...properties.aggregatedDeliveryProgress,
						...aggregatedDeliveryProgressByLocalId,
					},
					aggregatedDeliveryDates: {
						...properties.aggregatedDeliveryDates,
						...aggregatedDeliveryDatesByLocalId,
					},
				},
				meta: {
					...meta,
					loadingLinkedIssues: false,
					restrictedDeliveryIssuesCount: response.aggregationLimit,
					fullDeliveryDataInitialized: true,
				},
			});
		};

		props.issuesRemote
			.fetchAggregatedDeliveryProgress({
				projectId,
				issueIds,
				calculationStrategy,
				calculationMode,
				dateFieldConfigurations,
				archivedFilter,
			})
			.then(handleResponse)
			.catch((error) => {
				fireErrorAnalytics(props.createAnalyticsEvent, fetchAggregatedStartTimestamp);
				setState(setLoadingLinkedIssuesState(getState(), false));
				props.onDeliveryDataFetchFailed(error);
			});

		fireTrackAnalytics(props.createAnalyticsEvent({}), 'deliveryData loaded', analyticsAttributes);
	};
