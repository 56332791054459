import React, {
	useCallback,
	useState,
	type PropsWithChildren,
	type ReactNode,
	type SyntheticEvent,
} from 'react';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';

type RoundedSectionProps = PropsWithChildren<{
	header: ((params: { isOpen: boolean }) => ReactNode) | ReactNode;
	status?: ReactNode;
	triggerRef?: EntryPointButtonTrigger;
	spacing?: 'default' | 'compact';
	appearance?: 'default' | 'subtle';
}>;

export const RoundedSection = ({
	header,
	status,
	triggerRef,
	spacing = 'default',
	appearance = 'default',
	children,
}: RoundedSectionProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const onToggle = useCallback(
		(e: SyntheticEvent<HTMLDetailsElement, Event>) => {
			e.stopPropagation();
			setIsOpen(!isOpen);
		},
		[isOpen],
	);

	return (
		<Box xcss={containerStyles}>
			<Box as="details" ref={triggerRef} open={isOpen} onToggle={onToggle}>
				<Box
					as="summary"
					xcss={[
						summaryStyles,
						spacing === 'compact' ? compactSummarySpacing : defaultSummarySpacing,
					]}
				>
					<Inline spread="space-between" alignBlock="center">
						<Inline space="space.150" alignBlock="center">
							{typeof header === 'function' ? header({ isOpen }) : header}
						</Inline>
						<Inline space="space.100" alignBlock="center">
							{status}
							<Box xcss={iconButtonStyles}>
								{isOpen ? (
									<ChevronUpIcon
										LEGACY_size={appearance === 'subtle' ? 'medium' : 'large'}
										color={
											appearance === 'subtle' ? token('color.icon.subtle') : token('color.icon')
										}
										label=""
									/>
								) : (
									<ChevronDownIcon
										LEGACY_size={appearance === 'subtle' ? 'medium' : 'large'}
										color={
											appearance === 'subtle' ? token('color.icon.subtle') : token('color.icon')
										}
										label=""
									/>
								)}
							</Box>
						</Inline>
					</Inline>
				</Box>
				{isOpen && (
					<Box xcss={[bodyStyles, spacing === 'compact' ? compactBodySpacing : defaultBodySpacing]}>
						{children}
					</Box>
				)}
			</Box>
		</Box>
	);
};

const containerStyles = xcss({
	borderColor: 'color.border',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderRadius: 'border.radius.100',
});

const summaryStyles = xcss({
	cursor: 'pointer',
	':hover': {
		backgroundColor: 'elevation.surface.hovered',
	},
	listStyle: 'none',

	// This is necessary to remove the default triangle in Safari --> https://atlassian.slack.com/archives/C060GTVV988/p1729077384846149
	// @ts-expect-error - Object literal may only specify known properties, and ''::-webkit-details-marker'' does not exist in type 'SafeCSSObject'.
	'::-webkit-details-marker': {
		display: 'none',
	},
});

const bodyStyles = xcss({
	borderTopColor: 'color.border',
});

const iconButtonStyles = xcss({
	paddingRight: 'space.050',
});

const compactSummarySpacing = xcss({
	paddingBlock: 'space.100',
	paddingInline: 'space.200',
});

const defaultSummarySpacing = xcss({
	paddingInline: 'space.200',
	paddingBlock: 'space.150',
});

const compactBodySpacing = xcss({
	paddingTop: 'space.050',
	paddingBottom: 'space.200',
	paddingInline: 'space.200',
});

const defaultBodySpacing = xcss({
	paddingInline: 'space.200',
	paddingTop: 'space.050',
	paddingBottom: 'space.150',
});
