import { createSelector } from 'reselect';
import { findViewWithSlug } from '../../../actions/utils/views/index.tsx';
import type { Props, State } from '../../../types.tsx';

export const getCurrentView = createSelector(
	(state: State) => state.viewSets,
	(_: State, props: Props | undefined) => props?.currentViewSlug,
	findViewWithSlug,
);

export const getPreviousView = createSelector(
	(state: State) => state.viewSets,
	(_: State, props: Props | undefined) => props?.currentViewSlug,
	(state: State) => state?.prevContainerProps?.currentViewSlug,
	(viewSets, currentViewSlug, prevViewSlug) => {
		if (prevViewSlug === currentViewSlug) {
			return undefined;
		}
		return findViewWithSlug(viewSets, prevViewSlug);
	},
);

export const getSharedViewConfigError = createSelector(getCurrentView, (view) => view?.configError);

export const hasSharedViewConfigError = createSelector(
	getSharedViewConfigError,
	(configError) => configError !== undefined,
);
