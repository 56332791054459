/**
 * @generated SignedSource<<c61fe0aad1f72072c70d19cc160f4eab>>
 * @relayHash 9f78a91195938f86c7378a9050a9480a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ecfef57414f0325324e149d240876f35fdddc141af2587c636f018987c03c408

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type team_newTeamSearchQuery$variables = {
  id: string;
  organisationId: string;
  sessionId: string;
  siteId: string;
};
export type team_newTeamSearchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef">;
};
export type team_newTeamSearchQuery = {
  response: team_newTeamSearchQuery$data;
  variables: team_newTeamSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sessionId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v4 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v5 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
},
v6 = {
  "kind": "Variable",
  "name": "sessionId",
  "variableName": "sessionId"
},
v7 = {
  "kind": "Variable",
  "name": "siteId",
  "variableName": "siteId"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  },
  (v6/*: any*/)
],
v10 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "team_newTeamSearchQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "team_newTeamSearchQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraTeamViewConnection",
                "kind": "LinkedField",
                "name": "teams",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraTeamViewEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraTeamView",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "jiraSuppliedId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "jiraMemberCount"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "jiraIncludesYou"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "jiraSuppliedIsVerified"
                          },
                          {
                            "args": [
                              (v7/*: any*/)
                            ],
                            "concreteType": "TeamV2",
                            "kind": "LinkedField",
                            "name": "fullTeam",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": "name",
                                "kind": "ScalarField",
                                "name": "displayName"
                              },
                              {
                                "alias": "avatarUrl",
                                "kind": "ScalarField",
                                "name": "smallAvatarImageUrl"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "description"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "isVerified"
                              }
                            ]
                          },
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v9/*: any*/),
                "filters": [
                  "searchBy",
                  "organisationId",
                  "sessionId"
                ],
                "handle": "connection",
                "key": "team_issueFieldTeamEditviewFullNew_teams",
                "kind": "LinkedHandle",
                "name": "teams"
              }
            ],
            "type": "JiraTeamViewField"
          },
          (v10/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "ecfef57414f0325324e149d240876f35fdddc141af2587c636f018987c03c408",
    "metadata": {},
    "name": "team_newTeamSearchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "89f8dffd337cca5181a2fa0d85774437";

export default node;
