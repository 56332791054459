import React, { useEffect, useRef, useState } from 'react';
import { useQueryLoader } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import PanelViewEntitlementQuery, {
	type panelViewEntitlementQuery,
} from '@atlassian/jira-relay/src/__generated__/panelViewEntitlementQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEntitlementFieldValue } from '../../common/controllers/use-entitlement-field-value/index.tsx';
import { Header } from '../../common/ui/details/index.tsx';
import { ExpandPanel } from '../../common/ui/expand-panel/index.tsx';
import { EntitlementField } from './entitlement-field/index.tsx';
import messages from './messages.tsx';
import { PanelView } from './panel-view/index.tsx';

export const Entitlement = () => {
	const cloudId = useCloudId();
	const issueId = useIssueId() || '';
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [queryReference, loadQuery, disposeQuery] =
		useQueryLoader<panelViewEntitlementQuery>(PanelViewEntitlementQuery);
	const fieldValue = useEntitlementFieldValue();
	const idRef = useRef<string | undefined>(fieldValue?.id);
	const hasQueryReference = !!queryReference;

	useEffect(() => {
		if (isOpen && (!fieldValue || idRef.current !== fieldValue?.id)) {
			setIsOpen(false);
		}

		if (!isOpen && hasQueryReference) {
			disposeQuery();
		}

		idRef.current = fieldValue?.id;
	}, [loadQuery, disposeQuery, fieldValue, cloudId, isOpen, hasQueryReference]);

	const onToggle = () => {
		setIsOpen((prev) => {
			if (!prev && fieldValue) {
				loadQuery({
					cloudId,
					entitlementId: fieldValue.id,
					filter: fg('jcs_detail_permissions')
						? { context: { type: 'ISSUE', issueId } }
						: { context: { type: 'ISSUE' } },
					jcsIssueLayoutEapEnabled: fg('jcs_issue_layout_eap'),
				});
			}

			return !prev;
		});
	};

	if (!fieldValue) {
		return <></>;
	}

	return (
		<ContextualAnalyticsData sourceName="issueViewPanelEntitlementDetails" sourceType={SCREEN}>
			<div data-testid="servicedesk-customer-service-issue-view-panel.ui.entitlement.field-container">
				<Header>{formatMessage(messages.entitlement)}</Header>
				<ExpandPanel
					id="entitlement"
					onToggle={onToggle}
					field={<EntitlementField />}
					isOpen={isOpen}
					source="ENTITLEMENT_FIELD"
				>
					{isOpen && queryReference && (
						<div data-testid="servicedesk-customer-service-issue-view-panel.ui.entitlement.div">
							<PanelView queryReference={queryReference} />
						</div>
					)}
				</ExpandPanel>
			</div>
		</ContextualAnalyticsData>
	);
};
