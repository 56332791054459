// eslint-disable-next-line jira/restricted/react-component-props
import React, { useState, useRef, useCallback, useEffect, type ComponentProps } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { ErrorBoundaryFlag } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { getIsEditFromIssueView } from '@atlassian/jira-issue-analytics/src/utils/get-is-edit-from-issue-view/index.tsx';
import {
	useCanConfigureStatus,
	shouldShowConfigureIssueTypeOrStatusOption,
} from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import { STATUS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	SCOPE_GLOBAL,
	SCOPE_PROJECT,
} from '@atlassian/jira-project-configuration-commons/src/model/constants.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	toIssueTypeId,
	type IssueTypeId,
	type ProjectId,
	toProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { StatusValue } from '../common/types.tsx';
import { StatusService } from '../services/status-service/index.tsx';
import { TransitionsQuery } from '../services/transitions-query/index.tsx';
import ViewWorkflowContextServiceDI from '../services/view-workflow-service/index.tsx';
import StatusView from './status-view/index.tsx';
import type { Props } from './types.tsx';
import ViewWorkflow from './view-workflow/index.tsx';
import WorkflowModal from './workflow-modal/index.tsx';

export const StatusField = ({
	isOptimistic = true,
	ViewWorkflowContextService = ViewWorkflowContextServiceDI,
	preFetchTransitions = false,
	projectType = null,
	onEditStart = noop,
	onEditCancel = noop,
	onSubmit = noop,
	onSuccess = noop,
	onFailure = noop,
	issueKey,
	issueId,
	isEditable,
	initialValue,
	appearance,
	value,
	statusButtonRef: statusButtonRefExternal,
	popperProps,
	registerInCommandPalette,
	onUpdateStatusMutation,
}: Props) => {
	// All these states can be removed on clean up of cmp_support_for_readonly_workflow_modal
	const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
	const [isWorkflowModalMounted, setisWorkflowModalMounted] = useState(false);
	const [issueTypeId, setIssueTypeId] = useState(toIssueTypeId(''));
	const [projectId, setProjectId] = useState(toProjectId(''));
	const previousIsWorkflowModalOpen = usePrevious(isWorkflowModalOpen);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const projectKey = useProjectKey(issueKey);
	const canConfigureStatus = useCanConfigureStatus(projectKey);
	const showConfigureStatusButtons =
		canConfigureStatus && shouldShowConfigureIssueTypeOrStatusOption(projectType);

	const statusButtonRef = useRef<HTMLElement>(null);

	const tenantContext = useTenantContext();

	useEffect(() => {
		if (previousIsWorkflowModalOpen === true && isWorkflowModalOpen === false) {
			if (statusButtonRef?.current) {
				statusButtonRef.current.focus();
			}
		}
	}, [isWorkflowModalOpen, previousIsWorkflowModalOpen]);

	const onOpenModal = useCallback((issueTypeIdInArgs: IssueTypeId, projectIdInArgs: ProjectId) => {
		setIsWorkflowModalOpen(true);
		setIssueTypeId(issueTypeIdInArgs);
		setProjectId(projectIdInArgs);
	}, []);

	const onCloseModal = useCallback(() => {
		if (fg('cmp_support_for_readonly_workflow_modal')) {
			statusButtonRef?.current?.focus();
		} else {
			setIsWorkflowModalOpen(false);
		}
	}, []);

	const onModalMounted = useCallback(() => {
		setisWorkflowModalMounted(true);
	}, []);

	return !tenantContext ? null : (
		<StatusService
			appearance={appearance}
			value={value}
			projectType={projectType}
			initialValue={initialValue}
			issueKey={issueKey}
			issueId={issueId}
			baseUrl={tenantContext.baseUrl}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			onFailure={onFailure}
			onEditStart={onEditStart}
			onEditCancel={onEditCancel}
			{...(fg('relay-migration-issue-fields-status')
				? {
						onUpdateStatusMutation,
					}
				: {})}
		>
			{({ value: statusServiceValue, loading: saving, transitionStatus, error, resetError }) => (
				<TransitionsQuery
					issueKey={issueKey}
					baseUrl={tenantContext.baseUrl}
					// this will force an empty list of transitions when the status changes
					status={statusServiceValue}
					isSaving={saving}
					preFetch={preFetchTransitions}
					projectType={projectType}
				>
					{({ data: transitions, loading, fetch, ui }) => (
						<ViewWorkflowContextService baseUrl={tenantContext.baseUrl} issueKey={issueKey}>
							{(workflowServiceApi) => {
								const isSaving = fg('issue-view-status-transition-optimistic-update')
									? saving || Boolean(ui?.transitionInProgress)
									: saving;

								const viewWorkflowFooter = (
									<JSErrorBoundary id="issue-field-view-workflow">
										<ViewWorkflow
											baseUrl={tenantContext.baseUrl}
											issueKey={issueKey}
											workflowServiceApi={workflowServiceApi}
											onOpenModal={onOpenModal}
											isModalMounted={isWorkflowModalMounted}
											onCloseModal={
												fg('cmp_support_for_readonly_workflow_modal') ? onCloseModal : undefined
											}
										/>
									</JSErrorBoundary>
								);

								const shouldRenderConfigureStatusButtons =
									showConfigureStatusButtons &&
									expValEquals('jira_issue_view_inline_status_creation', 'cohort', 'variation');

								return (
									<>
										<StatusView
											statusButtonRef={statusButtonRefExternal || statusButtonRef}
											value={statusServiceValue}
											isEditable={isEditable}
											isLoading={loading || saving || Boolean(ui?.transitionInProgress)}
											isSaving={isOptimistic === true ? false : isSaving}
											appearance={appearance}
											transitions={transitions}
											onOpen={(analyticsEvent: UIAnalyticsEvent) => {
												onEditStart();

												if (fg('one_event_rules_them_all_fg')) {
													const startEvent = createAnalyticsEvent({});
													getUpdateAnalyticsFlowHelper().fireAnalyticsStart(STATUS_TYPE, {
														analytics: startEvent,
														attributes: {
															fieldType: FIELD_TYPE_MAP[STATUS_TYPE],
															isInlineEditing: !getIsEditFromIssueView(startEvent),
														},
													});
												}

												fetch(analyticsEvent);

												const hasFetchedWorkflowService =
													workflowServiceApi.data &&
													workflowServiceApi.data.hasPermission !== undefined;
												if (hasFetchedWorkflowService !== true) {
													workflowServiceApi.fetch();
												}
												resetError();
											}}
											onClose={onEditCancel}
											onChange={transitionStatus}
											error={error}
											footer={viewWorkflowFooter}
											popperProps={popperProps}
											registerInCommandPalette={registerInCommandPalette}
											onSuccess={onSuccess}
											onFetch={fetch}
											{...(fg('relay-migration-issue-fields-status')
												? {
														onUpdateStatusMutation,
													}
												: {})}
											showConfigureStatusButtons={shouldRenderConfigureStatusButtons}
											projectId={workflowServiceApi.data?.projectId}
											issueTypeId={workflowServiceApi.data?.issueTypeId}
											projectKey={projectKey}
											projectType={projectType}
										/>

										{fg('cmp_support_for_readonly_workflow_modal') ? null : (
											<ErrorBoundaryFlag>
												<WorkflowModal
													onModalMounted={onModalMounted}
													issueKey={issueKey}
													issueTypeId={issueTypeId}
													baseUrl={tenantContext.baseUrl}
													locale={tenantContext.locale}
													cloudId={tenantContext.cloudId}
													projectId={projectId}
													isOpen={isWorkflowModalOpen}
													onClose={onCloseModal}
													scope={
														workflowServiceApi.data?.isSimplified === true
															? SCOPE_PROJECT
															: SCOPE_GLOBAL
													}
												/>
											</ErrorBoundaryFlag>
										)}
									</>
								);
							}}
						</ViewWorkflowContextService>
					)}
				</TransitionsQuery>
			)}
		</StatusService>
	);
};

export default StatusField;

/**
 * We had to add these here to avid circular dependency.
 * The issue here is StatusField component has some defaultProps which were not included as optional in types earlier
 * So we created a new prop to be used from async file.
 */
type BaseProps = JSX.LibraryManagedAttributes<
	typeof StatusField,
	ComponentProps<typeof StatusField>
>;
export type ExpProps = BaseProps & {
	initialValue?: StatusValue;
	value?: StatusValue;
	statusButtonRef?: {
		current: HTMLElement | null;
	};
};
