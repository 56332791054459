import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../../config-items/config-items';
import { Icon as PageIcon } from '../../assets/icons/issueReformatter';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';

import { messages } from './messages';

export type GetAdditionalProps = () => Partial<{
	issue_summary?: string;
	issue_type_label?: string;
}>;

type Options = {
	getAdditionalProps: GetAdditionalProps;
};

export const createImproveDescription = (options: Options): EditorPluginAIConfigItem => ({
	key: CONFIG_ITEM_KEYS.ADD_STRUCTURE,
	title: messages.improveDescription,
	description: messages.reformatWithTemplateDescription,
	icon: ({ shownAt }) => <PageIcon shownAt={shownAt} />,
	experienceId: 'issue-reformatter',
	intentSchemaId: 'issue_reformatter_intent_schema.json',
	alwaysNeedDoc: true,
	...(fg('platform_gravityai-1853_issue_reformatter_ga') ? {} : { statusLozengeType: 'beta' }),
	getRequestPayload: ({ aiSessionId, locale, doc }) => {
		return {
			// TODO convo-ai places ai_session_id in agent_input_context, why jira places it in root level?
			ai_session_id: aiSessionId,
			recipient_agent_named_id: 'jira_issue_reformatter_agent',
			agent_input_context: {
				locale,
				issue_description: doc,
				...options.getAdditionalProps?.(),
			},
		};
	},
	actions: {
		empty: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => 'assistance-service',
});

export const createImproveDescriptionWithOptions = (
	options: Options,
): EditorPluginAIConfigItemWithOptions => ({
	config: createImproveDescription(options),
	triggers: { empty: { docMinSize: 1 }, selection: true },
});
