import { useMemo } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { LayoutContainerTemplateItem } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import type { AdditionalEventAttributes } from '@atlassian/jira-issue-view-layout-group/src/common/types.tsx';
import { useLayoutItem } from '@atlassian/jira-issue-view-layout-templates-item-list-services/src/index.tsx';
import {
	GROUP_APP,
	GROUP_CUSTOM,
	GROUP_PANEL,
	GROUP_SYSTEM,
	type IssueViewLayoutItemTypeWithAnalytics,
} from '@atlassian/jira-issue-view-layout-templates-item-list/src/types.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { useSecondaryItemIds } from '@atlassian/jira-issue-view-layout/src/utils.tsx';

type LayoutGroupsEvents = {
	appFieldTypes: string[];
	customFieldTypes: string[];
	panelItemTypes: string[];
	systemFieldTypes: string[];
};

export const getSecondaryFieldsFromGroup = (
	secondaryItemIds: string[],
	fields: IssueViewLayoutItemTypeWithAnalytics[],
): string[] =>
	fields.map((field) => getLayoutItemId(field)).filter((id) => secondaryItemIds.includes(id));

// Groups fields depending on whether they are custom or not
export const groupFieldsByType = (fields: IssueViewLayoutItemTypeWithAnalytics[]) => {
	const groups: LayoutGroupsEvents = {
		appFieldTypes: [],
		customFieldTypes: [],
		panelItemTypes: [],
		systemFieldTypes: [],
	};

	fields.forEach((field) => {
		const { analytics } = field;
		switch (analytics.group) {
			case GROUP_APP:
				groups.appFieldTypes.push(analytics.value);
				break;
			case GROUP_CUSTOM:
				groups.customFieldTypes.push(analytics.value);
				break;
			case GROUP_PANEL:
				groups.panelItemTypes.push(analytics.value);
				break;
			case GROUP_SYSTEM:
				groups.systemFieldTypes.push(analytics.value);
				break;
			default:
				break;
		}
	});

	return groups;
};

// Helper function that creates the Additional Analytics object
export const generateAdditionalAnalyticsEventAttributes = (
	fields: LayoutGroupsEvents,
	numOfFields: number,
	numHiddenWhenEmptyItems: number,
): AdditionalEventAttributes => {
	const { appFieldTypes, customFieldTypes, panelItemTypes, systemFieldTypes } = fields;

	return {
		numHiddenWhenEmptyItems,
		panelItemTypes,
		systemFieldTypes,
		customFieldTypes,
		appFieldTypes,
		numOfFields,
	};
};

export const useGenerateAdditionalAnalyticsEventAttributes = (
	fields: LayoutContainerTemplateItem[],
): AdditionalEventAttributes => {
	const issueKey = useIssueKey();
	const secondaryItemIds = useSecondaryItemIds(issueKey);
	const layoutItemFields = useLayoutItem(fields);

	const numHiddenWhenEmptyItems = useMemo(
		() => getSecondaryFieldsFromGroup(secondaryItemIds, layoutItemFields).length,
		[layoutItemFields, secondaryItemIds],
	);

	const fieldsByGroupType = useMemo(() => groupFieldsByType(layoutItemFields), [layoutItemFields]);

	const additionalAnalyticsEvents = useMemo(
		() =>
			generateAdditionalAnalyticsEventAttributes(
				fieldsByGroupType,
				layoutItemFields.length,
				numHiddenWhenEmptyItems,
			),
		[fieldsByGroupType, layoutItemFields.length, numHiddenWhenEmptyItems],
	);

	return additionalAnalyticsEvents;
};
