/**
 * @module RenderChildrenOnServer.tsx
 */

import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

/*
 * This component will render children on the server when the feature flag is on.
 * The server implementation is in RenderChildrenOnServer.server.tsx which is used in SSR builds.
 * This is to be used at the highest level of the route or component that we are converting from not SSR'd to SSR, to ensure no new code runs when the fg is off.
 *
 * This client version does nothing, just firing an exposure so that we can measure the changes and **always** returning children.
 */
export function RenderChildrenOnServer({ children }: Props) {
	// evaluated so that an exposure is fired on the client
	// This will also evaluate on transition, we will need to investigate if we need to only fire when the SPA first mounts, if it is possible to get that data.
	fg('issue-view-ssr-perf-m1');
	return children;
}
