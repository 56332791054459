import keyBy from 'lodash/keyBy';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { PermissionsListTransformed } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/permissions.tsx';
import { RICH_CONTENT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type {
	Field,
	FieldsState,
	ServerField,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { Agile } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import type {
	Tab,
	Tabs,
} from '@atlassian/jira-issue-shared-types/src/common/types/layout-item-type.tsx';
import type {
	IssueLinks,
	ServerIssueLinks,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type {
	ParentIssue,
	ServerParentIssue,
} from '@atlassian/jira-issue-shared-types/src/common/types/parent-issue-type.tsx';
import {
	type ServerProject,
	type ProjectContext,
	type ProjectStatus,
	PROJECT_ARCHIVED,
	PROJECT_TRASHED,
	PROJECT_ACTIVE,
} from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import type { StatusDeprecated } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import type { Subtask } from '@atlassian/jira-issue-shared-types/src/common/types/subtask-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import type {
	EpicIssue as ServerEpicIssue,
	NextGenChildIssue as ServerNextGenChildIssue,
	PortfolioIssue as ServerPortfolioIssue,
	Subtask as ServerSubtask,
} from '@atlassian/jira-issue-view-common-types/src/issue-server-type.tsx';
import type {
	PermissionKeys,
	ServerPermissions,
} from '@atlassian/jira-issue-view-common-types/src/permissions-type.tsx';
import type { GraphQLIssueResponse } from '@atlassian/jira-issue-view-common-utils/src/graphql/index.tsx';

import { DESCRIPTION, ENVIRONMENT } from '@atlassian/jira-issue-view-configurations/src/index.tsx';

import {
	emptyAdfObject,
	removeCollectionFromAdf,
} from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { toBaseUrl, toIssueId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { transformChildrenNew } from './child-issue-transformer-new.tsx';
import { transformChildren } from './child-issue-transformer.tsx';
import { transformIssueLinks } from './issue-links-transformer.tsx';
import { transformSubtasks } from './subtasks-transformer.tsx';

type TransformedIssue = {
	childIssuesLimit: undefined;
	project: ProjectContext;
	createdDate: string | null;
	updatedDate: string | null;
	statuses: {
		[status_id: string]: StatusDeprecated;
	};
	issueLinks: IssueLinks;
	subtasks: Subtask[];
	childrenIssues: ChildIssue[];
	parentIssue: ParentIssue | null;
};

type GraphQLIssueFieldDict = {
	issuelinks: {
		content: ServerIssueLinks;
	};
	status: {
		content: {
			id: string;
			name: string;
			statusCategory: {
				id: string;
			};
		};
	};
	// Deprecated - TMP parent issue. Use parent instead. Remove with FD-30157
	['parent-issue']: {
		content: ServerParentIssue | null;
	};
	// Parent issue in unified hierarchy
	parent: {
		content: ServerParentIssue | null;
	};
	subtasks?: {
		content: ServerSubtask[];
	};
	['children-issues']?: {
		content: ServerNextGenChildIssue[];
	};
	['epic-issues']?: {
		content: ServerEpicIssue[];
	};
	['portfolio-child-issues']?: {
		content: ServerPortfolioIssue[];
	};
	created: {
		content: string | null;
	};
	updated: {
		content: string | null;
	};
};

export type FieldData = {
	issue: {
		id: string;
		agile: Agile;
		fields: FieldsState;
		tabs: Tabs;
		viewScreenId?: number;
	};
} & TransformedIssue;

const transformParentIssue = (parentIssue: ServerParentIssue | null): ParentIssue | null =>
	parentIssue
		? {
				id: toIssueId(parentIssue.id),
				key: toIssueKey(parentIssue.key),
				summary: parentIssue.fields.summary,
				issueTypeName: parentIssue.fields.issuetype ? parentIssue.fields.issuetype.name : '',
				issueTypeIconUrl: parentIssue.fields.issuetype ? parentIssue.fields.issuetype.iconUrl : '',
			}
		: null;

const getProjectStatus = (project: ServerProject): ProjectStatus => {
	if (project.archived) {
		return PROJECT_ARCHIVED;
	}
	if (project.deleted) {
		return PROJECT_TRASHED;
	}
	return PROJECT_ACTIVE;
};

export const transformIssue = (graphQLResponse: GraphQLIssueResponse): TransformedIssue => {
	const { issue, project } = graphQLResponse;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const issueFields: GraphQLIssueFieldDict = keyBy(issue.fields, 'key') as any;
	const subTasks = issueFields.subtasks?.content;
	const parentIssue = issueFields.parent?.content;
	const nextGenChildrenIssues = issueFields['children-issues']?.content;
	const classicEpicChildrenIssues = issueFields['epic-issues']?.content;
	const portfolioChildrenIssues = issueFields['portfolio-child-issues']?.content;
	const isSimplifiedProject = project.simplified;

	let childrenIssues: ChildIssue[] = [];
	if (nextGenChildrenIssues) {
		childrenIssues = transformChildrenNew(nextGenChildrenIssues, isSimplifiedProject);
	} else if (classicEpicChildrenIssues) {
		childrenIssues = transformChildrenNew(classicEpicChildrenIssues, isSimplifiedProject);
	} else if (portfolioChildrenIssues) {
		childrenIssues = transformChildren(portfolioChildrenIssues, toBaseUrl(''));
	}

	const avatarUrl = project.avatarUrls?.find(({ key }) => key === '32x32')?.value;
	return {
		// The `childIssuesLimit` field has been added to satisfy types.
		// This feature only works for the AGG migration phase 1 on case.
		// This value is not consumed.
		childIssuesLimit: undefined,
		project: {
			projectId: project.id,
			projectName: project.name,
			projectKey: project.key,
			projectType: project.projectTypeKey,
			isSimplified: isSimplifiedProject,
			avatarUrl,
			projectStatus: getProjectStatus(project),
		},
		createdDate: issueFields.created?.content || null,
		updatedDate: issueFields.updated?.content || null,
		statuses: {
			[issueFields.status.content.id]: {
				id: issueFields.status.content.id,
				name: issueFields.status.content.name,
				statusCategory: statusCategoryForId(issueFields.status.content.statusCategory.id),
			},
		},
		issueLinks:
			issueFields.issuelinks && transformIssueLinks(toBaseUrl(''), issueFields.issuelinks.content),
		subtasks: transformSubtasks(subTasks, toBaseUrl('')),
		parentIssue: transformParentIssue(parentIssue),
		childrenIssues,
	};
};

// A list of all keys of permissions that this transformer supports.
export const getPermissionKeys = (): PermissionKeys[] => [
	'ADMINISTER',
	'ADMINISTER_PROJECTS',
	'ASSIGN_ISSUES',
	'EDIT_ISSUES',
	'SCHEDULE_ISSUES',
	'ADD_COMMENTS',
	'DELETE_ALL_COMMENTS',
	'DELETE_OWN_COMMENTS',
	'EDIT_ALL_COMMENTS',
	'EDIT_OWN_COMMENTS',
	'CREATE_ISSUES',
	'DELETE_ISSUES',
	'MOVE_ISSUES',
	'MODIFY_REPORTER',
	'CREATE_ATTACHMENTS',
	'DELETE_ALL_ATTACHMENTS',
	'DELETE_OWN_ATTACHMENTS',
	'WORK_ON_ISSUES',
	'ASSIGNABLE_USER',
	'LINK_ISSUES',
	'VIEW_VOTERS_AND_WATCHERS',
	'MANAGE_WATCHERS',
	'EDIT_ALL_WORKLOGS',
	'EDIT_OWN_WORKLOGS',
	'DELETE_ALL_WORKLOGS',
	'DELETE_OWN_WORKLOGS',
	'USER_PICKER',
	'VIEW_DEV_TOOLS',
	'SERVICEDESK_AGENT',
	'RESOLVE_ISSUES',
	'CREATE_SUBTASK',
	'CLONE_ISSUE',
	'ARCHIVE_ISSUES',
	'UNARCHIVE_ISSUES',
];

export const transformPermissions = (
	responseData: ServerPermissions,
): PermissionsListTransformed => {
	const {
		permissions: {
			ADMINISTER_PROJECTS,
			ASSIGN_ISSUES,
			EDIT_ISSUES,
			SCHEDULE_ISSUES,
			ADD_COMMENTS,
			DELETE_ALL_COMMENTS,
			DELETE_OWN_COMMENTS,
			EDIT_ALL_COMMENTS,
			EDIT_OWN_COMMENTS,
			CREATE_ISSUES,
			CLONE_ISSUE,
			DELETE_ISSUES,
			MOVE_ISSUES,
			MODIFY_REPORTER,
			CREATE_ATTACHMENTS,
			DELETE_ALL_ATTACHMENTS,
			DELETE_OWN_ATTACHMENTS,
			WORK_ON_ISSUES,
			ASSIGNABLE_USER,
			LINK_ISSUES,
			VIEW_VOTERS_AND_WATCHERS,
			MANAGE_WATCHERS,
			EDIT_ALL_WORKLOGS,
			EDIT_OWN_WORKLOGS,
			DELETE_ALL_WORKLOGS,
			DELETE_OWN_WORKLOGS,
			VIEW_DEV_TOOLS,
			SERVICEDESK_AGENT,
			RESOLVE_ISSUES,
			ARCHIVE_ISSUES,
			UNARCHIVE_ISSUES,
		},
	} = responseData;

	return {
		permissions: {
			canAddComments: ADD_COMMENTS.havePermission,
			canAdministerProject: ADMINISTER_PROJECTS.havePermission,
			canAssignIssues: ASSIGN_ISSUES.havePermission,
			canBeAssignedToIssues: ASSIGNABLE_USER.havePermission,
			canCloneIssue: CLONE_ISSUE.havePermission,
			canCreateAttachments: CREATE_ATTACHMENTS.havePermission,
			canCreateChildren: CREATE_ISSUES.havePermission,
			canCreateIssuesInClassicProjectEpic: CREATE_ISSUES.havePermission,
			canDeleteAllAttachments: DELETE_ALL_ATTACHMENTS.havePermission,
			canDeleteOwnAttachments: DELETE_OWN_ATTACHMENTS.havePermission,
			canDeleteAllComments: DELETE_ALL_COMMENTS.havePermission,
			canDeleteOwnComments: DELETE_OWN_COMMENTS.havePermission,
			canDeleteIssue: DELETE_ISSUES.havePermission,
			canEditAllComments: EDIT_ALL_COMMENTS.havePermission,
			canEditOwnComments: EDIT_OWN_COMMENTS.havePermission,
			canEditIssues: EDIT_ISSUES.havePermission,
			canLinkIssues: LINK_ISSUES.havePermission,
			canLogWork: WORK_ON_ISSUES.havePermission,
			canManageWatchers: MANAGE_WATCHERS.havePermission,
			canMoveIssue: MOVE_ISSUES.havePermission,
			canModifyReporter: MODIFY_REPORTER.havePermission,
			canScheduleIssues: SCHEDULE_ISSUES.havePermission,
			canViewWatchers: VIEW_VOTERS_AND_WATCHERS.havePermission,
			canViewVoters: VIEW_VOTERS_AND_WATCHERS.havePermission,
			canEditAllWorklogs: EDIT_ALL_WORKLOGS.havePermission,
			canEditOwnWorklogs: EDIT_OWN_WORKLOGS.havePermission,
			canDeleteAllWorklogs: DELETE_ALL_WORKLOGS.havePermission,
			canDeleteOwnWorklogs: DELETE_OWN_WORKLOGS.havePermission,
			canViewDevTools: !!(VIEW_DEV_TOOLS && VIEW_DEV_TOOLS.havePermission),
			canUseServiceDeskAgentFeatures: !!(SERVICEDESK_AGENT && SERVICEDESK_AGENT.havePermission),
			canResolveIssues: RESOLVE_ISSUES.havePermission,
			canArchiveIssue: ARCHIVE_ISSUES ? ARCHIVE_ISSUES.havePermission : false,
			canUnarchiveIssue: UNARCHIVE_ISSUES ? UNARCHIVE_ISSUES.havePermission : false,
		},
	};
};

export const transformGraphQlField = (field: ServerField): Field => {
	const { content, renderedContent, configuration, ...rest } = field;

	const shouldHaveContentFields = content !== undefined || renderedContent !== undefined;
	// @ts-expect-error - TS2322 - Type '{ key: string; title: string; editable: boolean; required: boolean; autoCompleteUrl?: string | null | undefined; description?: string | null | undefined; allowedValues: any[]; schema: FieldSchema; ... 5 more ...; value?: any; }' is not assignable to type 'Field'.
	return {
		...(shouldHaveContentFields ? { value: content } : null),
		...(shouldHaveContentFields ? { renderedValue: renderedContent } : null),
		...(configuration && { configuration }),
		...rest,
	};
};

const findGraphQlAdfField = (graphQlData: GraphQLIssueResponse, fieldId: string) => {
	const systemField = graphQlData.issue.systemFields[`${fieldId}Adf`];

	const customTextareaField = graphQlData.issue.customFields.textareaAdf.find(
		(multilineField) => multilineField.key === fieldId,
	);

	return systemField || customTextareaField || null;
};

const getAdfValue = (graphQlData: GraphQLIssueResponse, fieldId: string) => {
	const graphQlAdfField = findGraphQlAdfField(graphQlData, fieldId);
	let adfField;
	if (graphQlAdfField && graphQlAdfField.value) {
		adfField = { value: graphQlAdfField.value };
	} else {
		// A null ADF value and null WikiMarkup value means that the field has never had a value
		// set in it.
		adfField = {
			value: emptyAdfObject,
		};
	}

	// GraphQL is currently returning this as a JSON string, though ideally
	// we want it as JSON object
	// Can remove the check/JSON.parse when the below ticket is complete:
	//    https://product-fabric.atlassian.net/browse/EX-203
	const adfValue = typeof adfField.value === 'string' ? JSON.parse(adfField.value) : adfField.value;
	return removeCollectionFromAdf(adfValue);
};

export const getGraphQlFields = (graphQlData: GraphQLIssueResponse): FieldsState => {
	const fieldKeysWithRichRenderer: Set<string> = new Set(
		graphQlData.issue.fields
			.filter((field) => field.schema.renderer === RICH_CONTENT)
			.map((field) => field.key),
	);
	const customMultilineFieldKeys = graphQlData.issue.customFields.textareaAdf.map(
		(field) => field.key,
	);
	const customMultilineFieldKeysWithRichRenderer = customMultilineFieldKeys.filter((key) =>
		fieldKeysWithRichRenderer.has(key),
	);
	const systemMultilineFieldsWithRichRenderer = [DESCRIPTION, ENVIRONMENT].filter(
		(key) => fieldKeysWithRichRenderer.has(key) || key === DESCRIPTION,
	);
	const adfKeys = [
		...systemMultilineFieldsWithRichRenderer,
		...customMultilineFieldKeysWithRichRenderer,
	];
	const adfFields = adfKeys.map((field) => ({
		key: field,
		value: getAdfValue(graphQlData, field),
	}));

	return graphQlData.issue.fields
		.map((field) => {
			const adfTransformationField = adfFields.find(
				(adfField) => adfField.key === field.key && adfField.value !== null,
			);
			if (adfTransformationField) {
				return {
					[adfTransformationField.key]: {
						...transformGraphQlField(field),
						adfValue: adfTransformationField.value,
					},
				};
			}
			return { [field.key]: transformGraphQlField(field) };
		})
		.reduce((acc, currentValue) => Object.assign(acc, currentValue));
};

const getTabs = (tabs: Tab[]): Tabs =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	tabs?.reduce((acc: Record<any, any>, { id, name, items }) => {
		acc[id] = { id, name, items };
		return acc;
	}, {});

export const transformGraphQlData = (graphQlData: GraphQLIssueResponse): FieldData => {
	const oldTransformedFields = transformIssue(graphQlData);

	const graphQlTransformedFields = {
		issue: {
			id: graphQlData.issue.id,
			agile: graphQlData.issue.agile,
			fields: getGraphQlFields(graphQlData),
			tabs: getTabs(graphQlData.issue.tabs),
			...(graphQlData.issue.viewScreenId ? { viewScreenId: graphQlData.issue.viewScreenId } : null),
		},
	};

	return { ...graphQlTransformedFields, ...oldTransformedFields };
};
