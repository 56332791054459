import { useUIModificationsConsentStatusForApps } from '@atlassian/ui-modifications-core/src/controllers/adjustments-context/hooks/use-consent-status/index.tsx';
import { UIM_CONSENT_REQUIRED } from '@atlassian/ui-modifications-core/src/controllers/adjustments-context/types.tsx';
import { useFieldIsProcessing } from '../../issue-adjustments/hooks/use-field-is-processing/index.tsx';

/**
 * Hook covers the blocking (loading) UIM functionality:
 * - when UIM error occurred @see `useFieldIsProcessing` it returns false - fields are NOT blocked
 * - scenarios with consent and registering fields: see unit tests.
 */
export const useFieldIsBusy = (fieldId: string) => {
	const [isBusyData] = useFieldIsProcessing(fieldId);
	const uimConsentMap = useUIModificationsConsentStatusForApps();
	const { isBusy, appsIds } = isBusyData;

	if (!isBusy) {
		return false;
	}

	return appsIds.some((appId) => uimConsentMap[appId] !== UIM_CONSENT_REQUIRED);
};
