import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { GenericCompositionFormula } from './composition/types.tsx';
import type { ConstantFormula } from './constant/types.tsx';
import type { GenericDiscretizationFormula } from './discretization/types.tsx';
import type { GenericExpressionFormula } from './expression/types.tsx';
import type { ExternalReferencePropertyFormula } from './external-reference-property/types.tsx';
import type { FieldFormula } from './field/types.tsx';
import type { DataPointCountFormula } from './insight-count/types.tsx';
import type { IssueCommentFormula } from './issue-comments/types.tsx';
import type { LinkedIssuesFormula, LinkedIssuesCountFormula } from './linked-issues/types.tsx';
import type { MultiselectCountFormula } from './multi-select-count/types.tsx';
import type { GenericNormalizationFormula } from './normalize/types.tsx';
import type { PlayAggFormula } from './play-agg/types.tsx';
import type { PropertyAggregationFormula } from './property-aggregation/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ConstantFormula } from './constant/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FieldFormula } from './field/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { DataPointCountFormula } from './insight-count/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { PropertyAggregationFormula } from './property-aggregation/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { MultiselectCountFormula } from './multi-select-count/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	type LinkedIssuesFormula,
	type LinkedIssuesCountFormula,
	STORY_POINTS,
	ISSUE_COUNT,
} from './linked-issues/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ExternalReferencePropertyFormula } from './external-reference-property/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { PlayAggFormula } from './play-agg/types';

export type ExpressionFormula = GenericExpressionFormula<DynamicFieldFormula>;
export type NormalizationFormula = GenericNormalizationFormula<DynamicFieldFormula>;
type DiscretizationFormula = GenericDiscretizationFormula<DynamicFieldFormula>;
export type CompositionFormula = GenericCompositionFormula<DynamicFieldFormula>;

export type DynamicFieldFormula =
	| PropertyAggregationFormula
	| DataPointCountFormula
	| FieldFormula
	| ConstantFormula
	| CompositionFormula
	| DiscretizationFormula
	| NormalizationFormula
	| ExpressionFormula
	| MultiselectCountFormula
	| LinkedIssuesFormula
	| ExternalReferencePropertyFormula
	| IssueCommentFormula
	| PlayAggFormula
	| LinkedIssuesCountFormula;

export type FormulaVisitor = (formula: DynamicFieldFormula) => void;
export type FieldVisitor = (field: Ari) => void;

export type Term = FieldTerm | WeightedFieldTerm | PropertyAggregationTerm;
type FieldTerm = { id: string; type: string };
type WeightedFieldTerm = { id: string; type: string; weight?: number };

type PropertyAggregationTerm = {
	id: string;
	type: string;
	weight?: number;
	oauthClientId?: string;
	labels?: string[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JsonLogic = any;

export type LoaderParameters = {
	terms: Term[];
};

type PlayAggLoaderParameters = {
	playAri: string;
};

type DeliveryLoaderParameters = {
	calculationMode: string;
};

export type Formula = {
	formulaKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	logic?: any;
	loaderParameter?: LoaderParameters | PlayAggLoaderParameters | DeliveryLoaderParameters;
	expression?: string;
};

export const ROLL_UP_FORMULA_KEY = 'roll_up';
export const WEIGHTED_SCORE_FORMULA_KEY = 'weighted_score';
export const CUSTOM_FORMULA_KEY = 'custom';
export const FIELD_TERM = 'field';
export const INSIGHTS_COUNT_TERM = 'num_data_points';
export const PROPERTY_AGG_TERM = 'property_agg';
export const NUMBER_LINKED_ISSUES_FORMULA_KEY = 'num_linked_issues';
export const NUMBER_COMMENT_FORMULA_KEY = 'num_issue_comments';
export const LINKED_ISSUES_STATUS = 'linked_issues_status';
export const LINKED_ISSUES_PROGRESS = 'linked_issues_progress';
export const INSIGHTS_COUNT_KEY = 'num_data_points';
export const PLAY_AGG_KEY = 'play_agg';
