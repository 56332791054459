/**
 * @generated SignedSource<<4b080b1a38f7bdf0d887b66204e41cdf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef$data = {
  readonly node: {
    readonly teams?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly fullTeam: {
            readonly avatarUrl: string | null | undefined;
            readonly id: string;
            readonly isVerified: boolean | null | undefined;
            readonly name: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef";
};
export type team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef$key = {
  readonly " $data"?: team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef">;
};

import team_teamSearchRefetchQuery_graphql from './team_teamSearchRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "teams"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 1000,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organisationId"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sessionId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "siteId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": team_teamSearchRefetchQuery_graphql
    }
  },
  "name": "team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "teams",
              "args": [
                {
                  "kind": "Variable",
                  "name": "organisationId",
                  "variableName": "organisationId"
                },
                {
                  "kind": "Variable",
                  "name": "searchBy",
                  "variableName": "searchBy"
                },
                {
                  "kind": "Variable",
                  "name": "sessionId",
                  "variableName": "sessionId"
                }
              ],
              "concreteType": "JiraTeamViewConnection",
              "kind": "LinkedField",
              "name": "__team_issueFieldTeamEditviewFull_teams_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraTeamViewEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraTeamView",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "siteId",
                              "variableName": "siteId"
                            }
                          ],
                          "concreteType": "TeamV2",
                          "kind": "LinkedField",
                          "name": "fullTeam",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "id"
                            },
                            {
                              "alias": "name",
                              "kind": "ScalarField",
                              "name": "displayName"
                            },
                            {
                              "alias": "avatarUrl",
                              "kind": "ScalarField",
                              "name": "smallAvatarImageUrl"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "isVerified"
                            }
                          ]
                        },
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    }
                  ]
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                }
              ]
            }
          ],
          "type": "JiraTeamViewField"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "e937c201708cb30cac1e394efbbbfca2";

export default node;
