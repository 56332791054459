import type { ComponentType } from 'react';
import { type UIAnalyticsEvent, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { SaveRemoteIssueLinkPayload } from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-add-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fetchJiraAppLinks } from '@atlassian/jira-issue-view-store/src/actions/fetch-jira-app-links-actions.tsx';
import {
	saveLinkedIssueRequest,
	type NewIssueLinks,
	clearRelatedIssueLink,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { saveRemoteLinkedIssueRequest } from '@atlassian/jira-issue-view-store/src/actions/remote-issue-links-actions.tsx';
import {
	canCreateLinkedIssueSelector,
	isOpenedFromJsmSimilarIssuesSelector,
	quickAddClickedCountSelector,
	quickAddClickedIssueSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import {
	canLinkJiraRemoteIssuesSelector,
	jiraAppLinksFetchStatusSelector,
	jiraAppLinksSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/jira-app-links-selector.tsx';
import { withGlobalRef } from '@atlassian/jira-refs-store/src/index.tsx';
import type { ComponentProps } from './types.tsx';
import { AddIssueLinkView } from './view.tsx';

type HandleError = (error?: Error) => void;
type HandleErrorOld = (errorMessage?: string) => void;

// FIXME: JIV-17455 should be fully typed
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default flowWithSafeComponent(
	injectIntl,
	withAnalyticsEvents({
		onAdd: { action: 'added' },
		onAddRemote: { action: 'added', isRemote: true },
	}),
	connect(
		(state: State) => ({
			isOpenedFromJsmSimilarIssues: isOpenedFromJsmSimilarIssuesSelector(state),
			quickAddClickedCount: quickAddClickedCountSelector(state),
			quickAddClickedIssue: quickAddClickedIssueSelector(state),
			canCreateLinkedIssue: canCreateLinkedIssueSelector(state),
			canLinkRemoteIssues: canLinkJiraRemoteIssuesSelector(state),
			jiraAppLinksFetchStatus: jiraAppLinksFetchStatusSelector(state),
			jiraAppLinks: jiraAppLinksSelector(state),
		}),
		(dispatch) => ({
			onAdd: (
				issueLinks: NewIssueLinks,
				requestAnalyticsEvent: UIAnalyticsEvent | null,
				maybeAnalyticEventOrPostAnalyticsEvent: UIAnalyticsEvent,
				onAddSuccess: (() => void) | undefined,
				onAddError: HandleError | HandleErrorOld | undefined,
				analyticsEvent?: UIAnalyticsEvent,
			) =>
				dispatch(
					fg('operandi_issue_view_additional_logging')
						? saveLinkedIssueRequest(
								issueLinks,
								requestAnalyticsEvent,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								analyticsEvent!,
								onAddSuccess,
								onAddError,
								maybeAnalyticEventOrPostAnalyticsEvent,
							)
						: saveLinkedIssueRequest(
								issueLinks,
								requestAnalyticsEvent,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								analyticsEvent!,
								onAddSuccess,
								onAddError,
							),
				),
			onAddRemote: (
				saveRemoteIssueLinkPayload: SaveRemoteIssueLinkPayload,
				requestAnalyticsEvent: UIAnalyticsEvent,
				onAddSuccess: (() => void) | undefined,
				onAddError: HandleError | HandleErrorOld | undefined,
				analyticsEvent: UIAnalyticsEvent,
			) =>
				dispatch(
					saveRemoteLinkedIssueRequest(
						saveRemoteIssueLinkPayload,
						requestAnalyticsEvent,
						analyticsEvent.update({
							withReciprocalLink: Boolean(saveRemoteIssueLinkPayload.createReciprocalLink),
						}),
						onAddSuccess,
						onAddError,
					),
				),
			fetchJiraAppLinks: () => dispatch(fetchJiraAppLinks()),
			onClearRelatedIssueLink: () => dispatch(clearRelatedIssueLink()),
		}),
	),
	// @ts-expect-error error TS2345: Argument of type '<P extends { containerWidth?: number | undefined; }>
	withContainerWidth,
	withGlobalRef('linkIssueRef', 'quick-add-items.link-issue'),
)(
	// FIXME: JIV-17455 should be fully typed
	// @ts-expect-error TS2345: Argument of type 'typeof AddIssueLinkView' is not assignable to parameter
	AddIssueLinkView,
) as ComponentType<ComponentProps>; // cast to ComponentType as flowWithSafeComponent strips types
