import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import {
	FETCH_WORK_SUGGESTIONS,
	setWorkSuggestionsData,
	type Action,
} from '../../state/actions/work-suggestions/index.tsx';
import { getWorkSuggestionsData } from '../../services/details/index.tsx';
import { getIssueId, getProjectAri } from '../../state/selectors/app/index.tsx';
import type { State } from '../../state/reducers/types.tsx';

export const epic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(FETCH_WORK_SUGGESTIONS).mergeMap(() => {
		const state = store.getState();
		const projectAriString = getProjectAri(state) || '';
		const { cloudId } = JiraProjectAri.parse(projectAriString);
		const issueId = getIssueId(state);
		if (!cloudId || !issueId) {
			return Observable.empty();
		}
		const workSuggestionsObservable = Observable.zip(
			getWorkSuggestionsData(cloudId, issueId),
			(workSuggestions) => setWorkSuggestionsData(workSuggestions),
		).catch((error) => {
			log.safeErrorWithoutCustomerData(
				'packages.development.work-suggestions.src.main.fetch-suggestions',
				error,
			);
			return Observable.empty();
		});

		return workSuggestionsObservable;
	});
