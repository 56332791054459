/** @jsx jsx */
import React, { useCallback, useEffect, useState } from 'react';
import { styled, jsx, css } from '@compiled/react';
import Button from '@atlaskit/button';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import Icon from '@atlaskit/icon';
import AutomationIcon from '@atlaskit/icon/core/automation';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Section } from '@atlaskit/menu';
import type { Placement } from '@atlaskit/popper';
import { Popup, type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { token } from '@atlaskit/tokens';
import { AutomationGlyph } from '@atlassian/jira-automation-menu/src/common/assets/automation.tsx';
import { fg } from '@atlassian/jira-feature-gating';

import Footer from '@atlassian/jira-automation-platform/src/ui/footer/main.tsx';

import { CORE_PROJECT, SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/index.tsx';
import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useHasSeenRecurringWorkPulse } from '@atlassian/jira-recurring-issue-action/src/ui/hooks/use-has-seen-recurring-work-pulse.tsx';
import { RecurringIssueLinkItem } from '@atlassian/jira-recurring-issue-action/src/ui/index.tsx';
import { SpotlightWrapper } from '@atlassian/jira-recurring-issue-action/src/ui/spotlight-wrapper/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { AutomationSubMenu } from './automation-submenu/index.tsx';
import messages from './messages.tsx';
import { TransitionsSubMenu } from './transitions-submenu/index.tsx';
import type { Props } from './types.tsx';

// Component is responsible for triggering the ManualRulesContainer HOC to fetch the rules
// when this mounts. This is how we ensure lazy load behaviour of the menu.
const FetchRulesContainer = (props: {
	triggerFetch: () => Promise<void>;
	children: React.ReactNode;
}) => {
	const { triggerFetch, children } = props;
	useEffect(() => {
		triggerFetch();
		// Ignore exhaustive dep here to ensure no call to useEffect on re-render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <>{children}</>;
};

<AutomationIcon
	LEGACY_fallbackIcon={() => <AutomationGlyph />}
	LEGACY_size="medium"
	LEGACY_secondaryColor="none"
	label=""
	color={token('color.icon')}
/>;

const tooltipOptions: IconButtonProps['tooltip'] = {
	position: 'top',
	hideTooltipOnClick: true,
};

const customModifiers = [
	{
		name: 'preventOverflow',
		enabled: true,
		options: {
			padding: { top: 65 },
		},
	},
];

export const useIsOpen = () => useState(false);

export const ActionsMenu = (props: Props) => {
	const {
		transitions,
		onSelect,
		rules,
		executeRule,
		executingRule,
		triggerFetch,
		initialised,
		issueKey,
		issueId,
		projectType,
		baseAutomationUrl,
		canManageAutomations,
	} = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useIsOpen();
	const { formatMessage } = useIntl();
	const [hasSeenRecurringWorkPulse, setHasSeenRecurringWorkPulse] = useHasSeenRecurringWorkPulse();

	const canSeeRecurringWorkToggle =
		canManageAutomations &&
		(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
		expVal('jira_recurring_work_oncomplete_admin_experiment', 'showRecurringWorkToggle', false);

	const onTriggerClick = useCallback(() => {
		if (!isOpen) {
			const analyticsEvent = createAnalyticsEvent({
				actionSubject: 'menu',
				action: 'opened',
			});
			fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu', {
				userType: canManageAutomations ? 'admin' : 'endUser',
			});
		}
		if (canSeeRecurringWorkToggle) {
			setHasSeenRecurringWorkPulse(true);
		}
		setIsOpen(!isOpen);
	}, [
		canManageAutomations,
		createAnalyticsEvent,
		canSeeRecurringWorkToggle,
		isOpen,
		setHasSeenRecurringWorkPulse,
		setIsOpen,
	]);

	const onTriggerClickOld = useCallback(() => {
		if (!hasSeenRecurringWorkPulse && canSeeRecurringWorkToggle) {
			setHasSeenRecurringWorkPulse(true);
		}
		if (fg('jira_adds_isjiraglobaladmin_event_attribute')) {
			if (!isOpen) {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'menu',
					action: 'opened',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu', {
					userType: canManageAutomations ? 'admin' : 'endUser',
				});
			}
		}
		setIsOpen(!isOpen);

		if (!fg('jira_adds_isjiraglobaladmin_event_attribute')) {
			if (isOpen) {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'menu',
					action: 'opened',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu', {
					userType: canManageAutomations ? 'admin' : 'endUser',
				});
			}
		}
	}, [
		canManageAutomations,
		createAnalyticsEvent,
		canSeeRecurringWorkToggle,
		hasSeenRecurringWorkPulse,
		isOpen,
		setHasSeenRecurringWorkPulse,
		setIsOpen,
	]);

	// fallback placements added as per atlaskit dropdown-menu
	const fallbackPlacements: Placement[] = [
		'bottom',
		'bottom-end',
		'top-start',
		'top',
		'top-end',
		'auto',
	];

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			fallbackPlacements={fallbackPlacements}
			trigger={(triggerProps: TriggerProps) => {
				const trigger = isVisualRefreshEnabled() ? (
					<IconButton
						{...triggerProps}
						isSelected={isOpen}
						onClick={
							fg('jira_manualrulesactionsmenu_analytics_bugfix')
								? onTriggerClick
								: onTriggerClickOld
						}
						icon={AutomationIcon}
						isTooltipDisabled={false}
						tooltip={tooltipOptions}
						label={formatMessage(messages.automation)}
						interactionName="jira-issue-field-status.ui.status-view.actions.actions-menu.button"
					/>
				) : (
					<Button
						{...triggerProps}
						appearance="subtle"
						isSelected={isOpen}
						onClick={
							fg('jira_manualrulesactionsmenu_analytics_bugfix')
								? onTriggerClick
								: onTriggerClickOld
						}
						iconAfter={<ChevronDownIcon label="" />}
						iconBefore={
							<Icon size="medium" glyph={AutomationGlyph} label="" secondaryColor="none" />
						}
						interactionName="jira-issue-field-status.ui.status-view.actions.actions-menu.button"
					>
						{formatMessage(messages.actions)}
					</Button>
				);

				if (canSeeRecurringWorkToggle) {
					return (
						<SpotlightWrapper showPulse={!hasSeenRecurringWorkPulse}>{trigger}</SpotlightWrapper>
					);
				}

				return trigger;
			}}
			onClose={() => setIsOpen(false)}
			content={() => (
				<div css={menuContainerStyles} role="menu">
					<MenuItemContainer>
						<FetchRulesContainer triggerFetch={triggerFetch}>
							<TransitionsSubMenu
								initialised={initialised}
								onSelect={onSelect}
								transitions={transitions}
								canManageAutomations={canManageAutomations}
							/>
							<AutomationSubMenu
								initialised={initialised}
								rules={rules}
								transitions={transitions}
								executeRule={executeRule}
								issueKey={issueKey}
								issueId={issueId}
								executingRule={executingRule}
								baseAutomationUrl={baseAutomationUrl}
								canManageAutomations={canManageAutomations}
							/>
						</FetchRulesContainer>
					</MenuItemContainer>
					{canSeeRecurringWorkToggle && <RecurringIssueLinkItem issueKey={issueKey} />}
					{canManageAutomations && <Footer baseAutomationUrl={baseAutomationUrl} />}
				</div>
			)}
			zIndex={layers.modal}
			shouldRenderToParent
			modifiers={customModifiers}
			testId="issue-field-status.ui.status-view.actions.actions-menu.popup"
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const MenuItemContainer = styled(Section)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 40}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 62}px`,
	overflow: 'auto',
});

const menuContainerStyles = css({
	textWrap: 'initial',
	// maxHeight = total height - top navigation height(56px) - top and bottom space (20px)
	maxHeight: 'calc(100vh - 76px)',
	overflow: 'auto',
});
