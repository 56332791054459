/**
 * @generated SignedSource<<f00ed2d6477f15bd3f4a81f6854955df>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueActions_issueViewFoundation_IssueActionsMenuWithRelay$data = {
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewFoundation_ConfigureIssueFF">;
  readonly " $fragmentType": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay";
};
export type issueActions_issueViewFoundation_IssueActionsMenuWithRelay$key = {
  readonly " $data"?: issueActions_issueViewFoundation_IssueActionsMenuWithRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueActions_issueViewFoundation_IssueActionsMenuWithRelay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "view_issueViewFoundation_ConfigureIssueFF"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "87b1d2fa169c23c445df1b80d9a05d45";

export default node;
