import { ProfileClient } from '@atlaskit/profilecard';
import {
	type Action,
	createStore,
	createContainer,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { CustomUserProfileClient, type OnLoadError } from './custom-user-profile-client.tsx';

export type ProfileCardContainerProps = {
	fabricPfDirUrl: string | null;
	profileClient?: ProfileClient | null;
	cloudId: string;
	onLoadError?: OnLoadError;
};

export type State = {
	profileClient: ProfileClient | null;
};

const GATEWAY_GRAPHQL_URL = '/gateway/api/graphql';

export const createUserProfileCardClient = (
	fabricPfDirUrl: string,
	onLoadError: OnLoadError | undefined,
	gatewayGraphqlUrl?: string,
	cloudId?: string,
) => {
	const userClient = new CustomUserProfileClient(fabricPfDirUrl, onLoadError);

	// From upward version 14 of `@atlaskit/profilecard`, `ProfileClient` class accepts `userClient` and `teamClient` in the 2nd argument.
	return new ProfileClient(
		{
			cacheMaxAge: 1000 * 60 * 3,
			cacheSize: 20,
			url: fabricPfDirUrl,
			productIdentifier: 'jira',
			gatewayGraphqlUrl,
			cloudId,
		},
		{
			userClient,
		},
	);
};

export const actions = {
	bootstrap:
		(): Action<State, ProfileCardContainerProps> =>
		({ setState }, { fabricPfDirUrl, profileClient, onLoadError, cloudId }) => {
			const finalProfileClient =
				profileClient ||
				createUserProfileCardClient(
					fabricPfDirUrl ?? '',
					onLoadError,
					GATEWAY_GRAPHQL_URL,
					cloudId,
				);

			setState({
				profileClient: finalProfileClient,
			});
		},
} as const;

type Actions = typeof actions;

export const ProfileClientContainer = createContainer<ProfileCardContainerProps>({
	displayName: 'ProfileClientContainer',
});

export const initialState: State = {
	profileClient: null,
};

export const store = createStore<State, Actions, ProfileCardContainerProps>({
	name: 'ProfileClientStore',
	initialState,
	actions,
	containedBy: ProfileClientContainer,
	handlers: {
		onInit:
			() =>
			({ dispatch }) =>
				dispatch(actions.bootstrap()),
	},
});

export const useProfileClient = createStateHook(store);
