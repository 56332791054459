import React, { useEffect, useMemo } from 'react';

import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import {
	AnalyticsListener as AnalyticsListenerAnalyticsNext,
	type UIAnalyticsEvent,
	type UIAnalyticsEventHandler,
} from '@atlaskit/analytics-next';
import {
	extractAWCDataFromEvent,
	OPERATIONAL_EVENT_TYPE,
	TRACK_EVENT_TYPE,
} from '@atlassian/analytics-bridge';
import { initialiseErrorAnalyticsClient } from '@atlassian/error-handling';

import { UI_MODIFICATIONS_ANALYTICS_CHANNEL } from '../../constants';
import {
	type AnalyticsEventPayload,
	type AnalyticsEventWithType,
	type AnalyticsListenerProps,
} from '../../types';

// Copied from platform/packages/data/error-handling/src/utils/fire-error-analytics.tsx as this is the type they expect
// It should be AnalyticsListener (from @atlassiansox/analytics-web-client), but this type was not available at the time fire-error-analytics was created
type AnalyticsWebClientPlatformExpected = {
	sendOperationalEvent: (event: Object) => void;
};

export const sendEvent = (
	event: AnalyticsEventWithType,
	client: AnalyticsWebClient | Promise<AnalyticsWebClient>,
): void | Promise<void> => {
	switch (event.type) {
		case OPERATIONAL_EVENT_TYPE:
			Promise.resolve(client).then((c) => c.sendOperationalEvent(event));
			break;

		case TRACK_EVENT_TYPE:
			Promise.resolve(client).then((c) => c.sendTrackEvent(event));
			break;
	}
};

export const getEventHandler: (
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>,
) => UIAnalyticsEventHandler =
	(analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>) =>
	(event: UIAnalyticsEvent) => {
		const eventData = extractAWCDataFromEvent(event);

		sendEvent(
			{
				type: eventData.type,
				...(eventData.payload as AnalyticsEventPayload),
			},
			analyticsWebClient,
		);
	};

export const AnalyticsListener = ({ children, analyticsWebClient }: AnalyticsListenerProps) => {
	const onEvent = useMemo(() => getEventHandler(analyticsWebClient), [analyticsWebClient]);

	useEffect(() => {
		const initClient = async () => {
			const client = await analyticsWebClient;

			initialiseErrorAnalyticsClient(client as unknown as AnalyticsWebClientPlatformExpected);
		};

		initClient();
	}, [analyticsWebClient]);

	return (
		<AnalyticsListenerAnalyticsNext onEvent={onEvent} channel={UI_MODIFICATIONS_ANALYTICS_CHANNEL}>
			{children}
		</AnalyticsListenerAnalyticsNext>
	);
};
