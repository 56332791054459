import React, { useCallback, useMemo } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ConnectedReactionsView, ReactionUpdateType } from '@atlaskit/reactions';
import { JiraCommentAri } from '@atlassian/ari/jira';
import { getEmojiProviderWithCustomEmojiUploadDisabled } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { store as reactionsStoreFactory } from '@atlassian/jira-common-atlaskit-services/src/reactions.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { shouldShowReactionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/reactions-selector.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Props, StateProps } from './types.tsx';

export const ReactionsComponent = ({
	containerAri = '',
	objectAri = '',
	reactionsStore: reactionsStoreProp,
	showReactions,
}: Props) => {
	const loggedInUserAccountId = useAccountId();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const reactionsStore = useMemo(
		() => reactionsStoreProp ?? reactionsStoreFactory(),
		[reactionsStoreProp],
	);

	const emojiProvider = useMemo(
		() =>
			getEmojiProviderWithCustomEmojiUploadDisabled(
				cloudId,
				loggedInUserAccountId,
				!fg('perf-push-big-components-delay-emoji'),
			),
		[cloudId, loggedInUserAccountId],
	);

	const reactionSuccessEvent = useCallback(
		(action: string, commentAri: string, emojiId: string, reactionsCount: number) => {
			const reactionCountHierarchy = reactionsCount > 1 ? 1 : 0;
			fireTrackAnalytics(createAnalyticsEvent({}), `reaction ${action}`, emojiId, {
				...(action === ReactionUpdateType.added && { reactionCountHierarchy }),
				commentId: JiraCommentAri.parse(commentAri).resourceId,
			});
		},
		[createAnalyticsEvent],
	);

	if (!showReactions || !reactionsStore || !emojiProvider) {
		return null;
	}

	return (
		<ConnectedReactionsView
			ari={objectAri}
			containerAri={containerAri}
			store={reactionsStore}
			emojiProvider={emojiProvider}
			allowAllEmojis
			onReactionSuccess={reactionSuccessEvent}
		/>
	);
};

export const Reactions = (props: Props) => (
	<ErrorBoundary>
		<ReactionsComponent {...props} />
	</ErrorBoundary>
);

export default connect(
	(state: State): StateProps => ({
		showReactions: shouldShowReactionsSelector(state),
	}),
	{},
)(Reactions);
