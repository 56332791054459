import React, { useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { useRelatedIssuesForIssue } from '../services/context.tsx';
import { useQueryText } from '../services/use-query-text/index.tsx';
import { getTopFiveDistances, getTopFiveCrossEncoderScores } from '../services/utils.tsx';
import { RelatedIssuesFeedbackSentiment } from './feedback-sentiment/index.tsx';
import PanelHeader from './header/index.tsx';
import IssueLineCardGroup from './issue-line-card-group/index.tsx';
import StatusTabs from './status-tabs/index.tsx';

export type Props = {
	sessionId: string;
};

export const RelatedIssuesPanelView = ({ sessionId }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isExpanded, setExpanded] = useState(false);
	const [hasClickedSentiment, setHasClickedSentiment] = useState(false);
	const queryText = useQueryText();

	const [{ data, status }, { fetchRelatedIssuesForIssue }] = useRelatedIssuesForIssue();
	useEffect(() => {
		if (isExpanded) {
			fetchRelatedIssuesForIssue(sessionId, createAnalyticsEvent, queryText);
		}
	}, [createAnalyticsEvent, fetchRelatedIssuesForIssue, isExpanded, sessionId, queryText]);

	const renderPanelBody = () => (
		<>
			<StatusTabs sessionId={sessionId} />
			<FieldsContainer data-testid="smart-related-issues-panel.ui.fields-container">
				<IssueLineCardGroup sessionId={sessionId} />
			</FieldsContainer>
			<Box xcss={sentimentContainerStyles}>
				<RelatedIssuesFeedbackSentiment
					sessionId={sessionId}
					hasClickedSentiment={hasClickedSentiment}
					setHasClickedSentiment={setHasClickedSentiment}
					openIssuesDistance={getTopFiveDistances(data.OPEN)}
					resolvedIssuesDistance={getTopFiveDistances(data.RESOLVED)}
					openIssuesCrossEncoderScore={getTopFiveCrossEncoderScores(data.OPEN)}
					resolvedIssuesCrossEncoderScore={getTopFiveCrossEncoderScores(data.RESOLVED)}
				/>
			</Box>
		</>
	);

	const renderRelatedIssues = () => (
		<>
			<PanelHeader
				sessionId={sessionId}
				onExpand={() => setExpanded(true)}
				onCollapse={() => setExpanded(false)}
				isExpanded={isExpanded}
			/>
			{isExpanded && <PanelBody>{renderPanelBody()}</PanelBody>}
		</>
	);

	return (
		<ContextualAnalyticsData
			attributes={{
				selectedStatusFilter: status,
			}}
		>
			<RelatedIssuesContainer isExpanded={isExpanded}>
				{renderRelatedIssues()}
			</RelatedIssuesContainer>
		</ContextualAnalyticsData>
	);
};

export default RelatedIssuesPanelView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelatedIssuesContainer = styled.div<{
	isExpanded: boolean;
	hasContentItems?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isExpanded, hasContentItems }) => isExpanded && hasContentItems && 'padding-bottom: 0');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelBody = styled.div({
	marginBottom: token('space.300', '24px'),
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottomLeftRadius: `${gridSize / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottomRightRadius: `${gridSize / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border', colors.N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderLeft: `1px solid ${token('color.border', colors.N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRight: `1px solid ${token('color.border', colors.N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	paddingTop: token('space.200', '16px'),
});

const sentimentContainerStyles = xcss({
	marginTop: 'space.200',
});
