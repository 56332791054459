// TODO: go/restrict-enums

import type { EditorProps } from '@atlaskit/editor-core';
import type { JiraAuditLogEventData } from '@atlassian/editor-plugin-ai/AuditLogs';
import type { AIPlugin } from '@atlassian/editor-plugin-ai/EditorPluginAI';
import type {
	createJiraEditorPluginAI,
	createJiraEditorPluginAIProvider,
} from '@atlassian/editor-plugin-ai/JiraPrebuilt';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum Product {
	JSW = 'JSW',
	JWM = 'JWM',
	JSM = 'JSM',
	JPD = 'JPD',
}

export type CreateEditorPluginAIProvider = ReturnType<typeof createJiraEditorPluginAIProvider>;
type CreateEditorPluginAI = ReturnType<typeof createJiraEditorPluginAI>;
export type EditorPluginAI = CreateEditorPluginAI['editorPluginAI'];
export type AIPluginConfiguration = Parameters<AIPlugin>[0]['config'] | undefined;

/**
 * Currently just product required for EditorPluginAI but can add to this as needed
 * @link https://hello.atlassian.net/wiki/spaces/EDAI/pages/2731933104/Editor+AI+EAP+Part+2+Dev+Documentation+Prebuilt+Providers+Plugin+Presets
 */
export type EditorPluginAIConfig = {
	product: Product | null;
	issueId?: string;
	isAiOptInEnabled: boolean;
	allowIssueReformatter?: boolean;
	allowSuggestResponse?: boolean;
	getAdditionalProps?: () => Partial<{
		issue_summary?: string;
		issue_type_label?: string;
	}>;
	getChannelVariables?: Parameters<
		typeof createJiraEditorPluginAIProvider
	>[0]['getChannelVariables'];
	actionSideEffects?: Parameters<typeof createJiraEditorPluginAIProvider>[0]['actionSideEffects'];
	actionOverrides?: Parameters<typeof createJiraEditorPluginAIProvider>[0]['actionOverrides'];
	AIConfiguration?: AIPluginConfiguration;
	aiLifeCycleDynamicAttributesGetter?: Parameters<
		typeof createJiraEditorPluginAIProvider
	>[0]['aiLifeCycleDynamicAttributesGetter'];
	/**
	 * Editor AI now supports sending audit log events.
	 * It requires contextual information to send those events.
	 * Currently audit event for:
	 * - issue view: requires issueId
	 */
	auditLogEventData?: JiraAuditLogEventData;
};

export type SharedEditorConfigProps = {
	editorPluginAIConfig?: EditorPluginAIConfig & { linking?: EditorProps['linking'] };
};

// Return type used for the editor config
export type EditorConfig = {
	props: {
		isAIUpsellInEditorFreeEnabled?: boolean;
	};
	AIConfiguration: AIPluginConfiguration;
	components: {
		AIOptInModal: JSX.Element | null;
	};
};

type HandleFeedbackSubmission = NonNullable<
	Parameters<typeof createJiraEditorPluginAIProvider>[0]
>['handleFeedbackSubmission'];

export type HandleFeedbackSubmissionArgs = Parameters<NonNullable<HandleFeedbackSubmission>>[0];
