/**
 * @generated SignedSource<<3c4dfa249c9a3097de67316666f7f622>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTemplates_UserPreferences$data = {
  readonly userPreferences: {
    readonly showDateFieldAssociationMessageByIssueKey: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutTemplates_UserPreferences";
};
export type ui_issueViewLayoutTemplates_UserPreferences$key = {
  readonly " $data"?: ui_issueViewLayoutTemplates_UserPreferences$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTemplates_UserPreferences">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "issueKey"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTemplates_UserPreferences",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "issueKey",
              "variableName": "issueKey"
            }
          ],
          "kind": "ScalarField",
          "name": "showDateFieldAssociationMessageByIssueKey"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "65fab55ea852f9ebb5489737be7119a9";

export default node;
