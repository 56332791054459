/** @jsx jsx */
import React, { useState, useCallback, type KeyboardEvent, forwardRef, type Ref } from 'react';
import { styled, css, jsx } from '@compiled/react';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { token } from '@atlaskit/tokens';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import DropdownGroup from './dropdown-group/index.tsx';
import DropdownTrigger from './dropdown-trigger/index.tsx';
import type { MeatballMenuData } from './types.tsx';

type Props = {
	isDisabled?: boolean;
	isLoading?: boolean;
	hasPulse?: boolean;
	getMeatballMenuData: () => MeatballMenuData;
};

const customModifiers = [
	{
		name: 'preventOverflow',
		enabled: true,
		options: {
			padding: { top: 65, bottom: 5, left: 5, right: 5 },
		},
	},
];

// visible for testing
export const useIsOpen = () => useState(false);

const MeatballMenu = forwardRef(
	({ hasPulse, isDisabled, isLoading, getMeatballMenuData }: Props, ref?: Ref<HTMLElement>) => {
		const [isOpen, setOpen] = useIsOpen();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleOpen = useCallback(() => {
			setOpen(true);
			const analyticsEvent = createAnalyticsEvent({
				action: 'click',
				actionSubject: 'dropdownItem',
			});
			fireUIAnalytics(analyticsEvent, 'issueActionsOpenChanged');
		}, [createAnalyticsEvent, setOpen]);

		const handleClose = useCallback(() => {
			setOpen(false);
			const analyticsEvent = createAnalyticsEvent({
				action: 'click',
				actionSubject: 'dropdownItem',
			});
			fireUIAnalytics(analyticsEvent, 'issueActionsOpenChanged');
		}, [createAnalyticsEvent, setOpen]);

		const loadContent = useCallback(() => {
			const dropdownGroups = getMeatballMenuData()
				.filter(({ items }) => items.length > 0)
				.map((dropdownGroupData, dropdownGroupIndex) => (
					<DropdownGroup
						key={dropdownGroupData.key}
						dropdownGroupData={dropdownGroupData}
						isFirst={dropdownGroupIndex === 0}
						onClick={handleClose}
					/>
				));
			return (
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				<div css={contentWrapperStyles} data-testid="issue.issue-meatball-menu.content">
					{dropdownGroups}
				</div>
			);
		}, [getMeatballMenuData, handleClose]);

		const onKeyDown = useCallback(
			(event: KeyboardEvent | KeyboardEvent<EventTarget>) => {
				if (event.key === 'Escape' && isOpen) {
					// FIXME: JIV-15402, provide better support for this functionality
					// See https://jplat.atlassian.net/browse/JIV-15402
					event.nativeEvent?.stopImmediatePropagation();
					setOpen(false);
				}
			},
			[isOpen, setOpen],
		);

		return (
			<Container onKeyDown={onKeyDown}>
				<Popup
					boundary="clippingParents"
					isOpen={isOpen}
					placement="auto-end"
					trigger={(triggerProps) => {
						const { 'aria-expanded': ariaExpanded, ...restProps } = triggerProps;
						return (
							<div {...restProps}>
								<DropdownTrigger
									isOpen={isOpen}
									hasPulse={hasPulse === true}
									isDisabled={isDisabled}
									isLoading={isLoading}
									ariaExpanded={ariaExpanded}
									ref={ref}
									onClick={isOpen ? handleClose : handleOpen}
								/>
							</div>
						);
					}}
					onClose={handleClose}
					content={loadContent}
					zIndex={layers.modal}
					{...(fg('jfp_a11y_team_revert_issueview_popup_reading_order')
						? {}
						: {
								modifiers: customModifiers,
								shouldRenderToParent: true,
							})}
				/>
			</Container>
		);
	},
);

MeatballMenu.defaultProps = {
	hasPulse: false,
	isDisabled: false,
	isLoading: false,
};

export default MeatballMenu;

const menuWidthStyles = {
	minWidth: '150px',
	padding: `${token('space.050', '4px')} 0px`,

	'&&': {
		"[role='menuitem']": {
			padding: `${token('space.100', '8px')} ${token('space.150', '12px')} 7px`,
		},
		/**
		 * We cannot target the inner element by contains / starts with
		 * because that automatically includes the parent as well, as they
		 * both start with, contain in their class name "GroupTitle"
		 */

		"[class*='GroupTitle']": {
			padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
		},

		"[class*='GroupTitleText']": {
			padding: 0,
		},
	},
};

const contentWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	maxHeight: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${PAGE_LAYOUT_OFFSET_TOP})`,
	overflow: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage
	...menuWidthStyles,
});

// Added wrapper for Keydown to control modal close ref - JIV-15359
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'contents',
});
