import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	steps,
	useRelatedIssuesActions,
	useRelatedIssuesState,
} from '../../controllers/context.tsx';
import { useHasRelatesToIssueLinkType } from '../../utils/index.tsx';
import messages from '../messages.tsx';

export const FindSimilarIssuesButton = () => {
	const { formatMessage } = useIntl();

	const hasRelatesToLinkType = useHasRelatesToIssueLinkType();
	const { currentStep } = useRelatedIssuesState();
	const { setRelatedIssuesFetchStep, setIsLinkedIssuePanelButtonTriggered } =
		useRelatedIssuesActions();

	const triggerDraftListSuggestion = useCallback(() => {
		setRelatedIssuesFetchStep();
		setIsLinkedIssuePanelButtonTriggered(true);
	}, [setIsLinkedIssuePanelButtonTriggered, setRelatedIssuesFetchStep]);

	const isDisabled =
		!hasRelatesToLinkType ||
		(currentStep === steps.fetching && fg('aligning-jira-ai-features-ui-improvements'));
	const isSelected = currentStep !== steps.hidden;

	return (
		<Box xcss={buttonStyles}>
			<Button
				spacing="compact"
				iconBefore={(iconProps) => (
					<AiIcon
						{...iconProps}
						label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.findSimilarIssuesIssueTermRefresh
								: messages.findSimilarIssues,
						)}
						size="small"
						isDisabled={isDisabled}
					/>
				)}
				onClick={triggerDraftListSuggestion}
				isDisabled={isDisabled}
				{...(fg('aligning-jira-ai-features-ui-improvements') ? { isSelected } : {})}
			>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.findSimilarIssuesIssueTermRefresh
						: messages.findSimilarIssues,
				)}
			</Button>
		</Box>
	);
};

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
