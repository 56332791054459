import { v4 as uuid } from 'uuid';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { initialTemplates } from '../../common/constants.tsx';
import type { UserBranchSettingsObject } from '../../common/types.tsx';
import {
	setCaretPosition,
	setDefaultTemplates,
	setUserEnteredTemplate,
	insertPlaceholder,
	removePlaceholder,
	saveUserConfigBranchData,
	setInitialState,
	getUserConfigBranchData,
	setBranchData,
	setFocusTagIndex,
	setRestoreFocus,
	setEmptyTextStarterTemplates,
	restoreFocusAfterDeletingAllText,
	setIsGitCommandIncluded,
} from './actions/index.tsx';
import type { State } from './types.tsx';

export const actions = {
	setDefaultTemplates,
	setUserEnteredTemplate,
	setCaretPosition,
	insertPlaceholder,
	removePlaceholder,
	saveUserConfigBranchData,
	setInitialState,
	getUserConfigBranchData,
	setBranchData,
	setFocusTagIndex,
	setRestoreFocus,
	setEmptyTextStarterTemplates,
	restoreFocusAfterDeletingAllText,
	setIsGitCommandIncluded,
} as const;

export type Actions = typeof actions;

const defaultTemplates = Array.from(initialTemplates);

export const defaultSettings: UserBranchSettingsObject = {
	branchNameTemplate: defaultTemplates,
	version: 'v2',
	isGitCommandIncluded: true,
};

// @ExportForTesting
export const initialState: State = {
	// React.js: Getting "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
	// we need to update the key of the parent element to fix this issue
	uniqueKey: uuid(),
	writeOnlyTemplates: defaultTemplates,
	readAndWriteTemplates: defaultTemplates,
	get originalTemplates() {
		return defaultTemplates;
	}, // if there is no data come from DB will use defaultTemplates
	caretPosition: {
		templateIndex: 2,
		characterIndex: 0,
	},
	isLoading: false,
	isSaving: false,
	restoreFocus: false,
	saveError: null,
	branchData: {
		issueKey: '',
		issueSummary: '',
		issueType: '',
		projectKey: '',
	},
	focusTagIndex: 0,
};

export const Store = createStore<State, Actions>({
	name: 'branchCommandBuilderStore',
	initialState,
	actions,
});

export const useBranchCommandBuilderStore = createHook(Store);
