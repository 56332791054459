import { useCallback } from 'react';
import get from 'lodash/get';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { AssigneeKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { AUTOMATIC_ID } from '../../common/constants.tsx';
import type { CurrentAssignee, UserValue } from '../../common/types.tsx';
import type { Props } from './types.tsx';
import { saveAssigneeField } from './utils.tsx';

const getCurrentAssigneeUrl = (_baseUrl: string, issueKey: string) =>
	`/rest/api/3/issue/${issueKey}?fields=assignee`;

const getCurrentAssignee = async (baseUrl: string, issueKey: string) => {
	const response: CurrentAssignee = await performGetRequest(
		getCurrentAssigneeUrl(baseUrl, issueKey),
	);
	const { assignee } = response.fields;
	return assignee;
};

export const useAssigneeField = ({
	issueKey,
	initialValue,
	onSubmit,
	onSuccess,
	onFailure,
	onSaveField,
}: Props): [
	{
		value: UserValue;
		error: Error | null;
		fieldConfig: FieldConfiguration<UserValue> | null;
	},
	{
		saveValue: (value: UserValue, meta: null, analyticsEvent: UIAnalyticsEvent) => Promise<void>;
		resetError: () => void;
	},
] => {
	const fieldKey = AssigneeKey;
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);

	const fieldType = get(fieldConfig, 'schema.type', 'user');

	const [, { setFieldValue }] = useFieldValue({ issueKey, fieldKey: AssigneeKey });
	const baseUrl = '';

	const onSaveSuccess = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'currentAssignee' implicitly has an 'any' type.
		(currentAssignee) => {
			if (currentAssignee && currentAssignee.accountId === AUTOMATIC_ID) {
				getCurrentAssignee(baseUrl, issueKey)
					.then((assignee) => {
						setFieldValue(issueKey, AssigneeKey, assignee);
						onSuccess?.(assignee);
					})
					.catch((error) => {
						fireErrorAnalytics({
							meta: {
								id: 'currentAssigneeFetch',
								packageName: 'jiraIssueFieldAssignee',
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
						onSuccess?.(currentAssignee); // In case api fail, better to show Automatic only
					});
			} else {
				onSuccess?.(currentAssignee);
			}
		},
		[baseUrl, issueKey, onSuccess, setFieldValue],
	);

	const [{ value, error }, { saveValue, resetError }] = useEditField<UserValue, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: initialValue || null,
		onSubmit,
		onSuccess: onSaveSuccess,
		onFailure,
		saveField: fg('issue_cards_in_program_board')
			? onSaveField || saveAssigneeField
			: saveAssigneeField,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	];
};

export default useAssigneeField;
