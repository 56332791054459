/**
 * @generated SignedSource<<4bb8122e8075b1675b717c60399f7fb0>>
 * @relayHash 7527ccf58a10d8e098f3a1ed9e4f9532
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a033f6d9c510eccc5ed7f1a1f677a9b04d2c52d39178a5bfb71d4de15c92fb25

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraCustomerServiceUpdateOrganizationFieldInput = {
  id: string;
  operation?: JiraCustomerServiceUpdateOrganizationOperationInput | null | undefined;
};
export type JiraCustomerServiceUpdateOrganizationOperationInput = {
  operation: JiraSingleValueFieldOperations;
  organizationId?: string | null | undefined;
};
export type useUpdateIssueViewOrganizationField_Mutation$variables = {
  input: JiraCustomerServiceUpdateOrganizationFieldInput;
};
export type useUpdateIssueViewOrganizationField_Mutation$data = {
  readonly jira: {
    readonly updateOrganizationField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly selectedOrganization: {
          readonly id: string;
          readonly organizationId: string | null | undefined;
          readonly organizationName: string | null | undefined;
          readonly selectableLabel: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useUpdateIssueViewOrganizationField_Mutation = {
  response: useUpdateIssueViewOrganizationField_Mutation$data;
  variables: useUpdateIssueViewOrganizationField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "concreteType": "JiraServiceManagementOrganization",
  "kind": "LinkedField",
  "name": "selectedOrganization",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "name": "organizationId"
    },
    {
      "kind": "ScalarField",
      "name": "organizationName"
    },
    {
      "kind": "ScalarField",
      "name": "selectableLabel"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useUpdateIssueViewOrganizationField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCustomerServiceOrganizationFieldPayload",
            "kind": "LinkedField",
            "name": "updateOrganizationField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCustomerServiceOrganizationField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateIssueViewOrganizationField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCustomerServiceOrganizationFieldPayload",
            "kind": "LinkedField",
            "name": "updateOrganizationField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCustomerServiceOrganizationField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a033f6d9c510eccc5ed7f1a1f677a9b04d2c52d39178a5bfb71d4de15c92fb25",
    "metadata": {},
    "name": "useUpdateIssueViewOrganizationField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "21384f84e44ec5d93a3c9bdf365cada7";

export default node;
