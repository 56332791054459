import React, {
	createContext,
	type PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useRef,
	type FocusEventHandler,
} from 'react';
import noop from 'lodash/noop';

const CopyPasteContext = createContext(noop);

export type ClipboardEventHandlers = {
	onCopy?: (e: ClipboardEvent) => void;
	onPaste?: (e: ClipboardEvent) => void;
};

/**
 * Add global document listeners to copy and paste events which will invoke a delegated callback function as registered
 * by `useCopyPasteTarget`.
 */
export const CopyPasteContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const copyPasteTargetRef = useRef<ClipboardEventHandlers | null>(null);

	const updateCopyPasteTarget = useCallback((handlers: ClipboardEventHandlers) => {
		copyPasteTargetRef.current = handlers;
	}, []);

	const onCopy = useCallback((event: ClipboardEvent) => {
		copyPasteTargetRef.current?.onCopy?.(event);
	}, []);
	const onPaste = useCallback((event: ClipboardEvent) => {
		copyPasteTargetRef.current?.onPaste?.(event);
	}, []);

	useEffect(() => {
		globalThis.document.addEventListener('copy', onCopy);
		globalThis.document.addEventListener('paste', onPaste, true);
		return () => {
			globalThis.document.removeEventListener('copy', onCopy);
			globalThis.document.removeEventListener('paste', onPaste, true);
		};
	}, [onCopy, onPaste]);

	return (
		<CopyPasteContext.Provider value={updateCopyPasteTarget}>{children}</CopyPasteContext.Provider>
	);
};

type ClipboardTargetEventHandlers = {
	onFocus: FocusEventHandler;
	onBlur: FocusEventHandler;
};

/** Returns event handlers to enable binding/unbinding copy/paste callback functions when an element is focused/blurred. */
export const useCopyPasteTarget = ({
	onCopy,
	onPaste,
}: ClipboardEventHandlers): ClipboardTargetEventHandlers => {
	const updateCopyPasteTarget = useContext(CopyPasteContext);
	const onFocus = useCallback(() => {
		queueMicrotask(() => {
			updateCopyPasteTarget({ onCopy, onPaste });
		});
	}, [onCopy, onPaste, updateCopyPasteTarget]);

	const onBlur = useCallback(() => {
		updateCopyPasteTarget(null);
	}, [updateCopyPasteTarget]);

	return { onFocus, onBlur };
};
