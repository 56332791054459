import React, { useState, useMemo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useQueryLoader, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import AddIcon from '@atlaskit/icon/glyph/add';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	FRAGMENT_FIELD_OPTIONS_FIRST,
	MULTI_VERSIONS_FIELD_OPTIONS_FETCH_FIRST,
	versionStatus,
} from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import type { Group } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import {
	FooterContainer,
	OptionLabel,
	selectFooterStyles,
} from '@atlassian/jira-issue-internal-field-select/src/common/styled.tsx';
import { SelectableFieldEditViewWithFieldOptionsFragment } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/index.tsx';
import type { SelectableFieldEditViewWithFieldOptionsFragmentProps } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import { useProjectId } from '@atlassian/jira-project-context-service/src/main.tsx';
import createReleaseModalQuery, {
	type createReleaseModalQuery as createReleaseModalQueryType,
} from '@atlassian/jira-relay/src/__generated__/createReleaseModalQuery.graphql';
import type { releaseVersionsEditViewFullFieldQuery as ReleaseVersionsEditViewFullFieldQuery } from '@atlassian/jira-relay/src/__generated__/releaseVersionsEditViewFullFieldQuery.graphql';
import selectableFieldSearchRefetchQuery, {
	type selectableFieldSearchRefetchQuery as SelectableFieldRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/selectableFieldSearchRefetchQuery.graphql';
import selectableFieldFragment, {
	type ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$key as SelectableFieldFragment,
} from '@atlassian/jira-relay/src/__generated__/ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment.graphql';
import type { Version } from '@atlassian/jira-software-releases-release-modals-relay/src/common/ui/types.tsx';
import { useCreateReleaseExperienceStart } from '@atlassian/jira-software-releases-release-modals-relay/src/services/create-release-experience-tracker/index.tsx';
import { CreateReleaseModal } from '@atlassian/jira-software-releases-release-modals-relay/src/ui/create-release-modal/async.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import messages from './messages.tsx';
import type {
	CreatableReleaseVersionsEditViewProps,
	ReleaseVersionsEditViewDeprecatedProps,
	ReleaseVersionsEditViewProps,
} from './types.tsx';
import { sortOptions } from './utils.tsx';

const CreatableReleaseVersionsEditView = ({
	onReleaseCreation,
	onChange,
	value,
	fieldId,
	fieldOptionsFragmentRef,
	classNamePrefix,
	isMulti,
	isClearable,
	projectId,
	...restProps
}: CreatableReleaseVersionsEditViewProps) => {
	const accountId = useAccountId();
	const experienceStart = useCreateReleaseExperienceStart('issueView');
	const projectKey = useProjectKey();
	const issueProjectId = useProjectId(projectKey);

	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const { formatMessage } = useIntl();

	const [createReleaseFormQueryRef, loadQuery] =
		useQueryLoader<createReleaseModalQueryType>(createReleaseModalQuery);

	const loadCreateReleaseQuery = () => {
		if (!createReleaseFormQueryRef && accountId) {
			loadQuery({ accountId });
		}
	};

	const onFooterClick = () => {
		experienceStart();
		loadCreateReleaseQuery();
	};

	const [, refetch] = useRefetchableFragment<SelectableFieldRefetchQuery, SelectableFieldFragment>(
		selectableFieldFragment,
		fieldOptionsFragmentRef,
	);

	const [suspenselessRefetch] = useSuspenselessRefetch<
		SelectableFieldRefetchQuery,
		SelectableFieldFragment
	>(selectableFieldSearchRefetchQuery, refetch, { fetchPolicy: 'network-only' });

	const onSuccess = (version: Version) => {
		if (onChange && version.ari) {
			let currentValues: SelectableFieldEditViewWithFieldOptionsFragmentProps['value'] = [];

			if (value) {
				currentValues = Array.isArray(value) ? value : [value];
			}

			onChange(
				[
					...currentValues,
					{
						id: version.ari,
						versionId: version.id,
						selectableLabel: version.name,
						selectableGroupKey: null,
						selectableIconUrl: null,
						isDisabled: false,
					},
				],
				{
					action: 'select-option',
				},
			);

			onReleaseCreation?.(version);

			suspenselessRefetch({
				id: fieldId,
				includeSingleSelectColorField: false,
			});
		}
	};

	const Footer = (
		<CreateReleaseVersionFooter
			data-testid="issue-field-release-versions-editview-full.ui.release-versions.create-release-version-footer"
			onClick={onFooterClick}
			onFocus={loadCreateReleaseQuery}
			onMouseOver={loadCreateReleaseQuery}
		>
			<AddIcon label={formatMessage(messages.createNewIcon)} size="small" />
			<OptionLabel>{formatMessage(messages.createNewItem)}</OptionLabel>
		</CreateReleaseVersionFooter>
	);

	return (
		<>
			{isDialogOpen && createReleaseFormQueryRef != null && (
				<CreateReleaseModal
					queryReference={createReleaseFormQueryRef}
					onClose={() => setIsDialogOpen(false)}
					projectId={projectId || String(issueProjectId)}
					onReleaseCreation={onSuccess}
				/>
			)}
			<SelectableFieldEditViewWithFieldOptionsFragment
				{...restProps}
				fieldId={fieldId}
				onChange={onChange}
				value={value}
				footer={Footer}
				onFooterSelect={() => setIsDialogOpen(true)}
				styles={selectFooterStyles}
				classNamePrefix={classNamePrefix}
				isMulti={isMulti}
				isClearable={isClearable}
				fieldOptionsFragmentRef={fieldOptionsFragmentRef}
				hasInfiniteScroll={false}
				optionsFirstCount={
					fg('issue_view_versions_fetch_1000_options')
						? MULTI_VERSIONS_FIELD_OPTIONS_FETCH_FIRST
						: FRAGMENT_FIELD_OPTIONS_FIRST
				}
			/>
		</>
	);
};

/**
 * @deprecated Use `ReleaseVersionEditView` without the modal creation logic.
 * The consumer should handle the modal rendering and the creation of the version.
 */
export const ReleaseVersionsEditViewDeprecated = (
	props: ReleaseVersionsEditViewDeprecatedProps,
) => {
	const { formatMessage } = useIntl();

	const formatGroupLabel = (group: Group) => {
		switch (group.label) {
			case versionStatus.RELEASED:
				return formatMessage(messages.releasedHeading);
			case versionStatus.UNRELEASED:
				return formatMessage(messages.unreleasedHeading);
			default:
				return group.label;
		}
	};

	const Component = props.canCreateVersions
		? CreatableReleaseVersionsEditView
		: SelectableFieldEditViewWithFieldOptionsFragment;

	const suggestionsData = useLazyLoadQuery<ReleaseVersionsEditViewFullFieldQuery>(
		graphql`
			query releaseVersionsEditViewFullFieldQuery($id: ID!) {
				...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
					@arguments(id: $id)
			}
		`,
		{ id: props.fieldId },
		{ fetchPolicy: 'store-only' },
	);

	return (
		<Component
			{...props}
			// @ts-expect-error We know formatGroupLabel is only drilled to the AK Select component, fix in https://jplat.jira.atlassian.cloud/browse/JIV-17763
			formatGroupLabel={formatGroupLabel}
			classNamePrefix={props.classNamePrefix}
			fieldOptionsFragmentRef={suggestionsData}
			placeholder={formatMessage(messages.placeholder)}
		/>
	);
};

export const ReleaseVersionsEditView = ({
	shouldShowFooter,
	onCreateFooterSelect,
	onCreateFooterFocus,
	footer,
	...props
}: ReleaseVersionsEditViewProps) => {
	const { formatMessage } = useIntl();

	const formatGroupLabel = (group: Group) => {
		switch (group.label) {
			case versionStatus.RELEASED:
				return formatMessage(messages.releasedHeading);
			case versionStatus.UNRELEASED:
				return formatMessage(messages.unreleasedHeading);
			default:
				return group.label;
		}
	};

	const Footer = useMemo(() => {
		if (shouldShowFooter) {
			if (footer) return footer;

			return (
				<CreateReleaseVersionFooter
					data-testid="issue-field-release-versions-editview-full.ui.release-versions.create-release-version-footer"
					onClick={onCreateFooterSelect}
					onFocus={onCreateFooterFocus}
					onMouseOver={onCreateFooterFocus}
				>
					<AddIcon label={formatMessage(messages.createNewIcon)} size="small" />
					<OptionLabel>{formatMessage(messages.createNewItem)}</OptionLabel>
				</CreateReleaseVersionFooter>
			);
		}
	}, [formatMessage, shouldShowFooter, onCreateFooterSelect, onCreateFooterFocus, footer]);

	const suggestionsData = useLazyLoadQuery<SelectableFieldRefetchQuery>(
		graphql`
			query releaseVersions_issueSelectableFieldQuery($id: ID!) {
				...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
					@arguments(id: $id)
			}
		`,
		{ id: props.fieldId, includeSingleSelectColorField: false },
		{ fetchPolicy: 'store-only' },
	);

	return (
		<SelectableFieldEditViewWithFieldOptionsFragment
			{...props}
			// @ts-expect-error We know formatGroupLabel is only drilled to the AK Select component, fix in https://jplat.jira.atlassian.cloud/browse/JIV-17763
			formatGroupLabel={formatGroupLabel}
			classNamePrefix={props.classNamePrefix}
			placeholder={formatMessage(messages.placeholder)}
			styles={selectFooterStyles}
			footer={Footer}
			fieldOptionsFragmentRef={suggestionsData}
			hasInfiniteScroll={false}
			optionsFirstCount={
				fg('issue_view_versions_fetch_1000_options')
					? MULTI_VERSIONS_FIELD_OPTIONS_FETCH_FIRST
					: FRAGMENT_FIELD_OPTIONS_FIRST
			}
			{...(fg('version_field_dropdown_order_fix') ? { sortOptions } : {})}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateReleaseVersionFooter = styled(FooterContainer)({
	// Needed to apply styles with `selectFooterStyles`
	className: 'issue-view__select-footer',
});
