/* eslint-disable jira/deprecations/no-base-url */ // baseUrl is used as a prop name here from confluenceAppLinks
import React, { useMemo, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { IconButton } from '@atlaskit/button/new';
import { PopupSelect } from '@atlaskit/select';
import GlobeIcon from '@atlaskit/icon/core/globe';
import type { SingleValue } from '@atlaskit/react-select';
import { useIntl } from '@atlassian/jira-intl';
import type { siteSwitcherQuery } from '@atlassian/jira-relay/src/__generated__/siteSwitcherQuery.graphql';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from '../messages.tsx';
import { useRelatedResourcesActions } from '../../controllers/related-resources-context.tsx';
import {
	RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY,
	type SiteSwitcherSelection,
} from '../../common/types.tsx';

function cleanUrl(url: string) {
	// Use a regular expression to match and replace the unwanted parts
	return url.replace(/^https:\/\/(.*?)\/wiki$/, '$1');
}

const storage = createLocalStorageProvider(RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY);

export const SiteSwitcher = ({
	confluenceAppLinks,
}: {
	confluenceAppLinks: ConfluenceAppLink[];
}) => {
	const [selectedSite, setSelectedSite] = useState<SiteSwitcherSelection | null>(
		storage.get(RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY),
	);
	const { formatMessage } = useIntl();
	const { setConfluenceSiteAri, fetchSuggestions } = useRelatedResourcesActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const hostNamesFromApplinks = useMemo(
		() =>
			confluenceAppLinks.reduce(
				(acc: { [key: string]: string }, { baseUrl, name, requireCredentials }) => {
					/**
					 * Skip the site if it requires credentials
					 */
					if (requireCredentials) {
						return acc;
					}
					const hostname = cleanUrl(baseUrl);
					acc[hostname] = name;
					return acc;
				},
				{},
			),
		[confluenceAppLinks],
	);

	const data = useLazyLoadQuery<siteSwitcherQuery>(
		graphql`
			query siteSwitcherQuery($hostNames: [String!]!) {
				tenantContexts(hostNames: $hostNames) {
					hostName
					cloudId
				}
			}
		`,
		{ hostNames: Object.keys(hostNamesFromApplinks) },
	);

	const selectData = useMemo(
		() =>
			data.tenantContexts
				?.map((context) => {
					if (!context?.hostName) return null;
					return {
						label: hostNamesFromApplinks[context.hostName],
						value: context.cloudId,
					};
				})
				.filter(Boolean) || [],
		[data.tenantContexts, hostNamesFromApplinks],
	);

	const onChange = (newValue: SingleValue<{ label: string; value: string | null | undefined }>) => {
		if (newValue && newValue.value) {
			storage.set(RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY, newValue);
			setSelectedSite(newValue);
			setConfluenceSiteAri(newValue.value);
			fetchSuggestions('suggestConfluencePagesButton', createAnalyticsEvent({}));
		}
	};

	// If there is only one site, we don't need to show the site switcher
	if (!(selectData.length > 1)) {
		return null;
	}

	return (
		<PopupSelect
			testId="ai-related-resources.ui.site-switcher.related-resources-site-switcher"
			options={selectData}
			searchThreshold={10}
			popperProps={{ placement: 'bottom-end' }}
			target={({ isOpen, ...triggerProps }) => (
				<IconButton
					{...triggerProps}
					icon={GlobeIcon}
					label={formatMessage(messages.siteSwitcherLabel)}
					appearance="subtle"
					isTooltipDisabled={false}
				/>
			)}
			onChange={onChange}
			defaultValue={selectedSite}
		/>
	);
};
