import type { JSResourceOptions, JSResourceReference, Loader } from '../../types';
import { createCache } from '../../utils';
import { JSResource } from '../js-resource';
import { postTask } from '../scheduler';

const cache = createCache(new Map<string, JSResourceReference<any>>());

export function importForInteraction<T>(
	loader: Loader<T>,
	opts?: JSResourceOptions,
): JSResourceReference<T> {
	const resource = cache.get(opts!.moduleId, () =>
		JSResource(() => postTask(loader, { priority: 'user-visible' }), opts!),
	);

	resource.load().catch(() => {});

	return resource;
}
