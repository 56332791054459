import React from 'react';
import { Flex } from '@atlaskit/primitives';
import ChangesIcon from '@atlaskit/icon/core/changes';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import {
	isIssueTypeIdFilter,
	type ConnectionFieldIssueTypeFilter,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';

type Props = {
	issueTypeFilters?: ConnectionFieldIssueTypeFilter[];
};

export const ConnectionFieldIcon = ({ issueTypeFilters = [] }: Props) => {
	const issueTypeIds = issueTypeFilters.find(isIssueTypeIdFilter);

	if (!issueTypeIds?.ids.length) {
		// eslint-disable-next-line jira/i18n/text-wrapped-in-format-message
		return <ChangesIcon label="connection" color="var(--ds-icon)" />;
	}

	return (
		<Flex alignItems="center">
			<IssueTypeIcon issueTypeId={issueTypeIds.ids[0]} />
		</Flex>
	);
};
