import React, { type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import { ViewAnalyticsWrapper } from '@atlassian/jira-forge-issue-action/src/analytics/ui/index.tsx';
import { AsyncIssueAction } from '@atlassian/jira-forge-issue-action/src/ui/async/AsyncIssueAction.tsx';
import type { ForgeUiAnalyticsAttributes } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { IssueAction } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	forgeIssueActionClose,
	forgeIssueActionRendered,
} from '@atlassian/jira-issue-view-store/src/actions/forge-actions.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	forgeActiveIssueActionSelector,
	forgeIssueDataSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';

export type Props = {
	extensionData: ForgeUiIssueData;
	extension: IssueAction | null;
	source: string | undefined;
	onClose: () => void;
	onRendered: () => void;
};

export const ForgeActionRendererView = ({
	extension,
	extensionData,
	onClose,
	onRendered,
	source,
}: Props) => {
	if (!extension) {
		return null;
	}

	const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Escape' || e.key === 'Esc') {
			e.nativeEvent.stopImmediatePropagation();
			onClose();
		}
	};

	return (
		<InvisibleDiv
			data-testid="issue-view-foundation.issue-actions.ecosystem-action.forge.invisible-div"
			onKeyDown={onKeyDown}
		>
			<AsyncIssueAction
				extension={extension}
				extensionData={extensionData}
				analyticsAttributes={{ source }}
				onClose={onClose}
				onInitialRender={onRendered}
			/>
		</InvisibleDiv>
	);
};

export const ForgeActionRendererViewWithAnalytics = (props: Props) => (
	<ViewAnalyticsWrapper
		attributes={
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{
				source: props.source,
				...(props.extension && getAnalyticsAttributesFromExtension(props.extension)),
			} as ForgeUiAnalyticsAttributes
		}
	>
		<ForgeActionRendererView {...props} />
	</ViewAnalyticsWrapper>
);

export const ForgeActionRenderer = flowWithSafeComponent(
	connect(
		(state: State) => {
			const activeAction = forgeActiveIssueActionSelector(state);
			return {
				extension: activeAction != null ? activeAction.action.extension : null,
				extensionData: forgeIssueDataSelector(state),
				source: analyticsSourceSelector(state),
			};
		},
		{
			onClose: forgeIssueActionClose,
			onRendered: forgeIssueActionRendered,
		},
	),
)(ForgeActionRendererViewWithAnalytics);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InvisibleDiv = styled.div({
	display: 'none',
});
