// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.components.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select component',
		description: '',
	},
	createNewItem: {
		id: 'issue.components.create-new-component-message',
		defaultMessage: 'Create new component',
		description: '',
	},
	recent: {
		id: 'issue.components.recent-items',
		defaultMessage: 'Recent components',
		description: 'Header displayed in components list used to mark recently selected items',
	},
	all: {
		id: 'issue.components.all-items',
		defaultMessage: 'All components',
		description: 'Header displayed in components list used to mark fetched from server items',
	},
	disabledJiraComponent: {
		id: 'issue.tag-view.disabled-jira-component',
		defaultMessage:
			'<italic>{componentName}</italic> can be removed from this issue, but to add it again, switch to Jira components from the <strong>Components</strong> page.',
		description:
			'Message shown to user when they hover over project component when have Compass components enabled',
	},
	disabledCompassComponent: {
		id: 'issue.tag-view.disabled-compass-component',
		defaultMessage:
			'<italic>{componentName}</italic> can be removed from this issue, but to add it again, switch to Compass components from the <strong>Components</strong> page.',
		description:
			'Message shown to user when they hover over Compass component when have Compass components disabled',
	},
	disabledJiraComponentIssueTermRefresh: {
		id: 'issue.tag-view.disabled-jira-component-issue-term-refresh',
		defaultMessage:
			'<italic>{componentName}</italic> can be removed from this work item, but to add it again, switch to Jira components from the <strong>Components</strong> page.',
		description:
			'Message shown to user when they hover over project component when have Compass components enabled',
	},
	disabledCompassComponentIssueTermRefresh: {
		id: 'issue.tag-view.disabled-compass-component-issue-term-refresh',
		defaultMessage:
			'<italic>{componentName}</italic> can be removed from this work item, but to add it again, switch to Compass components from the <strong>Components</strong> page.',
		description:
			'Message shown to user when they hover over Compass component when have Compass components disabled',
	},
});
