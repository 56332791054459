import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { CONTENT_TOO_LARGE } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import { secondsToWholeMinuteTimeString } from '@atlassian/jira-issue-format-time/src/common/utils/seconds-to-whole-minute-time-string/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { ADD_MODAL } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import timeStringToSeconds from '@atlassian/jira-issue-view-common-utils/src/time-string/time-string-to-seconds/index.tsx';
import { getIssueLimitErrorField } from '@atlassian/jira-issue-view-errors/src/common/utils/index.tsx';
import { FIELD_EDIT_REQUEST_EXTERNAL } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import {
	openModal,
	type Action,
	type AddWorklogRequestAction,
	type UpdateTimeRemainingRequestAction,
	addWorklogSuccess,
	ADD_WORKLOG_REQUEST,
	UPDATE_TIME_REMAINING_REQUEST,
	addWorklogFailure,
	updateTimeRemainingSuccess,
	updateTimeRemainingFailure,
	fetchSurroundingWorklogsById,
} from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	idSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	timeTrackingConfigurationSelector,
	timeTrackingSelector,
	canLogTimeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { TIME_TRACKING_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { hasMediaFileNodes } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { worklogExperienceDescription } from '../../common/experience-description/index.tsx';
import { transformWorklog } from '../../common/transform-worklogs/index.tsx';
import worklogHasComment, { isNotEmptyComment } from '../../common/worklog-has-comment/index.tsx';
import addWorklog from '../../rest/add-worklog.tsx';
import saveRemainingEstimate from '../../rest/save-remaining-estimate.tsx';

export const addWorklogEpic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(action$.ofType(ADD_WORKLOG_REQUEST) as Observable<AddWorklogRequestAction>).switchMap(
		(action) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = toIssueKey(issueKeySelector(state));
			const projectType = projectTypeSelector(state);
			const config = timeTrackingConfigurationSelector(state);
			const timeTracking = timeTrackingSelector(state);
			const parseTime = timeStringToSeconds(config.daysPerWeek, config.hoursPerDay);
			const {
				payload: { values, commitTimeTrackingLocallyToRelay },
				meta: { analyticsEvent },
			} = action;
			const { timeRemaining, workDescription } = values || {};
			const shouldFetchViewContext = isNotEmptyComment(workDescription)
				? hasMediaFileNodes(workDescription)
				: false;

			return Observable.from(addWorklog(baseUrl, issueKey, values))
				.mergeMap((newWorklog) => {
					const transformedWorklog = transformWorklog(newWorklog);
					const withComment = worklogHasComment(transformedWorklog);
					fireTrackAnalytics(analyticsEvent, {
						action: 'added',
						actionSubjectId: transformedWorklog.id,
						attributes: { withComment },
					});

					const timeSpentSeconds =
						(timeTracking.timeSpentSeconds || 0) + newWorklog.timeSpentSeconds;

					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: true,
								action: 'ADD',
								analyticsSource: 'addWorklogEpic',
								projectType,
							}),
					});

					// we need to ensure the value that is set into redux
					// is the same value that was sent to the backend
					const remainingEstimateSeconds =
						typeof values.timeRemainingSeconds === 'number'
							? parseTime(secondsToWholeMinuteTimeString(values.timeRemainingSeconds))
							: null;
					// conditionally override remainingEstimateSeconds
					const newTimeTracking =
						remainingEstimateSeconds !== null
							? {
									...timeTracking,
									timeSpentSeconds,
									remainingEstimateSeconds,
								}
							: {
									...timeTracking,
									timeSpentSeconds,
								};

					if (
						commitTimeTrackingLocallyToRelay &&
						fg('relay-migration-issue-fields-time-tracking')
					) {
						try {
							commitTimeTrackingLocallyToRelay(newTimeTracking);
						} catch (error) {
							log.safeErrorWithoutCustomerData(
								'issue.activity.worklog.add-worklog',
								`Failed to commit local Relay update with time tracking lengths: ${JSON.stringify(newTimeTracking)}`,
							);
						}
					}

					return Observable.of(
						addWorklogSuccess(
							newWorklog.id,
							newTimeTracking,
							{
								shouldFetchViewContext,
							},
							issueKey,
						),
						// @ts-expect-error - TS2769 - No overload matches this call.
						fetchSurroundingWorklogsById(newWorklog.id),
					);
				})
				.catch((error) => {
					let invalidMessage;
					if (error.statusCode === CONTENT_TOO_LARGE) {
						invalidMessage = getIssueLimitErrorField(error);
					}
					// BENTO-3387 - Log the length of the time logged/time remaining input values to
					// verify whether errors are being caused by users entering large values
					const { timeLogged } = values;
					const logTimeLength = timeLogged && timeLogged.length;
					const remainingTimeLength = timeRemaining && timeRemaining.length;
					const lengths = { logTimeLength, remainingTimeLength };

					sendExperienceAnalytics({
						getExperienceDescription: ({ wasSuccessful, statusCode }) => {
							if (!wasSuccessful) {
								log.safeErrorWithoutCustomerData(
									'issue.activity.worklog.add-worklog',
									`Failed to save new worklog with input lengths: ${JSON.stringify(lengths)}`,
									error,
								);
							}

							return worklogExperienceDescription({
								wasSuccessful,
								action: 'ADD',
								analyticsSource: 'addWorklogEpic',
								projectType,
								errorMessage: wasSuccessful ? undefined : error.message,
								statusCode,
								...(fg('thor_add_missing_attributes_across_issue_view_2')
									? { traceId: error?.traceId }
									: {}),
							});
						},
						error,
					});
					return Observable.of(addWorklogFailure({ invalidMessage }));
				});
		},
	);

export const updateTimeRemainingEpic = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	(action$.ofType(UPDATE_TIME_REMAINING_REQUEST) as Observable<UpdateTimeRemainingRequestAction>) // eslint-disable-line @typescript-eslint/consistent-type-assertions
		.switchMap((action) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueId = idSelector(state);
			const projectType = projectTypeSelector(state);
			const config = timeTrackingConfigurationSelector(state);
			const parseTime = timeStringToSeconds(config.daysPerWeek, config.hoursPerDay);
			const {
				payload: { timeRemaining: payloadTimeRemaining },
				meta: { analyticsEvent },
			} = action;

			// Convert the timeRemaining from time-string to minutes because the
			// backend doesn't support weeks/days/hours with decimal places other than
			// 0.25, 0.5 or 0.75
			// we have to convert the final value to a whole number as passing decimal
			// values in minutes errors.
			const timeRemaining = secondsToWholeMinuteTimeString(parseTime(payloadTimeRemaining) || 0);

			return Observable.from(saveRemainingEstimate(baseUrl, issueId, timeRemaining))
				.do(() => {
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: true,
								action: 'ADD',
								analyticsSource: 'updateTimeRemainingEpic',
								projectType,
							}),
					});

					fireTrackAnalytics(analyticsEvent, {
						action: 'time-remaining-updated',
					});
				})
				.map(() => updateTimeRemainingSuccess(parseTime(timeRemaining)))
				.catch((error) => {
					// BENTO-3387 - Log the length of the time remaining input value to
					// verify whether errors are being caused by users entering large values
					const remainingTimeLength = timeRemaining && timeRemaining.length;

					sendExperienceAnalytics({
						getExperienceDescription: ({ wasSuccessful, statusCode }) => {
							if (!wasSuccessful) {
								log.safeErrorWithoutCustomerData(
									'issue.activity.worklog.update-time-remaining',
									`Failed to update time remaining with input length: ${remainingTimeLength}`,
									error,
								);
							}

							return worklogExperienceDescription({
								wasSuccessful,
								action: 'ADD',
								analyticsSource: 'updateTimeRemainingEpic',
								projectType,
								errorMessage: wasSuccessful ? undefined : error.message,
								statusCode,
							});
						},
						error,
					});
					return Observable.of(updateTimeRemainingFailure());
				});
		});

export const openModalFromExternalSourceEpic = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(FIELD_EDIT_REQUEST_EXTERNAL)
		// @ts-expect-error - TS2339 - Property 'payload' does not exist on type 'Action'.
		.filter((action) => action.payload?.fieldId === TIME_TRACKING_TYPE)
		.filter(() => canLogTimeSelector(store.getState()))
		.map(() => openModal(ADD_MODAL, null));

export const combinedEpics = combineEpics<Action, State>(
	// @ts-expect-error - Action mismatch
	addWorklogEpic,
	updateTimeRemainingEpic,
	openModalFromExternalSourceEpic,
);
