import type { SelectableValueOption } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';

const sortOrder = ['RECENT', 'UNRELEASED', 'RELEASED', 'ARCHIVED'];

export const sortOptions: (options: SelectableValueOption[]) => SelectableValueOption[] = (
	options: SelectableValueOption[],
) =>
	options.sort(
		(a: SelectableValueOption, b: SelectableValueOption) =>
			sortOrder.indexOf(a?.selectableGroupKey || '') -
			sortOrder.indexOf(b?.selectableGroupKey || ''),
	);
