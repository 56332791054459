import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import type { FieldOperations } from '@atlassian/jira-common-rest/src/internal/simplified/1.0/projects/id/issuetypes/id/types.tsx';
import {
	TEXT_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	DATETIME_CF_TYPE,
	DATE_CF_TYPE,
	NUMBER_CF_TYPE,
	SELECT_CF_TYPE,
	LABELS_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	URL_CF_TYPE,
	PEOPLE_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { FieldTypeList } from '@atlassian/jira-project-settings-apps-issue-type-page-product-features/src/common/types/layout-items.tsx';

const defaultFieldOperations: FieldOperations = {
	editable: true,
	canModifyRequired: true,
	canModifyOptions: true,
	canModifyDefaultValue: true,
	canModifyPropertyConfiguration: true,
	removable: true,
	categoriesWhitelist: [],
	canAssociateInSettings: true,
	deletable: true,
};

const operations = { ...defaultFieldOperations, canModifyDefaultValue: false };

export const availableFieldTypes: FieldTypeList = [
	{
		key: TEXT_CF_TYPE,
		name: 'Short text',
		operations,
	},
	{
		key: TEXT_AREA_CF_TYPE,
		name: 'Paragraph',
		operations,
	},
	{
		key: DATETIME_CF_TYPE,
		name: 'Timestamp',
		operations,
	},
	{
		key: SELECT_CF_TYPE,
		name: 'Dropdown',
		operations,
	},
	{
		key: DATE_CF_TYPE,
		name: 'Date',
		operations,
	},
	{
		key: NUMBER_CF_TYPE,
		name: 'Number',
		operations,
	},
	{
		key: LABELS_CF_TYPE,
		name: 'Labels',
		operations,
	},
	{
		key: MULTI_CHECKBOXES_CF_TYPE,
		name: 'Checkbox',
		operations,
	},
	{
		key: PEOPLE_CF_TYPE,
		name: 'People',
		operations,
	},
	{
		key: URL_CF_TYPE,
		name: 'Url',
		operations,
	},
];

export const PACKAGE_NAME = 'jiraBusinessInlineFieldConfigSidebar';

export const CREATE_FIELD_EXPERIENCE: ExperienceDetails = {
	experience: 'createField',
	packageName: PACKAGE_NAME,
	teamName: 'deliveroo',
};

export const ASSOCIATE_FIELD_EXPERIENCE: ExperienceDetails = {
	experience: 'associateField',
	packageName: PACKAGE_NAME,
	teamName: 'deliveroo',
};

export const DELETE_FIELD_EXPERIENCE: ExperienceDetails = {
	experience: 'deleteField',
	packageName: PACKAGE_NAME,
	teamName: 'deliveroo',
};

export const UPDATE_FIELD_EXPERIENCE: ExperienceDetails = {
	experience: 'updateField',
	packageName: PACKAGE_NAME,
	teamName: 'deliveroo',
};

export const GET_FIELDS_CONFIGURATION_EXPERIENCE: ExperienceDetails = {
	experience: 'getFieldsConfiguration',
	packageName: PACKAGE_NAME,
	teamName: 'deliveroo',
};

export const GET_FIELD_DETAILS_EXPERIENCE: ExperienceDetails = {
	experience: 'getFieldDetails',
	packageName: PACKAGE_NAME,
	teamName: 'deliveroo',
};
