import React from 'react';
import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import AkIcon from '@atlaskit/icon';
import { Box, xcss } from '@atlaskit/primitives';
import AttachmentIconOld from '@atlaskit/icon/core/migration/attachment';
import ChildIssuesIconOld from '@atlaskit/icon/core/migration/child-issues';
import GlobeIcon from '@atlaskit/icon/core/migration/globe--world';
import IssuesIconOld from '@atlaskit/icon/core/migration/issues';
import LightbulbIconOld from '@atlaskit/icon/core/migration/lightbulb';
import SearchIconOld from '@atlaskit/icon/core/migration/search--editor-search';
import SubtasksIcon from '@atlaskit/icon/core/migration/subtasks--subtask';
import { ConfluenceIcon } from '@atlaskit/logo';
import AttachmentIconNew from '@atlaskit/icon/core/attachment';
import ChildIssuesIconNew from '@atlaskit/icon/core/child-issues';
import IssuesIconNew from '@atlaskit/icon/core/issue';
import LightbulbIconNew from '@atlaskit/icon/core/lightbulb';
import SearchIconNew from '@atlaskit/icon/core/search';
import PageIcon from '@atlaskit/icon/core/page';
import AlertIcon from '@atlaskit/icon/core/alert';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import {
	ADD_ATTACHMENT_ID,
	CREATE_SUBTASK_ID,
	CREATE_ISSUE_IN_EPIC_ID,
	ADD_ISSUE_LINKS_ID,
	CREATE_CHILD_ID,
	LINK_CONF_PAGE_ID,
	ADD_WEB_LINK_ID,
	LINK_ALERTS_ID,
	INVESTIGATE_INCIDENT_ID,
	LINK_IDEA_ID,
	LINK_CONF_PAGE_NESTED_MENU_ID,
	LINK_CONF_WHITEBOARD_NESTED_MENU_ID,
} from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { AlertsGlyph } from '@atlassian/jira-servicedesk-incident-management-common/src/assets/index.tsx';
import { INCIDENTS } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	QuickAddItemToRender,
	QuickAddItemStateProps,
	QuickAddItemDispatchProps,
} from '../../types.tsx';
import messages from '../messages.tsx';
import type { IncidentManagementField } from './types.tsx';
import { shouldLinkAlertsItemRender } from './utils.tsx';

export type ItemListProps = QuickAddItemStateProps &
	QuickAddItemDispatchProps & {
		supportsChildCreation: boolean;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		incidentLinking?: Record<any, any> | undefined;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		incidentEscalate?: Record<any, any> | undefined;
		incidentManagement?: IncidentManagementField;
		formatMessage: FormatMessage;
		onLinkAlertsClick?: () => void;
		hasPremiumServiceDeskAccess?: boolean;
		isQuickActionsListView?: boolean;
		createAnalyticsEvent?: (payload: AnalyticsEventPayload) => UIAnalyticsEvent;
	};

export type ItemList = {
	[key: string]: QuickAddItemToRender;
};

const getQuickActionItems = (props: ItemListProps): ItemList => {
	const {
		formatMessage,
		canCreateAttachment,
		shouldShowCreateSubtaskButton,
		shouldShowIssueInEpicButton,
		canCreateIssueLink,
		onAddAttachmentClick,
		onCreateSubtaskClick,
		onCreateIssueInEpicClick,
		onChildCreateClick,
		supportsChildCreation,
		isServiceDesk,
		canLinkConfluencePage,
		onLinkConfluencePageClick,
		incidentManagement,
		onLinkAlertsClick,
		canAddWebLinks,
		onAddWebLinkClick,
		onAddIssueLinkClick,
		onLinkIdeaClick,
		onInvestigateIncidentClick,
		practices,
		hasPremiumServiceDeskAccess,
		isQuickActionsListView,
		createAnalyticsEvent,
	} = props;

	const isQuickActionsGAWithListView = !!isQuickActionsListView && !!fg('quick_actions_menu_ga');

	let AttachmentIcon = AttachmentIconOld;
	let ChildIssuesIcon = ChildIssuesIconOld;
	let IssuesIcon = IssuesIconOld;
	let LightbulbIcon = LightbulbIconOld;
	let SearchIcon = SearchIconOld;

	if (isQuickActionsGAWithListView && fg('quick_actions_m2_experiment_gate')) {
		AttachmentIcon = AttachmentIconNew;
		ChildIssuesIcon = ChildIssuesIconNew;
		IssuesIcon = IssuesIconNew;
		LightbulbIcon = LightbulbIconNew;
		SearchIcon = SearchIconNew;
	}

	const createChildLabel = (() => {
		if (isQuickActionsGAWithListView) {
			if (expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)) {
				if (fg('quick_actions_m2_experiment_gate')) {
					return formatMessage(messages.createChildIssueDropdownLabelIssueTermRefresh);
				}
				return formatMessage(messages.childIssueLabelIssueTermRefresh);
			}

			if (fg('quick_actions_m2_experiment_gate')) {
				return formatMessage(messages.createChildIssueDropdownLabel);
			}

			return formatMessage(messages.childIssueLabel);
		}

		if (expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)) {
			return formatMessage(messages.createChildLabelIssueTermRefresh);
		}
		return formatMessage(messages.createChildLabel);
	})();

	const linkIssueLabel = (() => {
		if (isQuickActionsGAWithListView) {
			if (expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)) {
				if (fg('quick_actions_m2_experiment_gate')) {
					return formatMessage(messages.linkIssueDropdownLabelIssueTermRefresh);
				}
				return formatMessage(messages.addLinkedIssueLabelIssueTermRefresh);
			}
			if (fg('quick_actions_m2_experiment_gate')) {
				return formatMessage(messages.linkIssueDropdownLabel);
			}
			return formatMessage(messages.addLinkedIssueLabel);
		}

		if (expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)) {
			return formatMessage(messages.addIssueLinkLabelIssueTermRefresh);
		}
		return formatMessage(messages.addIssueLinkLabel);
	})();

	const onAddAttachmentClickWithAnalytics = () => {
		onAddAttachmentClick();

		if (createAnalyticsEvent) {
			getUpdateAnalyticsFlowHelper().fireAnalyticsStart('attachment', {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: 'attachment',
					actionType: 'add',
				},
			});
		}
	};

	const attachmentAction = {
		id: ADD_ATTACHMENT_ID,
		tooltip: formatMessage(messages.addAttachmentTooltip),
		label: (() => {
			if (isQuickActionsGAWithListView) {
				if (fg('quick_actions_m2_experiment_gate')) {
					return formatMessage(messages.addAttachmentDropdownLabel);
				}
				return formatMessage(messages.attachmentLabel);
			}
			return formatMessage(messages.addAttachmentLabel);
		})(),
		icon: <AttachmentIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onAddAttachmentClickWithAnalytics,
		itemIndex: 0,
		shouldRender: !isServiceDesk && canCreateAttachment,
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.add-attachment',
	};

	const subtaskAction = {
		id: CREATE_SUBTASK_ID,
		tooltip: formatMessage(messages.createSubtaskTooltip),
		label:
			isQuickActionsGAWithListView && !fg('quick_actions_m2_experiment_gate')
				? formatMessage(messages.subtaskLabel)
				: formatMessage(messages.createSubtaskLabel),
		icon: <SubtasksIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onCreateSubtaskClick,
		itemIndex: 1,
		shouldRender: shouldShowCreateSubtaskButton,
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.create-subtask',
	};

	const issueInEpicAction = {
		id: CREATE_ISSUE_IN_EPIC_ID,
		tooltip: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.createChildTooltipIssueTermRefresh
				: messages.createChildTooltip,
		),
		label: createChildLabel,
		icon: <ChildIssuesIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onCreateIssueInEpicClick,
		itemIndex: 1,
		shouldRender: shouldShowIssueInEpicButton,
		testId:
			'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.create-issue-in-epic',
	};

	const createChildAction = {
		id: CREATE_CHILD_ID,
		tooltip: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.createChildTooltipIssueTermRefresh
				: messages.createChildTooltip,
		),
		label: createChildLabel,
		icon: <ChildIssuesIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onChildCreateClick,
		itemIndex: 1,
		shouldRender: supportsChildCreation,
		testId:
			'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.create-child-issue',
	};

	const investigateIncidentAction = {
		id: INVESTIGATE_INCIDENT_ID,
		tooltip: formatMessage(messages.investigateIncidentTooltip),
		label: formatMessage(messages.investigateIncidentLabel),
		icon: (
			<>
				<FireUiAnalytics
					eventName="InvestigateIncidentButton viewed"
					componentName="BentoQuickAddItems"
				/>
				<SearchIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />
			</>
		),
		onClick: onInvestigateIncidentClick,
		itemIndex: 4,
		shouldRender:
			isServiceDesk &&
			Array.isArray(practices) &&
			practices.includes(INCIDENTS) &&
			!!hasPremiumServiceDeskAccess,
		testId:
			'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.investigate-incident',
		hasGlobalSpotlightTarget: true,
	};

	const addIssueLinksAction = {
		id: ADD_ISSUE_LINKS_ID,
		tooltip: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.addIssueLinkTooltipIssueTermRefresh
				: messages.addIssueLinkTooltip,
		),
		label: linkIssueLabel,
		icon: <IssuesIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onAddIssueLinkClick,
		itemIndex: 2,
		shouldRender: canCreateIssueLink,
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-issue',
	};

	const linkConfluenceAction = {
		id: LINK_CONF_PAGE_ID,
		tooltip: formatMessage(messages.linkConfluenceContentTooltip),
		label: formatMessage(messages.linkConfluenceContentLabel),
		icon: <ConfluenceIcon appearance="brand" size="small" label="" />,
		onClick: onLinkConfluencePageClick,
		itemIndex: 3,
		shouldRender: canLinkConfluencePage && !isQuickActionsGAWithListView,
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-page',
	};

	const linkConfluenceListViewAction = {
		id: LINK_CONF_PAGE_ID,
		tooltip: formatMessage(messages.linkConfluencePageTooltip),
		label: formatMessage(messages.existingConfluencePageLabel),
		icon:
			isQuickActionsGAWithListView && fg('quick_actions_m2_experiment_gate') ? (
				<Box xcss={iconPaddingStyles}>
					<PageIcon label="" />
				</Box>
			) : (
				<ConfluenceIcon appearance="brand" size="small" label="" />
			),
		onClick: onLinkConfluencePageClick,
		itemIndex: 3,
		shouldRender:
			canLinkConfluencePage &&
			isQuickActionsGAWithListView &&
			!fg('quick_actions_m2_experiment_gate'),
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-page',
	};

	const linkConfluencePageListViewAction = {
		id: LINK_CONF_PAGE_NESTED_MENU_ID,
		tooltip: formatMessage(messages.linkConfluencePageTooltip),
		label: formatMessage(messages.linkConfluencePageNestedLabel),
		onClick: onLinkConfluencePageClick,
		itemIndex: 3,
		shouldRender: canLinkConfluencePage && isQuickActionsGAWithListView,
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-page',
	};

	const linkConfluenceWhiteboardListViewAction = {
		id: LINK_CONF_WHITEBOARD_NESTED_MENU_ID,
		tooltip: formatMessage(messages.linkConfluencePageTooltip),
		label: formatMessage(messages.linkConfluenceWhiteboardNestedLabel),
		onClick: onLinkConfluencePageClick,
		itemIndex: 3,
		shouldRender: canLinkConfluencePage && isQuickActionsGAWithListView,
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-page',
	};

	const linkIdeaAction = {
		id: LINK_IDEA_ID,
		tooltip: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.linkIdeaTooltipIssueTermRefresh
				: messages.linkIdeaTooltip,
		),
		label: formatMessage(messages.linkIdeaLabel),
		icon: <LightbulbIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onLinkIdeaClick,
		itemIndex: 3,
		shouldRender:
			canCreateIssueLink &&
			!isQuickActionsGAWithListView &&
			fg('jpd_in_jsw_add_idea_action_killswitch'),
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-idea',
	};

	const linkIdeaActionListView = {
		id: LINK_IDEA_ID,
		tooltip: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.linkIdeaTooltipIssueTermRefresh
				: messages.linkIdeaTooltip,
		),
		label: formatMessage(messages.linkIdeaLabel),
		icon: <LightbulbIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onLinkIdeaClick,
		itemIndex: 3,
		shouldRender:
			canCreateIssueLink &&
			!!isQuickActionsGAWithListView &&
			fg('jpd_in_jsw_add_idea_action_killswitch'),
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-idea',
	};

	const linkAlertsAction = {
		id: LINK_ALERTS_ID,
		tooltip: formatMessage(messages.linkAlertsTooltip),
		label: formatMessage(messages.linkAlertsLabel),
		icon:
			isQuickActionsGAWithListView && fg('quick_actions_m2_experiment_gate') ? (
				<Box xcss={iconPaddingStyles}>
					<AlertIcon label="" />
				</Box>
			) : (
				// @ts-expect-error - TS2322 - Type '(props: { [key: string]: string; }) => JSX.Element' is not assignable to type 'ComponentType<CustomGlyphProps> | undefined'.
				<AkIcon glyph={AlertsGlyph} size="medium" label="" />
			),
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onClick: onLinkAlertsClick || (() => {}),
		itemIndex: 3,
		shouldRender: shouldLinkAlertsItemRender(incidentManagement),
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-alerts',
	};

	const addWebLinkAction = {
		id: ADD_WEB_LINK_ID,
		tooltip: formatMessage(messages.addWebLinkTooltip),
		label:
			isQuickActionsGAWithListView && !fg('quick_actions_m2_experiment_gate')
				? formatMessage(messages.webLinkLabel)
				: formatMessage(messages.addWebLinkLabel),
		icon: <GlobeIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />,
		onClick: onAddWebLinkClick,
		itemIndex: 3,
		shouldRender: canAddWebLinks,
		testId: 'issue.issue-view.views.issue-base.foundation.quick-add.quick-add-item.link-web',
	};

	return {
		attachment: attachmentAction,
		subtask: subtaskAction,
		issueInEpic: issueInEpicAction,
		createChild: createChildAction,
		investigateIncident: investigateIncidentAction,
		addIssueLinks: addIssueLinksAction,
		linkConfluence: linkConfluenceAction,
		linkConfluenceListView: linkConfluenceListViewAction,
		linkConfluencePage: linkConfluencePageListViewAction,
		linkConfluenceWhiteboard: linkConfluenceWhiteboardListViewAction,
		linkIdea: linkIdeaAction,
		linkIdeaListView: linkIdeaActionListView,
		linkAlerts: linkAlertsAction,
		addWebLink: addWebLinkAction,
	};
};

export default getQuickActionItems;

const iconPaddingStyles = xcss({
	paddingInline: 'space.050',
});
