import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const initialState: State = {};

const store = createStore<State, Actions>({
	name: 'jsw-edition-awareness-storage-limit-data',
	initialState,
	actions,
});

/**
 * In memory cache for storage limits data
 */
export const useJswEditionAwarenessStorageLimitData = createHook(store);
