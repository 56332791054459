import React from 'react';

import { Stack, Box, xcss } from '@atlaskit/primitives';

const RecentRuleRunsSkeleton: React.FC = () => (
	<Stack
		space="space.150"
		testId="automation-issue-audit-log.ui.recent-rule-runs.recent-rule-runs-skeleton"
		xcss={skeletonContainerStyle}
	>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingLongStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingShortStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingLongStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingShortStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
	</Stack>
);

const iconLoadingStyle = xcss({
	display: 'inline-block',
	width: '16px',
	height: '16px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

const ruleNameLoadingShortStyle = xcss({
	marginLeft: 'space.050',
	display: 'inline-block',
	height: '16px',
	width: '140px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

const ruleNameLoadingLongStyle = xcss({
	marginLeft: 'space.050',
	display: 'inline-block',
	height: '16px',
	width: '200px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

const dateLoadingStyle = xcss({
	marginLeft: 'space.250',
	paddingTop: 'space.025',
	marginTop: 'space.025',
	height: '14px',
	width: '80px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

const skeletonContainerStyle = xcss({
	marginTop: 'space.075',
});

export default RecentRuleRunsSkeleton;
