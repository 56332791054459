import React, { useEffect, useState, useCallback, memo, type ChangeEvent } from 'react';
import { css, styled } from '@compiled/react';
import get from 'lodash/get';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import commonMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import {
	InlineEditContainer,
	ReadViewContainer,
	READ_VIEW_CONTAINER_SELECTOR,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey, toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { CLASSIC_STORY_POINT_TEST_ID } from '../common/constants.tsx';
import type { ClassicStoryPointValue } from '../common/types.tsx';
import messages from '../messages.tsx';
import { useClassicStoryPointField } from '../services/index.tsx';
import { ClassicStoryPointEdit } from './edit/index.tsx';
import type { Props } from './types.tsx';
import { ClassicStoryPointView } from './view/index.tsx';

const nonEditableInlineEditContainerStyles = css({
	marginLeft: 0,
	/* NonEditableMargin overrides */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginTop: token('space.100', '8px'),
		marginRight: 0,
		marginBottom: token('space.025', '2px'),
		marginLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[READ_VIEW_CONTAINER_SELECTOR]: {
		left: 0,
		lineHeight: 1,
	},
});

export const actionSubject = 'numberInlineEdit';

export default memo<Props>((props: Props) => {
	const {
		fieldKey,
		issueKey,
		issueId,
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		onUpdate,
		readView,
		editView,
		noValueText,
		label,
		...rest
	} = props;
	const [{ value, error, fieldConfig }, { saveValue, resetError }] = useClassicStoryPointField({
		issueKey: toIssueKey(issueKey),
		issueId: issueId && toIssueId(issueId),
		fieldKey,
		onSuccess: onUpdate,
	});
	const fieldType = get(fieldConfig, 'schema.type', 'number');
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [updatedValue, setUpdatedValue] = useState<ClassicStoryPointValue>(value);
	const isFieldEditable = fieldConfig ? fieldConfig.isEditable : false;
	const [isInvalidInput, setIsInvalidInput] = useState(false);

	const { formatMessage } = useIntl();

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
		if (!isEditing) {
			setUpdatedValue(value);
		}
	}, [error, isEditing, value]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (updatedValue !== value) {
				if (updatedValue !== null && updatedValue < 0) {
					setIsInvalidInput(true);
					return;
				}
				saveValue(updatedValue, null, analyticsEvent);
			}
			setIsEditing(false);
			setIsInvalidInput(false);
			fireUIAnalytics(analyticsEvent);
		},
		[saveValue, updatedValue, value],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsEditing(false);
			setUpdatedValue(value);
			fireUIAnalytics(analyticsEvent);
			setIsInvalidInput(false);
		},
		[resetError, value],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onConfirm && onConfirm(analyticsEvent);
		},
		[onConfirm, save],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			resetError();
			const typedValue = event.target.value === '' ? null : Number(event.target.value);
			setUpdatedValue(typedValue);
		},
		[resetError, setUpdatedValue],
	);

	const renderEditView = () =>
		editView !== undefined ? (
			editView
		) : (
			<ClassicStoryPointEdit
				defaultValue={updatedValue}
				onChange={onChange}
				type={fieldType}
				isInvalid={isInvalidInput}
			/>
		);

	const renderReadView = () =>
		readView !== undefined ? (
			readView
		) : (
			<ReadViewContainer>
				<ClassicStoryPointView
					isEditable={isFieldEditable}
					value={value}
					noValueText={noValueText}
				/>
			</ReadViewContainer>
		);

	const renderErrorFlag = () => {
		if (!error) return null;

		const description =
			error.message === messages.negativeNumbersNotAllowed.id
				? messages.negativeNumbersNotAllowed
				: messages.errorMessage;

		return <ErrorFlag error={error} title={messages.errorTitle} description={description} />;
	};

	const getEditButtonLabel = () =>
		formatMessage(commonMessages.extendedEditButtonLabel, {
			inputFieldValue: value !== null ? value : noValueText,
			fieldName: label,
		});

	return (
		<>
			{error && renderErrorFlag()}
			<StoryPointInlineEditContainer isEditable={isFieldEditable}>
				<FieldInlineEditStateLess
					testId={CLASSIC_STORY_POINT_TEST_ID}
					fieldId={fg('one_event_rules_them_all_fg') ? fieldKey : undefined}
					editButtonLabel={getEditButtonLabel()}
					actionSubject={actionSubject}
					isEditable={isFieldEditable}
					isEditing={isEditing}
					readView={renderReadView()}
					editView={isFieldEditable ? renderEditView() : null}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEdit={onEditRequested}
					onEscape={onEscapeRequest}
					{...rest}
				/>
			</StoryPointInlineEditContainer>
		</>
	);
});

// @ts-expect-error - TS2339 - Property 'isEditable' does not exist on type '{}'.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const StoryPointInlineEditContainer = styled(InlineEditContainer)(({ isEditable }) =>
	!isEditable ? nonEditableInlineEditContainerStyles : undefined,
);
