import type { State } from './types.tsx';

export const initialState: State = {
	meta: {
		loading: true,
		error: undefined,
		loadingProps: undefined,
	},
	viewSets: [],
	containerProps: undefined,
	hasUnsavedChanges: false,
	forceReloadOnNextUpdate: false,
	hasManageCurrentViewPermission: false,
};
