/**
 * @generated SignedSource<<086b01c8a1a7594da358bbd81e75c96c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner$data = {
  readonly selectedOrganization: {
    readonly organizationId: string | null | undefined;
    readonly organizationName: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"organizationField_servicedeskCustomerServiceCustomFields">;
  readonly " $fragmentType": "issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner";
};
export type issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner$key = {
  readonly " $data"?: issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "concreteType": "JiraServiceManagementOrganization",
      "kind": "LinkedField",
      "name": "selectedOrganization",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "organizationId"
        },
        {
          "kind": "ScalarField",
          "name": "organizationName"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "organizationField_servicedeskCustomerServiceCustomFields"
    }
  ],
  "type": "JiraCustomerServiceOrganizationField"
};

(node as any).hash = "58cc11817cebcc302a7aaaeb023a75a5";

export default node;
