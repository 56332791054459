// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	includeChildIssueLabelSubtasks: {
		id: 'issue.log-time-modal.include-child-issues-label-subtasks',
		defaultMessage: 'Include subtasks',
		description:
			'Label for control where the user toggles whether they want time tracking data to include information from child subtasks or not.',
	},
	includeChildIssueLabelChildIssues: {
		id: 'issue.log-time-modal.include-child-issues-label-child-issues',
		defaultMessage: 'Include child issues',
		description:
			'Label for control where the user toggles whether they want time tracking data to include information from child issues or not.',
	},
	includeChildIssueLabelChildIssuesIssueTermRefresh: {
		id: 'issue.log-time-modal.include-child-issues-label-child-issues-issue-term-refresh',
		defaultMessage: 'Include child work items',
		description:
			'Label for control where the user toggles whether they want time tracking data to include information from child issues or not.',
	},
	timetrackingRollupDisabledTooltipLabelWithLimitSubtasks: {
		id: 'issue.log-time-modal.time-tracking-disabled-tooltip.label-with-limit-subtasks',
		defaultMessage: 'Including subtasks is not available with more than {limit} subtasks',
		description:
			'An accessibility label for the disabled tooltip on roll up time tracking checkbox when over the child issue limit',
	},
	timetrackingRollupDisabledTooltipLabelWithLimitChildIssues: {
		id: 'issue.log-time-modal.time-tracking-disabled-tooltip.label-with-limit-child-issues',
		defaultMessage: 'Including child issues is not available with more than {limit} child issues',
		description:
			'An accessibility label for the disabled tooltip on roll up time tracking checkbox when over the child issue limit',
	},
	timetrackingRollupDisabledTooltipLabelWithLimitChildIssuesIssueTermRefresh: {
		id: 'issue.log-time-modal.time-tracking-disabled-tooltip.label-with-limit-issue-term-refresh',
		defaultMessage:
			'Including child work items is not available with more than {limit} child work items',
		description:
			'An accessibility label for the disabled tooltip on roll up time tracking checkbox when over the child issue limit',
	},
});
