import React, { useCallback, forwardRef, type Ref, type KeyboardEvent } from 'react';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import type { EntryPointProps } from 'react-relay';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import availableTimes from '@atlassian/jira-common-constants/src/datetimepicker-available-times.tsx';
import { convertISODateFormat } from '@atlassian/jira-compatibility/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { standardizeLocale } from '@atlassian/jira-issue-format-date/src/common/utils.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { isDataExtra } from '@atlassian/jira-platform-services-user-current/src/types.tsx';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { DateTimeEditViewProps, ParseValueFn } from './types.tsx';
import { parseDateTimeValue as onParseValue } from './utils.tsx';

export const DEFAULT_WEEK_START_DAY = 0; // Sunday

/**
 * DateTimeEditView lets you edit the datetime of the field
 *
 *
 * @param props {@link DateTimeEditView}
 * */
export const DateTimeEditView = forwardRef(
	(
		{
			autoFocus,
			isDisabled,
			isInvalid,
			timeZone,
			value,
			onChange,
			datePlaceholder,
			timePlaceholder,
			onDatePickerKeyDown,
			defaultIsOpen,
			spacing,
			menuPosition,
			menuPortalTarget,
			onDateChange,
			onTimeChange,
		}: DateTimeEditViewProps,
		ref: Ref<HTMLDivElement>,
	) => {
		const locale = useLocale();
		const {
			data: { user },
		} = useCurrentUser();
		// eslint-disable-next-line no-nested-ternary
		const currentUserTimeZone = fg('relay-migration-issue-fields-date-time-fg')
			? 'timeZone' in user
				? user.timeZone || null
				: null
			: isDataExtra(user)
				? user?.timeZone || null
				: null;
		const timeZoneToUse: TimeZone | null = timeZone !== undefined ? timeZone : currentUserTimeZone;
		const handleOnChange = useCallback(
			(isoDateTimeValue?: string | null) => {
				const convertedIso = convertISODateFormat(isoDateTimeValue ?? '');
				if (isNaN(Date.parse(convertedIso))) {
					onChange?.(null);
				} else {
					onChange?.(convertedIso);
				}
			},
			[onChange],
		);

		const parseValue: ParseValueFn = useCallback(
			(dateTimeValue, fallbackDate, fallbackTime, fallbackTimeZone) =>
				onParseValue(dateTimeValue, fallbackDate, fallbackTime, timeZoneToUse ?? fallbackTimeZone),
			[timeZoneToUse],
		);

		const baseSelectProps = fg('jsc_inline_editing_field_refactor')
			? {
					blurInputOnSelect: false,
					menuPosition,
					menuPortalTarget,
				}
			: {};

		const handleKeyDown = useCallback((e: KeyboardEvent<HTMLElement>) => {
			switch (e.code) {
				case 'ArrowLeft':
				case 'ArrowRight':
				case 'ArrowUp':
				case 'ArrowDown':
					// Stop propagation of arrow keys used for keyboard navigation in the date picker control so it
					// doesn't clash with consumer shortcut keys, i.e. issue view.
					if (e.defaultPrevented) {
						e.stopPropagation();
					}
					break;
				default:
					break;
			}
		}, []);

		const standardizedLocale = standardizeLocale(locale);
		const content = (
			<DateTimePicker
				testId="issue-field-date-time-editview-full.ui.date-time.date-time-picker"
				locale={standardizedLocale}
				autoFocus={autoFocus}
				isDisabled={isDisabled}
				isInvalid={isInvalid}
				value={value || ''}
				onChange={handleOnChange}
				parseValue={timeZoneToUse != null ? parseValue : undefined}
				spacing={spacing}
				timePickerProps={{
					placeholder: timePlaceholder,
					times: availableTimes,
					timeIsEditable: true,
					onChange: onTimeChange,
					selectProps: {
						...baseSelectProps,
						formatCreateLabel: (val: string) => val,

						// Want to show the dropdown option if the user input
						// matches the value (22:30) or the label (10:30pm)
						filterOption: (
							option: {
								label: string;
								value: unknown;
							},
							val: string,
						) => !val || option.value === val || option.label === val,
					},
				}}
				// Use of non-standardized locale is intentional, getLocale map accounts for this.

				datePickerProps={{
					placeholder: datePlaceholder || '',
					defaultIsOpen: expValEquals(
						'jira_issue_view_date_time_picker_experiment_new',
						'isEnabled',
						true,
					)
						? defaultIsOpen
						: false,
					weekStartDay: get(getLocale(locale), 'options.weekStartsOn', DEFAULT_WEEK_START_DAY),
					selectProps: {
						...baseSelectProps,
						...(onDatePickerKeyDown
							? {
									onKeyDown: onDatePickerKeyDown,
								}
							: {}),
					},
					onChange: onDateChange,
				}}
			/>
		);

		return fg('jsc_inline_editing_field_refactor') ||
			expValEquals('jira_issue_view_date_time_picker_experiment_new', 'isEnabled', true) ? (
			<div
				role="presentation"
				ref={ref}
				onKeyDown={
					expValEquals('jira_issue_view_date_time_picker_experiment_new', 'isEnabled', true)
						? handleKeyDown
						: undefined
				}
			>
				{content}
			</div>
		) : (
			content
		);
	},
);

const DateTimeEditViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, DateTimeEditViewProps, {}>) => <DateTimeEditView {...props} />;

export default DateTimeEditViewEntryPoint;
