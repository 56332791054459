import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { attachmentPickerPopupOpenSuccess } from '@atlassian/jira-issue-view-store/src/actions/attachment-popup-actions.tsx';
import { isServiceDeskSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { canCreateAttachmentsSelector } from '@atlassian/jira-issue-view-store/src/selectors/attachment-selector.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from '../messages.tsx';
import type { Props, StateProps } from './types.tsx';

export const AddAttachments = ({ canCreateAttachment, onClick }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const handleClick = useCallback(() => {
		onClick();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireUIAnalytics(analyticsEvent, 'addAttachment');

		getUpdateAnalyticsFlowHelper().fireAnalyticsStart('attachment', {
			analytics: createAnalyticsEvent({}),
			attributes: {
				fieldType: 'attachment',
				actionType: 'add',
			},
		});
	}, [createAnalyticsEvent, onClick]);

	if (!canCreateAttachment) return null;

	return (
		<ButtonWrapper
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.content.attachment.heading.add-attachment.button-wrapper"
		>
			<SquareAddIconButton
				label={formatMessage(messages.addAttachmentAriaLabel)}
				onClick={handleClick}
			/>
		</ButtonWrapper>
	);
};

export default connect(
	(state: State): StateProps => ({
		canCreateAttachment: !isServiceDeskSelector(state) && canCreateAttachmentsSelector(state),
	}),
	{
		onClick: attachmentPickerPopupOpenSuccess,
	},
)(AddAttachments);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ButtonWrapper = styled.div({
	marginLeft: token('space.075', '6px'),
});
