import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { CommonFlagAction } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-19531
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	successFlagAddToSprintAction,
	successFlagClearFiltersAction,
} from '@atlassian/jira-issue-create-analytics/src/services/performance-analytics/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	CreatedIssues,
	CreatedIssueWithIssueData,
	ActiveSprint,
} from '../../../../common/types/index.tsx';
import { useAddToSprintFlagActionStateController } from '../../../../controllers/add-to-sprint-flag-action-context/index.tsx';
import {
	SUCCESS_FLAG_ACTION_VIEW_ISSUE,
	SUCCESS_FLAG_ACTION_COPY_LINK,
	SUCCESS_FLAG_ACTION_ADD_TO_SPRINT,
	SUCCESS_FLAG_ACTION_CLEAR_FILTERS,
} from './constants.tsx';
import messages from './messages.tsx';
import type {
	CommonGetFlagActionsProps,
	GetNewScrumIssuesWithNoSprintsActionsProps,
	GetHiddenIssuesActionsProps,
} from './types.tsx';

export const ViewIssueCta = (
	issueList: CreatedIssues,
	issueDiscoveryUrl: string,
): CommonFlagAction => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireViewIssueAnalyticsEvent = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'link',
		});
		fireUIAnalytics(analyticsEvent, SUCCESS_FLAG_ACTION_VIEW_ISSUE, { isNewGIC: true });
	}, [createAnalyticsEvent]);
	return {
		content: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.viewIssueCtaIssueTermRefresh
				: messages.viewIssueCta,
			{ issueCount: issueList.length },
		),
		href: issueDiscoveryUrl,
		onClick: fireViewIssueAnalyticsEvent,
	};
};

const CopyLinkCta = (issueDiscoveryUrl: string): CommonFlagAction => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isCopied, setCopied] = useState(false);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (isCopied) {
			timeoutId = setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
		return () => {
			timeoutId && clearTimeout(timeoutId);
		};
	}, [isCopied]);

	const copyToClipboard = useCallback(async (): Promise<void> => {
		try {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			});

			if (fg('jfp-magma-undefined-navigator')) {
				await globalThis.navigator.clipboard.writeText(
					`${globalThis.location.origin}${issueDiscoveryUrl}`,
				);
			} else {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				await navigator.clipboard.writeText(`${window.location.origin}${issueDiscoveryUrl}`);
			}

			setCopied(true);
			fireUIAnalytics(analyticsEvent, SUCCESS_FLAG_ACTION_COPY_LINK);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'copyToClipboard',
					packageName: 'jiraIssueCreate',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [createAnalyticsEvent, issueDiscoveryUrl]);

	return {
		content: isCopied ? (
			<ActionSuccess>{formatMessage(messages.linkCopied)}</ActionSuccess>
		) : (
			formatMessage(messages.copyLinkCta)
		),
		onClick: copyToClipboard,
	};
};

const ClearFiltersCta = (): CommonFlagAction => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isLoading, setIsLoading] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);

	const clearFiltersAction = useCallback(() => {
		successFlagClearFiltersAction.start();

		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'link',
		});
		const { GH } = window;

		setIsLoading(true);
		fireUIAnalytics(analyticsEvent, SUCCESS_FLAG_ACTION_CLEAR_FILTERS, { isNewGIC: true });
		GH.WorkController.clearFilters()
			.done(() => {
				successFlagClearFiltersAction.stop();
				setIsCompleted(true);
				setIsLoading(false);
			})
			.fail(() => {
				successFlagClearFiltersAction.stop();
				fireErrorAnalytics({
					meta: {
						id: 'clearFilters',
						packageName: 'jiraIssueCreate',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			});
	}, [createAnalyticsEvent]);

	if (isLoading) {
		return {
			content: formatMessage(messages.clearFiltersCtaLoading),
		};
	}
	if (isCompleted) {
		return {
			content: <ActionSuccess>{formatMessage(messages.clearFiltersCtaCompleted)}</ActionSuccess>,
		};
	}

	return {
		content: formatMessage(messages.clearFiltersCta),
		onClick: clearFiltersAction,
	};
};

export const AddToSprintCta = (
	issuesWithNoSprint: CreatedIssueWithIssueData[],
	totalCreatedIssuesCount: number,
	sprint: ActiveSprint,
): CommonFlagAction[] => {
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ addToSprintCTAsEnabled }, { disableOtherAddToSprintCTAs }] =
		useAddToSprintFlagActionStateController();

	const addToSprintAction = useCallback(() => {
		successFlagAddToSprintAction.start();

		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'link',
		});
		const { GH } = window;

		disableOtherAddToSprintCTAs();
		setIsLoading(true);
		fireUIAnalytics(analyticsEvent, SUCCESS_FLAG_ACTION_ADD_TO_SPRINT, { isNewGIC: true });
		GH.WorkController.addNewIssuesToSprintFromJFE(sprint, issuesWithNoSprint)
			.done(() => {
				successFlagAddToSprintAction.stop();
				setIsCompleted(true);
				setIsLoading(false);
			})
			.fail(() => {
				successFlagAddToSprintAction.stop();
				fireErrorAnalytics({
					meta: {
						id: 'addToSprint',
						packageName: 'jiraIssueCreate',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			});
	}, [createAnalyticsEvent, sprint, issuesWithNoSprint, disableOtherAddToSprintCTAs]);

	if (isLoading) {
		return [
			{
				content: formatMessage(messages.addIssuesToSprintCtaLoading),
			},
		];
	}
	if (isCompleted) {
		return [
			{
				content: (
					<ActionSuccess>{formatMessage(messages.addIssuesToSprintCtaCompleted)}</ActionSuccess>
				),
			},
		];
	}
	if (addToSprintCTAsEnabled) {
		return [
			{
				content: formatMessage(messages.addAllIssuesToSprintCta, {
					sprintName: sprint.name,
				}),
				onClick: addToSprintAction,
			},
		];
	}
	return [];
};

const getAddToSprintsCtas = (
	issuesWithNoSprint: CreatedIssueWithIssueData[],
	issueList: CreatedIssues,
	sprints: ActiveSprint[],
): CommonFlagAction[] => {
	const editableSprints = sprints.filter((sprint) => sprint.canUpdateSprint);
	const totalCreatedIssuesCount = issueList.length;
	let ctaList: Array<CommonFlagAction> = [];
	editableSprints.forEach((sprint) => {
		ctaList = ctaList.concat(AddToSprintCta(issuesWithNoSprint, totalCreatedIssuesCount, sprint));
	});
	return ctaList;
};

export const getDefaultFlagActions = ({
	issueList,
	issueDiscoveryUrl,
}: CommonGetFlagActionsProps): CommonFlagAction[] => [
	ViewIssueCta(issueList, issueDiscoveryUrl),
	CopyLinkCta(issueDiscoveryUrl),
];

export const getNewScrumIssuesWithNoSprintsActions = ({
	issueList,
	issueDiscoveryUrl,
	issuesWithNoSprint,
	sprints,
}: GetNewScrumIssuesWithNoSprintsActionsProps): CommonFlagAction[] => {
	const ctaList = getAddToSprintsCtas(issuesWithNoSprint, issueList, sprints);
	ctaList.push(ViewIssueCta(issueList, issueDiscoveryUrl));
	return ctaList;
};

export const getHiddenIssuesActions = ({
	issueList,
	issueDiscoveryUrl,
	isFiltered,
}: GetHiddenIssuesActionsProps): CommonFlagAction[] => {
	const ctaList = [ViewIssueCta(issueList, issueDiscoveryUrl)];
	if (isFiltered) ctaList.push(ClearFiltersCta());
	return ctaList;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionSuccess = styled.span({
	color: token('color.text.success', G300),
});
