/**
 * @generated SignedSource<<5408110fb12ab17b0c2f1f0839959ae4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTeamField_IssueViewTeamField$data = {
  readonly __typename: "JiraTeamViewField";
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
    readonly isRequired: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly id: string;
    readonly key: string;
  } | null | undefined;
  readonly name: string;
  readonly selectedTeam: {
    readonly jiraIncludesYou: boolean | null | undefined;
    readonly jiraMemberCount: number | null | undefined;
    readonly jiraSuppliedId: string;
    readonly jiraSuppliedIsVerified: boolean | null | undefined;
    readonly jiraSuppliedName: string | null | undefined;
    readonly jiraSuppliedTeamId: string;
    readonly jiraSuppliedVisibility: boolean | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"services_issueViewLayoutTeamField_IssueViewTeamField">;
  readonly " $fragmentType": "ui_issueViewLayoutTeamField_IssueViewTeamField";
};
export type ui_issueViewLayoutTeamField_IssueViewTeamField$key = {
  readonly " $data"?: ui_issueViewLayoutTeamField_IssueViewTeamField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTeamField_IssueViewTeamField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTeamField_IssueViewTeamField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        },
        {
          "kind": "ScalarField",
          "name": "isRequired"
        }
      ]
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "concreteType": "JiraTeamView",
      "kind": "LinkedField",
      "name": "selectedTeam",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedId"
        },
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedName"
        },
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedVisibility"
        },
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedTeamId"
        },
        {
          "kind": "ScalarField",
          "name": "jiraIncludesYou"
        },
        {
          "kind": "ScalarField",
          "name": "jiraMemberCount"
        },
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedIsVerified"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "services_issueViewLayoutTeamField_IssueViewTeamField"
    }
  ],
  "type": "JiraTeamViewField"
};
})();

(node as any).hash = "ee3fdc8d3cb7525e6824769063e0597b";

export default node;
