import React, { useEffect } from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

/**
 * This function is used on the Hook that check the requisites for the Feature Gates:  TMP Access page and Issue Restrictions
 *
 * - `useProjectContext` returns the correct project data except when you are on the search page (https://<jira.url>/issues)
 * - To get the project type for the issue, we are using `useProjectType` as backup
 *
 * Why we can't just rely on `useProjectType` hook?
 *  - Because the TMP Access page is not associated to an issue, and it will return null
 *  - `useIssueKey` keeps the last viewed issue, if I view an issue from another project (service_desk) and navigate to the access page
 *  it will return the incorrect value, because of this `useProjectContext` has precedence on the check
 */
export function useIsJiraSoftwareFree(): boolean {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data: projectContext } = useProjectContext();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const appEditions = useAppEditions();

	useEffect(() => {
		if (
			projectContext?.projectType !== undefined &&
			projectType !== undefined &&
			projectContext.projectType !== projectType
		) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'useIsJiraSoftwareFree mismatch', {
				useProjectType: projectType,
				useProjectContext: projectContext.projectType,
			});
		}
	}, [createAnalyticsEvent, projectContext?.projectType, projectType]);

	const project = projectContext?.projectType || projectType;
	return project === SOFTWARE_PROJECT && appEditions?.software === FREE_EDITION;
}

type Props = {
	renderJswFree: () => React.ReactNode;
	renderElse: () => React.ReactNode;
	caller: string;
};

/**
 * This component performs prerequisite checks for the JSW Free Feature Gates.
 * If the prerequisites are met, it will render the component provided through the `renderElse` prop.
 * Otherwise, it will render the original component provided through the `renderJswFree` prop.
 */
function PrerequisitesCheck({ renderJswFree, renderElse }: Omit<Props, 'caller'>) {
	const isJiraSoftwareFree = useIsJiraSoftwareFree();
	return <>{isJiraSoftwareFree ? renderJswFree() : renderElse()}</>;
}

export function JswFreeConditionalRenderer({ caller, renderJswFree, renderElse }: Props) {
	return (
		// If the non-JSW Free component fails, it will be rendered again in
		// the ErrorBoundary fall back. This would cause it to render again, and
		// fail and bubble up to the previous ErrorBoundary as before
		<JSErrorBoundary
			id="jswFreeConditionalRenderer"
			packageName={caller}
			teamName="growth-tako"
			fallback={renderElse}
		>
			{/* If any errors happen here, it will be caught by the error boundary */}
			<PrerequisitesCheck renderJswFree={renderJswFree} renderElse={renderElse} />
		</JSErrorBoundary>
	);
}
