import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	jiraIosDownloadCta: {
		id: 'issue-view-activity.section-message.jira-ios-download-cta',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Download Jira iOS',
		description: 'Jira iOS download link',
	},
	jiraAndroidDownloadCta: {
		id: 'issue-view-activity.section-message.jira-android-download-cta',
		defaultMessage: 'Download Jira Android',
		description: 'Jira android download link',
	},
	body: {
		id: 'issue-view-activity.section-message.body',
		defaultMessage:
			'Get issue updates in real-time and respond from anywhere, with the Jira Mobile app.',
		description: 'Jira mobile web awareness section message body',
	},
	title: {
		id: 'issue-view-activity.section-message.title',
		defaultMessage: 'Stay in the loop',
		description: 'Jira mobile web awareness section message title',
	},
	bodyIssueTermRefresh: {
		id: 'issue-view-activity.section-message.body-issue-term-refresh',
		defaultMessage:
			'Get updates on your work items in real-time and respond from anywhere, with the Jira Mobile app.',
		description: 'Jira mobile web awareness section message body',
	},
});
