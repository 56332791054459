import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import {
	BAD_REQUEST,
	UNAUTHORIZED,
	NOT_FOUND,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics/src/services/send-experience-analytics/index.tsx';
import { secondsToWholeMinuteTimeString } from '@atlassian/jira-issue-format-time/src/common/utils/seconds-to-whole-minute-time-string/index.tsx';
import type { Worklog } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { TimeTrackingDeltas } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import timeStringToSecondsUtil from '@atlassian/jira-issue-view-common-utils/src/time-string/time-string-to-seconds/index.tsx';
import {
	type Action,
	type EditWorklogRequestAction,
	EDIT_WORKLOG_REQUEST,
	editWorklogSuccess,
	editWorklogFailure,
	fetchSurroundingWorklogsById,
} from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import { markTokenAsOutdated } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	worklogsSelector,
	timeTrackingConfigurationSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { hasMediaFileNodesUpdated } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { worklogExperienceDescription } from '../../common/experience-description/index.tsx';
import worklogHasComment, { isNotEmptyComment } from '../../common/worklog-has-comment/index.tsx';
import editWorklog from '../../services/edit-worklog/index.tsx';

export const getTimeTrackingDeltas = (
	state: State,
	editedWorklog: Worklog,
	timeRemaining: string,
): TimeTrackingDeltas => {
	const config = timeTrackingConfigurationSelector(state);
	const timeStringToSeconds = timeStringToSecondsUtil(config.daysPerWeek, config.hoursPerDay);

	const worklogs = worklogsSelector(state);
	const oldWorklog = worklogs[editedWorklog.id];

	if (!oldWorklog) {
		log.safeErrorWithoutCustomerData(
			'issue.activity.worklog.edit-worklog',
			'Could not find existing worklog with matching ID when editing a worklog and calculating time tracking deltas.',
		);
		return {
			timeSpentSecondsDelta: 0,
			newRemainingEstimateSeconds: timeStringToSeconds(timeRemaining),
		};
	}

	const oldTimeSpentSeconds = timeStringToSeconds(oldWorklog.timeSpent) || 0;
	const newTimeSpentSeconds = timeStringToSeconds(editedWorklog.timeSpent) || 0;
	const timeSpentSecondsDelta = newTimeSpentSeconds - oldTimeSpentSeconds;

	let newRemainingEstimateSeconds = timeStringToSeconds(timeRemaining);
	if (typeof newRemainingEstimateSeconds === 'number') {
		newRemainingEstimateSeconds = timeStringToSeconds(
			secondsToWholeMinuteTimeString(newRemainingEstimateSeconds),
		);
	}

	return {
		timeSpentSecondsDelta,
		newRemainingEstimateSeconds,
	};
};

// BENTO-10321
export const actuallySuccessfulStatusCodes = [BAD_REQUEST, UNAUTHORIZED, NOT_FOUND] as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(action$.ofType(EDIT_WORKLOG_REQUEST) as Observable<EditWorklogRequestAction>).switchMap(
		(action) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = toIssueKey(issueKeySelector(state));
			const projectType = projectTypeSelector(state);

			const worklogsMap = worklogsSelector(state);
			const {
				payload: { worklogId, values },
				meta: { analyticsEvent },
			} = action;

			let shouldFetchViewContext = false;
			if (isNotEmptyComment(values.workDescription)) {
				shouldFetchViewContext = hasMediaFileNodesUpdated(
					worklogsMap[worklogId].comment,
					values.workDescription,
				);
				if (shouldFetchViewContext) {
					// @ts-expect-error - Argument of type '{ type: "MARK_TOKEN_AS_OUTDATED"; }' is not assignable to parameter of type 'Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: MyPreferences | undefined; }>; ... 5 more ...; validators: Validators; }>'.
					store.dispatch(markTokenAsOutdated());
				}
			}

			return Observable.from(editWorklog(baseUrl, issueKey, worklogId, values))
				.do((editedWorklog) => {
					const withComment = worklogHasComment(editedWorklog);
					sendExperienceAnalytics({
						getExperienceDescription: () =>
							worklogExperienceDescription({
								wasSuccessful: true,
								action: 'EDIT',
								analyticsSource: 'editWorklogEpic',
								projectType,
							}),
					});
					fireTrackAnalytics(analyticsEvent, {
						action: 'edited',
						actionSubjectId: worklogId,
						attributes: { withComment },
					});
				})
				.mergeMap((editedWorklog) => {
					const updatedWorklogMap = {
						...worklogsMap,
						[worklogId]: editedWorklog,
					};
					const editWorklogSuccessAction = editWorklogSuccess(
						updatedWorklogMap,
						editedWorklog.id,
						getTimeTrackingDeltas(state, editedWorklog, values.timeRemaining),
						{
							shouldFetchViewContext,
						},
					);

					if (editedWorklog.started !== worklogsMap[worklogId].started) {
						return Observable.of(
							editWorklogSuccessAction,
							// @ts-expect-error - TS2769 - No overload matches this call.
							fetchSurroundingWorklogsById(worklogId),
						);
					}
					return Observable.of(editWorklogSuccessAction);
				})
				.catch((error) => {
					sendExperienceAnalytics({
						getExperienceDescription: ({ wasSuccessful }) => {
							if (!wasSuccessful) {
								log.safeErrorWithoutCustomerData(
									'issue.activity.worklog.edit-worklog',
									'Failed to save edited worklog:',
									error,
								);
							}

							return worklogExperienceDescription({
								wasSuccessful,
								action: 'EDIT',
								analyticsSource: 'editWorklogEpic',
								projectType,
								errorMessage: wasSuccessful ? undefined : error.message,
								...(fg('thor_add_missing_attributes_across_issue_view_2')
									? {
											statusCode: error?.statusCode,
											traceId: error?.traceId,
										}
									: {}),
							});
						},
						error,
					});

					return Observable.of(editWorklogFailure());
				});
		},
	);
