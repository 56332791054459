import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import {
	createTemplate,
	setRuleConfig,
	type CreateTemplateResult,
	type SetRuleConfigResult,
} from '../../services/set-recurring.tsx';
import { useWithAnalytics } from './use-with-analytics.tsx';

type SetAsRecurringIssueResult = CreateTemplateResult & SetRuleConfigResult;

export const useSetRecurringIssue = (issueKey: string, projectId: string) => {
	const cloudId = useCloudId();
	const environment = useEnvironment();
	const withAnalytics = useWithAnalytics();

	const setAsRecurringIssue = useCallback(async (): Promise<SetAsRecurringIssueResult> => {
		const recurrenceToken = uuid();
		const createTemplateResponse = await withAnalytics(
			createTemplate({
				environment,
				cloudId,
				projectId,
				recurrenceToken,
			}),
			'createTemplate',
		);
		const response = await withAnalytics(
			setRuleConfig({
				issueKey,
				recurrenceToken,
				ruleId: createTemplateResponse.ruleId,
			}),
			'setRuleConfig',
		);
		return { ...createTemplateResponse, ...response };
	}, [cloudId, environment, issueKey, projectId, withAnalytics]);

	return useService<SetAsRecurringIssueResult>(setAsRecurringIssue);
};
