import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import type { Routes } from '@atlassian/react-resource-router/src/common/types.tsx';
import { startJiraSpa as oldStartJiraSpa } from '../start-jira-spa.tsx';
import { startJiraSpa as altStartJiraSpa } from '../start-jira-spa-alt.tsx';

setMark('JIRA_SPA_ENTRY_LOADED');

export const bootstrapSingleSpaRoute = (getInitialRoutes: () => Routes) => {
	const loadLazyRoutes = async () =>
		new Promise<() => Routes>((resolve, reject) => {
			const cb = () => {
				import(
					/* webpackChunkName: "async-route-map" */ '@atlassian/jira-routes/src/getSpaRoutesAsync.tsx'
				)
					.then((mod) => {
						resolve(mod.default);
					})
					.catch((err) => {
						reject(err);
					});
			};

			// requestIdleCallback is not supported in Safari, so we need a workaround - won't be ideal for Safari
			// users but better than nothing.
			if (typeof window !== 'undefined' && typeof window.requestIdleCallback !== 'undefined') {
				window.requestIdleCallback(cb);
			} else {
				// arbitrary non-zero timeout to push the start of the lazy route load further down the path, but not _too_ far
				setTimeout(cb, 500);
			}
		});

	// It is easier to make the check against window than it is to make typescript happy here.
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (!__SERVER__ && typeof window !== 'undefined' && window.__SPA_BOOTSTRAP_ALT) {
		altStartJiraSpa(getInitialRoutes, loadLazyRoutes);
	} else {
		oldStartJiraSpa(getInitialRoutes, loadLazyRoutes);
	}
};
