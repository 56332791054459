import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createStore,
	createStateHook,
	createActionsHook,
	createSelector,
} from '@atlassian/react-sweet-state';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import { makeAvatarUrlFromId } from '../common/utils/make-avatar-url-from-id.tsx';
import { actions } from './actions/index.tsx';
import type { IssueType, State } from './types.tsx';

const EMPTY: IssueTypeId[] = [];

const initialState: State = {
	issueTypesById: {},
	issueTypesByProjectId: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisIssueTypesStore',
});

export const useActions = createActionsHook(Store);

export const useFetchIssueTypesForProject = () => {
	const { fetchIssueTypesForProject } = useActions();
	return fetchIssueTypesForProject;
};

const getAllIssueTypes = (state: State) => state.issueTypesById;

export const useAllIssueTypes = createStateHook(Store, {
	selector: getAllIssueTypes,
});

const getIssueTypeIdsForProject = (
	state: State,
	{ projectId }: { projectId: ProjectId | undefined },
): IssueTypeId[] => {
	if (projectId === undefined) {
		return EMPTY;
	}
	return state.issueTypesByProjectId[projectId] || EMPTY;
};

export const useIssueTypeIdsForProject = createStateHook(Store, {
	selector: getIssueTypeIdsForProject,
});

const getIssueTypesForProject = createSelector(
	getAllIssueTypes,
	getIssueTypeIdsForProject,
	(issueTypesById, issueTypeIds) =>
		issueTypeIds.map((issueTypeId) => issueTypesById[issueTypeId]).filter(Boolean),
);

export const useIssueTypesForProject = createStateHook(Store, {
	selector: getIssueTypesForProject,
});

/**
 * @deprecated Use useIssueTypeIdsForProject instead, we can no longer
 * guarantee a single issue type per project
 */
export const useIssueTypeIdForProject = ({
	projectId,
}: {
	projectId: ProjectId | undefined;
}): IssueTypeId | undefined => {
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });
	return issueTypeIds.length > 0 ? issueTypeIds[0] : undefined;
};

export const useIssueTypeName = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.name,
});

export const useIssueTypeDescription = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.description,
});

export const useIssueTypeAvatarId = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.avatarId,
});

export const useIsIssueTypeDeletable = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.operation.deletable === true,
});

export const useIsIssueTypeEditable = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.operation.editable === true,
});

const transformToFieldValue = ({ id, name, avatarId }: IssueType): IssueTypeFieldValue => ({
	id,
	name,
	iconUrl: makeAvatarUrlFromId(avatarId),
});

const getIssueTypeValuesForProject = createSelector(
	getIssueTypesForProject,
	(issueTypes): IssueTypeFieldValue[] => issueTypes.map(transformToFieldValue),
);

export const useIssueTypeValuesForProject = createStateHook(Store, {
	selector: getIssueTypeValuesForProject,
});

const getAllIssueTypeValues = createSelector(getAllIssueTypes, (allIssueTypes) => {
	const issueTypeMap = new Map<string, IssueTypeFieldValue>();
	Object.values(allIssueTypes).forEach((issueType) => {
		issueType && issueTypeMap.set(issueType.id, transformToFieldValue(issueType));
	});
	return issueTypeMap;
});

export const useAllIssueTypeValues = createStateHook(Store, {
	selector: getAllIssueTypeValues,
});

const hasProjectCustomIssueTypes = createSelector(
	getIssueTypeIdsForProject,
	(issueTypeIds) => issueTypeIds.length > 1,
);

export const useHasProjectCustomIssueTypes = createStateHook(Store, {
	selector: hasProjectCustomIssueTypes,
});
