import type {
	ClipboardComponentType,
	ClipboardComponentValue,
	ClipboardTextSerialiser,
} from './types.tsx';

/**
 * Serialiser that converts primitive value types to their string representation, and returns an empty string for object
 * types.
 */
export const getDefaultClipboardTextSerialiser = <
	Type extends ClipboardComponentType,
>(): ClipboardTextSerialiser<ClipboardComponentValue<Type>> => {
	return (value) => {
		switch (typeof value) {
			case 'string':
			case 'number':
			case 'boolean':
				return String(value);
			default:
				return '';
		}
	};
};
