/**
 * @generated SignedSource<<a0c513e6f69c684a687ba824e71d00d8>>
 * @relayHash c85ded23f5eb9f06e1f33606d6b8a08a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 789e1058aee53618320be34c8fe29ff20ad79f15993332ebc49e45daf42694df

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleLineTextFieldInput = {
  id: string;
  operation: JiraSingleLineTextFieldOperationInput;
};
export type JiraSingleLineTextFieldOperationInput = {
  operation: JiraSingleValueFieldOperations;
  text?: string | null | undefined;
};
export type singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$variables = {
  input: JiraUpdateSingleLineTextFieldInput;
};
export type singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$data = {
  readonly jira: {
    readonly updateSingleLineTextField: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateSingleLineTextField: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly __typename: "BulkMutationErrorExtension";
          readonly id: string;
          readonly statusCode: number | null | undefined;
        } | {
          readonly __typename: string;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldConfig: {
          readonly isRequired: boolean | null | undefined;
        } | null | undefined;
        readonly fieldId: string;
        readonly id: string;
        readonly isEditableInIssueView: boolean | null | undefined;
        readonly name: string;
        readonly text: string | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation = {
  rawResponse: singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$rawResponse;
  response: singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$data;
  variables: singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "message"
},
v4 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleLineTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleLineTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraSingleLineTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleLineTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleLineTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraSingleLineTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isEditableInIssueView"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "text"
                  },
                  {
                    "concreteType": "JiraFieldConfig",
                    "kind": "LinkedField",
                    "name": "fieldConfig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "isRequired"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "789e1058aee53618320be34c8fe29ff20ad79f15993332ebc49e45daf42694df",
    "metadata": {},
    "name": "singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e88da566aa7c50026d2c32ef608802d0";

export default node;
