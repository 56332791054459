import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	empty: {
		id: 'issue.views.field.select.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue.views.field.select.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	reloadIssue: {
		id: 'issue.views.field.select.reload-issue',
		defaultMessage: 'Try reloading the issue.',
		description: 'Message to prompt the user to reload the issue',
	},
	reloadIssueIssueTermRefresh: {
		id: 'issue.views.field.select.reload-issue-issue-term-refresh',
		defaultMessage: 'Try reloading the work item.',
		description: 'Message to prompt the user to reload the issue',
	},
});
