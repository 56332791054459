import {
	SET_VALUE_ARRAY_SIZE_LIMIT,
	isNotAutomaticId,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isNotEmptyString } from '@atlassian/ui-modifications-public-api-utils/is-not-empty-string';

export type PublicValue = {
	accountId: string;
};

export type MultiUserPickerPublicGetValue = PublicValue[];

export type MultiUserPickerPublicSetValue = string[];

export const validateMultiUserPickerSetValue = (
	value: unknown,
): value is MultiUserPickerPublicGetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	// We should omit automatic id special value so it won't be picked up by this field
	// It is only applicable to the assignee field
	value.every((option) => isNotEmptyString(option) && isNotAutomaticId(option));
