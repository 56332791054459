// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	menuHeader: {
		id: 'issue.views.field.team.edit.menuHeader',
		defaultMessage: 'Plan-specific teams can only be selected through a plan.',
		description: 'Select menu header message. "Plan" means "Portfolio plan" here',
	},
	noMatches: {
		id: 'issue.views.field.team.edit.noMatches',
		defaultMessage: 'No Matches',
		description: 'Select menu message. Shows there is no issues found',
	},
	placeholder: {
		id: 'issue.views.field.team.edit.placeholder',
		defaultMessage: 'Choose a team to assign this issue to.',
		description: 'Select menu placeholder.',
	},
	sharedTeam: {
		id: 'issue.context-items.team.shared-team',
		defaultMessage: 'Shared Team',
		description: 'Dropdown group header text. Represents options belong to the Shared Team group.',
	},
	menuFooter: {
		id: 'issue.context-items.team.edit.menuFooter',
		defaultMessage: 'Showing 100 results. To display more results, please refine your search.',
		description:
			'Text indicates there are more search results and customer has to refine the text to display them.',
	},
	teamSelectAriaLabel: {
		id: 'issue.context-items.team.edit.teamSelectAriaLabel',
		defaultMessage: 'Choose a team to assign this issue to',
		description: 'aria label for Team Select Field',
	},
	placeholderIssueTermRefresh: {
		id: 'issue.views.field.team.edit.placeholder-issue-term-refresh',
		defaultMessage: 'Choose a team to assign this work item to.',
		description: 'Select menu placeholder.',
	},
	teamSelectAriaLabelIssueTermRefresh: {
		id: 'issue.context-items.team.edit.teamSelectAriaLabel-issue-term-refresh',
		defaultMessage: 'Choose a team to assign this work item to',
		description: 'aria label for Team Select Field',
	},
});
export default messages;
