import { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { useClientValidationHandler } from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/index.tsx';
import { useServerValidationHandler } from '@atlassian/jira-issue-field-validation-handler/src/controllers/server-validation-handler/index.tsx';
import { getFieldIdFromAri } from '@atlassian/jira-issue-view-common-utils/src/id-from-ari/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	getOnCopyHandler,
	getOnPasteHandler,
} from '@atlassian/jira-issue-field-copy-paste/src/controller/index.tsx';
import type {
	ClipboardComponentType,
	ClipboardComponentValue,
} from '@atlassian/jira-issue-field-copy-paste/src/controller/types.tsx';
import type { FieldInlineEditActions, FieldInlineEditActionsInput } from './types.tsx';

const defaultIsEquals = <FieldValue,>(a: FieldValue, b: FieldValue) => a === b;

/** Conditional type to assert that `Value` strictly maps to a valid `ClipboardComponentValue` when `Type` is defined. */
type ClipboardCompatibleValue<Value, Type> = Type extends ClipboardComponentType
	? ClipboardComponentValue<Type>
	: Value;

export const useFieldInlineEditActions = <
	Value,
	Type extends ClipboardComponentType | undefined,
	FieldValue = ClipboardCompatibleValue<Value, Type>,
>({
	attributes,
	fieldId,
	fieldName,
	fieldType,
	initialValue,
	isValueEqual = defaultIsEquals,
	onCancel,
	onEdit,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	onUpdateValue,
	startWithEditViewOpen = false,
	updatedValue,
	validator,
	setAnalyticsAttributes,
	analyticsFieldType,
	clipboardComponentType,
}: FieldInlineEditActionsInput<FieldValue, Type>): FieldInlineEditActions<FieldValue> => {
	const [isEditing, setIsEditing] = useOptionallyControlledEditingState(
		startWithEditViewOpen,
		fieldId,
		{
			// Ensure our updatedValue is re-initialised if editing is enabled via an external store update
			onSharedChange: (sharedIsEditing: boolean) =>
				sharedIsEditing && onUpdateValue(initialValue, true),
		},
	);

	const [invalidMessage, { validateChange, validateSubmit, clearValidation }] =
		useClientValidationHandler(validator);
	const [hasServerValidationError, { handleSubmitFailed, clearServerValidation }] =
		useServerValidationHandler(fieldName, setIsEditing, onSubmitFailed);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	/** Clear any validation errors and transition to read view. */
	const onStopEditing = useCallback(() => {
		clearValidation();
		clearServerValidation();
		setIsEditing(false);
	}, [clearServerValidation, clearValidation, setIsEditing]);

	/** Handler to validate and submit the updated value for a field. */
	const handleSubmit = useCallback(
		(value: FieldValue, analyticsEvent: UIAnalyticsEvent) => {
			// Early exit if a validation error is present
			if (validateSubmit(value) === 'error') {
				return;
			}

			onStopEditing();
			// Skip mutation if value is unchanged
			if (isValueEqual(initialValue, value)) {
				return;
			}

			onSubmit(value, {
				onSuccess: () => {
					// Field updated event is a core action used to track MCU as defined in https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition
					fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', {
						...attributes,
						fieldKey: fieldId,
						fieldType,
					});
					onSubmitSucceeded?.(value);
				},
				onFail: (error?: Error, message?: string) => {
					handleSubmitFailed(error, message);
					if (!fg('relay-migration-issue-fields-assignee-fg')) {
						onSubmitFailed?.(); // we don't need this since it's called in handleSubmitFailed
					}
				},
			});
			fireUIAnalytics(analyticsEvent);
		},
		[
			attributes,
			createAnalyticsEvent,
			fieldId,
			fieldType,
			handleSubmitFailed,
			initialValue,
			isValueEqual,
			onStopEditing,
			onSubmit,
			onSubmitFailed,
			onSubmitSucceeded,
			validateSubmit,
		],
	);

	/** Exits `editView` and switches back to `readView`. This is called when the cancel action button (x) is clicked. */
	const handleCancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			onStopEditing();
			onCancel?.();
			fireUIAnalytics(analyticsEvent);
		},
		[onCancel, onStopEditing],
	);

	/** Handler called when readView is clicked. */
	const handleEdit = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			// Re-initialise the edit view value unless there is a server error, in which case we want to preserve the
			// previous dirty value.
			if (!hasServerValidationError) {
				onUpdateValue(initialValue, true);
			}
			setIsEditing(true);
			onEdit?.();
			fireUIAnalytics(analyticsEvent);

			if (fg('one_event_rules_them_all_fg')) {
				const fieldIdWithoutAri = getFieldIdFromAri(fieldId);
				const fieldTypeAttribute = analyticsFieldType ?? fieldType;

				if (fieldIdWithoutAri && fieldTypeAttribute) {
					getUpdateAnalyticsFlowHelper().fireAnalyticsStart(fieldIdWithoutAri, {
						analytics: createAnalyticsEvent({}),
						attributes: {
							fieldType: fieldTypeAttribute,
						},
					});
				}
			}
		},
		[
			hasServerValidationError,
			setIsEditing,
			onEdit,
			fieldId,
			fieldType,
			onUpdateValue,
			initialValue,
			createAnalyticsEvent,
			analyticsFieldType,
		],
	);

	/** Saves and confirms the value entered into the field. It exits `editView` and returns to `readView`. */
	const handleConfirm = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			handleSubmit(updatedValue, analyticsEvent);
		},
		[handleSubmit, updatedValue],
	);

	/**
	 * Handler called when the `editView` is updated by the user. This will validate the input and call
	 * `onUpdateValue` to update the value in state.
	 */
	const handleChange = useCallback(
		(value: FieldValue) => {
			validateChange(value);
			onUpdateValue(value, false);
			fg('one_event_rules_them_all_fg') && setAnalyticsAttributes?.(value);
		},
		[onUpdateValue, setAnalyticsAttributes, validateChange],
	);

	/**
	 * Handler called when the `editView` is updated by the user which will immediately confirm the value and return to
	 * `readView`.
	 *
	 * Note that this **will not** run any `change` validator functions. Consumers should instead define a `submit`
	 * validator function if required.
	 */
	const handleChangeAndConfirm = useCallback(
		(value: FieldValue) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'confirmed',
				actionSubject: 'inlineEdit',
			});
			onUpdateValue(value, false);
			handleSubmit(value, analyticsEvent);
			fg('one_event_rules_them_all_fg') && setAnalyticsAttributes?.(value);
		},
		[createAnalyticsEvent, handleSubmit, onUpdateValue, setAnalyticsAttributes],
	);

	let handleCopy: ((e: ClipboardEvent) => void) | undefined;
	let handlePaste: ((e: ClipboardEvent) => void) | undefined;
	if (expVal('jira_list_inline_editing', 'isInlineEditingEnabled', false)) {
		/**
		 * Attempt to submit changes to a field in the background (i.e. while remaining in `readView`). If the updated
		 * value fails `change` or `submit` validation then we forcibly switch to `editView` to allow users to correct
		 * their changes.
		 */
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const handleBackgroundSubmit = useCallback(
			(value: FieldValue) => {
				if (validateChange(value) === 'error' || validateSubmit(value) === 'error') {
					onUpdateValue(value, false);
					setIsEditing(true);
				} else {
					handleChangeAndConfirm(value);
				}
			},
			[handleChangeAndConfirm, setIsEditing, onUpdateValue, validateChange, validateSubmit],
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleCopy = useMemo(() => {
			if (clipboardComponentType !== undefined) {
				return getOnCopyHandler(
					clipboardComponentType,
					/* Type assertion is safe in this instance as we have previously asserted that FieldValue is
					 * compatible with ClipboardComponentValue<Type> via ClipboardCompatibleValue. */
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					initialValue as ClipboardComponentValue<typeof clipboardComponentType>,
				);
			}
			return noop;
		}, [clipboardComponentType, initialValue]);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		handlePaste = useMemo(() => {
			if (clipboardComponentType !== undefined) {
				const onPaste = getOnPasteHandler(clipboardComponentType);

				return (e: ClipboardEvent) => {
					const value = onPaste(e);
					if (value !== undefined) {
						/* Type assertion is safe in this instance as we have previously asserted that FieldValue is
						 * compatible with ClipboardComponentValue<Type> via ClipboardCompatibleValue. */
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						handleBackgroundSubmit(value as FieldValue);
					}
				};
			}
			return noop;
		}, [clipboardComponentType, handleBackgroundSubmit]);
	}

	return useMemo(
		() => ({
			handleCancel,
			handleEdit,
			handleConfirm,
			handleChange,
			handleChangeAndConfirm,
			hasServerValidationError,
			handleCopy,
			handlePaste,
			invalidMessage,
			isEditing,
		}),
		[
			handleCancel,
			handleChange,
			handleChangeAndConfirm,
			handleConfirm,
			handleEdit,
			handleCopy,
			handlePaste,
			hasServerValidationError,
			invalidMessage,
			isEditing,
		],
	);
};
