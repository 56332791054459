/** @jsx jsx */
import React, { useEffect, useCallback, useContext, type FC } from 'react';
import { jsx, styled } from '@compiled/react';
import ButtonOld from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { N400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { AiIcon as AILogo } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAiWorkBreakDownAnalytics,
	ISSUE_BREAKDOWN_BUTTON_ID,
} from '../../common/analytics/index.tsx';
import { ISSUE_VIEW_CONSUMER } from '../../constants.tsx';
import { AiIssueBreakdownEntryPointContext } from '../../controllers/context-provider.tsx';
import { steps, useIssueBreakdown } from '../../controllers/context.tsx';
import messages from '../../messages.tsx';

type Props = {
	issueHierarchyLevel?: number | undefined;
	isCompact: boolean;
};

export const useAiIssueBreakdownEntryPointActions = (): EntryPointActions =>
	useContext(AiIssueBreakdownEntryPointContext).entryPointActions;

export const IssueBreakdownButton: FC<Props> = ({ issueHierarchyLevel, isCompact }) => {
	const [
		{ currentStep, channelId },
		{ setIssueBreakdownStep, updateStreamingStatus, setStartTime },
	] = useIssueBreakdown();
	const entryPointActions = useAiIssueBreakdownEntryPointActions();
	const triggerRef = useEntryPointButtonTrigger(entryPointActions, true);
	const { fireTrack } = useAiWorkBreakDownAnalytics({ channelId, consumer: ISSUE_VIEW_CONSUMER });

	const isSelected = currentStep !== steps.defaultStep;

	/**
	 * We manually load the AI Work Breakdown (AIWB) component if it looks like it was already open (i.e. isSelected)
	 * This is to ensure that the AIWB component is rendered when the issue view is resized
	 * since this results in a complete re-render of the issue view & unmounting of the AIWB component.
	 */
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isSelected) {
			entryPointActions.load();
		}
	}, [isSelected, entryPointActions]);

	// Toggle to open/close issue breakdown
	const onFullViewClick = useCallback(() => {
		if (!isSelected) {
			setStartTime();
			setIssueBreakdownStep(steps.draftListStep);
			updateStreamingStatus(true);
			fireTrack('aiInteraction initiated', 'issueBreakdownButtonClicked', {
				...(fg('jira-ai-issue-view-improve-issues-button')
					? { invokedFrom: ISSUE_BREAKDOWN_BUTTON_ID }
					: {}),
			});
		}
	}, [isSelected, setStartTime, setIssueBreakdownStep, updateStreamingStatus, fireTrack]);

	const { formatMessage } = useIntl();

	const issueNameForFormatting = {
		issueType: issueHierarchyLevel && issueHierarchyLevel > 0 ? 'issues' : 'tasks',
	};

	const buttonLabel = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.buttonLabelIssueTermRefresh
			: messages.buttonLabel,
		issueNameForFormatting,
	);

	const buttonDefaultTooltip = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.buttonDefaultTooltipContentIssueTermRefresh
			: messages.buttonDefaultTooltipContent,
		issueNameForFormatting,
	);

	const interactionName = 'ai-work-breakdown.ui.issue-breakdown-button';

	const commonProps = {
		ref: triggerRef,
		spacing: 'compact',
		interactionName,
		testId: 'ai-work-breakdown.ui.issue-breakdown-button.button',
		'aria-label': buttonLabel,
	} as const;

	const ButtonComponent = fg('aligning-jira-ai-features-ui-improvements')
		? Button
		: IssueBreakdownButtonStyled;

	return (
		<Tooltip content={buttonDefaultTooltip} position="right">
			<ButtonComponent
				{...commonProps}
				isSelected={isSelected}
				onClick={onFullViewClick}
				// @ts-expect-error because iconBefore is complaining with ButtonOld - however, this works and will get cleaned up
				iconBefore={
					fg('aligning-jira-ai-features-ui-improvements') ? (
						(iconProps) => <AILogo {...iconProps} size="small" label="" />
					) : (
						<AILogo size="medium" label="" />
					)
				}
			>
				{!isCompact && buttonLabel}
			</ButtonComponent>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const IssueBreakdownButtonStyled = styled(ButtonOld)({
	color: token('color.text.subtlest', N400),
});
