import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import { LabelIcon } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/label-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	name?: string;
};

export const ReadView = ({ name }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Inline
			space="space.100"
			alignBlock="center"
			xcss={[containerStyles, !name && placeholderStyles]}
			testId="servicedesk-customer-service-custom-fields.common.ui.organization-read-view.inline"
		>
			<LabelIcon size="xsmall" type="ORGANIZATION" />
			{name || formatMessage(messages.none)}
		</Inline>
	);
};

const containerStyles = xcss({
	margin: 'space.050',
	overflowWrap: 'anywhere',
	paddingLeft: 'space.100',
});

const placeholderStyles = xcss({
	color: 'color.text.subtlest',
});
