import React, { type FC, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import Button from '@atlaskit/button';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import InfoIcon from '@atlaskit/icon/glyph/info';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss, Inline, type Space, Flex } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import { Hide, Show } from '@atlaskit/primitives/responsive';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { AiIcon } from '../ai-icon/index.tsx';
import { AiInformationIcon } from '../ai-information-icon/index.tsx';
import messages from './messages.tsx';

const ATLASSIAN_INTELLIGENCE_LINK =
	'https://support.atlassian.com/organization-administration/docs/understand-atlassian-intelligence-features-in-products/';

export type Props = {
	testId?: string;
	attribution?: string;
	disclaimer?: string;
	footerSpace?: Space;
	isBeta?: boolean;
	showDisclaimer?: boolean;
	showAttributionLink?: boolean;
};

const AIFooterThreshold = {
	SMALL: 340,
	MEDIUM: 600,
};

export const AiFooterOld: FC<Props> = ({
	testId,
	footerSpace,
	disclaimer,
	attribution,
	isBeta = true,
	showDisclaimer = true,
	showAttributionLink = false,
}) => {
	const { formatMessage } = useIntl();
	const footerContainer = useRef(null);
	const [displayedAttribution, setDisplayedAttribution] = useState<MessageDescriptor | null>(null);

	useResizeObserver({
		ref: footerContainer,
		onResize() {
			if (showAttributionLink && footerContainer.current) {
				const { offsetWidth } = footerContainer.current;
				if (offsetWidth > AIFooterThreshold.MEDIUM) {
					setDisplayedAttribution(messages.longAttributionText);
				} else if (offsetWidth > AIFooterThreshold.SMALL) {
					setDisplayedAttribution(messages.shortAttributionText);
				} else {
					setDisplayedAttribution(null);
				}
			}
		},
	});

	// Render conditions based on media queries
	// empty if <= xs
	// short text if <= sm size
	// long text if >= sm size
	const renderAttributionText = useMemo(
		() => (
			<>
				<Show below="xs" as="span">
					<VisuallyHidden>{formatMessage(messages.longAttributionText)}</VisuallyHidden>
				</Show>
				<Hide below="xs" as="span">
					<Show below="sm" as="span">
						{formatMessage(messages.shortAttributionText)}
					</Show>
					<Hide below="sm" as="span">
						{formatMessage(messages.longAttributionText)}
					</Hide>
				</Hide>
			</>
		),
		[formatMessage],
	);

	return (
		<Inline
			testId={testId}
			xcss={footerStyle}
			spread="space-between"
			alignBlock="center"
			space={footerSpace ?? 'space.050'}
			ref={footerContainer}
		>
			{showDisclaimer && (
				<>
					<AiInformationIcon />
					<Button
						appearance="subtle-link"
						spacing="none"
						href="https://www.atlassian.com/trust/atlassian-intelligence"
						rel="noopener"
						target="_blank"
						testId="atlassian-intelligence.common.ui.ai-footer.disclaimer-text"
					>
						<Box as="span" xcss={linkStyle}>
							{disclaimer ?? formatMessage(messages.disclaimerText)}
						</Box>
					</Button>
				</>
			)}
			{isBeta ? (
				<Lozenge testId="atlassian-intelligence.common.ui.ai-footer.beta-lozenge" appearance="new">
					{formatMessage(messages.betaLozenge)}
				</Lozenge>
			) : null}

			<Box xcss={spacerStyle} />
			{showAttributionLink ? (
				<Button
					appearance="subtle-link"
					spacing="none"
					href={ATLASSIAN_INTELLIGENCE_LINK}
					rel="noopener"
					target="_blank"
					testId="atlassian-intelligence.common.ui.ai-footer.attribution-link"
				>
					<Box as="span" xcss={linkStyle}>
						{attribution ?? (
							<>
								{displayedAttribution ? (
									formatMessage(displayedAttribution)
								) : (
									<VisuallyHidden>{formatMessage(messages.longAttributionText)}</VisuallyHidden>
								)}
							</>
						)}
					</Box>
				</Button>
			) : (
				<Box as="span" xcss={textStyle}>
					{attribution ?? renderAttributionText}
				</Box>
			)}

			<AiIcon isDisabled size="small" label="" />
		</Inline>
	);
};

const NewAIFooterThreshold = {
	SMALL: 280,
	MEDIUM: 328,
	LARGE: 624,
};

export const AiFooterNew: FC<Props> = ({ testId, isBeta = true, showDisclaimer = true }) => {
	const { formatMessage } = useIntl();
	const footerContainer = useRef(null);
	const onResize = useCallback(() => {
		if (footerContainer.current) {
			const { offsetWidth } = footerContainer.current;
			if (showDisclaimer) {
				if (offsetWidth > NewAIFooterThreshold.LARGE) {
					setDisplayedAttribution(messages.longAttributionText);
					setDisplayedDisclaimer(messages.longDisclaimerText);
				} else if (offsetWidth > NewAIFooterThreshold.MEDIUM) {
					setDisplayedAttribution(messages.shortAttributionText);
					setDisplayedDisclaimer(messages.disclaimerText);
				} else {
					setDisplayedAttribution(null);
					setDisplayedDisclaimer(messages.disclaimerText);
				}
			} else if (offsetWidth > NewAIFooterThreshold.SMALL) {
				setDisplayedAttribution(messages.longAttributionText);
			} else {
				setDisplayedAttribution(messages.shortAttributionText);
			}
		}
	}, [showDisclaimer]);

	const [displayedAttribution, setDisplayedAttribution] = useState<MessageDescriptor | null>(null);
	const [displayedDisclaimer, setDisplayedDisclaimer] = useState<MessageDescriptor | null>(null);

	useEffect(() => {
		onResize();
	}, [onResize]);

	useResizeObserver({
		ref: footerContainer,
		onResize,
	});

	return (
		<Inline
			testId={testId}
			xcss={newFooterStyle}
			spread="space-between"
			alignBlock="center"
			ref={footerContainer}
		>
			<Flex alignItems="center" gap="space.050">
				{isBeta && (
					<Lozenge
						testId="atlassian-intelligence.common.ui.ai-footer.beta-lozenge"
						appearance="default"
					>
						{formatMessage(messages.betaLozenge)}
					</Lozenge>
				)}
				{displayedDisclaimer && (
					<>
						<InfoIcon
							label={formatMessage(messages.infoIconLabel)}
							size="small"
							primaryColor={token('color.icon.subtle', colors.P300)}
							testId="atlassian-intelligence.common.ui.ai-footer.info-icon"
						/>
						<Anchor
							xcss={newLinkStyle}
							href="https://www.atlassian.com/trust/atlassian-intelligence"
							rel="noopener"
							target="_blank"
							testId="atlassian-intelligence.common.ui.ai-footer.disclaimer-text"
						>
							{formatMessage(displayedDisclaimer)}
						</Anchor>
					</>
				)}
			</Flex>
			<Flex alignItems="center" gap="space.050">
				<Anchor
					xcss={newLinkStyle}
					href={ATLASSIAN_INTELLIGENCE_LINK}
					rel="noopener"
					target="_blank"
					testId="atlassian-intelligence.common.ui.ai-footer.attribution-link"
				>
					{displayedAttribution ? (
						formatMessage(displayedAttribution)
					) : (
						<VisuallyHidden>{formatMessage(messages.longAttributionText)}</VisuallyHidden>
					)}
				</Anchor>
				<AtlassianIntelligenceIcon label={formatMessage(messages.aiIconLabel)} spacing="spacious" />
			</Flex>
		</Inline>
	);
};

export const AiFooter = componentWithFG('update_ai_footer_ui', AiFooterNew, AiFooterOld);

const spacerStyle = xcss({
	flexGrow: 1,
});

const footerStyle = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N40)}`,
	paddingInline: 'space.150',
	paddingBlock: 'space.150',
});

const textStyle = xcss({
	color: 'color.text.subtlest',
	margin: 'space.0',
	padding: 'space.0',
	display: 'flex',
	alignItems: 'center',
});

const linkStyle = xcss({
	textDecoration: 'none',
	fontWeight: token('font.weight.regular'),
	color: 'color.text.subtlest',
});

const newFooterStyle = xcss({
	borderTop: `1px solid ${token('color.border')}`,
	paddingInline: 'space.150',
	paddingBlock: 'space.050',
});

const newLinkStyle = xcss({
	textDecoration: 'none',
	color: 'color.text.subtlest',
	font: token('font.body.small'),
});
