import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	errorTitleRecordingAlreadyInProgress: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-title-recording-already-in-progress',
		defaultMessage: 'Loom recorder unavailable',
		description:
			'title for a notification flag when a user encounters an error because a recording is already in progress',
	},
	errorRecordingAlreadyInProgress: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-recording-already-in-progress',
		defaultMessage: 'End the recording in progress before recording another Loom video.',
		description: 'description for an error flag when a recording is already in progress',
	},
	errorTitleSdkNotInitialized: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-title-sdk-not-initialized',
		defaultMessage: 'The Loom recorder wasn’t ready',
		description: 'title for a notification flag when SDK is not ready to record',
	},
	errorSdkNotInitialized: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-sdk-not-initialized',
		defaultMessage: 'Try to start recording again now.',
		description:
			'description for an error flag when the Loom SDK fails to record for an unknown reason',
	},
	errorTitleUnknown: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-title-unknown',
		defaultMessage: 'Failed to start recording',
		description: 'title for a notification flag when SDK is not ready to record',
	},
	errorUnknown: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-unknown',
		defaultMessage:
			'Sorry, we couldn’t start your recording. Please refresh the page and try again.',
		description:
			'description for an error flag when the Loom SDK fails to record for an unknown reason',
	},
	errorTitleInitFailed: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-title-init-failed',
		defaultMessage: 'Loom recorder unavailable',
		description:
			'title for an error flag when the Loom SDK fails to start because the user browser is not supported',
	},
	errorInitFailedBrowserUnsupported: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-init-failed-browser-unsupported',
		defaultMessage:
			'Switch to a different browser or turn off private browsing to continue recording.',
		description:
			'description for an error flag when the Loom SDK fails to start because the user browser is not supported',
	},
	errorInitFailedThirdPartyCookiesDisabled: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-init-failed-third-party-cookies-disabled',
		defaultMessage: 'Enable third party cookies in your browser to continue recording.',
		description:
			'description for an error flag when the Loom SDK fails to start because third party cookies are disabled',
	},
	errorInitFailedStreamingUnsupported: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-init-failed-streaming-unsupported',
		defaultMessage:
			'Your browser isn’t supported - switch to a different browser to continue recording.',
		description:
			'description for an error flag when the Loom SDK fails to start because streaming media is not supported',
	},
	errorInitFailedInternal: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-init-failed-internal',
		defaultMessage: 'Refresh your browser to try again.',
		description:
			'description for an error flag when the Loom SDK fails to start because of an internal error (e.g. timeout)',
	},
	errorActionRefresh: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-action-refresh',
		defaultMessage: 'Refresh',
		description: 'the label on a button that makes the browser refresh (reload)',
	},
	errorTitleUnmergedAccount: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-title-unmerged-account',
		defaultMessage: 'User not found',
		description:
			'title for an error flag when the Loom SDK fails to start because the user has not merged their Atlassian and Loom accounts',
	},
	errorUnmergedAccount: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-unmerged-account',
		defaultMessage:
			'Merge your Atlassian and Loom profiles to access your account and available features.',
		description:
			'content of an error flag to tell the user to go merge their Atlassian and Loom accounts in order to use the Loom integration in Atlassian products',
	},
	errorActionMergeAccounts: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-action-merge-accounts',
		defaultMessage: 'Merge profiles',
		description:
			'label on a link that takes the user to the merge accounts page in Loom to merge their Loom and Atlassian accounts so they can use Loom integrations in Atlassian products',
	},
	errorActionBrowserUnsupported: {
		id: 'loom-utils.controllers.use-jira-loom-recorder-entrypoint.error-action-browser-unsupported',
		defaultMessage: 'See supported browsers',
		description: 'label on a link that takes the user to the supported browsers page on loom.com',
	},
});

export default messages;
