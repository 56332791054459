import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-19489
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { transformStkeholdersDetailResponseData } from '../../../common/utils.tsx';
import type { State, ContainerProps } from '../../types.tsx';
import messages from './messages.tsx';
import type { FetchStakeholdersResponse } from './types.tsx';

export const getStakeholders =
	(): Action<State, ContainerProps> =>
	async ({ setState, getState }, { issueId, cloudId, analyticsEvent }) => {
		const { showFlag } = useFlagsService();
		const { issueVersion } = getState();

		if (!issueId) {
			setState({
				stakeholders: {
					loading: null,
					error: 'Missing issueId',
				},
			});
			return;
		}

		try {
			let url;

			if (issueVersion.isNew) {
				url = `/gateway/api/jsm/stakeholders/cloudId/${cloudId}/v1/stakeholders/issue/${issueId}`;
			} else {
				url = `${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/v2/stakeholders`;
			}

			const response: FetchStakeholdersResponse = await fetchJson(url);

			setState({
				stakeholders: {
					loading: true,
				},
			});

			let data;

			if (issueVersion.isNew) {
				data = response;
			} else {
				// @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
				data = transformStkeholdersDetailResponseData(response.stakeholders);
			}

			setState({
				stakeholders: {
					loading: null,
					data,
				},
			});

			if (issueVersion.isNew) {
				// https://data-portal.internal.atlassian.com/analytics/registry/41036
				fireOperationalAnalytics(analyticsEvent, 'getStakeholders succeeded');
			} else {
				fireOperationalAnalytics(analyticsEvent, 'getStakeholdersV2 succeeded');
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// https://data-portal.internal.atlassian.com/analytics/registry/41045
			fireErrorAnalytics({
				event: analyticsEvent,
				meta: {
					id: 'getStakeholders',
					packageName: 'jiraJsmStakeholderUpdates',
					teamName: 'itsm-agni',
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});

			setState({
				stakeholders: {
					loading: null,
					error: e.message,
				},
			});

			showFlag({
				type: 'error',
				title: messages.failureHeading,
				description: messages.failureDescription,
			});
		}
	};
