import React, { type PointerEventHandler, useCallback, useRef } from 'react';
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { HandlerProps } from '../../common/types.tsx';
import {
	useDoubleClick,
	calculateNewRatio,
	useAnimationFrameThrottle,
	clampValue,
} from '../../common/utils.tsx';
import { ResizeHandle, ResizeBorder, ResizeIcon } from './styled.tsx';

const RESIZE_ICON_TEST_ID = 'flex-resizer.ui.handle.resize-icon';
const RESIZE_CHEVRON_ICON = 'flex-resizer.ui.handle.chevron-';

const Resizer = ({
	childrenContainerRef,
	initialRatio,
	defaultRatio,
	minWidth,
	minRatio = 0,
	maxWidth,
	maxRatio = 1,
	extraWidth = 0,
	position = 'left',
	resizeHandleAsOverlay,
	resizeHandleZIndex,
	onRatioChange,
	onRatioReset,
	onResizeStart,
	chevronDirection,
	persistResizeIcon,
	onResizeHandleClick,
	collapsed,
}: HandlerProps) => {
	const startX = useRef(0);
	const startWidth = useRef(0);
	const parentWidth = useRef(0);

	const prevChevronDirection = usePreviousWithInitial(chevronDirection);
	const nextRatio = useRef(initialRatio ?? defaultRatio);
	const styleElement = useRef<HTMLStyleElement | null>(null);

	const setFlexBasisProp = useCallback(
		(ratio: number, setNextRatio?: number) => {
			// This will force-fix any inconsitencies in the ratio value if ratio is set from parent components
			if (setNextRatio !== undefined) {
				nextRatio.current = setNextRatio;
			}
			if (childrenContainerRef.current) {
				childrenContainerRef.current.style.setProperty(
					'flex-basis',
					extraWidth > 0 ? `calc(${ratio * 100}% + ${extraWidth}px)` : `${ratio * 100}%`,
				);
			}
		},
		[childrenContainerRef, extraWidth],
	);

	const resetDetector = useDoubleClick(
		useCallback(() => {
			const targetRatio =
				clampValue({
					value: defaultRatio * parentWidth.current,
					minValue: Math.max(minWidth ?? 0, minRatio * parentWidth.current),
					maxValue: Math.min(maxWidth ?? Infinity, maxRatio * parentWidth.current),
				}) / parentWidth.current;

			nextRatio.current = targetRatio;
			setFlexBasisProp(targetRatio);
			onRatioReset?.(targetRatio);
		}, [defaultRatio, minWidth, minRatio, maxWidth, maxRatio, setFlexBasisProp, onRatioReset]),
	);

	const [onPointerMoveThrottled, cancelPointerMove] = useAnimationFrameThrottle(
		useCallback(
			(e: PointerEvent) => {
				const r = calculateNewRatio({
					parentWidth: parentWidth.current,
					currentWidth: startWidth.current,
					deltaX: startX.current - e.pageX,
					minWidth: minWidth ?? 0,
					minRatio,
					maxWidth: maxWidth ?? Infinity,
					maxRatio,
					baseWidth: extraWidth,
					position,
				});
				nextRatio.current = r;
				setFlexBasisProp(r);
			},
			[extraWidth, maxRatio, maxWidth, minRatio, minWidth, position, setFlexBasisProp],
		),
	);

	const onPointerMove = useCallback(
		(e: PointerEvent) => {
			e.preventDefault();
			onPointerMoveThrottled(e);
		},
		[onPointerMoveThrottled],
	);

	const onPointerUp = useCallback(() => {
		const r = nextRatio.current;
		const resetTriggered = resetDetector();
		if (!resetTriggered) {
			onRatioChange?.(r, nextRatio.current * parentWidth.current, setFlexBasisProp);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('pointermove', onPointerMove, { capture: true });

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('pointerup', onPointerUp, { capture: true });
		const stylesheet = styleElement.current?.sheet;
		if (stylesheet) {
			stylesheet.deleteRule(1);
			stylesheet.deleteRule(0);
		}
		cancelPointerMove();
	}, [cancelPointerMove, onPointerMove, onRatioChange, resetDetector, setFlexBasisProp]);

	const onPointerDown = useCallback<PointerEventHandler<HTMLDivElement>>(
		(e) => {
			// Check if the chevron icon was clicked and prevent dragging
			if (onResizeHandleClick && e.target instanceof HTMLElement) {
				const target =
					(e.target.getAttribute('data-testid') || '').indexOf(RESIZE_CHEVRON_ICON) > -1
						? 'chevron'
						: 'resizer';
				if (!onResizeHandleClick?.(target, setFlexBasisProp, e)) {
					e.preventDefault();
					return;
				}
			}
			// Prevent resize when the resizer is collapsed
			if (collapsed) return;
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointermove', onPointerMove, { capture: true });

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointerup', onPointerUp, { capture: true });

			const stylesheet = styleElement.current?.sheet;
			if (stylesheet) {
				// There is a chromium bug which forces all scrollbars to be visible when 'pointer-events: none' is added.
				// Chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=1280013&q=pointer-events%20scrollbar&can=2
				// The previously implemented workarounds for this issue didn't solve all use cases, i.e., horizontal scrollbars,
				// thus, 'user-select: none' is used to prevent text-highlighting but allows hover events, unlike 'pointer-events'.
				stylesheet.insertRule('body { user-select: none; }', 0);

				// Set the dragging cursor (higher than disabled pointer events)
				stylesheet.insertRule('#jira { cursor: ew-resize; }', 1);
			}

			const currentContainer = childrenContainerRef.current;
			const parentContainer = currentContainer?.parentElement;
			if (!currentContainer || !parentContainer) return;

			startWidth.current = currentContainer.getBoundingClientRect().width;
			parentWidth.current = parentContainer.getBoundingClientRect().width ?? 0;

			onResizeStart?.();

			startX.current = e.pageX;

			// This is preventing popups from closing when the resize handle is dragged
			if (!onResizeHandleClick) e.preventDefault();
		},
		[
			onPointerMove,
			onPointerUp,
			childrenContainerRef,
			onResizeStart,
			onResizeHandleClick,
			collapsed,
			setFlexBasisProp,
		],
	);

	const ResizeBar = useCallback(() => {
		const directionDidChange = chevronDirection !== prevChevronDirection;

		return (
			<ResizeHandle
				tabIndex={0}
				role="separator"
				aria-orientation="vertical"
				data-testid="flex-resizer.ui.handle.resizer"
				onPointerDown={onPointerDown}
				position={position}
				resizeHandleZIndex={resizeHandleZIndex}
				resizeHandleAsOverlay={resizeHandleAsOverlay}
				aria-valuenow={Math.floor(nextRatio.current * 100)}
				collapsed={collapsed}
			>
				<ResizeBorder triggerAnimation={directionDidChange} collapsed={collapsed}>
					<ResizeIcon
						triggerAnimation={directionDidChange}
						data-testid={RESIZE_ICON_TEST_ID}
						persistResizeIcon={persistResizeIcon}
						onResizeHandleClick={onResizeHandleClick}
					>
						{chevronDirection === 'LEFT' && (
							<ChevronLeftIcon size="medium" label="" testId={`${RESIZE_CHEVRON_ICON}left`} />
						)}

						{chevronDirection === 'RIGHT' && (
							<ChevronRightIcon size="medium" label="" testId={`${RESIZE_CHEVRON_ICON}right`} />
						)}
					</ResizeIcon>
				</ResizeBorder>
			</ResizeHandle>
		);
	}, [
		chevronDirection,
		prevChevronDirection,
		onPointerDown,
		position,
		resizeHandleZIndex,
		resizeHandleAsOverlay,
		persistResizeIcon,
		onResizeHandleClick,
		collapsed,
	]);

	return (
		<>
			<ResizeBar />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
			<style ref={styleElement} />
		</>
	);
};
export default Resizer;
