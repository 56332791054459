/* eslint-disable @atlassian/relay/unused-fields */
import { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	useForgeActions,
	useForgeDataComplete,
} from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';

import type { AggExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { view_forgeDataStoreInitFragment$key } from '@atlassian/jira-relay/src/__generated__/view_forgeDataStoreInitFragment.graphql';
import { canFetchForgeModulesV2 } from '@atlassian/jira-forge-ui-utils/src/utils/can-fetch-forge/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { formatForgeExtensions } from './utils.tsx';
import type { Props } from './types.tsx';

export const ForgeDataStoreInit = ({ rootRelayFragment, onForgeSuccess }: Props) => {
	const [, forgeActions] = useForgeActions();
	const [isForgeLoadedSuccessfully] = useForgeDataComplete();
	const isAnonymous = useIsAnonymous();
	const cloudId = useCloudId();

	const data = useFragment<view_forgeDataStoreInitFragment$key>(
		graphql`
			fragment view_forgeDataStoreInitFragment on JiraQuery {
				forge {
					extensions(
						cloudId: $cloudId
						context: { issueKey: $issueKey }
						types: $forgeTypes
						includeHidden: $includeHiddenForge
					) {
						hiddenBy @include(if: $includeHiddenForge)
						scopes @include(if: $includeScopesForge)
						type
						id
						environmentId
						environmentKey
						environmentType
						installationId
						appVersion
						consentUrl
						properties
						userAccess @include(if: $includeUserAccessForge) {
							hasAccess
						}
						egress {
							addresses
							type
						}
						license {
							active
							type
							supportEntitlementNumber
							trialEndDate
							subscriptionEndDate
							isEvaluation
							billingPeriod
							ccpEntitlementId
							ccpEntitlementSlug
							capabilitySet
						}
					}
				}
			}
		`,
		rootRelayFragment?.jira || null,
	);

	useEffect(() => {
		// canFetchForgeModulesV2 always returns false for anon users (for historical reasons we do not fetch Forge modules for anons)
		// Early return and no error state needed for anon users
		if (isForgeLoadedSuccessfully || isAnonymous) {
			return;
		}

		if (!canFetchForgeModulesV2({ isAnonymous, cloudId }) || data == null) {
			forgeActions.setForgeLoadingFailed();
			return;
		}

		const mutableExtensions: AggExtension[] = Array.from(data?.forge?.extensions || []);
		const statePayload = formatForgeExtensions(mutableExtensions);
		forgeActions.setForge({
			...statePayload,
			isForgeDataComplete: true,
			isForgeLoadingFailed: false,
		});

		onForgeSuccess(statePayload);
	}, [cloudId, data, forgeActions, isAnonymous, isForgeLoadedSuccessfully, onForgeSuccess]);

	return undefined;
};
