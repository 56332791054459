import React, { useEffect, useRef, useMemo } from 'react';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { JSErrorBoundaryProps } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getErrorType } from '@atlassian/ui-modifications-analytics';
import { TenantContextProvider } from '@atlassian/ui-modifications-core/src/controllers/tenant-context/index.tsx';
import { useAdjustmentsContextActions } from '@atlassian/ui-modifications-core/src/controllers/adjustments-context/index.tsx';
import { ExecutionContextProvider } from '@atlassian/ui-modifications-core/src/controllers/execution-context/index.tsx';
import type { TenantContextValue } from '@atlassian/ui-modifications-core/src/controllers/tenant-context/types.tsx';
import type { ExecutionContext } from '@atlassian/ui-modifications-core/src/common/types/execution-context.tsx';
import {
	isTriggerPointSupported,
	isProjectTypeSupported,
	isExecutionContextSupported,
} from '../../controllers/view-configuration/index.tsx';
import type { EnabledIssueAdjustmentsProps } from './enabled-issue-adjustments/index.tsx';
import { issueAdjustmentsEntryPoint } from './entrypoint.tsx';
import { IssueAdjustmentsErrorHandler } from './error-handler/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { EnabledIssueAdjustmentsProps } from './enabled-issue-adjustments';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IssueAdjustmentsErrorHandler } from './error-handler';

export type IssueAdjustmentsAsyncProps = {
	containerId: string;
	containerPackageName: string;
	runtimeProps: EnabledIssueAdjustmentsProps;
	executionContext: ExecutionContext;
	// Remove optional on `uim_execution_context_data` FG clean-up
	executionContextKey?: string;
	triggerPointKey?: string;
	errorFallback?: JSErrorBoundaryProps['fallback'];
	cloudId: string;
	activationId: string;
};

export const useDefaultErrorFallback = () => () => <IssueAdjustmentsErrorHandler />;

const STABLE_EMPTY_OBJECT = {};

export const IssueAdjustmentsAsync = ({
	containerId,
	containerPackageName,
	runtimeProps,
	executionContext,
	triggerPointKey,
	errorFallback,
	cloudId,
	activationId,
}: IssueAdjustmentsAsyncProps) => {
	const { issueAdjustmentsContext, modules, viewType } = runtimeProps;
	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		issueAdjustmentsEntryPoint,
		STABLE_EMPTY_OBJECT,
	);
	const { setShouldUiModificationsLoad } = useAdjustmentsContextActions();
	const defaultErrorFallback = useDefaultErrorFallback();
	const hasUiModificationsLoaded = useRef(false);

	const shouldUiModificationsLoad =
		modules?.length > 0 &&
		isTriggerPointSupported(viewType, triggerPointKey) &&
		(fg('uim_execution_context_data')
			? executionContext && isExecutionContextSupported(executionContext)
			: isProjectTypeSupported(issueAdjustmentsContext.project, viewType));

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		const hasProjectAndIssueTypeFinishedLoading = fg('uim_execution_context_data')
			? executionContext !== null
			: issueAdjustmentsContext !== null;
		// issueAdjustmentContext starts out as null, so we need to wait until it is populated
		// to know if UI modifications should load or not in this project type.
		// issueAdjustmentContext needs to be a dependency not hasProjectAndIssueTypeFinishedLoading
		// otherwise we don't set the supported status when changing issue types only
		if (hasProjectAndIssueTypeFinishedLoading) {
			setShouldUiModificationsLoad(shouldUiModificationsLoad ? 'yes' : 'no');
		}
		if (shouldUiModificationsLoad && !hasUiModificationsLoaded.current) {
			entryPointActions.load();
			hasUiModificationsLoaded.current = true;
		}
	}, [
		issueAdjustmentsContext,
		shouldUiModificationsLoad,
		setShouldUiModificationsLoad,
		entryPointActions,
		executionContext,
	]);

	const tenantContext = useMemo<TenantContextValue>(
		() => ({
			cloudId,
			activationId,
		}),
		[cloudId, activationId],
	);

	if (shouldUiModificationsLoad) {
		const renderResult = (
			<AnalyticsContext
				data={{
					attributes: {
						triggerPointKey,
					},
				}}
			>
				<TenantContextProvider value={tenantContext}>
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id={containerId}
						packageName={containerPackageName}
						fallback={<></>} // Placeholder fallback
						errorFallback={errorFallback || defaultErrorFallback}
						errorAttributes={(error: Error) => ({
							errorType: getErrorType(error),
							viewType: runtimeProps.viewType,
						})}
						runtimeProps={runtimeProps}
					/>
				</TenantContextProvider>
			</AnalyticsContext>
		);

		if (executionContext && fg('uim_execution_context_data')) {
			return (
				<ExecutionContextProvider value={executionContext}>{renderResult}</ExecutionContextProvider>
			);
		}

		return renderResult;
	}
	return null;
};
