import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Checkbox } from '@atlaskit/checkbox';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIssueKey,
	useChildIssuesLimit,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useHasExceededChildIssuesLimitAnyPanelOnOrAfterLoad } from '@atlassian/jira-issue-view-services/src/child-issues/use-has-exceeded-child-issues-limit/index.tsx';
import { FireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useIsClassicProject,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages.tsx';

type Props = {
	isRollingUpData: boolean;
	location: string;
	actionSubjectId: string;
	onToggleRollingUpData: (arg1: boolean, arg2: UIAnalyticsEvent) => void;
};

export const RollupCheckbox = ({
	isRollingUpData,
	onToggleRollingUpData,
	location,
	actionSubjectId,
}: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const isClassicProject = useIsClassicProject(projectKey);
	const { formatMessage } = useIntl();

	const hasExceededChildIssuesLimitOnLoadOrAfter =
		useHasExceededChildIssuesLimitAnyPanelOnOrAfterLoad();

	const childIssuesLimit = useChildIssuesLimit();

	const getCheckboxLabel = () => {
		if (isClassicProject) return formatMessage(messages.includeChildIssueLabelSubtasks);
		if (fg('jira-issue-terminology-refresh-m3'))
			return formatMessage(messages.includeChildIssueLabelChildIssuesIssueTermRefresh);
		return formatMessage(messages.includeChildIssueLabelChildIssues);
	};

	const checkbox = (
		<Checkbox
			isChecked={hasExceededChildIssuesLimitOnLoadOrAfter ? false : isRollingUpData}
			onChange={({ target: { checked } }, analyticsEvent) => {
				onToggleRollingUpData(checked, analyticsEvent);
			}}
			label={getCheckboxLabel()}
			name={location}
			isDisabled={hasExceededChildIssuesLimitOnLoadOrAfter}
		/>
	);

	const getTooltipContent = () => {
		const values = {
			limit: childIssuesLimit,
		};
		if (isClassicProject)
			return formatMessage(
				messages.timetrackingRollupDisabledTooltipLabelWithLimitSubtasks,
				values,
			);
		if (fg('jira-issue-terminology-refresh-m3'))
			return formatMessage(
				messages.timetrackingRollupDisabledTooltipLabelWithLimitChildIssuesIssueTermRefresh,
				values,
			);
		return formatMessage(
			messages.timetrackingRollupDisabledTooltipLabelWithLimitChildIssues,
			values,
		);
	};

	return (
		<>
			{hasExceededChildIssuesLimitOnLoadOrAfter ? (
				<Tooltip content={getTooltipContent()} position="top">
					{checkbox}
				</Tooltip>
			) : (
				checkbox
			)}
			<FireOperationalAnalytics
				action="exposed"
				actionSubject="feature"
				actionSubjectId={actionSubjectId}
			/>
		</>
	);
};
