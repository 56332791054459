import React, { Component, type ReactNode, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button/standard-button';
import { ErrorMessage, Label } from '@atlaskit/form';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Text, xcss } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import { N0, N50A, N60A, N500 } from '@atlaskit/theme/colors';

import { token } from '@atlaskit/tokens';
import Pressable from '@atlaskit/primitives/pressable';
import { fg } from '@atlassian/jira-feature-gating';
import { layers, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import InfoIcon from '@atlassian/jira-issue-field-description/src/ui/assets/info-icon.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	CustomFieldHeadingWithBottomMargin,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';

type Props = {
	isInvalid: boolean;
	isDisabled: boolean;
	isRequired: boolean;
	value: string;
	autoFocus: boolean;
	label: string;
	triggerBtnAriaLabel?: string;
	name: string;
	description?: string | null;
	invalidMessage: ReactNode;
	onChange: (arg1: string) => void;
	onBlur: () => void;
};

const informationIconStyles = xcss({
	background: 'none',
	lineHeight: 'inherit',
	padding: '0',
	marginLeft: 'space.050',
	marginBottom: 'space.050',
	verticalAlign: 'baseline',
});

const labelStyles = xcss({
	fontWeight: token('font.weight.medium'),
	verticalAlign: 'bottom',
	letterSpacing: '-0.008em',
	marginBottom: '0',
	lineHeight: '1.3333333333333333',
});

// eslint-disable-next-line jira/react/no-class-components
export default class TimeLogTextField extends Component<Props> {
	static defaultProps = {
		isInvalid: false,
		isRequired: false,
		isDisabled: false,
		autoFocus: false,
		name: 'text-field',
		onBlur: noop,
	};

	state = {
		shouldShowDetails: false,
	};

	onToggle = () =>
		this.setState({
			shouldShowDetails: !this.state.shouldShowDetails,
		});

	onChange = (event: ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(event.target.value);
	};

	render() {
		return (
			<FieldWrapper>
				<CustomFieldHeadingWithBottomMargin>
					{fg('jfp-a11y-team-timelog-label-association') ? (
						<Box as="span" xcss={labelStyles}>
							<Label htmlFor={`timelog-textfield-${this.props.label}`}>{this.props.label}</Label>
						</Box>
					) : (
						<FieldHeadingTitle>{this.props.label}</FieldHeadingTitle>
					)}
					{this.props.description && (
						<Popup
							testId="issue.component.log-time-modal.modal.time-log-text-field.ak-popup"
							zIndex={layers.modal}
							onClose={() => {
								this.setState({ shouldShowDetails: false });
							}}
							placement="right-start"
							content={() => (
								<Container>
									<Text as="p">{this.props.description}</Text>
								</Container>
							)}
							isOpen={this.state.shouldShowDetails}
							shouldRenderToParent={fg('jfp-a11y-team_fix_logwork_modal_a11y_issues')}
							trigger={(triggerProps) =>
								fg('jfp-a11y-team-timelog-label-association') ? (
									<Pressable
										{...triggerProps}
										onClick={this.onToggle}
										aria-pressed={this.state.shouldShowDetails}
										xcss={informationIconStyles}
										aria-label={this.props.triggerBtnAriaLabel}
									>
										<InfoIcon />
									</Pressable>
								) : (
									<CustomTimeLogPopupButton
										{...triggerProps}
										spacing="none"
										appearance="subtle"
										isSelected={this.state.shouldShowDetails}
										onClick={this.onToggle}
										aria-label={this.props.triggerBtnAriaLabel}
									>
										<InfoIcon />
									</CustomTimeLogPopupButton>
								)
							}
						/>
					)}
				</CustomFieldHeadingWithBottomMargin>
				<TextField
					autoFocus={this.props.autoFocus}
					isInvalid={this.props.isInvalid}
					isDisabled={this.props.isDisabled}
					isRequired={this.props.isRequired}
					onChange={this.onChange}
					value={this.props.value}
					onBlur={this.props.onBlur}
					aria-label={this.props.label}
					aria-describedby="time-tracking-formatting-text"
					id={`timelog-textfield-${this.props.label}`}
					data-testId={`timelog-textfield-${this.props.label}`}
				/>
				{this.props.isInvalid && <ErrorMessage>{this.props.invalidMessage}</ErrorMessage>}
			</FieldWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 32}px`,
	marginLeft: token('space.025', '2px'),
	width: 'auto',
	paddingTop: token('space.200', '16px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.200', '16px'),
	paddingLeft: token('space.300', '24px'),
	overflow: 'auto',
	backgroundColor: token('elevation.surface.raised', N0),
	boxShadow: token(
		'elevation.shadow.raised',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 ${gridSize * 0.5}px ${gridSize}px ${gridSize * -0.25}px ${N50A}, 0 0 ${
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			gridSize * 0.125
		}px ${N60A}`,
	),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomTimeLogPopupButton = styled(Button)({
	marginLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus, :hover': {
		background: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		color: `${token('color.background.neutral.bold.hovered', N500)}`,
	},
	background: 'none',
});
