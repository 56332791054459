import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removed: {
		id: 'issue-history.history-items.history-item.redaction-history-item.changed',
		defaultMessage: 'has removed sensitive data',
		description: `Describes that a user has changed the priority of the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {priority} is the 'Priority' field label.`,
	},
	description: {
		id: 'issue-history.history-items.history-item.redaction-history-item.description',
		defaultMessage:
			'The following {fieldsCount, plural, one {field was} other {fields were}} affected: <b>{fields}</b>',
		description: "(noun) Value to display as label for the 'priority' field.",
	},
});
