import type {
	ClipboardComponentMap,
	ClipboardComponentType,
	ClipboardComponentMeta,
	ClipboardTextSerialiser,
	ClipboardComponentValue,
} from './types.tsx';
import { getDefaultClipboardTextSerialiser } from './clipboard-text-serialiser.tsx';
import { getDefaultClipboardParser } from './clipboard-parser.tsx';
import { defaultClipboardTextParser, stringClipboardTextParser } from './clipboard-text-parser.tsx';

/** Returns a sanitised HTML string with the provided `componentType` and `value` set as HTML attributes. */
const safelyAddRichValueToHtml = <Type extends ClipboardComponentType>(
	componentType: Type,
	value: ClipboardComponentMap[Type],
) => {
	try {
		const html = `<div data-type="table-cell" data-component-type="${componentType}" />`;
		const DOM = new DOMParser().parseFromString(html, 'text/html');
		DOM.body?.querySelector('div')?.setAttribute('data-field-value', JSON.stringify(value));
		return DOM.body?.innerHTML || '';
	} catch (e) {
		return null;
	}
};

/** Serialise the provided input data into a structured object that is written to the Clipboard API. */
export function addToClipboard<Type extends ClipboardComponentType>(
	componentType: Type,
	value: ClipboardComponentValue<Type>,
	textSerialiser: ClipboardTextSerialiser<ClipboardComponentValue<Type>>,
): void {
	const clipboardItems: Record<string, Blob> = {
		'text/plain': new globalThis.window.Blob([textSerialiser(value)], { type: 'text/plain' }),
	};

	const html = safelyAddRichValueToHtml(componentType, value);
	if (html) {
		clipboardItems['text/html'] = new globalThis.window.Blob([html], { type: 'text/html' });
	}

	const clipboardItem = new globalThis.window.ClipboardItem(clipboardItems);
	globalThis.navigator.clipboard.write([clipboardItem]);
}

/**
 * Attempt to read structured HTML data from the Clipboard API and map it into strongly typed component metadata.
 * Returns `null` if there is no HTML content on the clipboard or it doesn't match the expected format.
 */
export const getComponentMetadataFromClipboard = (
	e: ClipboardEvent,
): ClipboardComponentMeta | null => {
	const html = e.clipboardData?.getData('text/html');
	if (!html) {
		return null;
	}

	try {
		const clipboardDocument = new DOMParser().parseFromString(html, 'text/html');
		const data = clipboardDocument.body?.querySelector('div')?.dataset;

		if (!data || !data.componentType || !data.fieldValue) {
			return null;
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return {
			componentType: data.componentType,
			value: JSON.parse(data.fieldValue),
		} as ClipboardComponentMeta;
	} catch (error) {
		return null;
	}
};

/** Returns on `onCopy` event handler that will serialise the provided input arguments to the Clipboard API. */
export const getOnCopyHandler = (
	componentType: ClipboardComponentType,
	value: ClipboardComponentValue<typeof componentType>,
) => {
	const textSerialiser = getDefaultClipboardTextSerialiser<typeof componentType>();
	return () => addToClipboard(componentType, value, textSerialiser);
};

/** Returns on `onPaste` event handler that will parse component metadata from the Clipboard API. */
export const getOnPasteHandler = (componentType: ClipboardComponentType) => {
	const parser = getDefaultClipboardParser<typeof componentType>(componentType);

	return (e: ClipboardEvent): ClipboardComponentValue<typeof componentType> | undefined => {
		const metadata = getComponentMetadataFromClipboard(e);
		if (metadata) {
			try {
				return parser(metadata);
			} catch (_) {
				// Silently ignore and fallthrough
			}
		}
		const plainText = e.clipboardData?.getData('text/plain');
		if (plainText) {
			try {
				if (componentType === 'text') {
					return stringClipboardTextParser(plainText);
				}
				return defaultClipboardTextParser(plainText);
			} catch (_) {
				// Silently ignore and fallthrough
			}
		}
	};
};
