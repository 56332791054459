import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	generating: {
		id: 'ai-work-breakdown.issue-breakdown-draft-list.streaming-status-bar.generating',
		defaultMessage: 'Generating issue suggestions',
		description:
			'Alt message of loading icon while Atlassian Intelligence is generating suggested child issues',
	},
	generatingIssueTermRefresh: {
		id: 'ai-work-breakdown.issue-breakdown-draft-list.streaming-status-bar.generating-issue-term-refresh',
		defaultMessage: 'Generating suggestions',
		description:
			'Alt message of loading icon while Atlassian Intelligence is generating suggested child issues',
	},
});
