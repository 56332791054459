import React from 'react';
import { v4 as uuid } from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useTriggerIssueViewModal } from '@atlassian/jira-open-issue-view-modal-controller/src/index.tsx';
import SquareAddIconButton from '../../../../button/square-add-icon-button.tsx';
import messages from './messages.tsx';
import type { AddChildProps } from './types.tsx';

export const AddChild = ({ isClassicSubtaskPanel = false, onClick }: AddChildProps) => {
	const { formatMessage } = useIntl();
	const label = isClassicSubtaskPanel ? messages.createSubtaskLabel : messages.createChildLabel;
	const [, { setIssueCreateSessionId }] = useTriggerIssueViewModal();

	const handleOnClick = (
		e: React.MouseEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	): void => {
		const sessionId = uuid();
		setIssueCreateSessionId(sessionId);
		onClick(e, analyticsEvent, sessionId);
	};

	return (
		<ErrorBoundary prefixOverride="issue" id="Safe(AddChild)">
			<Box xcss={buttonWrapperStyles}>
				<SquareAddIconButton onClick={handleOnClick} label={formatMessage(label)} />
			</Box>
		</ErrorBoundary>
	);
};

const buttonWrapperStyles = xcss({
	marginLeft: 'space.075',
});
