import type {
	IssueLinks,
	IssueLinkType,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import {
	type SaveRemoteIssueLinkPayload,
	REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID,
	REMOTE_ISSUE_LINK_PAYLOAD_WITHOUT_ISSUE_ID,
} from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-add-type.tsx';
import type { JiraAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { getIssueHtmlUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls.tsx';
import {
	toHref,
	toIssueId,
	toRemoteLinkGlobalId,
	type BaseUrl,
	type IssueKey,
	type IssueId,
	type RemoteLinkGlobalId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { SelectItemType } from '../issue-links-type/index.tsx';
import type { NewIssueLinksType } from './types.tsx';

export type IssueLink = {
	id: string;
	key: IssueKey;
	summaryText?: string;
	img?: string;
	issueProjectType?: string;
	issueItsmPractice?: string;
};

export const transformIssueLink = (
	optimisticId: string,
	issueLinkType: IssueLinkType,
	issueLink: IssueLink,
	baseUrl: BaseUrl,
): IssueLinks => {
	const issueLinks = {
		[optimisticId]: {
			id: optimisticId,
			linkedIssueKey: issueLink.key,
			direction: issueLinkType.direction,
			typeName: issueLinkType.label,
			typeId: issueLinkType.id,
		},
	};

	const linkedIssues = {
		[`${issueLink.key}`]: {
			id: issueLink.key,
			issueKey: issueLink.key,
			issueId: issueLink.id !== null && issueLink.id !== undefined ? issueLink.id.toString() : null,
			issueLink: getIssueHtmlUrl(baseUrl, issueLink.key),
			issueSummary: issueLink.summaryText ? issueLink.summaryText : '',
			issueProjectType: issueLink.issueProjectType,
			issueItsmPractice: issueLink.issueItsmPractice,
			issueTypeIconUrl: issueLink.img ? issueLink.img : '',
		},
	};
	return {
		issueLinks,
		// @ts-expect-error - TS2322 - Type '{ [x: string]: { id: string; issueKey: string; issueId: string | null; issueLink: string; issueSummary: string; issueTypeIconUrl: string; }; }' is not assignable to type 'NormalizedLinkedIssues'.
		linkedIssues,
	};
};

// com/atlassian/jira/issue/link/RemoteIssueLink.java
export const APPLICATION_TYPE_JIRA = 'com.atlassian.jira';
export const DEFAULT_APPLICATION_NAME_JIRA = 'jira';

// RemoteJiraGlobalIdFactoryImpl.java
const APP_ID_KEY = 'appId';
const ISSUE_ID_KEY = 'issueId';
const createGlobalId = (appLinkId: string, issueId: IssueId): RemoteLinkGlobalId =>
	toRemoteLinkGlobalId(`${APP_ID_KEY}=${appLinkId}&${ISSUE_ID_KEY}=${issueId}`);

export const transformSaveRemoteIssueLinkPayload = (
	index: number,
	newIssueLink: NewIssueLinksType,
	jiraAppLink: JiraAppLink,
	issueLinkType: SelectItemType,
	createReciprocalLink: boolean,
): SaveRemoteIssueLinkPayload => {
	const optimisticId = `OPTIMISTIC_LINKED_ISSUE_${Date.now()}_${index}`;
	const {
		fullObject: { id, key, summaryText },
		iconUrl,
	} = newIssueLink;
	const { id: appLinkId, name: appLinkName, baseUrl } = jiraAppLink;
	const partialPayload = {
		id: optimisticId,
		applicationType: APPLICATION_TYPE_JIRA,
		applicationName: appLinkName,
		issueLinkUrl: getIssueHtmlUrl(baseUrl, key),
		relationship: issueLinkType.label,
		summary: summaryText,
		iconUrl: toHref(iconUrl),
		issueKey: key,
		createReciprocalLink,
	};
	if (!id) {
		return {
			...partialPayload,
			appLinkId,
			// @ts-expect-error - TS2322 - Type '"WITHOUT_ISSUE_ID"' is not assignable to type '"WITH_ISSUE_ID"'.
			payloadType: REMOTE_ISSUE_LINK_PAYLOAD_WITHOUT_ISSUE_ID,
		};
	}
	const issueId = toIssueId(id.toString());
	return {
		...partialPayload,
		globalId: createGlobalId(appLinkId, issueId),
		remoteIssueId: issueId,
		payloadType: REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID,
	};
};
