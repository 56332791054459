import React, { useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { graphql, useMutation } from 'react-relay';
import Button, { LoadingButton } from '@atlaskit/button';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import Link from '@atlaskit/link';
import Lozenge from '@atlaskit/lozenge';
import { HeadingItem, Section } from '@atlaskit/menu';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { createPrDropdownContentAutofixMutation as CreateAutofixJobMutationType } from '@atlassian/jira-relay/src/__generated__/createPrDropdownContentAutofixMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { INTERNAL_REMOVE_FEATURE_FLAG, STATUS } from '../../../constants.tsx';
import { isBitbucketPermError, type FFCleanupError, type JobStatus } from '../../../types.tsx';
import messages from './messages.tsx';

type Props = {
	status?: JobStatus;
	issueAri: string;
	isEligible: boolean;
	eligibilityFailureMessages?: string[];
	setStatus: (value: JobStatus | undefined) => void;
	ffcleanupError?: FFCleanupError;
};

export const CreatePrDropdownMenuContent = ({
	status,
	issueAri,
	setStatus,
	isEligible,
	eligibilityFailureMessages,
	ffcleanupError,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const cloudId = useCloudId();
	const disableFeature = !isEligible && status !== STATUS.FAILED;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// prettier-ignore
	const [createAutofixJob] = useMutation<CreateAutofixJobMutationType>(graphql`
        mutation createPrDropdownContentAutofixMutation($cloudId: ID!, $input: JiraAutofixInput!) {
            jira {
                devOps {
                    createAutofixJob(cloudId: $cloudId, input: $input) @optIn(to: "Jira-autofix-jobs") {
                        success
                        errors {
                            message
                            extensions {
                                errorType
                                statusCode
                            }
                        }
                        job {
                            status
                        }
                    }
                }
            }
        }
    `);

	const sendEvent = (mutationStatus: string) => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});
		fireUIAnalytics(event, `autodev-create-draft-pr-job-${mutationStatus}`);
	};

	const onCreateJobHandler = () => {
		setIsLoading(true);
		createAutofixJob({
			variables: {
				input: {
					issueAri,
					jobType: INTERNAL_REMOVE_FEATURE_FLAG,
				},
				cloudId,
			},
			onCompleted: (data) => {
				const result = data?.jira?.devOps?.createAutofixJob;
				if (result?.success) {
					setStatus(result?.job?.status);
					sendEvent('success');
				} else {
					setStatus(STATUS.FAILED);
					sendEvent('failure');
					throw new Error(`Create autofix job failed : ${JSON.stringify(result?.errors)}`);
				}
				setIsLoading(false);
			},
			onError: (error) => {
				setStatus(STATUS.FAILED);
				setIsLoading(false);
				sendEvent('failure');
				throw error;
			},
		});
	};

	const failedErrorMessage = useMemo(() => {
		if (ffcleanupError && isBitbucketPermError(ffcleanupError)) {
			const { repoName, repoUrl } = ffcleanupError;
			return (
				<Stack space="space.100">
					<Box>
						{formatMessage(messages.repoAccessErrorMessageNonFinal, {
							repo: (
								<Link target="_blank" href={repoUrl}>
									{repoName}
								</Link>
							),
						})}
					</Box>
					<Box>
						{formatMessage(messages.repoAccessErrorMessageInstructionsNonFinal, {
							faq: (
								<Link target="_blank" href="https://hello.atlassian.net/wiki/x/THUo7Q">
									{formatMessage(messages.faqNonFinal)}
								</Link>
							),
						})}
					</Box>
				</Stack>
			);
		}

		return formatMessage(messages.errorMessage);
	}, [ffcleanupError, formatMessage]);

	return (
		<Section aria-labelledby="autodev-draft-pr-modal-title">
			<HeadingItem id="autodev-draft-pr-modal-title" aria-hidden>
				{formatMessage(messages.sectionTitle)}{' '}
				<Lozenge appearance="moved">{formatMessage(messages.alpha)}</Lozenge>
			</HeadingItem>
			<DropdownWrapper>
				<Stack space="space.100">
					{status === STATUS.FAILED && (
						<SectionMessage appearance="error" title={formatMessage(messages.errorMessageTitle)}>
							{failedErrorMessage}
						</SectionMessage>
					)}
					{status === STATUS.COMPLETED && (
						<SectionMessage
							appearance="success"
							title={formatMessage(messages.successMessageTitleNonFinal)}
						>
							PR has been created successfully.
						</SectionMessage>
					)}
					{disableFeature && (
						<SectionMessage appearance="warning">
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.nonEligibleIssueIssueTermRefresh
									: messages.nonEligibleIssue,
							)}
							<EligibilityReasonsListWrapper>
								<ul>
									{(eligibilityFailureMessages || []).map((errorMessage: string) => (
										<li
											key={errorMessage}
											// eslint-disable-next-line react/no-danger
											dangerouslySetInnerHTML={{ __html: errorMessage }}
										/>
									))}
								</ul>
							</EligibilityReasonsListWrapper>
						</SectionMessage>
					)}
					<Box xcss={contentStyles}>
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.instructionalTextIssueTermRefresh
								: messages.instructionalText,
						)}
						<ButtonWrapper>
							<Button
								appearance="link"
								target="_blank"
								href="https://hello.atlassian.net/wiki/x/DCPOyg"
							>
								{formatMessage(messages.learnMoreText)}
							</Button>
						</ButtonWrapper>
					</Box>
					{!disableFeature && (
						<Box>
							<LoadingButton
								iconAfter={
									!status ? (
										<AddCircleIcon
											label={formatMessage(messages.createPrButtonText)}
											size="small"
										/>
									) : (
										<></>
									)
								}
								onClick={onCreateJobHandler}
								isLoading={isLoading}
							>
								{!status && formatMessage(messages.createPrButtonText)}
								{status === STATUS.FAILED && formatMessage(messages.retryButtonText)}
								{status === STATUS.COMPLETED &&
									formatMessage(messages.regenerateButtonTextNonFinal)}
							</LoadingButton>
						</Box>
					)}
				</Stack>
			</DropdownWrapper>
		</Section>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownWrapper = styled.div({
	paddingTop: token('space.050', '4px'),
	paddingRight: token('space.250', '20px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.250', '20px'),
	maxWidth: '350px',
});

const contentStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	position: 'relative',
	right: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EligibilityReasonsListWrapper = styled.div({
	paddingTop: `${token('space.150', '12px')}`,
	paddingBottom: `${token('space.150', '12px')}`,
});
