import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	type ApplicationKey,
	CORE,
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	SOFTWARE,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import {
	PACKAGE_NAME,
	USAGE_LIMITS_BY_PRODUCT_CACHE_KEY,
	USAGE_LIMITS_EXPIRY_MS,
} from './constants.tsx';
import type {
	ProductUsageLimitsData,
	UsageLimitsData,
	UsageLimit,
	UsageLimitsResponse,
} from './types.tsx';

export const usageLimitsStorage = createLocalExpirableStorageProvider('usage-limits');

export const usageLimitsByProductStorage =
	createLocalExpirableStorageProvider('usage-limits-by-product');

const isApplicationKey = (key: string): key is ApplicationKey => {
	const supportedApplications: ApplicationKey[] = [SERVICE_DESK, SOFTWARE, PRODUCT_DISCOVERY, CORE];
	return supportedApplications.some((application) => application === key);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isUsageLimit = (limit: any): limit is UsageLimit => {
	if (limit == null || typeof limit !== 'object') {
		return false;
	}

	if (limit.product == null || typeof limit.product !== 'string') {
		return false;
	}

	if (limit.edition == null || typeof limit.edition !== 'string') {
		return false;
	}

	if (limit.total == null || typeof limit.total !== 'object') {
		return false;
	}

	if (limit.total.allowed == null || typeof limit.total.allowed !== 'number') {
		return false;
	}

	if (limit.total.used == null || typeof limit.total.used !== 'number') {
		return false;
	}

	if (limit.total.unlimited == null || typeof limit.total.unlimited !== 'boolean') {
		return false;
	}
	return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUsageLimitsResponse = (data: any): data is UsageLimitsResponse => {
	if (data == null || typeof data !== 'object') {
		return false;
	}

	// Check if limits exists and is a non-empty array
	if (!Array.isArray(data.limits) || data.limits.length === 0) {
		return false;
	}

	// Check every limit isUsageLimit
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (!data.limits.every((limit: any) => isUsageLimit(limit))) {
		return false;
	}

	return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isUsageLimitsData = (data: any): data is UsageLimitsData => {
	if (data == null || typeof data !== 'object') {
		return false;
	}

	if (!isUsageLimitsResponse(data)) {
		return false;
	}

	// Optionally check fetchTimestamp if it exists
	if ('fetchTimestamp' in data && typeof data.fetchTimestamp !== 'number') {
		return false;
	}
	return true;
};

export const isProductUsageLimitsData = (obj: unknown): obj is ProductUsageLimitsData => {
	// Check if obj is an object
	if (obj == null || typeof obj !== 'object') {
		return false;
	}

	// Check each key and value in the object
	for (const [key, value] of Object.entries(obj)) {
		// Check if key is one of the ApplicationKeys
		if (!isApplicationKey(key)) {
			return false;
		}

		// Check if value matches UsageLimitsData structure
		if (!isUsageLimitsData(value)) {
			return false;
		}
	}
	// If all checks pass, return true
	return true;
};

export function getUsageLimitsByProductFromLocalStorage(
	teamName: string,
): ProductUsageLimitsData | undefined {
	try {
		const cachedValue = usageLimitsByProductStorage.get(USAGE_LIMITS_BY_PRODUCT_CACHE_KEY);
		if (isProductUsageLimitsData(cachedValue)) {
			return cachedValue;
		}
		return undefined;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getUsageLimitsForProductFromLocalStorage',
				packageName: PACKAGE_NAME,
				teamName,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

export function setUsageLimitsForProductInLocalStorage(
	product: ApplicationKey,
	response: UsageLimitsData,
	teamName: string,
) {
	const prevCache = getUsageLimitsByProductFromLocalStorage(teamName);

	const newCache: ProductUsageLimitsData = { ...prevCache, [product]: response };

	usageLimitsByProductStorage.set(
		USAGE_LIMITS_BY_PRODUCT_CACHE_KEY,
		newCache,
		Date.now() + USAGE_LIMITS_EXPIRY_MS,
	);
}
