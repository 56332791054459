import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export type Props = {
	gitCommand: string;
};

const GitCommandPreview = ({ gitCommand }: Props) => (
	<Tooltip content={gitCommand} position="top">
		<PreviewContainer data-testid="development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.git-command-preview.preview-container">
			{gitCommand}
		</PreviewContainer>
	</Tooltip>
);

export default GitCommandPreview;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewContainer = styled.div({
	borderWidth: '2px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: `${token('color.border', colors.N30)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '3px',
	paddingRight: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBottom: '3px',
	paddingLeft: token('space.075', '6px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	WebkitLineClamp: 2,
	WebkitBoxOrient: 'vertical',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 5}px`,
});
