/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type { PropsWithChildren } from 'react';

import { cssMap, jsx } from '@compiled/react';

const conditionallyVisibleStyles = cssMap({
	visible: { display: 'block' },
	hidden: { display: 'none' },
});

export function ConditionallyVisibleDiv({
	isVisible = true,
	children,
	testId,
	id,
	role,
}: PropsWithChildren<{ isVisible?: boolean; testId?: string; id?: string; role?: string }>) {
	return (
		<div
			id={id}
			role={role}
			data-testid={testId}
			css={conditionallyVisibleStyles[isVisible ? 'visible' : 'hidden']}
		>
			{children}
		</div>
	);
}
