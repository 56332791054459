/**
 * @generated SignedSource<<30b5a646889d9dd35a6d34e5feb34e66>>
 * @relayHash 92b65a811b3912515b5a3efa744f98dc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8a2c6f1bd5349601acace5edead5f0c73b8738ab7d9a506a3a638e282c4d3785

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraUpdateTimeTrackingFieldInput = {
  id: string;
  originalEstimate?: JiraEstimateInput | null | undefined;
  remainingEstimate?: JiraEstimateInput | null | undefined;
};
export type JiraEstimateInput = {
  timeInSeconds: AGG$Long;
};
export type originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$variables = {
  input: JiraUpdateTimeTrackingFieldInput;
};
export type originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$data = {
  readonly jira: {
    readonly updateTimeTrackingField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly originalEstimate: {
          readonly timeInSeconds: AGG$Long | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$rawResponse = {
  readonly jira: {
    readonly updateTimeTrackingField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly originalEstimate: {
          readonly timeInSeconds: AGG$Long | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation = {
  rawResponse: originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$rawResponse;
  response: originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$data;
  variables: originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraTimeTrackingFieldPayload",
        "kind": "LinkedField",
        "name": "updateTimeTrackingField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraTimeTrackingField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "concreteType": "JiraEstimate",
                "kind": "LinkedField",
                "name": "originalEstimate",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "timeInSeconds"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "8a2c6f1bd5349601acace5edead5f0c73b8738ab7d9a506a3a638e282c4d3785",
    "metadata": {},
    "name": "originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_OldMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bbf5824591f15a7e242ff1f2c875bdcf";

export default node;
