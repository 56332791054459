/**
 * @generated SignedSource<<ff658e2fa2e7e45dace0a64aa8643465>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type number_issueFieldNumberInlineEditFull_NumberInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly number: number | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberReadviewFull_NumberReadView">;
  readonly " $fragmentType": "number_issueFieldNumberInlineEditFull_NumberInlineEditViewWithIsEditable_fragmentRef";
};
export type number_issueFieldNumberInlineEditFull_NumberInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: number_issueFieldNumberInlineEditFull_NumberInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberInlineEditFull_NumberInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "number_issueFieldNumberInlineEditFull_NumberInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "number_issueFieldNumberReadviewFull_NumberReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "number"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "bb1bee4e2db3c55d94055f595729b66a";

export default node;
