import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { getColorMetadata } from '../color-picker/utils/index.tsx';

export type Props = {
	name: string;
	color: string | null | undefined;
	isInteractive?: boolean;
};

export const OptionTag = memo(({ name, color, isInteractive = true }: Props) => {
	const { backgroundColor: tagColor } = getColorMetadata(color);

	return (
		<Box
			xcss={[tagWrapperStyles, isInteractive && interactiveStyles]}
			aria-label={name}
			testId="option-color-picker.option-tag.field-tag-wrapper"
		>
			<StyledTag
				data-testid={`option-color-picker.option-tag.${color || 'default'}`}
				color={tagColor}
			>
				{name}
			</StyledTag>
		</Box>
	);
});

const tagWrapperStyles = xcss({
	display: 'inline-flex',
	justifyContent: 'left',
	alignItems: 'center',
	transition: '0.3s',
	maxWidth: '100%',
	minWidth: '0',
	minHeight: '0',
	color: 'color.text',
});
const interactiveStyles = xcss({
	':hover': {
		cursor: 'pointer',
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTagBase = styled.div<{ color: string }>({
	font: token('font.body'),
	lineHeight: '14px',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTag = styled(StyledTagBase)<{ color: string }>({
	borderRadius: token('border.radius.050', '3px'),
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	border: ({ color }) => `1px solid ${color}`,
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.050', '4px'),
});
