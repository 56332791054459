import difference from 'lodash/difference';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-19489
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Stakeholder } from '../../../common/types.tsx';
import { transformStkeholdersDetailResponseData } from '../../../common/utils.tsx';
import type { State, ContainerProps } from '../../types.tsx';
import messages from './messages.tsx';

export const addStakeholder =
	(updatedStakeholders: Stakeholder['stakeholderAri'][]): Action<State, ContainerProps> =>
	async ({ getState, setState }, { issueId, cloudId, analyticsEvent }) => {
		const { showFlag } = useFlagsService();
		const { stakeholders, issueVersion } = getState();

		if (!issueId) {
			setState({
				stakeholders: {
					error: 'Missing issueId',
				},
			});
			return;
		}

		// @ts-expect-error - TS2345 - Argument of type 'Stakeholders | undefined' is not assignable to parameter of type 'List<Stakeholder>'.
		const addedStakeholders = difference(updatedStakeholders, stakeholders.data);

		if (issueVersion.isNew) {
			setState({
				stakeholders: {
					...stakeholders,
					error: undefined,
					isAddStakeholderLoading: true,
				},
			});
		} else {
			// @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
			const data = transformStkeholdersDetailResponseData(updatedStakeholders);

			setState({
				stakeholders: {
					error: undefined,
					data,
				},
			});
		}

		try {
			let url;

			if (issueVersion.isNew) {
				url = `/gateway/api/jsm/stakeholders/cloudId/${cloudId}/v1/stakeholders/issue/${issueId}`;
			} else {
				url = `${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/stakeholders`;
			}

			const response = await performPostRequest(url, {
				body: JSON.stringify({
					stakeholderAris: issueVersion.isNew
						? addedStakeholders
						: // @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
							addedStakeholders.map((stakeholder) => stakeholder.ari),
				}),
			});

			if (issueVersion.isNew) {
				setState({
					stakeholders: {
						data: [...(stakeholders.data ?? []), ...response],
						error: undefined,
						isAddStakeholderLoading: false,
					},
				});
			}

			// https://data-portal.internal.atlassian.com/analytics/registry/59451
			fireOperationalAnalytics(analyticsEvent, 'addStakeholder succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// https://data-portal.internal.atlassian.com/analytics/registry/41051
			fireErrorAnalytics({
				event: analyticsEvent,
				meta: {
					id: 'addStakeholder',
					packageName: 'jiraJsmStakeholderUpdates',
					teamName: 'itsm-agni',
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});

			if (issueVersion.isNew) {
				setState({
					stakeholders: {
						...stakeholders,
						isAddStakeholderLoading: false,
						error: e.message,
					},
				});
			} else {
				setState({
					stakeholders: {
						error: e.message,
					},
				});
			}

			showFlag({
				type: 'error',
				title: messages.failureHeading,
				description: messages.failureDescription,
			});
		}
	};
