import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'servicedesk-customer-service-organization-field.controllers.use-update-issue-view-organization-field.title-non-final',
		defaultMessage: 'We couldn’t update {fieldName}',
		description: 'Flag title shown when an error occurred trying to update an issue field.',
	},
	description: {
		id: 'servicedesk-customer-service-organization-field.controllers.use-update-issue-view-organization-field.description-non-final',
		defaultMessage: 'You can try again in a few minutes, or refresh the page.',
		description: 'Flag description shown when an error occurred trying to update an issue field.',
	},
	tryAgain: {
		id: 'servicedesk-customer-service-organization-field.controllers.use-update-issue-view-organization-field.try-again-non-final',
		defaultMessage: 'Try editing the field again',
		description: 'Action button text to allow the user to try again to edit a field.',
	},
});
