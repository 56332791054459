import React from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { Text } from '@atlaskit/primitives';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import ThumbsUpIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import TransitiveNumber from '@atlassian/react-transitive-number';

type VoteButtonProps = {
	testId: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	isSelected: boolean;
	label: string;
	ariaExpanded?: boolean;
	isDisabled?: boolean;
	shouldShowEmptyLikeButton?: boolean;
	hasVoted: boolean;
	votes: number;
};

const VoteButtonRefresh = ({
	testId,
	isSelected,
	isDisabled,
	label,
	ariaExpanded,
	onClick,
	hasVoted,
	votes,
	shouldShowEmptyLikeButton = true,
}: VoteButtonProps) => {
	return (
		<UFOSegment name="issue-vote-button">
			{votes !== 0 ? (
				<Button
					testId={testId}
					isSelected={hasVoted}
					appearance="default"
					spacing="default"
					iconBefore={() => (
						<ThumbsUpIcon
							testId="issue-field-voters.common.vote-button-refresh.like-icon"
							label=""
							LEGACY_size="medium"
							spacing="spacious"
							{...(hasVoted ? { color: token('color.icon.selected', B400) } : {})}
						/>
					)}
					aria-label={label}
					aria-expanded={ariaExpanded}
					isDisabled={isDisabled}
					onClick={onClick}
				>
					<Text
						testId="issue-field-voters.common.vote-button-refresh.counter"
						{...(hasVoted ? { color: 'color.text.selected' } : {})}
					>
						<TransitiveNumber>{votes !== 0 ? votes : ''}</TransitiveNumber>
					</Text>
				</Button>
			) : (
				shouldShowEmptyLikeButton && (
					<IconButton
						testId={testId}
						icon={() => (
							<LikeIcon
								testId="issue-field-voters.common.vote-button-refresh.like-icon"
								label={label}
								size="medium"
							/>
						)}
						isSelected={isSelected}
						label={label}
						aria-expanded={ariaExpanded}
						isDisabled={isDisabled}
						onClick={onClick}
					/>
				)
			)}
		</UFOSegment>
	);
};

export default VoteButtonRefresh;
